import React, { Component } from "react";
import "./livraisonCoachAssistant.css";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import ItemFilActualite from "../itemFilActualite/index";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import API from "../../variablesGlobales";
import base_api_url from "../../variablesGlobales";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import FeatherIcon from "feather-icons-react";
import amplitude from "amplitude-js";

//const Loader = () => <div> </div>;
export default class livraisonCoachAssistant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: sessionStorage.getItem("user_id"),
      userFullName: window.sessionStorage.getItem("userFullName"),
      domaine_id: window.sessionStorage.getItem("domaine_id"),
      api: API.API,
      base_api_url: base_api_url.base_api_url,
      livraisonsSyllabus: [],
      livraisonsTaches: [],
      images: [],
      files: [],
      fields: {},
      hasMore: true,
      // loading: false,
      recherche: "",
      rechercher: "",
      searchName: true,
      currentPage: 1,
    };
  }
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);

    amplitude.getInstance().init(api_key);
  };
  getLivraisonData() {
    const rechercher = this.state.rechercher.trim().toLocaleLowerCase();
    const livraison = [...this.state.livraisonsSyllabus];
    if (rechercher.length < 1) {
      return livraison;
    }
    return livraison.filter((s) => {
      return s.bakelisteFullname.toLocaleLowerCase().includes(rechercher);
    });
  }
  componentDidMount() {
    this.getLivraisonsByCoach(this.state.page);
  }

  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };

  getLivraisonsByCoach = () => {
    axios
      .get(
        this.state.api +
          "livraison_bakelistes_by_domain/" +
          this.state.domaine_id +
          "/?page=" +
          this.state.currentPage
      )
      .then((res) => {
        if (res.data.success === true) {
          // console.log("livraisons", res.data.detailsSyllabus.data);
          this.setState({
            livraisonsSyllabus: res.data.detailsSyllabus.data,
          });
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getFichiviersLraisons = (data) => {
    const ok = data.map((i, ix) => {
      return i.fichiers;
    });
    return ok;
  };

  getCommentsLivraisons = (data) => {
    const ok = data.map((i, ix) => {
      return i.comments;
    });
    return ok;
  };

  getHours = (date) => {
    if (date !== null) {
      var before = moment(date, "YYYY.MM.DD HH:mm");

      var now = moment();
      var diff = now.diff(before, "days");
      // = moment(now - before).format(" H[ heure(s)]");

      return diff;
    } else return 0;
  };
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);

    amplitude.getInstance().init(api_key);
  };
  addReactiononLivrable = (fields, item) => {
    const livraison = item.livraisons;
    const eventProperties = {
      user_id: this.state.user_id,
      Name: this.state.userFullName,
    };
    axios
      .post(this.state.api + "comment/store", fields)
      .then((response) => {
        // console.log(response.data.data);
        amplitude
          .getInstance()
          .logEvent(
            "COMMENTAIRE_LIVRAISON_COACH-ASSISTANT_V2",
            eventProperties
          );
        const data = response.data.data;
        data.fullname = this.state.userFullName;
        item.comments.push(data);
        const livraisonsSyllabus = [...this.state.livraisonsSyllabus];
        const index = livraisonsSyllabus.findIndex((it) => it.id === item.id);
        livraisonsSyllabus[index].livraisons = livraison;
        this.setState({ livraisonsSyllabus });
        var Event = {
          user_id: this.state.bakeliste_id,
          email: this.state.email,
        };
        this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
        amplitude
          .getInstance()
          .logEvent("COMMENTAIRE_LIVRAISON_COACH_ASSISTANT_V2", Event);
      })

      .catch((error) => {
        // console.log(error);
      });
  };

  fetchMoreData = () => {
    setTimeout(() => {
      this.setState({
        currentPage: this.state.currentPage + 1,
        loading: true,
      });
      axios
        .get(
          this.state.api +
            "livraison_bakelistes_by_domain/" +
            this.state.domaine_id +
            "/?page=" +
            this.state.currentPage
        )
        .then((response) => {
          // console.log("jdcjcndzl", response);

          if (response.data.success === true) {
            Object.values(response.data.detailsSyllabus.data).forEach(
              (element) => {
                this.state.livraisonsSyllabus.push(element);
              }
            );
            //console.log("dzlkd", this.state.livraisonsSyllabus);

            if (
              response.data.detailsSyllabus.current_page ===
              response.data.detailsSyllabus.last_page
            ) {
              this.setState({ hasMore: false, loading: false });
            } else {
              this.setState({ hasMore: true, loading: true });
            }
          }
        });
    }, 500);

    return this.state.livraisonsSyllabus;
  };

  render() {
    const { livraisonsTaches, livraisonsSyllabus } = this.state;

    return (
      <div className="p-b-200">
        <div className="row">
          {/* <div className="col-lg-9"> */}
          {/* {loading && (
              <div
                className="col-lg-10 pt-3 offset-5 overlay-box;
              "
              >
                <Loader
                  type="ThreeDots"
                  color="#009688"
                  height={700}
                  width={150}
                  timeout={4000}
                />
              </div>
            )} */}
          {/* <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item ">
                  <Link to="/dashboard-coach-assistant">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Livraisons
                </li>
              </ol>
            </nav> */}

          {/* <div className="row" style={{ marginTop: "30px" }}> */}
          {/* <div className="col m-b-10">
                <h6 className="h-theme ff-inter fw-600 ">Livrables</h6>
              </div> */}
          {/* <div className="col dis-flex m-b-15">
                <div className="input-group1">
                  <FeatherIcon className="search_icon" icon="search" />
                  <input
                    value={this.state.rechercher}
                    type="search"
                    className="form-control form-control-costom"
                    placeholder="Chercher un nom"
                    aria-label="Search"
                    aria-describedby="search-addon"
                    icon="Search"
                    onChange={(e) =>
                      this.setState({
                        rechercher: e.target.value,
                      })
                    }
                  />
                </div>
              </div> */}
          {/* </div> */}

          {/* <div className="row"> */}
          <div
            className="col-md-12"
            style={{ alignItems: "center", marginInline: "auto" }}
          >
            <InfiniteScroll
              className="hello"
              dataLength={this.state.livraisonsSyllabus.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMore}
              loader={
                this.state.loading &&
                "Les donnees sont entrain de se charger...."
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Vous avez tous visualise!!!</b>
                </p>
              }
            >
              {livraisonsSyllabus.length > 0 &&
                livraisonsSyllabus.map((item, index) => (
                  <ItemFilActualite
                    key={item.id}
                    livraisons={item.livraisons}
                    onSubmitComment={this.addReactiononLivrable}
                    fichiers={item.fichiers !== null && item.fichiers}
                    commentaires={item.comments !== null && item.comments}
                    index={index + 1}
                    item={item}
                    bakeliste_id={item.bakeliste_user_id}
                    fullname={
                      item.user !== null &&
                      item.user.first_name + " " + item.user.last_name
                    }
                    tache_content={
                      item.details !== null && item.details.syllabus_task
                    }
                    date={item.updated_at}
                    hours="0"
                  />
                ))}
            </InfiniteScroll>
          </div>

          {/* {this.state.searchName ? (
                <div
                  className="col-md-12"
                  style={{ alignItems: "center", marginInline: "auto" }}
                >
                  {this.getLivraisonData().map((item, index) => (
                    <ItemFilActualite
                      key={item.id}
                      item={item}
                      livraisons={item.livraisons}
                      test={this.getFichiersLivraisons(item.livraisons)}
                      index={index + 1}
                      commentaires={this.getCommentsLivraisons(item.livraisons)}
                      bakeliste_id={item.bakeli_user_id}
                      fullname={item.bakelisteFullname}
                      onSubmitComment={this.addReactiononLivrable}
                      tache_content={item.syllabus_task}
                      date={item.created_at}
                      hours={this.getHours(item.created_at)}
                    />
                  ))}
                  {this.getLivraisonData().map((item, index) => (
                    <ItemFilActualite
                      key={item.id}
                      livraisons={item.livraisons}
                      test={this.getFichiersLivraisons(item.livraisons)}
                      onSubmitComment={this.addReactiononLivrable}
                      commentaires={this.getCommentsLivraisons(item.livraisons)}
                      index={index + 1}
                      bakeliste_id={item.bakeli_user_id}
                      fullname={item.bakelisteFullname}
                      tache_content={item.content}
                      date={item.created_at}
                      hours={this.getHours(item.created_at)}
                    />
                  ))}
                </div>
              ) : (
                <div
                  className="col-md-12"
                  style={{ alignItems: "center", marginInline: "auto" }}
                >
                  {this.getLivraisonData().map((item, index) => (
                    <ItemFilActualite
                      key={item.id}
                      item={item}
                      livraisons={item.livraisons}
                      test={this.getFichiersLivraisons(item.livraisons)}
                      index={index + 1}
                      commentaires={this.getCommentsLivraisons(item.livraisons)}
                      bakeliste_id={item.bakeli_user_id}
                      fullname={item.bakelisteFullname}
                      onSubmitComment={this.addReactiononLivrable}
                      tache_content={item.syllabus_task}
                      date={item.created_at}
                      hours={this.getHours(item.created_at)}
                    />
                  ))}
                  {this.getLivraisonData().map((item, index) => (
                    <ItemFilActualite
                      key={item.id}
                      livraisons={item.livraisons}
                      test={this.getFichiersLivraisons(item.livraisons)}
                      onSubmitComment={this.addReactiononLivrable}
                      commentaires={this.getCommentsLivraisons(item.livraisons)}
                      index={index + 1}
                      bakeliste_id={item.bakeli_user_id}
                      fullname={item.bakelisteFullname}
                      tache_content={item.content}
                      date={item.created_at}
                      hours={this.getHours(item.created_at)}
                    />
                  ))}
                </div>
              )} */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteGroupItemForCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ livraisonCoach }) => ({ ...livraisonCoach }),
//     dispatch => bindActionCreators({ ...livraisonCoachActions }, dispatch)
//   )( livraisonCoach );
