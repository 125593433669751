import React, { Component } from "react";
//import "./syllabusArchiveCoach.css";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import { AutoComplete, Icon, InputGroup, Modal } from "rsuite";
import SyllabusItemCoach from "../syllabusItemCoach/index";
import SyllabusArchiveCoachItem from "../syllabusArchiveCoachItem/index";
//import AddSyllabus from "../addSyllabus/index";
import axios from "axios";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import $ from "jquery";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import MultiSelect from "@khanacademy/react-multi-select";
import SweetAlert from "react-bootstrap-sweetalert";

export default class syllabusArchivesCoach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: window.sessionStorage.getItem("user_id"),
      api: API.API,
      domaine_id: "",
      domaines: [],
      syllabusDatas: [],
      syllabusDomaineDatas: [],
      isPageLoading: false,
      datas: [],
      recherche: "",
      datasDomaine: [],
      tab_all_syllabus: true,

      // Add SYllabusss

      show: false,
      aisSuccessAssign: false,
      isErrorAssign: false,
      isAssignSyllabusInProgress: false,
      selectedOption: null,
      userID: window.sessionStorage.getItem("user_id"),
      showHide: false,
      isPageLoading: true,
      api: API.API,
      syllabusDatas: [],
      domaines: [],
      technologies: [],
      tools: [],
      fields: {},
      errors: {},
      sName: "",
      sDescription: "",
      sDomaine: 0,
      domainesSelect: [],
      sLevel: "",
      sTechnologie: [],
      sTool: [],
      sname: false,
      sdescription: false,
      stechnotool_empty: false,
      screatedby_empty: false,
      slevel_empty: false,
      sdomaineid_empty: false,
      addSyllabusInProgress: false,
      addSyllabusSuccessAlert: false,
      addSyllabusErrorAlert: false,
      remaining_char: 245,
      selectOptions: [],
      selectOptions1: [],
      selectOptions2: [],
      selectTechnologies: [],
      selectTools: [],
      syllabusID: 0,
      bakelisteID: 0,
      selected: [],
      utFields: {},
      selectedtools: [],
      onlySelect: false,
      groupySelect: false,
      bakelistesByCoach: [],
      bakeliste: [],
      assignationEncours: false,
      assignationSuccessAlert: false,
      isAssignationError: false,
      noSavedData: [],
      showAlertSuccess: false,
      showAlertError: false,
      utInProgress: false,
      syllabusByCoache: [],
      updateSyllabusRequestError: false,
      // updateSyllabusRequestError: false,
      updateSyllabusRequestSuccessAlert: false,
      bakelisteBySyllabus: [],
      sous_groupes: [],
    };

    Sentry.init({
      dsn:
        "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
    });

    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }
  componentDidMount = () => {
    this.onGetSyllabus();
    // this.onGetSyllabusByDomaine();
    this.onGetDomaines();
    this.getAllDomaines();
    window.scrollTo(0, 0);
  };

  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
  };

  getAllDomaines = () => {
    axios
      .get(this.state.api + "domaines")
      .then((res) => {
        const domains = res.data;
        this.setState({
          domaines: domains.data,
        });

        /* this.state.domaines.map((item,index) => (
          this.setState({
            domainesSelect:this.state.domainesSelect.concat(
              {
                
                label:item.name,
                value:item.id,
                role:'Master'
              }
            )
          })
        ))  */
        // console.log(this.state.domainesSelect);
      })
      .catch((error) => {
        //(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getSyllabusData() {
    const recherche = this.state.recherche.trim().toLocaleLowerCase();
    const syllabus = this.state.syllabusDatas;
    if (recherche.length < 1) {
      return syllabus;
    }
    return syllabus.filter((s) => {
      return s.syllabus_name.toLocaleLowerCase().includes(recherche);
    });
  }

  onGetDomaines = () => {
    const _this = this;
    this.showLoader();
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;

    axios
      .get(baseApiUrl + "liste-domaines-actifs")
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          this.setState({
            domaines: response.data.data.reverse(),
          });
        }
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
      });
  };

  setNavTabs = (event) => {
    // let navs = this.state.navs;
    // navs[event.target.name] = event.target.value;
    // console.log("testttt", event.target.value);
    this.setState({
      tab_all_syllabus: false,
      domaine_id: event.target.value,
    });
    this.onGetSyllabusByDomaine(event.target.value);
  };

  setNavall = (event) => {
    // let navs = this.state.navs;
    // navs[event.target.name] = event.target.value;
    // console.log(event.target.value);
    this.setState({
      tab_all_syllabus: true,
    });
  };

  onGetSyllabusByDomaine = (id) => {
    const _this = this;
    this.showLoader();
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;

    axios
      .get(baseApiUrl + "syllabus-by-domaine/" + id)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          this.setState({
            syllabusDomaineDatas: response.data.data.reverse(),
          });
        }
        this.state.syllabusDomaineDatas.forEach((item) => {
          let infos = {
            name: item.syllabus_name,
          };
          this.state.datasDomaine.push(infos);
        });
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
      });
  };
  onGetSyllabus = () => {
    const _this = this;
    this.showLoader();
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;
    axios
      .get(baseApiUrl + "archived/syllabus/")
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          this.setState({
            syllabusDatas: response.data.data.reverse(),
          });
        }
        this.state.syllabusDatas.forEach((item) => {
          let infos = {
            name: item.syllabus_name,
          };
          this.state.datas.push(infos);
        });
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
      });
  };

  render() {
    return (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item ">
                  <Link to="/dashboard-coach">Dashboard</Link>
                </li>
                <li className="breadcrumb-item ">
                  <Link to="/coach-syllabus">Syllabus</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Syllabus archivés
                </li>
              </ol>
            </nav>
            <div className="row m-b-15 mto-3">
              <div className="col-xl-4 col-lg-5 col-md-4 col-sm-5">
                <input
                  value={this.state.recherche}
                  type="search"
                  className="recherche"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  onChange={(e) =>
                    this.setState({
                      recherche: e.target.value,
                    })
                  }
                />
                {/* <FeatherIcon
                className="icon-content-syllabus-item"
                icon="search"
                /> */}
              </div>
            </div>

            <div className="row px-lg-0 px-md-2"></div>
            <div className="row px-lg-0 px-md-2">
              <div
                classname="col-md-2"
                onChange={(event) => this.setNavall(event)}
              >
                <div className="radiobtn">
                  <input
                    defaultChecked
                    type="radio"
                    value="all"
                    name="domaine"
                    id="all"
                    data-target="#all-syllabus"
                  />
                  <label for="all">All</label>
                </div>
              </div>

              {this.state.domaines.map((item, index) => (
                <div
                  classname="col"
                  onChange={(event) => this.setNavTabs(event)}
                >
                  <div className="radiobtn">
                    <input
                      key={index}
                      type="radio"
                      value={item.id}
                      name="domaine"
                      id={item.id}
                      data-target="#filter-syllabus"
                    />
                    <label for={item.id}>{item.name}</label>
                  </div>
                </div>
              ))}
            </div>

            <div className=" px-lg-0 px-md-2">
              {this.state.tab_all_syllabus ? (
                <div classname="row" id="all-syllabus">
                  {this.getSyllabusData().map((item, index) => (
                    <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
                      <SyllabusArchiveCoachItem
                        niveau={item.syllabus_level}
                        name={item.syllabus_name}
                        description={item.syllabus_description}
                        technologies={item.technologies}
                        syllabus_id={item.id}
                        outils={item.tools}
                        nbreBakeliste={item.assignations.length}
                        nbreTache={item.details.length}
                        ongetSyllabus={this.onGetSyllabus}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="tab-pane active" id="filter-syllabus">
                  {this.state.syllabusDomaineDatas.map((item, index) => (
                    <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
                      <SyllabusArchiveCoachItem
                        niveau={item.syllabus_level}
                        name={item.syllabus_name}
                        description={item.syllabus_description}
                        technologies={item.technologies}
                        syllabus_id={item.id}
                        outils={item.tools}
                        nbreBakeliste={item.assignations.length}
                        nbreTache={item.details.length}
                        ongetSyllabus={this.onGetSyllabus}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteGroupItemForCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ syllabusArchivesCoach }) => ({ ...syllabusArchivesCoach }),
//     dispatch => bindActionCreators({ ...syllabusArchivesCoachActions }, dispatch)
//   )( syllabusArchivesCoach );
