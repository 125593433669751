import React, {Component} from 'react';
import './accountBakeliste.css';
import CoachItemDashboardBakeliste from '../coachItemDashboardBakeliste/index';
import BakelisteGroupItem from '../bakelisteGroupItem/index';
import NextReunionItem from '../nextReunionItem/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import MyAccount from '../myAccount/index';

export default class accountBakeliste extends Component {
  
  render() {
    return  <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
              <div className="row">
                <div className="col-lg-9">
                  <MyAccount/>
                </div>
                <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                  <div className="row">
                    <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                      <CoachItemDashboardBakeliste />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                      <BakelisteGroupItem />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                      <NextReunionItem />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                      <SavedReunionListItem />
                    </div>
                  </div>
                </div>
              </div>
            </div>;
  } 
}
