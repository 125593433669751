import React, { Component } from "react";
import ContentQuestionCoachAssistant from "../contentQuestionCoachAssistant/index";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoachAssistant from "../navVerticalCoachAssistant/index";
import "./viewQuestionsCoachAssistant.css";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as viewQuestionnaireQuizActions from "../../store/viewQuestionnaireQuiz/actions";
export default class viewQuestionsCoachAssistant extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />

        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoachAssistant />
            </div>
            <div className="col-lg-10 pt-3">
              <ContentQuestionCoachAssistant /> 
            </div> 
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ viewQuestions }) => ({ ...viewQuestions }),
//     dispatch => bindActionCreators({ ...viewQuestionsActions }, dispatch)
//   )( viewQuestions );
