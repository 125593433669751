import React, { Component } from "react";
import "./notFoundView.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import NotFound from "../notFound/index";
// import { Amplitude } from "@amplitude/react-amplitude";

export default class notFoundView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      groupe_id: sessionStorage.getItem("sous_groupe_id"),
      email: window.sessionStorage.getItem("email"),
      user_id: window.sessionStorage.getItem("user_id"),
    };
  }



  render() {
    return (
      <div className="body-theme">
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVertical />
            </div>
            <div className="col-lg-10 pt-3">
              <NotFound />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ dashboardBakelisteView }) => ({ ...dashboardBakelisteView }),
//     dispatch => bindActionCreators({ ...dashboardBakelisteViewActions }, dispatch)
//   )( dashboardBakelisteView );
