import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { Link } from "react-router-dom";
import Title from "./Title";

// Registering Fonts
Font.register({
  family: "Open Sans",
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: "Lato",
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: "Lato Italic",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

// Creating the styles for the PDF of CV
export const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  container: {
    flex: 1,
    flexDirection: "row",
    "@media max-width: 400": {
      flexDirection: "column",
    },
  },
  image: {
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: "column",
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    "@media max-width: 400": {
      width: "100%",
      paddingRight: 0,
    },
    "@media orientation: landscape": {
      width: 200,
    },
  },
  footer: {
    fontSize: 12,
    fontFamily: "Lato Bold",
    textAlign: "center",
    marginTop: 15,
    paddingTop: 5,
    borderWidth: 3,
    borderColor: "gray",
    borderStyle: "dashed",
    "@media orientation: landscape": {
      marginTop: 10,
    },
  },

  //   HEADER STYLES

  headerContainer: {
    flexDirection: "row",
    borderBottomWidth: 2,
    borderBottomColor: "#112131",
    borderBottomStyle: "solid",
    alignItems: "stretch",
  },
  detailColumn: {
    flexDirection: "column",
    flexGrow: 9,
    textTransform: "uppercase",
  },
  linkColumn: {
    flexDirection: "column",
    flexGrow: 2,
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  name: {
    fontSize: 24,
    fontFamily: "Lato Bold",
  },
  subtitle: {
    fontSize: 10,
    justifySelf: "flex-end",
    fontFamily: "Lato",
  },
  link: {
    fontFamily: "Lato",
    fontSize: 10,
    color: "black",
    textDecoration: "none",
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },

  //   EDUCATION STYLES
  educationContainer: {
    marginBottom: 10,
  },
  school: {
    fontFamily: "Lato Bold",
    fontSize: 10,
  },
  degree: {
    fontFamily: "Lato",
    fontSize: 10,
  },
  candidate: {
    fontFamily: "Lato Italic",
    fontSize: 10,
  },

  //   SKILLS STYLES
  item: {
    flexDirection: "row",
    marginBottom: 5,
  },
  bulletPoint: {
    width: 10,
    fontSize: 10,
  },
  itemContent: {
    flex: 1,
    fontSize: 10,
    fontFamily: "Lato",
  },
  title: {
    fontFamily: "Lato Bold",
    fontSize: 11,
    marginBottom: 10,
  },
  skills: {
    fontFamily: "Lato",
    fontSize: 10,
    marginBottom: 10,
  },

  //   EXPERIENCE STYLES
  experienceContainer: {
    flex: 1,
    paddingTop: 30,
    paddingLeft: 15,
    "@media max-width: 400": {
      paddingTop: 10,
      paddingLeft: 0,
    },
  },
  entryContainer: {
    marginBottom: 10,
  },
  date: {
    fontSize: 11,
    fontFamily: "Lato Italic",
  },
  detailContainer: {
    flexDirection: "row",
  },
  detailLeftColumn: {
    flexDirection: "column",
    marginLeft: 10,
    marginRight: 10,
  },
  detailRightColumn: {
    flexDirection: "column",
    flexGrow: 9,
  },
  details: {
    fontSize: 10,
    fontFamily: "Lato",
  },
  experienceHeaderContainer: {
    flexDirection: "row",
    marginBottom: 10,
  },
  experienceLeftColumn: {
    flexDirection: "column",
    flexGrow: 9,
  },
  experienceRightColumn: {
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-end",
    justifySelf: "flex-end",
  },
  experienceTitle: {
    fontSize: 11,
    color: "black",
    textDecoration: "none",
    fontFamily: "Lato Bold",
  },
});

// Creating the PDF of CV
const CVPdf = ({ data }) => {
  let cvName = `CV BAKELI | ${data.cvthequeData.bakelisteFullname}`;
  return (
    <Document title={cvName} author={cvName}>
      <Page size="A4" style={styles.page}>
        {/* {console.log("Items for PDF", data)} */}
        {/* Header View */}
        <View style={styles.headerContainer}>
          <View style={styles.detailColumn}>
            <Text style={styles.name}>
              {data.cvthequeData.bakelisteFullname}
            </Text>
            <Text style={styles.subtitle}>
              {data.cvthequeData.bakelisteNickname}
            </Text>
          </View>
        </View>

        {/* Our Container */}
        <View style={styles.container}>
          {/* Left Section */}
          <View style={styles.leftColumn}>
            <Image
              src="https://react-pdf.org/static/images/luke.jpg"
              style={styles.image}
            />
            {/* Education Part */}
            <View style={styles.containerEducation}>
              <Title>Education</Title>
              {data.cveducationData.map((item) => (
                <>
                  <Text style={styles.school}>{item.school}</Text>
                  <Text style={styles.degree}>
                    {item.diplome} - {item.diplome_label}
                  </Text>
                  <Text style={styles.candidate}>
                    {item.ville} - {item.pays}
                  </Text>
                </>
              ))}
            </View>

            {/* Skills Part */}
            <View>
              <Title>Competences</Title>
              {data.cvtechnologiesData.map((item) => (
                <View style={styles.item}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.itemContent}>{item.techno_name}</Text>
                </View>
              ))}
            </View>
          </View>
          {/* Experience Section */}
          <View style={styles.experienceContainer}>
            <Title>Projets</Title>
            {data.cvprojectsData.map((project) => (
              <View style={styles.entryContainer}>
                <View style={styles.experienceHeaderContainer}>
                  <View style={styles.experienceLeftColumn}>
                    <Text style={styles.experienceTitle}>
                      Project: {project.project_name}
                    </Text>
                    <Text style={styles.degree}>
                      {project.project_technologies}
                    </Text>
                  </View>
                </View>
                {/* {project.taches.map((item, index) => (
                  <View style={styles.item} key={index}>
                    <Text style={styles.bulletPoint}>•</Text>
                    <Text style={styles.detailContainer}>
                      {item.tache_content}
                    </Text>
                  </View>
                ))} */}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CVPdf;
