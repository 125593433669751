import React, { Component } from "react";
import "./syllabusDetailsBakeliste.css";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItem from "../bakelisteGroupItem/index";
import NextReunionItem from "../nextReunionItem/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import SyllabusDetails from "../syllabusDetails/index";
import axios from "axios";
import Preloader from "../preloader/index";

import API from "../../variablesGlobales";
import { Link } from "react-router-dom";

export default class syllabusDetailsBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      syllabus_id: this.props.id,
      api: API.API,
      userID: window.sessionStorage.getItem("user_id"),
      syllabusDatas: [],
      syllabusInfos: [],
      syllabusID: 0,
      detail_syllabus_id: 0,
      isPageLoading: false,
      taches: [],
      details: [],
      nbrtache: 0,
      loading: true,
    };
  }

  componentDidMount = () => {
    this.onGetSyllabusDetails();
    setTimeout(() => { 
      this.setState({
        loading: false,
      });
    }, 5000);
  };

  onGetSyllabusDetails = () => {
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;

    axios
      .get(baseApiUrl + "all-details-syllabus/" + this.state.syllabus_id)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });

        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            syllabusDatas: response.data.data,
            nombreTaches: response.data.data.details.length,
            nombre: response.data.data.assignations.length,
          });
        }
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          isPageLoading: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };
  render() {
    return this.state.loading ? (
      <div>
        <Preloader />
      </div>
    ) : (
      <>
        <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
          <div className="row">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb fixed">
                <ol className="breadcrumb fixed">
                  <li className="breadcrumb-item ">
                    <Link to="/dashboard-bakeliste">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item " aria-current="page">
                    <Link to="/syllabus-bakeliste">Programmes</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Détails {this.state.syllabusDatas.syllabus_name}
                  </li>
                </ol>
              </nav>
              <SyllabusDetails
                name={this.state.syllabusDatas.syllabus_name}
                niveau={this.state.syllabusDatas.syllabus_level}
                description={this.state.syllabusDatas.syllabus_description}
                id={this.state.syllabus_id}
                nbreTaches={this.state.nombreTaches}
                Taches={this.state.syllabusDatas.taches}
                nbreBakeliste={this.state.nombre}
              />
            </div>
            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                  <CoachItemDashboardBakeliste />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                  <BakelisteGroupItem />
                </div>
                {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                      <NextReunionItem />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                      <SavedReunionListItem />
                    </div> */}
              </div>
            </div>
          </div>
        </div>
        ;
      </>
    );
  }
}
