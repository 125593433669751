import React, { Component } from "react";
import "./statisticsBakeliste.css";
import IconStat1 from "../../img/icons/person_add.png";
import IconStat2 from "../../img/icons/content_paste.png";
import IconStat3 from "../../img/icons/people.png";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { NavLink } from "react-router-dom";

import API from "../../variablesGlobales";

export default class statisticsBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      bakeliste_id: sessionStorage.getItem("user_id"),
      tasktodo: 0,
      taskdoing: 0,
      taskdone: 0,
      userID: window.sessionStorage.getItem("user_id"),
    };
    Sentry.init({
      dsn:
        "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
    });
  }

  componentDidMount() {
    this.getTotalaskByBakeliste();
  }
  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  getTotalaskByBakeliste = () => {
    const bakeliste_id = this.state.bakeliste_id;
    axios
      .get(this.state.api + "count-taches-by-bakeliste/" + bakeliste_id)
      .then((response) => {
        var task = response.data;
        this.setState({
          tasktodo: task.datatodo,
          taskdoing: task.datadoing,
          taskdone: task.datadone,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  render() {
    return (
      <div className="row">
        <div className="col-md-4 pr-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard">
            <NavLink to="/tache-bakeliste" style={{ color: "white" }}>
              <p className="chiffre-item-statistics-dashboard">
                {" "}
                {this.state.tasktodo}
              </p>
              <p className="designation-item-statistics-dashboard">
                Nombre de tâches à faire
              </p>
              <img
                className="img-item-statistics-dashboard"
                src={IconStat1}
                alt=""
              />
            </NavLink>
          </div>
        </div>
        <div className="col-md-4 pr-md-2 pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard">
            <NavLink to="/tache-bakeliste" style={{ color: "white" }}>
              <p className="chiffre-item-statistics-dashboard">
                {" "}
                {this.state.taskdoing}
              </p>
              <p className="designation-item-statistics-dashboard">
                Nombre de tâches en cours
              </p>
              <img
                className="img-item-statistics-dashboard icon2"
                src={IconStat2}
                alt=""
              />
            </NavLink>
          </div>
        </div>
        <div className="col-md-4 pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard">
            <NavLink to="/tache-bakeliste" style={{ color: "white" }}>
              <p className="chiffre-item-statistics-dashboard">
                {" "}
                {this.state.taskdone}
              </p>
              <p className="designation-item-statistics-dashboard">
                Nombre de tâches terminé
              </p>
              <img
                className="img-item-statistics-dashboard"
                src={IconStat3}
                alt=""
              />
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ statisticsBakeliste }) => ({ ...statisticsBakeliste }),
//     dispatch => bindActionCreators({ ...statisticsBakelisteActions }, dispatch)
//   )( statisticsBakeliste );
