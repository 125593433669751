import React, {Component} from 'react';
import './livraisonDunBakeliste.css';
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItem from "../bakelisteGroupItem/index";
import NextReunionItem from "../nextReunionItem/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import LivrableDunBakelisteItem from "../livrableDunBakelisteItem/index";
import ModalContactUs from "../modalContactUs/index";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import { Link } from "react-router-dom";
import Preloader from "../preloader/index";

import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";
import API from "../../variablesGlobales";
import Loader from "react-loader-spinner";
import EnvoyerMonTravail from "../envoyerMonTravail/index";
export default class livraisonDunBakeliste extends Component {
    constructor(props) {
        super(props);
        this.state = {
          api: API.API,
          taches: [],
          loading: true,
          detailsSyllabus: [],
          livraison:[],
          user_id: sessionStorage.getItem("user_id"),
          bakeliste_id: this.props.bakeliste_id,
        };
        Sentry.init({
          dsn:
            "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
        });
      }

      componentDidMount() {
        this.getTachesBakeliste();
        setTimeout(() => {
          this.setState({ loading: false });
        }, 4000);
      }

      componentDidCatch(err, info) {
        Sentry.captureException(err);
      }

      getTachesBakeliste = () => {
        axios
          .get(this.state.api + "taches-by-bakeliste/" + this.state.bakeliste_id)
          .then((response) => {
            this.setState({
              taches: response.data.data,
              detailsSyllabus: response.data.detailsSyllabus,
            }); 
            // console.log("tache_id",this.state.detailsSyllabus)
            const livraison = response.data.detailsSyllabus.map((d) => ({
              value: d.livraisons,
            }));
            // console.log(livraison,"liv")
           var eventProperties = {
              user_id: this.state.bakeliste_id,
              nombre_taches:
                this.state.taches.length + this.state.detailsSyllabus.length,
            };
         })
          .catch((error) => {
            if (process.env.NODE_ENV !== "production") {
              return;
            }
            Sentry.captureException(error);
          });
      };

      getFichiersLivraisons = (data) => {
        const ok = data.map((i, ix) => {
          return i.fichiers;
        });
        return ok;
      };
getTacheId = (data) => {
  const okk = data.map((i, indexe) => {
    return i.id;
  });
  return okk;
}
      getCommentsLivraisons = (data) => {
        const ok = data.map((i, ix) => {
          return i.comments.reverse();
        });
        return ok;
      };
      addReactiononLivrable = (fields, item) => {
        // console.log("11111111")
        const livraison = item.livraisons;
        const eventProperties = {
          user_id: this.state.user_id,
          Name: this.props.Name,
        };
        axios
          .post(this.state.api + "comment/store", fields)
          .then((response) => {
            // console.log(response)
            const data = response.data.data;
            amplitude
            .getInstance()
            .logEvent(
              "COMMENTAIRE_LIVRAISON_COACH_V2",
              eventProperties
            );
            data.fullname = this.props.Name;
            item.comments.push(data);
            const livraisonsSyllabus = [...this.state.livraisonsSyllabus];
            const index = livraisonsSyllabus.findIndex((it) => it.id === item.id);
            livraisonsSyllabus[index].livraisons = livraison;
            this.setState({ livraisonsSyllabus });
          })
          .catch((error) => {
            // console.log(error);
          });
      };
      render() {    
        return this.state.loading ? (
          <div>
            <Preloader />
          </div>
        ) : (
            <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
              <div className="row">
                <div className="col-lg-9 livrason">
                <div>
                    <nav aria-label="breadcrumb fixed">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item ">
                          <Link to="/coach-dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                          Livraisons de {this.props.Name}
                        </li>
                      </ol>
                    </nav>
                  </div>
                  {/*  */}
                  <div className="row m-b-15 pt3">
                    <div className="col m-b-15">
                      <h6 className="h-theme ff-inter fw-600">Livraison</h6>
                    </div>
                    <div className="col dis-flex m-b-15">
                      <ModalContactUs />
                    </div>
                  </div>
                  <section> 
                    {this.state.detailsSyllabus !== undefined ? (
                      this.state.detailsSyllabus.map((item, index) => (
                        <LivrableDunBakelisteItem
                          key={index}
                          tache_number={index + 1}
                          // getTachesBakeliste={this.getTachesBakeliste()}
                          fichiers={this.getFichiersLivraisons(item.livraisons)}
                          commentaires={this.getCommentsLivraisons(item.livraisons)}
                          livraison_id={this.getTacheId(item.livraisons)}
                          
                          id={item.id}
                          onSubmitComment={this.addReactiononLivrable}
                          bakeliste_id={this.state.bakeliste_id}
                          // commentaires={item.livraisons.comments !== null && item.livraisons.comments}
                          titre={item.syllabus_task}
                          date_tache={item.syllabus_completion_time}
                          livraisons={item.livraisons}
                          syllabus={"syllabus"}
                          is_archived={item.is_archived}
                        /> 
                      ))
                    ) : (
                      <div>Aucune livraison pour le moment</div>
                    )}
                  </section>
                </div>
                <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                  <div className="row">
                    <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                      <CoachItemDashboardBakeliste />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                      <BakelisteGroupItem />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
      }
    }
// export default connect(
//     ({ livraisonDunBakeliste }) => ({ ...livraisonDunBakeliste }),
//     dispatch => bindActionCreators({ ...livraisonDunBakelisteActions }, dispatch)
//   )( livraisonDunBakeliste );