import React, { Component } from "react";
import "./coachItemDashboardBakeliste.css";
import AvatarCoach from "../../img/coach.png";
import axios from "axios";
import * as Sentry from "@sentry/browser";

import API from "../../variablesGlobales";

export default class coachItemDashboardBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      coach_id: sessionStorage.getItem("coach_id"),
      coach_data: [],
      domaine: [],
    };
    Sentry.init({
      dsn:
        "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
    });
  }

  componentDidMount() {
    this.getCoachInfos();
  }

  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  getCoachInfos = () => {
   // console.log("coach", this.state.coach_id);
    axios
      .get(this.state.api + "get-coach-by-id/" + this.state.coach_id)
      .then((response) => {
      //  console.log("response", response);
        this.setState({
          coach_data: response.data.data,
          domaine: response.data.data.domaine,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  render() {
    return (
      <div className="item-coach-dashboard-bakeliste m-b-15">
        <img
          className="avatar-coach-dashboard-bakeliste"
          src={AvatarCoach}
          alt=""
        />
        <div className="w-full">
          <p className="name-coach-dashboard-bakeliste">
            {this.state.coach_data.first_name}&nbsp;
            {this.state.coach_data.last_name}
          </p>
          <p className="function-coach-dashboard-bakeliste">Coach</p>
          <div className="dis-flex justify-content-end">
            <button
              type="button"
              className="show-details-coach-dashboard-bakeliste"
              data-toggle="modal"
              data-target="#btnShowDetailsCoach"
            >
              Détails
            </button>
          </div>

          <div
            className="modal fade"
            id="btnShowDetailsCoach"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="btnShowDetailsCoachTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content modal-details-coach">
                <div className="dis-flex justify-content-center m-b-70">
                  <div className="col-md-7 dis-flex text-center flex-col">
                    <img
                      className="avatar-details-coach-dashboard-bakeliste"
                      src={AvatarCoach}
                      alt=""
                    />
                    <p className="name-details-coach-dashboard-bakeliste">
                      {this.state.coach_data.first_name}&nbsp;
                      {this.state.coach_data.last_name}
                    </p>
                    <p className="email-details-coach-dashboard-bakeliste">
                      {this.state.coach_data.email}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-8 m-b-15">
                    <p className="h-info-details-coach-dashboard-bakeliste">
                      Spécialité
                    </p>
                    <p className="info-details-coach-dashboard-bakeliste">
                      {this.state.domaine.name}
                    </p>
                  </div>
                  <div className="col-sm-4">
                    <p className="h-info-details-coach-dashboard-bakeliste">
                      Téléphone
                    </p>
                    <p className="info-details-coach-dashboard-bakeliste">
                      {this.state.coach_data.phone}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
