import React, { Component } from "react";
import "./syllabusItemTache.css";
import { Dropdown, Icon, IconButton } from "rsuite";
import { NavLink } from "react-router-dom";

export default class syllabusItemTache extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taches: this.props.Taches,
      id: this.props.id,
    };
  }
  render() {
    return (
      <div className="card-dashboard pos-relative mb-2 px-xl-5 px-lg-4 px-md-5">
        <div className="row">
          <div className="col-md-2 col-sm-2 pr-sm-0 mb-sm-0 mb-3">
            <p className="numero-tache-syllabus-item-tache">
              Tâches N° {this.props.id_tache}
            </p>
            <p className="duree-tache-syllabus-item-tache">
              Durée: {this.props.duree}h
            </p>
          </div>
          <div className="col-md-9 col-sm-9">
            <p className="titre-syllabus-item-tache">{this.props.contenu}</p>
            <p className="contenu-syllabus-item-tache">
              {this.props.description}
            </p>
            <p className="titre-syllabus-item-tache">
              Liens utiles
              <a
                className="lien-utile-bakeliste-tache-card"
                href={this.props.lien}
                target="blank"
              >
                {this.props.lien}
              </a>
            </p>
          </div>
        </div>
        {/* <Dropdown 
                  className="dropdown-syllabus-item-tache"
                  renderTitle={() => {
                    return <IconButton 
                              icon={<Icon 
                                      icon="ellipsis-v" 
                                      className="icon-dropdown-syllabus-item-tache" />} 
                              circle />;
                  }}
                  placement="bottomEnd"
                >
                  <Dropdown.Item >
                    <NavLink  ID={this.state.id} to={"/modifier-tache-syllabus/"+ this.props.id_tache} >
                      Modifier
                    </NavLink>                     
                  </Dropdown.Item>
                  <Dropdown.Item>
                    Archiver
                  </Dropdown.Item>
                </Dropdown> */}
      </div>
    );
  }
}
