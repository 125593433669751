import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import ModalContactUs from "../modalContactUs/index";
// import SweetAlert from "react-bootstrap-sweetalert";
import ListCoachAssistantRefact from "../listCoachAssistant/ListCoachAssistantRefact";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItemRefact from "../bakelisteGroupItem/BakelisteGroupItemRefact";
import "./demandeAbsence.css";
import FeatherIcon from "feather-icons-react";
import API from "../../variablesGlobales";
import axios from "axios";
import * as Sentry from "@sentry/react";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Tooltip } from "@material-ui/core";
import { useDispatch, useSelector,connect } from "react-redux";
import $ from "jquery";
import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from "react-bootstrap-table";
// import { options } from "amplitude-js";
import { toast } from "react-toastify";
import { addDemandeAbsence, getDemandeAbsenceBakeliste, updatedDemande } from "../LivraisonRefactor/redux/actions/demandeAbsenceActions";
toast.configure();

const DemandeAbsenceRefact = (props) => {

// console.log('hmmmm',props.demandes)
  const [show, setShow] = useState(false);
  const [fields, setFileds] = useState({});
  // const [utInProgress, setUtIntProgress] = useState(false);
  const [api] = useState(API.API);
  const [userID] = useState(window.sessionStorage.getItem("user_id"));
  const [isSendingDemandeInProgress, setIsSendingDemandeInProgress] =
    useState(false);
  const [isSuccessSending, setIsSuccessSending] = useState(false);
  const [isErrorSending, setIsErrorSending] = useState(false);
  const [demande, setDemande] = useState([]);
  const [alldemande, setAlldemande] = useState([]); 
  const [eventeDroite, setEventeDroite] = useState(
    "ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_V2_DROITE"
  );
  // const [demandeById, setDemandeById] = useState([]);
  const [isEditingInProgress, setIsEditingInProgress] = useState(false);
  const [demandeId, setDemandeId] = useState("");
  const [motif, setMotif] = useState("");
  const [contenu, setContenu] = useState("");
  const [date_debut, setDate_debut] = useState("");
  const [date_fin, setDate_fin] = useState("");
  // const [showAlertEditSuccess, setShowAlertEditSuccess] = useState(false);
  // const [showAlertEditError, setShowAlertEditError] = useState(false);
  const [showDate, setShowDate] = useState(false);
  let today = new Date();
  let date = today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate();

  const dispatch = useDispatch();

  const demandeAbsenceData = useSelector((state) => state.demandeAbsence);

  const open = () => {
    setShow(true);
  };

  const close = () => {
    setShow(false);
  };

  const demandeStateFormatter = (cell, row) => {
    return (
      <span>
        {row.status === "rejete" && (
          <span className="label label-danger" data-placement="top">
            Rejetée
          </span>
        )}
        {row.status === "nouveau" && (
          <span className="label label-warning" data-placement="top">
            Nouvelle
          </span>
        )}
        {row.status === "accepte" && (
          <span className="label label-success" data-placement="top">
            Accéptée
          </span>
        )}
      </span>
    );
  };
  const frenchShortFormatDate = (datetoformat) => {
    const date = new Date(datetoformat);
    return date.toLocaleString("fr-FR", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  const getDemande = () => {
    axios
      .get(api + "demande_absences/bakeliste/" + userID)
      .then((res) => {
        const donnees = res.data;
        setDemande(donnees.data);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  // const onSubmitEditDemande = (e) => {
  //   e.preventDefault();
  //   // console.log("gooo");
  //   setIsEditingInProgress(true);

  //   var fd = new FormData();
  //   fd.append("motif", motif);
  //   fd.append("bakeliste_id", userID);
  //   fd.append("contenu", contenu);
  //   fd.append("date_debut", date_debut);
  //   fd.append("date_fin", date_fin);
    
  //   axios
  //     .put(api + "demande_absences/" + demandeId, fd, {
  //       Headers: { "Content-Type": "multipart/formdata" },
  //     })
  //     .then((response) => {
    
  //       if (response.data.success) {
  //         // $(".close-modal-bakeliste-title").click();
  //         setIsEditingInProgress(false);

  //         toast.success(
  //           "Demande d'absence modifiée avec succes",
  //           { position: toast.POSITION.BOTTOM_LEFT },
  //           { autoClose: 1500 }
  //         );
  //         // getDemande();
  //         $(".close-modal-bakeliste-title").click();
  //       } else {
  //         setIsEditingInProgress(false);
  //         toast.error(
  //           "Erreur sur modification de la demande",
  //           { position: toast.POSITION.TOP_CENTER },
  //           { autoClose: 1500 }
  //         );
  //       }
  //     })
  //     .catch((error) => {
        
  //       if (process.env.NODE_ENV !== "production") {
  //         return;
  //       }
  //       Sentry.captureException(error);
  //     });
  // };
  const onDemandeInfos = (infosdemande) => {
    setDemandeId(infosdemande.id);
    setMotif(infosdemande.motif);
    setContenu(infosdemande.contenu);
    setDate_debut(infosdemande.date_debut);
    setDate_fin(infosdemande.date_fin);
  };
  const handleChange = (e) => {
    fields[e.target.name] = e.target.value;
    if (fields.motif === "absence") {
      setShowDate(true);
    }
    fields["bakeliste_id"] = userID;
    setFileds(fields);
  };
  const onSubmitDemande = (e) => {
    e.preventDefault();

    //if(this.onValidateDemandesFormData()){
    setIsSendingDemandeInProgress(true);

    var fd = new FormData();
    fd.append("motif", fields.motif);
    

    fd.append("contenu", fields.contenu);
    fd.append("date_debut", fields.date_debut);
    fd.append("date_fin", fields.date_fin);
    fd.append("bakeliste_id", userID);
    axios
      .post(api + "demande_absences", fd, {
        Headers: { "Content-Type": "multipart/formdata" },
      })
      .then((response) => {
        
        setIsSendingDemandeInProgress(false);
        document.getElementById("submit-demande").reset();

        if (response.data.success) {
          // getDemande();
          $("#motif").val("");
          $("#contenu").val("");
          $("#date_debut").val("");
          $("#date_fin").val("");
          setIsSuccessSending(true);
          setShow(false);

          //alert("Demande envoyé avec succès !")

          setTimeout(() => {
            setIsSuccessSending(false);
          }, 1000);
        }
      })
      .catch((error) => {
        
        setIsSendingDemandeInProgress(false);
        setIsErrorSending(true);
        setTimeout(() => {
          setIsErrorSending(false);
        }, 1000);
      });
  };
  const createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton style={{ background: "#009688" }} btnText="Export CSV" />
    );
  };
  const actionsFormatter = (cell, row) => {
    
    // let id = row.id

    return row.status === "nouveau" ? (
      <span>
        <Tooltip title="Modifier">
          <EditIcon
            className="btn-icon-table editColor"
            cursor="pointer"
            data-toggle="modal"
            data-target="#editDemandeAbsence"
            onClick={() => onDemandeInfos(row)}
          />
        </Tooltip>

        <Tooltip title="Voir demande">
          <VisibilityIcon
            className="btn-icon-table voirColor"
            cursor="pointer"
            data-toggle="modal"
            data-target="#voirDemandeAbsence"
            onClick={() => onDemandeInfos(row)}
          />
        </Tooltip>
      </span>
    ) : (
      <span>
        <Tooltip title="Voir demande">
          <VisibilityIcon
            className="btn-icon-table voirColor"
            cursor="pointer"
            data-toggle="modal"
            data-target="#voirDemandeAbsence"
            onClick={() => onDemandeInfos(row)}
          />
        </Tooltip>
      </span>
    );
  };

  useEffect(() => {
    dispatch(getDemandeAbsenceBakeliste(userID));
    // getDemandeAbsenceRedux(demandeAbsenceData);
  }, [dispatch]);

  // const getDemandeAbsenceRedux = (dataFormRedux) => {
  //   setDemande(dataFormRedux.items);
  // };
  const addDemandeRedux = (e) => {
    e.preventDefault();

    //if(this.onValidateDemandesFormData()){
    setIsSendingDemandeInProgress(true);

    var fd = new FormData();
    fd.append("motif", fields.motif);
    

    fd.append("contenu", fields.contenu);
    fd.append("date_debut", fields.date_debut);
    fd.append("date_fin", fields.date_fin);
    fd.append("bakeliste_id", userID);
    dispatch(addDemandeAbsence(fd))
    // setIsSendingDemandeInProgress(false);
    setTimeout(() => {
      setShow(false);
    }, 3000);

  }

  const updatedDemandeRedux = (e) =>{
    e.preventDefault();
    // console.log("gooo");
    setIsEditingInProgress(true);

    var fd = new FormData();
    fd.append("motif", motif);
    fd.append("bakeliste_id", userID);
    fd.append("contenu", contenu);
    fd.append("date_debut", date_debut);
    fd.append("date_fin", date_fin);
    dispatch(updatedDemande(demandeId,fd))

    setIsEditingInProgress(false);
    
    setTimeout(()=>{
      $(".close-modal-bakeliste-title").click();
    },3000)

  }
  const options = {
    exportCSVBtn: createCustomExportCSVButton,
  };
  return (
    <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
      <div className="row">
        <div className="col-lg-9">
          <nav aria-label="breadcrumb fixed">
            <ol className="breadcrumb fixed ">
              <li className="breadcrumb-item ">
                <Link to="coach-dashboard">Dashboard </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Demande d'absence
              </li>
            </ol>
          </nav>
          <div className="row m-b-15 pt3">
            <div className="col m-b-15">
              <h6 className="h-theme ff-inter fw-600">Demande d'absence </h6>
            </div>
            <div className="col dis-flex m-b-15">
              <ModalContactUs />
            </div>
          </div>

          <div className="card-dashboard p-md-5 p-sm-5 m-b-20">
            <div className="col-md-4 offset-md-8">
              <button
                className="btn-theme text-up w-full "
                onClick={() => open()}
              >
                <FeatherIcon
                  icon="mail"
                  className="icon-btn-livrable-bakeliste-item"
                />
                Faire une demande
              </button>
            </div>

            <br />

            {show ? (
              <form
                id="submit-demande"
                encType="multipart/form-data"
                onSubmit={(e) => addDemandeRedux(e)}
              >
                <div className="form-group m-b-45">
                  <label>Motif de votre absence</label>

                  <select
                    className="form-control form-custom-input"
                    name="motif"
                    id="pet-select"
                    onChange={handleChange}
                  >
                    <option value="">--Séléctionner un motif--</option>
                    <option value="absence">Demande d'absence</option>
                    <option value="Changement-horaire">
                      Changement horaire
                    </option>
                  </select>
                  {/* <input
                  id="motifd"
                  required
                  onChange={this.handleChange}
                  name="motif"
                  className="form-control form-custom-input"
                  aria-describedby="emailHelp"
                  type="text"
                  placeholder="Motif de l'absence"
                ></input> */}
                </div>
                <div className="form-group m-b-45">
                  <label>Contenu de la demande</label>
                  <textarea
                    id="contenud"
                    required
                    onChange={handleChange}
                    name="contenu"
                    className="form-control form-custom-input"
                    aria-describedby="emailHelp"
                    placeholder=""
                  ></textarea>
                </div>
                {showDate === true ? (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group m-b-45">
                        <label>Date de début</label>
                        <input
                          id="date_debutd"
                          type="date"
                          required
                          onChange={handleChange}
                          name="date_debut"
                          className="form-control form-custom-input"
                          // aria-describedby="emailHelp"
                          // min="2021-01-01"
                          // max="2021-12-31"
                        ></input>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group m-b-45">
                        <label>Date de fin</label>
                        <input
                          id="date_find"
                          type="date"
                          required
                          onChange={handleChange}
                          name="date_fin"
                          className="form-control form-custom-input"
                          // aria-describedby="emailHelp"
                          // min="2021-01-01"
                          // max="2021-12-31"
                        ></input>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="row">
                  <div className="col-md-6">
                    <button
                      className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                      onClick={() => close("md")}
                    >
                      ANNULER
                    </button>
                  </div>
                  <div className="col-md-6">
                    {isSendingDemandeInProgress ? (
                      <button
                        type="submit"
                        className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                        disabled
                      >
                        Envoie en cours &nbsp;
                        <FeatherIcon icon="loader" aria-hidden="true" />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                      >
                        ENVOYER
                      </button>
                    )}
                  </div>
                </div>
              </form>
            ) : (
              <>
                <BootstrapTable
                  data={props.demandes}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  exportCSV
                  // trClassName={this.rowClassNameFormat}
                >
                  <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="motif"
                    filter={{ type: "TextFilter", delay: 1000 }}
                    width="100"
                    dataSort={true}
                  >
                    Motif
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="date_debut"
                    dataFormat={frenchShortFormatDate}
                    filter={{ type: "TextFilter", delay: 1000 }}
                    dataSort={true}
                    width="160"
                  >
                    Date de début
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="date_fin"
                    dataFormat={frenchShortFormatDate}
                    dataSort={true}
                    width="160"
                    filter={{ type: "TextFilter", delay: 1000 }}
                  >
                    Date de fin
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="status"
                    dataFormat={demandeStateFormatter}
                    dataSort={true}
                    width="100"
                    filter={{ type: "TextFilter", delay: 1000 }}
                  >
                    Status
                  </TableHeaderColumn>
                  <TableHeaderColumn width="110" dataFormat={actionsFormatter}>
                    Actions
                  </TableHeaderColumn>
                </BootstrapTable>
                <div
                  className="modal fade"
                  id="voirDemandeAbsence"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="voirDemandeAbsenceTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content modal-content-bakeliste">
                      <h5
                        className="modal-bakeliste-title m-b-50"
                        id="voirDemandeAbsenceTitle"
                      >
                        Détails de la demande
                      </h5>
                      <button
                        type="button"
                        className="close-modal-bakeliste-title"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <FeatherIcon icon="x" aria-hidden="true" />
                      </button>
                      <form
                        // encType="multipart/form-data"

                        onSubmit={(e) => updatedDemandeRedux(e)}
                      >
                        <div className="form-group">
                          Motif
                          <div
                            id="tache_id"
                            name="motif"
                            className="form-control select-modal-content-bakeliste"
                          >
                            {motif}
                          </div>
                        </div>
                        <div className="form-group">
                          Contenu
                          <p>{contenu}</p>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            Date de début :
                            <label
                              id="tache_file"
                              name="date_debut"
                              type="date"
                              className="form-control select-modal-content-bakeliste"
                            >
                              {frenchShortFormatDate(date_debut)}
                            </label>
                          </div>
                          <div className="form-group col-md-6">
                            Date de fin :
                            <label
                              id="tache_file"
                              value={frenchShortFormatDate(date_fin)}
                              name="date_debut"
                              type="date"
                              className="form-control select-modal-content-bakeliste"
                            >
                              {frenchShortFormatDate(date_debut)}
                            </label>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                {/* <SweetAlert
                  show={this.state.showAlertEditSuccess}
                  onConfirm={() =>
                    this.setState({ showAlertEditSuccess: false })
                  }
                  success
                  title="Demande d'absence modifié avec succes!!!"
                  style={{
                    color: "#069A77",
                    fontSize: "12px",
                    fontFamily: "Work Sans, sans-serif",
                    paddingTop: 60,
                    paddingBottom: 40,
                  }}
                  confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                />
               
                <SweetAlert
                  show={this.state.showAlertEditError}
                  onConfirm={() =>
                    this.setState({ showAlertEditError: false })
                  }
                  error
                  title="Erreur sur la modification de votre demande, veuillez reessayer!!!"
                  style={{
                    color: "#d9534f",
                    fontSize: "12px",
                    fontFamily: "Work Sans, sans-serif",
                    paddingTop: 60,
                    paddingBottom: 40,
                  }}
                  confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                /> */}
                <div
                  className="modal fade"
                  id="editDemandeAbsence"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="editDemandeAbsenceTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content modal-content-bakeliste">
                      <h5
                        className="modal-bakeliste-title m-b-50"
                        id="editDemandeAbsenceTitle"
                      >
                        Modifier votre demande d'absense
                      </h5>
                      <button
                        type="button"
                        className="close-modal-bakeliste-title"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <FeatherIcon icon="x" aria-hidden="true" />
                      </button>
                      <form
                        encType="multipart/form-data"
                        onSubmit={(e) => updatedDemandeRedux(e)}
                      >
                        <div className="form-group">
                          Motif
                          <input
                            id="tache_id"
                            name="motif"
                            type="text"
                            className="form-control select-modal-content-bakeliste"
                            value={motif}
                            onChange={(e) => setMotif(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          Contenu
                          <textarea
                            className="form-control input-modal-content-bakeliste p-t-15"
                            rows="9"
                            // placeholder="Ajouter un commentaire"
                            // name="comment"
                            value={contenu}
                            onChange={(e) => setContenu(e.target.value)}
                            name="contenu"
                          ></textarea>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            Date de début :{frenchShortFormatDate(date_debut)}
                            <input
                              id="tache_file"
                              // value={this.state.demandeById.date_debut}
                              value={date_debut}
                              onChange={(e) => setDate_debut(e.target.value)}
                              name="date_debut"
                              type="date"
                              className="form-control select-modal-content-bakeliste"
                              placeholder="Votre livrable"
                              // min="2021-01-01"
                              // max="2021-12-31"
                            />
                          </div>
                          <div className="form-group col-md-6">
                            Date de fin :{frenchShortFormatDate(date_fin)}
                            <input
                              id="tache_file"
                              value={date_fin}
                              onChange={(e) => setDate_fin(e.target.value)}
                              name="date_debut"
                              type="date"
                              className="form-control select-modal-content-bakeliste"
                              placeholder="Votre livrable"
                            />
                          </div>
                        </div>
                        {isEditingInProgress ? (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                            disabled
                          >
                            Envoie en cours &nbsp;
                            <FeatherIcon icon="loader" aria-hidden="true" />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                          >
                            ENVOYER
                          </button>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
              <CoachItemDashboardBakeliste />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
              <BakelisteGroupItemRefact />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
              <ListCoachAssistantRefact />
            </div>
            {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
            <NextReunionItem />
          </div>
          <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
            <SavedReunionListItem />
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToprops = state =>{
  return{
    demandes:state.demandeAbsence.items
  }
}
export default connect(mapStateToprops,{getDemandeAbsenceBakeliste})(DemandeAbsenceRefact);
