import React, { useEffect, useState } from "react";
import "./groupeBakeliste.css";
import { NavLink } from "react-router-dom";
import SavedReunionListItem from "../savedReunionListItem/index";
import IconGroupWhatsapp from "../../img/icons/group-whatsapp.png";
import TableGroupeBakeliste from "../tableGroupeBakeliste/index";
import ModalContactUs from "../modalContactUs/index";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItemRefact from "../bakelisteGroupItem/BakelisteGroupItemRefact";
import NextReunionItem from "../nextReunionItem/index";
import axios from "axios";
import { Link } from "react-router-dom";
import amplitude from "amplitude-js";
import ListCoachAssistantRefact from "../listCoachAssistant/ListCoachAssistantRefact";
import * as Sentry from "@sentry/browser";
import API from "../../variablesGlobales";
import EnvoyerMonTravail from "../envoyerMonTravail/index"; 
import { useDispatch, useSelector } from "react-redux";
import { getGroupeBakeliste } from "../LivraisonRefactor/redux/actions/groupesActions";

const GroupeBakelisteRefact = () => {
  const [api] = useState(API.API);
  const [groupeId, setGroupeId] = useState(
    sessionStorage.getItem("sous_groupe_id")
  );
  const [groupeDatas, setGroupeDatas] = useState([]);
  const groupeBakeliste = useSelector((state) => state.groupesReducer);
  const [groupe, setGroupe] = useState([]);

  const dispatch = useDispatch();

  useEffect(
    (err) => {
      dispatch(getGroupeBakeliste(groupeId));
      setGroupeDatas(groupeBakeliste);
      getGroupe(groupeBakeliste.items);
    },
    [dispatch, groupeId]
  );
  const getGroupe = (groupeList) => {
    groupeList.map((item) => {
      setGroupe(item);
    });
  };

  return (
    // console.log(groupeDatas, "groupeData", groupeDatas.items),
    <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
      <div className="row">
        <div className="col-lg-9">
          <nav aria-label="breadcrumb  fixed">
            <ol className="breadcrumb fixed">
              <li className="breadcrumb-item ">
                <Link to="/dashboard-bakeliste">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Groupe
              </li>
            </ol>
          </nav>
          <div className="row m-b-15 pt3">
            <div className="col m-b-15">
              <h6 className="h-theme ff-inter fw-600">Groupe</h6>
            </div>
            <div className="col dis-flex m-b-15">
              <ModalContactUs />
            </div>
          </div>

          <div className="card-dashboard m-b-15 p-b-60">
            <div className="row m-b-30">
              <div className="col-xl-7 col-lg-6 col-md-7 col-sm-6 col-6 m-b-15 dis-flex itm-center">
                <h5 className="h-theme ff-inter fw-500">
                  {/* Groupe {groupeDatas.groupe_bakeliste.name} */}
                </h5>
              </div>
              <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
            </div>

            <TableGroupeBakeliste listeBakeliste={groupe} />
          </div>
        </div>

        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
              <CoachItemDashboardBakeliste />
            </div>
            {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
              <BakelisteGroupItemRefact />
            </div> */}
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
              <ListCoachAssistantRefact />
            </div>
            {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                <NextReunionItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <SavedReunionListItem />
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GroupeBakelisteRefact;
