import React, {Component} from 'react';
import './coachProductionsView.css';
import NavVerticalCoach from '../navVerticalCoach/index';
import HeaderDashboardCoach from '../headerDashboardCoach/index';
import CoachProductions from "../coachProductions/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as ajouterResumeViewActions from "../../store/ajouterResumeView/actions";
export default class coachProductionsView extends Component {
    constructor(props) {
        super(props);
        this.state = {}; 
    }
    render() {
      return ( 
      <div className="body-theme">
      <HeaderDashboardCoach />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalCoach />
          </div>
          <div className="col-lg-10 pt-3">
            <CoachProductions/>
          </div> 
        </div>
      </div>
    </div>) }
  }
// export default connect(
//     ({ ajouterResumeView }) => ({ ...ajouterResumeView }),
//     dispatch => bindActionCreators({ ...ajouterResumeViewActions }, dispatch)
//   )( ajouterResumeView );