import React, {Component} from 'react';
import './syllabusArchivesCoachView.css'
import HeaderDashboard from "../headerDashboard/index";

import NavVerticalCoach from "../navVerticalCoach/index";
import Preloader from "../preloader/index";

import SyllabusArchivesCoach from "../syllabusArchivesCoach/index";

import Loader from "react-loader-spinner";

import { Link } from "react-router-dom";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as syllabusArchivesCoachViewActions from "../../store/syllabusArchivesCoachView/actions";
export default class syllabusArchivesCoachView extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
        };
    }
    
  render() {
   const { loading } = this.state;

    return (
      <div className="body-theme">
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoach />
            </div>
            {this.state.loading ? (
                <div>
 <Preloader />
              </div>
            )
               
            : null}
            <div className="col-lg-10 pt-3">
             
              <SyllabusArchivesCoach/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ syllabusArchivesCoachView }) => ({ ...syllabusArchivesCoachView }),
//     dispatch => bindActionCreators({ ...syllabusArchivesCoachViewActions }, dispatch)
//   )( syllabusArchivesCoachView );