import React, { Component } from "react";
import "./itemFilActualiteBakeliste.css";
import AvatarFilActualite from "../../img/icons/fil-actualite.png";
import IconComment from "../../img/icons/comment.png";
import IconSendComment from "../../img/icons/send1.png";
import { InputGroup } from "rsuite";
import base_api_url from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import $ from "jquery";
import API from "../../variablesGlobales";
import Commments from "../commments/index";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import frenchFormatDate from "../frenchFormatDate";
import FeatherIcon from "feather-icons-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ReactPlayer from "react-player";
import FbImageLibrary from "react-fb-image-grid";

// import frenchShortFormatDate from "../frenchFormatDate";

export default class itemFilActualiteBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formatDate: frenchFormatDate.frenchFormatDate,
      // formatDate: frenchShortFormatDate.frenchShortFormatDate,

      images: [],
      commentaires: this.props.commentaires,
      api: API.API,
      liv_id: "",
      base_api_url: base_api_url.base_api_url,
      user_id: window.sessionStorage.getItem("user_id"),
      user_type: window.sessionStorage.getItem("user_status"),
      showComments: false,
      voirCommentaires: false,
      newComment: {
        comment_text: "",
        isOk: "",
        isnOk: "",
        commentable_id: 0,
        user_type: "",
        livrable_id: 0,
      },
    };
  }

  componentDidMount() {
    // this.ongetComments();
    // console.log("okkk", this.props.item);
  }

  loadComments = () => {
    this.setState({ showComments: !this.state.showComments });
  };

  addComment = (id, idl) => {
    this.setState({
      bakeliste_id: id,
      livrable_id: idl,
    });
  };

  onhandleChange = (e) => {
    e.preventDefault();
    let { newComment } = this.state;
    newComment.comment_text = e.target.value;
    this.props.fichiers.map((item, indexx) => {
      // console.log("item", item.livrais);
      if (indexx === 0) {
        this.setState({ liv_id: item.livraisons_id });
        newComment.livrable_id = item.livraisons_id;
      }
    });
    newComment.user_id = this.state.user_id;
    newComment.user_type = this.state.user_type;
    newComment.bakeliste_user_id = this.props.bakeliste_id;
    newComment.tache_content = this.props.tache_content;
  };

  addReactiononLivrable = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSubmitComment(this.state.newComment, this.props.item);
    e.target.reset();
  };
  voirComments = (e) => {
    this.setState({
      voirCommentaires: true,
    });
  };
  cacherComments = (e) => {
    this.setState({
      voirCommentaires: false,
    });
  };

  // shwfileType = (item) => {
  //   console.log("okk", this.state.base_api_url + item);
  //   if (this.state.base_api_url + item.toString().endsWith(".jpg")) {
  //     return (
  //       <div>
  //         <img
  //           src={this.state.base_api_url + item.file_path}
  //           alt="ok"
  //           loading="lazy"
  //         />{" "}
  //       </div>
  //     );
  //   }
  //   if (this.state.base_api_url + item.toString().endsWith(".jpeg")) {
  //     return (
  //       <div>
  //         <img
  //           src={this.state.base_api_url + item.file_path}
  //           alt="ok"
  //           loading="lazy"
  //         />{" "}
  //       </div>
  //     );
  //   }
  //   if (this.state.base_api_url + item.toString().endsWith(".png")) {
  //     return (
  //       <div>
  //         <img
  //           src={this.state.base_api_url + item.file_path}
  //           alt="ok"
  //           loading="lazy"
  //         />{" "}
  //       </div>
  //     );
  //   }
  //   if (this.state.base_api_url + item.toString().endsWith(".mp4")) {
  //     return (
  //       <div>
  //         <ReactPlayer
  //           width="100%"
  //           // height="276px"
  //           url={this.state.base_api_url + item.file_path}
  //           // playing={isSelected}
  //         />
  //       </div>
  //     );
  //   }
  // };
  render() {
    // const renderSlides = () =>
    //   this.props.fichiers.map(
    //     (item) => this.shwfileType(item.file_path)
    //     // <div>
    //     //   <img src={this.state.base_api_url + item.file_path} />
    //     // </div>
    //   );

    // const renderCarouselSlides = () =>
    //   this.props.fichiers.map((item) => (
    //     <div>
    //       <img
    //         src={this.state.base_api_url + item.file_path}
    //         alt="ok"
    //         loading="lazy"
    //       />
    //     </div>
    //   ));
    const files = [];

    this.props.fichiers.map((item) => {
      files.push(this.state.base_api_url + item.file_path);
    });
    return (
      <div className="card-fil-actualite ">
        <div className="row mb-15">
          <div className="col-sm-9 dis-flex itm-center mb-sm-0 mb-2">
            <img
              className="avatar-fil-actualite"
              src={AvatarFilActualite}
              alt=""
            />
            <p className="name-fil-actualite">{this.props.fullname}</p>
            {this.state.formatDate(this.props.date)}
          </div>
          <div className="col-sm-3 dis-flex itm-center">
            <p className="h-theme ff-work fs-12 fw-500 ml-sm-auto ml-5"></p>
          </div>
        </div>
        <div className="px-md-5 py-md-4 py-3 px-3">
          <Link
            className="lien-utile-bakeliste-tache-card"
            to="/liste-tache-bakeliste"
          >
            <p className="h-theme ff-work fs-14 fw-500 ml-sm-auto ml-5 mb-3">
              Tache: {this.props.tache_content}
            </p>
          </Link>
          <div>
            <div>
              <FbImageLibrary images={files} />
              {/* <Carousel>{renderCarouselSlides()}</Carousel> */}
            </div>
            {this.props.commentaires.length === 0 ?(
                <p className="pComment">Pas de commentaires!!! Soyez le premier a commenter.</p>
                ):(
           
                  <Commments comments={this.props.commentaires} />
                 
           
                )}
          </div>
        </div>

        <form onSubmit={this.addReactiononLivrable}>
          <div className="row m-t-25 m-b-5">
            <div className="px-lg-2 col-sm-2 col-3 px-md-3 px-sm-2 dis-flex itm-center justify-content-center">
              {/* <button className="btn-like-fil-actualite-card mr-xl-3 mr-lg-2 pr-sm-3 px-lg-0">
                <FeatherIcon
                  className="icon-btn-like-fil-actualite-card"
                  icon="thumbs-up"
                />
              </button>
              <button className="btn-like-fil-actualite-card px-lg-0">
                <FeatherIcon
                  className="icon-btn-like-fil-actualite-card"
                  icon="thumbs-down"
                />
              </button> */}
            </div>
            <div className="col-sm-10 col-9 pl-0 pr-2 pr-sm-2 pr-md-3">
              <InputGroup inside>
                <InputGroup.Addon>
                  <img
                    className="icon-comment-fil-actualite"
                    src={IconComment}
                    alt=""
                  />
                </InputGroup.Addon>
                <input
                  type="text"
                  placeholder="Ajouter un commentaire"
                  className="input-comment-fil-actualite"
                  name="comment"
                  onChange={this.onhandleChange}
                />
                <button
                  className="btn-send-comment-fil-actualite"
                  type="submit"
                >
                  <img
                    className="icon-btn-send-comment-fil-actualite"
                    src={IconSendComment}
                    alt=""
                  />
                </button>
              </InputGroup>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
// export default connect(
//     ({ itemFilActualite }) => ({ ...itemFilActualite }),
//     dispatch => bindActionCreators({ ...itemFilActualiteActions }, dispatch)
//   )( itemFilActualite );
