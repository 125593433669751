import React, { Component } from "react";
import "./livraisonExamenCoach.css";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import ItemFilActualite from "../itemFilActualite/index";
import axios from "axios";
import logo from "../../img/logo.png";
import prat from "../../img/pra.jpeg";
import * as Sentry from "@sentry/browser";
import API from "../../variablesGlobales";
import base_api_url from "../../variablesGlobales";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-bootstrap";

//const Loader = () => <div> </div>;
export default class livraisonExamenCoach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: sessionStorage.getItem("user_id"),
      userFullName: window.sessionStorage.getItem("userFullName"),
      api: API.API,
      base_api_url: base_api_url.base_api_url,
    };
  }

  render() {
    return (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item ">
                  <Link to="coach-dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Livraisons Examen
                </li>
              </ol>
            </nav>

            <div className="row" style={{ marginTop: "30px" }}>
              <div className="col m-b-10">
                <h6 className="h-theme ff-inter fw-600 ">Livrables</h6>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-9 col-lg-12 col-md-9">
                <div className="card-dashboard m-b-15">
                  <div className="row mb-4">
                    <div className="col-sm-8 mb-sm-0 mb-3">
                      <p className="h-theme ff-work fw-500">Examen N°1</p>
                      <p className="ff-work fs-14 fw-500 text-dark">
                        Concevoir une application mobile
                      </p>
                    </div>
                    <div className="col-sm-4 mb-sm-0 mb-3 itm-center">
                      <FeatherIcon
                        className="icon-tache-bakeliste-limit-hours"
                        icon="clock"
                      />
                      <div>
                        <p className="tache-bakeliste-limit-hours fw-300 text-up">
                          Rendu le :
                        </p>
                        <p className="tache-bakeliste-limit-hours fw-500 text-cap">
                          21/02/2020 à 10H
                        </p>
                      </div>
                    </div>
                  </div>

                  <p className="content-tache-bakeliste-view mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Amet ut a, ut sed ac ultrices nulla sem. Egestas neque, enim
                    aliquet in. Euismod nunc pulvinar ut volutpat ipsum
                    pharetra. Magnis pharetra, vehicula porttitor volutpat.
                    <br />
                    <br />
                    Sed pulvinar eget sed faucibus nibh amet, ornare diam. Id
                    mauris morbi a neque. Fermentum interdum sed proin ut
                    fringilla lobortis diam id faucibus. Mauris pulvinar
                    elementum sed mauris amet felis pulvinar eget. Arcu, aliquam
                    vitae, in nibh mollis. Non consequat, et purus etiam lectus
                    cras pulvinar suspendisse ipsum. Varius ultrices eu ipsum
                    fames lectus. Pellentesque elementum feugiat porta.
                  </p>
                  <p className="ff-work fs-12 fw-500 text-dark m-b-10 m-t-30">
                    Livraisons
                  </p>
                  {/*                   
                  <div className="row">
                    <div className="col-md-3">
                    <a href={prat}>
                      <img
                        className="card-img-top"
                        src={prat}
                        alt="Card image"
                        style={{width:"100%", height:"100%"}}
                      />
                     </a> 
    

                    </div>
                    <div className="col-md-3">
                      <img
                        className="card-img-top"
                        src={prat}
                        alt="Card image"
                        style={{width:"100%", height:"100%"}}
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        className="card-img-top"
                        src={prat}
                        alt="Card image"
                        style={{width:"100%", height:"100%"}}
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        className="card-img-top"
                        src={prat}
                        alt="Card image"
                        style={{width:"100%", height:"100%"}}
                      />
                    </div>
                    
                   
                  </div>
               */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteGroupItemForCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ livraisonCoach }) => ({ ...livraisonCoach }),
//     dispatch => bindActionCreators({ ...livraisonCoachActions }, dispatch)
//   )( livraisonCoach );
