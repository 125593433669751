import React, { Component } from "react";
import "./contentListeQuestionsCoachAssistant.css";
import ArchiveIcon from "@material-ui/icons/Archive";
import API from "../../variablesGlobales";
import { Link } from "react-router-dom";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from "react-bootstrap-table";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import Select from "react-select";
import { Icon } from "rsuite";
import { toast } from "react-toastify";
toast.configure();
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentListeQuestionsActions from "../../store/contentListeQuestions/actions";

export default class contentListeQuestionsCoachAssistant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liste_question: [],
      question: "",
      opt1: "",
      opt2: "",
      opt3: "",
      opt4: "",
      api: API.API,
      correct_reponse: "",
      point: 1,
      selected_id: 0,
      is_archived: false,
      created_by: window.sessionStorage.getItem("id"),
      is_verified: false,
      module_id: 0,
    };
  }

  onChange = (e) => {
    // console.log(e, "eeeeeee");
    this.setState({ is_verified: true });
    // console.log(e.value, this.state.is_verified);
  };
  async handleVerifiedQuestion(e, id) {
    e.preventDefault();
    const fd = new FormData();
    fd.append("is_verified", 1);
    // console.log("iddd", id);
    axios
      .post(this.state.api + "update-question-status/" + id, fd)
      .then((result) => {
        if (result.data.success) {
          this.getlistquestions();

          toast.success(
            "Question Verifiee avec succes",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1500 }
          );
        }
      })
      .catch((error) => {
        toast.error(
          "Erreur verification question",
          { position: toast.POSITION.TOP_CENTER },
          { autoClose: 1500 }
        );
      });
  }

  componentDidMount = () => {
    this.getlistquestions();
    this.getModules();
  };
  getQuestion = () => {
    axios
      .get(this.state.api + "questions/" + this.state.id)
      .then((res) => {
        const items = res.data.data;
        // console.log(res.data.data)
        this.setState({ items });
      })
      .catch((err) => console.log(err));
  };
  async getlistquestions() {
    var response = await axios.get(this.state.api + "questions");
    // console.log("okkk", this.state.api, response);

    this.setState({ liste_question: response.data.data });
  }
  async getModules() {
    const response = await axios.get(this.state.api + "domaines");
    this.setState({ selectOptions: response.data });
    const options = response.data.data.map((d) => ({
      value: d.id,
      label: d.name,
    }));
    this.setState({ selectOptions: options });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton style={{ background: "#009688" }} btnText="Export CSV" />
    );
  };

  deleteQuestions = (id) => {
    axios
      .delete(this.state.api + "questions/" + id)
      .then((res) => {
        if (res.data.success) {
          this.getlistquestions();
          toast.success(
            "Question archivé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } 
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  actions = (cell, row) => {
    // console.log(row.id);
    let id = row.id;
    return (
      <>
        <Link
          to={{
            pathname: "/modifier-question-coach",
            state: { id: id },
          }} 
        >
          <EditIcon className="" />
        </Link>
        <VisibilityIcon
          className="orange-icon"
          data-toggle="modal"
          data-target={`#exampleModalLongg${row.id}`}
        />
        <DeleteOutlineIcon className="red-icon" 
        // onClick={this.deleteQuestions(row.id)}
        onClick={() => this.deleteQuestions(row.id)}/>

        <div
          className="modal fade"
          id={`exampleModalLongg${row.id}`}
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <h5 className="h-theme ff-inter fw-600 offset-md-3">
                  Détails d'une question
                </h5>
                <div className="row">
                  <div className="col-md-12">
                    <h7 className="h-theme ff-inter fw-600 ">Question:</h7>
                    {row.libelle}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h7 className="h-theme ff-inter fw-600 ">Réponses</h7>

                    <ul>
                      <li>Réponse 1: {row.opt1}</li>
                      <li>Réponse 2: {row.opt2}</li>
                      <li>Réponse 3: {row.opt3}</li>
                      <li>Réponse 4: {row.opt4}</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <h7 className="h-theme ff-inter fw-600 ">Bonne réponse: </h7>
                    {row.bonne_reponse}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h7 className="h-theme ff-inter fw-600 ">Auteur: </h7>
                    {row.auteur_name}
                  </div>
                  <div className="col-md-6">
                    <h7 className="h-theme ff-inter fw-600 ">Domaine: </h7>
                    {row.domaine_name}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  verified = (cell, row) => {
    return (
      <>
        {row.is_verified ? (
          <span
            className="green-icon"
            // onClick={(e) => this.handleVerifiedQuestion(e, row.id)}
          >
            <DoneIcon />
          </span>
        ) : (
          <button
            className="btn-btn red-icon"
            onClick={(e) => this.handleVerifiedQuestion(e, row.id)}
          >
            <CloseIcon />
          </button>
        )}
        <div
          className="modal fade"
          id="exampleModalLong"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="">
                  <Select
                    options={this.state.optionsSelect}
                    onChange={this.onChange}
                    selected={this.state.selected}
                  />
                  <button
                    type="submit"
                    onClick={(e) => this.handleVerifiedQuestion(e, row.id)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  render() {
    const {
      question,
      opt1,
      opt2,
      opt3,
      opt4,
      correct_reponse,
      point,
      is_verified,
      module_id,
    } = this.state;
    // console.log(question);
    const options = {
      exportCSVBtn: this.createCustomExportCSVButton,
    };
    return (
      <div className="p-b-200 container-fluid px-md-3 p-0">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item ">
                  <Link to="/dashboard-coach-assistant">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Questions
                </li>
              </ol>
            </nav>
            <div className="row mt-5">
              <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
                <h6 className="h-theme ff-inter fw-600">Liste des Questions</h6>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-end">
                {/* <div>
                  <Link to="/ajout-question-coach">
                    <button className="btn-add-groupe d-flex flex-row-reverse mr-5 tope">
                      Ajouter une question{" "}
                      <i className="icon-add-groupe ml-2 rs-icon rs-icon-plus-circle"></i>
                    </button>
                  </Link>
                </div> */}
                 <Link
                    className="btn-theme col-md-6 offset-md-3  d-flex flex-row-reverse fts"
                    to={{
                      pathname: "/ajout-question-coach",
                    
                    }}
                  > 
                    Ajouter une question{" "}
                    <Icon className="icon-pointage" icon="plus-circle" />
                  </Link>
              </div>

              <div className="col dis-flex m-b-15"></div>
              <div className="component-table-my-bakelistes table-responsive-sm table-responsive-xl table-responsive-md">
                <BootstrapTable
                  striped={true}
                  hover={true}
                  options={options}
                  search
                  searchPlaceholder="Rechercher question..."
                  multiColumnSearch
                  exportCSV
                  csvFileName="mes-questions.csv"
                  multiColumnSort={2}
                  pagination 
                  bordered={true}
                  data={this.state.liste_question}
                >
                  <TableHeaderColumn
                    isKey
                    dataField="libelle"
                    filter={{ type: "TextFilter", delay: 1000 }}
                    tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  >
                    Question
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="auteur_name">
                    Auteur
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="domaine_name">
                    Domaine
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="is_verified"
                    dataFormat={this.verified}
                  >
                    Validé
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="" dataFormat={this.actions}>
                    action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>{" "}
          </div>

          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteGroupItemForCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    //   <div className="component-content-liste-questions">
    //     <div className="container-fluid">
    //       <div className="row">
    //         <div className="col-sm-12">
    //           <nav aria-label="breadcrumb">
    //             <ol className="breadcrumb">
    //               <li className="breadcrumb-item">
    //                 <Link to="/home" className="lien_nav">
    //                   Accueil
    //                 </Link>{" "}
    //               </li>
    //               <li
    //                 className="breadcrumb-item active lien_nav_current"
    //                 aria-current="page"
    //               >
    //                 Examen
    //               </li>
    //             </ol>
    //           </nav>
    //         </div>
    //       </div>
    //       <div className="row">
    //         <div className="col-xl-6 col-lg-6 col-md-6 pb-5">
    //           <h2 className="title_exam">Listes des questions</h2>
    //         </div>
    //         <div className="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-end">
    //           <div>
    //             <Link to="/ajout-question">
    //               <button className="btn-add-groupe d-flex flex-row-reverse mr-5 tope">
    //                 <a className="add-group" href="/ajout-groupe">
    //                   Ajouter une question{" "}
    //                   <i className="icon-add-groupe ml-2 rs-icon rs-icon-plus-circle"></i>
    //                 </a>
    //               </button>
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="component-table-my-bakelistes table-responsive-sm table-responsive-xl table-responsive-md">
    //         <BootstrapTable
    //           striped={true}
    //           hover={true}
    //           options={options}
    //           search
    //           searchPlaceholder="Rechercher question..."
    //           multiColumnSearch
    //           exportCSV
    //           csvFileName="mes-questions.csv"
    //           multiColumnSort={2}
    //           pagination
    //           bordered={true}
    //           data={this.state.liste_question}
    //         >
    //           <TableHeaderColumn
    //             isKey
    //             dataField="libelle"
    //             filter={{ type: "TextFilter", delay: 1000 }}
    //             tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
    //           >
    //             Question
    //           </TableHeaderColumn>
    //           <TableHeaderColumn dataField="auteur_name">
    //             Auteur
    //           </TableHeaderColumn>
    //           <TableHeaderColumn dataField="domaine_name">
    //             Domaine
    //           </TableHeaderColumn>
    //           <TableHeaderColumn
    //             dataField="is_verified"
    //             dataFormat={this.verified}
    //           >
    //             Validé
    //           </TableHeaderColumn>
    //           <TableHeaderColumn dataField="" dataFormat={this.actions}>
    //             action
    //           </TableHeaderColumn>
    //         </BootstrapTable>
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}
// export default connect(
//     ({ contentListeQuestions }) => ({ ...contentListeQuestions }),
//     dispatch => bindActionCreators({ ...contentListeQuestionsActions }, dispatch)
//   )( contentListeQuestions );
