import React, { Component } from "react";
import "./examenCoach.css";
import API from "../../variablesGlobales";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import axios from "axios";
//import API from '../../services/api';

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as examenCoachActions from "../../store/examenCoach/actions";

import ExamenItem from "../examenItem/index";
import { Link } from "react-router-dom";
import ExamenItemTheo from "../examenItemTheo/index";
import ExamenItemDisabled from "../examenItemDisabled/index";
import ExamenTheoItemDisabled from "../examenTheoItemDisabled/index";

export default class examenCoach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      examens: [],
      api: API.API,
      examensPra: [],
      examensTheo: [],
    };
   // console.log(this.state.api);
  }

  componentDidMount = () => {
    // this.getExamens();
    this.getExamensPra();
    this.getExamensTheo();
  };

  //   async getExamens() {
  //     const api= this.state.api;
  //     console.log(api)
  //     var response = await api.get("examen_theoriques");
  //     console.log('examen', this.state.api,response);
  //     this.setState({examens:response.data.data}) ;
  // }

 getExamensTheo = () => {
    axios
      .get(this.state.api + "examen_theoriques")
      .then((response) => {
        this.setState({
          examensTheo: response.data.data.reverse(),
        });
        //  console.log("theorique",this.state.examensTheo)
      })
      .catch((error) => {
        // console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  }

getExamensPra = () => {
    axios
      .get(this.state.api + "examen_pratiques")
      .then((response) => {
        this.setState({
          examensPra: response.data.data.reverse(),
        });
        // console.log("pra",this.state.examensPra)
      })
      .catch((error) => {
        // console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  }

  pratique = () => {
    window.location = "/ajout-examen-pratique";
  };
  theorique = () => {
    window.location = "/ajout-examen-theorique";
  };

  render() {
    return (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <div>
              <nav aria-label="breadcrumb fixed">
                <ol className="breadcrumb fixed">
                  <li className="breadcrumb-item ">
                    <Link to="/dashboard-coach">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Examens
                  </li>
                </ol>
              </nav>
            </div>

            <div className="row mp">
              <ul className="nav nav-pills1" role="tablist">
                <li className="nav-item1 col-md-6">
                  <a
                    className="nav-link nav-link1 active"
                    data-toggle="pill"
                    href="#theorique"
                  >
                    Examens Théoriques
                  </a>
                </li>
                <li className="nav-item1 col-md-6">
                  <a
                    className="nav-link nav-link1"
                    data-toggle="pill"
                    href="#pratique"
                  >
                    Examens Pratiques
                  </a>
                </li>
              </ul>

              <div className="col-md-5 offset-md-2"></div>
            </div>

            <div className="row m-b-20">
              <div className="col-xl-4 col-lg-5 col-md-4 col-sm-5"></div>
            </div>

            <div className="tab-content">
              <div id="theorique" className="container tab-pane active">
                <div className="col-md-6 offset-7">
                  <div className="submit-pointme-btn-container">
                    <button
                      className="btn-pointage ddr"
                      onClick={(e) => this.theorique(e)}
                    >
                      Ajouter examen théorique
                    </button>
                  </div>
                </div>

                <div className="row px-lg-9 px-md-2">
                  {this.state.examensTheo.map((item, index) =>
                    item.is_available ? (
                      <div
                        key={index}
                        className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2"
                      >
                        <ExamenItemTheo
                          name={item.libelle}
                          examen_id={item.id}
                          nbrQuestions={item.nbre_questions}
                          niveau={item.niveau}
                          user={item.username}
                          session={item.session}
                          type={item.type}
                          creer_par={item.created_by}
                          module_id={item.module_id}
                          date_examen={item.date}
                          getExamensTheo={this.getExamensTheo}
                        />
                      </div>
                    ) : ( 
                      <div
                        key={index}
                        className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2"
                      >
                        <ExamenTheoItemDisabled
                          name={item.libelle}
                          examen_id={item.id}
                          nbrQuestions={item.nbre_questions}
                          niveau={item.niveau}
                          user={item.username}
                          session={item.session}
                          type={item.type}
                          creer_par={item.created_by}
                          module_id={item.module_id}
                          date_examen={item.date}
                          getExamensTheo={this.getExamensTheo}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
              
              <div id="pratique" className="container tab-pane ">
                <div className="submit-pointme-btn-container off ">
                  <div className="col-md-6 offset-7">
                    <button
                      className="btn-pointage ddr"
                      onClick={(e) => this.pratique(e)}
                    >
                      Ajouter examen pratique
                    </button>
                  </div>
                </div>

                <div className="row px-lg-9 px-md-2">
                  {this.state.examensPra.map((item, index) =>
                    item.is_available ? (
                      <div
                        key={index}
                        className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2"
                      >
                        <ExamenItem
                          ennonce={item.enonce}
                          name={item.titre}
                          examen_id={item.id}
                          niveau={item.niveau}
                          session={item.session}
                          created_by={item.created_by}
                          module_id={item.module_id}
                          date_examen={item.date}
                          taf={item.taf}
                          duree={item.duree}
                          getExamensPra={this.getExamensPra}
                        />
                      </div>
                    ) : (
                      <div
                        key={index}
                        className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2"
                      >
                        <ExamenItemDisabled
                          name={item.libelle}
                          examen_id={item.id}
                          nbrQuestions={item.nbre_questions}
                          niveau={item.niveau}
                          session={item.session}
                          type={item.type}
                          created_by={item.created_by}
                          module_id={item.module_id}
                          date_examen={item.date}
                          getExamensPra={this.getExamensPra}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteGroupItemForCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default connect(
//     ({ examenCoach }) => ({ ...examenCoach }),
//     dispatch => bindActionCreators({ ...examenCoachActions }, dispatch)
//   )( examenCoach );
