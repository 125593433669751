import React, {Component} from 'react';
import './bakelisteGroupeItemForCoachAssistant.css';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import IconGroup from '../../img/icons/people-icon.png';
import ModalBakelisteDetails from '../modalBakelisteDetails/index';
import axios from "axios";
import {NavLink} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import { Loader } from "react-loader-spinner";



var bakelisteGroupeItemForCoach = [{
  id: 1
},{
  id: 2
},{
  id: 3
},{
  id: 4
},{
  id: 5
}];

class Status extends React.Component {
 /*  render() {
    return (
/*       <ModalBakelisteDetails/>
    );
  } */
}



function changeStatus(cell, row) { 
  return (
    <Status active={ cell } />
  );
}
//const Loader = () => <div>Loading...</div>;
export default class bakelisteGroupeItemForCoachAssistant extends Component {
    constructor(props) {
        super(props);
        this.state = {
          list: [],
          liste:[],
          sous_groupes: [],
          api:API.API,
          sgroupeInfos: [],
          userID: window.sessionStorage.getItem("user_id"),
          sgId:"",
          bakelistes:[],
          loading: false,
          coach_id:this.props.id,
        };
    }
    componentDidMount=()=>{
        this.__getSousGroupes();
        this.getBakelisteBySousGroupes();
    }
    hideLoader = () => {
      this.setState({ loading: false });
    }
  
    showLoader = () => {
      this.setState({ loading: true });
    }

    __getSousGroupes = () => {

    const _this = this;
    this.showLoader();
      axios
        .get(this.state.api + "sous__groupes/coach/" + this.state.coach_id)
        .then((res) => {
          this.setState({
            loading: false,
          });
          if (res.data.success) {
            this.setState({
              sous_groupes: res.data.data.reverse(),
            });
          }
          this.state.sous_groupes.slice(0, 5).map((i)=> {
            let items = {
              id: i.id,
              value: i.name,
              groupe_id: i.groupe_id,
              bak:i.sous_groupes_bakelistes
            };
            this.state.list.push(items);
          });
          //_this.hideLoader();
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
          //_this.hideLoader();
        }); 
    };

    actionsFormatterSousGroup(cell, row){
      return (
        <span>
            {/* <a  href={"/sous-groupe/"+this.state.sgId} >             */}
            <FeatherIcon
           

            icon="users"
              onClick={() => {
                this.onGetSousGroupeInfos(row);
                this.getBakelisteBySousGroupes();
              } }
              cursor="pointer"
              data-toggle="modal"
              data-target="#voirListeBakeliseModale"
            /> 
            {/* </a> */}
        </span>  
      );
    }
    onGetSousGroupeInfos(sous_groupes) {
      this.setState({
        sgroupeInfos: sous_groupes,
        sgId: sous_groupes.id,
        name:sous_groupes.value,

      });
    } 
    getBakelisteBySousGroupes = () => {

    const _this = this;
    this.showLoader();
   axios
        .get(this.state.api + "sous__groupes/" + this.state.sgId  )
           
        .then((response) => {
          this.setState({
            loading: false,
          });
          if (response.data.success) {
            this.setState({
              bakelistes: response.data.liste[0].reverse(),
            });
          }
         // _this.hideLoader();
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
          //_this.hideLoader();
        });
    }
    render() {
      return  <div className="card-dashboard m-b-15">
                
                  <p className="h-card-dashboard-bakeliste"><img className="icon-h-card-dashboard-bakeliste" src={IconGroup} alt="" />
                    {this.state.sous_groupes.length} Sous-groupes
                  </p> 
               
                <BootstrapTable data={this.state.list} version='4' bordered={ false } bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }}>
                      {/* <TableHeaderColumn isKey dataField='id' thStyle={{ 'border': 'none' }} tdStyle={{ 'border': 'none', 'paddingLeft': '0', 'paddingRight': '0', 'width': '20px' }}></TableHeaderColumn> */}
                      <TableHeaderColumn 
                      isKey
                        dataField='value'  
                        thStyle={{ 'border': 'none' }} 
                        tdStyle={{ 'border': 'none', 'paddingLeft': 0 }}
                        // dataFormat={this.actionsFormatterSousGroup.bind(this)} 
                      ></TableHeaderColumn>
                      <TableHeaderColumn 
                        dataFormat={this.actionsFormatterSousGroup.bind(this)} 
                        dataField='id'  
                        thStyle={{ 'border': 'none' }} 
                        tdStyle={{ 'border': 'none', 'paddingLeft': 0 }}>
                      </TableHeaderColumn>
                </BootstrapTable>
                <NavLink to={"/groupe-coach"} >  
                  <p style={{color:"#069A77"}}>
                     Voir plus
                  </p> 
                </NavLink>
                 {/* voire les bakeliste dans un sous-groupe*/}
                
                
                 <div
                  className="modal fade"
                  id="voirListeBakeliseModale"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="addLivraisonBakelisteTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content modal-content-bakeliste">
                      <h5
                        className="modal-bakeliste-title m-b-50"
                        id="addLivraisonBakelisteTitle"
                      >
                    Liste des bakelistes dans {this.state.name}
                      </h5>
                      <button
                        type="button"
                        className="close-modal-bakeliste-title"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <FeatherIcon icon="x" aria-hidden="true" />
                      </button>
                      
                        <div className="modal-body">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Prenom</th>
                                <th scope="col">Nom</th>
                                <th scope="col">Email</th>
                              </tr>
                            </thead>
                            <tbody> 
                            {this.state.bakelistes.map((item, index) => ( 
                              <tr  key={index}>
                                <td>{item.first_name}</td>
                                <td>{item.last_name}</td>
                                <td>{item.email}</td>
                              </tr> 
                                ))} 
                            </tbody>
                          </table>
                        </div>
           
                    </div>
                  </div>
                </div>
           
              </div>; 
    }
  }
// export default connect(
//     ({ bakelisteGroupItemForCoach }) => ({ ...bakelisteGroupItemForCoach }),
//     dispatch => bindActionCreators({ ...bakelisteGroupItemForCoachActions }, dispatch)
//   )( bakelisteGroupItemForCoach );
//{`/sous-groupe/${this.state.liste.groupe_id}`}
//                    <NavLink to={"/sous-groupe/"} >                    </NavLink>

