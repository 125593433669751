import React, {Component} from 'react';
import './detailsCoachAssistantExamen.css';
import FeatherIcon from 'feather-icons-react';
// import ExamenItemQuestion from '../examenItemQuestion/index';
import AddQuestionExamenCoachAssistant from '../addQuestionExamenCoachAssistant/index';

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as detailsExamenActions from "../../store/detailsExamen/actions";
export default class detailsCoachAssistantExamen extends Component {
    constructor(props) {
        super(props);
        this.state = {
          liste_questions: this.props.liste,
        };
        // console.log("liste",this.props.liste)
        // console.log("liste",this.state.id)

    }
    render() {
   // console.log("ééééééé",this.props.id);
   
 
      let comp = this.props.id;
      let nomEx =  this.props.nom; 
      return  <div>
                <div className="card-dashboard mb-4 mto-3">
                  <div className="row m-b-30">
                    <div className="col-md-8">
                      <p className="titre-syllabus-details-card mb-3">
                        {this.props.nom}
                        </p>
                      <span
                className={
                  "eetiquette-syllabus-details " +
                  (this.props.level === "débutant"
                    ? " etiquette-syllabus-details debutant mb-2"
                    : this.props.level === "intermédiaire"
                    ? "etiquette-syllabus-details intermediaire mb-2"
                    : this.props.level === "avancé"
                    ? "etiquette-syllabus-details avance mb-2"
                    : "")
                }
              >
                {this.props.level} 
                 </span>                    
                      {/* Etiquette pour confirmé  */}
                      {/* <span className="etiquette-syllabus-details confirme mb-2">confirmé</span> */}


                      <div className="row mb-4">
                        <div className="col-xl-3 col-lg-4 pr-lg-0 col-md-3 pr-md-0 col-sm-3 col-6">
                          <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="list"/> {this.props.questions} Questions</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 pl-xl-0 px-lg-0 col-md-3 col-6 px-0">
                          <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="clock"/>Session {this.props.session}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 pl-xl-0 px-lg-0 col-md-3 col-6 px-0">
                          {/* <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="clock"/>Examen {this.props.type}</p> */}
                        </div>
                      </div> 
                    </div>
                    <div className="col-md-4 dis-flex">
                      <AddQuestionExamenCoachAssistant
                      exam = {comp} 
                      libelle = {nomEx}
                      />
                    </div> 
                  </div>
                </div>
              </div>;
    }
  }

// export default connect(
//     ({ detailsExamen }) => ({ ...detailsExamen }),
//     dispatch => bindActionCreators({ ...detailsExamenActions }, dispatch)
//   )( detailsExamen );
