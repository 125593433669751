import React, { Component } from "react";
import "./navVerticalCoachAssistant.css";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

export default class navVerticalCoachAssistant extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container-nav-vertical no-view-tablette">
        <div
          className="nav flex-column nav-pills"
          id="v-pills-tabCustom"
          role="tablist"
          aria-orientation="vertical"
        >
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/dashboard-coach-assistant"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="layout" />
            Dashboard
          </NavLink>
          {/* <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/coach-bakelistes"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Bakelistes
          </NavLink> */} 
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-bakelistes-coach-assistant"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="book" />
            Bakelistes
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/syllabus-coach-assistant"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="book" />
            Syllabus
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/taches-coach-assistant"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="grid" />
            Tâches
          </NavLink>
          {/* <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/groupe-coach-assistant"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Groupes
          </NavLink> */}
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/sous-groupes-coach-assistant"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Sous Groupes
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-questions-coach"
          >
            <FeatherIcon
              className="icon-side-navbar-custom"
              icon="help-circle"
            />
            Questions
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/examens-coach-assistant"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="file-text" />
            Examens
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/livraison-coach-assistant"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="folder" />
            Livraisons
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-contenus-coach-assistant"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="settings" />
            Gestion des contenus
          </NavLink>
          {/* 
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/groupe-coach"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Groupes
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/sous-groupes-coach"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Sous Groupes
          </NavLink>
         
       
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-questions"
          >
            <FeatherIcon
              className="icon-side-navbar-custom"
              icon="help-circle"
            />
            Questions
          </NavLink> */}

          {/*  <NavLink className="nav-link nav-link-side-navbar-custom bg-warning text-dark" to="/notification-exemples">
                    <FeatherIcon className="icon-side-navbar-custom" icon="bell" />
                    Notifications (Exemples)
                  </NavLink> */}
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ navVerticalCoach }) => ({ ...navVerticalCoach }),
//     dispatch => bindActionCreators({ ...navVerticalCoachActions }, dispatch)
//   )( navVerticalCoach );
