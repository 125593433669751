import React, { Component } from "react";
import "./modalContactUs.css";
import { Modal } from "rsuite";
import API from "../../variablesGlobales";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import $ from "jquery";
//import {  toast } from 'react-toastify';
import SweetAlert from "react-bootstrap-sweetalert";

export default class modalContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      userID: window.sessionStorage.getItem("user_id"),
      isSendingAideInProgress: false,
      isSuccessSending: false,
      isErrorSending: false,
      api: API.API,
      fields: {},
      showAlertSuccess: false,
      showAlertError: false,
    };
    Sentry.init({
      dsn:
        "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
    });
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.onSubmitAide = this.onSubmitAide.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  close() {
    this.setState({
      show: false,
    });
  }
  open(size) {
    this.setState({
      size,
      show: true,
    });
  }

  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  onSubmitAide = (e) => {
    var api = this.state.api;
    var data = this.state.fields;
    var fd = new FormData();
    fd.append("user_id", this.state.userID);
    fd.append("email", data.email);
    fd.append("content", data.content);
    axios
      .post(api + "request_helps", fd, {
        Headers: { "Content-Type": "multipart/formdata" },
      })
      .then((response) => {
        this.setState({});
        if (response.data.success) {
          $("#email").val("");
          $("#content").val("");
          $(".rs-modal-header-close").click();

          this.setState({
            isSuccessSending: true,
            isErrorSending: false,
          });
          setTimeout(() => {
            this.setState({
              isSuccessSending: false,
            });
          }, 6000);
        } else {
          this.setState({
            isErrorSending: true,
            isSuccessSending: false,
          });
          this.handleShowAndHideAlert("errorSendingAide");
        }
      })
      .catch((error) => {
        // console.log(error.message);
        if ((error.message = "Request failed with status code 422")) {
        }
        this.setState({
          isErrorSending: true,
          isSendingAideInProgress: false,
        });

        this.handleShowAndHideAlert("errorSendingAide");
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);

        setTimeout(() => {
          this.setState({
            isErrorSending: false,
          });
        }, 6000);
      });
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["bakeliste_id"] = this.state.userID;
    if (e.target.name === "message") {
      var chars = e.target.value.length;
      var remaining_char = 245 - chars;
      this.setState({
        remaining_char: remaining_char,
      });
    }
    this.setState({
      fields: fields,
    });

    //console.log(fields);
  };
 
  render() {
    return (
      <div className="component-modal-contact-us">
        <button className="link-aide" size="md" onClick={() => this.open("md")}>
          Besoin d’aide ?
        </button>
        <SweetAlert
          show={this.state.isSuccessSending}
          onConfirm={() => this.setState({ isSuccessSending: false })}
          success
          title="Demande envoyée avec succés"
          style={{
            color: "#069A77",
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            paddingTop: 60,
            paddingBottom: 40,
          }}
          confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
        />
        <SweetAlert
          show={this.state.isErrorSending}
          onConfirm={() => this.setState({ isErrorSending: true })}
          error
          title="Erreur sur l'envoie de demande!!!"
          style={{
            color: "#069A77",
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            paddingTop: 60,
            paddingBottom: 40,
          }}
          confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
        />
        <Modal
          className="modal-responsive-md"
          size={this.state.size}
          show={this.state.show}
          onHide={this.close}
        >
          <Modal.Header>
            <Modal.Title className="modal-contact-us">
              Contacter le support
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="max-height-modal">
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control input-contact-us"
                placeholder="Adresse mail"
                required
                onChange={this.handleChange}
              ></input>
            </div>
            <div className="form-group">
              <textarea
                name="content"
                className="form-control input-contact-us pt-3 pb-3"
                rows="12"
                placeholder="Votre message"
                required
                onChange={this.handleChange}
              ></textarea>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row dis-flex justify-content-end">
              <div className="col-md-5 col-sm-6">
                <button
                  className="btn-theme text-up w-full"
                  onClick={(e) => this.onSubmitAide(e)} /* {this.open} */
                >
                  Envoyer
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
// export default connect(
//     ({ modalContactUs }) => ({ ...modalContactUs }),
//     dispatch => bindActionCreators({ ...modalContactUsActions }, dispatch)
//   )( modalContactUs );
