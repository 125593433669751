import React, { Component } from "react";
import "./dashboardCoachAssistant.css";
import StatisticsCoach from "../statisticsCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import TableMyBakelistesCoachAssistant from "../tableMyBakelistesCoachAssistant/index";
import axios from "axios";
import Loader from "react-loader-spinner";
import $ from "jquery";
import Preloader from "../preloader/index";
import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import BakelisteGroupeItemForCoachAssistant from "../bakelisteGroupeItemForCoachAssistant/index";
import LivraisonCoachAssistant from "../livraisonCoachAssistant/livraisonCoachAssistant";
import GrapheLivraisons from "../grapheLivraisons/grapheLivraisons";
import GrapheCommentaires from "../grapheCommentaires/grapheCommentaires";
import GrapheContenu from "../grapheContenu/grapheContenu";
import GrapheTaskValidated from "../grapheTaskValidated/grapheTaskValidated";

// const Loader = () => <div></div>;
export default class dashboardCoachAssistant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      sous_groupes: [],
      api: API.API,
      userID: window.sessionStorage.getItem("user_id"),
      token: window.sessionStorage.getItem("token"),
      email: window.sessionStorage.getItem("email"),
      loading: true,
      detailCoach: [],
      coach_id: "",
      isOpen: false,
    };
  }
  componentDidMount = () => {
    var eventProperties = {
      email: this.state.email,
      user_id: this.state.userID,
    };
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude
      .getInstance()
      .logEvent("VISITED_DASHBOARD_COACH_ASSISTANT_V2", eventProperties);
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 5000);
  };

  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };
  // hideLoader = () => {
  //   this.setState({ loading: false });
  // };

  // showLoader = () => {
  //   this.setState({ loading: true });
  //};
  // getDetailsCoachById = () => {
  //   axios
  //     .get(this.state.api + "get-coach-by-id/" + this.state.userID)
  //     .then((res) => {
  //       //console.log(res)

  //       if (res.data.success) {
  //         this.setState({
  //           detailCoach: res.data.data,
  //           coach_id: res.data.data.coach_id,
  //         });
  //         //  console.log(this.state.detailCoach, this.state.coach_id)
  //       }
  //     })
  //     .catch((error) => {
  //       if (process.env.NODE_ENV !== "production") {
  //         return;
  //       }
  //       Sentry.captureException(error);
  //     });
  // };

  toggle() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }
  __getSousGroupes = () => {
    // const _this = this;
    // this.showLoader();
    axios
      .get(this.state.api + "sous__groupes/coach/" + this.state.coach_id)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            sous_groupes: res.data.data.reverse(),
          });
        }
        this.state.sous_groupes.slice(0, 5).map((i) => {
          let items = {
            id: i.id,
            value: i.name,
            groupe_id: i.groupe_id,
          };
          this.state.list.push(items);
        });
        // _this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        // _this.hideLoader();
      });
  };
  render() {
    return (
      <Amplitude>
        {this.state.loading ? (
          <div>
            <Preloader />
          </div>
        ) : (
          <div className="p-b-200">
            <div className="row">
              <div className="col-lg-9">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </nav>
                <div className="row m-b-15">
                  <div className="col m-b-15">
                    <h6 className="h-theme ff-inter fw-600">Chiffres</h6>
                  </div>
                  <div className="col dis-flex m-b-15"></div>
                </div>
                <StatisticsCoach />

                <div class="box" onClick={(e) => this.toggle(e)}>
                  <button class="button-graphe ">
                    <span>
                      {this.state.isOpen
                        ? "Cacher Statistique"
                        : "Afficher Statistique"}
                    </span>
                  </button>
                </div>
                {this.state.isOpen && (
                  <>
                    <GrapheContenu /> <GrapheLivraisons />{" "}
                    <GrapheCommentaires />
                    <GrapheTaskValidated />
                  </>
                )}

                <div className=" m-b-15 p-b-60">
                  <div className="col-md-6">
                    <h6 className="h-theme ff-inter fw-600 mb-3">
                      Livraisons des Bakelistes
                    </h6>
                  </div>
                  {/* <div className="col-xl-7 col-lg-6 col-md-7 col-sm-6 col-6 m-b-15 dis-flex itm-center">
                      <h5 className="h-theme ff-inter fw-500">
                        Mes bakelistes
                      </h5>
                    </div> */}
                  {/* <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
                  </div>
                  {this.state.loading ? (
                    <div className="col-md-3 offset-md-5">
                      <Loader
                        type="ThreeDots"
                        color="#009688"
                        height={700}
                        width={150}
                        timeout={4000}
                      />{" "}
                    </div>
                  ) : (
                    <>
                      <TableMyBakelistesCoachAssistant
                        id={this.state.coach_id}
                      />
                    </>
                  )} */}
                  <LivraisonCoachAssistant />{" "}
                </div>
              </div>
              <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                <div className="row">
                  <div className="col-lg-12 col-md-6 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                    <BakelisteGroupeItemForCoachAssistant
                      id={this.state.coach_id}
                    />
                  </div>
                  {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </Amplitude>
    );
  }
}
// export default connect(
//     ({ dashboardCoach }) => ({ ...dashboardCoach }),
//     dispatch => bindActionCreators({ ...dashboardCoachActions }, dispatch)
//   )( dashboardCoach );
