import React, {Component} from 'react';
import profilb from '../../image/profilb.png';
// import Ellipse43 from '../../image/imagelogin.png';
// import axios from 'axios';
// import api from '../../services/api';
import axios from "axios";
import * as Sentry from "@sentry/react";

import API from "../../variablesGlobales";
import './listeBakelisteForExamen.css';

export default class listeBakelisteForExamen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            liste_users: [],
            api:API.API,
            Bakeliste: [],
            coach_id: window.sessionStorage.getItem("user_id"),

        };
        
    }
    componentDidMount = () => {
        //  const response =  api.get("api/users")
        // console.log('okkk',api,response)
       // this.getlist();
    this.getBakelisteByCoach();


    }
//     async getlist(){
//       let api = this.state.api;
//         var response = await api.get("users");
//         this.setState({liste_users:response.data})      
// }

getBakelisteByCoach() {
    const _this = this;
    
    axios
      .get(this.state.api + "get_score_bakelistes/" + this.state.coach_id)
      .then((response) => {
        if (response.data.data !== undefined) {
          this.setState({
            Bakeliste: response.data.data,
          });
          //console.log(this.state.Bakeliste)
          this.state.Bakeliste.forEach((item) => {
          
            this.state.datas.push(item.sous_groupes);
          });
        } else {
          this.setState({
            Bakeliste: 0,
          });
        }
       // _this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
       // _this.hideLoader();
      });
  }

  
    render() {
      return (
      <div className="component-liste-des-bakeliste-for-exam px-0 bg-white">
          <div className="container-fluid">
              <div className="row">
                  <div>
                      <h3 className="liste-bakeliste-exam p-3">Liste de Bakeliste des examens à venir</h3>
                  </div>
              </div>
              <div >
                <ul className="list-group">
                    {this.state.Bakeliste.map((user,index)=>(
                    <li className="list-group-item liste-costom"><img src={profilb} className="imglistebakeliste" /><span className="nom-bakeliste pl-3">{user.name} </span> </li>

                    ))}
                    
                </ul>
              </div>
          </div>
      </div>
      );
    }
  }
// export default connect(
//     ({ listeDesBakelisteForExam }) => ({ ...listeDesBakelisteForExam }),
//     dispatch => bindActionCreators({ ...listeDesBakelisteForExamActions }, dispatch)
//   )( listeDesBakelisteForExam );
