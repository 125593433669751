import React, { Component } from "react";
import "./contentComments.css";
import frenchFormatDate from "../frenchFormatDate";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentCommentsActions from "../../store/contentComments/actions";
export default class contentComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frenchFormat: frenchFormatDate.frenchFormatDate,
      commentaires: this.props.comments,
      showReplyForm: false,
      user_id: window.sessionStorage.getItem("user_id"),
      user_type: window.sessionStorage.getItem("user_status"),
    };
  }
  render() {
    return (
      <div className="component-content-comments">
        <div className="row">
          <div className="col-md-12 mt-5">
            <div className="blog-comment">
              <h5> {this.state.commentaires.length} &nbsp; Commentaires</h5>

              <div className="row">
                <div className="col-md-8">
                  <ul className="comments">
                    {this.state.commentaires.map((i, ix) => (
                      <li className="clearfix">
                        <div className="post-comments mt-3">
                          {i.user_fullname}
                          <i className="p-3">
                            {i.user_type === "bakeliste" ? (
                              <small className="bakeliste">{i.user_type}</small>
                            ) : (
                              <small className="coach">Coach</small>
                            )}
                          </i>
                          <span className="meta pull-right">
                            {this.state.frenchFormat(i.created_at)}{" "}
                          </span>
                          <p className="comment mt-3">{i.comment_text}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}
// export default connect(
//     ({ contentComments }) => ({ ...contentComments }),
//     dispatch => bindActionCreators({ ...contentCommentsActions }, dispatch)
//   )( contentComments );
