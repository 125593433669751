import React, { Component } from "react";
import "./examenItemTheo.css";
import Theorique from "../../img/theorique.jpeg";
import { NavLink, Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import API from "../../variablesGlobales";
import { toast } from "react-toastify";

// import * as Sentry from "@sentry/browser";
export default class examenItemTheo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      activeSuccess: false,
    };
  }

  desactiverExamen = (e) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    };
    axios
      .post(
        this.state.api + "desactiver-examen/" + this.props.examen_id,
        headers
      )
      .then((res) => {
        // console.log(res);
        if (res.data.success) {
          toast.success(
            "Examen desactive avec succes",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 500 }
          );
          this.props.getExamensTheo();
          this.setState({ activeSuccess: true });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  
  render() {
    let id = this.props.examen_id;
    return (
      <div className="container-syllabus-item">
        <div className="syllabus-item">
          {/* <p className="etiquette-syllabus-item debutant">{this.props.niveau}</p> */}
          {/* Etiquette pour debutant  */}
          <p /* className="etiquette-syllabus-item " */
            className={
              "etiquette-syllabus-item " +
              (this.props.niveau === "débutant"
                ? " bg-debutant"
                : this.props.niveau === "intermédiaire"
                ? "bg-intermediaire"
                : this.props.niveau === "avancé"
                ? "bg-avance"
                : "")
            }
          >
            {this.props.niveau}
          </p>
          {/* <p
                    className="etiquette-syllabus-item " 
                   >   
                     {this.props.niveau} 
                  </p> */}
          <img
            src={Theorique}
            alt="Avatar"
            className="image-syllabus-item"
            style={{ width: "100%" }}
          />
          <div className="content-syllabus-item">
            <p className="titre-content-syllabus-item">{this.props.name}</p>
            <div className="row">
              {/* <div className="col-xl-4 col-lg-5 pr-lg-0 col-6 pr-0">
                <p className="info-content-syllabus-item">
                  <FeatherIcon
                    className="icon-content-syllabus-item"
                    icon="list"
                  />
                {this.props.nbrQuestions} &nbsp; Questions 
                </p>
              </div> */}
              <div className="col-xl-6 pl-xl-0 col-6 pr-0">
                <p className="info-content-syllabus-item">
                  <FeatherIcon
                    className="icon-content-syllabus-item"
                    icon="clock"
                  />
                  Session &nbsp;
                  {this.props.session}
                </p>
              </div>
              <div className="col-xl-6 pl-xl-0 col-6 pr-0">
              {this.props.creer_par !== 1 && (
                <p className="info-content-syllabus-item">
                  <FeatherIcon
                    className="icon-content-syllabus-item"
                    icon="user"
                  />{" "}
                Auteur:{this.props.user} 
                  
                </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container-syllabus-item-back">
          <Link
            className="link-details-container-syllabus-item-back"
            to={{
              pathname: "/details-examens-theorique",
              state: { id: id },
            }}
          >
            <div className="height-container-syllabus-item-back"></div>
          </Link>
          <div className="">
            <div className="row justify-content-end position-action-container-syllabus-item-back">
              <div className="col-xl-4 col-lg-5 col-md-5 pl-lg-3 pl-md-0 col-sm-6">
                <Link
                  className="link-details-container-syllabus-item-back"
                  to={{
                    pathname: "/details-examens-theorique",
                    state: { id: id },
                  }}
                >
                  <FeatherIcon
                    className="icon-details-container-syllabus-item-back"
                    icon="eye"
                  />
                  Details
                </Link>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5 pl-lg-3 pl-md-0 col-sm-6 link-details-container-syllabus-item-back">
                <button
                  onClick={(e) => this.desactiverExamen(e)}
                  className="transp"
                >
                  <FeatherIcon
                    className="icon-details-container-syllabus-item-back"
                    icon="x-circle"
                    type="button"
                    aria-hidden="true"
                  />
                  Desactiver
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default connect(
//     ({ examenItem }) => ({ ...examenItem }),
//     dispatch => bindActionCreators({ ...examenItemActions }, dispatch)
//   )( examenItem );
