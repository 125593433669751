import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Connexion from "./components/connexion/index";
import DashboardBakelisteView from "./components/dashboardBakelisteView/index";
import TacheBakelisteView from "./components/tacheBakelisteView/index";
import LivrableBakelisteView from "./components/livrableBakelisteView";
import GroupeBakelisteView from "./components/groupeBakelisteView/index";
import DashboardCoachView from "./components/dashboardCoachView/index";
import TacheCoachView from "./components/tacheCoachView/index";
import LivrableCoachView from "./components/livrableCoachView";
import GroupeCoachView from "./components/groupeCoachView/index";
import SyllabusCoachView from "./components/syllabusCoachView/index";
//import SyllabusDetailsCoachView2 from "./components/syllabusDetailsCoachView2/index";
import SyllabusBakelisteViewRefact from "./components/syllabusBakelisteView/SyllabusBakelisteViewRefact";
import NotificationExemples from "./components/notificationPage/index";
import AccountBakelisteView from "./components/accountBakelisteView/index";
import AccountCoachView from "./components/accountCoachView/index";
import SyllabusDetailsBakelisteViewRefact from "./components/syllabusDetailsBakelisteView/SyllabusDetailsBakelisteViewRefact";
import AjoutTacheSyllabus from "./components/ajoutTacheSyllabus/index";
import ri from "./components/ri/index";
import BakelisteBySyllabus from "./components/bakelisteBySyllabus/index";
import AjoutGroupeCoach from "./components/ajoutGroupeCoach/index";
import SousGroupeCoach from "./components/sousGroupeCoach/index";
import SousGroupeCoachAssistant from "./components/sousGroupeCoachAssistant/index";

import AjoutSousGroupe from "./components/ajoutSousGroupe/index";
import withClearCache from "./ClearCache";
import GroupeArchived from "./components/groupeArchived/index";
import SousGroupeArchived from "./components/sousGroupeArchived/index";
import listeTachesCoachView from "./components/listeTachesCoachView/index";
import ListeTachesCoachAssistantView from "./components/listeTachesCoachAssistantView/index";

import ListeBakelistesCoachView from "./components/listeBakelistesCoachView/index";
import ListeSousGroupesCoachView from "./components/listeSousGroupesCoachView/index";
//import SyllabusArchivesCoach from "./components/syllabusArchivesCoach/index";
import SyllabusArchivesCoachView from "./components/syllabusArchivesCoachView/index";
import SyllabusDetailsCoachView2 from "./components/syllabusDetailsCoachView2/index";
import FicheBakeliste from "./components/ficheBakeliste/index";
import FicheBakelisteCoachAssistant from "./components/ficheBakelisteCoachAssistant/index";
import InformationSousGroupe from "./components/informationSousGroupe/index";
import InformationSousGroupeCoach from "./components/informationSousGroupeCoach/index";

import DetailSousGroupe from "./components/detailSousGroupe/index";
import ListeTacheBakelisteView from "./components/listeTacheBakelisteView/index";
import DemandeAbsenceView from "./components/demandeAbsenceView/index";
import ViewListeQuestions from "./components/viewListeQuestions/index";
import ViewListeQuestionsCoachAssistant from "./components/viewListeQuestionsCoachAssistant/index";

import ViewQuestions from "./components/viewQuestions/viewQuestions";
import ViewQuestionsCoachAssistant from "./components/viewQuestionsCoachAssistant/index";

import ViewEditQuestion from "./components/viewEditQuestion/index";
import FaireExamenView from "./components/faireExamenView/index";
import ExamenCoachView from "./components/examenCoachView/index";
import ExamenCoachAssistantView from "./components/examenCoachAssistantView/index";

import DetailsExamenCoachView from "./components/detailsExamenCoachView/index";

import AjoutQuestionCoach from "./components/ajoutQuestionCoach/index";
import AjoutQuestionCoachAssistant from "./components/ajoutQuestionCoachAssistant/index";
import AjoutExamenPratique from "./components/ajoutExamenPratique/index";
import AjoutExamenPratiqueCoachAssistant from "./components/ajoutExamenPratiqueCoachAssistant/index";

import ResultatExamenTheorique from "./components/resultatExamenTheorique/index";
import AjoutExamTheorique from "./components/ajoutExamenTheorique/index";
import AjoutExamTheoriqueCoachAssistant from "./components/ajoutExamTheoriqueCoachAssistant/index";
import AjouterResumeView from "./components/ajouterResumeView/index";
import DetailsExamenPratiqueView from "./components/detailExamenPratiqueView/index";
import NoteExamenView from "./components/noteExamenView/index";
import ContentExamenBakelisteView from "./components/contentExamenBakelisteView/contentExamenBakelisteView";
import ListeCoachView from "./components/listeCoachView/index";
import ajoutCoachAssistant from "./components/ajoutCoachAssistant/index";
import LivraisonExamenCoachView from "./components/livraisonExamenCoachView/index";
import DashboardCoachAssistantView from "./components/dashboardCoachAssistantView/index";
import SyllabusCoachAssistantView from "./components/syllabusCoachAssistantView/index";
import SyllabusDetailsCoachAssistantView2 from "./components/syllabusDetailsCoachAssistantView2/index";
import AjoutTacheSyllabusCoachAssistant from "./components/ajoutTacheSyllabusCoachAssistant/index";
import GroupeCoachAssistantView from "./components/groupeCoachAssistantView/groupeCoachAssistantView";
import ListeSousGroupesCoachAssistantView from "./components/listeSousGroupesCoachAssistantView/index";
import AjoutGroupeCoachAssistant from "./components/ajoutGroupeCoachAssistant/ajoutGroupeCoachAssistant";
import AjoutSousGroupeCoachAssistant from "./components/ajoutSousGroupeCoachAssistant/ajoutSousGroupeCoachAssistant";
import DetailsExamenCoachAssistantView from "./components/detailsExamenCoachAssistantView/index";
import DetailExamenPratiqueCoachAssistantView from "./components/detailExamenPratiqueCoachAssistantView/index";
import LivrableCoachAssistantView from "./components/livrableCoachAssistantView/index";
import ViewEditQuestionCoach from "./components/viewEditQuestionCoach/index";
import RessetPassword from "./components/ressetPassword/index";
import PasswordReset from "./components/passwordReset/index";
import LivraisonDunBakelisteView from "./components/livraisonDunBakelisteView/index";
import LivraisonDunBakelisteCoachAssistantView from "./components/livraisonDunBakelisteCoachAssistantView/index";
import VoirMesContenusView from "./components/voirMesContenusView/index";
import ContenusView from "./components/contenusView/index";
import VoirContenusCoachView from "./components/voirContenusCoachView/index";
import ContenusCoachView from "./components/contenusCoachView/index";
import VoirContenusCoachAssistantView from "./components/voirContenusCoachAssistantView/index";
import ContenusCoachAssistantView from "./components/contenusCoachAssistantView/index";
import VoirContenusBakelisteView from "./components/voirContenusBakelisteView/index";
import ContenusBakelistesView from "./components/contenusBakelistesView/index";
import DetailContenusValidesView from "./components/detailContenusValidesView";
import ContenusValidesView from "./components/contenusValidesView/index";
import ListeBakelisteCoachAssistantView from "./components/listeBakelisteCoachAssistantView/index";
import NotFoundView from "./components/notFoundView/index";
import ModifierResumeView from "./components/modifierResumeView/index";
import TachesBakelisteValideesView from "./components/tachesBakelisteValideesView/index";
import ListCoachAssistant from "./components/listCoachAssistant/index";
import ListeContenusBakelisteView from "./components/listeContenusBakelisteView/index";
import CvBakelisteView from "./components/cvBakelisteView/index";
import AjoutCvBakelisteView from "./components/ajoutCvBakelisteView/index";
import CoachAjoutContenuView from "./components/coachAjoutContenuView/index";
import CoachAssistantAjoutContenuView from "./components/coachAssistantAjoutContenuView/index";
import CoachProductionsView from "./components/coachProductionsView/index";
import contenusCoachAssistant from "./components/contenusCoach/contenusCoach";
import ModifierProductionsView from "./components/modifierProductionsView/index";
import CoachAssistantProductionsView from "./components/coachAssistantProductionsView/index";
import ModifierProductionsCoachAssistantView from "./components/modifierProductionsCoachAssistantView/index";
import ShowAllLivraisonsv2 from "./components/LivraisonRefactor/showAllLivraisonsv2/showAllLivraisonsv2";
import AddLivraisonsv2 from "./components/LivraisonRefactor/addLivraisonsv2/addLivraisonsv2";
import ListeTacheBakelisteViewRefac from "./components/listeTacheBakelisteView/ListeTacheBakelisteViewRefac";
import SyllabusDetailsBakelisteView from "./components/syllabusDetailsBakelisteView/index";
import GroupeBakelisteViewRefact from "./components/groupeBakelisteView/GroupeBakelisteViewRefact";
//import AddLivraisonsv2 from "./components/LivraisonRefactor/addLivraisonsv2/addLivraisonsv2"
import DashboardCAViewR from "./components/dashboardCARefactor/dashboardCAViewR/dashboardCAViewR";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}
function MainApp(props) {
  // function to guard the component for private access
  const authGuardCoach = (Component) => () => {
    return window.sessionStorage.getItem("token") &&
      window.sessionStorage.getItem("user_status") === "coach" ? (
      <Component />
    ) : (
      <Redirect to="/" />
    );
  };
  const authGuardLogin = (Component) => () => {
    if (
      window.sessionStorage.getItem("token") &&
      window.sessionStorage.getItem("user_status") === "coach"
    ) {
      return <Redirect to="/coach-dashboard" />;
    } else if (
      window.sessionStorage.getItem("token") &&
      window.sessionStorage.getItem("user_status") === "coach-assistant"
    ) {
      return <Redirect to="/dashboard-coach-assistant" />;
    } else if (
      window.sessionStorage.getItem("token") &&
      window.sessionStorage.getItem("user_status") === "bakeliste"
    ) {
      return <Redirect to="/dashboard-bakeliste" />;
    } else {
      return <Connexion />;
    }
  };
  const authGuardBakeliste = (Component) => () => {
    return window.sessionStorage.getItem("token") &&
      window.sessionStorage.getItem("user_status") === "bakeliste" ? (
      <Component />
    ) : (
      <Redirect to="/" />
    );
  };
  const authGuardCoachAssistant = (Component) => () => {
    return window.sessionStorage.getItem("token") &&
      window.sessionStorage.getItem("user_status") === "coach-assistant" ? (
      <Component />
    ) : (
      <Redirect to="/" />
    );
  };

  return (
    <Router>
      <Switch>
        {/* <div> */}
        {/* <Route exact path="/" component={Connexion} />
        <Route path="/dashboard-bakeliste" component={DashboardBakelisteView} />
        <Route path="/tache-bakeliste" component={TacheBakelisteView} />
        <Route path="/livraison-bakeliste" component={LivrableBakelisteView} />
        <Route path="/groupe-bakeliste" component={GroupeBakelisteView} />
        <Route path="/syllabus-bakeliste" component={SyllabusBakelisteView} />
        <Route path="/syllabus-details-bakeliste/:id"  component={props => <SyllabusDetailsBakelisteView id={props.match.params.id}/>}/>
        <Route path="/compte-bakeliste" component={AccountBakelisteView} />
        <Route path="/tache-coach" component={TacheCoachView} />
        <Route path="/livraison-coach" component={LivrableCoachView} />
        <Route path="/groupe-coach" component={GroupeCoachView} />
        <Route path="/coach-syllabus" component={SyllabusCoachView} />
        <Route path="/syllabus-details-coach" component={SyllabusDetailsCoachView} />
        <Route path="/notification-exemples" component={NotificationExemples} />
        <Route path="/compte-coach" component={AccountCoachView} />
        <Route path="/ajout-tache-syllabus" component={AjoutTacheSyllabus} /> */}
        <Route exact path="/" component={authGuardLogin(Connexion)} />
        <Route
          path="/dashboard-bakeliste"
          component={authGuardBakeliste(DashboardBakelisteView)}
        />

        <Route path="/reinitialiser-mot-de-passe" component={RessetPassword} />
        <Route path="/ajouter-un-contenu" component={AjouterResumeView} />
        <Route path="/coach-ajout-contenu" component={CoachAjoutContenuView} />
        <Route
          path="/coach-assistant-ajout-contenu"
          component={CoachAssistantAjoutContenuView}
        />
        <Route path="/modification-contenu" component={ModifierResumeView} />
        <Route
          path="/modification-contenu-coach"
          component={ModifierProductionsView}
        />
        <Route
          path="/modification-contenu-coach-assistant"
          component={ModifierProductionsCoachAssistantView}
        />

        <Route path="/password-reset/:id" component={PasswordReset} />
        {/* <Route
          path="/password-reset/:id"
          component={PasswordReset}
        /> */}
        {/*<Route
          path="/tache-bakeliste"
          component={authGuardBakeliste(TacheBakelisteView)}
        />*/}
        {/* <Route
          path="/liste-tache-bakeliste"
          component={authGuardBakeliste(ListeTacheBakelisteView)}
        /> */}
        <Route
          path="/liste-tache-bakeliste"
          component={authGuardBakeliste(ListeTacheBakelisteViewRefac)}
        />
        <Route
          path="/livraison-bakeliste"
          component={authGuardBakeliste(LivrableBakelisteView)}
        />
        {/* <Route
          path="/groupe-bakeliste"
          component={authGuardBakeliste(GroupeBakelisteView)}
        /> */}
        <Route
          path="/groupe-bakeliste"
          component={authGuardBakeliste(GroupeBakelisteViewRefact)}
        />
        <Route path="/reglement-interieur" component={ri} />
        {/* <Route
          path="/syllabus-bakeliste"
          component={authGuardBakeliste(SyllabusBakelisteView)}
        /> */}
        <Route
          path="/syllabus-bakeliste"
          component={authGuardBakeliste(SyllabusBakelisteViewRefact)}
        />
        <Route
          path="/liste-taches-validees"
          component={authGuardBakeliste(TachesBakelisteValideesView)}
        />

        {/* <Route
          path="/syllabus-details-bakeliste/:id"
          component={(props) => (
            <SyllabusDetailsBakelisteView id={props.match.params.id} />
          )}
        /> */}
        <Route
          path="/syllabus-details-bakeliste"
          component={SyllabusDetailsBakelisteView}
        />
        {/* <Route
          path="/syllabus-details-bakeliste"
          component={SyllabusDetailsBakelisteViewRefact}
        /> */}
        <Route path="/ajout-examen-pratique" component={AjoutExamenPratique} />

        {/* Routes pour contenus */}
        <Route
          path="/liste-de-mes-contenus"
          component={authGuardBakeliste(VoirMesContenusView)}
        />
        <Route
          path="/liste-de-mes-productions"
          component={authGuardCoach(CoachProductionsView)}
        />
        <Route
          path="/mes-productions"
          component={authGuardCoachAssistant(CoachAssistantProductionsView)}
        />
        <Route path="/details-contenus" component={ContenusView} />
        <Route path="/contenus-validés" component={ContenusValidesView} />
        <Route path="/cv-bakeliste" component={CvBakelisteView} />

        <Route
          path="/details-contenus-valides"
          component={DetailContenusValidesView}
        />

        <Route
          path="/liste-contenus-coach-assistant"
          component={VoirContenusCoachAssistantView}
        />
        <Route
          path="/details-contenus-coach-assistant"
          component={ContenusCoachAssistantView}
        />
        <Route path="/liste-contenus-coach" component={VoirContenusCoachView} />
        <Route path="/details-contenus-coach" component={ContenusCoachView} />
        <Route
          path="/liste-contenus-bakelistes"
          component={VoirContenusBakelisteView}
        />
        <Route
          path="/liste-des-contenus-bakelistes"
          component={authGuardBakeliste(ListeContenusBakelisteView)}
        />
        <Route
          path="/details-contenus-bakelistes"
          component={ContenusBakelistesView}
        />
        {/* <Route
          path="/liste-coach"
          component={ListCoachAssistant}
        /> */}

        <Route
          path="/ajout-examen-pratique-coach-assistant"
          component={AjoutExamenPratiqueCoachAssistant}
        />
        <Route
          path="/liste-bakelistes-coach-assistant"
          component={ListeBakelisteCoachAssistantView}
        />

        <Route path="/ajout-examen-theorique" component={AjoutExamTheorique} />
        <Route
          path="/ajout-examen-theorique-coach-assistant"
          component={AjoutExamTheoriqueCoachAssistant}
        />

        <Route path="/compte-bakeliste" component={AccountBakelisteView} />
        <Route
          path="/coach-dashboard"
          render={authGuardCoach(DashboardCoachView)}
        />
        {/* <Route
          path="/dashboard-coach-assistant"
          render={authGuardCoachAssistant(DashboardCoachAssistantView)}
        /> */}
        <Route
          path="/dashboard-coach-assistant"
          render={authGuardCoachAssistant(DashboardCAViewR)}
        />
        <Route path="/dashboard-coach-assistant-r" render={DashboardCAViewR} />
        <Route path="/tache-coach" component={authGuardCoach(TacheCoachView)} />
        <Route
          path="/livraison-coach"
          component={authGuardCoach(LivrableCoachView)}
        />
        <Route
          path="/livraison-coach-assistant"
          component={authGuardCoachAssistant(LivrableCoachAssistantView)}
        />
        <Route
          path="/livraison-examen-coach"
          component={authGuardCoach(LivraisonExamenCoachView)}
        />
        <Route
          path="/groupe-coach"
          component={authGuardCoach(GroupeCoachView)}
        />
        <Route
          path="/groupe-coach-assistant"
          component={authGuardCoachAssistant(GroupeCoachAssistantView)}
        />
        <Route
          path="/coach-syllabus"
          component={authGuardCoach(SyllabusCoachView)}
        />
        <Route
          path="/syllabus-coach-assistant"
          component={authGuardCoachAssistant(SyllabusCoachAssistantView)}
        />
        <Route
          path="/sous-groupes-coach"
          component={authGuardCoach(ListeSousGroupesCoachView)}
        />
        <Route
          path="/sous-groupes-coach-assistant"
          component={authGuardCoachAssistant(
            ListeSousGroupesCoachAssistantView
          )}
        />

        <Route path="/coach-tâches" component={listeTachesCoachView} />
        <Route
          path="/taches-coach-assistant"
          component={authGuardCoachAssistant(ListeTachesCoachAssistantView)}
        />
        <Route
          path="/coach-bakelistes"
          component={authGuardCoach(ListeBakelistesCoachView)}
        />

        <Route
          path="/livraisons-par-bakeliste"
          component={LivraisonDunBakelisteView}
        />
        <Route
          path="/coach-assistant-livraisons-par-bakeliste"
          component={LivraisonDunBakelisteCoachAssistantView}
        />
        <Route path="/fiche-bakeliste" component={FicheBakeliste} />
        <Route
          path="/fiche-bakeliste-coach-assistant"
          component={FicheBakelisteCoachAssistant}
        />

        <Route
          path="/information-sous-groupe"
          component={InformationSousGroupe}
        />
        <Route
          path="/informations-sous-groupe-coach"
          component={InformationSousGroupeCoach}
        />
        <Route path="/informations-sous-groupe" component={DetailSousGroupe} />

        {/* <Route
          path="/syllabus-details-coach/:id"
          component={(props) => (
            <SyllabusDetailsCoachView2 id={props.match.params.id} />
          )}
        /> */}
        <Route
          path="/syllabus-details-coach"
          component={SyllabusDetailsCoachView2}
        />
        <Route
          path="/syllabus-details-coach-assistant"
          component={SyllabusDetailsCoachAssistantView2}
        />
        <Route path="/notification-exemples" component={NotificationExemples} />
        {/* <Route
          path="/bakelistes-par-syllabus/:id"
          component={BakelisteBySyllabus}
        /> */}
        <Route
          path="/bakelistes-par-syllabus"
          component={BakelisteBySyllabus}
        />
        <Route path="/compte-coach" component={AccountCoachView} />
        {/* <Route
          path="/ajout-tache-syllabus/:id"
          component={(props) => (
            <AjoutTacheSyllabus id={props.match.params.id} />
          )}
        /> */}
        <Route path="/ajouter-question-examen" component={AjoutQuestionCoach} />
        <Route
          path="/ajouter-question-examen-coach-assistant"
          component={AjoutQuestionCoachAssistant}
        />
        <Route path="/examens" component={ExamenCoachView} />
        <Route
          path="/examens-coach-assistant"
          component={ExamenCoachAssistantView}
        />
        <Route
          path="/details-examens-pratique"
          component={DetailsExamenCoachView}
        />
        <Route
          path="/details-examens-pratique-coach-assistant"
          component={DetailsExamenCoachAssistantView}
        />

        <Route
          path="/details-examens-theorique"
          component={DetailsExamenPratiqueView}
        />
        <Route
          path="/details-examens-theorique-coach-assistant"
          component={DetailExamenPratiqueCoachAssistantView}
        />

        <Route
          path="/ajout-tache-coach-syllabus"
          component={AjoutTacheSyllabusCoachAssistant}
        />

        <Route path="/ajout-tache-syllabus" component={AjoutTacheSyllabus} />
        <Route
          path="/ajout-tache-syllabus-coach"
          component={AjoutTacheSyllabusCoachAssistant}
        />
        <Route
          path="/ajout-tache-syllabus-coach"
          component={AjoutTacheSyllabusCoachAssistant}
        />
        <Route path="/ajout-cv-bakeliste" component={AjoutCvBakelisteView} />

        {/*<Route
          path="/ajout-tache-syllabus/:id"
          component={(props) => (
            <AjoutTacheSyllabus id={props.match.params.id} />
          )}
        />
        <Route
          path="/ajout-tache-syllabus/:id"
          component={(props) => (
            <AjoutTacheSyllabus id={props.match.params.id} />
          )}
        />*/}
        {/* <Route
          path="/sous-groupe/:id"
          component={(props) => <SousGroupeCoach id={props.match.params.id} />}
        /> */}
        <Route path="/sous-groupe" component={SousGroupeCoach} />
        <Route path="/sous-groupe-coach" component={SousGroupeCoachAssistant} />

        <Route path="/ajout-groupe" component={AjoutGroupeCoach} />
        <Route
          path="/ajout-groupe-coach-assitant"
          component={AjoutGroupeCoachAssistant}
        />

        <Route path="/groupes-archives" component={GroupeArchived} />
        <Route path="/demande-d-absence" component={DemandeAbsenceView} />

        <Route path="/sous-groupes-archives" component={SousGroupeArchived} />
        <Route
          path="/syllabus-archives"
          component={SyllabusArchivesCoachView}
        />
        {/* <Route
          path="/ajout-sous-groupe/:id"
          component={(props) => <AjoutSousGroupe id={props.match.params.id} />}
        /> */}
        <Route path="/ajout-sous-groupe" component={AjoutSousGroupe} />
        <Route
          path="/ajout-sous-groupe-coach-assistant"
          component={AjoutSousGroupeCoachAssistant}
        />

        <Route
          path="/liste-questions"
          component={authGuardCoach(ViewListeQuestions)}
        />
        <Route
          path="/liste-questions-coach"
          component={authGuardCoachAssistant(ViewListeQuestionsCoachAssistant)}
        />
        <Route
          path="/ajout-question"
          component={authGuardCoach(ViewQuestions)}
        />
        <Route
          path="/ajout-question-coach"
          component={authGuardCoachAssistant(ViewQuestionsCoachAssistant)}
        />
        <Route path="/modifier-question" component={ViewEditQuestion} />
        <Route
          path="/modifier-question-coach"
          component={ViewEditQuestionCoach}
        />

        <Route path="/faire-examen" component={FaireExamenView} />
        <Route
          path="/examen-bakeliste"
          component={ContentExamenBakelisteView}
        />

        <Route path="/resultat" component={ResultatExamenTheorique} />
        <Route path="/score-des-bakeliste" component={NoteExamenView} />
        <Route path="/liste-coachs" component={ListeCoachView} />
        <Route
          path="/ajouter-coach-assistant"
          component={ajoutCoachAssistant}
        />
        {/* </div> */}
        {/* <Route path="*" component={NotFoundView} /> */}

        {/* Refactoring livraisons */}
        {/* <Route path="/livraisons-v2" component={AddLivraisonsv2}/> */}
        <Route path="/livraisons-refactor" component={ShowAllLivraisonsv2} />
        <Route path="/add-Livraisons-v2" component={AddLivraisonsv2} />
      </Switch>
    </Router>
  );
}

export default App;
