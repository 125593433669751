import React, { Component } from "react";
import "./listeSousGroupesCoachView.css";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";
import Loader from "react-loader-spinner";
import ListeSousGroupesCoach from "../listeSousGroupesCoach/index";
import { Link } from "react-router-dom";
import Offlinecoach from "../offlinecoach/index";
import Preloader from "../preloader/index";

export default class listeSousGroupesCoachView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      show: false,
    };
  }
  componentDidMount() {
    this.isLoading = setTimeout(() => {
      this.setState({
        loading: false,
        show: true,
      });
    }, 4000);
  }
  render() {
    const checkConnexion = navigator.onLine ? (
      this.state.show ? (
        <ListeSousGroupesCoach />
      ) : null
    ) : (
      <p>
        <Offlinecoach />
      </p>
    );
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoach />
            </div>

            <div className="col-lg-10 pt-3">
              {this.state.loading ? (
                <div>
                  <Preloader />
                </div>
              ) : (
                <>
                  <nav aria-label="breadcrumb fixed">
                    <ol className="breadcrumb fixed">
                      <li className="breadcrumb-item ">
                        <Link to="coach-dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item " aria-current="page">
                        <Link to="/groupe-coach">Groupes</Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Sous-Groupes
                      </li>{" "}
                    </ol>
                  </nav>
                  {checkConnexion}
                  {/* <ListeSousGroupesCoach /> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ listeSousGroupesCoachView }) => ({ ...listeSousGroupesCoachView }),
//     dispatch => bindActionCreators({ ...listeSousGroupesCoachViewActions }, dispatch)
//   )( listeSousGroupesCoachView );
