import React, {Component} from 'react';
import './examenItemQuestion.css';
import { Dropdown, Icon, IconButton } from 'rsuite';
import axios from "axios";
import API from "../../variablesGlobales";
export default class examenItemQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
          api: API.API,
          removeQuestion: false,
          id:this.props.eid,
          fields: {}
        };
       //  console.log("eeeeeeeid",this.props.eid)
    }

    removeQuestion = () => {
      let data = this.state.fields;
        data["question_id"] = this.props.id;
        data["examen_id"] = this.state.id;
        //console.log(data, "eeedddddddddd")
      axios
        .post(this.state.api + "remove-questions-to-exam", data)
        .then((res) => {
         // console.log("remove",res)
          if (res.data.success) {
            this.setState({ removeQuestion: true 
            });
            this.props.getExamensById()

          }
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    render() {
      // console.log(this.props.index)
      return  <div className="card-dashboard pos-relative mb-2 px-xl-5 px-lg-4 px-md-5">
                <div className="row">
                  <div className="col-md-2 col-sm-2 pr-sm-0 mb-sm-0 mb-3">
                    <p className="numero-tache-syllabus-item-tache">Question &nbsp;
                    {this.props.index} 
                    </p>
                  
                  </div>
                  <div className="col-md-9 col-sm-9">
                    <p className="titre-syllabus-item-tache">
                      {this.props.libelle}
                      </p>
                    <div className="row">
                       <div className="col-md-2">
                         <p className="contenu-syllabus-item-tache">
                          Reponse 1 &nbsp; {this.props.option1}
                           </p>
                       </div>
                       <div className="col-md-2">
                         <p className="contenu-syllabus-item-tache">
                         Reponse 2 &nbsp; {this.props.option2}
                           </p>
                       </div>
                       <div className="col-md-2 col-sm-2">
                         <p className="contenu-syllabus-item-tache"> 
                         Reponse 3 &nbsp; {this.props.option3}
                         </p>
                       </div>
                       <div className="col-md-2 col-sm-2">
                         <p className="contenu-syllabus-item-tache">
                         Reponse 4 &nbsp; {this.props.option4}
                            </p>
                       </div>
                       <div className="col-md-2 col-sm-2">
                       <p className="etiquette-syllabus-details avance mb-2">
                      {this.props.correct_answer}  
                      </p> 
                     </div>
                     </div>
                 
                  </div>
                </div>
                
                <Dropdown 
                  className="dropdown-syllabus-item-tache"
                  renderTitle={() => {
                    return <IconButton 
                    icon={<Icon 
                      icon="ellipsis-v" 
                      className="icon-dropdown-syllabus-item-tache" />} 
                    circle />;
                  }}
                  placement="bottomEnd"
                >
                  {/* <Dropdown.Item>
                    Modifier
                  </Dropdown.Item> */}
                  <Dropdown.Item>
                    <span onClick={() => this.removeQuestion()}>
                      Retirer 
                    </span>
                  </Dropdown.Item>
                </Dropdown>
             
              </div>;
    }
  }

// export default connect(
//     ({ examenItemQuestion }) => ({ ...examenItemQuestion }),
//     dispatch => bindActionCreators({ ...examenItemQuestionActions }, dispatch)
//   )( examenItemQuestion );

// <div className="col-md-10 col-sm-9">
//                     <div className="row">
//                       <div className="col-md-2">
//                         <p className="titre-syllabus-item-tache">{this.props.option1}</p>
//                       </div>
//                       <div className="col-md-2">
//                         <p className="titre-syllabus-item-tache">{this.props.option2}</p>
//                       </div>
//                       <div className="col-md-2 col-sm-2">
//                         <p className="titre-syllabus-item-tache">{this.props.option3}</p>
//                       </div>
//                       <div className="col-md-2 col-sm-2">
//                         <p className="titre-syllabus-item-tache">{this.props.option4}</p>
//                       </div>
//                       <div className="col-md-2 col-sm-2">
//                       <p className="titre-syllabus-item-tache">{this.props.correct_answer}</p>
//                     </div>
//                     </div>
//                   </div>
