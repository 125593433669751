import React, { Component } from "react";
import "./envoyerMonExamen.css";
import FeatherIcon from "feather-icons-react";
import Dropzone from "react-dropzone-uploader";
import { Modal } from "rsuite";
import SweetAlert from "react-bootstrap-sweetalert";
import API from "../../variablesGlobales";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import amplitude from "amplitude-js";
import $ from "jquery";
import base_api_url from "../../variablesGlobales";
export default class envoyerMonExamen extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),
    date = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate() + ' ' +  today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

    this.state = { 
      api: API.API,
      userID: window.sessionStorage.getItem("user_id"),
      email: sessionStorage.getItem("email"),
      bakeliste_id: window.sessionStorage.getItem("user_id"),
      domaine_id: sessionStorage.getItem("domaine_id"),
      show: false,
      showAlertSuccessExamen: false,
      showAlertErrorExamen: false,
      fields: {},
      Files: [],
      currentDate: date,

     
    };
  }



  open = (size) => {
    this.setState({
      size,
      show: true,
    });
  };

  close = () => {
    this.setState({
      show: false,
    });
  };


  handleChangeExamen = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  //  console.log(this.state.fields, e.target.value)
  };

  fileChange = (e) => {
    //console.log("fields", e)

    let fields = this.state.Files;
    if (e.target.name === "examen_file") {
      if (this.fileValidation("examen_file")) {
        fields["examen_file"] = e.target.files[0];
      } else {
        this.setState({
          extensionValid: true,
        });
        setTimeout(() => {
          this.setState({
            extensionValid: false,
          });
        }, 6000);
      }
    } else {
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields: fields,
    });
   // console.log("fields", fields)

  };

  onValidateLivrablesFormDataExamen() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }
  onSendLivrablesExamen = (e) => {
   
    e.preventDefault();
    if (this.onValidateLivrablesFormDataExamen()) {
      this.setState({
        isSendingLivrableComponentInProgress: true,
      });
      const id = this.state.bakeliste_id;
      const email = this.state.email;
      var dataaS = this.state.fields;
      var Files = this.state.Files;
      var eventProperties = {
        user_id: id,
        email: email,
        examen_id: this.props.id,
      };
      if (navigator.onLine) {
        Files.map((image) => {
          var fd = new FormData();

          fd.append("comments", dataaS.comments);
          fd.append("bakeliste_id", this.state.bakeliste_id);
          fd.append("examen_id", this.props.id);
          fd.append("examen_files", image);
          fd.append("date", this.state.currentDate);
          axios
            .post(this.state.api + "livraison_examens", fd, {
              Headers: { "Content-Type": "multipart/formdata" },
            })
            .then((response) => {
              // console.log("response", response)
              this.setState({
                isSendingLivrableComponentInProgress: false,
              });
  
              if (response.data.success) {
                // $("#tache_file").val("");
                $(".close-modal-bakeliste-title").click();
                setTimeout(() => {
                  this.setState({ 
                    showAlertSuccessExamen: true,
                    showlivrable: false,
                  });
                }, 1000);
                amplitude
                  .getInstance()
                  .logEvent(
                    "ENVOIE_LIVRABLE_EXAMEN_SUCCESS",
                    eventProperties
                  );
              } else {
                this.setState({
                  showAlertErrorExamen: true,
                });
                setTimeout(() => {
                  this.setState({
                    isErrorSending: false,
                  });
                }, 1000);
                amplitude
                  .getInstance()
                  .logEvent(
                    "ENVOIE_LIVRABLE_EXAMEN_ERROR",
                    eventProperties
                  );
              }
              $(".close-modal-bakeliste-title").click();
            })
            .catch((error) => {
              if ((error.message = "Request failed with status code 422")) {
              }
              amplitude
                .getInstance()
                .logEvent("ENVOIE_LIVRABLE_EXAMEN_ERROR", eventProperties);
              this.setState({
                isSendingLivrableComponentInProgress: false,
                showAlertErrorExamen: true,
              });
              if (process.env.NODE_ENV !== "production") {
                return;
              }
              Sentry.captureException(error);
            });
        
          });
        $(".close-modal-bakeliste-title").click();
      } else {
        this.setState({ userNotConnected: true });
        amplitude
          .getInstance()
          .logEvent("ERROR_CONNEXION_ENVOIE_LIVRABLE_EXAMEN", eventProperties);
      }
    }
  };
  handleChangeStatusExamen = ({ meta, file }, status) => {
    const Files = file;
// console.log(file)
    if (status === "done") {
      this.setState({ Files: this.state.Files.concat(file) });
    }
  };

  render() {
    // console.log( "current date", this.state.currentDate )
    return (
      <div>
        <button
          className="btn-theme text-up w-full "
          onClick={() => this.open("md")}
        >
          <FeatherIcon
            icon="send"
            className="icon-btn-livrable-bakeliste-item"
          />
          Envoyer mon travail
        </button>
        <SweetAlert
          show={this.state.showAlertSuccessExamen}
          onConfirm={() => this.setState({ showAlertSuccessExamen: false })}
          success
          title="Livraison examen envoyé avec succes!!!"
          style={{
            color: "#069A77",
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            paddingTop: 60,
            paddingBottom: 40,
          }}
          confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
        />
        {/* ENd ALert Succes */}

        {/* Alert Livrable non envoye */}
        <SweetAlert
          show={this.state.showAlertErrorExamen}
          onConfirm={() => this.setState({ showAlertErrorExamen: false })}
          error
          title="Erreur envoie livrable examen, veuillez reessayer!!!"
          style={{
            color: "#d9534f",
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            paddingTop: 60,
            paddingBottom: 40,
          }}
          confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
        />
        <Modal
          className="modal-responsive-md"
          size={this.state.size}
          show={this.state.show}
          onHide={this.close}
        >
          <h5
            className="modal-bakeliste-title m-b-50"
            id="modalSendLivrableTitle"
          >
            Envoyer mon travail
          </h5>
          <button className="close-modal-bakeliste-title" onClick={this.close}>
            <FeatherIcon icon="x" aria-hidden="true" />
          </button>

          <form
            encType="multipart/form-data"
            id="livrable_form"
            onSubmit={(e) => this.onSendLivrablesExamen(e)}
          >
          
            <div className="form-group">

              <textarea
                className="form-control input-modal-content-bakeliste p-t-15"
                rows="9"
                placeholder="Ajouter un commentaire"
                name="comments"
                value={this.state.fields["comments"]}
                onChange={(e) => this.handleChangeExamen(e)}
              ></textarea>
            </div>
            <Dropzone
              required
              getUploadParams={this.getUploadParams}
             onChangeStatus={this.handleChangeStatusExamen}
              onChange={(e) => this.fileChange(e)}
              accept="image/*,audio/*,video/*"
              inputContent={"Cliquer pour ajouter des images"}
              inputWithFilesContent={"Ajouter plus..."}
            />
            {this.state.isSendingLivrableExamenInProgress ? (
              <button
                type="submit"
                className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                disabled
              >
                Envoie en cours &nbsp;
                <FeatherIcon icon="loader" aria-hidden="true" />
              </button>
            ) : (
              <button
                type="submit"
                className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
              >
                ENVOYER
              </button>
             
            )}
          </form>
        </Modal>
      </div>
    );
  }
}
