import React, { Component } from "react";
import "./listeSousGroupesCoachAssistantView.css";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoachAssistant from "../navVerticalCoachAssistant/index";
import Loader from "react-loader-spinner";
import ListeSousGroupesCoachAssistant from "../listeSousGroupesCoachAssistant/index";
import { Link } from "react-router-dom";
import Offlinecoach from "../offlinecoach/index";
import Preloader from "../preloader/index";

import amplitude from "amplitude-js";

export default class listeSousGroupesCoachAssistantView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      show: false,
      userID: window.sessionStorage.getItem('user_id'),

    };
  }
  componentDidMount() {
    var eventProperties = {
      userID : this.state.userID,
 
    }
     this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
     amplitude.getInstance().logEvent("VISITED_PAGE_SOUS_GROUPES_COACH_ASSISTANT_V2", eventProperties);
 
    this.isLoading = setTimeout(() => {
      this.setState({ 
        loading: false,
        show:true
       });
    }, 4000);
  }
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };
  render() {
    const checkConnexion = navigator.onLine ? (
      this.state.show?

      <ListeSousGroupesCoachAssistant />:null 
    ) : (
      <p>
        <Offlinecoach />
      </p>
    );
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoachAssistant />
            </div>
            
            <div className="col-lg-10 pt-3">
            {this.state.loading ? (
                <div>
                   <Preloader />
              </div>
            )
            : <>
            <nav aria-label="breadcrumb fixed">
                <ol className="breadcrumb fixed">
                  <li className="breadcrumb-item ">
                    <Link to="/dashboard-coach-assistant">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item " aria-current="page">
                    <Link to="/groupe-coach-assistant">Groupes</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Sous-Groupes
                  </li>{" "}
                </ol>
              </nav>
              {checkConnexion}
              {/* <ListeSousGroupesCoach /> */}
              </>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ listeSousGroupesCoachView }) => ({ ...listeSousGroupesCoachView }),
//     dispatch => bindActionCreators({ ...listeSousGroupesCoachViewActions }, dispatch)
//   )( listeSousGroupesCoachView );
