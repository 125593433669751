import React, {Component, useState} from 'react';
import { Link } from 'react-router-dom';
// import ListeDesBakelisteForExam from '../listeDesBakelisteForExam/index';
import './noteExamen.css';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TableNotesAllBakeliste from '../tableNotesAllBakeliste/index';


export default class noteExamen extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }

    render(){
        return (
            <div className="component-theori-pratique">
                <div className="container-fluid px-0">
                    <div className="row mx-3">
                        <div className="col-sm-12">
                            <h4 className="format-txt-h4-examen mto-3 ">Note des examens théorique et pratique</h4>
                            <div className="row pt-5">
                                <div className="col-lg-6 col-md-6 col-xl-6 col-sm-6">
                                    <div className="form-group has-search">
                                    <span className="fas fa-search form-control-feedback"></span>
                                    <input type="text" className="form-control type-input-search" placeholder="Rechercher" />
                                </div>
                             </div>
                             <div className="col-lg-6 col-md-6 col-xl-6 col-sm-6">
                                <button className="btn-export-to offset-7"><ExitToAppIcon /> EXPORT TO CSV</button>
                             </div>
                       </div>
                        <TableNotesAllBakeliste /> 
                        
                        </div>                    
                    </div>
                </div>
            </div>
        );
    }   
}
