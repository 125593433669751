import API2 from "../../../../variablesGlobales";
import API from "../../../../variablesGlobales";
import axios from "axios";
export const FETCH_TACHES_BEGIN = "FETCH_TACHES_BEGIN";
export const FETCH_TACHES_SUCCESS = "FETCH_TACHES_SUCCESS";
export const FETCH_TACHES_FAILURE = "FETCH_TACHES_FAILURE";
export const FETCH_TACHES_BAKELISTE = "FETCH_TACHES_BAKELISTE";
export const FETCH_MORE_TACHES_BAKELISTE = "FETCH_MORE_TACHES_BAKELISTE";

const bakeliste_id = sessionStorage.getItem("user_id");

export const fetchTachesBegin = () => ({
  type: FETCH_TACHES_BEGIN,
});

export const fetchTachesSuccess = (taches) => ({
  type: FETCH_TACHES_SUCCESS,
  payload: { taches },
});

export const fetchTachesFailure = (error) => ({
  type: FETCH_TACHES_FAILURE,
  payload: { error },
});

export function fetchTaches() {
  return function (dispatch) {
    dispatch(fetchTachesBegin());
    fetch(API2.API2 + "get_all_taches/" + bakeliste_id)
      .then((res) => res.json())
      .then((data) => {
        dispatch(fetchTachesSuccess(data));
      })
      .catch((error) => dispatch(fetchTachesFailure(error)));
  };
}

export const getDetailsSyllabus = (bakelisteId) => {
  return async (dispatch) => {
    return await axios
      .get(API.API + "bakeliste-details-syllabus/" + bakelisteId)
      .then((response) => {
        dispatch({
          type: FETCH_TACHES_BAKELISTE,
          payload: response.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const fetchMoreData = (bakelisteId, currentPage) => {
  return (dispatch) => {
    return axios
      .get(
        API.API +
          "bakeliste-details-syllabus/" +
          bakelisteId +
          "/?page=" +
          currentPage
      )
      .then((response) => {
        dispatch({
          type: FETCH_MORE_TACHES_BAKELISTE,
          payload: response.data,
        });
      })
      .catch((err) => console.log(err));
  };
};
