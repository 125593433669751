import API from "../../../../variablesGlobales";
import axios from "axios";

export const FETCH_LIVRAISONS_BY_BAKELISTE = "FETCH_LIVRAISONS_BY_BAKELISTE";
export const FETCH_LIVRAISON_BEGIN = "FETCH_LIVRAISON_BEGIN";

export const fetchLivraisonBegin = () => ({
  type: FETCH_LIVRAISON_BEGIN,
});

export const getLivraisonsBakeliste = (bakelisteId) => {
  return async (dispatch) => {
    return await axios
      .get(API.API + "taches-by-bakeliste/" + bakelisteId)
      .then((response) => {
        dispatch({
          type: FETCH_LIVRAISONS_BY_BAKELISTE,
          payload: response.data,
        });
      })
      .catch((err) => console.log(err));
  };
};
