import React, { useEffect, useState} from 'react';
import './dashboardCAR.css'
import StatisticsCoach from "../../statisticsCoach/index";
import axios from "axios";
import Preloader from "../../preloader/index";
import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";
import API2 from "../../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import BakelisteGroupeItemForCoachAssistant from "../../bakelisteGroupeItemForCoachAssistant/index";
import GrapheLivraisons from "../../grapheLivraisons/grapheLivraisons";
import GrapheCommentaires from "../../grapheCommentaires/grapheCommentaires";
import GrapheContenu from "../../grapheContenu/grapheContenu";
import GrapheTaskValidated from "../../grapheTaskValidated/grapheTaskValidated";
import AmplitudeScript from '../../../amplitudeScript';
import LivraisonsItemv2 from '../../LivraisonRefactor/livraisonsItemv2/livraisonsItemv2';
import { connect } from 'react-redux';
import { fetchLivraisons } from '../../LivraisonRefactor/redux/actions/livraisonsActions';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
const userFullName = window.sessionStorage.getItem("userFullName")

const DashboardCAR =(props)=> {
  const [loading,setLoading]=useState(true)
  // const [sous_groupes,setSousGroupes]=useState(true)
  const [isOpen,setIsOpen]=useState(false)
  const [coach_id,setCoachId]=useState("")
  const [livraisons, setLivraisonsSyllabus]=useState(props.livraisons)



    useEffect (() => {
      var eventProperties = {
        email: window.sessionStorage.getItem("email"),
        user_id: window.sessionStorage.getItem("user_id"),
      };
      AmplitudeScript("0168d5bcb0d33437d15f2a6220fb054a")
      amplitude
        .getInstance()
        .logEvent("VISITED_DASHBOARD_COACH_ASSISTANT_V2", eventProperties);
     
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      // setLoading(false)
    },[]);
  
  

    const toggle=() => {
      setIsOpen(!isOpen);
      // setState(prevState => {
      //   return { ...prevState, [e.target.name] : e.target.value ,bakeliste_id:sessionStorage.getItem('user_id')}
      // });
    }
    const __getSousGroupes = () => {
      // const _this = this;
      // this.showLoader();
      axios
        .get(this.state.api + "sous__groupes/coach/" + this.state.coach_id)
        .then((res) => {
          this.setState({
            loading: false,
          });
          if (res.data.success) {
            this.setState({
              sous_groupes: res.data.data.reverse(),
            });
          }
          this.state.sous_groupes.slice(0, 5).map((i) => {
            let items = {
              id: i.id,
              value: i.name,
              groupe_id: i.groupe_id,
            };
            this.state.list.push(items);
          });
          // _this.hideLoader();
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
          // _this.hideLoader();
        });
    };

    const addCommentOnLivrable = (fields, item) => {
      const livraison = item.livraisons;
      // const eventProperties = {
      //   user_id: this.state.user_id,
      //   Name: this.state.userFullName,
      // };
      axios
        .post(API2.API2 + "add/livraison_comments", fields)
        .then((response) => {
          // amplitude
          //   .getInstance()
          //   .logEvent(
          //     "COMMENTAIRE_LIVRAISON_COACH-ASSISTANT_V2",
          //     eventProperties
          //   );
          const data = response.data.data;
          data.fullname = userFullName;
          item.comments.push(data);
          const livraisonSyllabus = [...props.livraisons];
          const index = livraisonSyllabus.findIndex((it) => it.id === item.id);
          livraisonSyllabus[index].livraisons = livraison;
          setLivraisonsSyllabus(livraisonSyllabus)
          // var Event = {
          //   user_id: this.state.bakeliste_id,
          //   email: this.state.email,
          // };
          // this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
          // amplitude
          //   .getInstance()
          //   .logEvent("COMMENTAIRE_LIVRAISON_COACH_ASSISTANT_V2", Event);
        })
  
        .catch((error) => {
          toast.error('Erreur ajout commentaire!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            })        });
    };
     
    return (
        <Amplitude>
          {loading ? (
            <div>
              <Preloader />
            </div>
          ) : (
            <div className="p-b-200">
              <div className="row">
                <div className="col-lg-9">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </nav>
                  <div className="row m-b-15">
                    <div className="col m-b-15">
                      <h6 className="h-theme ff-inter fw-600">Chiffres</h6>
                    </div>
                    <div className="col dis-flex m-b-15"></div>
                  </div>
                  <StatisticsCoach />
  
                  <div class="box" onClick={toggle}>
                    <button class="button-graphe ">
                      <span>
                        {isOpen
                          ? "Cacher Statistique"
                          : "Afficher Statistique"}
                      </span>
                    </button>
                  </div>
                  {isOpen && (
                    <>
                      <GrapheContenu /> <GrapheLivraisons />{" "}
                      <GrapheCommentaires />
                      <GrapheTaskValidated />
                    </>
                  )}
  
                  <div className=" m-b-15 p-b-60">
                    <div className="col-md-6">
                      <h6 className="h-theme ff-inter fw-600 mb-3">
                        Livraisons des Bakelistes
                      </h6>
                    </div>
                    {props.livraisons.map((item, index) => (
                      <LivraisonsItemv2
                        key={item.id}
                        livraisons={item}
                        fichiers = {item.fichiers.reverse()}
                        comments={item.comments}
                        livraison_id={item.id}
                       onSubmitComment={addCommentOnLivrable}
                        item={item}
                        index={index + 1}
                        bakeliste_id={item.bakeliste_user_id}
                        fullname={item.bakeliste.user_fullname}
                        tache_content={item.details_syllabus.task}
                        date={item.updated_at}
                      />
                        ))}
                    {/* <LivraisonCoachAssistant />{" "} */}
                  </div>
                </div>
                <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                  <div className="row">
                    <div className="col-lg-12 col-md-6 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                      <BakelisteGroupeItemForCoachAssistant
                        id={coach_id}
                      />
                    </div>
                    {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          )} 
        </Amplitude>
      );
    
  }

  const mapStateToprops =state=>{
    return{
     livraisons: state.livraisons.items,
    loading:state.livraisons.loading,
    error:state.livraisons.error,
    }
  }
  
  export default connect(mapStateToprops,{fetchLivraisons})(DashboardCAR)
  // export default connect(
  //     ({ dashboardCoach }) => ({ ...dashboardCoach }),
  //     dispatch => bindActionCreators({ ...dashboardCoachActions }, dispatch)
  //   )( dashboardCoach );
  