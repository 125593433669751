import React, { Component } from "react";
import "./listeBakelisteCoachAssistantView.css";
import ListeBakelisteCoachAssistant from "../listeBakelisteCoachAssistant/index";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoachAssistant from "../navVerticalCoachAssistant/index";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import Preloader from "../preloader/index";

export default class listeBakelisteCoachAssistantView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoachAssistant />
            </div>

            <div className="col-lg-10 pt-3">
              {/* {this.state.loading ? (
                <div>
                    <Preloader />
              </div>
            ) 
            
            :  <> */}
              <nav aria-label="breadcrumb fixed">
                <ol className="breadcrumb fixed">
                  <li className="breadcrumb-item ">
                    <Link to="/coach-dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Liste Bakelistes
                  </li>{" "}
                </ol>
              </nav>
              <ListeBakelisteCoachAssistant />
              {/* </>
            } */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ listeBakelistesCoachView }) => ({ ...listeBakelistesCoachView }),
//     dispatch => bindActionCreators({ ...listeBakelistesCoachViewActions }, dispatch)
//   )( listeBakelistesCoachView );
