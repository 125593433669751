import React, { Component } from "react";
import "./livrableBakelisteItem.css";
import FeatherIcon from "feather-icons-react";
import { Modal } from "rsuite";
import base_api_url from "../../variablesGlobales";
// import amplitudeScript from "../../amplitudeScript";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import $ from "jquery";
import API from "../../variablesGlobales";
import SweetAlert from "react-bootstrap-sweetalert";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import Gallery from "react-grid-gallery";
import { toast } from "react-toastify";
import { Tooltip } from "@material-ui/core";
import Commments from "../commments/index";
import frenchFormatDate from "../frenchFormatDate";
import IconComment from "../../img/icons/comment.png";
import IconSendComment from "../../img/icons/send1.png";
import { Input, InputGroup } from "rsuite";
toast.configure();

export default class livrableBakelisteItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      show: false,
      frenchFormat: frenchFormatDate.frenchFormatDate,

      showlivrable: false,
      base_api_url: base_api_url.base_api_url,
      fields: {},
      isValidForm: false,
      is_empty_tache_file: false,
      isSendingLivrableInProgress: false,
      bakeliste_id: sessionStorage.getItem("user_id"),
      showAlertSuccess: false,
      showAlertError: false,
      titre: this.props.titre,
      id: this.props.id,
      files: [],
      images: [],
      showC: false,
      comment: [],
      newComment: {
        comment_text: "",
        isOk: "",
        isnOk: "",
        commentable_id: 0,
        user_type: "",
        livrable_id: 0,
        bakeliste_user_id: 0,
      },
     
    };
    Sentry.init({
      dsn:
        "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
    });
    // console.log("commentaires", this.props.commentaires[0]);
    // console.log(this.props.iteme)
  }
  componentDidMount() {
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("bakelisteLivrablePageVisite");
  }

  onRenderImagesLivrables = () => {
    // var base_api_url =
    // console.log("livraisons", this.props.livraisons);
    this.props.fichieirs.map((item, indexx) => {
      var image = {
        src: this.state.base_api_url + item.file_path,
        thumbnail: this.state.base_api_url + item.file_path,
        thumbnailHeight: 320,
      };
      this.state.images.push(image);
    });

    return <Gallery images={this.state.images} />;
  };
  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  open = (size) => {
    this.setState({
      size,
      show: true,
    });
  };
  close = () => {
    this.setState({
      show: false,
    });
  };

  openC = (size) => {
    this.setState({
      size,
      showC: true,
    });
  };
  closeC = () => {
    this.setState({
      showC: false,
    });
  };

  openLivrable = (sizelivrable) => {
    this.setState({
      sizelivrable,
      showlivrable: true,
    });
  };
  closeLivrable = () => {
    this.setState({
      showlivrable: false,
    });
  };
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);

    amplitude.getInstance().init(api_key);
  };
  onSendLivrables = (e) => {
    e.preventDefault();

    if (this.onValidateLivrablesFormData()) {
      this.setState({
        isSendingLivrableInProgress: true,
      });
      var data = this.state.fields;
      var files = this.state.files;
      // console.log("dataa", files);
      var fd = new FormData();
      var eventProperties = {
        user_id: this.state.bakeliste_id,
        tache_id: data.tache_id,
      };
      files.forEach((item) => {
        fd.append("tache_files", item);
        fd.append("commentaire", data.comment);
        fd.append("bakeliste_user_id", this.state.bakeliste_id);
        fd.append("tache_id", data.tache_id);
        // console.log("a soumettre", fd);
        axios
          .post(this.state.api + "livraisons", fd, {
            Headers: { "Content-Type": "multipart/formdata" },
          })
          .then((response) => {
            this.setState({
              isSendingLivrableInProgress: false,
            });

            if (response.data.success) {
              // $("#tache_file").val("");
              $(".close-modal-bakeliste-title").click();
              setTimeout(() => {
                this.setState({
                  showAlertSuccess: true,
                  showlivrable: false,
                });
              }, 1000);
              amplitude
                .getInstance()
                .logEvent(
                  "ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_V2",
                  eventProperties
                );
            } else {
              this.setState({
                showAlertError: true,
              });
              setTimeout(() => {
                this.setState({
                  isErrorSending: false,
                });
              }, 1000);
              amplitude
                .getInstance()
                .logEvent(
                  "ENVOIE_LIVRABLE_BAKELISTE_ERROR_V2",
                  eventProperties
                );
            }
            $(".close-modal-bakeliste-title").click();
          })
          .catch((error) => {
            if ((error.message = "Request failed with status code 422")) {
            }
            amplitude
              .getInstance()
              .logEvent("ENVOIE_LIVRABLE_BAKELISTE_ERROR_V2", eventProperties);
            this.setState({
              isSendingLivrableInProgress: false,
              showAlertError: true,
            });
            setTimeout(() => {
              this.setState({
                showAlertError: false,
              });
            }, 1000);
            if (process.env.NODE_ENV !== "production") {
              return;
            }
            Sentry.captureException(error);
          });
      });
    }
  };

  onValidateLivrablesFormData() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    // if (!fields["tache_file"]) {
    //   isValidForm = false;
    //   this.setState({
    //     is_empty_tache_file: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       is_empty_tache_file: false,
    //     });
    //   }, 5000);

    //   errors["tache_file"] = "Ce champ est obligatoire";
    // }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  fileValidation = (elementID) => {
    var fileInput = document.getElementById(elementID);

    var filePath = fileInput.value;

    // Allowing file type
    var allowedExtensions = /(\.png|\.jpg|\.jpeg|)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  };
  getFichiersLivraisons = (data) => {
    const ok = data.map((i, ix) => {
      // console.log("fichierss", i.fichiers);
      return i.fichiers;
    });
    return ok;
  };

  getIdLivraison = (data) => {
    // console.log(data[0])
    const IdLiv = data[0].map((item, index) => {
      return item.livraisons_id;
    });
    // console.log(IdLiv,"livid")
    this.deleteLivraison(IdLiv);
    return IdLiv;
  };
  handlefileChange = (e) => {
    let fields = this.state.fields;
    if (e.target.name === "tache_file") {
      if (this.fileValidation("tache_file")) {
        fields["tache_file"] = e.target.files[0];
      } else {
        this.setState({
          isInvalidFileExtension: true,
        });
        setTimeout(() => {
          this.setState({
            isInvalidFileExtension: false,
          });
        }, 6000);
      }
    } else {
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields: fields,
    });
    // console.log(this.state.fields);
  };

  // called every time a file's `status` changes
  handleChangeStatus = ({ meta, file }, status) => {
    const files = [];
    if (status === "done") {
      // console.log(status, meta, file);
      // files.push(file);
      this.setState({ files: this.state.files.concat(file) });
    }

    // this.setState({ files: files });
    //console.log("filesss", this.state.files);
  };

  // receives array of files that are done uploading when submit button is clicked
  handleSubmit = (files, allFiles) => {
    // console.log(files.map((f) => f.meta));
    // console.log("all files", allFiles);
    allFiles.map((file) => {
      const formData = new FormData();
      formData.append("file", file.file);
    });

    // allFiles.forEach((f) => f.remove());
  };
  onDrop = (files) => {
    // alert("goooooooooooo");
    this.setState({
      files: files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
    const uploaders = files.map((file) => {
      const formData = new FormData();
      formData.append("file", file);

      return axios.post("https://httpbin.org/post", formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" },
      });
    });
  };

  deleteFiles = (id) => {
    // console.log(id,"delete id", id)
    axios
      .post(this.state.api + "archive_livraison_file/" + id)
      .then((res) => {
        if (res.data.success) {
          // console.log("res",res)
          this.props.getTachesBakeliste();

          toast.success(
            "Fichier archivé avec succes",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1500 }
          );
        } else {
          toast.error(
            "Erreur la suppression de cette fichier",
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 1500 }
          );
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

 
  deleteLivraison = (id) => {
    const Id = id[0];
    // console.log(id,"delete id", Id)
    axios
      .post(this.state.api + "archive_livraison/" + Id)
      .then((res) => {
        if (res.data.success) {
          this.props.getTachesBakeliste();
          // console.log("res",res)
          toast.success(
            "livraison archivé avec succes",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1500 }
          );
        } else {
          toast.error(
            "Erreur la suppression de cette livraison",
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 1500 }
          );
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  
  onhandleChange = (e) => {
    e.preventDefault();

    let { newComment } = this.state;
    newComment.comment_text = e.target.value;

    this.props.fichiers.map((item, indexx) => {
      if (indexx === 0) {
        this.setState({ liv_id: item.livraisons_id });
        newComment.livrable_id = item.livraisons_id;
      }
    });
    newComment.user_id = this.state.user_id;
    newComment.user_type = this.state.user_type;
    newComment.bakeliste_user_id = this.props.bakeliste_id;
    newComment.tache_content = this.props.tache_content;
    this.setState({ newComment });
  };

  // addReactiononLivrable = (e) => {
  //   console.log(this.props.iteme,'eee')
  //   var fd = new FormData();
  //   fd.append("user_id", this.state.bakeliste_id );
  //   fd.append("user_type", 'bakeliste');
  //   fd.append("bakeliste_user_id", this.state.bakeliste_id );
  //   fd.append("tache_content",this.props.titre,)
  //   e.preventDefault();
  //   e.stopPropagation();
  //   this.props.onSubmitComment(this.state.newComment, this.props.iteme, fd);
  //   e.target.reset();
  // };
  addReactiononLivrable = (e, fields, item) => {
    e.preventDefault();
    e.stopPropagation();
    const livraison = item;
    var eventProperties = {
      user_id: this.state.bakeliste_id,
      email: this.state.email,
    };
    console.log(fields,"fields")
   
    var fd = new FormData();
      fd.append("user_id", this.state.bakeliste_id );
      fd.append("user_type", 'bakeliste');
      // fd.append("bakeliste_user_id", this.state.bakeliste_id );
      fd.append("tache_content",this.props.titre,)
      fd.append("newComment", this.state.newComment)


    axios
      .post(this.state.api + "comment/store",fd)
      .then((response) => {
        amplitude
          .getInstance()
          .logEvent("COMMENTAIRE_LIVRAISON_V2", eventProperties);
        const data = response.data.data;
        data.fullname = this.state.userFullName;

        // const item1 = livraison.find((item) => item.id === data.commentable_id);
        item.comments.push(data);
        const livraisonsSyllabus = [...this.state.livraisonsSyllabus];
        const index = livraisonsSyllabus.findIndex((it) => it.id === item.id);
        livraisonsSyllabus[index].livraisons = livraison;
        this.setState({ livraisonsSyllabus });
        var event = {
          user_id: this.state.bakeliste_id,
          email: this.state.email,
        };
        this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
        amplitude
          .getInstance()
          .logEvent("COMMENTAIRE_LIVRAISON_BAKELISTE_V2", event);
      })

      .catch((error) => {
        // console.log(error);
      });
  };

  render() {
    // console.log(this.props.commentaires, "comment", this.props.commentaires[0])
    
    const renderCarouselSlides = () =>
      this.props.fichiers.map((item) =>
        item.map((i) => (
          <div>
            <img src={this.state.base_api_url + i.file_path} />
            <Tooltip title="Supprimer cette livraison">
              <FeatherIcon
                icon="trash-2"
                className="icon-btn-livrable-bakeliste-itemS"
                onClick={() => this.deleteFiles(i.id)}
              />
            </Tooltip>
          </div>
        ))
      );

    return (
      <Amplitude>
        <div className="component-livrable-bakeliste-item">
          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 dis-flex itm-center mb-md-0 mb-3">
              <div>
                <p className="num-tache-livrable-bakeliste-item">
                  Tâches n°{this.props.tache_number}
                </p>
                <p className="date-livrable-bakeliste-item">
                  {this.props.date_tache}
                </p>
                {this.props.syllabus !== undefined && (
                  <p className="icon-btn-livrable-bakeliste-item">
                    {this.props.syllabus}
                  </p>
                )}
              </div>
            </div>
            <div className="col-xl-7 pr-xl-5 col-lg-6 col-md-7 pr-md-5 col-sm-9 mb-md-0 mb-3 separateur-livrable-bakeliste-item">
              <p className="titre-tache-livrable-bakeliste-item">
                {this.props.titre}
              </p>
              <p className="content-tache-livrable-bakeliste-item"></p>
            </div>
            <div className="col-xl-3 pl-xl-0 col-lg-4 col-md-3 pl-md-0 col-sm-6 ml-auto dis-flex itm-center">
              <div className="row">
                <div className="col-xl-12 mb-3">
                  <button
                    className="btn-livrable-bakeliste-item"
                    onClick={() => this.open("md")}
                  >
                    <FeatherIcon
                      icon="eye"
                      className="icon-btn-livrable-bakeliste-item"
                    />
                    Voir les livrables
                    <span className="badge badge-livrable">
                      {/* {this.props.fichiers.length} */}
                      {/* {this.props.livraisons.length} */}
                      {/* {this.props.fichiers.length} */}
                    </span>
                  </button>
                </div>

                <div className="col-xl-12 mb-3">
                  <button
                    className="btn-theme-green text-up w-full"
                    onClick={() => this.openC("md")}
                  >
                    <FeatherIcon
                      icon="eye"
                      className="icon-btn-livrable-bakeliste-item"
                    />
                    Commentaires
                  </button>
                </div>

                <div className="col-xl-12">
                  {/* <button
                    className="btn-theme-red text-up w-full"
                    onClick={() => this.getIdLivraison(this.props.fichiers)}

                  >
                    <FeatherIcon
                      icon="trash-2"
                      className="icon-btn-livrable-bakeliste-item"
                    />
                    Supprimer
                  </button> */}
                  {/* <AddLivrableBakelisteDashboard
                  titre={this.props.tache_number}
                  id={this.props.id}
                /> */}
                  {/* <button
                    className="btn-theme text-up w-full"
                    onClick={() => this.openLivrable("md")}
                  >
                    <FeatherIcon
                      icon="send"
                      className="icon-btn-livrable-bakeliste-item"
                    />
                    Envoyer mon travail
                  </button> */}
                </div>
              </div>
              <Modal
                className="modal-responsive-md"
                size={this.state.size}
                show={this.state.showC}
                onHide={this.closeC}
              >
                <h5
                  className="modal-bakeliste-title m-b-50"
                  id="modalShowLivrableDetailsTitle"
                >
                  Commentaires du livrables de la tâche {this.props.titre}
                </h5>

                <button
                  className="close-modal-bakeliste-title"
                  onClick={this.closeC}
                >
                  <FeatherIcon icon="x" aria-hidden="true" />
                </button>
                <div >
                {this.props.commentaires[0].length > 0 ? (
                this.props.commentaires[0].map((i, ix) =>
                
                      <li className="clearfix" >
                        <div className="post-comments mt-3">
                          {/* {i.user.first_name} {i.user.last_name} */}
                          <i className="p-3">
                            {i.user_type === "bakeliste" ? (
                              <small className="bakeliste">{i.user_type}</small>
                            ) : (
                              <small className="coach">Coach</small>
                            )}
                          </i>
                          <span className="meta pull-right">
                            {this.state.frenchFormat(i.created_at)}{" "}
                          </span>
                          <p className="comment mt-3">{i.comment_text}</p>
                        </div>  
                 
                      </li>
        
                )) :( 
                
                  <p className="pComment">Pas de commentaires sur cette livraison!!!.</p>

                )}
{/*               
        <form onSubmit={this.addReactiononLivrable}>
        <div className="row m-t-25 m-b-5">
          <div className="px-lg-2 col-sm-2 col-3 px-md-3 px-sm-2 dis-flex itm-center justify-content-center"></div>
          <div className="col-sm-10 col-9 pl-0 pr-2 pr-sm-2 pr-md-3">
            <InputGroup inside>
              <InputGroup.Addon>
                <img
                  className="icon-comment-fil-actualite"
                  src={IconComment}
                  alt=""
                />
              </InputGroup.Addon>
              <input
                type="text"
                placeholder="Ajouter un commentaire"
                className="input-comment-fil-actualite"
                name="comment"
                // value={this.state.newComment.commentaire}
                onChange={this.onhandleChange}
              />
              <button
                className="btn-send-comment-fil-actualite"
                type="submit"
              >
                <img
                  className="icon-btn-send-comment-fil-actualite"
                  src={IconSendComment}
                  alt=""
                />
              </button>
            </InputGroup>
          </div>
        </div>
      </form>
   
             */}
                
                </div>
                {/* {this.props.commentaires[0].map((d) => (
                  <>
                   
                    <div className="post-comments mt-3">
                      <span className="meta pull-right">
                        {this.state.frenchFormat(d.created_at)}{" "}
                      </span>
                      <p className="comment mt-3"> {d.comment_text}</p>
                    </div>

                  </>
                ))} */}
              </Modal>

              <Modal
                className="modal-responsive-md"
                size={this.state.size}
                show={this.state.show}
                onHide={this.close}
              >
                <h5
                  className="modal-bakeliste-title m-b-50"
                  id="modalShowLivrableDetailsTitle"
                >
                  Livrables
                </h5>

                <button
                  className="close-modal-bakeliste-title"
                  onClick={this.close}
                >
                  <FeatherIcon icon="x" aria-hidden="true" />
                </button>

                <h2 className="title-modal-bakeliste-title">
                  Tâches n°{this.props.tache_number}
                </h2>
                {/* <div className="row"> */}
                {/* <Gallery images={this.state.images} /> */}
                <Carousel>{renderCarouselSlides()}</Carousel>
                {/* </div> */}
              </Modal>

              {/* Alert Livrable envoye avec succes */}
              <SweetAlert
                show={this.state.showAlertSuccess}
                onConfirm={() => this.setState({ showAlertSuccess: false })}
                success
                title="Livrable envoyé avec succes!!!"
                style={{
                  color: "#069A77",
                  fontSize: "12px",
                  fontFamily: "Work Sans, sans-serif",
                  paddingTop: 60,
                  paddingBottom: 40,
                }}
                confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
              />
              {/* ENd ALert Succes */}

              {/* Alert Livrable non envoye */}
              <SweetAlert
                show={this.state.showAlertError}
                onConfirm={() => this.setState({ showAlertError: false })}
                error
                title="Erreur envoie livrable, veuillez reessayer!!!"
                style={{
                  color: "#d9534f",
                  fontSize: "12px",
                  fontFamily: "Work Sans, sans-serif",
                  paddingTop: 60,
                  paddingBottom: 40,
                }}
                confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
              />
              {/* ENd ALert Error */}
              <Modal
                className="modal-responsive-md"
                size={this.state.sizelivrable}
                show={this.state.showlivrable}
                onHide={this.closeLivrable}
              >
                <h5
                  className="modal-bakeliste-title m-b-50"
                  id="modalSendLivrableTitle"
                >
                  Envoyer mon travail
                </h5>
                <button
                  className="close-modal-bakeliste-title"
                  onClick={this.closeLivrable}
                >
                  <FeatherIcon icon="x" aria-hidden="true" />
                </button>

                <form
                  encType="multipart/form-data"
                  id="livrable_form"
                  onSubmit={(e) => this.onSendLivrables(e)}
                >
                  <div className="form-group">
                    {/* <select
                    name="tache_id"
                    className="form-control select-modal-content-bakeliste"
                    id="addLivrableSelectTache"
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.fields["tache_id"]}
                  >
                    <option>Choisir une tache</option>

                    {this.state.listetaches.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.content}
                      </option>
                    ))}
                  </select> */}
                    <label
                      id="tache_id"
                      name="tache_id"
                      className="form-control select-modal-content-bakeliste"
                      value={(this.state.fields["tache_id"] = this.props.id)}
                      onChange={(e) => this.handleChange(e)}
                    >
                      Tache: {this.props.titre}
                    </label>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control input-modal-content-bakeliste p-t-15"
                      rows="9"
                      placeholder="Ajouter un commentaire"
                      name="comment"
                      value={this.state.fields["comment"]}
                      onChange={(e) => this.handleChange(e)}
                    ></textarea>
                  </div>
                  <Dropzone
                    required
                    getUploadParams={this.getUploadParams}
                    onChangeStatus={this.handleChangeStatus}
                    // onSubmit={this.handleSubmit}
                    onChange={(e) => this.handlefileChange(e)}
                    onDrop={this.onDrop.bind(this)}
                    accept="image/*,audio/*,video/*"
                    inputContent={"Cliquer pour ajouter des images"}
                    inputWithFilesContent={"Ajouter plus..."}
                  />
                  {this.state.isSendingLivrableInProgress ? (
                    <button
                      type="submit"
                      className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                      disabled
                    >
                      Envoie en cours &nbsp;
                      <FeatherIcon icon="loader" aria-hidden="true" />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                    >
                      ENVOYER
                    </button>
                  )}
                </form>
              </Modal>
            </div>
          </div>
        </div>
      </Amplitude>
    );
  }
}
// export default connect(
//     ({ livrableBakelisteItem }) => ({ ...livrableBakelisteItem }),
//     dispatch => bindActionCreators({ ...livrableBakelisteItemActions }, dispatch)
//   )( livrableBakelisteItem );

// submitAddSyllabus = async (e) => {
//   e.preventDefault();
//   if (this.onValidateAddFormData()) {
//     this.setState({
//       addSyllabusInProgress: true,
//     });
//     //var baseApiUrl = this.state.api;
//     var data = this.state.fields;
//     console.log("data", data)
//      axios
//       .post(this.state.api + "syllabus/create", this.state.fields)
//       .then((response) => {
//         console.log(response)
//         this.setState({
//           addSyllabusInProgress: false,
//         });
//         console.log(response.data.success)
//         if (response.data.success) {
//           this.onGetSyllabus();
//           $(".close-modal-coach-title").click();
//           //$("#syllabus_form").reset();
//           //this.onGetSyllabus();
//           toast.success("Syllabus ajouté avec succées!", {
//             position: toast.POSITION.BOTTOM_RIGHT
//           });

//             this.setState({
//               // showAlertSuccess: true,
//               isSuccessSending: true
//             })
//             setTimeout(() => {
//               this.setState({
//                 isSuccessSending: false
//               })
//             },6000);
//         }
//         // $(".close-modal-bakeliste-title").click();
//       })
//       .catch((error) => {
//         console.log("error",error)
//         if ((error.message = "Request failed with status code 422")) {
//           toast.error("Oups! Une erreur est survenue lors de l\'ajout",{
//             position: toast.POSITION.BOTTOM_RIGHT
//           });

//         }
//         this.setState({
//           addSyllabusInProgress: false,
//           // showAlertError: true,
//           isErrorSending: true
//         })
//         setTimeout(() => {
//           this.setState({
//               isErrorSending: false
//           })
//       }, 6000);

//         if (process.env.NODE_ENV !== "production") {
//           return;
//         }
//         Sentry.captureException(error);
//       });
//   }
// };
