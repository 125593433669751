import React, {Component} from 'react';
import './tachesBakelisteValidees.css'
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItem from "../bakelisteGroupItem/index";
import NextReunionItem from "../nextReunionItem/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import Preloader from "../preloader/index";

import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import ModalContactUs from "../modalContactUs/index";
import $ from "jquery";
import API from "../../variablesGlobales";
import base_api_url from "../../variablesGlobales";
import frenchFormatDate from "../frenchFormatDate";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "react-js-pagination";
import Dropzone from "react-dropzone-uploader";
import { Modal } from "rsuite";
import { Amplitude } from "@amplitude/react-amplitude";
import amplitude from "amplitude-js";
import Loader from "react-loader-spinner";
import ReactPlayer from "react-player";
import EnvoyerMonTravail from "../envoyerMonTravail/index";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tachesBakelisteValideesActions from "../../store/tachesBakelisteValidees/actions";
export default class tachesBakelisteValidees extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isload: true,
          bakeliste_id: sessionStorage.getItem("user_id"),
          email: sessionStorage.getItem("email"),
          coach_id: sessionStorage.getItem("coach_id"),
          tachesValidees:[],
          api:API.API,
          taches:[]
        };
        Sentry.init({
          dsn:
            "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
        });
    }

    
    componentDidMount() {
      this.getTachesValidees();
  
      this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
      amplitude.getInstance().logEvent("bakelisteTachesValideesPageVisite");
      setTimeout(() => {
        this.setState({
          isload: false,
        });
      }, 5000);
    }

    isValidURL = (string) => {
      var res = string.match(
        // /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
      );
      return res !== null;
    };
    componentDidCatch(err, info) {
      Sentry.captureException(err);
    }
  
    amplitudeScript = (api_key) => {
      (function (e, t) {
        var n = e.amplitude || { _q: [], _iq: {} };
        var r = t.createElement("script");
        r.type = "text/javascript";
        r.integrity =
          "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
        r.crossOrigin = "anonymous";
        r.async = true;
        r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
        r.onload = function () {
          if (!e.amplitude.runQueuedFunctions) {
          }
        };
        var i = t.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(r, i);
        function s(e, t) {
          e.prototype[t] = function () {
            this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
            return this;
          };
        }
        var o = function () {
          this._q = [];
          return this;
        };
        var a = [
          "add",
          "append",
          "clearAll",
          "prepend",
          "set",
          "setOnce",
          "unset",
        ];
        for (var u = 0; u < a.length; u++) {
          s(o, a[u]);
        }
        n.Identify = o;
        var c = function () {
          this._q = [];
          return this;
        };
        var l = [
          "setProductId",
          "setQuantity",
          "setPrice",
          "setRevenueType",
          "setEventProperties",
        ];
        for (var p = 0; p < l.length; p++) {
          s(c, l[p]);
        }
        n.Revenue = c;
        var d = [
          "init",
          "logEvent",
          "logRevenue",
          "setUserId",
          "setUserProperties",
          "setOptOut",
          "setVersionName",
          "setDomain",
          "setDeviceId",
          "enableTracking",
          "setGlobalUserProperties",
          "identify",
          "clearUserProperties",
          "setGroup",
          "logRevenueV2",
          "regenerateDeviceId",
          "groupIdentify",
          "onInit",
          "logEventWithTimestamp",
          "logEventWithGroups",
          "setSessionId",
          "resetSessionId",
        ];
        function v(e) {
          function t(t) {
            e[t] = function () {
              e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
            };
          }
          for (var n = 0; n < d.length; n++) {
            t(d[n]);
          }
        }
        v(n);
        n.getInstance = function (e) {
          e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
          if (!n._iq.hasOwnProperty(e)) {
            n._iq[e] = { _q: [] };
            v(n._iq[e]);
          }
          return n._iq[e];
        };
        e.amplitude = n;
      })(window, document);
  
      amplitude.getInstance().init(api_key);
    };
  
    getTachesValidees = () => {

      axios
        .get(
          this.state.api +
            "get_bakeliste_validated_tasks/" + this.state.bakeliste_id
        )
        .then((response) => {
           var data = response.data;
          this.setState({
            tachesValidees: response.data,
          });
          data.forEach((item) => {
            let infos = item.task
            // = {
            //   task: item.task,
            // };
            this.state.taches.push(infos);
          });
          // console.log(this.state.taches)
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    };
    render() {
      var {
        per_page,
        total_todo,
        current_page,
        current_list_todo,
        tasksyllabusdone,
        tasksyllabustodo,
      } = this.state;
  
      return this.state.isload ? (
        <div>
          <Preloader />
        </div>
      ) : (
        <div className="p-b-200">
          <div className="row">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb fixed">
                <ol className="breadcrumb fixed">
                  <li className="breadcrumb-item ">
                    <Link to="/dashboard-bakeliste">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Tâches Validées
                  </li>
                {/* <div className="search offset-md-5">
                  <div className="input-group size1"> */}
                    {/* <div className="input-group-prepend SearchB offset-md-5">
                      <FeatherIcon className="search_icon" icon="search" />
                      <input
                        value={this.state.rechercheTache}
                        type="search"
                        className="form-control form-control-costom"
                        placeholder="Chercher une tache"
                        aria-label="Search"
                        aria-describedby="search-addon" 
                        icon="Search"
                        onChange={(e) =>
                          this.setState({
                            rechercheTache: e.target.value,
                          })
                        }
                      />
                    </div> */}
                 
                  {/* </div>
                </div> */}
          
                </ol>
              </nav>
              <div className="row m-b-15 pt6">
                <div className="col m-b-15">
                  <h6 className="h-theme ff-inter fw-600 ">
                    Liste de vos tâches validées
                  </h6>
                </div>
              </div>
  
            
              {/* Toutes les taches du baekliste */}
              {/* <InfiniteScroll
                className="hello"
                dataLength={this.state.tachesValidees.length}
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                loader={
                  this.state.loading &&
                  "Les donnees sont entrain de se charger...."
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Vous avez tous visualise!!!</b>
                  </p>
                }
              >
              */}
                {
                  this.state.taches.length > 0 &&
                    this.state.taches.map((item, index) => (
                      <div className="col-xl-12 col-lg-12 col-md-9">
                        <div className="card-dashboard m-b-15">
                          <div className="row mb-4">
                            <div className="col-sm-8 mb-sm-0 mb-3">
                              <p className="h-theme ff-work fw-500">
                              </p>
                              <p className="ff-work fs-14 fw-500 text-dark">
                                {item.syllabus_task}
                              </p>
                              {item.description}
                            </div>
                            <div className="col-sm-4 mb-sm-0 mb-3 itm-right">
                              <FeatherIcon
                                className="icon-tache-bakeliste-limit-hours"
                                icon="clock"
                              />
                              <div>
                                <p className="tache-bakeliste-limit-hours fw-300 text-up">
                                  Duree tache :
                                </p>
                                <p className="tache-bakeliste-limit-hours fw-500 text-cap">
                                  {item.syllabus_completion_time} h
                                </p>
                              </div>
                              <p className="fs-12 m-t-30">
                                <span className="flag">{item.syllabus_name}</span>
                              </p>
                            </div>
                          </div>
  
                          <p className="ff-work fs-12 fw-500 text-dark m-b-10">
                            Liens utiles
                          </p>
  
                          {item.tache_url !== null &&
                          this.isValidURL(item.tache_url) ? (
                            <div>
                              <ReactPlayer
                                url={item.tache_url}
                                width="100%"
                                controls={true}
                              />
                            </div>
                          ) : (
                            <a
                              className="lien-utile-bakeliste-tache-card"
                              href={item.tache_url}
                              target="blank"
                            >
                              {item.tache_url}
                            </a>
                          )}
  
                          {item.tache_url !== null && item.tache_file ? (
                            <div classname="thumbnail">
                              <p className="ff-work fs-12 fw-500 text-dark m-b-10 m-t-30">
                                Pièces jointes
                              </p>
                              <img
                                src={this.state.base_api_url + item.tache_file}
                                alt="fichier tache"
                                style={{ width: "100px" }}
                              />
                            </div>
                          ) : (
                            <div classname="thumbnail">
                            </div>
                          )}
                         <div className="col-md-3 offset-md-8">
                          <div
                            type="button"
                            className="btn-theme-orange text-up w-full"
                          >
                            Tache validée
                            <FeatherIcon icon="check" aria-hidden="true" />
                          </div>
                        </div>
                        </div>
                      </div>
                    ))
                  // ))
                }

              {/* </InfiniteScroll> */}
  
             
            </div>
            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
               
                <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                  <CoachItemDashboardBakeliste />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                  <BakelisteGroupItem />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                  <NextReunionItem />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
// export default connect(
//     ({ tachesBakelisteValidees }) => ({ ...tachesBakelisteValidees }),
//     dispatch => bindActionCreators({ ...tachesBakelisteValideesActions }, dispatch)
//   )( tachesBakelisteValidees );