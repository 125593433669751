import React, {useEffect,useState} from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchLivraisons } from '../redux/actions/livraisonsActions';
 import './showAllLivraisonsv2.css';
 import axios from 'axios';
 import LivraisonsItemv2 from '../livraisonsItemv2'
import AddLivraisonsv2 from '../addLivraisonsv2/addLivraisonsv2';
import API2 from '../../../variablesGlobales'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AmplitudeScript from '../../../amplitudeScript';
import amplitude from 'amplitude-js'

toast.configure();
const userFullName = window.sessionStorage.getItem("userFullName")
const user_id = window.sessionStorage.getItem("user_id")

const ShowAllLivraisonsv2 = (props)=>{

  const [livraisons, setLivraisonsSyllabus]=useState(props.livraisons)
  AmplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("bakelisteaddLivrableButtonClick_v2_Redux")

  const dispatch = useDispatch();
  useEffect(() => { fetchLivraisons(dispatch); }, [dispatch])

  const addCommentOnLivrable = (fields, item) => {
    const livraison = item.livraisons;
    const eventProperties = {
      user_id: user_id,
      name: userFullName,
    };
    axios
      .post(API2.API2 + "add/livraison_comments", fields)
      .then((response) => {
        amplitude
          .getInstance()
          .logEvent(
          "COMMENTAIRE_LIVRAISON_Success_v2_Redux",
              eventProperties
          );
        const data = response.data.data;
         data.fullname = userFullName;
        item.comments.push(data);
        const livraisonSyllabus = [...livraisons];
        const index = livraisonSyllabus.findIndex((it) => it.id === item.id);
        livraisonSyllabus[index].livraisons = livraison;
        setLivraisonsSyllabus(livraisonSyllabus)
      })
      .catch((error) => {
        console.log(error)
        amplitude
        .getInstance()
        .logEvent(
        "COMMENTAIRE_LIVRAISON_Error_v2_Redux",
            eventProperties
        );
      });
  };
  return (
    <>
   <AddLivraisonsv2/>
     {props.livraisons.map((item, index) => (
       <LivraisonsItemv2
       key={item.id}
       livraisons={item}
       fichiers = {item.fichiers.reverse()}
       comments={item.comments}
       livraison_id={item.id}
        onSubmitComment={addCommentOnLivrable}
       item={item}
       index={index + 1}
       bakeliste_id={item.bakeliste_user_id}
       fullname={item.bakeliste.user_fullname}
       tache_content={item.details_syllabus.task}
       date={item.updated_at}
     />
    ))}
    </>
  );
}


const mapStateToprops =state=>{
  return{
   livraisons: state.livraisons.items,
  loading:state.livraisons.loading,
  error:state.livraisons.error,
  }
}

export default connect(mapStateToprops,{fetchLivraisons})(ShowAllLivraisonsv2)