import React, { Component } from "react";
import "./viewListeQuestions.css";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";
import ContentListeQuestions from "../contentListeQuestions/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as viewListeQuestionsActions from "../../store/viewListeQuestions/actions";
export default class viewListeQuestions extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // } 
  render() {
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0"> 
              <NavVerticalCoach />
            </div>
            <div className="col-lg-10 pt-3">
              <ContentListeQuestions />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ viewListeQuestions }) => ({ ...viewListeQuestions }),
//     dispatch => bindActionCreators({ ...viewListeQuestionsActions }, dispatch)
//   )( viewListeQuestions );
