import React, { Component } from "react";
import "./dashboardCoachAssistantView.css";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";
import DashboardCoachAssistant from "../dashboardCoachAssistant/index";
import Loader from "react-loader-spinner";
import NavVerticalCoachAssistant from "../navVerticalCoachAssistant/index";

export default class dashboardCoachAssistantView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  componentDidMount = () => {
    // setTimeout(() => {
    //   this.setState({
    //     loading: false,
    //   });
    // }, 5000);
  };

  render() {
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoachAssistant />
            </div>

            <div className="col-lg-10 pt-3">
              <DashboardCoachAssistant />
              {/* </>}  */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ dashboardCoachView }) => ({ ...dashboardCoachView }),
//     dispatch => bindActionCreators({ ...dashboardCoachViewActions }, dispatch)
//   )( dashboardCoachView );
