import React, { Component } from "react";
import "./resultatExamenTheorique.css";
import { Link } from "react-router-dom";

import resultatimg from "../../image/resultat-img.png";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as resultatExamenTheoriqueActions from "../../store/resultatExamenTheorique/actions";
export default class ResultatExamenTheorique extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-resultat-examen-theorique">
        <div className="col-sm-12 pb-5">
          <h2 className="title_exam">Résultat de l’examen théorique</h2>
        </div>

        <div className="container-fluid bg-white mb-5">
          <h1 className="resultat-title">Merci pour votre participation</h1>
          <div className="pl-5 pr-5">
            <div className="row card-resultat-two ">
              <div className="col-xl-6 col-lg-6 col-md-6 pt-5">
                <img className="resultat-img" src={resultatimg} alt="" />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 pt-5 pl-5">
                <div className="score-title pt-5 mb-4">
                  <span className="score-title">Votre score est</span>
                </div>
                <div className="score-numbe">
                  <span className="score-number">{this.props.score}/20</span>
                </div>
                <div className="percentage-two pt-4">
                  <div className="percentage">Soit 60% de réussite</div>
                </div>
              </div>
            </div>
            <div className="offset-2 pt-4 pb-5">
              <span className="text-prochain">
                Bien... vous pouvez mieux faire la prochaine fois
              </span>
            </div>
          </div>

          <div className="pl-4 pr-4 pb-5">
            <div className="card">
              <span className="result-final pt-2">Résultat final</span>
              <hr />
              <div>
                {/* <p>
                  <span className="txt-not-final pl-5">
                    Nombre total de questions{" "}
                  </span>{" "}
                  <span className="offset-5 txt-note">10</span>
                </p> */}
              </div>
              <div>
                <p>
                  <span className="txt-not-final pl-5">
                    Nombre total de questions{" "}
                  </span>{" "}
                  <span className="offset-5 txt-note">{this.props.nbq}</span>
                </p>
              </div>
              <div>
                <p>
                  <span className="txt-not-final pl-5">
                    Nombre total de questions trouvees{" "}
                  </span>{" "}
                  <span className="offset-5 txt-note">
                    {this.props.nbqtrue}
                  </span>
                </p>
              </div>
              <div>
                <p>
                  <span className="txt-not-final pl-5">
                    Nombre total de questions faussees{" "}
                  </span>{" "}
                  <span className="offset-5 txt-note">
                    {this.props.nbqfalse}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ resultatExamenTheorique }) => ({ ...resultatExamenTheorique }),
//     dispatch => bindActionCreators({ ...resultatExamenTheoriqueActions }, dispatch)
//   )( resultatExamenTheorique );
