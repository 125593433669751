import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import undraw_question from "../../image/undraw_question.png";
import EnvoyerMonExamen from "../envoyerMonExamen/index";
import API from "../../variablesGlobales";
import "./contentExamenBakeliste.css";
import axios from "axios";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentExamTheoriqueTwoActions from "../../store/contentExamTheoriqueTwo/actions";
export default class contentExamenBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      examen: [],
      examen_id: 1,
      api: API.API,
    };
  }
  componentDidMount = () => {
    this.getExamenPratique();
  };
  getExamenPratique(e) {
    axios
      .get(this.state.api + "examen_pratiques/" + this.state.examen_id)
      .then((res) => {
        const exam = res.data;
        this.setState({
          examen: res.data.data,
        });
        // console.log("res", this.state.examens);
        // console.log("exam", res.data);
      })
      .catch((error) => {
        // console.log("error");
      });
  }
  render() {
    return (
      <div className="component-content-exam-theorique-two">
        <div className="p-b-200 container-fluid px-md-3 p-0">
          <div className="row">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb fixed">
                <ol className="breadcrumb fixed">
                  <li className="breadcrumb-item ">
                    <Link to="coach-dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Examens
                  </li>
                </ol>
              </nav>

              <div className="row pt-5 pb-5">
                <ul
                  className="nav nav-tabs nav-theorique pl-3"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-item nav-link active"
                      id="nav-home-tab"
                      data-toggle="tab"
                      href="#nav-home"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      Examen théorique
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-item nav-link"
                      id="nav-profile-tab"
                      data-toggle="tab"
                      href="#nav-profile"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      Examen Pratique
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="card card-exam pb-5">
                    <div className="entete-question pt-2 pl-4 pb-3">
                      <div className="row partie-theorique pt-4 pl-4">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                          <div className="row mt-5">
                            <div className="start-exam">
                              <NavLink to="/faire-examen" className=" p-3">
                                {" "}
                                Commencer l'examen
                              </NavLink>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              15mn 00s
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                          <div className="article-img">
                            <img
                              className="undraw_question"
                              src={undraw_question}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="part-two-title pt-3">
                      <p>Cocher la(les) bonne(s) réponse(s)</p>
                    </div> */}

                    {/* <div className="part-question-exam-one pt-4 pl-3">
                      <div className="exam-question-one pt-4">
                        <p>Arcu enim sit nunc placerat sit ac...</p>
                      </div>
                    </div>
                    <div className="part-question-exam-one pt-4 pl-3">
                      <div className="exam-question-one pt-4">
                        <p>Arcu enim sit nunc placerat sit ac...</p>
                      </div>
                    </div>
                    <div className="part-question-exam-one pt-4 pl-3">
                      <div className="exam-question-one pt-4">
                        <p>Arcu enim sit nunc placerat sit ac...</p>
                      </div>
                    </div>
                    <div className="row pt-5">
                      <div className="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-end">
                        <div className="btn-precedant pt-3">
                          <Link
                            to="/examen-theorique"
                            className="exam-btn-precedant"
                          >
                            <KeyboardBackspaceIcon />
                            Question précédente
                          </Link>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-center">
                        <div className="btn-voir-resultat pt-3">
                          <Link to="" className="exam-btn-voir-resultat">
                            Voir le résultat
                          </Link>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <div className="card card-exam-pratique pb-5">
                    <div className="card exam-pratique-entete">
                      <div className="school">
                        <p>Bakeli school of technology</p>
                      </div>
                      <div
                        className="date-exam pt-3"
                        onChange={(e) => this.getExamenPratique(e)}
                      >
                        <p>{this.state.examen.session}</p>
                      </div>

                      <div className="modul-exam pt-3">
                        <p>Design graphic-examen final</p>
                      </div>
                    </div>

                    <div className="sujet-pratique pt-5">
                      <p>{this.state.examen.titre}</p>
                    </div>

                    <div className="domaine pt-5">
                      <p>
                        Domaine : <span>{this.state.examen.module_id}</span>{" "}
                      </p>
                    </div>
                    <div className="niveau pt-3">
                      <p>
                        Niveau : <span>{this.state.examen.niveau}</span>{" "}
                      </p>
                    </div>
                    <div className="duree pt-3">
                      <p>
                        Durée : <span>{this.state.examen.duree}</span>{" "}
                      </p>
                    </div>
                    <div className="sujet pt-3">
                      <p>
                        Sujet : <span>{this.state.examen.titre}</span>{" "}
                      </p>
                      <div className="sujet-text pt-4">
                        <p>{this.state.examen.enonce}</p>
                      </div>
                    </div>
                    <div className="travail-a-faire pt-3">
                      <p>Travail à faire</p>
                      <div className="travail-a-faire-text pt-3">
                        <p>{this.state.examen.taf}</p>
                      </div>
                      <div className="travail-one pt-4 pl-5">
                        <p>
                          {" "}
                          <i>
                            <FiberManualRecordIcon />{" "}
                          </i>
                          <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. At ac praesent fusce nunc tristique ultricies
                            nibh euismod sit. At cursus volutpat scelerisque
                            elementum in praesent sollicitudin quis purus.
                          </span>
                        </p>
                      </div>
                      <div className="travail-two pt-4 pl-5">
                        <p>
                          {" "}
                          <i>
                            <FiberManualRecordIcon />{" "}
                          </i>
                          <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. At ac praesent fusce nunc tristique ultricies
                            nibh euismod sit. At cursus volutpat scelerisque
                            elementum in praesent sollicitudin quis purus.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="nb pt-4">
                      <p>
                        NB :{" "}
                        <span>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. At ac praesent fusce nunc tristique ultricies
                          nibh euismod sit. At cursus volutpat scelerisque
                          elementum in praesent sollicitudin
                        </span>{" "}
                      </p>
                    </div>
                  </div>

                  <div className="row pt-5 pb-5 oo ">
                    {/* <Link to="" className="exam-btn-precedant-pratique">
                        Envoyer mon travail
                        </Link> */}
                    <EnvoyerMonExamen id={this.state.examen_id} />

                    {/* <div className="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-center">
                      <div className="btn-voir-resultat-pratique pt-3">
                        <Link to="" className="exam-btn-voir-resultat-pratique">
                          <KeyboardBackspaceIcon />
                          Exercice précédente
                        </Link>
                      </div>
                    </div>*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteGroupItemForCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ contentExamenBakeliste }) => ({ ...contentExamenBakeliste }),
//     dispatch => bindActionCreators({ ...contentExamenBakelisteActions }, dispatch)
//   )( contentExamenBakeliste );
