import React, { Component } from "react";
import "./syllabusDetailsCoachView2.css";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";
import SyllabusDetailsCoach2 from "../syllabusDetailsCoach2/index";
import API from "../../variablesGlobales";
import  Loader  from "react-loader-spinner";
import Preloader from "../preloader/index";

import qs from "qs";
export default class syllabusDetailsCoachView2 extends Component {
  constructor(props) {
    super(props); 
    let syllabus_id = this.props.location?.state?.id;
    if (!syllabus_id && this.props.location.search) {
      const obj = qs.parse(this.props.location.search.replace("?", ""));
      syllabus_id = parseInt(obj.id);
    } 
    this.state = {
      syllabus_id: syllabus_id, 
      api: API.API,
      userID: window.sessionStorage.getItem("user_id"),
      syllabusDatas: [],
      taches: [],
      loading: true,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 4000);
  }
  render() {
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoach />
            </div>
            <div className="col-lg-10 pt-3">
            {this.state.loading ? (
                <div>
 <Preloader />
              </div>
            )
                
            : <>
              <SyllabusDetailsCoach2
                name={this.state.syllabusDatas.syllabus_name}
                niveau={this.state.syllabusDatas.syllabus_level}
                id={this.state.syllabus_id}
                description={this.state.syllabusDatas.syllabus_description}
                nbreTaches={this.state.nombreTaches}
                Taches={this.state.syllabusDatas.taches}
                lien={this.state.syllabusDatas.tache_url}
              /> 
              </>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//   // componentDidMount() {
//   //   console.log(this.props.location.state.id);

//   //   this.isLoading = setTimeout(() => {
//   //     this.setState({ loading: false });
//   //   }, 2000);
//   // }
//   // render() {
//   //   const { loading } = this.state;

//   //   return (
//   //     <div className="body-theme">
//   //       <HeaderDashboardCoach />
//   //       <div className="container-fluid fixing-after-header">
//   //         <div className="row">
//   //           <div className="col-lg-2 p-0">
//   //             <NavVerticalCoach />
//   //           </div>
//   //           {loading && (
//   //             <div className="col-lg-10 pt-3 offset-5">
//   //               <Loader
//   //                 type="ThreeDots"
//   //                 color="#009688"
//   //                 height={700}
//   //                 width={150}
//   //                 // timeout={50000} //3 secs
//   //               />
//   //             </div>
//   //           )}
//   //           <div className="col-lg-10 pt-3">
//   //             <nav aria-label="breadcrumb">
//   //               <ol className="breadcrumb">
//   //                 <li className="breadcrumb-item active">Dashboard</li>
//   //               </ol>
//   //             </nav>
//   //             <div>{this.state.syllabus_id}</div>
//   //             <SyllabusDetailsCoach2 />
//   //           </div>
//   //         </div>
//   //       </div>
//   //     </div>
//   //   );
//   // }
// }
// export default connect(
//     ({ syllabusDetailsView }) => ({ ...syllabusDetailsView }),
//     dispatch => bindActionCreators({ ...syllabusDetailsViewActions }, dispatch)
//   )( syllabusDetailsView );
