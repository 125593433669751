import React, { Component } from "react";
import "./ajoutCvBakelisteView.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import AjoutCvBakeliste from "../ajoutCvBakeliste/index";
import AjoutCvBakelisteRefac from "../ajoutCvBakeliste/AjoutCvBakelisteRefac";

export default class ajoutCvBakelisteView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="body-theme">
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVertical />
            </div>
            <div className="col-lg-10 pt-3">
              {/* <AjoutCvBakeliste/> */}
              <AjoutCvBakelisteRefac />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
