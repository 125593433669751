import React, {Component} from 'react';
import './contenusValidesView.css';
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import ContenusValides from "../contenusValides/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as ajouterResumeViewActions from "../../store/ajouterResumeView/actions";
export default class contenusValidesView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return ( 
      <div className="body-theme">
      <HeaderDashboard />
      <div className="container-fluid fixing-after-header">
        <div className="row"> 
          <div className="col-lg-2 p-0">
            <NavVertical />
          </div>
          <div className="col-lg-10 pt-3">
            <ContenusValides />
          </div> 
        </div>
      </div>
    </div>) }
  }
// export default connect(
//     ({ ajouterResumeView }) => ({ ...ajouterResumeView }),
//     dispatch => bindActionCreators({ ...ajouterResumeViewActions }, dispatch)
//   )( ajouterResumeView );