import React, { Component } from "react";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import nointernet from "../../img/nointernet.png";
import "./offlinecoach.css";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as offlinecoachActions from "../../store/offlinecoach/actions";
export default class offlinecoach extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  _refreshPage = () => {
    window.location.reload();
  };

  render() {
    return (
      <div className="p-b-200">
        <div className="row justify-content-center ">
          <div className="col-lg-9">
            <div className="card cardd ">
              <div className="card-header card-headerr pb-0 bg-white border-0 text-center px-sm-4">
                <h6 className="text-left mt-4 font-weight-bold mb-0">
                  Pas de connexion{" "}
                </h6>{" "}
                <button
                  type="button"
                  className="btn btn-successs"
                  onClick={this._refreshPage}
                >
                  <span>Veuillez Reessayer</span>
                </button>
                <span className="img-1 text-center">
                  <img src={nointernet} className="img-fluid my-4 offline" />
                </span>
              </div>
              <div className="card-body px-sm-4 mb-3">
                <div className="row justify-content-end mt-4 ">
                  <div className="col-auto">
                    {/* <button
                      type="button"
                      className="btn btn-successs"
                      onClick={this._refreshPage}
                    >
                      <span>Reessayer</span>
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteGroupItemForCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
