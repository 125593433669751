import React, {Component} from 'react';
import './ajoutSousGroupeCoachAssistant.css';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import * as Sentry from "@sentry/react";
import { Link } from 'react-router-dom';
import NavVerticalCoachAssistant from '../navVerticalCoachAssistant/index';
import BakelisteGroupItemForCoach from '../bakelisteGroupItemForCoach/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import HeaderDashboardCoach from '../headerDashboardCoach/index';
import API from "../../variablesGlobales";
import $ from "jquery";
import SweetAlert from "react-bootstrap-sweetalert";
import amplitude from "amplitude-js";
 
import qs from "qs"




export default class ajoutSousGroupeCoachAssistant extends Component {
  
    constructor(props) {
        super(props);
        
        this.state = {
            Bakeliste:[],
            showAlertSuccess: false,
            showAlertError: false,    
            api: API.API,
            groupeId: this.props.location.state.id,
            isErrorAjout: false,
            name:"",
            lien_whatsapp:"",
            userID: window.sessionStorage.getItem('user_id'),
            addSGroupInProgress: false,
            newSousGroup: {
                description:  "",
                name: "",
                lien_whatsapp: ""
            },
            groupes:[],

        };
       // this.addGroupe = this.addGroupe.bind(this);
       this.addSousGroup = this.addSousGroup.bind(this);

    }
    componentDidMount(){
        var eventProperties = {
            userID : this.state.userID,
       
          }
           this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
           amplitude.getInstance().logEvent("VISITED_PAGE_GROUPES_COACH_ASSISTANT_V2", eventProperties);
       
        this.getSousGroupes();
    }
    amplitudeScript = (api_key) => {
        (function (e, t) {
          var n = e.amplitude || { _q: [], _iq: {} };
          var r = t.createElement("script");
          r.type = "text/javascript";
          r.integrity =
            "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
          r.crossOrigin = "anonymous";
          r.async = true;
          r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
          r.onload = function () {
            if (!e.amplitude.runQueuedFunctions) {
            }
          };
          var i = t.getElementsByTagName("script")[0];
          i.parentNode.insertBefore(r, i);
          function s(e, t) {
            e.prototype[t] = function () {
              this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
              return this;
            };
          }
          var o = function () {
            this._q = [];
            return this;
          };
          var a = [
            "add",
            "append",
            "clearAll",
            "prepend",
            "set",
            "setOnce",
            "unset",
          ];
          for (var u = 0; u < a.length; u++) {
            s(o, a[u]);
          }
          n.Identify = o;
          var c = function () {
            this._q = [];
            return this;
          };
          var l = [
            "setProductId",
            "setQuantity",
            "setPrice",
            "setRevenueType",
            "setEventProperties",
          ];
          for (var p = 0; p < l.length; p++) {
            s(c, l[p]);
          }
          n.Revenue = c;
          var d = [
            "init",
            "logEvent",
            "logRevenue",
            "setUserId",
            "setUserProperties",
            "setOptOut",
            "setVersionName",
            "setDomain",
            "setDeviceId",
            "enableTracking",
            "setGlobalUserProperties",
            "identify",
            "clearUserProperties",
            "setGroup",
            "logRevenueV2",
            "regenerateDeviceId",
            "groupIdentify",
            "onInit",
            "logEventWithTimestamp",
            "logEventWithGroups",
            "setSessionId",
            "resetSessionId",
          ];
          function v(e) {
            function t(t) {
              e[t] = function () {
                e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
              };
            }
            for (var n = 0; n < d.length; n++) {
              t(d[n]);
            }
          }
          v(n);
          n.getInstance = function (e) {
            e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
            if (!n._iq.hasOwnProperty(e)) {
              n._iq[e] = { _q: [] };
              v(n._iq[e]);
            }
            return n._iq[e];
          };
          e.amplitude = n;
        })(window, document);
        amplitude.getInstance().init(api_key);
      };
    getSousGroupes = () => {
        axios
          .get(this.state.api + "groupes/" + this.state.groupeId)
          .then((res) => {
            this.setState({
              loading: false,
            });
            if (res.data.data != undefined) {
              this.setState({
                groupes: res.data.data,
                sous_groupes: res.data.data.sous_groupes,
              });
            }
          })
          .catch((error) => {
            if (process.env.NODE_ENV !== "production") {
              return;
            }
            Sentry.captureException(error);
          });
    };
// Ajouter sous-groupes
    addSousGroup(e) {
        e.preventDefault()
        this.setState({
          addSGroupInProgress: true,
        });
        var fd = new FormData();
        fd.append('created_by', this.state.userID);
        fd.append('groupe_id', this.state.groupeId );
        fd.append("description", this.state.description);
        fd.append("name", this.state.name);
        fd.append("lien_whatsapp", this.state.lien_whatsapp);
        axios
          .post(this.state.api + "sous__groupes",fd, this.state.newSousGroup)
          .then((response) => {
            if (response.data.success) {
               
                $('#description').val('')
                $('#name').val('')  
                $('#lien_whatsapp').val('')              
                this.setState({
                  addSGroupInProgress: false,
                  showAlertSuccess: true,
                  name: "",
                  description: "",
                  lien_whatsapp: ""
                })
                // window.location = '/sous-groupe/'+this.props.id;
                window.location =  `/sous-groupe-coach?id=${this.state.groupeId}`;

            }  else {
                this.setState({
                  showAlertError: true,
                })
            } 
          })
          .catch((error) => {
            if ((error.message = "Request failed with status code 422")) {
            }
            this.setState({
              assignationEncours: false,
              showAlertError: true,
            });
            setTimeout(() => {
              this.setState({
                showAlertError: false,
              });
            }, 1000);
            if (process.env.NODE_ENV !== "production") {
              return;
            }
            Sentry.captureException(error);
          });
    
      }
      onChange = event => {
        this.setState({
            name: event.target.value.replace(/[^\w\s]/gi, "") 
        });
      };
      onChangeLink = event => {
        this.setState({
            lien_whatsapp: event.target.value.replace(/[^\w\s]/gi, "") 
        });
      };

  handleChange = (e) => {
    
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["created_by"] = this.state.userID;
    fields["groupe_id"] = this.props.id;
  }


  render() {
      let id = this.state.groupeId;
    return  <div className="body-theme">
                <HeaderDashboardCoach />
                <div className="container-fluid fixing-after-header">
                    <div className="row">
                        <div className="col-lg-2 p-0">
                            <NavVerticalCoachAssistant />
                        </div>
                        <div className="col-lg-10 pt-3">
                        
                            <div className="p-b-200">
                                <div className="col-lg-9">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                        <li className="breadcrumb-item ">
                                            <Link to="/dashboard-coach-assistant">Dashboard</Link>
                                        </li>
                                        <li className="breadcrumb-item " aria-current="page">
                                            <Link to="/groupe-coach-assistant">Groupes</Link>
                                        </li>
                                        <li className="breadcrumb-item " aria-current="page">
                                            <Link 
                                            to={{
                                                pathname: "/sous-groupe-coach", 
                                                state: { id: id },
                                            }}>Sous-groupes
                                             </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Ajouter un sous-groupe
                                        </li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div className="row m-b-15">
                                            <div className="col m-b-15">
                                                <h6 className="h-theme ff-inter fw-600">Ajouter un sous-groupe dans {this.state.groupes.group_name}</h6>
                                            </div>
                                            <div className="col dis-flex m-b-15">
                                            </div>
                                        </div>
                                        <div className="card-dashboard p-md-5 p-sm-5 m-b-20"> 
                                                {/* Alert groupe crée avec succes */}
                                            <SweetAlert
                                                show={this.state.showAlertSuccess}
                                                onConfirm={() => this.setState({ showAlertSuccess: false })}
                                                success
                                                title="Sous-groupe ajouté avec succes!!!"
                                                style={{
                                                    color: "#069A77",
                                                    fontSize: "12px",
                                                    fontFamily: "Work Sans, sans-serif",
                                                    paddingTop: 60,
                                                    paddingBottom: 40,
                                                }}
                                                confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                                            />
                                                {/* ENd ALert Succes */}

                                                {/* Alert erreur sur création de groupe */}
                                            <SweetAlert
                                                show={this.state.showAlertError}
                                                onConfirm={() => this.setState({ showAlertError: false })}
                                                error
                                                title="Erreur sur l'ajout d'un sous-groupe, veuillez reessayer!!!"
                                                style={{
                                                    color: "#d9534f",
                                                    fontSize: "12px",
                                                    fontFamily: "Work Sans, sans-serif",
                                                    paddingTop: 60,
                                                    paddingBottom: 40,
                                                }}
                                                confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                                            />
                                            <form id="add_sgroupe_form"  onSubmit={(e) => this.addSousGroup(e)}>                                    
                                                <div className="add-sgroup-container">
                                                        <div className="form-group ">
                                                            <label>Nom du sous-groupe</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="inputName"
                                                                placeholder="Nom"
                                                                name="group_name"
                                                                required
                                                                value={this.state.name} 
                                                                onChange={this.onChange} 
                                                            />
                                                        </div>
                                                        <div >
                                                            <label>Description</label>
                                                            <textarea
                                                            type="text"
                                                            className="form-control" 
                                                            placeholder="Description"
                                                            name="description"
                                                            required  onChange={ this.handleChange }
                                                            value={this.state.description}
                                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                                            onChange={(e) => {
                                                                let newSousGroup = this.state;
                                                                newSousGroup.description = e.target.value;
                                                                this.setState({
                                                                newSousGroup,
                                                                });
                                                            }}
                                                            />
                                                        </div>
                                                        <div className="form-group ">
                                                            <label>Lien du groupe whatsapp</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="inputLien"
                                                                placeholder="Lien groupe whatsapp"
                                                                name="lien_whatsapp"
                                                                value={this.state.lien_whatsapp} 
                                                                onChange={this.onChangeLink} 
                                                            />
                                                        </div>
                                                    </div>
                                                <div className="col-md-12">
                                                    {this.state.addSGroupInProgress ? (
                                                        <button
                                                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                                            disabled
                                                        >
                                                            Ajout en cours &nbsp;
                                                            <FeatherIcon icon="loader" aria-hidden="true" />
                                                        </button>
                                                        ) : (
                                                        <button
                                                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                                        >
                                                            Ajouter
                                                        </button>
                                                    )}
                                                </div>
                                            </form> 
                                        
                                        
                      
                                        </div>
                                    </div>
                                <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                                            <BakelisteGroupItemForCoach />
                                        </div>
                                        <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                                            <ProgramReunionCoach />
                                        </div>
                                        <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                                            <SavedReunionListItem />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    
}
// export default connect(
//     ({ tableMyBakelistes }) => ({ ...tableMyBakelistes }),
//     dispatch => bindActionCreators({ ...tableMyBakelistesActions }, dispatch)
//   )( tableMyBakelistes );

