import React, { Component } from "react";
import "./faireExamen.css";
import { Link } from "react-router-dom";
import { Prompt } from "react-router";

import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import API from "../../variablesGlobales";
import axios from "axios";
import ForwardIcon from "@material-ui/icons/Forward";
import { toast } from "react-toastify";
import undraw_question from "../../image/undraw_question.png";
import resultatimg from "../../image/resultat-img.png";

import ResultatExamenTheorique from "../resultatExamenTheorique/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as faireExamenActions from "../../store/faireExamen/actions";
export default class faireExamen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list_question_verifie: [],
      is_verified: false,
      exam: [],
      questions: [],
      domaine_id: window.sessionStorage.getItem("domaine_id"),
      user_id: window.sessionStorage.getItem("user_id"),
      api: API.API,
      nextQuestion: null,
      score: 0,
      nbqtrue: 0,
      nbqfalse: 0,
      percentage: 0,
      examLength: null,
      thisQuestion: [],
      endExam: false,
      currentQuestion: null,
      shouldBlockNavigation: false,
    };
  }
  componentDidMount = () => {
    this.getExamen();
    //     $(window).blur(function() {
    //     //do something
    // })
  };

  percentCalculation(a, b) {
    var c = (parseFloat(a) / parseFloat(b)) * 100;
    Math.round((a / b) * 100);
    // console.log("percentage", parseFloat(c), Math.round((a / b) * 100));
    return parseFloat(c);
  }
  async getExamen() {
    try {
      const response = await axios.get(
        this.state.api +
          "get-bakeliste-examen_theorique/" +
          this.state.domaine_id
      );
      // console.log("okkkkkk", response, this.state.domaine_id);
      this.setState({ questions: response.data.questions });
      const resp = response.data.questions;
      this.setState({
        exam: response.data.exam,
        examLength: resp.length - 1,
        thisQuestion: response.data.questions[0][0],
      });
    } catch (err) {
      console.warn(err);
    }
  }

  handleAnswerButtonClick = (e, answerOption) => {
    e.preventDefault();
    var endExam = false;

    const examLength = this.state.examLength - 1;
    this.setState({ examLength });

    if (this.state.thisQuestion.bonne_reponse === answerOption) {
      const score = this.state.score + 1;
      const nbqtrue = this.state.nbqtrue + 1;
      this.setState({ score, nbqtrue });
      // console.log("scoore", this.state.score);
    } else {
      const nbqfalse = this.state.nbqfalse + 1;
      this.setState({ nbqfalse });
      // console.log(this.state.nbqfalse);
    }
    const nextQuestion = this.state.currentQuestion + 1;
    this.setState({ nextQuestion });
    const thisQuestion = this.state.questions[nextQuestion][0];
    this.setState({ thisQuestion });

    // console.log(this.state.endExam);
    if (this.state.examLength === 0) {
      // alert("nextQuestion");
      var fd = new FormData();
      // console.log("exammm", this.state.examen_id);
      fd.append("examen_id", this.state.exam.examen_id);
      fd.append("user_id", this.state.user_id);
      fd.append("score", this.state.score);
      fd.append("nbfalse", this.state.nbqfalse);
      fd.append("nbtrue", this.state.nbqtrue);

      axios
        .post(this.state.api + "add-bakeliste-score", fd)
        .then((response) => {
          // console.log(response, response.status);
          if (response.data.success === true) {
            endExam = true;
            this.setState({ endExam });
          }
          if (response.data.status === "210") {
            toast.warning(
              "Vous avez deja passe l'examen",
              { position: toast.POSITION.TOP_CENTER },
              { autoClose: 2000 }
            );
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    // else {
    this.setState({ endExam });

    // console.log(endExam);
    //   alert("you reached the end of the quiz");
  };
  componentDidUpdate = () => {
    // if (this.state.shouldBlockNavigation) {
    //   window.onbeforeunload = () => true;
    // } else {
    //   window.onbeforeunload = undefined;
    // }
    window.onbeforeunload = (event) => {
      const e = event || window.event;
      // Cancel the event
      e.preventDefault();
      if (e) {
        e.returnValue = "";
        alert("ok"); // Legacy method for cross browser support
      }
      return ""; // Legacy method for cross browser support
    };
  };

  componentWillUnmount() {
    if (
      window.confirm("Changes are saved, but not published yet. Do that now?")
    ) {
    } else {
    }
  }
  userCheated() {
    // The user cheated by leaving this window (e.g opening another window)
    // Do something
    alert("You can't cheat!");
  }

  render() {
    return (
      <div className="component-faire-examen">
        {" "}
        <Prompt
          when={true}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className="p-b-200 container-fluid px-md-3 p-0">
          <div className="row">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb fixed">
                <ol className="breadcrumb fixed">
                  <li className="breadcrumb-item ">
                    <Link to="coach-dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/examen-bakeliste">Examens</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Faire Examen Theorique
                  </li>
                </ol>
              </nav>
              <div className="col dis-flex m-b-15 mt-5"></div>
              {!this.state.endExam ? (
                <div className="card card-exam pb-5">
                  <div className="entete-question pt-2 pl-4 pb-3">
                    <div className="row partie-theorique pt-4 pl-4">
                      <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="row">
                          <div className="part-article">
                            <h1>Theorie</h1>
                          </div>
                          <div className="part-article-par pt-5 pl-5">
                            <p>{this.state.thisQuestion.libelle}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3">
                        {/* <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            3mn 00s
                          </div>
                        </div> */}
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="article-img">
                          <img
                            className="undraw_question"
                            src={undraw_question}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3">
                        {/* <div className="part-article1">
                           <h1>10/10</h1> 
                        </div>*/}
                      </div>
                    </div>
                  </div>

                  <div className="part-two-title pt-3 pb-2">
                    <p>Cliquer sur la bonne réponse</p>
                  </div>
                  <button
                    onClick={(e) => this.handleAnswerButtonClick(e, "opt1")}
                  >
                    <div className="row radio-btn-row mx-0 px-0">
                      <div className="col-xl-2 col-lg-2 col-md-2">
                        <div className="radio-btn-wrapper py-2">
                          <button className="radio-btn" id="bt1" type="button">
                            A
                          </button>
                        </div>
                      </div>
                      <div className="col-xl-10 col-lg-10 col-md-10">
                        <div>
                          <span className="libelle-reponse">
                            {this.state.thisQuestion.opt1}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={(e) => this.handleAnswerButtonClick(e, "opt2")}
                  >
                    <div className="row radio-btn-row mx-0 px-0">
                      <div className="col-xl-2 col-lg-2 col-md-2">
                        <div className="radio-btn-wrapper py-2">
                          <button id="btn2" className="radio-btn" type="button">
                            B
                          </button>
                        </div>
                      </div>
                      <div className="col-xl-10 col-lg-10 col-md-10">
                        <div>
                          <span className="libelle-reponse">
                            {this.state.thisQuestion.opt2}
                          </span>
                        </div>
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={(e) => this.handleAnswerButtonClick(e, "opt3")}
                  >
                    <div className="row radio-btn-row mx-0 px-0">
                      <div className="col-xl-2 col-lg-2 col-md-2">
                        <div className="radio-btn-wrapper py-2">
                          <button id="btn3" className="radio-btn" type="button">
                            C
                          </button>
                        </div>
                      </div>
                      <div className="col-xl-10 col-lg-10 col-md-10">
                        <div>
                          <span className="libelle-reponse">
                            {this.state.thisQuestion.opt3}
                          </span>
                        </div>
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={(e) => this.handleAnswerButtonClick(e, "opt4")}
                  >
                    <div className="row radio-btn-row mx-0 px-0">
                      <div className="col-xl-2 col-lg-2 col-md-2">
                        <div className="radio-btn-wrapper py-2">
                          <button id="btn4" className="radio-btn" type="button">
                            D
                          </button>
                        </div>
                      </div>
                      <div className="col-xl-10 col-lg-10 col-md-10">
                        <div>
                          <span className="libelle-reponse">
                            {this.state.thisQuestion.opt4}
                          </span>
                        </div>
                      </div>
                    </div>
                  </button>

                  {/* <div className="row exam-btn-next pt-5">
                  <Link to="/exam-theorique-two" className="exam-btn pt-3">
                    Question suivante
                    <ForwardIcon />
                  </Link>
                </div> */}
                </div>
              ) : (
                <>
                  {/* <div className="col-sm-12 pb-5">
                    <h2 className="title_exam">
                      Résultat de l’examen théorique
                    </h2>
                  </div> */}

                  <div className="container-fluid bg-white mb-5">
                    <h1 className="resultat-title">
                      Merci pour votre participation
                    </h1>
                    <div className="pl-5 pr-5">
                      <div className="row card-resultat-two ">
                        <div className="col-xl-6 col-lg-6 col-md-6 pt-5">
                          <img
                            className="resultat-img"
                            src={resultatimg}
                            alt=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 pt-5 pl-5">
                          <div className="score-title pt-5 mb-4">
                            <span className="score-title">Votre score est</span>
                          </div>
                          <div className="score-numbe">
                            <span className="score-number">
                              {this.state.score}/20
                            </span>
                          </div>
                          <div className="percentage-two pt-4">
                            <div className="percentage">
                              Soit{" "}
                              {this.percentCalculation(
                                this.state.score,
                                this.state.questions.length
                              )}
                              % de réussite
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="offset-2 pt-4 pb-5">
                        <span className="text-prochain">
                          Bien... vous pouvez mieux faire la prochaine fois
                        </span>
                      </div>
                    </div>

                    <div className="pl-4 pr-4 pb-5">
                      <div className="card">
                        <span className="result-final pt-2">
                          Résultat final
                        </span>
                        <hr />
                        <div>
                          {/* <p>
                        <span className="txt-not-final pl-5">
                          Nombre total de questions{" "}
                        </span>{" "}
                        <span className="offset-5 txt-note">10</span>
                      </p> */}
                        </div>
                        <div>
                          <p>
                            <span className="txt-not-final pl-5">
                              Nombre total de questions{" "}
                            </span>{" "}
                            <span className="offset-6 txt-note">
                              {this.state.questions.length}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p>
                            <span className="txt-not-final pl-5">
                              Nombre total de questions trouvees{" "}
                            </span>{" "}
                            <span className="offset-5 txt-note">
                              {this.state.nbqtrue}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p>
                            <span className="txt-not-final pl-5">
                              Nombre total de questions faussees{" "}
                            </span>{" "}
                            <span className="offset-5 txt-note">
                              {this.state.nbqfalse}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}{" "}
            </div>

            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteGroupItemForCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ faireExamen }) => ({ ...faireExamen }),
//     dispatch => bindActionCreators({ ...faireExamenActions }, dispatch)
//   )( faireExamen );
