import React, { Component } from "react";
import "./groupeArchived.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { Link } from "react-router-dom";
import SendTaskTo from "../sendTaskTo/index";
import IconGroup from "../../img/icons/people.png";

import { Icon } from "rsuite";

import { NavLink } from "react-router-dom";

import NavVerticalCoach from "../navVerticalCoach/index";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";

import ProgramReunionCoach from "../programReunionCoach/index";
import { Uploader, DatePicker } from "rsuite";

import TableGestionTacheForCoach from "../tableGestionTacheForCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import Loader from "react-loader-spinner";
import API from "../../variablesGlobales";

class PresenceCheck extends React.Component {
  render() {
    return (
      <div style={{ color: "#22C580", textAlign: "center" }}>
        <FeatherIcon icon="check" size={16} />
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return <PresenceCheck active={cell} />;
}
// const Loader = () => <div> </div>;

export default class groupeArchived extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupes: [],
      api: API.API,

      loading: true,

      userID: window.sessionStorage.getItem("user_id"),
    };
  }

  componentDidMount() {
    this.getArchivedGroupes();
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 4000);
  }
  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };

  desarchiveGroupe = (id) => {
    axios
      .get(this.state.api + "groupe/desarchiver/" + id)
      .then((res) => {
        // console.log(res);
        if (res.data.success) {
          this.getArchivedGroupes();
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getArchivedGroupes = () => {
    axios
      .get(this.state.api + "archived/groupes")
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            groupes: res.data.data.reverse(),
            name: res.data.data.group_name,
          });
        }
        //_this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        //_this.hideLoader();
      });
  };

  render() {
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoach />
            </div>
            <div className="col-lg-10 pt-3">
              <div className="p-b-200">
                {this.state.loading ? (
                  <div className="col-md-3 offset-md-5">
                    <Loader
                      type="ThreeDots"
                      color="#009688"
                      height={700}
                      width={150}
                      timeout={4000}
                    />{" "}
                  </div>
                ) : (
                  <>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item ">
                          <Link to="coach-dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item " aria-current="page">
                          <Link to="/groupe-coach">Groupes</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                          Groupes archivés
                        </li>
                      </ol>
                    </nav>
                    <div className="row">
                      <div className="col-lg-9">
                        <div className="row m-b-15">
                          <div className="col m-b-15">
                            <h6 className="h-theme ff-inter fw-600">
                              Les groupes archivés
                            </h6>
                          </div>
                          <div className="col dis-flex m-b-15"></div>
                        </div>

                        <div className="row">
                          {this.state.groupes.map((item, index) => (
                            <div
                              className="col-md-6 col-sm-4 pr-sm-2 mb-3 dis-flex"
                              key={index}
                            >
                              <div className="item-statistics-dashboard">
                                {/* <NavLink to={"/sous-groupe/" + item.id} >   */}
                                <p className="groupe-archived-titre-on-coach-dashboard">
                                  {item.group_name}
                                </p>
                                <p className="designation-item-statistics-dashboard">
                                  {" "}
                                </p>
                                <img
                                  className="img-item-statistics-dashboard"
                                  src={IconGroup}
                                  alt=""
                                />
                                {/* </NavLink>  */}
                                {/* <div className="row" >
                              <div className="col-md-4 offset-md-10">
                              <FeatherIcon icon="edit" className="btn-modifier" type="button" data-toggle="modal" data-target={`#editGroupModal${item.id}`}  aria-hidden="true" />
                              </div >*/}
                                <div
                                  className="col-md-4 offset-md-10"
                                  onClick={() => this.desarchiveGroupe(item.id)}
                                >
                                  <FeatherIcon
                                    icon="archive"
                                    className="btn-archiver"
                                    type="button"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                        <div className="row">
                          <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                            <BakelisteGroupItemForCoach />
                          </div>
                          <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                            <ProgramReunionCoach />
                          </div>
                          <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                            <SavedReunionListItem />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ tableMyBakelistes }) => ({ ...tableMyBakelistes }),
//     dispatch => bindActionCreators({ ...tableMyBakelistesActions }, dispatch)
//   )( tableMyBakelistes );
