import React, { Component } from "react";
import "./informationSousGroupe.css";
import { Link } from "react-router-dom";

import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
// import { connect } from "react-redux";
import qs from "qs";
import axios from "axios";
import API from "../../variablesGlobales";
import Loader from "react-loader-spinner";
import Preloader from "../preloader/index";

// import { bindActionCreators } from "redux";
// import * as informationSousGroupeActions from "../../store/informationSousGroupe/actions";
export default class informationSousGroupe extends Component {
  constructor(props) {
    super(props);

    let sous_groupe_id = this.props.location?.state?.id;
    let name = this.props.location?.state.name;
    let donnees = this.props.location?.state?.donnees;
    if (!sous_groupe_id && this.props.location.search) {
      const obj = qs.parse(this.props.location.search.replace("?", ""));
      sous_groupe_id = parseInt(obj.id);
    }

    this.state = {
      sous_groupe_id: sous_groupe_id,
      liste: [],
      syllabus: [],
      api: API.API,
      sgroupeInfos: [],
      infos: [],
      voirListe: false,
      voirSyllabus: false,
      able: true,
      abled: true,
      name: name,
      loading: true,
      donnees: donnees,
    };
  }

  componentDidMount() {
    this.getBakelisteBySousGroupes();
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 4000);
  }

  getBakelisteBySousGroupes = () => {
    axios
      .get(this.state.api + "sous__groupes/" + this.state.sous_groupe_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            infos: response.data.data,
            liste: response.data.liste[0].reverse(),
            syllabus: response.data.syllabus[0].reverse(),
          });
          //console.log(this.state.liste)
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  };

  showSyllabus = () => {
    this.setState({
      voirListe: false,
      voirSyllabus: true,
      abled: false,
      able: true,
    });
  };

  showListe = () => {
    this.setState({
      voirListe: true,
      voirSyllabus: false,
      able: false,
      abled: true,
    });
  };

  render() {
    let sous_groupe = this.state.infos;
    let dataGroupe = this.state.donnees;
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoach />
            </div>

            <div className="col-lg-10 pt-3">
              <div className="p-b-200">
                <div className="row">
                  {this.state.loading ? (
                    <div className="col-lg-10 pt-3">
                      <Preloader />
                    </div>
                  ) : (
                    <>
                      <div className="col-lg-10">
                        <nav aria-label="breadcrumb fixed">
                          <ol className="breadcrumb fixed">
                            <li className="breadcrumb-item ">
                              <Link to="coach-dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item " aria-current="page">
                              <Link to="/sous-groupes-coach">Sous-groupes</Link>
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              {this.state.name} -- {sous_groupe.name}
                            </li>
                          </ol>
                        </nav>
                      </div>

                      <div className="col-lg-9">
                        <div className="row mto-3">
                          <div className="col-md-6">
                            <label className="label-color-green p-3">
                              Nombre de bakeliste:
                            </label>
                            <label className="label-color-black p-3">
                              {dataGroupe.nombre_bakeliste}
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="label-color-green p-3">
                              Nombre de Syllabus:
                            </label>
                            <label className="label-color-black p-3">
                              {this.state.syllabus.length}
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          {this.state.able ? (
                            <div className="col-md-6">
                              <button
                                type="submit"
                                className="btn-theme text-up m-t-5 w-full p-t-15 p-b-15"
                                onClick={() => this.showListe()}
                              >
                                Voir bakelistes &nbsp;
                              </button>
                            </div>
                          ) : (
                            <div className="col-md-6">
                              <button
                                type="submit"
                                disabled
                                className="btn-theme text-up m-t-5 w-full p-t-15 p-b-15"
                                onClick={() => this.showListe()}
                              >
                                Voir bakelistes &nbsp;
                              </button>
                            </div>
                          )}
                          {this.state.abled ? (
                            <div className="col-md-6">
                              <button
                                type="submit"
                                className="btn-theme text-up m-t-5 w-full p-t-15 p-b-15"
                                onClick={() => this.showSyllabus()}
                              >
                                Voir syllabus &nbsp;
                              </button>
                            </div>
                          ) : (
                            <div className="col-md-6">
                              <button
                                type="submit"
                                disabled
                                className="btn-theme text-up m-t-5 w-full p-t-15 p-b-15"
                                onClick={() => this.showSyllabus()}
                              >
                                Voir syllabus &nbsp;
                              </button>
                            </div>
                          )}
                        </div>{" "}
                        <br />
                        {this.state.voirListe &&
                          (this.state.liste.length > 0 ? (
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">Prenom</th>
                                  <th scope="col">Nom</th>
                                  <th scope="col">Email</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.liste.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.first_name}</td>
                                    <td>{item.last_name}</td>
                                    <td>{item.email}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <h6>Ce groupe n'a pas encore de bakeliste</h6>
                          ))}
                        {this.state.voirSyllabus &&
                          (this.state.syllabus.length > 0 ? (
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">Nom</th>
                                  <th scope="col">Description</th>
                                  <th scope="col">Niveau</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.syllabus.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.syllabus_name}</td>
                                    <td>{item.syllabus_description}</td>
                                    <td>{item.syllabus_level}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <h6>Ce groupe n'a pas encore reçue de syllabus</h6>
                          ))}
                      </div>

                      <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                        <div className="row">
                          <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                            <BakelisteGroupItemForCoach />
                          </div>
                          <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                            <ProgramReunionCoach />
                          </div>
                          <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                            <SavedReunionListItem />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ informationSousGroupe }) => ({ ...informationSousGroupe }),
//     dispatch => bindActionCreators({ ...informationSousGroupeActions }, dispatch)
//   )( informationSousGroupe );
