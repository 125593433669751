import React, { Component } from "react";
import "./tacheBakelisteView.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import TacheBakeliste from "../tacheBakeliste/index";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link } from "react-router-dom";

export default class tacheBakelisteView extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  } 

  componentDidMount() {
   
  }
  render() {
    return (
      <div className="body-theme">
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVertical />
            </div>
            <div className="col-lg-10 pt-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item ">
                    <Link to="/dashboard-bakeliste">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Tâches
                  </li> 
                </ol>
              </nav>
              <TacheBakeliste />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ tacheBakelisteView }) => ({ ...tacheBakelisteView }),
//     dispatch => bindActionCreators({ ...tacheBakelisteViewActions }, dispatch)
//   )( tacheBakelisteView );
