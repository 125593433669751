import React, {Component} from 'react';
import './modifierResumeView.css';
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import ModifierResume from "../modifierResume/index";
import qs from "qs";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as ajouterResumeViewActions from "../../store/ajouterResumeView/actions";
export default class modifierResumeView extends Component {
    constructor(props) {
        super(props);

      let id = this.props.location?.state?.id;
      if (!id && this.props.location.search) {
        const obj = qs.parse(this.props.location.search.replace("?", ""));
        id = parseInt(obj.id);
      }
        this.state = { 
          id: id
        };
    }
    render() {
      return ( 
      <div className="body-theme">
      <HeaderDashboard />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVertical />
          </div>
          <div className="col-lg-10 pt-3">
            <ModifierResume id={this.state.id} />
          </div>
        </div>
      </div>
    </div>) }
  }
// export default connect(
//     ({ ajouterResumeView }) => ({ ...ajouterResumeView }),
//     dispatch => bindActionCreators({ ...ajouterResumeViewActions }, dispatch)
//   )( ajouterResumeView );