import React, { useEffect, useMemo, useRef, useState } from "react";
import "./livraisonBakeliste.css";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItemRefact from "../bakelisteGroupItem/BakelisteGroupItemRefact";
import NextReunionItem from "../nextReunionItem/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import LivrableBakelisteItem from "../livrableBakelisteItem/index";
import ModalContactUs from "../modalContactUs/index";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import { Link } from "react-router-dom";
import Preloader from "../preloader/index";
import ListCoachAssistantRefact from "../listCoachAssistant/ListCoachAssistantRefact";
import API from "../../variablesGlobales";
// import amplitudeScript from "../../amplitudeScript";
import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";
import Loader from "react-loader-spinner";
import EnvoyerMonTravail from "../envoyerMonTravail/index";
import { useDispatch, useSelector, connect } from "react-redux";
import livraisonsBakelisteReducer from "../LivraisonRefactor/redux/reducers/livraisonsBakelisteReducer1";
import { getLivraisonsBakeliste } from "../LivraisonRefactor/redux/actions/livraisonBakelisteActions";
import PaginationRefac from "../pagination/PaginationRefac";

let PageSize = 5;
const LivraisonBakelisteRefact = (props) => {
  const dispatch = useDispatch();
  const [api] = useState(API.API);
  const [taches, setTaches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [detailsSyllabus, setDetailsSyllabus] = useState([]);
  const [livraison, setLivraison] = useState([]);
  const [bakeliste_id] = useState(sessionStorage.getItem("user_id"));
  const [currentPage, setCurrentPage] = useState(1);
  // const [eventeDroite] = useState("ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_V2_DROITE")
  const livraisonData = useSelector((state) => state.livraisonsBakeliste);

  const amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);

    amplitude.getInstance().init(api_key);
  };

  const getFichiersLivraisons = (data) => {
    const ok = data.map((i, ix) => {
      return i.fichiers;
    });

    return ok;
  };
  const getCommentsLivraisons = (data) => {
    const ok = data.map((i, ix) => {
      return i.comments.reverse();
    });

    return ok;
  };

  useEffect(() => {
    dispatch(getLivraisonsBakeliste(bakeliste_id));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    // getTachesBakeliste();
  }, [bakeliste_id, dispatch]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return props.livraison.detailsSyllabus?.slice(
      firstPageIndex,
      lastPageIndex
    );
  }, [currentPage, props.livraison.detailsSyllabus]);

  return loading ? (
    <Preloader />
  ) : (
    <Amplitude>
      <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
        <div className="row">
          <div className="col-lg-9 livrason">
            <div>
              <nav aria-label="breadcrumb fixed">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item ">
                    <Link to="/dashboard-bakeliste">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Livraisons
                  </li>
                </ol>
              </nav>
            </div>
            {/*  */}
            <div className="row m-b-15 pt3">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600">Livraison</h6>
              </div>
              <div className="col dis-flex m-b-15">
                <ModalContactUs />
              </div>
            </div>

            <section>
              {currentTableData !== undefined ? (
                <>
                  {currentTableData.map((item, index) => (
                    <LivrableBakelisteItem
                      key={index}
                      tache_number={index + 1}
                      // onSubmitComment={this.addReactiononLivrable}
                      getTachesBakeliste={props.livraison.detailsSyllabus}
                      fichiers={getFichiersLivraisons(item.livraisons)}
                      commentaires={getCommentsLivraisons(item.livraisons)}
                      id={item.id}
                      iteme={item}
                      // commentaires={item.livraisons.comments !== null && item.livraisons.comments}
                      titre={item.syllabus_task}
                      date_tache={item.syllabus_completion_time}
                      livraisons={item.livraisons}
                      syllabus={"syllabus"}
                    />
                  ))}
                  <PaginationRefac
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={props.livraison.detailsSyllabus.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </>
              ) : (
                <div>Aucune livraison pour le moment</div>
              )}
            </section>
          </div>

          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <CoachItemDashboardBakeliste />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <BakelisteGroupItemRefact />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <ListCoachAssistantRefact />
              </div>
              {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
              <NextReunionItem />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
              <SavedReunionListItem />
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </Amplitude>
  );
};

const mapStateToprops = (state) => {
  return {
    livraison: state.livraisonsBakeliste.items,
  };
};

export default connect(mapStateToprops, { getLivraisonsBakeliste })(
  LivraisonBakelisteRefact
);
