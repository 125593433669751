import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import "./ajoutCvBakeliste.css";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItem from "../bakelisteGroupItem/index";
import NextReunionItem from "../nextReunionItem/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import ModalContactUs from "../modalContactUs/index";
import { Accordion, Button, Card, Icon } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import API from "../../variablesGlobales";
import { Modal, Tooltip } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import {
  getBakelisteCV,
  updateReduxEducation,
} from "../LivraisonRefactor/redux/actions/cvBakelisteActions";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import CVPdf from "../../utils/CVPdf";

const AjoutCvBakelisteRefac = () => {
  // Dispatching Redux Data
  const dispatch = useDispatch();
  const allCvData = useSelector((state) => state.cvBakeliste);

  // INITIAL STATES
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      cvthequeID: 0,
      bakelisteID: window.sessionStorage.getItem("user_id"),
      isRecruitable: false,
      cv: [],
      isCvthequeEmpty: false,
      isProjectEmpty: false,
      isEducationEmpty: false,
      isPortfolioEmpty: false,
      isTecnologyEmpty: false,
      isLanguageEmpty: false,
      isEmptyCV: false,
      cvinfos: [],
      cvprojects: [],
      cveducation: [],
      cvportfolio: [],
      cvtechnology: [],
      cvlanguague: [],
      showProjectForm: false,

      showProjectForm2: false,
      fields: {},
      cvtheque_data: {},
      isAddProjectInProgress: false,
      isSuccessAddItemAlert: false,
      isAddRequestError: false,
      showTacheForm: false,
      showProjectForm2Btn: true,
      showTacheFormBtn: true,
      id_cv: "",
      isAddTacheInProgress: false,
      isTacheRequestError: false,
      showEducationForm: false,
      showEducationFormBtn: false,
      isAddEducationInProgress: false,
      showEducationForm2Btn: true,
      showEducationForm2: false,
      showEducationForm3Btn: false,
      showPortfolioForm: false,
      showPortfolioFormBtn: false,
      isAddPortfolioInProgress: false,
      showPortfolioForm2Btn: true,
      showPortfolioForm2: false,
      imagePreviewUrl: false,
      image: "",
      imgPathValue: "",
      idValue: 0,
      portProjectName: "",

      showMotivationForm: false,
      showMotivationFormBtn: false,
      showMotivationForm2: false,
      showMotivationForm2Btn: true,
      isAUMotivationInProgress: false,
      cvMotivationsValue: "",

      showTechnoForm: false,
      showTechnoFormBtn: false,
      showTechnoForm2: false,
      showTechnoForm2Btn: true,
      isAddTechnoInProgress: false,

      showLangForm: false,
      showLangFormBtn: false,
      showLangForm2: false,
      showLangForm2Btn: true,
      isAddLangInProgress: false,
      isPending: false,
      formEducation: false,
      updateEducation: {
        diplome: "",
        school: "",
        ville: "",
        pays: "",
        diplome_label: "",
      },
      idEdu: "",
      showEditModal: false,
      showProjetModal: false,
      showTacheModal: false,
      showCvModal: false,
      size: "md",
      project_technologies: "",
      project_name: "",
      id_projet: "",
      tache_content: "",
      id_tache: "",
      project_id: "",
      showTechModal: false,
      techno_percent: "",
      techno_name: "",
      id_tech: "",
      language_name: "",
      languague_label: "",
      id_lang: "",
    }
  );

  // Mouting with useEffect
  //   useEffect(() => {
  //     getBakelisteCV(state.bakelisteID);
  //   }, []);

  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    if (mounted) {
      dispatch(getBakelisteCV(state.bakelisteID));
    }
    return () => (mounted.current = false);
  }, [dispatch, state.bakelisteID]);

  const setCvReduxData = useCallback(() => {
    if (allCvData.data.success) {
      if (allCvData.data.cvthequeData.length === 0) {
        setState({
          isCvthequeEmpty: true,
        });
      } else {
        setState({
          cvinfos: allCvData.data.cvthequeData,
          id_cv: allCvData.data.cvthequeData.id,
          cvMotivationsValue: allCvData.data.cvthequeData.cv_motivations,
        });
      }
      if (allCvData.data.cvprojectsData.length === 0) {
        setState({
          isProjectEmpty: true,
        });
      } else {
        setState({
          cvprojects: allCvData.data.cvprojectsData,
        });
      }
      if (allCvData.data.cveducationData.length === 0) {
        setState({
          isEducationEmpty: true,
        });
      } else {
        setState({
          cveducation: allCvData.data.cveducationData,
        });
      }
      if (allCvData.data.cvportfolioData.length === 0) {
        setState({
          isPortfolioEmpty: true,
        });
      } else {
        setState({
          cvportfolio: allCvData.data.cvportfolioData,
        });
      }
      if (allCvData.data.cvtechnologiesData.length === 0) {
        setState({
          isTecnologyEmpty: true,
        });
      } else {
        setState({
          cvtechnology: allCvData.data.cvtechnologiesData,
        });
      }
      if (allCvData.data.cvlanguagesData.length === 0) {
        setState({
          isLanguageEmpty: true,
        });
      } else {
        setState({
          cvlanguague: allCvData.data.cvlanguagesData,
        });
      }
    } else {
      setState({
        isEmptyCV: true,
        isCvthequeEmpty: true,
      });
    }
  }, [
    allCvData.data.cveducationData,
    allCvData.data.cvlanguagesData,
    allCvData.data.cvportfolioData,
    allCvData.data.cvprojectsData,
    allCvData.data.cvtechnologiesData,
    allCvData.data.cvthequeData,
    allCvData.data.success,
  ]);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      setCvReduxData();
    }
    return () => (mounted.current = false);
  }, [setCvReduxData]);

  //! CV handleChange
  const handleChangeCvTheque = useCallback(
    (e) => {
      let cvtheque_data = state.cvtheque_data;
      cvtheque_data[e.target.name] = e.target.value;
      cvtheque_data["bakeliste_id"] = state.bakelisteID;
      cvtheque_data["is_hired"] = 1;
      setState({
        cvtheque_data: cvtheque_data,
      });

      //   console.log("cv thecque data", state.cvtheque_data);
    },
    [state.bakelisteID, state.cvtheque_data]
  );

  //   const handleChangeCvTheque = (e) => {
  //     let cvtheque_data = state.cvtheque_data;
  //     cvtheque_data[e.target.name] = e.target.value;
  //     cvtheque_data["bakeliste_id"] = state.bakelisteID;
  //     cvtheque_data["is_hired"] = 1;
  //     setState({
  //       cvtheque_data: cvtheque_data,
  //     });

  //     console.log("cv thecque data", state.cvtheque_data);
  //   };

  //! CV handleSubmit
  const handleSubmitCvTheque = (e) => {
    e.preventDefault();

    setState({
      isPending: true,
    });

    let data = state.cvtheque_data;
    axios
      .post(API.API + "cvtheques", data)
      .then((response) => {
        setState({
          isPending: false,
        });
        if (response.data.success) {
          setState({ successRegister: true });
          //   console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error.message);
        /* setState({
                  errorprogresseAlert: true
                }) */
        //handleSubmitlError('errorprogresseAlert')
      });
  };

  //! handleAlert Show & Hide
  const handleShowAndHideAlert = (label) => {
    setTimeout(() => {
      if (label === "successAddItem") {
        setState({
          isSuccessAddItemAlert: false,
        });
      }
      if (label === "errorAddItem") {
        setState({
          isAddRequestError: false,
        });
      }
    }, 5000);
  };

  //! New project Form
  const addNewProjectForm = (e, label) => {
    e.preventDefault();
    if (label === "pFirstForm") {
      setState({
        showProjectForm: true,
      });
    }
    if (label === "pSecondForm") {
      setState({
        showProjectForm2: true,
        showProjectForm2Btn: false,
        showTacheFormBtn: false,
      });
    }
  };

  //! New Tache Form
  const addNewTacheForm = (e) => {
    e.preventDefault();
    setState({
      showTacheForm: true,
      showProjectForm2Btn: false,
      showTacheFormBtn: false,
    });
  };

  /*
   * Switcher method for showing education form
   */
  const addNewEducationForm = (e, label) => {
    e.preventDefault();
    if (label === "eFirstForm") {
      setState({
        showEducationForm: true,
        showEducationFormBtn: false,
      });
    }
    if (label === "eSecondForm") {
      setState({
        showEducationForm2: true,
        showEducationForm2Btn: false,
      });
    }
  };

  /*
   * Switcher method for showing portfolio form
   */
  const addNewPortfolioForm = (e, label) => {
    e.preventDefault();
    if (label === "poFirstForm") {
      setState({
        showPortfolioForm: true,
        showPortfolioFormBtn: false,
      });
    }
    if (label === "poSecondForm") {
      setState({
        showPortfolioForm2: true,
        showPortfolioForm2Btn: false,
      });
    }
  };

  /*
   * Switcher method for showing motivations form
   */
  const addMotivationForm = (e, label, motivation) => {
    e.preventDefault();
    if (label === "moFirstForm") {
      setState({
        showMotivationForm: true,
        showMotivationFormBtn: false,
      });
    }
    if (label === "moSecondForm") {
      setState({
        showMotivationForm2: true,
        showMotivationForm2Btn: false,
        cvMotivationsValue: motivation,
      });
    }
  };

  /*
   * Switcher method for showing techno form
   */
  const addTechnoForm = (e, label) => {
    e.preventDefault();
    if (label === "teFirstForm") {
      setState({
        showTechnoForm: true,
        showTechnoFormBtn: false,
      });
    }
    if (label === "teSecondForm") {
      setState({
        showTechnoForm2: true,
        showTechnoForm2Btn: false,
      });
    }
  };

  /*
   * Switcher method for showing languague form
   */
  const addLangForm = (e, label) => {
    e.preventDefault();
    if (label === "laFirstForm") {
      setState({
        showLangForm: true,
        showLangFormBtn: false,
      });
    }
    if (label === "laSecondForm") {
      setState({
        showLangForm2: true,
        showLangForm2Btn: false,
      });
    }
  };

  //!
  const hideItemForm = (e) => {
    e.preventDefault();
    setState({
      showProjectForm: false,
      showProjectForm2Btn: true,
      showProjectForm2: false,

      showTacheFormBtn: true,
      showTacheForm: false,

      showEducationForm: false,
      showEducationForm2: false,
      showEducationForm2Btn: true,

      showPortfolioForm: false,
      showPortfolioForm2: false,
      showPortfolioForm2Btn: true,

      showMotivationForm: false,
      showMotivationForm2: false,
      showMotivationForm2Btn: true,

      showTechnoForm: false,
      showTechnoForm2: false,
      showTechnoForm2Btn: true,

      showLangForm: false,
      showLangForm2: false,
      showLangForm2Btn: true,
    });
  };

  /*
   * Handle add form
   */
  const handleChange = (e) => {
    let fields = state.fields;
    fields["cvtheque_id"] = state.cvinfos.id;
    fields["action"] = "add";
    if (e.target.name === "img_path") {
      //   console.log(e.target.files);
      createImage(e.target.files[0]);
      fields["img_path"] = e.target.files[0];
    } else {
      fields[e.target.name] = e.target.value;
    }

    setState({
      fields,
    });

    // console.log(fields);
  };

  const createImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setState({
        image: e.target.result,
      });
    };
    // console.log(state.image);
    reader.readAsDataURL(file);
  };

  /*
   * Submit new project form
   */
  const handleSubmitNewProject = (e) => {
    e.preventDefault();
    setState({
      isAddProjectInProgress: true,
    });
    let newProject = state.fields;
    setState({
      fields: {},
    });
    axios
      .post(API.API + "cvprojects", newProject)
      .then((response) => {
        if (response.data.success === true) {
          setState({
            isProjectEmpty: false, // déclarer projet non vide
            showProjectForm: false, // cacher le formulaire d'ajout
            showProjectForm2: false, // cacher le deuxième formulaire d'ajout
            showProjectForm2Btn: true, // afficher le bouton switcheur
            showTacheFormBtn: true, // afficher bouton switcheur
            isAddProjectInProgress: false, // cacher l'action en cours
            isSuccessAddItemAlert: true, // afficher le toast de confirmation
          });
          //   getBakelisteCV(state.bakelisteID); // raffraîchir les données
          dispatch(getBakelisteCV(state.bakelisteID));
          handleShowAndHideAlert("successAddItem");
        } else {
          setState({
            isAddProjectInProgress: false, // cacher l'action en cours
            isAddRequestError: true, // afficher le message d'erreur
          });
          handleShowAndHideAlert("errorAddItem");
        }
      })
      .catch((error) => {
        setState({
          isAddProjectInProgress: false, // cacher l'action en cours
          isAddRequestError: true, // afficher le message d'erreur
        });
        console.log(error);
        handleShowAndHideAlert("errorAddItem");
      });
    // console.log(state.fields);
  };

  /*
   * Submit add new tache form
   */
  const handleSubmitNewTache = (e) => {
    e.preventDefault();
    setState({
      isAddTacheInProgress: true, // activer l'action en cours
    });
    e.target.reset();
    let newTache = state.fields; // recupération des données du formulaire
    axios
      .post(API.API + "cvtaches", newTache)
      .then((response) => {
        // return back de la requête
        if (response.data.success === true) {
          //   console.log(response);
          // return back égal à true
          // document.getElementById
          // $.reset("addTacheForm"); // vider le formulaire
          setState({
            isAddTacheInProgress: false, // cacher l'action en cours
            isSuccessAddItemAlert: true, // afficher le toast de confirmation
            fields: {},
          });
          //   getBakelisteCV(state.bakelisteID); // raffraichissement des données
          dispatch(getBakelisteCV(state.bakelisteID));
          handleShowAndHideAlert("successAddItem");
        } else {
          // console.log(response);
          setState({
            isAddTacheInProgress: false, // cacher l'action en cours
            isAddRequestError: true, // afficher le message d'erreur
          });
          handleShowAndHideAlert("errorAddItem");
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          isAddTacheInProgress: false, // cacher l'action en cours
          isAddRequestError: true, // afficher le message d'erreur
        });
        handleShowAndHideAlert("errorAddItem");
      });
  };

  /*
   * Submit new education
   */
  const SubmitNewEducation = (e, label) => {
    e.preventDefault();
    setState({
      isAddEducationInProgress: true, // submit en cours
    });
    let newEducation = state.fields; // recupération des données du formulaire
    axios
      .post(API.API + "cveducations", newEducation)
      .then((response) => {
        // return back du serveur
        if (response.data.success === true) {
          // submit action effectuée
          //   getBakelisteCV(state.bakelisteID); // raffraichissement des données
          dispatch(getBakelisteCV(state.bakelisteID));
          document.getElementById("addNewEducationForm").reset(); // reset du formulaire
          setState({
            isEducationEmpty: false, // education non vide
            isAddEducationInProgress: false, // désactiver ajout en cours
            isSuccessAddItemAlert: true, // toast de confirmation
            showEducationForm: false, // cacher le formulaire
            fields: {},
          });
          if (label === "first") {
            // test si c'est le premier ajout
            setState({
              showEducationForm2Btn: true, // affichage du bouton switcher
            });
          } else if (label === "second") {
            // test si c'est ajout supplémentaire
            setState({
              showEducationForm2Btn: false, // cacher le bouton switcher
            });
          }
          handleShowAndHideAlert("successAddItem"); // cacher le toast de confirmation
        } else {
          //   console.log(response);
          setState({
            isAddEducationInProgress: false, // désactiver ajout en cours
            isAddRequestError: true, // affcihage de l'erreur
          });
          handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          isAddEducationInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  /*
   * Submit new portfolio item
   */
  const handleSubmitNewPortfolio = (e, label) => {
    e.preventDefault();
    setState({
      isAddPortfolioInProgress: true,
    });
    const newPortfolio = new FormData();
    newPortfolio.append("img_path", state.fields.img_path);
    newPortfolio.append("cvtheque_id", state.fields.cvtheque_id);
    newPortfolio.append("project_id", state.fields.project_id);
    axios
      .post(API.API + "cvportfolios", newPortfolio)
      .then((response) => {
        if (response.data.success === true) {
          //   getBakelisteCV(state.bakelisteID); // raffraichissement des données
          dispatch(getBakelisteCV(state.bakelisteID));
          document.getElementById("addNewPortfolioForm").reset();
          setState({
            isPortfolioEmpty: false,
            isAddPortfolioInProgress: false,
            isSuccessAddItemAlert: true,
            showPortfolioForm: false,
            fields: {},
          });
          if (label === "first") {
            setState({
              showPortfolioForm2Btn: true,
            });
          } else if (label === "second") {
            setState({
              showPortfolioForm2Btn: false,
            });
          }
          handleShowAndHideAlert("successAddItem");
        } else {
          //   console.log(response);
          setState({
            isAddPortfolioInProgress: false,
            isAddRequestError: true,
          });
          handleShowAndHideAlert("errorAddItem");
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          isAddPortfolioInProgress: false,
          isAddRequestError: true,
        });
        handleShowAndHideAlert("errorAddItem");
      });
  };

  /*
   * Portfolio infos pour afficher l'image en taille réelle
   */
  const getPortfolioInfos = (portfolio) => {
    setState({
      imgPathValue: portfolio.img_path,
      idValue: portfolio.id,
      portProjectName: portfolio.projectName,
    });
  };

  /*
   * Handle Change method for update motivations
   */
  const handleChangeUpdateMotivation = (e) => {
    let fields = state.fields;
    fields[e.target.name] = e.target.value;
    fields["action"] = "update";
    if (e.target.name === "cv_motivations") {
      setState({
        cvMotivationsValue: e.target.value,
      });
    }

    if (!fields["cv_motivations"]) {
      fields["cv_motivations"] = state.cvMotivationsValue;
    }

    setState({
      fields: fields,
    });

    // console.log(fields);
  };

  /*
   * Handle submit bakeliste motivations
   */
  const SubmitMotivation = (e, label) => {
    e.preventDefault();
    setState({
      isAUMotivationInProgress: true, // submit en cours
    });
    let motivations = state.fields; // recupération des données du formulaire
    // console.log(motivations);
    axios
      .post(
        API.API + "bakeliste-add-cv-motivations/" + state.cvinfos.id,
        motivations
      )
      .then((response) => {
        // return back du serveur
        if (response.data.success === true) {
          // submit action effectuée
          //   getBakelisteCV(state.bakelisteID); // raffraichissement des données
          dispatch(getBakelisteCV(state.bakelisteID));
          document.getElementById("addUpdateMotivationForm").reset(); // reset du formulaire
          setState({
            isAUMotivationInProgress: false, // désactiver ajout en cours
            isSuccessAddItemAlert: true, // toast de confirmation
            showMotivationForm: false, // cacher le formulaire
            fields: {},
          });
          if (label === "first") {
            // test si c'est le premier ajout
            setState({
              showMotivationForm2Btn: true, // affichage du bouton switcher
            });
          } else if (label === "second") {
            // test si c'est ajout supplémentaire
            setState({
              showMotivationForm2Btn: true, // cacher le bouton switcher
              showMotivationForm2: false, // cacher le formulaire de modification
            });
          }
          handleShowAndHideAlert("successAddItem"); // cacher le toast de confirmation
        } else {
          //   console.log(response);
          setState({
            isAUMotivationInProgress: false, // désactiver ajout en cours
            isAddRequestError: true, // affcihage de l'erreur
          });
          handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          isAUMotivationInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  /**
   * Handle submit new techno
   */
  const hanldeSubmitTechno = (e, label) => {
    e.preventDefault();
    setState({
      isAddTechnoInProgress: true, // submit en cours
    });
    let techno = state.fields; // recupération des données du formulaire
    axios
      .post(API.API + "cvtechnologies", techno)
      .then((response) => {
        // return back du serveur
        if (response.data.success === true) {
          // submit action effectuée
          //   getBakelisteCV(state.bakelisteID); // raffraichissement des données
          dispatch(getBakelisteCV(state.bakelisteID));
          document.getElementById("addTechnoForm").reset(); // reset du formulaire
          setState({
            isTecnologyEmpty: false, // techno non vide
            isAddTechnoInProgress: false, // désactiver ajout en cours
            isSuccessAddItemAlert: true, // toast de confirmation
            showTechnoForm: false, // cacher le formulaire
            fields: {},
          });
          if (label === "first") {
            // test si c'est le premier ajout
            setState({
              showTechnoForm2Btn: true, // affichage du bouton switcher
            });
          } else if (label === "second") {
            // test si c'est ajout supplémentaire
            setState({
              showTechnoForm2Btn: false, // cacher le bouton switcher
            });
          }
          handleShowAndHideAlert("successAddItem"); // cacher le toast de confirmation
        } else {
          //   console.log(response);
          setState({
            isAddTechnoInProgress: false, // désactiver ajout en cours
            isAddRequestError: true, // affcihage de l'erreur
          });
          handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          isAddTechnoInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  /*
   * Handle submit new lang
   */
  const hanldeSubmitLang = (e, label) => {
    e.preventDefault();
    setState({
      isAddLangInProgress: true, // submit en cours
    });
    let lang = state.fields; // recupération des données du formulaire
    axios
      .post(API.API + "cvlanguages", lang)
      .then((response) => {
        // return back du serveur
        if (response.data.success === true) {
          // submit action effectuée
          //   getBakelisteCV(state.bakelisteID); // raffraichissement des données
          dispatch(getBakelisteCV(state.bakelisteID));
          document.getElementById("addLangForm").reset(); // reset du formulaire
          setState({
            isLanguageEmpty: false, // language non vide
            isAddLangInProgress: false, // désactiver ajout en cours
            isSuccessAddItemAlert: true, // toast de confirmation
            showLangForm: false, // cacher le formulaire
            fields: {},
          });
          if (label === "first") {
            // test si c'est le premier ajout
            setState({
              showLangForm2Btn: true, // affichage du bouton switcher
            });
          } else if (label === "second") {
            // test si c'est ajout supplémentaire
            setState({
              showLangForm2Btn: false, // cacher le bouton switcher
            });
          }
          handleShowAndHideAlert("successAddItem"); // cacher le toast de confirmation
        } else {
          //   console.log(response);
          setState({
            isAddLangInProgress: false, // désactiver ajout en cours
            isAddRequestError: true, // affcihage de l'erreur
          });
          handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          isAddLangInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  //!
  const showFormEducation = () => {
    setState({
      isEducationEmpty: false,
      // showEducationForm : true,
      formEducation: true,
      showEducationForm2Btn: false,
    });
  };
  const closeFormEducation = () => {
    setState({
      formEducation: false,
      showEducationForm2Btn: true,
    });
  };
  // const closeFormEducation = () => {
  //   setState({
  //     showEducationForm3Btn: false,
  //   });
  // };

  //! Modal settings
  const editEducationModal = (educ) => {
    // this.onGetEducation(id);
    // console.log("educ", educ)
    setState({
      diplome: educ.diplome,
      school: educ.school,
      ville: educ.ville,
      pays: educ.pays,
      diplome_label: educ.diplome_label,
      idEdu: educ.id,
      showEditModal: true,
      annee_scolaire: educ.annee_scolaire,
    });
  };
  const editProjetModal = (item) => {
    // this.onGetEducation(id);
    // console.log("educ", educ)
    setState({
      project_name: item.project_name,
      project_technologies: item.project_technologies,
      showProjetModal: true,
      id_projet: item.id,
    });
  };
  const editTacheModal = (tache) => {
    // this.onGetEducation(id);
    // console.log("educ", tache)
    setState({
      tache_content: tache.tache_content,
      showTacheModal: true,
      id_tache: tache.id,
      project_id: tache.project_id,
      // id_projet: item.id,
    });
  };
  const editTechModal = (techno) => {
    // this.onGetEducation(id);
    // console.log("educ", techno)
    setState({
      techno_name: techno.techno_name,
      showTechModal: true,
      techno_percent: techno.techno_percent,
      id_tech: techno.id,
      // id_projet: item.id,
    });
  };
  const editLangModal = (lang) => {
    // this.onGetEducation(id);
    // console.log("educ", lang)
    setState({
      languague_label: lang.languague_label,
      showLangModal: true,
      language_name: lang.language_name,
      id_lang: lang.id,
      // id_projet: item.id,
    });
  };
  const closeLang = () => {
    setState({
      showLangModal: false,
    });
  };
  const closeTache = () => {
    setState({
      showTacheModal: false,
    });
  };
  const close = () => {
    setState({
      showEditModal: false,
    });
  };
  const closeProjet = () => {
    setState({
      showProjetModal: false,
    });
  };
  const closeTech = () => {
    setState({
      showTechModal: false,
    });
  };

  //! Handle Update Change
  const handleUpdateChange = (e) => {
    let fields = state.fields;
    fields["cvtheque_id"] = state.cvinfos.id;
    fields["action"] = "add";
    if (e.target.name === "img_path") {
      //   console.log(e.target.files);
      createImage(e.target.files[0]);
      fields["img_path"] = e.target.files[0];
    } else {
      fields[e.target.name] = e.target.value;
    }

    setState({
      fields,
    });

    // console.log(fields);
  };

  //! Upadte Education Function

  // With Redux
  const handleSetState = () => {
    setState({
      updateEducationInProgress: false,
      isSuccessAddItemAlert: true,
      showEditModal: false,
    });
  };

  const updateEducation = (e, id) => {
    e.preventDefault();
    setState({
      updateEducationInProgress: true,
    });
    var fd = new FormData();
    fd.append("cvtheque_id", state.id_cv);
    fd.append("school", state.school);
    fd.append("annee_scolaire", state.annee_scolaire);
    fd.append("diplome", state.diplome);
    fd.append("diplome_label", state.diplome_label);
    fd.append("ville", state.ville);
    fd.append("pays", state.pays);
    axios
      .put(API.API + "cveducations/" + id, fd)
      .then((response) => {
        if (response.data.success === true) {
          //   getBakelisteCV(state.bakelisteID);
          dispatch(getBakelisteCV(state.bakelisteID));
          setState({
            updateEducationInProgress: false,
            isSuccessAddItemAlert: true,
            showEditModal: false,
          });
          handleShowAndHideAlert("successAddItem");
        } else {
          setState({
            updateEducationInProgress: false,
            isAddRequestError: true,
          });
          handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          updateEducationInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  //! Close Form Education
  const closeFormEducation2 = (e) => {
    setState({
      formEducation: false,
      showEducationForm2Btn: true,
    });
  };

  //! Update Profect Function
  const updateProjet = (e, id) => {
    e.preventDefault();
    setState({
      updateProjetInProgress: true,
    });
    var fd = new FormData();
    fd.append("cvtheque_id", state.id_cv);
    fd.append("project_name", state.project_name);
    fd.append("project_technologies", state.project_technologies);
    axios
      .put(API.API + "cvprojects/" + id, fd)
      .then((response) => {
        if (response.data.success === true) {
          //   getBakelisteCV(state.bakelisteID);
          dispatch(getBakelisteCV(state.bakelisteID));
          setState({
            updateProjetInProgress: false,
            isSuccessAddItemAlert: true,
            showProjetModal: false,
          });
          handleShowAndHideAlert("successAddItem");
        } else {
          setState({
            updateProjetInProgress: false,
            isAddRequestError: true,
          });
          handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          updateProjetInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  //! Upadte Tache Function
  const updateTache = (e, id) => {
    e.preventDefault();
    setState({
      updateTacheInProgress: true,
    });
    var fd = new FormData();
    fd.append("cvtheque_id", state.id_cv);
    fd.append("tache_content", state.tache_content);
    fd.append("project_id", state.project_id);
    axios
      .put(API.API + "cvtaches/" + id, fd)
      .then((response) => {
        if (response.data.success === true) {
          //   getBakelisteCV(state.bakelisteID);
          dispatch(getBakelisteCV(state.bakelisteID));
          setState({
            updateTacheInProgress: false,
            isSuccessAddItemAlert: true,
            showTacheModal: false,
          });
          handleShowAndHideAlert("successAddItem");
        } else {
          setState({
            updateTacheInProgress: false,
            isAddRequestError: true,
          });
          handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          updateTacheInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  //! Update Tech Function
  const updateTech = (e, id) => {
    e.preventDefault();
    setState({
      updateTechInProgress: true,
    });
    var fd = new FormData();
    fd.append("cvtheque_id", state.id_cv);
    fd.append("techno_name", state.techno_name);
    fd.append("techno_percent", state.techno_percent);
    axios
      .put(API.API + "cvtechnologies/" + id, fd)
      .then((response) => {
        if (response.data.success === true) {
          //   getBakelisteCV(state.bakelisteID);
          dispatch(getBakelisteCV(state.bakelisteID));
          setState({
            updateTechInProgress: false,
            isSuccessAddItemAlert: true,
            showTechModal: false,
          });
          handleShowAndHideAlert("successAddItem");
        } else {
          setState({
            updateTechInProgress: false,
            isAddRequestError: true,
          });
          handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          updateTechInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  //! Update Lang
  const updateLang = (e, id) => {
    e.preventDefault();
    setState({
      updateLangInProgress: true,
    });
    var fd = new FormData();
    fd.append("cvtheque_id", state.id_cv);
    fd.append("language_name", state.language_name);
    fd.append("languague_label", state.languague_label);
    axios
      .put(API.API + "cvlanguages/" + id, fd)
      .then((response) => {
        if (response.data.success === true) {
          //   getBakelisteCV(state.bakelisteID);
          dispatch(getBakelisteCV(state.bakelisteID));
          setState({
            updateLangInProgress: false,
            isSuccessAddItemAlert: true,
            showLangModal: false,
          });
          handleShowAndHideAlert("successAddItem");
        } else {
          setState({
            updateLangInProgress: false,
            isAddRequestError: true,
          });
          handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          updateLangInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  const showCv = () => setState({ showCvModal: true });
  const closeCv = () => setState({ showCvModal: false });

  return (
    <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
      <div className="row">
        <div className="col-lg-9">
          <nav aria-label="breadcrumb fixed">
            <ol className="breadcrumb fixed">
              <li className="breadcrumb-item ">
                <Link to="coach-dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ajout CV
              </li>
            </ol>
          </nav>

          <div className="row m-b-15">
            <div className="col m-b-15">
              <h6 className="h-theme ff-inter fw-600">Ajout de CV</h6>
            </div>
            <div className="col dis-flex m-b-15">
              <ModalContactUs />
            </div>
          </div>

          <h6 className="h-theme ff-inter fw-600 h61">
            {" "}
            {state.cvinfos.cv_name}
          </h6>

          <h6 className="h-theme ff-inter fw-600 h61" onClick={showCv}>
            Voir CV
          </h6>
          <Modal
            dialogClassName="modal-90w"
            className="modal-responsive-md"
            show={state.showCvModal}
            onHide={closeCv}
            size={state.size}
          >
            <Modal.Header closeButton>
              <Modal.Title>CURRICULUM VITAE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PDFViewer showToolbar={false} width="100%" height={700}>
                <CVPdf data={allCvData.data} />
              </PDFViewer>
            </Modal.Body>
            <Modal.Footer>
              <PDFDownloadLink
                document={<CVPdf data={allCvData.data} />}
                fileName={`${allCvData.data?.cvthequeData?.bakelisteFullname}.pdf`}
              >
                {({ loading }) =>
                  loading ? (
                    <Button disabled className="mt-3" variant="primary">
                      Chargement...
                    </Button>
                  ) : (
                    <Button className="mt-3" variant="primary">
                      Telecharger CV
                    </Button>
                  )
                }
              </PDFDownloadLink>
            </Modal.Footer>
          </Modal>
          <div>
            <Accordion className="accordion">
              <Card className="carde">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  variant="link"
                  className="according-text"
                >
                  Education
                  <FeatherIcon
                    className="chevron offset-10"
                    icon="chevron-down"
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <div className="bodyAcordion formrow">
                    {state.isEducationEmpty === true &&
                      state.showEducationForm === false && (
                        <div className="empty_container">
                          <div className="empty_education alert alert-warning">
                            Vous n'avez pas encore renseigné votre parcours
                            scolaire. Nous vous invitons à le faire.
                          </div>

                          <button
                            type="button"
                            className="col-md-4 offset-md-6 btn-theme"
                            onClick={(e) => this.showFormEducation(e, "first")}
                          >
                            Ajouter
                          </button>
                        </div>
                      )}
                    {state.isEducationEmpty === false && (
                      <div className="list_educations_row ">
                        {state.cveducation.map((educ, i) => (
                          <div className="col-md-12 education_item_container">
                            <div className="row">
                              <div className="col-md-6">
                                <span className="diplome_label">
                                  {" "}
                                  {educ.diplome_label},{" "}
                                </span>
                                <span className="school_container">
                                  {" "}
                                  {educ.school} -{" "}
                                </span>
                                <i className="diplome_container">
                                  {" "}
                                  {educ.diplome}{" "}
                                </i>{" "}
                              </div>
                              <div className="col-md-6">
                                <FeatherIcon
                                  className="edit-education offset-md-4"
                                  icon="edit-3"
                                  onClick={() => editEducationModal(educ)}
                                />
                              </div>
                            </div>
                            <br />
                            <span className="ville_pays">
                              {" "}
                              {educ.ville} - {educ.pays}{" "}
                            </span>
                            <hr />
                            <Modal
                              className="modal-responsive-md"
                              size={state.size}
                              show={state.showEditModal}
                              onHide={close}
                            >
                              <form
                                onSubmit={(e) =>
                                  updateEducation(e, state.idEdu)
                                }
                              >
                                <Modal.Header>
                                  <Modal.Title className="modal-contact-us">
                                    Modifier Education{" "}
                                  </Modal.Title>
                                </Modal.Header>

                                <div className="row ">
                                  <div className="col-md-6">
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control fmCv"
                                        id="exampleInputEmail1"
                                        name="diplome"
                                        aria-describedby="emailHelp"
                                        placeholder="Diplôme:"
                                        onChange={(e) =>
                                          setState({
                                            diplome: e.target.value,
                                          })
                                        }
                                        value={state.diplome}
                                      />
                                    </div>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control fmCv"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Ecole:"
                                        name="school"
                                        value={state.school}
                                        onChange={(e) =>
                                          setState({
                                            school: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control fmCv"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Ecole:"
                                        name="annee_scolaire"
                                        value={state.annee_scolaire}
                                        onChange={(e) =>
                                          setState({
                                            annee_scolaire: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control fmCv"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Ville:"
                                        name="ville"
                                        value={state.ville}
                                        onChange={(e) =>
                                          setState({
                                            ville: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control fmCv"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Pays d'obtention:"
                                        name="pays"
                                        value={state.pays}
                                        onChange={(e) =>
                                          setState({
                                            pays: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control fmCv"
                                        id="exampleInputEmail1"
                                        placeholder="Libellé du dipôme - Exemple : Génie Informatique"
                                        name="diplome_label"
                                        value={state.diplome_label}
                                        onChange={(e) =>
                                          setState({
                                            diplome_label: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    {state.updateEducationInProgress ? (
                                      <button
                                        type="submit"
                                        className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                                        disabled
                                      >
                                        Modification en cours &nbsp;
                                        <FeatherIcon
                                          icon="loader"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    ) : (
                                      <button
                                        type="submit"
                                        className=" col-md-4 btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                      >
                                        Valider
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </form>
                            </Modal>
                          </div>
                        ))}

                        {state.showEducationForm2Btn === true && (
                          <div className="add_form_btn_container">
                            <button
                              type="button"
                              className="col-md-4 offset-md-6 btn-theme"
                              onClick={(e) => showFormEducation(e, "first")}
                            >
                              Ajouter
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                    {state.formEducation === true && (
                      <form id="addNewEducationForm formrow">
                        <div className="row ">
                          <div className="col-md-6">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control fmCv"
                                id="exampleInputEmail1"
                                name="diplome"
                                aria-describedby="emailHelp"
                                placeholder="Diplôme:"
                                onChange={handleChange}
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control fmCv"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                name="annee_scolaire"
                                placeholder="Année scolaire:"
                                onChange={handleChange}
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control fmCv"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Ecole:"
                                name="school"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control fmCv"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Ville:"
                                name="ville"
                                onChange={handleChange}
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control fmCv"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Pays d'obtention:"
                                name="pays"
                                onChange={handleChange}
                              />
                            </div>
                            <div class="form-group">
                              <input
                                type="email"
                                class="form-control fmCv"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Libellé du dipôme - Exemple : Génie Informatique"
                                name="diplome_label"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            {state.isAddEducationInProgress === false ? (
                              <button
                                type="button"
                                className="col-md-6 offset-md-6 btn-theme"
                                onClick={(e) => SubmitNewEducation(e, "first")}
                              >
                                Ajouter
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn-theme-orange"
                                disabled
                              >
                                Ajout en cours &nbsp;
                                <FeatherIcon icon="loader" aria-hidden="true" />
                              </button>
                            )}
                          </div>
                          <div className="col-md-6">
                            <button
                              type="button"
                              className="col-md-6 offset-md-6 btn-theme-orange"
                              onClick={(e) => closeFormEducation2(e)}
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>

          <div>
            <Accordion className="accordion">
              <Card className="carde">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  variant="link"
                  className="according-text"
                >
                  Projets &nbsp;&nbsp;&nbsp;&nbsp;
                  <FeatherIcon
                    className="chevron offset-10"
                    icon="chevron-down"
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <div className="bodyAcordion">
                    {state.isProjectEmpty === true &&
                      state.showProjectForm === false && (
                        <div className="empty_container">
                          <div className="empty_projects alert alert-warning">
                            Vous n'avez pas encore renseigné vos projets. Nous
                            vous invitons à le faire.
                          </div>
                          <div className="add_form_btn_container">
                            <button
                              type="button"
                              className="col-md-4 offset-md-6 btn-theme"
                              onClick={(e) =>
                                this.addNewProjectForm(e, "pFirstForm")
                              }
                            >
                              Ajouter
                            </button>
                          </div>
                        </div>
                        // {this.state.showProjectForm === true &&
                      )}
                    {state.showProjectForm === true && (
                      <div className="form_container row">
                        <div className="col-md-12">
                          <h5 className="new_project_title">Nouveau projet</h5>
                        </div>

                        <form
                          id="addNewProjetForm"
                          onSubmit={handleSubmitNewProject}
                        >
                          <div className="row formrow">
                            <div className="col-md-6">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control fmCv"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  name="project_name"
                                  placeholder="Nom du projet:"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control fmCv"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Technologies:"
                                  name="project_technologies"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              {state.isAddProjectInProgress === false ? (
                                <button
                                  type="button"
                                  className="col-md-6 btn-theme"
                                  onClick={(e) =>
                                    handleSubmitNewProject(e, "first")
                                  }
                                >
                                  Ajouter
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn-theme-orange"
                                  disabled
                                >
                                  Ajout en cours &nbsp;
                                  <FeatherIcon
                                    icon="loader"
                                    aria-hidden="true"
                                  />
                                </button>
                              )}
                              <button
                                className="btn btn-theme-orange offset-md-2"
                                onClick={(e) => hideItemForm(e)}
                              >
                                Quitter
                              </button>
                            </div>
                            <div className="col-md-6"></div>
                          </div>
                        </form>
                      </div>
                    )}

                    {state.isProjectEmpty === false && (
                      <div className="list_projects_row">
                        {state.cvprojects.map((item, indexe) => (
                          <div
                            key={indexe}
                            className="list_projects_container col-md-12"
                          >
                            <div className="row">
                              <div className="col-md-6">
                                <h5 className="h-theme ff-inter fw-600">
                                  Nom du projet : {item.project_name}{" "}
                                </h5>
                              </div>
                              <div className="col-md-6">
                                <FeatherIcon
                                  className="edit-education offset-md-2"
                                  icon="edit-3"
                                  onClick={() => editProjetModal(item)}
                                />
                              </div>

                              <Modal
                                className="modal-responsive-md"
                                size={state.size}
                                show={state.showProjetModal}
                                onHide={closeProjet}
                              >
                                <form
                                  onSubmit={(e) =>
                                    updateProjet(e, state.id_projet)
                                  }
                                >
                                  <Modal.Header>
                                    <Modal.Title className="modal-contact-us">
                                      Modifier Projet{" "}
                                    </Modal.Title>
                                  </Modal.Header>

                                  <div className="row formrow">
                                    <div className="col-md-6">
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control fmCv"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          name="project_name"
                                          value={state.project_name}
                                          onChange={(e) =>
                                            setState({
                                              project_name: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control fmCv"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          name="project_technologies"
                                          value={state.project_technologies}
                                          onChange={(e) =>
                                            setState({
                                              project_technologies:
                                                e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      {state.updateProjetInProgress ? (
                                        <button
                                          type="submit"
                                          className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                                          disabled
                                        >
                                          Modification en cours &nbsp;
                                          <FeatherIcon
                                            icon="loader"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      ) : (
                                        <button
                                          type="submit"
                                          className=" col-md-4 btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                        >
                                          Valider
                                        </button>
                                      )}
                                    </div>
                                    <div className="col-md-6">
                                      <button
                                        type="submit"
                                        className="col-md-4 btn-theme-danger text-up m-t-30 w-full p-t-15 p-b-15"
                                        onClick={(e) => close(e)}
                                      >
                                        Annuler
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </Modal>
                            </div>
                            <h6 className="project_technologies_label">
                              {" "}
                              {item.project_technologies}{" "}
                            </h6>
                            {item.taches.length === 0 ? (
                              <div>
                                <i>Veuillez ajouter une ou les tâche(s)</i>
                              </div>
                            ) : (
                              <div className="list_taches_container">
                                <ul>
                                  {item.taches.map((tache, ind) => (
                                    <li className="tache_item">
                                      {" "}
                                      {tache.tache_content}{" "}
                                      <FeatherIcon
                                        className="edit-tache offset-md-2"
                                        icon="edit-2"
                                        onClick={() => editTacheModal(tache)}
                                      />
                                      <Modal
                                        className="modal-responsive-md"
                                        size={state.size}
                                        show={state.showTacheModal}
                                        onHide={closeTache}
                                      >
                                        <form
                                          onSubmit={(e) =>
                                            updateTache(e, state.id_tache)
                                          }
                                        >
                                          <Modal.Header>
                                            <Modal.Title className="modal-contact-us">
                                              Modifier Tâche{" "}
                                            </Modal.Title>
                                          </Modal.Header>

                                          <textarea
                                            type="text"
                                            class="form-control fmCv"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            name="tache_content"
                                            value={state.tache_content}
                                            onChange={(e) =>
                                              setState({
                                                tache_content: e.target.value,
                                              })
                                            }
                                          />

                                          <div className="row">
                                            <div className="col-md-6">
                                              {state.updateTacheInProgress ? (
                                                <button
                                                  type="submit"
                                                  className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                                                  disabled
                                                >
                                                  Modification en cours &nbsp;
                                                  <FeatherIcon
                                                    icon="loader"
                                                    aria-hidden="true"
                                                  />
                                                </button>
                                              ) : (
                                                <button
                                                  type="submit"
                                                  className=" col-md-4 btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                                >
                                                  Valider
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </form>
                                      </Modal>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            <hr />
                          </div>
                        ))}

                        <div className="row">
                          <div className="col-md-6 projects-btn">
                            {state.showProjectForm2Btn === true && (
                              <div className="add_form_btn_container">
                                <button
                                  type="button"
                                  className="col-md-4 offset-md-6 btn-theme"
                                  onClick={(e) =>
                                    addNewProjectForm(e, "pSecondForm")
                                  }
                                >
                                  Ajouter projet
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 taches-btn">
                            {state.showTacheFormBtn === true && (
                              <div className="add_tache_btn_container">
                                <button
                                  type="button"
                                  className="col-md-4 offset-md-6 btn-theme-orange"
                                  onClick={(e) => addNewTacheForm(e)}
                                >
                                  Ajouter Tâche
                                </button>
                              </div>
                            )}
                          </div>
                        </div>

                        {state.showTacheForm === true && (
                          <div className="row tache_form_container">
                            <div className="col-md-12">
                              <h5 className="new_tache_title">
                                Nouvelle tâche
                              </h5>
                            </div>
                            <div className="form_row">
                              <form
                                onSubmit={handleSubmitNewTache}
                                id="addTacheForm formrow"
                              >
                                <div className="row">
                                  <div className="select_project col-md-6 form-group">
                                    <select
                                      className="form-control project_id_label"
                                      name="project_id"
                                      onChange={handleChange}
                                      required
                                    >
                                      <option>--Choisir un projet--</option>
                                      {state.cvprojects.map((pr, indx) => (
                                        <option key={indx} value={pr.id}>
                                          {" "}
                                          {pr.project_name}{" "}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="tache_content col-md-6 form-group">
                                    <textarea
                                      placeholder="Tâche effectuée"
                                      className="form-control formrow"
                                      name="tache_content"
                                      onChange={handleChange}
                                      required
                                    />
                                  </div>
                                </div>

                                {state.isAddRequestError && (
                                  <div className="error-row">
                                    <div className="col-md-12 error-container alert alert-danger">
                                      Une erreur est survenue. Veuillez
                                      réessayer plus tard.
                                    </div>
                                  </div>
                                )}
                                <div className="col-md-12 form-group">
                                  {state.isAddTacheInProgress === false ? (
                                    <button className="btn btn-theme">
                                      Valider
                                    </button>
                                  ) : (
                                    <button className="btn btn-theme-orange">
                                      Ajout en cours
                                      <i className="fas fa-spin fa-spinner"></i>
                                    </button>
                                  )}
                                  <button
                                    className="btn btn-theme-orange offset-md-1"
                                    onClick={(e) => hideItemForm(e)}
                                  >
                                    Quitter
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {state.showProjectForm2 === true && (
                      <form id="addNewProjetForm">
                        <div className="row formrow">
                          <div className="col-md-6">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control fmCv"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                name="project_name"
                                placeholder="Nom du projet:"
                                onChange={handleChange}
                              />
                            </div>
                            {/* <div class="form-group">
                            <input
                              type="text"
                              class="form-control fmCv"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Tâche:"
                            />
                          </div> */}
                          </div>
                          <div className="col-md-6">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control fmCv"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Technologies:"
                                name="project_technologies"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            {state.isAddProjectInProgress === false ? (
                              <button
                                type="button"
                                className="col-md-6 btn-theme"
                                onClick={(e) =>
                                  handleSubmitNewProject(e, "first")
                                }
                              >
                                Ajouter
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn-theme-orange"
                                disabled
                              >
                                Ajout en cours &nbsp;
                                <FeatherIcon icon="loader" aria-hidden="true" />
                              </button>
                            )}
                            <button
                              className="btn btn-theme-orange offset-md-2"
                              onClick={(e) => hideItemForm(e)}
                            >
                              Quitter
                            </button>
                          </div>
                          <div className="col-md-6"></div>
                        </div>
                      </form>
                    )}
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          <div>
            <Accordion className="accordion">
              <Card className="carde">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  variant="link"
                  className="according-text"
                >
                  Motivation
                  <FeatherIcon
                    className="chevron offset-10"
                    icon="chevron-down"
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <div className="bodyAcordion">
                    {state.cvinfos.cv_motivations === null &&
                      state.showMotivationForm === false && (
                        <div className="empty_motivations_row">
                          <div className="col-md-12 error-alert-container alert alert-warning">
                            Vous n'avez pas encore écrit vos motivations. Nous
                            vous invitons à le faire. Merci !
                          </div>{" "}
                          <hr />
                          <div className="add_form_switcher_container">
                            <button
                              type="button"
                              className="offset-md-6 btn-theme"
                              onClick={(e) =>
                                addMotivationForm(
                                  e,
                                  "moFirstForm",
                                  state.cvinfos.cv_motivations
                                )
                              }
                            >
                              Ajouter
                            </button>
                          </div>
                        </div>
                      )}
                    {state.cvinfos.cv_motivations !== null && (
                      <div className="motivations_content_row row">
                        <div className="motivations_item_container">
                          <div className="col-md-12 motivations_item">
                            <p>{state.cvinfos.cv_motivations}</p>
                          </div>
                        </div>{" "}
                        <hr />
                        {state.showMotivationForm2Btn === true && (
                          <div className="add_form_btn_container col-md-12">
                            <button
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Modifier vos motivations"
                              className="btn-theme"
                              onClick={(e) =>
                                addMotivationForm(
                                  e,
                                  "moSecondForm",
                                  state.cvinfos.cv_motivations
                                )
                              }
                            >
                              {/* <i className="fas fa-edit"></i>
                                <FeatherIcon icon="edit"></FeatherIcon> */}
                              Modifier
                            </button>
                          </div>
                        )}
                      </div>
                    )}

                    {state.showMotivationForm === true && (
                      <form
                        id="addUpdateMotivationForm"
                        onSubmit={(e) => SubmitMotivation(e, "first")}
                      >
                        <div class="form-group formrow">
                          <textarea
                            type="text"
                            class="form-control fmTa"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Motivation:"
                            name="cv_motivations"
                            onChange={handleChange}
                          />
                        </div>

                        {state.isAUMotivationInProgress === false ? (
                          <button
                            type="button"
                            className="col-md-6 offset-md-6 btn-theme"
                          >
                            Valider
                          </button>
                        ) : (
                          <button className="btn btn-theme-orange pull-right">
                            Ajout en cours
                            <i className="fas fa-spin fa-spinner"></i>
                          </button>
                        )}
                      </form>
                    )}
                    {state.showMotivationForm2 === true && (
                      <div className="motivations_form_row row">
                        <div className="col-md-12 add_title">
                          <h5 className="h-theme ff-inter fw-600">
                            Modifier vos motivations{" "}
                          </h5>
                          <form
                            id="addUpdateMotivationForm"
                            onSubmit={(e) => SubmitMotivation(e, "second")}
                          >
                            <div className="col-md-6 col-md-offset-3 form-group cvMotivation">
                              <textarea
                                value={state.cvMotivationsValue}
                                placeholder="Ajoutez vos motivations"
                                name="cv_motivations"
                                className="form-control cv_motivation_input"
                                onChange={handleChangeUpdateMotivation}
                              />
                            </div>
                            {state.isAddRequestError && (
                              <div className="error-row">
                                <div className="col-md-12 error-container alert alert-danger">
                                  Une erreur est survenue. Veuillez réessayer
                                  plus tard.
                                </div>
                              </div>
                            )}
                            <div className="col-md-12 form-group">
                              <button
                                className="btn btn-theme-danger pull-left volkeno-red-bcolor return-btn"
                                onClick={(e) => hideItemForm(e)}
                              >
                                Quitter
                              </button>
                              {state.isAUMotivationInProgress === false ? (
                                <button className="btn btn-theme offset-md-4">
                                  Valider
                                </button>
                              ) : (
                                <button className="btn btn-theme-orange pull-right volkeno-orange-bcolor inprogress-btn">
                                  Modification en cours
                                  <i className="fas fa-spin fa-spinner"></i>
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          <div>
            <Accordion className="accordion">
              <Card className="carde">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  variant="link"
                  className="according-text"
                >
                  Outils/Technologies
                  <FeatherIcon
                    className="chevron offset-9"
                    icon="chevron-down"
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <div className="bodyAcordion">
                    <div className="techno_lang_row row">
                      <div className="col-md-6 techno_container">
                        <h6 className="text-center techno_lang_title">
                          {state.cvinfos.bakelisteDomaineName ===
                          "Programmation" ? (
                            <span>
                              <h6 className="h-theme ff-inter fw-600">
                                Technologies
                              </h6>
                            </span>
                          ) : (
                            <span>
                              <h6 className="h-theme ff-inter fw-600">
                                Outils
                              </h6>
                            </span>
                          )}
                        </h6>
                        {state.isTecnologyEmpty === true &&
                          state.showTechnoForm === false && (
                            // Render technology si les données sont nulles
                            <div className="empty_techno_row">
                              <div className="col-md-12 error-alert-container alert alert-warning">
                                Vous n'avez pas encore fourni d'informations.
                                Nous vous invitons à le faire. Merci !
                              </div>{" "}
                              <hr />
                              <div className="add_form_switcher_container">
                                <button
                                  type="button"
                                  className="offset-md-6 btn-theme"
                                  onClick={(e) =>
                                    addTechnoForm(e, "teFirstForm")
                                  }
                                >
                                  Ajouter
                                </button>
                              </div>
                            </div>
                          )}

                        {/* Render premier formulaire d'ajout de techno ou outils */}
                        {state.showTechnoForm === true && (
                          <div className="techno_form_row row">
                            <div className="col-md-12 add_title">
                              <h5 className="add_techno_title text-center">
                                Ajouter une technologies / Outils{" "}
                              </h5>
                              <form
                                id="addTechnoForm"
                                onSubmit={(e) => hanldeSubmitTechno(e, "first")}
                              >
                                <div className="row">
                                  <div className="col-md-6 form-group">
                                    <input
                                      type="text"
                                      placeholder="Ajoutez une technologie / Outils"
                                      name="techno_name"
                                      className="form-control fmCv"
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="col-md-6 form-group ">
                                    <input
                                      type="text"
                                      placeholder="% de maîtrise. Exemple: 65"
                                      name="techno_percent"
                                      className="form-control fmCv"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                {state.isAddRequestError && (
                                  <div className="error-row">
                                    <div className="col-md-12 error-container alert alert-danger">
                                      Une erreur est survenue. Veuillez
                                      réessayer plus tard.
                                    </div>
                                  </div>
                                )}
                                <div className="col-md-12 form-group">
                                  <button className="btn-theme pull-left volkeno-red-bcolor return-btn">
                                    Ajouter
                                  </button>
                                  {state.isAddTechnoInProgress === false ? (
                                    <button
                                      type="button"
                                      className="offset-md-6 btn-theme-orange"
                                      onClick={(e) => hideItemForm(e)}
                                    >
                                      Quitter
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn-theme-orange"
                                    >
                                      <i className="fas fa-spin fa-spinner"></i>
                                    </button>
                                  )}
                                </div>
                              </form>
                            </div>
                          </div>
                        )}

                        {/* Render techno si le champ n'est pas vide */}
                        {state.isTecnologyEmpty === false && (
                          <div className="techno_content_row row">
                            <div className="techno_item_container">
                              <div className="col-md-12 techno_item">
                                <ul className="list_techno_ul">
                                  {state.cvtechnology.map((techno, idx) => (
                                    <li key={idx} className="techno_li">
                                      {" "}
                                      {techno.techno_name}{" "}
                                      <FeatherIcon
                                        className="edit-tache offset-md-2"
                                        icon="edit-2"
                                        onClick={() => editTechModal(techno)}
                                      />
                                      <Modal
                                        className="modal-responsive-md"
                                        size={state.size}
                                        show={state.showTechModal}
                                        onHide={closeTech}
                                      >
                                        <form
                                          onSubmit={(e) =>
                                            updateTech(e, state.id_tech)
                                          }
                                        >
                                          <Modal.Header>
                                            <Modal.Title className="modal-contact-us">
                                              Modifier Technologie{" "}
                                            </Modal.Title>
                                          </Modal.Header>

                                          <div className="row">
                                            <div className="col-md-6 form-group">
                                              <input
                                                type="text"
                                                name="techno_name"
                                                className="form-control fmCv"
                                                value={state.techno_name}
                                                onChange={(e) =>
                                                  setState({
                                                    techno_name: e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                            <div className="col-md-6 form-group ">
                                              <input
                                                type="text"
                                                name="techno_percent"
                                                className="form-control fmCv"
                                                value={state.techno_percent}
                                                onChange={(e) =>
                                                  setState({
                                                    techno_percent:
                                                      e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-md-6">
                                              {state.updateTechInProgress ? (
                                                <button
                                                  type="submit"
                                                  className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                                                  disabled
                                                >
                                                  Modification en cours &nbsp;
                                                  <FeatherIcon
                                                    icon="loader"
                                                    aria-hidden="true"
                                                  />
                                                </button>
                                              ) : (
                                                <button
                                                  type="submit"
                                                  className=" col-md-4 btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                                >
                                                  Valider
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </form>
                                      </Modal>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>{" "}
                            <hr />
                            {/* Add new techno show form button */}
                            {state.showTechnoForm2Btn === true && (
                              <div className="add_form_btn_container col-md-12">
                                <button
                                  type="button"
                                  className="offset-md-6 btn-theme"
                                  onClick={(e) =>
                                    addTechnoForm(e, "teFirstForm")
                                  }
                                >
                                  Ajouter
                                </button>
                              </div>
                            )}
                            {/* Render ajout formulaire de techno */}
                            {state.showTechnoForm2 === true && (
                              <div className="motivations_form_row row">
                                <div className="col-md-12 add_title">
                                  <h5 className="add_motivation_title text-center">
                                    Ajouter une technologie / outils{" "}
                                  </h5>
                                  <form
                                    id="addTechnoForm"
                                    onSubmit={(e) =>
                                      hanldeSubmitTechno(e, "second")
                                    }
                                  >
                                    <div className="row">
                                      <div className="col-md-6 form-group">
                                        <input
                                          type="text"
                                          placeholder="Ajoutez une technologie / Outils"
                                          name="techno_name"
                                          className="form-control fmCv"
                                          onChange={handleChange}
                                        />
                                      </div>
                                      <div className="col-md-6 form-group techno">
                                        <input
                                          type="text"
                                          placeholder="% de maîtrise. Exemple: 65"
                                          name="techno_percent"
                                          className="form-control percent_input"
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>

                                    {state.isAddRequestError && (
                                      <div className="error-row">
                                        <div className="col-md-12 error-container alert alert-danger">
                                          Une erreur est survenue. Veuillez
                                          réessayer plus tard.
                                        </div>
                                      </div>
                                    )}
                                    <div className="col-md-12 form-group">
                                      <button
                                        className="btn btn-danger pull-left volkeno-red-bcolor return-btn"
                                        onClick={(e) => hideItemForm(e)}
                                      >
                                        Quitter
                                      </button>
                                      <button
                                        type="button"
                                        className="btn-theme-warning"
                                        onClick={(e) => hideItemForm(e)}
                                      >
                                        Quitter
                                      </button>
                                      {state.isAddTechnoInProgress === false ? (
                                        <button
                                          type="button"
                                          className="btn-theme"
                                        >
                                          Ajouter
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn-theme-warning"
                                        >
                                          Ajout en cours
                                          <i className="fas fa-spin fa-spinner"></i>
                                        </button>
                                      )}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 lang_container">
                        <h6 className="h-theme ff-inter fw-600">Langues</h6>
                        {state.isLanguageEmpty === true &&
                          state.showLangForm === false && (
                            // Render technology si les données sont nulles
                            <div className="empty_lang_row">
                              <div className="col-md-12 error-alert-container alert alert-warning">
                                Vous n'avez pas encore renseigné les langues que
                                vous parlez. Nous vous invitons à le faire.
                                Merci !
                              </div>{" "}
                              <hr />
                              <div className="add_form_switcher_container">
                                <button
                                  type="button"
                                  className="btn-theme offset-md-6"
                                  onClick={(e) => addLangForm(e, "laFirstForm")}
                                >
                                  Ajouter
                                </button>
                              </div>
                            </div>
                          )}

                        {/* Render premier formulaire d'ajout de langue */}
                        {state.showLangForm === true && (
                          <div className="techno_form_row row">
                            <div className="col-md-12 add_title">
                              <h5 className="add_techno_title text-center">
                                Ajouter une langue{" "}
                              </h5>
                              <form id="addLangForm">
                                <div class="row">
                                  <div className="col-md-4 form-group">
                                    <input
                                      type="text"
                                      placeholder="Langue"
                                      name="language_name"
                                      className="form-control fmCv"
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="col-md-6 form-group">
                                    <input
                                      type="text"
                                      placeholder="Label. Exemple : Lu, parlé et écrit"
                                      name="languague_label"
                                      className="form-control fmCv"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>

                                {state.isAddRequestError && (
                                  <div className="error-row">
                                    <div className="col-md-12 error-container alert alert-danger">
                                      Une erreur est survenue. Veuillez
                                      réessayer plus tard.
                                    </div>
                                  </div>
                                )}
                                <div className="col-md-12 form-group">
                                  {state.isAddLangInProgress === false ? (
                                    <button
                                      type="button"
                                      className="btn-theme"
                                      onClick={(e) =>
                                        hanldeSubmitLang(e, "first")
                                      }
                                    >
                                      Valider
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn-theme-warning"
                                    >
                                      Ajout en cours
                                      <i className="fas fa-spin fa-spinner"></i>
                                    </button>
                                  )}
                                  <button
                                    className="btn btn-theme-orange offset-md-4"
                                    onClick={(e) => hideItemForm(e)}
                                  >
                                    Quitter
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        )}

                        {/* Render lang si le champ n'est pas vide */}
                        {state.isLanguageEmpty === false && (
                          <div className="lang_content_row row">
                            <div className="lang_item_container">
                              <div className="col-md-12 lang_item">
                                <ul className="list_lang_ul">
                                  {state.cvlanguague.map((lang, ix) => (
                                    <li key={ix} className="lang_li">
                                      {" "}
                                      {lang.language_name} ({" "}
                                      {lang.languague_label} ){" "}
                                      <FeatherIcon
                                        className="edit-tache offset-md-2"
                                        icon="edit-2"
                                        onClick={() => editLangModal(lang)}
                                      />
                                      <Modal
                                        className="modal-responsive-md"
                                        size={state.size}
                                        show={state.showLangModal}
                                        onHide={closeLang}
                                      >
                                        <form
                                          onSubmit={(e) =>
                                            updateLang(e, state.id_lang)
                                          }
                                        >
                                          <Modal.Header>
                                            <Modal.Title className="modal-contact-us">
                                              Modifier Langues{" "}
                                            </Modal.Title>
                                          </Modal.Header>

                                          <div className="row">
                                            <div className="col-md-6 form-group">
                                              <input
                                                type="text"
                                                name="language_name"
                                                className="form-control fmCv"
                                                value={state.language_name}
                                                onChange={(e) =>
                                                  setState({
                                                    language_name:
                                                      e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                            <div className="col-md-6 form-group ">
                                              <input
                                                type="text"
                                                name="languague_label"
                                                className="form-control fmCv"
                                                value={state.languague_label}
                                                onChange={(e) =>
                                                  setState({
                                                    languague_label:
                                                      e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-md-6">
                                              {state.updateTechInProgress ? (
                                                <button
                                                  type="submit"
                                                  className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                                                  disabled
                                                >
                                                  Modification en cours &nbsp;
                                                  <FeatherIcon
                                                    icon="loader"
                                                    aria-hidden="true"
                                                  />
                                                </button>
                                              ) : (
                                                <button
                                                  type="submit"
                                                  className=" col-md-4 btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                                >
                                                  Valider
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </form>
                                      </Modal>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>{" "}
                            <hr />
                            {/* Add new lang show form button */}
                            {state.showLangForm2Btn === true && (
                              <div className="add_form_btn_container col-md-12">
                                <button
                                  type="button"
                                  className="btn-theme"
                                  onClick={(e) =>
                                    addLangForm(e, "laSecondForm")
                                  }
                                >
                                  Ajouter
                                </button>
                              </div>
                            )}
                            {/* Render ajout formulaire de lang */}
                            {state.showLangForm2 === true && (
                              <div className="motivations_form_row row">
                                <div className="col-md-12 add_title">
                                  <h5 className="add_motivation_title text-center">
                                    Ajouter une langue{" "}
                                  </h5>
                                  <form id="addLangForm">
                                    <div className="row">
                                      <div className="col-md-4 form-group">
                                        <input
                                          type="text"
                                          placeholder="Langue"
                                          name="language_name"
                                          className="form-control fmCv"
                                          onChange={handleChange}
                                        />
                                      </div>
                                      <div className="col-md-6 form-group">
                                        <input
                                          type="text"
                                          placeholder="Label. Exemple : Lu, parlé et écrit"
                                          name="languague_label"
                                          className="form-control fmCv"
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                    {state.isAddRequestError && (
                                      <div className="error-row">
                                        <div className="col-md-12 error-container alert alert-danger">
                                          Une erreur est survenue. Veuillez
                                          réessayer plus tard.
                                        </div>
                                      </div>
                                    )}
                                    <div className="col-md-12 form-group">
                                      {state.isAddLangInProgress === false ? (
                                        <button
                                          type="button"
                                          className="btn-theme"
                                          onClick={(e) =>
                                            hanldeSubmitLang(e, "second")
                                          }
                                        >
                                          Valider
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn-theme-warning"
                                        >
                                          Ajout en cours
                                          <i className="fas fa-spin fa-spinner"></i>
                                        </button>
                                      )}
                                      <button
                                        className="btn-theme-orange offset-md-4"
                                        onClick={(e) => hideItemForm(e)}
                                      >
                                        Quitter
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Render premier formulaire d'ajout de techno ou outils */}
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          <div>
            <Accordion className="accordion">
              <Card className="carde">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  variant="link"
                  className="according-text"
                >
                  Portfolio &nbsp;&nbsp;
                  <FeatherIcon
                    className="chevron offset-10"
                    icon="chevron-down"
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <div className="bodyAcordionPortfolio">
                    {state.isPortfolioEmpty === true &&
                      state.showPortfolioForm === false &&
                      state.isProjectEmpty === true && (
                        <div className="empty_container">
                          <div className="empty_portfolio alert alert-danger">
                            Vous n'avez pas encore ajouté de projets. Vous devez
                            en créer au préalable pour pouvoir gérer votrer
                            portfolio.
                          </div>
                        </div>
                      )}
                    {/* {state.isEducationEmpty === false */}
                    {state.isPortfolioEmpty === true &&
                      state.showPortfolioForm === false &&
                      state.isProjectEmpty === false && (
                        <div className="empty_container">
                          <div className="empty_portfolio alert alert-warning">
                            Vous n'avez pas encore uploadé vos schreenshots.
                            Nous vous invitons à le faire.
                          </div>
                          <div className="add_form_btn_container">
                            <button
                              title="Ajouter un portfolio"
                              className="btn btn-theme"
                              onClick={(e) =>
                                addNewPortfolioForm(e, "poFirstForm")
                              }
                            >
                              Ajouter
                            </button>
                          </div>
                        </div>
                      )}
                    {state.showPortfolioForm === true && (
                      <div className="form_container row">
                        <div className="col-md-12">
                          <h5 className="new_project_title">
                            Nouveau screenshot
                          </h5>
                        </div>
                        <form
                          onSubmit={(e) => handleSubmitNewPortfolio(e, "first")}
                          id="addNewPortfolioForm"
                        >
                          <div className="row">
                            <div className="select_project col-md-6 form-group">
                              <select
                                className="form-control project_id_label"
                                name="project_id"
                                onChange={handleChange}
                                required
                              >
                                <option>--Choisir un projet--</option>
                                {state.cvprojects.map((pr, indx) => (
                                  <option key={indx} value={pr.id}>
                                    {" "}
                                    {pr.project_name}{" "}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-6 form-group">
                              <input
                                type="file"
                                name="img_path"
                                onChange={handleChange}
                                className="form-control fmCv"
                              />
                            </div>
                          </div>
                          {state.isAddRequestError && (
                            <div className="error-row">
                              <div className="col-md-12 error-container alert alert-danger">
                                Une erreur est survenue. Veuillez réessayer plus
                                tard.
                              </div>
                            </div>
                          )}
                          <div className="col-md-12 form-group">
                            {state.isAddPortfolioInProgress === false ? (
                              <button className="col-md-4 btn btn-theme">
                                Valider
                              </button>
                            ) : (
                              <button className="btn btn-theme-orange">
                                Ajout en cours
                                <i className="fas fa-spin fa-spinner"></i>
                              </button>
                            )}
                            <button
                              className=" col-md-4 btn btn-theme-danger offset-md-4"
                              onClick={(e) => hideItemForm(e)}
                            >
                              Quitter
                            </button>
                          </div>
                        </form>
                      </div>
                    )}

                    {/* Render portfolio s'il n'est pas vide */}
                    {state.isEducationEmpty === false && (
                      <div className="list_portfolio_row row">
                        {state.cvportfolio.map((port, ind) => (
                          <div className="col-md-6 portfolio-card">
                            <div class="card" style={{ width: "18rem" }}>
                              <img
                                src={port.img_path}
                                class="card-img-top"
                                alt={port.projectName}
                              />
                              <div class="card-body">
                                <div className="row">
                                  <div className="col-md-8">
                                    <h5 class="card-title">
                                      <span className="span-projet">
                                        Projet:
                                      </span>{" "}
                                      &nbsp;{port.projectName}
                                    </h5>
                                  </div>
                                  <div
                                    className="col-md-4"
                                    data-toggle="modal"
                                    data-target="#showPrtfolioItemModal"
                                    onClick={(e) => getPortfolioInfos(port)}
                                  >
                                    <FeatherIcon
                                      icon="eye"
                                      className="iconeVoir"
                                    >
                                      Voir
                                    </FeatherIcon>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {/* Show portfolio item modal */}
                        <div
                          className="modal fade"
                          id="showPrtfolioItemModal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="showPrtfolioItemModalLabel"
                        >
                          <div
                            className="modal-dialog modal-lg"
                            role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">
                                  {" "}
                                  {state.portProjectName}{" "}
                                </h4>
                                <button
                                  type="button"
                                  className="close "
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  aria-hidden="true"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <img
                                  src={state.imgPathValue}
                                  alt=""
                                  className="imagePortfolio"
                                />
                              </div>
                              <div className="modal-footer">
                                {/* <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Add new portfolio button */}
                        {state.showPortfolioForm2Btn === true && (
                          // <div className="add_form_btn_container col-md-12">
                          //   <button  title="Ajouter un portfolio" className="btn btn-theme" onClick={(e) => addNewPortfolioForm(e, 'poSecondForm')}>
                          //     AJouter
                          //   </button>
                          // </div>
                          <div className="add_form_btn_container col-md-12">
                            <button
                              title="Ajouter un portfolio"
                              className="col-md-3 offset-md-8 btn btn-theme"
                              onClick={(e) =>
                                addNewPortfolioForm(e, "poSecondForm")
                              }
                            >
                              Ajouter
                            </button>
                          </div>
                        )}

                        {/* Render add form au clic sur le bouton ajouter */}
                        {state.showPortfolioForm2 === true && (
                          <div className="form_container row">
                            <div className="col-md-12">
                              <h5 className="new_project_title">
                                Nouveau screenshot
                              </h5>
                            </div>
                            <form
                              onSubmit={(e) =>
                                handleSubmitNewPortfolio(e, "second")
                              }
                              className="newform"
                              id="addNewPortfolioForm"
                            >
                              <div className="row">
                                <div className="select_project col-md-6 form-group">
                                  <select
                                    className="form-control project_id_label"
                                    name="project_id"
                                    onChange={handleChange}
                                    required
                                  >
                                    <option>--Choisir un projet--</option>
                                    {state.cvprojects.map((pr, indx) => (
                                      <option key={indx} value={pr.id}>
                                        {" "}
                                        {pr.project_name}{" "}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-md-6 form-group">
                                  <input
                                    type="file"
                                    name="img_path"
                                    onChange={handleChange}
                                    className="form-control fmCv"
                                  />
                                </div>
                              </div>
                              {state.isAddRequestError && (
                                <div className="error-row">
                                  <div className="col-md-12 error-container alert alert-danger">
                                    Une erreur est survenue. Veuillez réessayer
                                    plus tard.
                                  </div>
                                </div>
                              )}
                              <div className="col-md-12 form-group">
                                <button
                                  className="btn btn-theme-danger"
                                  onClick={(e) => hideItemForm(e)}
                                >
                                  Quitter
                                </button>
                                {state.isAddPortfolioInProgress === false ? (
                                  <button className="btn btn-theme offset-md-6 ">
                                    Valider
                                  </button>
                                ) : (
                                  <button className="btn btn-theme-orange">
                                    Ajout en cours
                                    <i className="fas fa-spin fa-spinner"></i>
                                  </button>
                                )}
                              </div>
                            </form>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
              <CoachItemDashboardBakeliste />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
              <BakelisteGroupItem />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
              <NextReunionItem />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
              <SavedReunionListItem />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjoutCvBakelisteRefac;
