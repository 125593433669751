import React, { Component } from "react";
import "./listeCoach.css";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import Preloader from "../preloader/index";
import API from "../../variablesGlobales";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArchiveIcon from "@material-ui/icons/Archive";

import axios from "axios";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import { Link } from "react-router-dom";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listeCoachActions from "../../store/listeCoach/actions";
export default class listeCoach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: window.sessionStorage.getItem("user_id"),
      coachs: [],
      api: API.API,
      coachID: 0,
      coachFullname: "",
      coachDomaine: "",
      coachDomaineID: 0,
      coachStatus: "",
      coachNbreBakelistes: "",
      updatedCoachStatusInProgress: false,
      updatedError: false,
      updateFields: {},
      updateCoach: [],
      is_archived: 0,
    };
  }

  componentDidMount() {
    this.getListCoach();
  }

  getListCoach = () => {
    axios.get(this.state.api + "liste-des-coachs").then((res) => {
      //console.log('coach', res.data.data)
      this.setState({
        coachs: res.data.data,
        loading: false,
      });
      // console.log("j ves" + res)
      // console.log(this.state.coach)
    });
  };

  getCoachID = (coach) => {
    window.sessionStorage.setItem("lcoach_id", coach.id);
    // console.log(window.sessionStorage.getItem("lcoach_id"));
  };

  updateCoachStatusHandleChange = (e) => {
    let fields = this.state.updateFields;
    fields[e.target.name] = e.target.value;
    this.setState({
      updateFields: fields,
    });
    // console.log(fields);
  };
  updateCoachRoleHandleChange = (e) => {
    let fields = this.state.updateFields;
    fields[e.target.name] = e.target.value;
    this.setState({
      updateFields: fields,
    });
    // console.log(fields);
  };
  updateCoachStatus = (e) => {
    e.preventDefault();
    this.setState({
      updatedCoachStatusInProgress: true,
    });
    // console.log(this.state.updateFields);
    let updatedData = this.state.updateFields;
    let coachID = window.sessionStorage.getItem("lcoach_id");
    axios
      .post(this.state.api + "update-coach-status/" + coachID, updatedData)
      .then((response) => {
        this.setState({
          updatedCoachStatusInProgress: false,
        });
        if (response.data.success === true) {
          window.location = "liste-coach";
        } else {
          this.setState({
            updatedError: true,
          });
        }
      });
  };

  updateCoachRole = (e) => {
    e.preventDefault();
    this.setState({
      updatedCoachStatusInProgress: true,
    });
    // console.log(this.state.updateFields);
    let updatedData = this.state.updateFields;
    updatedData.user_id = this.state.userID;
    // console.log(updatedData);
    let coachID = window.sessionStorage.getItem("lcoach_id");
    axios
      .post(
        this.state.api + "update-coach-to-assistant/" + coachID,
        updatedData
      )
      .then((response) => {
        this.setState({
          updatedCoachStatusInProgress: false,
        });
        if (response.data.success === true) {
          this.getListCoach();
          // window.location = "liste-coach";
        } else {
          this.setState({
            updatedError: true,
          });
        }
      });
  };
  statusFormatter(cell, row) {
    return (
      <span>
        {row.coach_is_actif === 1 && (
          <span
            className="label label-success"
            data-toggle="modal"
            data-target="#changeCoachStatusModal"
            onClick={() => this.getCoachID(row)}
          >
            actif
          </span>
        )}
        {row.coach_is_actif === 0 && (
          <span
            className="label label-danger"
            data-toggle="modal"
            data-target="#changeCoachStatusModal"
            onClick={() => this.getCoachID(row)}
          >
            non actif
          </span>
        )}
      </span>
    );
  }
  roleFormatter(cell, row) {
    return (
      <span>
        <span
          className="label label-success"
          data-toggle="modal"
          data-target="#changeRoleModal"
          onClick={() => this.getCoachID(row)}
        >
          {row.status}
        </span>
      </span>
    );
  }
  getCoachInfos = (coach) => {
    this.setState({
      coachID: coach.id,
      coachFullname: coach.coachFullname,
      coachEmail: coach.email,
      coachDomaine: coach.domaine,
      coachDomaineID: coach.coach_domaine_id,
      coachPhone: coach.phone,
      coachStatus: coach.coach_is_actif,
      coachNbreBakelistes: coach.nbreBakeliste,
      coachFirstname: coach.first_name,
      coachLastname: coach.last_name,
      updateCoach: coach,
    });
  };

  actionsFormatter(cell, row) {
    return (
      <span>
        <VisibilityIcon
          title="Voir"
          className="orange-icon"
          onClick={() => {
            this.getCoachInfos(row);
          }}
          id="show_item"
          className="update_item"
          data-toggle="modal"
          data-target="#showInfosModal"
        />
        <EditIcon
          onClick={() => {
            this.getCoachInfos(row);
          }}
          id="update_item"
          data-toggle="modal"
          data-target="#sendMsgModal"
          className="green-icon"
        />
        <ArchiveIcon
          title="Archiver"
          onClick={() => {
            this.archivedCoach(row.id);
          }}
          id="update_item"
          className="red-icon"
          data-toggle="modal"
        />{" "}
      </span>
    );
  }

  nbreBakelistesFormatter(cell, row) {
    return (
      <span>
        {/* <span className="label label-info volkeno-green-bcolor nbakelistes_link" onClick={() => this.getCoachIDForRedirect(row)}> {row.nbreBakeliste} </span> */}
        <button
          onClick={() => this.getCoachIDForRedirect(row)}
          className="btn green-icon nbakelistes_link"
          type="button"
        >
          <span className="badge"> {row.nbreBakeliste} </span>
        </button>
      </span>
    );
  }
  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
    };
    return this.state.loading ? (
      <div>
        <Preloader />
      </div>
    ) : (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </nav>
            <div className="row m-b-15">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600">Coachs</h6>
              </div>
              <div className="col dis-flex m-b-15"></div>
            </div>

            <div className="card-dashboard m-b-15 p-b-60">
              <div className="row m-b-30 m-t-20">
                <div className="col-xl-7 col-lg-6 col-md-7 col-sm-6 col-6 m-b-15 dis-flex itm-center">
                  <h5 className="h-theme ff-inter fw-500">Coachs</h5>
                </div>
                <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
              </div>
              <button className="btn-add-groupe d-flex flex-row-reverse mr-5 tope">
                <Link
                  className="btn-add-with-icon-plus-circle btn btn-theme"
                  to={{
                    pathname: "/ajouter-coach-assistant",
                    state: { coachId: this.state.userId },
                  }}
                >
                  Ajouter un Coach Assistant{" "}
                  <i className="icon-add-groupe ml-2 rs-icon rs-icon-plus-circle"></i>
                </Link>
              </button>
              <BootstrapTable
                data={this.state.coachs}
                striped={true}
                hover={true}
                condensed={true}
                multiColumnSort={2}
                options={options}
                pagination
                search={true}
              >
                <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                  {" "}
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn dataField="coachFullname">
                  Nom complet
                </TableHeaderColumn>
                <TableHeaderColumn dataField="domaine">
                  Domaine
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="nbreBakeliste"
                  dataFormat={this.nbreBakelistesFormatter.bind(this)}
                >
                  Nombre Bakelistes
                </TableHeaderColumn>
                <TableHeaderColumn dataField="email">Email</TableHeaderColumn>
                <TableHeaderColumn
                  dataField="status"
                  dataFormat={this.roleFormatter.bind(this)}
                >
                  Role
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="coach_is_actif"
                  dataFormat={this.statusFormatter.bind(this)}
                  dataSort={true}
                >
                  Status
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="id"
                  dataFormat={this.actionsFormatter.bind(this)}
                >
                  Actions
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
          <div
            className="modal fade"
            id="showInfosModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="showInfosModalLabel"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="showInfosModalLabel">
                    Detail sur {this.state.coachFullname}
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="coachInfos-container">
                    <div className="fullname_container">
                      <span className="item_label">Prénom et Nom : </span>{" "}
                      <span className="label_content">
                        {this.state.coachFullname}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="email_container">
                      <span className="item_label">Email : </span>{" "}
                      <span className="label_content">
                        {this.state.coachEmail}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="phone_container">
                      <span className="item_label">Téléphone : </span>{" "}
                      <span className="label_content">
                        {this.state.coachPhone}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="domaine_container">
                      <span className="item_label">
                        Domaine de compétence :{" "}
                      </span>{" "}
                      <span className="label_content">
                        {this.state.coachDomaine}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="count_container">
                      <span className="item_label nbakelistes">
                        Nombre de bakelistes :{" "}
                      </span>{" "}
                      <br />
                      <span className="label_content cbakelistes">
                        {this.state.coachNbreBakelistes}
                      </span>
                    </div>{" "}
                    <hr />
                  </div>
                  <div className="modal-footer"></div>
                </div>
              </div>
            </div>
          </div>
          {/* Modification du statut du coach */}
          <div
            className="modal fade"
            id="changeCoachStatusModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="changeCoachStatusModalLabel"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="changeCoachStatusModalLabel">
                    Detail sur{" "}
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="update-coach-status-container">
                    <div className="form-group ">
                      <select
                        className="form-control"
                        id="inputStatus_Reporting"
                        name="coach_is_actif"
                        onClick={this.updateCoachStatusHandleChange}
                      >
                        <option>--Choisir un statut--</option>
                        <option value={1}>Activer</option>
                        <option value={0}>Désactivé</option>
                      </select>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default cancel_btn cancel button"
                      data-dismiss="modal"
                    >
                      <i className="fas fa-times-circle"></i> &nbsp; Quitter
                    </button>
                    {!this.state.updatedCoachStatusInProgress && (
                      <button
                        type="button"
                        className="btn btn-primary btn_for_assignation button addBtn"
                        onClick={(e) => this.updateCoachStatus(e)}
                      >
                        <i className="fas fa-user-check"></i>&nbsp; Modifier
                      </button>
                    )}
                    {this.state.updatedCoachStatusInProgress && (
                      <button
                        type="button"
                        className="btn btn-warning button addBtn btn_for_wait"
                      >
                        Modification en cours &nbsp;
                        <i className="fas fa-spinner fa-spin"></i>
                      </button>
                    )}
                    {this.state.updatedError && (
                      <div className="row update-error-container">
                        <div className="col-md-12 error-content alert alert-danger">
                          Oups...Un problème est survenu lors de la
                          modification. Veuillez réessayer plus tard.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Change Role Modal */}
          <div
            className="modal fade"
            id="changeRoleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="changeRoleModalLabel"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="changeRoleModalLabel">
                    Detail sur{" "}
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="update-coach-status-container">
                    <div className="form-group ">
                      <select
                        className="form-control"
                        id="inputStatus_Reporting"
                        name="role"
                        onClick={this.updateCoachRoleHandleChange}
                      >
                        <option>--Choisir un role--</option>
                        <option value="coach">Coach</option>
                        <option value="coach-assistant">Coach Assistant</option>
                      </select>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default cancel_btn cancel button"
                      data-dismiss="modal"
                    >
                      <i className="fas fa-times-circle"></i> &nbsp; Quitter
                    </button>
                    {!this.state.updatedCoachStatusInProgress && (
                      <button
                        type="button"
                        className="btn btn-primary btn_for_assignation button addBtn"
                        onClick={(e) => this.updateCoachRole(e)}
                      >
                        <i className="fas fa-user-check"></i>&nbsp; Modifier
                      </button>
                    )}
                    {this.state.updatedCoachStatusInProgress && (
                      <button
                        type="button"
                        className="btn btn-warning button addBtn btn_for_wait"
                      >
                        Modification en cours &nbsp;
                        <i className="fas fa-spinner fa-spin"></i>
                      </button>
                    )}
                    {this.state.updatedError && (
                      <div className="row update-error-container">
                        <div className="col-md-12 error-content alert alert-danger">
                          Oups...Un problème est survenu lors de la
                          modification. Veuillez réessayer plus tard.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteGroupItemForCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ listeCoach }) => ({ ...listeCoach }),
//     dispatch => bindActionCreators({ ...listeCoachActions }, dispatch)
//   )( listeCoach );
