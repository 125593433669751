import React, { Component } from "react";
import "./itemFilActualite.css";
import AvatarFilActualite from "../../img/icons/fil-actualite.png";
import IconComment from "../../img/icons/comment.png";
import IconSendComment from "../../img/icons/send1.png";
import { InputGroup } from "rsuite";
import base_api_url from "../../variablesGlobales";
import Gallery from "react-grid-gallery";
import API from "../../variablesGlobales";
import Commments from "../commments/index";
import frenchFormatDate from "../frenchFormatDate";
import { Link } from "react-router-dom";
import FbImageLibrary from "react-fb-image-grid";

export default class itemFilActualite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formatDate: frenchFormatDate.frenchFormatDate,
      images: [],
      commentaires: this.props.commentaires,
      api: API.API,
      liv_id: "",
      base_api_url: base_api_url.base_api_url,
      user_id: window.sessionStorage.getItem("user_id"),
      user_type: window.sessionStorage.getItem("user_status"),
      showComments: false,
      voirCommentairesC: false,
      newComment: {
        comment_text: "",
        isOk: "",
        isnOk: "",
        commentable_id: 0,
        user_type: "",
        livrable_id: 0,
        bakeliste_user_id: 0,
      },
    };
  }

  onRenderImagesLivrables = () => {
    // var base_api_url =
    // console.log("livraisons", this.props.livraisons);
    this.props.test.map((item, indexx) => {
      var image = {
        src: this.state.base_api_url + item.file_path,
        thumbnail: this.state.base_api_url + item.file_path,
        thumbnailHeight: 320,
        // caption: item.commentaire,
      };
      this.state.images.push(image);
    });
    return <Gallery images={this.state.images} />;
  };

  addComment = (id, idl) => {
    this.setState({
      bakeliste_id: id,
      livrable_id: idl,
    });
  };

  onhandleChange = (e) => {
    e.preventDefault();

    let { newComment } = this.state;
    newComment.comment_text = e.target.value;

    this.props.fichiers.map((item, indexx) => {
      if (indexx === 0) {
        this.setState({ liv_id: item.livraisons_id });
        newComment.livrable_id = item.livraisons_id;
      }
    });
    newComment.user_id = this.state.user_id;
    newComment.user_type = this.state.user_type;
    newComment.bakeliste_user_id = this.props.bakeliste_id;
    newComment.tache_content = this.props.tache_content;
    this.setState({ newComment });
  };

  addReactiononLivrable = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSubmitComment(this.state.newComment, this.props.item);
    e.target.reset();
  };

  voirCommentsC = (e) => {
    this.setState({
      voirCommentairesC: true,
    });
  };
  cacherCommentsC = (e) => {
    this.setState({
      voirCommentairesC: false,
    });
  };
  render() {
    const files = [];

    this.props.fichiers.map((item) => {
      files.push(this.state.base_api_url + item.file_path);
    });

    return (
      <div className="card-fil-actualite">
        <div className="row mb-15">
          <div className="col-sm-9 dis-flex itm-center mb-sm-0 mb-2">
            <img
              className="avatar-fil-actualite"
              src={AvatarFilActualite}
              alt=""
            />
            <p className="name-fil-actualite">{this.props.fullname}</p>
            <p className="heure-fil-actualite">
              {this.state.formatDate(this.props.date)}
            </p>
          </div>
          <div className="col-sm-3 dis-flex itm-center">
            <p className="h-theme ff-work fs-12 fw-500 ml-sm-auto ml-5">
              {/* Tâches N°{this.props.index} */}
            </p>
          </div>
        </div>

        <div className="px-md-5 py-md-4 py-3 px-3">
          <Link className="lien-utile-bakeliste-tache-card" to="/coach-tâches">
            <p className="h-theme ff-work fs-14 fw-500 ml-sm-auto ml-5 mb-3">
              Tache: {this.props.tache_content}
            </p>
          </Link>
          <div>
            <div>
              <FbImageLibrary images={files} />
              {/* <Carousel>{renderCarouselSlides()}</Carousel> */}
            </div>
            {this.props.commentaires.length === 0 ? (
              <p className="pComment">
                Pas de commentaires!!! Soyez le premier a commenter.
              </p>
            ) : (
              <Commments comments={this.props.commentaires} />
            )}
          </div>
        </div>

        <form onSubmit={this.addReactiononLivrable}>
          <div className="row m-t-25 m-b-5">
            <div className="px-lg-2 col-sm-2 col-3 px-md-3 px-sm-2 dis-flex itm-center justify-content-center"></div>
            <div className="col-sm-10 col-9 pl-0 pr-2 pr-sm-2 pr-md-3">
              <InputGroup inside>
                <InputGroup.Addon>
                  <img
                    className="icon-comment-fil-actualite"
                    src={IconComment}
                    alt=""
                  />
                </InputGroup.Addon>
                <input
                  type="text"
                  placeholder="Ajouter un commentaire"
                  className="input-comment-fil-actualite"
                  name="comment"
                  // value={this.state.newComment.commentaire}
                  onChange={this.onhandleChange}
                />
                <button
                  className="btn-send-comment-fil-actualite"
                  type="submit"
                >
                  <img
                    className="icon-btn-send-comment-fil-actualite"
                    src={IconSendComment}
                    alt=""
                  />
                </button>
              </InputGroup>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
// export default connect(
//     ({ itemFilActualite }) => ({ ...itemFilActualite }),
//     dispatch => bindActionCreators({ ...itemFilActualiteActions }, dispatch)
//   )( itemFilActualite );
