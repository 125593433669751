import API2 from "../../../../variablesGlobales";
import API from "../../../../variablesGlobales";
import axios from "axios";

export const FETCH_DETAILS_CV_BAKELISTE = "FETCH_DETAILS_CV_BAKELISTE";

export const getBakelisteCV = (bakelisteId) => {
  return async (dispatch) => {
    return await axios
      .get(API.API + "cvtheques/" + bakelisteId)
      .then((res) => {
        dispatch({
          type: FETCH_DETAILS_CV_BAKELISTE,
          payload: res.data,
        });
        // console.log("MY CV DATA", res.data);
      })
      .catch((err) => console.log(err));
  };
};

export const updateReduxEducation = ({ id, fd }) => {
  return (dispatch) => {
    console.log("My form data", fd);
    return axios
      .put(API.API + "cveducations/" + id, fd)
      .then((res) => {
        dispatch(getBakelisteCV());
        // console.log("New States", states);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
