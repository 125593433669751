import React, { useState } from "react";
import "./livraisonsItemv2.css";
import AvatarFilActualite from "../../../img/icons/fil-actualite.png";
import IconComment from "../../../img/icons/comment.png";
import IconSendComment from "../../../img/icons/send1.png";
import { Input, InputGroup } from "rsuite";
import FeatherIcon from "feather-icons-react";
import base_api_url2 from "../../../variablesGlobales";
import frenchFormatDate from "../../frenchFormatDate";
import API2 from "../../../variablesGlobales";
import FbImageLibrary from "react-fb-image-grid";
import Commentsv2 from "../commentsv2/commentsv2";
import SkeletonFilDactualite from '../../itemFilActualite'
const LivraisonsItemv2 = (props)=> {
  const [commentaires, setCommentaires] = useState(props.commentaires);
  const api = API2.API2;
 
  const user_id = window.sessionStorage.getItem("user_id");
  const user_type = window.sessionStorage.getItem("user_status");

  const [images,setImages] = useState([])
  const [showComments, setShowComments] = useState(false)
    // this.state = {
    //   images: [],
    //   commentaires: this.props.commentaires,
    //   api: API.API,
    //   liv_id: "",
    //   base_api_url: base_api_url.base_api_url,
    //   user_id: window.sessionStorage.getItem("user_id"),
    //   user_type: window.sessionStorage.getItem("user_status"),
    //   showComments: false,
    //   newComment: {
    //     comment_text: "",
    //     isOk: "",
    //     isnOk: "",
    //     commentable_id: 0,
    //     user_type: "",
    //     livrable_id: 0,
    //   },
    // };
  
    const [newComment, setNewComment]=useState({
      comment_text: "",
      bakeliste_id: 0,
      commentable_id: 0,
      coach_id: 0,
      user_type: "",
      parent_id: "",
      livrable_id: 0,
      voirCommentairesC: false
})

  

  // function loadComments () {
  //   setShowComments({showComments: !this.state.showComments})
  //   // this.setState({ showComments: !this.state.showComments });
  // };


  // addComment = (id, idl) => {
  //   this.setState({
  //     bakeliste_id: id,
  //     livrable_id: idl,
  //   });
  // };

  const onhandleChange = (e) => {
    e.preventDefault();
    setNewComment({
      comment_text:e.target.value,
      livrable_id:props.livraison_id,
      user_id :user_id,
      user_type:user_type
    })
  };

  const addReactiononLivrable = (e) => {
    e.preventDefault();
    e.stopPropagation();
     props.onSubmitComment(newComment, props.item);
     setNewComment({
       comment_text:''
     })
    // const form = document.getElementById('comment_form');
    // form.reset();
  };

     
      const files = [];
   props.fichiers.map((item) => {
      files.push(base_api_url2.base_api_url2 + item.file_path);
    });


    return (
      <div className="card-fil-actualite">
      <div className="row mb-15">
        <div className="col-sm-9 dis-flex itm-center mb-sm-0 mb-2">
          <img
            className="avatar-fil-actualite"
            src={AvatarFilActualite}
            alt=""
          />
          <p className="name-fil-actualite">{props.fullname}</p>
          <p className="heure-fil-actualite">
            {frenchFormatDate.frenchFormatDate (props.date)}
          </p>
        </div>
        <div className="col-sm-3 dis-flex itm-center">
          <p className="h-theme ff-work fs-12 fw-500 ml-sm-auto ml-5">
            {/* Tâches N°{this.props.index} */}
          </p>
        </div>
      </div>

      <div className="px-md-5 py-md-4 py-3 px-3">
        {/* <Link className="lien-utile-bakeliste-tache-card" to="/coach-tâches"> */}
          <p className="h-theme ff-work fs-14 fw-500 ml-sm-auto ml-5 mb-3">
             {props.tache_content}
          </p>
        {/* </Link> */}
        <div>
          <div>
            <FbImageLibrary images={files} />
          </div>
          {props.comments.length === 0 ? (
            <p className="pComment mt-3">
              Pas de commentaires!!! Soyez le premier a commenter.
            </p>
          ) : (
            <Commentsv2 comments={props.comments} />
          )}
        </div>
      </div>

      <form onSubmit={addReactiononLivrable} id='comment_form'>
        <div className="row m-t-25 m-b-5">
          <div className="px-lg-2 col-sm-2 col-3 px-md-3 px-sm-2 dis-flex itm-center justify-content-center"></div>
          <div className="col-sm-10 col-9 pl-0 pr-2 pr-sm-2 pr-md-3">
            <InputGroup inside>
              <InputGroup.Addon>
                <img
                  className="icon-comment-fil-actualite"
                  src={IconComment}
                  alt=""
                />
              </InputGroup.Addon>
              <input
                type="text"
                placeholder="Ajouter un commentaire"
                className="input-comment-fil-actualite"
                name="comment"
                 value={newComment.comment_text}
                onChange={onhandleChange}
              />
              <button
                className="btn-send-comment-fil-actualite"
                type="submit"
              >
                <img
                  className="icon-btn-send-comment-fil-actualite"
                  src={IconSendComment}
                  alt=""
                />
              </button>
            </InputGroup>
          </div>
        </div>
      </form>
    </div>
    );
  }

  export default LivraisonsItemv2
// export default connect(
//     ({ itemFilActualite }) => ({ ...itemFilActualite }),
//     dispatch => bindActionCreators({ ...itemFilActualiteActions }, dispatch)
//   )( itemFilActualite );
