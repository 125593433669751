import React, { Component } from "react";
import "./groupeBakeliste.css";
import { NavLink } from "react-router-dom";
import SavedReunionListItem from "../savedReunionListItem/index";
import IconGroupWhatsapp from "../../img/icons/group-whatsapp.png";
import TableGroupeBakeliste from "../tableGroupeBakeliste/index";
import ModalContactUs from "../modalContactUs/index";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItem from "../bakelisteGroupItem/index";
import NextReunionItem from "../nextReunionItem/index";
import axios from "axios";
import { Link } from "react-router-dom";
import amplitude from "amplitude-js";
import ListCoachAssistantRefact from "../listCoachAssistant/ListCoachAssistantRefact";
import * as Sentry from "@sentry/browser";
import API from "../../variablesGlobales";
import EnvoyerMonTravail from "../envoyerMonTravail/index";

export default class groupeBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupe_bakeliste: [],
      membres_groupe: [],
      lien_whatsapp: "",
      bakeliste_id: sessionStorage.getItem("user_id"),
      groupe_id: sessionStorage.getItem("sous_groupe_id"),
      eventeDroite: "ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_V2_DROITE",

      api: API.API,
    };
    Sentry.init({
      dsn: "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
    });
  }

  componentDidMount() {
    var eventProperties = {
      groupe_id: this.state.groupe_id,
      user_id: this.state.bakeliste_id,
    };
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude
      .getInstance()
      .logEvent("VISITED_PAGE_GROUPE_BAKELISTE_V2", eventProperties);

    this.onGetBakelisteGroupe();
  }
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };
  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  onGetBakelisteGroupe = () => {
    axios
      .get(this.state.api + "sous__groupes/" + this.state.groupe_id)
      .then((response) => {
        if (response.data.data !== undefined) {
          this.setState({
            groupe_bakeliste: response.data.data,
            membres_groupe: response.data.liste[0],
            lien_whatsapp: response.data.lien_whatsapp,
          });
        }
        // console.log(response)
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  render() {
    return (
      // console.log(groupeDatas, "groupeData"),

      <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb  fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item ">
                  <Link to="/dashboard-bakeliste">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Groupe
                </li>
              </ol>
            </nav>
            <div className="row m-b-15 pt3">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600">Groupe</h6>
              </div>
              <div className="col dis-flex m-b-15">
                <ModalContactUs />
              </div>
            </div>

            <div className="card-dashboard m-b-15 p-b-60">
              <div className="row m-b-30">
                <div className="col-xl-7 col-lg-6 col-md-7 col-sm-6 col-6 m-b-15 dis-flex itm-center">
                  <h5 className="h-theme ff-inter fw-500">
                    Groupe {this.state.groupe_bakeliste.name}
                  </h5>
                </div>
                <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15">
                  {/* <a
                    className="link-groupe-whatsapp no-view-mobile"
                    to="this.state.lien_whatsapp"
                    aria-disabled
                    target="blank"
                  >
                    <img
                      className="icon-link-groupe-whatsapp"
                      src={IconGroupWhatsapp}
                      alt="Groupe whatsapp"
                    />
                    Groupe de discussion
                  </a>  */}

                  {/* <NavLink
                    className="link-groupe-whatsapp-mobile no-display-tablette ml-auto"
                    to=""
                  >
                    <img
                      className="icon-link-groupe-whatsapp"
                      src={IconGroupWhatsapp}
                      alt="Groupe whatsapp"
                    />
                  </NavLink> */}
                </div>
              </div>

              <TableGroupeBakeliste
                listeBakeliste={this.state.membres_groupe}
              />
            </div>
          </div>

          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <CoachItemDashboardBakeliste />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <BakelisteGroupItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <ListCoachAssistantRefact />
              </div>
              {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                <NextReunionItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <SavedReunionListItem />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ groupeBakeliste }) => ({ ...groupeBakeliste }),
//     dispatch => bindActionCreators({ ...groupeBakelisteActions }, dispatch)
//   )( groupeBakeliste );
