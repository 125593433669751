import React, {Component} from 'react';
import './skeletonLivraisonv2.css'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as skeletonLivraisonv2Actions from "../../store/skeletonLivraisonv2/actions";
const SkeletonLivraisonv2 =()=>{
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
      return (
        <div className="card-fil-actualite ">
        <div className="row">
          <div className="col-md-2 ">
            <p className="cardPic"></p>
          </div>
          <div className="col-md-7 ">
            <p className="nameS"></p>
            <p className="dateS"></p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <p className="num"></p>
          </div>
          <div className="col-md-8">
            <p className="tache"></p>
          </div>
        </div>

        <div>
          <p className="fb-grid"></p>
        </div>
        <div>
          <p className="commentaires"></p>
        </div>
        <div className="row">
          <div className="col-md-2 ">
            <p className="cardPic"></p>
          </div>
          <div className="col-md-7 ">
            <p className="nameS"></p>
            <p className="dateS"></p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <p className="num"></p>
          </div>
          <div className="col-md-8">
            <p className="tache"></p>
          </div>
        </div>

        <div>
          <p className="fb-grid"></p>
        </div>
        <div>
          <p className="commentaires"></p>
        </div>
      </div>
      )
     }
  export default SkeletonLivraisonv2
// export default connect(
//     ({ skeletonLivraisonv2 }) => ({ ...skeletonLivraisonv2 }),
//     dispatch => bindActionCreators({ ...skeletonLivraisonv2Actions }, dispatch)
//   )( skeletonLivraisonv2 );