import React, { Component } from "react";
import "./dashboardCoach.css";
import StatisticsCoach from "../statisticsCoach/index";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import TableMyBakelistes from "../tableMyBakelistes/index";
import axios from "axios";
import Loader from "react-loader-spinner";
import $ from "jquery";
import Preloader from "../preloader/index";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import LivraisonCoachAssistant from "../livraisonCoachAssistant/livraisonCoachAssistant";
import GrapheLivraisons from "../grapheLivraisons/grapheLivraisons";
import GrapheCommentaires from "../grapheCommentaires/grapheCommentaires";
import GrapheContenu from "../grapheContenu/grapheContenu";

// const Loader = () => <div></div>;
export default class dashboardCoach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      sous_groupes: [],
      api: API.API,
      userID: window.sessionStorage.getItem("user_id"),
      token: window.sessionStorage.getItem("token"),
      loading: true,
      isOpen: false,
    };
  }
  componentDidMount = () => {
    this.__getSousGroupes();
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 5000);
  };
  toggle() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }
  // hideLoader = () => {
  //   this.setState({ loading: false });
  // };

  // showLoader = () => {
  //   this.setState({ loading: true });
  // };

  __getSousGroupes = () => {
    // const _this = this;
    // this.showLoader();
    axios
      .get(this.state.api + "sous__groupes/coach/" + this.state.userID)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            sous_groupes: res.data.data.reverse(),
          });
        }
        this.state.sous_groupes.slice(0, 5).map((i) => {
          let items = {
            id: i.id,
            value: i.name,
            groupe_id: i.groupe_id,
          };
          this.state.list.push(items);
        });
        // _this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        // _this.hideLoader();
      });
  };
  render() {
    return this.state.loading ? (
      <div>
        <Preloader />
      </div>
    ) : (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </nav>
            <div className="row m-b-15">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600">Chiffres</h6>
              </div>
              <div className="col dis-flex m-b-15"></div>
            </div>
            <StatisticsCoach />

            <div class="box" onClick={(e) => this.toggle(e)}>
              <button class="button-graphe ">
                <span>
                  {this.state.isOpen
                    ? "Cacher Statistique"
                    : "Afficher Statistique"}
                </span>
              </button>
            </div>
            {this.state.isOpen && (
              <>
                <GrapheContenu /> <GrapheLivraisons /> <GrapheCommentaires />
              </>
            )}
            <div className=" m-b-15 p-b-60">
              <div className="col-md-6">
                <h6 className="h-theme ff-inter fw-600 mb-3">
                  Livraisons des Bakelistes
                </h6>
              </div>
              {/* <div className="card-dashboard m-b-15 p-b-60">
              <div className="row m-b-30 m-t-20">
                <div className="col-xl-7 col-lg-6 col-md-7 col-sm-6 col-6 m-b-15 dis-flex itm-center">
                  <h5 className="h-theme ff-inter fw-500">Mes bakelistes</h5>
                </div>
                <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
              </div>
              {this.state.loading ? (
                <div className="col-md-3 offset-md-5">
                  <Loader
                    type="ThreeDots"
                    color="#009688"
                    height={700}
                    width={150}
                    timeout={4000}
                  />{" "}
                </div>
              ) : (
                <> 
                  <TableMyBakelistes />
              )}*/}
              <LivraisonCoachAssistant />
            </div>
          </div>

          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-6 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteGroupItemForCoach />
              </div>
              {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ dashboardCoach }) => ({ ...dashboardCoach }),
//     dispatch => bindActionCreators({ ...dashboardCoachActions }, dispatch)
//   )( dashboardCoach );
