import React, { Component } from "react";
//import MultiSelect from "@khanacademy/react-multi-select";
import Select from "react-select";
import "./shareToCoachAssistant.css";
import { Modal, ButtonToolbar, Button } from "rsuite";
import axios from "axios";
import $ from "jquery";
import API from "../../variablesGlobales"; 
import * as Sentry from "@sentry/browser";
import FeatherIcon from "feather-icons-react";
import SweetAlert from "react-bootstrap-sweetalert";
import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";
import { toast } from "react-toastify";
toast.configure();

const options = ["A", "B", "C", "D"];

export default class shareToCoachAssistant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlertSuccess: false,
      showAlertError: false,
      checkAll: false,
      api: API.API,
      show: false,
      value: [],
      fields: {},
      selectOptions: [],
      sous_groupes: [],
      bakelistesByCoach: [],
      isAssignSyllabusInProgress: false,
      assignationEncours: false,
      assignationSuccessAlert: false,
      assignationErrorAlert: false,
      userID: window.sessionStorage.getItem("user_id"),
      selected: [],
      syllabusID: this.props.id,
      syllabusDatas: [],
      isSuccessAssign: false,
      isErrorAssign: false,
      selectedOption: null,
      showHide: false,
      isPageLoading: true,
      domaines: [],
      technologies: [],
      tools: [],
      errors: {},
      sName: "",
      sDescription: "",
      sDomaine: 0,
      sLevel: "",
      sTechnologie: [],
      sTool: [],
      sname: false,
      sdescription: false,
      stechnotool_empty: false,
      screatedby_empty: false,
      slevel_empty: false,
      sdomaineid_empty: false,
      addSyllabusInProgress: false,
      addSyllabusSuccessAlert: false,
      addSyllabusErrorAlert: false,
      remaining_char: 245,
      selectOptions1: [],
      selectOptions2: [],
      selectTechnologies: [],
      selectTools: [],
      bakelisteID: 0,
      selectedtools: [],
      onlySelect: false,
      groupySelect: false,
      bakeliste: [],
      noSavedData: [],
      utInProgress: false,
      syllabusByCoache: [],
      updateSyllabusRequestError: false,
      updateSyllabusRequestSuccessAlert: false,
      bakelisteBySyllabus: [],
      CID:this.props.id_coach,
    };
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.assignSyllabus = this.assignSyllabus.bind(this);

    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
   // console.log("eezzzzzz",this.state.CID);
  }

  handleCheckAll(value, checked) {
    const nextValue = checked ? options : [];
    this.setState({
      value: nextValue,
      indeterminate: false,
      checkAll: checked,
    });
  }

  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }

  componentDidMount = () => {
    this.getBakelisteByCoach();
    this.__getSousGroupes();
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
  };

  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };
  assignSyllabus = (e) => {
    e.preventDefault();
    this.setState({
      assignationEncours: true,
    });
    let data = this.state.fields;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "JWT fefege...",
    };
    data["syllabus_id"] = this.state.syllabusID;
    data["is_groupy"] = 1;
    data["is_only"] = 0;
    data["selected_groupes"] = this.state.selected;
    var eventProperties = {
      coach_id: this.state.userID,
      groupe_id: this.state.selected,
      syllabus_id: this.state.syllabusID,
    };
    axios
      .post(this.state.api + "assignation", data, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.success) {
          $("#date_syllabus").val("");
          this.props.syllabusDetails(this.state.syllabusID);
          this.setState({
            assignationEncours: false,
            syllabusDatas: response.data.data,
            fields: {},
          });
          toast.success(
            "Assignation effectué avec success",
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 1000 }
          );
          $(".close-modal-bakeliste-title").click();
         
          amplitude
            .getInstance()
            .logEvent("ASSIGNATION_SYLLABUS_COACH_SUCCESS_V2", eventProperties);
        } else {
          // this.setState({
          //   showAlertError: true,
          // });
          // setTimeout(() => {
          //   this.setState({
          //     isErrorAssign: false,
          //   });
          // }, 1000);
          toast.error(
            "Erreur sur l'assignation de syllabus",
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 1000 }
          );
        }
        $(".close-modal-bakeliste-title").click();
      })
      .catch((error) => {
        if ((error.message = "Request failed with status code 422")) {
        }
        amplitude
          .getInstance()
          .logEvent("ASSIGNATION_SYLLABUS_COACH_ERROR_V2", eventProperties);
        this.setState({
          assignationEncours: false,
        });
      
        toast.error(
          "Erreur sur l'assignation de syllabus",
          { position: toast.POSITION.TOP_CENTER },
          { autoClose: 1000 }
        );
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  __getSousGroupes = () => {
    axios
      .get(this.state.api + "sous__groupes")
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            sous_groupes: res.data.data.reverse(),
          });
        }
        this.state.sous_groupes.forEach((item) => {
          let infos = {
            label: item.name,
            value: item.id,
          };
          this.state.selectOptions.push(infos);
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  handleChange(e) {
    e.preventDefault();
    var fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }

  onOnlyAssignation = () => {
    this.setState({
      onlySelect: true,
      groupySelect: false,
    });
  };

  handleMultiChange = (selectedOption) => {
    let idinc = [];
    selectedOption.map((inc, id) => {
      idinc.push(inc.value);
    });
    this.setState({ selected: idinc });
  };

  /*
   * Activate groupy select assignation
  */
  onGroupyAssignation = () => {
    this.setState({
      onlySelect: false,
      groupySelect: true,
    });
  };

  getBakelisteByCoach() {
    axios
      .get(this.state.api + "bakelistes-by-coach/" + this.state.CID)
      .then((response) => {
        var data = response.data.data;
        if (data !== undefined) {
          this.setState({
            bakelistesByCoach: data,
          });
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }

  render() {
    return (
      <Amplitude>
        <div className="component-share-to">
          <div className="modal-container">
            <ButtonToolbar>
              <Button className="btn-share-to" onClick={this.open}>
                <FeatherIcon
                  className="icon-details-container-syllabus-item-back"
                  icon="share-2"
                />
                Assigner
              </Button>
            </ButtonToolbar>

            {/* Alert syllabus assigné avec succes */}
            <SweetAlert
              show={this.state.showAlertSuccess}
              onConfirm={() => this.setState({ showAlertSuccess: false })}
              success
              title="Syllabus assigné avec succes!!!"
              style={{
                color: "#069A77",
                fontSize: "12px",
                fontFamily: "Work Sans, sans-serif",
                paddingTop: 60,
                paddingBottom: 40,
              }}
              confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
            />
            {/* ENd ALert Succes */}

            {/* Alert erreur sur assignation */}
            <SweetAlert
              show={this.state.showAlertError}
              onConfirm={() => this.setState({ showAlertError: false })}
              error
              title="Erreur sur l'assignation syllabus, veuillez reessayer!!!"
              style={{
                color: "#d9534f",
                fontSize: "12px",
                fontFamily: "Work Sans, sans-serif",
                paddingTop: 60,
                paddingBottom: 40,
              }}
              confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
            />
            <Modal
              className="modal-responsive-sm"
              show={this.state.show}
              onHide={this.close}
            >
              <h5 className="modal-bakeliste-title" id="modalAddTacheTitle">
                Choisir Sous-Groupe
              </h5>
              <button
                type="button"
                className="close-modal-bakeliste-title"
                onClick={this.close}
              >
                <FeatherIcon icon="x" aria-hidden="true" />
              </button>
              {/* Assign syllabus to bakeliste */}
              <form
                id="assign_syllabus_form"
                onSubmit={(e) => this.assignSyllabus(e)}
              >
                <div className="modal-body">
                  <div className="col-md-12 form-group assignation-type-choice">
                  </div>
                  <div className="form-group col-md-12">
                    <Select
                      isMulti
                      name="groupe_ids"
                      options={this.state.selectOptions}
                      className="basic-multi-select map-color"
                      onChange={this.handleMultiChange}
                      classNamePrefix="select"
                    />
                  </div>
                  {this.state.addSyllabusErrorAlert && (
                    <div className="row assignation-error">
                      <div className="col-md-12 error-message-alert">
                        Un problème est survenu lors de l'ajout. Veuillez
                        réessayer plus tard.
                      </div>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  {this.state.assignationEncours ? (
                    <button
                      type="submit"
                      className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                      disabled
                    >
                      Assignation en cours &nbsp;
                      <FeatherIcon icon="loader" aria-hidden="true" />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                    >
                      ASSIGNER
                    </button>
                  )}
                </div>
              </form>
            </Modal>
          </div>
        </div>
      </Amplitude>
    );
  }
}
// export default connect(
//     ({ shareTo }) => ({ ...shareTo }),
//     dispatch => bindActionCreators({ ...shareToActions }, dispatch)
//   )( shareTo );
