import React, {Component} from 'react';
import './syllabusDetailsBakelisteView.css';
import HeaderDashboard from '../headerDashboard/index';
import NavVertical from '../navVertical/index';
import SyllabusDetailsBakeliste from '../syllabusDetailsBakeliste/index';
import Preloader from "../preloader/index";
import amplitude from "amplitude-js";

//import axios from 'axios';

import API from '../../variablesGlobales';
import Loader  from 'react-loader-spinner';


export default class syllabusDetailsBakelisteView extends Component {
  constructor(props) {
      super(props);
      this.state = {
        syllabus_id:this.props.location.state.id,  
        api: API.API,
        userID: window.sessionStorage.getItem('user_id'),
        syllabusDatas: [],
       taches:[],
        isPageLoading: false,
        loading: true,

      // email: window.sessionStorage.getItem("email"),
      user_id: window.sessionStorage.getItem("user_id")
       };
  }
  
  componentDidMount() {
    var eventProperties = {
      syllabus_name: this.state.syllabusDatas.syllabus_name,
      user_id: this.state.user_id,
    };
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("VISITED_PAGE_DÉTAILS_PROGRAMMES_BAKELISTE_V2", eventProperties);
    setTimeout(() => {
     this.setState({ 
       loading: false 
     });
   }, 6000);
 }

 amplitudeScript = (api_key) => {
  (function (e, t) {
    var n = e.amplitude || { _q: [], _iq: {} };
    var r = t.createElement("script");
    r.type = "text/javascript";
    r.integrity =
      "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
    r.crossOrigin = "anonymous";
    r.async = true;
    r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
    r.onload = function () {
      if (!e.amplitude.runQueuedFunctions) {
      }
    };
    var i = t.getElementsByTagName("script")[0];
    i.parentNode.insertBefore(r, i);
    function s(e, t) {
      e.prototype[t] = function () {
        this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
        return this;
      };
    }
    var o = function () {
      this._q = [];
      return this;
    };
    var a = [
      "add",
      "append",
      "clearAll",
      "prepend",
      "set",
      "setOnce",
      "unset",
    ];
    for (var u = 0; u < a.length; u++) {
      s(o, a[u]);
    }
    n.Identify = o;
    var c = function () {
      this._q = [];
      return this;
    };
    var l = [
      "setProductId",
      "setQuantity",
      "setPrice",
      "setRevenueType",
      "setEventProperties",
    ];
    for (var p = 0; p < l.length; p++) {
      s(c, l[p]);
    }
    n.Revenue = c;
    var d = [
      "init",
      "logEvent",
      "logRevenue",
      "setUserId",
      "setUserProperties",
      "setOptOut",
      "setVersionName",
      "setDomain",
      "setDeviceId",
      "enableTracking",
      "setGlobalUserProperties",
      "identify",
      "clearUserProperties",
      "setGroup",
      "logRevenueV2",
      "regenerateDeviceId",
      "groupIdentify",
      "onInit",
      "logEventWithTimestamp",
      "logEventWithGroups",
      "setSessionId",
      "resetSessionId",
    ];
    function v(e) {
      function t(t) {
        e[t] = function () {
          e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
        };
      }
      for (var n = 0; n < d.length; n++) {
        t(d[n]);
      }
    }
    v(n);
    n.getInstance = function (e) {
      e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
      if (!n._iq.hasOwnProperty(e)) {
        n._iq[e] = { _q: [] };
        v(n._iq[e]);
      }
      return n._iq[e];
    };
    e.amplitude = n;
  })(window, document);
  amplitude.getInstance().init(api_key);
};
render() {
    return  <div className="body-theme">
              <HeaderDashboard />
              <div className="container-fluid fixing-after-header">
                <div className="row">
                  <div className="col-lg-2 p-0">
                    <NavVertical/> 
                  </div>
                  <div className="col-lg-10 pt-3">
                    <SyllabusDetailsBakeliste 
                    id={this.state.syllabus_id}
                    />
                  </div> 
                </div>
              </div> 
            </div>;
  }
}
