import React, { useEffect,useState} from 'react';
import './addLivraisonsv2.css'
import FeatherIcon from "feather-icons-react";
import { Modal } from "rsuite";
// import amplitudeScript from "../../amplitudeScript";
import * as Sentry from "@sentry/browser";
import $ from "jquery";
import API from "../../../variablesGlobales";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import amplitude from "amplitude-js";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import 'react-dropzone-uploader/dist/styles.css'
import { fetchTaches } from '../redux/actions/tacheBakelistesActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { addLivraisons, addLivraisonsFailure, addLivraisonsSuccess } from '../redux/actions/livraisonsActions';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import { fetchLivraisons } from '../redux/actions/livraisonsActions';
import AmplitudeScript from '../../../amplitudeScript';
import axios from 'axios';
import API2 from "../../../variablesGlobales";

toast.configure();
  const bakeliste_id = sessionStorage.getItem("user_id");
  const email = sessionStorage.getItem("email");
const AddLivraisonsv2=(props)=>{
  const [livraison_files, setLivraisonFiles] = useState([]);

  const [addlivraison, setState] = useState({
    api: API.API,
     bakeliste_id: sessionStorage.getItem("user_id"),
    // bakeliste_id:695,
    listetaches: [],
    errors: {},
    details_syllabus_id:'',
    comment:'',
    selectedFile: null,
    isValidForm: false,
    is_empty_tache_file: false,
    isSendingLivrableInProgress: false,
    showAlertSuccess: false,
    showAlertError: false,
    tasksyllabusencours: [],
    table: [],

    });
    const [loading, setLoading]=useState(false)

    // Sentry.init({
    //   dsn:
    //     "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
    // });

    const dispatch = useDispatch();
   
    useEffect(() => { fetchTaches(dispatch); }, [dispatch])
    const taches = useSelector((state) => state.tachesBakeliste.items);
    // const add_livraison= useSelector((state) => state.addLivraison)
    const add_livraison_loading= useSelector((state) => state.addLivraison.loading)


    AmplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    // amplitude.getInstance().logEvent("bakelisteaddLivrableButtonClick_v2_Redux")

    const open = (size) => {
 setState({
        size,
        show: true,
      });
    };

    const close = (size) => {
      setState({
             show: false,
           });
         };
     

  const handleChange = (e) => {
     setState(prevState => {
      return { ...prevState, [e.target.name] : e.target.value ,bakeliste_id:sessionStorage.getItem('user_id')}
    });
  };

  const fileValidation = (elementID) => {
    var fileInput = document.getElementById(elementID);

    var filePath = fileInput.value;

    // Allowing file type
    var allowedExtensions = /(\.png|\.jpg|\.jpeg|)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  };


  const handlefileChange = (e) => {
    // let fields =addlivraison.fields;
    if (e.target.name === "tache_file") {
      if (fileValidation("tache_file")) {
        setState({tache_file : e.target.files[0]});
      } else {
        setState({
          isInvalidFileExtension: true,
        });
        setTimeout(() => {
          setState({
            isInvalidFileExtension: false,
          });
        }, 6000);
      }
    } else {
      e.target.name = e.target.value;
    }

   
  };


  const handleChangeStatus = ({ meta,file }, status) => {
    if (status === "done") {
      // setState({addlivraison.livraison_files => [...addlivraison.livraison_files, file]);
        setLivraisonFiles(livraison_files => {
          return [ ...livraison_files, file ]
        });

    }
  }





let options=[]

  const listeTaches = 
  taches.length > 0
  && taches.map((item, i) => {
    item.map((it,ii)=>{
      return (
        options.push(it)
      )
    })
}, this);




const onSendLivrableFiles = (e) => {
  e.preventDefault();
  setLoading(true)
 
    // const id = this.state.bakeliste_id;
    // const email = this.state.email;
    // var livraisons_files = livraison_files;
    var eventProperties = {
      user_id: bakeliste_id,
      email: email,
      details_syllabus_id: addlivraison.details_syllabus_id,
    };
    if (navigator.onLine) {
        livraison_files.map((file)=>{
        var fd = new FormData();
        fd.append("commentaire", addlivraison.comment);
        fd.append("bakeliste_user_id", bakeliste_id);
        fd.append("details_syllabus_id", addlivraison.details_syllabus_id);
        fd.append("file_path", file)
        // dispatch(addLivraisons(fd))
        axios
        .post(API2.API2 + "livraisons", fd, {
          Headers: { "Content-Type": "multipart/formdata" },
        })
        .then((response) => {
          dispatch(addLivraisonsSuccess(response))
          setLoading(false)
          setLivraisonFiles([])
          setState({
            show: false,
          });
          if(loading===false){
          toast('SUCCES!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            })
          }
          dispatch(fetchLivraisons());
          amplitude
          .getInstance()
          .logEvent(
            "ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_V2_Redux",
            eventProperties
          );

          }).catch((error) =>{
            setLoading(false)
            setLivraisonFiles([])
            setState({
              show: false,
            });
            dispatch(addLivraisonsFailure(error))
            if(loading===false){
            toast.error('Erreur!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              })
            }
            amplitude
            .getInstance()
            .logEvent(
              "ENVOIE_LIVRABLE_BAKELISTE_ERROR_V2_Redux",
              eventProperties
            );
          }
          );

       })
      
    } 
    else {
      this.setState({ userNotConnected: true });
      amplitude
        .getInstance()
        .logEvent("ENVOIE_LIVRABLE_ERROR_CONNEXION_INTERNET_v2", eventProperties);
    }
  
};

const getFilesFromEvent = e => {
  return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then(chosenFiles => {
          resolve(chosenFiles.map(f => f.fileObject))
      })
  })
}


const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
  const text = files.length > 0 ? 'Ajouter plus...' : 'Ajouter des images'

  const buttonStyle = {
      backgroundColor: 'rgb(231 157 49)',
      color: '#fff',
      cursor: 'pointer',
      padding: 15,
      borderRadius: 30,
      marginTop: '2em'
      
  }
  return (
    <label style={buttonStyle}>
        {text}
        <input
            style={{ display: 'none' }}
            type="file"
            accept={accept}
            multiple
            onChange={e => {
                getFilesFromEvent(e).then(chosenFiles => {
                    onFiles(chosenFiles)
                })
            }}
        />
    </label>
)
}
// $('option').each(function() {
//   var optionText = this.text;
//   var newOption = optionText.substring(0, 20);
//   $(this).text(newOption + '...');
// });

    return (
      <div className="component-add-livrable-bakeliste-dashboard">
          <div className="col-md-12 mt-2">     
          <div class="button__wrap">
              <button
                className="btn-livraison btn-theme-gradient text-up w-full  "
                onClick={() => open("md")}
              > 
                <FeatherIcon
                  icon="send"
                  className="icon-btn-livrable-bakeliste-item "
                />
                Envoyer mon travail
              </button>
              <div class="button__shadow"></div>

              </div>
          </div> 

        <Modal
            className="modal-responsive-md"
            size={addlivraison.size}
            show={addlivraison.show}
            onHide={addlivraison.close}
          >
            <h5
              className="modal-bakeliste-title m-b-50"
              id="modalSendLivrableTitle"
            >
              Envoyer mon travail
            </h5>
            <button
               className="close-modal-bakeliste-title"
              onClick={close}
            >
              <FeatherIcon icon="x" aria-hidden="true" />
            </button>

            <form
              encType="multipart/form-data"
              id="livrable_form"
              
              onSubmit={(e) => onSendLivrableFiles(e)}
            >
              <div className="form-group">
                <select
                  name="details_syllabus_id"
                  className="form-control select-modal-content-bakeliste"
                  id="addLivrableSelectTache"
                  onChange={(e) => handleChange(e)}
                   value={addlivraison.details_syllabus_id}
                >
                  <option>Choisir une tache</option> 
                   {options.length > 0
                    && options.map((item, i) => {
                    
                      return (<option key={i} value={item.id}>{item.task}</option>)
                      
                      
                  })}              
                </select>
                  </div>
                 
                  <div className="form-group">
                    <textarea
                      className="form-control input-modal-content-bakeliste p-t-15"
                      rows="9"
                      placeholder="Ajouter un commentaire"
                      name="comment"
                       value={addlivraison.comment}
                      onChange={(e) => handleChange(e)}
                    ></textarea>
                  </div>
                  
                     <Dropzone
                required
                onChangeStatus={handleChangeStatus}
                onChange={(e) => handlefileChange(e)}
                accept="image/*,audio/*,video/*"
                InputComponent={InputChooseFile}
                getFilesFromEvent={getFilesFromEvent}
                classNames
              />
              
                  {loading ? (
                    <button
                      type="submit"
                      className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                      disabled
                    >
                      Envoie en cours &nbsp;
                      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                    >
                      Envoyer
                    </button>
              )}
            </form>
          </Modal>
      </div>
    );
  
}

const mapStateToprops =state=>{
  return{
   taches: state.tachesBakeliste.items,
  loading:state.tachesBakeliste.loading,
  error:state.tachesBakeliste.error,
  success:state.addLivraison.success
  }
}

const mapDispatchToProps = dispatch => ( {
  list_taches: () => dispatch( fetchTaches() ),
  list_livraisons:()=>dispatch(fetchLivraisons())
} )

export default connect(mapStateToprops,mapDispatchToProps)(AddLivraisonsv2)
// export default AddLivraisonsv2
