import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./components/LivraisonRefactor/redux/rootReducer";
import { store } from "./components/LivraisonRefactor/redux/store";
import { fetchLivraisons } from "./components/LivraisonRefactor/redux/actions/livraisonsActions";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import * as serviceWorker from "./serviceWorker";
import App from "./App";
import {
  fetchTaches,
  getDetailsSyllabus,
} from "./components/LivraisonRefactor/redux/actions/tacheBakelistesActions";
import { getSyllabusBakeliste } from "./components/LivraisonRefactor/redux/actions/syllabusActions";
import { getDemandeAbsenceBakeliste } from "./components/LivraisonRefactor/redux/actions/demandeAbsenceActions";

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

store.dispatch(fetchLivraisons());
store.dispatch(fetchTaches());

store.dispatch(getDemandeAbsenceBakeliste(695));

document.addEventListener("DOMContentLoaded", function () {
  ReactDOM.render(
    <Provider store={store}>
      <App />
      {/* <PersistGate loading={null} persistor={persistor}>
      </PersistGate> */}
    </Provider>,

    document.getElementById("root")
  );
});
serviceWorker.register();
reportWebVitals();
