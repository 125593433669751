import React, { Component } from "react";
import "./bakelisteBySyllabus.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { Link } from "react-router-dom";
import SendTaskTo from "../sendTaskTo/index";

import NavVerticalCoach from "../navVerticalCoach/index";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";

import ProgramReunionCoach from "../programReunionCoach/index";
import { Uploader, DatePicker } from "rsuite";

import TableGestionTacheForCoach from "../tableGestionTacheForCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import HeaderDashboardCoach from "../headerDashboardCoach/index";

import API from "../../variablesGlobales";

class PresenceCheck extends React.Component {
  render() {
    return (
      <div style={{ color: "#22C580", textAlign: "center" }}>
        <FeatherIcon icon="check" size={16} />
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return <PresenceCheck active={cell} />;
}
export default class bakelisteBySyllabus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Bakeliste: [],
      api: API.API,
      coach_id: window.sessionStorage.getItem("user_id"),
      index: 0,
      bakelisteBySyllabus: [],
      syllabus_id: this.props.location.state.id,
    };
  }
  componentDidMount() {
    this.getBakelisteBySyllabus();
    this.onGetSyllabusDetails();
  }
  onGetSyllabusDetails = () => {
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;
    axios
      .get(baseApiUrl + "all-details-syllabus/" + this.state.syllabus_id)
      .then((response) => {
        var data = response.data.data.details;
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            syllabusDatas: response.data.data,
            name: response.data.data.syllabus_name,
          });
        }
        data.forEach((item) => {
          let infos = {
            sy: item.syllabus_task,
            value: item.id,
          };
          this.state.selectOptions.push(infos);
        });
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          isPageLoading: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };

  getBakelisteBySyllabus = () => {
    var baseApiUrl = this.state.api;
    axios
      .get(baseApiUrl + "bakelistes-by-syllabus/" + this.state.syllabus_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            bakelisteBySyllabus: response.data.data,
          });
        }
      })
      .catch((error) => {
        // console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  render() {
    let id = this.state.syllabus_id;
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoach />
            </div>
            <div className="col-lg-10 pt-3">
              <div className="p-b-200">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item ">
                      <Link to="/dashboard-coach">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item " aria-current="page">
                      <Link to="/coach-syllabus">Syllabus</Link>
                    </li>
                    <li className="breadcrumb-item " aria-current="page">
                      <Link
                        to={{
                          pathname: "/syllabus-details-coach/",
                          state: { id: id },
                        }}
                      >
                        {this.state.name}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Bakelistes
                    </li>
                  </ol>
                </nav>
                <div className="row">
                  <div className="col-lg-9">
                    <div className="row m-b-15">
                      <div className="col m-b-15">
                        <h6 className="h-theme ff-inter fw-600">
                          Le syllabus {this.state.name} est suivit par
                        </h6>
                      </div>
                      <div className="col dis-flex m-b-15"></div>
                    </div>

                    <div className="card-dashboard p-md-5 p-sm-5 m-b-20">
                      <BootstrapTable
                        data={this.state.bakelisteBySyllabus}
                        striped
                        version="4"
                        bordered={false}
                        bodyStyle={{
                          fontSize: 12,
                          color: "rgba(0, 0, 0, 0.7)",
                          fontFamily: "inherit",
                          fontWeight: 400,
                        }}
                        tdStyle={{ background: "red" }}
                        tableHeaderclassName="header-table-groupe-view"
                        wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                        className="table-responsive table-responsive-sm"
                      >
                        <TableHeaderColumn
                          isKey="true"
                          dataField="i"
                          thStyle={{
                            border: "none",
                            paddingLeft: "0",
                            paddingRight: "0",
                            width: "30px",
                            textAlign: "center",
                            fontWeight: "400",
                          }}
                          tdStyle={{
                            border: "none",
                            paddingLeft: "0",
                            paddingRight: "0",
                            width: "30px",
                            textAlign: "center",
                          }}
                        ></TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="first_name"
                          thStyle={{ border: "none", fontWeight: "400" }}
                          tdStyle={{ border: "none" }}
                        >
                          Prénom
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="last_name"
                          thStyle={{ border: "none", fontWeight: "400" }}
                          tdStyle={{ border: "none" }}
                        >
                          Nom
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="phone"
                          thStyle={{ border: "none", fontWeight: "400" }}
                          tdStyle={{ border: "none" }}
                        >
                          N° Téléphone
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="email"
                          dataSort
                          thStyle={{ border: "none", fontWeight: "400" }}
                          tdStyle={{ border: "none" }}
                        >
                          Adresse mail
                        </TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  </div>
                  <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <BakelisteGroupItemForCoach />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                        <ProgramReunionCoach />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <SavedReunionListItem />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ tableMyBakelistes }) => ({ ...tableMyBakelistes }),
//     dispatch => bindActionCreators({ ...tableMyBakelistesActions }, dispatch)
//   )( tableMyBakelistes );
