import {
  FETCH_LIVRAISONS_BY_BAKELISTE,
  FETCH_LIVRAISON_BEGIN,
} from "../actions/livraisonBakelisteActions";

const initialState = {
  items: [],
  loading: false,
  error: null,
};

export default function livraisonsBakelisteReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case FETCH_LIVRAISON_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_LIVRAISONS_BY_BAKELISTE:
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload,
      };
    default:
      return state;
  }
}
