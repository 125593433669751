import React, { Component } from "react";
import "./nextReunionItem.css";
import IconProReunion from "../../img/icons/calendar.png";
import IconZoom from "../../img/icons/icon-zoom.png";
import axios from "axios";
import API from "../../variablesGlobales";
import frenchFormatDate from "../frenchFormatDate";
import * as Sentry from "@sentry/browser";
import { Link } from "react-router-dom";

export default class nextReunionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      meeting: [],
      formatDate: frenchFormatDate.frenchFormatDate,
    };
    Sentry.init({
      dsn:
        "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
    });
  }

  componentDidMount() {
    this.onGetZoomMeetings();
  }
  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }
  onGetZoomMeetings = () => {
    axios
      .get(this.state.api + "next_zoom_meeting")
      .then((response) => {
        this.setState({
          meeting: response.data.data,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  render() {
    return (
      <div className="card-dashboard m-b-15 w-full dis-flex flex-col">
        <p className="h-card-dashboard-bakeliste m-b-30">
          <img
            className="icon-h-card-dashboard-bakeliste"
            src={IconProReunion}
            alt=""
          />
          Prochaine reunion
        </p>
        {this.state.meeting !== null && (
          <div>
            <p className="date-reunion-zoom-card-dashboard-bakeliste">
              {this.state.formatDate(this.state.meeting.zoom_datetime)}
            </p>
            <Link
              target="_blank"
              className="btn-reunion-zoom-card-dashboard-bakeliste text-up mt-auto"
              onClick={(event) => {
                event.preventDefault();
                window.location.href = this.state.meeting.zoom_invitation_url;
              }}
            >
              <img
                className="icon-btn-reunion-zoom-card-dashboard-bakeliste"
                src={IconZoom}
                alt=""
              />
              Participer
            </Link>
          </div>
        )}
      </div>
    );
  }
}
// export default connect(
//     ({ nextReunionItem }) => ({ ...nextReunionItem }),
//     dispatch => bindActionCreators({ ...nextReunionItemActions }, dispatch)
//   )( nextReunionItem );
