import API2 from "../../../../variablesGlobales";
import API from "../../../../variablesGlobales";
import axios from "axios";
export const FETCH_LISTE_COACH_ASSISTANT = "FETCH_LISTE_COACH_ASSISTANT";

export const getListeCoachAssistant = (groupeId) => {
    return (dispatch) => {
      return axios 
        .get(API.API + "coach-assistants-by-domaine/" + groupeId) 
        .then((response) => {
        if (response.data.data !== undefined) {
          dispatch({
            type: FETCH_LISTE_COACH_ASSISTANT,
            payload: response.data.data,
          });

     } })
        .catch((err) => console.log(err));
    };
  };


 