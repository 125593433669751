import React, { Component } from "react";
import "./contenus.css";
import axios from "axios";
import * as Sentry from "@sentry/react";
import API from "../../variablesGlobales";
import ModalContactUs from "../modalContactUs/index";
// import FeatherIcon from "feather-icons-react";
import Fichier from "../../image/fichier.png";
import { Link } from "react-router-dom";
import frenchShortFormatDate from "../frenchFormatDate";
import Logo from "../../img/logo.png";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItem from "../bakelisteGroupItem/index";
// import NextReunionItem from "../nextReunionItem/index";
// import SavedReunionListItem from "../savedReunionListItem/index";
import DOMPurify from "dompurify";
// CommonJS
import qs from "qs";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as voirMesContenusActions from "../../store/voirMesContenus/actions";
export default class contenus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contenus: [],
      // api:API.API,
      userID: window.sessionStorage.getItem("user_id"),
      formatDate: frenchShortFormatDate.frenchShortFormatDate,

      // id: this.props.id,
    };
  }
  //   componentDidMount(){
  //     this.getResume();
  //   }

  //   getResume = () => {
  //     // var fd = new FormData();
  //     // fd.append("id", this.state.id);
  //   axios
  //     .get(this.state.api + "textual-contents/" + this.state.id)
  //     .then((res) => {
  //       // const contenu = res.data;
  //       this.setState({
  //         contenus: res.data,
  //       });
  //       console.log(this.state.contenus);
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //       if (process.env.NODE_ENV !== "production") {
  //         return;
  //       }
  //       Sentry.captureException(error);
  //     });
  // };

  render() {
    // const parse = require('html-react-parser');
    const contenu = this.props.contenu_text;
    return (
      <div className="p-b-200 ">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item ">
                  <Link to="dashboard-bakeliste">Dashboard</Link>
                </li>
                 <li className="breadcrumb-item ">
                  <Link to="/liste-contenus-bakelistes">Liste des contenus</Link>
                </li>
                <li className="breadcrumb-item ">
                  <Link to="/liste-de-mes-contenus">Mes contenus</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {this.props.title}
                </li>
              </ol>
            </nav>
            <div className="row m-b-20 pt3">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600">
                  Détails contenu
                </h6>
              </div>
              <div className="col dis-flex m-b-15">
                <ModalContactUs />
              </div>
            </div>
            <div className="card-dashboard m-b-15 p-b-60 mto-3">
            <div className="images-aita-card-center">
              <img className="logo-header" src={Logo} alt="" />
                <h5>Bakeli school of technology</h5>
              </div>
              {this.props.type === "rapport de stage" &&
                <div >
                    <h5><span style={{textDecoration:"underline"}}>Rapport de stage portant sur: </span>&nbsp;{this.props.title}</h5>
                </div>
              }
              {this.props.type === "tutoriel" &&
                <div className="bot">
                    <h5><span style={{textDecoration:"underline"}}>Tutoriel sur:</span>&nbsp; {this.props.title}</h5>
                </div>
              }
              {this.props.type === "article" &&
                <div className="bot">
                    <h5><span style={{textDecoration:"underline"}}>Article sur:</span>&nbsp; {this.props.title}</h5>
                </div>
              }
              {this.props.type === "résumé" &&
                <div className="bot">
                    <h5><span style={{textDecoration:"underline"}}>Résumé sur:</span>&nbsp; {this.props.title}</h5>
                </div>
              }
              <div className="bot" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contenu) }}>
              </div>
              <div className="row bot">
                <div className="col-md-9"><span  className="auteur">Auteur:</span>&nbsp;{this.props.auteur}</div>
                <div className="col-md-3"><span  className="ecrit">Ecrit le:</span>&nbsp;{this.state.formatDate(this.props.created_at)}</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <CoachItemDashboardBakeliste />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <BakelisteGroupItem />
              </div>
              {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                <NextReunionItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <SavedReunionListItem />
              </div> */}
            </div>
            </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ voirMesContenus }) => ({ ...voirMesContenus }),
//     dispatch => bindActionCreators({ ...voirMesContenusActions }, dispatch)
//   )( voirMesContenus );
