import React, { useEffect, useState } from "react";
import "./syllabusBakeliste.css";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItemRefact from "../bakelisteGroupItem/BakelisteGroupItemRefact";
import NextReunionItem from "../nextReunionItem/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import SyllabusItem from "../syllabusItem/syllabusItem";
import SyllabusItemDisabled from "../syllabusItemDisabled/syllabusItemDisabled";
import ModalContactUs from "../modalContactUs/index";
import axios from "axios";
import API from "../../variablesGlobales";
import Preloader from "../preloader/index";
import ListCoachAssistantRefact from "../listCoachAssistant/ListCoachAssistantRefact";
import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";
import * as Sentry from "@sentry/browser";
import EnvoyerMonTravail from "../envoyerMonTravail/index";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector, connect } from "react-redux";

import { getSyllabusBakeliste } from "../LivraisonRefactor/redux/actions/syllabusActions";

const SyllabusBakelisteRefact = (props) => {
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [api] = useState(API.API);
  const [email, setEmail] = useState(window.sessionStorage.getItem("email"));
  const [userID, setUserID] = useState(
    window.sessionStorage.getItem("user_id")
  );
  const [syllabusDatas, setSyllabusDatas] = useState([]);
  const syllabusBakeliste = useSelector((state) => state.syllabusReducer);
  const dispatch = useDispatch();

  Sentry.init({
    dsn: "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
  });

  useEffect(
    (err) => {
      dispatch(getSyllabusBakeliste(userID));
      //   setSyllabusDatas(syllabusBakeliste);
      Sentry.captureException(err);
      onGetSyllabus(syllabusBakeliste);
      setTimeout(() => {
        setLoad(false);
      }, 1000);
    },
    [dispatch, userID]
  );

  const onGetSyllabus = () => {
    const syllabusData = [];
    for (let any of props.programmes) {
      for (let item of any) {
        syllabusData.push({
          ...item,
          technologies: item.technologies || [],
        });
      }
    }
    return syllabusData;
  };

  const data = onGetSyllabus();

  //   const onGetSyllabus = (syllabusBakeliste) => {
  //     setIsPageLoading(true);
  //     const syllabusDatas1 = props.programmes;
  //     for (let s of syllabusBakeliste.items) {
  //       for (let item of s) {
  //         syllabusDatas1.push({
  //           ...item,
  //           technologies: item.technologies || [],
  //         });
  //       }
  //     }
  //     return;
  //     // setSyllabusDatas(syllabusDatas1);
  //   };

  return load ? (
    <div>
      <Preloader />
    </div>
  ) : (
    // <Amplitude>
    <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
      <div className="row">
        <div className="col-lg-9">
          <nav aria-label="breadcrumb fixed">
            <ol className="breadcrumb fixed">
              <li className="breadcrumb-item ">
                <Link to="/dashboard-bakeliste">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Programmes
              </li>
            </ol>
          </nav>
          <div className="row m-b-15 pt3">
            <div className="col m-b-15">
              <h6 className="h-theme ff-inter fw-600">Programmes</h6>
            </div>
            <div className="col dis-flex m-b-15">
              <ModalContactUs />
            </div>
          </div>

          <div className="row px-lg-0 px-md-2">
            {data.map((item, index) =>
              item.is_archived ? (
                <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
                  <SyllabusItemDisabled syllabus={item} />
                </div>
              ) : (
                <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
                  <SyllabusItem syllabus={item} />
                </div>
              )
            )}
          </div>
        </div>
        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
              <CoachItemDashboardBakeliste />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
              <BakelisteGroupItemRefact />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
              <ListCoachAssistantRefact />
            </div>
          </div>
        </div>
      </div>
    </div>
    // </Amplitude>
  );
};

const mapStateToprops = (state) => {
  return {
    programmes: state.syllabusReducer.items,
  };
};

export default connect(mapStateToprops, { getSyllabusBakeliste })(
  SyllabusBakelisteRefact
);
