import React, { Component } from "react";
import "./voirContenusBakeliste.css";
import axios from "axios";
import * as Sentry from "@sentry/react";
import API from "../../variablesGlobales";
import ModalContactUs from "../modalContactUs/index";
import FeatherIcon from "feather-icons-react";
import Fichier from "../../image/fichier.png";
import { Link } from "react-router-dom";
import { NavLink } from "react-bootstrap";
import DOMPurify from "dompurify";
import TextTruncate from "react-text-truncate";
import Preloader from "../preloader/index";
import ListCoachAssistant from "../listCoachAssistant/index";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import InfiniteScroll from "react-infinite-scroll-component";
import Moment from "react-moment";
import Resume from "../../img/resume.jpeg";
import Article from "../../img/article.jpeg";
import Tutoriel from "../../img/tutoriel.jpeg";
import Rapport from "../../img/rapport.jpeg";
import { Tooltip } from "@material-ui/core";
import Truncate from "react-truncate-html";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItem from "../bakelisteGroupItem/index";
// import NextReunionItem from "../nextReunionItem/index";
// import SavedReunionListItem from "../savedReunionListItem/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as voirMesContenusActions from "../../store/voirMesContenus/actions";

export default class voirContenusBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contenus: [],
      api: API.API,
      userID: window.sessionStorage.getItem("user_id"),
      isload: true,
      currentPage: 1,
      hasMore: true,
      article: [],
      rapportDeStage: [],
      tutoriel: [], 
      resume: [],
      tab_All: true, 
      tab_Rapports: false,
      tab_tutoriels: false,
      tab_resumes: false,
      tab_articles: false,
      navs: [],
      recherche: "",

    };
  }
  componentDidMount() {
    this.getArticles();
    this.getListResumes();
    this.getTutoriels();
    this.getRapportDeStage();
    setTimeout(() => {
      this.setState({
        isload: false,
      });
    }, 5000);
    this.getResume();

  }

searchOnContent = () => {
  var fd = new FormData();
  fd.append("data", this.state.recherche);
  axios
  .post(this.state.api + "search-textual-contents", fd)
  .then((res) => {
    // const contenu = res.data;
    // this.setState({
    //   resume: contenu.data,
    // });
     console.log(res, "res search")
  })
  .catch((error) => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }
    Sentry.captureException(error);
  });
}

  getListResumes = () => {
    axios
      .get(this.state.api + "liste-validated-resumes")
      .then((res) => {
        const contenu = res.data;
        this.setState({
          resume: contenu.data,
        });
        //  console.log(res, this.state.resume)
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getTutoriels = () => {
    axios
      .get(this.state.api + "liste-validated-tutoriels")
      .then((res) => {
        if(res.success === true) {
          const contenu = res.data;
          this.setState({
            tutoriel: res.data.data,
          });
            console.log("tuto",res, this.state.tutoriel)
        }else{
    
            this.state.tutoriel.length = 0
          
        }
          })
        
       
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getRapportDeStage = () => {
    axios
      .get(this.state.api + "liste-validated-rapport-de-stage")
      .then((res) => {
        const contenu = res.data;
        this.setState({
          rapportDeStage: contenu.data,
        });
        //  console.log(res, this.state.rapportDeStage)
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getArticles = () => {
    axios
      .get(this.state.api + "liste-validated-articles")
      .then((res) => {
        const contenu = res.data;
        this.setState({
          article: contenu.data,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getResume = () => {
    this.showLoader();
    this.setState({
      isload: true,
    });
    axios
    .get(
      this.state.api +
        "validated-textual-contents" +
        "/?page=" +
        this.state.currentPage
    )

      .then((res) => {
        const contenu = res.data.data;
        this.setState({
          contenus: contenu.data,
        });
      this.hodeLoader();

      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  hideLoader = () => {
    this.setState({ isload: false });
  };

  showLoader = () => {
    this.setState({ isload: true });
  };

  fetchMoreData = () => {
    setTimeout(() => {
      this.setState({
        currentPage: this.state.currentPage + 1,
        loading: true,
      });
      axios
        .get(
          this.state.api +
            "validated-textual-contents" +
            "/?page=" +
            this.state.currentPage
        )

        .then((response) => {
          // console.log("jdcjcndzl", response);

          if (response.data.success === true) {
            Object.values(response.data.data.data).forEach((element) => {
              this.state.contenus.push(element);
            });
            //console.log("dzlkd", this.state.livraisonsSyllabus);

            if (
              response.data.data.current_page === response.data.data.last_page
            ) {
              this.setState({ hasMore: false, loading: false });
            } else {
              this.setState({ hasMore: true, loading: true });
            }
          }
        });
    }, 500);

    return this.state.contenus;
  };
  // voirPlus = (id) => {
  //   window.location=`/details-contenus-bakelistes?id=${id}`
  // }
  voirMesContenus = () => {
    window.location = "/liste-de-mes-contenus";
  };
  ajouterContenus = () => {
    window.location = "ajouter-un-contenu";
  };

  setNavTabs(event) {
    let navs = this.state.navs;
    navs[event.target.name] = event.target.value;
    if (navs["drone"] === "all") {
      this.setState({
        tab_All: true,
        tab_Rapports: false,
        tab_tutoriels: false,
        tab_resumes: false,
        tab_articles: false,
      });
    }
    if (navs["drone"] === "rapports") {
      this.setState({
        tab_All: false,
        tab_Rapports: true,
        tab_tutoriels: false,
        tab_resumes: false,
        tab_articles: false,
      });
    }
    if (navs["drone"] === "tutoriels") {
      this.setState({
        tab_All: false,
        tab_Rapports: false,
        tab_tutoriels: true,
        tab_resumes: false,
        tab_articles: false,
      });
    }
    if (navs["drone"] === "resumes") {
      this.setState({
        tab_All: false,
        tab_Rapports: false,
        tab_tutoriels: false,
        tab_resumes: true,
        tab_articles: false,
      });
    }
    if (navs["drone"] === "articles") {
      this.setState({
        tab_All: false,
        tab_Rapports: false,
        tab_tutoriels: false,
        tab_resumes: false,
        tab_articles: true,
      });
    }
  }
  // search-textual-contents
  render() {
    return this.state.isload ? (
      <div>
        <Preloader />
      </div>
    ) : (
      <div className="p-b-200 ">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item ">
                  <Link to="dashboard-bakeliste">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Liste des contenus
                </li>
              </ol>
            </nav>
            <div className="row m-b-20 pt3">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600">Liste des contenus</h6>
              </div>
              <div className="col dis-flex m-b-15">
                <ModalContactUs />
              </div>
            </div>
            {/* <div className="col-xl-4 col-lg-5 col-md-4 col-sm-5 ">
                <input
                  value={this.state.recherche}
                  type="search"
                  // className="recherche"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  onChange={(e) =>
                    this.setState({
                      recherche: e.target.value,
                    })
                  }
                 />
                 <FeatherIcon icon="search" aria-hidden="true" onClick={() =>this.searchOnContent()}/>

               
                
                
              </div> */}


            <div className="">
              <div className="row m-b-15">
                <div className="col-md-6">
                  <button
                    className="btn-theme-green"
                    onClick={() => this.ajouterContenus()}
                  >
                    Ajouter un contenu
                  </button>
                </div>
                <div className="col-md-6 mgl">
                  <button
                    className="btn-theme "
                    onClick={() => this.voirMesContenus()}
                  >
                    Mes productions
                  </button>
                </div>
              </div>
              <InfiniteScroll
                className="hello"
                dataLength={this.state.contenus.length}
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                loader={
                  this.state.loading &&
                  "Les donnees sont entrain de se charger...."
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Vous avez tous visualise!!!</b>
                  </p>
                }
              >
                <div>
                  <div className=" tab-data-col">
                    <form>
                      <div onChange={(event) => this.setNavTabs(event)}>
                        <div className="row tb">
                          <div className="col-md-4">
                            <div className="radiobtn1">
                              <input
                                defaultChecked
                                type="radio"
                                value="alls"
                                name="drone"
                                id="all"
                                data-target="#all"
                              />
                              <label for="all">All</label>
                            </div>
                            <div className="radiobtn1">
                              <input
                                type="radio"
                                value="articles"
                                name="drone"
                                id="article"
                                data-target="#articles"
                              />
                              <label for="article">Articles</label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="radiobtn1">
                              <input
                                type="radio"
                                value="resumes"
                                name="drone"
                                id="resume"
                                data-target="#resumes"
                              />
                              <label for="resume">Résumé de cours</label>
                            </div>
                            <div className="radiobtn1">
                              <input
                                type="radio"
                                value="rapports"
                                name="drone"
                                id="rapport"
                                data-target="#rapports"
                              />
                              <label for="rapport">Rapports de stage</label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="radiobtn1">
                              <input
                                type="radio"
                                value="tutoriels"
                                name="drone"
                                id="tutoriel"
                                data-target="#tutoriel"
                              />
                              <label for="tutoriel">Tutoriels</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* liste des tous les contenus */}
                  {this.state.tab_All ? (
                    <div className="container tab-pane active" id="all">
                      <div className="row content-block">
                        {this.state.contenus.length > 0 ? (
                          this.state.contenus.map((item, index) => (
                            <div key={index} className="col-md-6 dis-flex mb-5">
                              <div class="column">
                              <Link
                                      to={{
                                        pathname:
                                          "/details-contenus-bakelistes",
                                        state: { id: item.id },
                                      }}
                                    >
                                <div class="post-module">
                                  <div class="thumbnail">
                                    <div class="date">
                                      <div class="day">
                                        {" "}
                                        <Moment format="DD" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                      <div class="month">
                                        {" "}
                                        <Moment format="MMMM" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                    {item.content_type === "article" && (
                                      <img src={Article} />
                                    )}
                                    {item.content_type === "résumé" && (
                                      <img src={Resume} />
                                    )}
                                    {item.content_type === "tutoriel" && (
                                      <img src={Tutoriel} />
                                    )}
                                    {item.content_type ===
                                      "rapport de stage" && (
                                      <img src={Rapport} />
                                    )}
                                    {item.content_type ===
                                      "cahier de charges" && (
                                      <img src={Tutoriel} />
                                    )}
                                    {item.content_type === "backlog" && (
                                      <img src={Tutoriel} />
                                    )}
                                  </div>
                                  <div class="post-content p-2">
                                    <div class="category">
                                      {item.content_type}
                                    </div>
                                    {/* <Link
                                      to={{
                                        pathname:
                                          "/details-contenus-bakelistes",
                                        state: { id: item.id },
                                      }}
                                    > */}
                                      <h1 class="title">{item.title}</h1>
                                    {/* </Link> */}
                                    <p class="description">
                                      <div>
                                        <Truncate
                                          lines={3}
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                              item.content
                                            ),
                                          }}
                                        />
                                      </div>
                                      ....
                                    </p>
                                    <div class="post-meta">
                                      <span class="timestamp">
                                        {/* <i class="fa fa-clock-">o</i>

                                        <Moment format="DD-MM-YYYY">
                                          {item.created_at}
                                        </Moment> */}
                                         <div className="auteur">
                                    {item.fullName}
                                  </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                </Link>
                              
                              </div>
                            </div>
                          ))
                        ) : (
                          <p style={{ textAlign: "center" }}>
                            <b>
                              Aucun contenu disponible pour le moment. Vous
                              pouvez en créer
                            </b>
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
{/* la liste des rapports de stage */}
                  {this.state.tab_Rapports ? (
                    <div className="container tab-pane" id="rapports">
                      <div className="row content-block">
                        {this.state.rapportDeStage.length > 0 ? (
                          this.state.rapportDeStage.map((item, index) => (
                            <div key={index} className="col-md-6 dis-flex mb-5">
                              <div class="column">
                                <div class="post-module">
                                  <div class="thumbnail">
                                    <div class="date">
                                      <div class="day">
                                        {" "}
                                        <Moment format="DD" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                      <div class="month">
                                        {" "}
                                        <Moment format="MMMM" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                    {/* {item.content_type === "article" && ( */}
                                    <img src={Rapport} />
                                    {/* )} */}
                                  </div>
                                  <div class="post-content p-2">
                                    <div class="category">
                                      {item.content_type}
                                    </div>
                                    <Link
                                      to={{
                                        pathname:
                                          "/details-contenus-bakelistes",
                                        state: { id: item.id },
                                      }}
                                    >
                                      <h1 class="title">{item.title}</h1>
                                    </Link>
                                    <p class="description">
                                      <div>
                                        <Truncate
                                          lines={3}
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                              item.content
                                            ),
                                          }}
                                        />
                                      </div>
                                      ....
                                    </p>
                                    <div class="post-meta">
                                      <span class="timestamp">
                                        {/* <i class="fa fa-clock-">o</i>

                                        <Moment format="DD-MM-YYYY">
                                          {item.created_at}
                                        </Moment> */}
                                         <div className="auteur">
                                    {item.fullName}
                                  </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p style={{ textAlign: "center" }}>
                            <b>
                              Aucun article disponible. Vous pouvez en créer
                            </b>
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
              

                
{/* liste des tutoriels */}

                  {this.state.tab_tutoriels ? (
                    <div className="container tab-pane" id="tutoriel">
                      <div className="row content-block">
                        {this.state.tutoriel.length > 0 ? (
                          this.state.tutoriel.map((item, index) => (
                            <div key={index} className="col-md-6 dis-flex mb-5">
                              <div class="column">
                                <div class="post-module">
                                  <div class="thumbnail">
                                    <div class="date">
                                      <div class="day">
                                        {" "}
                                        <Moment format="DD" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                      <div class="month">
                                        {" "}
                                        <Moment format="MMMM" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                    <img src={Tutoriel} />
                                  </div>
                                  <div class="post-content p-2">
                                    <div class="category">
                                      {item.content_type}
                                    </div>
                                    <Link
                                      to={{
                                        pathname:
                                          "/details-contenus-bakelistes",
                                        state: { id: item.id },
                                      }}
                                    >
                                      <h1 class="title">{item.title}</h1>
                                    </Link>
                                    <p class="description">
                                      <div>
                                        <Truncate
                                          lines={3}
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                              item.content
                                            ),
                                          }}
                                        />
                                      </div>
                                      ....
                                    </p>
                                    <div class="post-meta">
                                      <span class="timestamp">
                                        {/* <i class="fa fa-clock-">o</i>

                                        <Moment format="DD-MM-YYYY">
                                          {item.created_at}
                                        </Moment> */}
                                         <div className="auteur">
                                    {item.fullName}
                                  </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p style={{ textAlign: "center" }}>
                            <b>
                              Aucun tutoriel disponible. Vous pouvez en créer
                            </b>
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}


{/* liste des résumés */}
                  {this.state.tab_resumes ? (
                    <div className="container tab-pane" id="resumes">
                      <div className="row content-block">
                        {this.state.resume.length > 0 ? (
                          this.state.resume.map((item, index) => (
                            <div key={index} className="col-md-6 dis-flex mb-5">
                              <div class="column">
                                <div class="post-module">
                                  <div class="thumbnail">
                                    <div class="date">
                                      <div class="day">
                                        {" "}
                                        <Moment format="DD" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                      <div class="month">
                                        {" "}
                                        <Moment format="MMMM" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                    {/* {item.content_type === "article" && ( */}
                                    <img src={Resume} />
                                    {/* )} */}
                                  </div>
                                  <div class="post-content p-2">
                                    <div class="category">
                                      {item.content_type}
                                    </div>
                                    <Link
                                      to={{
                                        pathname:
                                          "/details-contenus-bakelistes",
                                        state: { id: item.id },
                                      }}
                                    >
                                      <h1 class="title">{item.title}</h1>
                                    </Link>
                                    <p class="description">
                                      <div>
                                        <Truncate
                                          lines={3}
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                              item.content
                                            ),
                                          }}
                                        />
                                      </div>
                                      ....
                                    </p>
                                    <div class="post-meta">
                                      <span class="timestamp">
                                        {/* <i class="fa fa-clock-">o</i>

                                        <Moment format="DD-MM-YYYY">
                                          {item.created_at}
                                        </Moment> */}
                                         <div className="auteur">
                                    {item.fullName}
                                  </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p style={{ textAlign: "center" }}>
                            <b>
                              Aucun résumé disponible. Vous pouvez en créer
                            </b>
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
{/* liste des articles */}
                  {this.state.tab_articles ? (
                    <div className="container tab-pane " id="articles">
                      <div className="row content-block">
                        {this.state.article.length > 0 ? (
                          this.state.article.map((item, index) => (
                            <div key={index} className="col-md-6 dis-flex mb-5">
                              <div class="column">
                                <div class="post-module">
                                  <div class="thumbnail">
                                    <div class="date">
                                      <div class="day">
                                        {" "}
                                        <Moment format="DD" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                      <div class="month">
                                        {" "}
                                        <Moment format="MMMM" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                    {/* {item.content_type === "article" && ( */}
                                    <img src={Article} />
                                    {/* )} */}
                                  </div>
                                  <div class="post-content p-2">
                                    <div class="category">
                                      {item.content_type}
                                    </div>
                                    <Link
                                      to={{
                                        pathname:
                                          "/details-contenus-bakelistes",
                                        state: { id: item.id },
                                      }}
                                    >
                                      <h1 class="title">{item.title}</h1>
                                    </Link>
                                    <p class="description">
                                      <div>
                                        <Truncate
                                          lines={3}
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                              item.content
                                            ),
                                          }}
                                        />
                                      </div>
                                      ....
                                    </p>
                                    <div class="post-meta">
                                      <span class="timestamp">
                                        {/* <i class="fa fa-clock-">o</i>

                                        <Moment format="DD-MM-YYYY">
                                          {item.created_at}
                                        </Moment> */}
                                         <div className="auteur">
                                    {item.fullName}
                                  </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p style={{ textAlign: "center" }}>
                            <b>
                              Aucun article disponible. Vous pouvez en créer
                            </b>
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </InfiniteScroll>

            </div>
          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <CoachItemDashboardBakeliste />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <BakelisteGroupItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <ListCoachAssistant />
              </div>
              {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                <NextReunionItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <SavedReunionListItem />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ voirMesContenus }) => ({ ...voirMesContenus }),
//     dispatch => bindActionCreators({ ...voirMesContenusActions }, dispatch)
//   )( voirMesContenus );
