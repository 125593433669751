import React, { Component } from "react";
import "./statisticsCoach.css";
import IconStat1 from "../../img/icons/person_add.png";
import IconStat2 from "../../img/icons/content_paste.png";
import IconStat3 from "../../img/icons/people.png";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { NavLink } from "react-router-dom";
import { Loader } from "react-loader-spinner";
import API from "../../variablesGlobales";
// const Loader = () => <div>Loading...</div>;
export default class statisticsCoach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nbreBakeliste: 0,
      api: API.API,
      coach_id: window.sessionStorage.getItem("user_id"),
      groupes: [],
      list: [],
      liste: [],
      sous_groupes: [],
      syllabusDatas: [],
      syllabusCount: null,
    };
  }
  componentDidMount() {
    this.getBakelisteByCoach();
    this.getAllGroups();
    this.__getSousGroupes();
    this.onGetSyllabus();
  }

  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };

  getAllGroups = () => {
    const _this = this;
    this.showLoader();
    axios
      .get(this.state.api + "groupes")
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            groupes: res.data.data.reverse(),
          });
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  getBakelisteByCoach() {
    this.showLoader();

    axios
      .get(this.state.api + "bakelistes-by-coach/" + this.state.coach_id)
      .then((response) => {
        if (response.data.data !== undefined) {
          const nbreBakeliste = response.data.count;
          this.setState({
            nbreBakeliste: nbreBakeliste,
          });
        } else {
          this.setState({
            nbreBakeliste: 0,
          });
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }
  onGetSyllabus = () => {
    const _this = this;
    this.showLoader();
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;

    axios
      .get(baseApiUrl + "syllabus-by-coach/" + this.state.coach_id)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        // console.log(response.data.total, response.data.data);
        if (response.data.success) {
          this.setState({
            syllabusDatas: response.data.data.data.reverse(),
            syllabusCount: response.data.data.total,
          });
        }
        this.state.syllabusDatas.forEach((item) => {
          let infos = {
            name: item.syllabus_name,
          };
          this.state.datas.push(infos);
        });
        //_this.hideLoader();
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
        // _this.hideLoader();
      });
  };

  __getSousGroupes = () => {
    const _this = this;
    this.showLoader();
    axios
      .get(this.state.api + "sous__groupes/coach/" + this.state.coach_id)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            sous_groupes: res.data.data.reverse(),
          });
        }
        this.state.sous_groupes.slice(0, 5).map((i) => {
          let items = {
            id: i.id,
            value: i.name,
            groupe_id: i.groupe_id,
            bak: i.sous_groupes_bakelistes,
          };
          this.state.list.push(items);
        });
        // _this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col-md-4 pr-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard">
            <NavLink to="/coach-bakelistes" style={{ color: "white" }}>
              <p className="chiffre-item-statistics-dashboard">
                {this.state.nbreBakeliste}
              </p>
              <p className="designation-item-statistics-dashboard">
                bakelistes
              </p>
              <img
                className="img-item-statistics-dashboard"
                src={IconStat1}
                alt=""
              />
            </NavLink>
          </div>
        </div>
        <div className="col-md-4 pr-md-2 pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard">
            <NavLink to="/coach-syllabus" style={{ color: "white" }}>
              <p className="chiffre-item-statistics-dashboard">
                {this.state.syllabusCount}
              </p>
              <p className="designation-item-statistics-dashboard">
                Syllabus disponibles
              </p>
              <img
                className="img-item-statistics-dashboard icon2"
                src={IconStat2}
                alt=""
              />
            </NavLink>
          </div>
        </div>
        <div className="col-md-4 pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard">
            <NavLink to="/sous-groupes-coach" style={{ color: "white" }}>
              <p className="chiffre-item-statistics-dashboard">
                {this.state.sous_groupes.length}
              </p>
              <p className="designation-item-statistics-dashboard">
                Sous-groupes
              </p>
              <img
                className="img-item-statistics-dashboard"
                src={IconStat3}
                alt=""
              />
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ statisticsCoach }) => ({ ...statisticsCoach }),
//     dispatch => bindActionCreators({ ...statisticsCoachActions }, dispatch)
//   )( statisticsCoach );
