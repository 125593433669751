import React, { Component } from "react";
import "./examenItemDisabled.css";
import ImageSyllabus from "../../img/syllabus.jpg";
import FeatherIcon from "feather-icons-react";
import Pratique from "../../img/pratique.jpeg";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import API from "../../variablesGlobales";
import { toast } from "react-toastify";

export default class examenItemDisabled extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      showActiveSuccess: false,
    };
  }

  activerExamen = (e) => {
    // axios
    //   .post(this.state.api + "activer-examen/" + this.props.examen_id)
    //   .then((res) => {
    //     if (res.data.success) {
    //       this.setState({ showActiveSuccess: true });
    //     }
    //     window.reload();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    };
    axios
      .post(this.state.api + "activer-examen/" + this.props.examen_id, headers)
      .then((res) => {
        // console.log(res);
        if (res.data.success) {
          toast.success(
            "Examen active avec succes",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
          this.props.getExamensPra();
          this.setState({ activeSuccess: true });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  render() {
    let id = this.props.examen_id;
    return (
      <div className="container-syllabus-item">
        <div className="syllabus-item1">
          <p
            className={
              "etiquette-syllabus-item " +
              (this.props.niveau === "débutant"
                ? " bg-debutant"
                : this.props.niveau === "intermédiaire"
                ? "bg-intermediaire"
                : this.props.niveau === "avancé"
                ? "bg-avance"
                : "")
            }
          >
            {this.props.niveau}
          </p>

          <img
            src={Pratique}
            alt="Avatar"
            className="image-syllabus-item"
            style={{ width: "100%" }}
          />
          <div className="content-syllabus-item">
            <p className="titre-content-syllabus-item">{this.props.name}</p>
            <div className="row">
              <div className="col-xl-4 col-lg-5 pr-lg-0 col-6 pr-0">
                <p className="info-content-syllabus-item">
                  <FeatherIcon
                    className="icon-content-syllabus-item"
                    icon="list"
                  />
                  Session: {this.props.session}
                </p>
              </div>
              <div className="col-xl-4 pl-xl-0 col-6 pr-0">
                <p className="info-content-syllabus-item">
                  <FeatherIcon
                    className="icon-content-syllabus-item"
                    icon="clock"
                  />
                  Durée:{this.props.duree}H
                </p>
              </div>
              <div className="col-xl-4 pl-xl-0 col-6 pr-0">
                <p className="info-content-syllabus-item">
                  <FeatherIcon
                    className="icon-content-syllabus-item"
                    icon="user"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container-syllabus-item-back">
          <Link
            className="link-details-container-syllabus-item-back"
            to={{
              pathname: "/details-examens-pratique",
              state: { id: id },
            }}
          >
            <div className="height-container-syllabus-item-back"></div>
          </Link>

          <div className="">
            <div className="row justify-content-end position-action-container-syllabus-item-back">
              <div className="col-xl-4 col-lg-5 col-md-5 pl-lg-3 pl-md-0 col-sm-6">
                <Link
                  className="link-details-container-syllabus-item-back"
                  to={{
                    pathname: "/details-examens-pratique",
                    state: { id: id },
                  }}
                >
                  <FeatherIcon
                    className="icon-details-container-syllabus-item-back"
                    icon="eye"
                  />
                  Details
                </Link>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-5 pl-lg-3 pl-md-0 col-sm-6 link-details-container-syllabus-item-back">
                <span onClick={(e) => this.activerExamen(e)}>
                  <FeatherIcon
                    className="icon-details-container-syllabus-item-back"
                    icon="check-circle"
                    type="button"
                    aria-hidden="true"
                  />
                  Activer
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ syllabusItemDisabled }) => ({ ...syllabusItemDisabled }),
//     dispatch => bindActionCreators({ ...syllabusItemDisabledActions }, dispatch)
//   )( syllabusItemDisabled );
