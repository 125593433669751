 import {FETCH_SYLLABUS_BAKELISTE, FETCH_TACHES_BY_SYLLABUS} from "../actions/syllabusActions";

 const initialState = {
    items: [],
    loading: false,
    error: null,
};

export default function SyllabusReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_SYLLABUS_BAKELISTE:
        return {
            ...state,
            loading: false,
            error: action.payload.error,
            items: action.payload,
        };
        case FETCH_TACHES_BY_SYLLABUS:
        return {
            ...state,
            loading: false,
            error: action.payload.error,
            items: action.payload,
        };
        default :
        return state
    }

}

