import React, { Component } from "react";
import "./syllabusCoachAssistant.css";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import { Icon, Modal } from "rsuite";
import SyllabusItemCoachAssistant from "../syllabusItemCoachAssistant/index";
import axios from "axios";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import $ from "jquery";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import MultiSelect from "@khanacademy/react-multi-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink } from "react-bootstrap";
import Loader from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";

import Preloader from "../preloader/index";

const styles = {
  marginBottom: 30,
};

export default class syllabusCoachAssistant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: window.sessionStorage.getItem("user_id"),
      api: API.API,
      my_id: "",
      domaines: [],
      syllabusDomaineDatas: [],
      isPageLoading: false,
      datas: [],
      recherche: "",
      datasDomaine: [],
      tab_all_syllabus: true,

      // Add SYllabusss

      show: false,
      aisSuccessAssign: false,
      isErrorAssign: false,
      isAssignSyllabusInProgress: false,
      selectedOption: null,
      userID: window.sessionStorage.getItem("user_id"),
      showHide: false,
      isPageLoading: true,
      api: API.API,
      syllabusDatas: [],
      domaines: [],
      technologies: [],
      tools: [],
      fields: {},
      errors: {},
      sName: "",
      sDescription: "",
      sDomaine: 0,
      domainesSelect: [],
      sLevel: "",
      sTechnologie: [],
      sTool: [],
      sname: false,
      sdescription: false,
      stechnotool_empty: false,
      screatedby_empty: false,
      slevel_empty: false,
      sdomaineid_empty: false,
      addSyllabusInProgress: false,
      addSyllabusSuccessAlert: false,
      addSyllabusErrorAlert: false,
      remaining_char: 245,
      selectOptions: [],
      selectOptions1: [],
      selectOptions2: [],
      selectTechnologies: [],
      selectTools: [],
      syllabusID: 0,
      bakelisteID: 0,
      selected: [],
      utFields: {},
      selectedtools: [],
      onlySelect: false,
      groupySelect: false,
      bakelistesByCoach: [],
      bakeliste: [],
      assignationEncours: false,
      assignationSuccessAlert: false,
      isAssignationError: false,
      noSavedData: [],
      showAlertSuccess: false,
      showAlertError: false,
      utInProgress: false,
      syllabusByCoache: [],
      updateSyllabusRequestError: false,
      // updateSyllabusRequestError: false,
      updateSyllabusRequestSuccessAlert: false,
      bakelisteBySyllabus: [],
      sous_groupes: [],
      currentPage: 1,
      hasMore: true,
      loading: false,
      load: true,
      detailCoach:[],
      coach_id:"",
    };

    Sentry.init({
      dsn:
        "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
    });
    this.addHandleChange = this.addHandleChange.bind(this);

    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }
  componentDidMount = () => {
    this.getDetailsCoachById();

    this.onGetSyllabus();
    this.onGetDomaines();
    this.getAllTechnologies();
    this.getAllTools();
    this.getAllDomaines();
    window.scrollTo(0, 0);
    this.onGetSyllabusByDomaine();
    setTimeout(() => {
      this.setState({
        load: false,
      });
    }, 4000);
  };

  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };
  getDetailsCoachById = () => {
    axios
      .get(this.state.api + "get-coach-by-id/" + this.state.userID)
      .then((res) => {
        // console.log(res)
       
        if (res.data.success) {
          this.setState({
            detailCoach: res.data.data,
            coach_id:res.data.data.coach_id,
          });
        //  console.log(this.state.detailCoach, this.state.coach_id)
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
  };

  // Add Syllabus
  addHandleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["created_by"] = this.state.userID;
    fields["technologie_id"] = this.state.selected;
    fields["tool_id"] = this.state.selectedtools;
    // fields["syllabus_domaine_id"] = this.state.domainesSelect;
    if (e.target.name === "syllabus_description") {
      var chars = e.target.value.length;
      var remaining_char = 245 - chars;
      this.setState({
        remaining_char: remaining_char,
      });
    }
    this.setState({
      fields: fields,
    });

    // console.log(fields);
  };

  getAllDomaines = () => {
    axios
      .get(this.state.api + "domaines")
      .then((res) => {
        const domains = res.data;
        this.setState({
          domaines: domains.data,
        });
      })
      .catch((error) => {
        //console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  //get technologies
  getAllTechnologies() {
    axios
      .get(this.state.api + "technologies/")
      .then((response) => {
        var data = response.data.data;

        if (data !== undefined) {
          this.setState({
            technologies: data,
          });
          //console.log("les technologies :", this.state.technologies);
        }

        data.forEach((item) => {
          let infos = {
            label: item.name,
            value: item.id,
          };
          this.state.selectOptions1.push(infos);
        });
        //console.log("Technologies ", this.state.selectOptions1);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }

  //get tools
  getAllTools() {
    axios
      .get(this.state.api + "tools/")
      .then((response) => {
        var data = response.data.data;
        if (data !== undefined) {
          this.setState({
            tools: data,
          });
        }

        data.forEach((item) => {
          let infos = {
            label: item.name,
            value: item.id,
          };
          this.state.selectOptions2.push(infos);
        });
        //console.log("Tools ", this.state.selectOptions2);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }

  submitAddSyllabus = async (e) => {
    e.preventDefault();
    if (this.onValidateAddFormData()) {
      this.setState({
        addSyllabusInProgress: true,
      });
      //var baseApiUrl = this.state.api;
      var data = this.state.fields;
      // console.log("data", data);
      axios
        .post(this.state.api + "syllabus/create", this.state.fields)
        .then((response) => {
          // console.log(response);
          this.setState({
            addSyllabusInProgress: false,
          });
          // console.log(response.data.success);
          if (response.data.success) {
            this.onGetSyllabus();
            $(".close-modal-coach-title").click();
            //  $("#syllabus_form").reset();
            //this.onGetSyllabus();
            setTimeout(() => {
              this.setState({
                showAlertSuccess: true,
              });
            }, 1000);
          }
          // $(".close-modal-bakeliste-title").click();
        })
        .catch((error) => {
          if ((error.message = "Request failed with status code 422")) {
          }
          this.setState({
            addSyllabusInProgress: false,
            showAlertError: true,
          });

          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    }
  };

  onValidateAddFormData = () => {
    let fields = this.state.fields;
    // console.log(fields);
    let errors = {};
    let isValidForm = true;

    if (!fields["syllabus_name"]) {
      isValidForm = false;
      this.setState({
        sname_empty: true,
      });
      this.onSwitchAlertStatus("sname_empty");
      errors["sname_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_description"]) {
      isValidForm = false;
      this.setState({
        sdescription_empty: true,
      });
      this.onSwitchAlertStatus("sdescription_empty");
      errors["sdescription_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["technologie_id"]) {
      isValidForm = false;
      this.setState({
        technologie_id_empty: true,
      });
      this.onSwitchAlertStatus("technologie_id_empty");
      errors["technologie_id_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["tool_id"]) {
      isValidForm = false;
      this.setState({
        tool_id_empty: true,
      });
      this.onSwitchAlertStatus("tool_id_empty");
      errors["tool_id_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["created_by"]) {
      isValidForm = false;
      this.setState({
        screatedby_empty: true,
      });
      this.onSwitchAlertStatus("screatedby_empty");
      errors["screatedby_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_level"]) {
      isValidForm = false;
      this.setState({
        slevel_empty: true,
      });
      this.onSwitchAlertStatus("slevel_empty");
      errors["slevel_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_domaine_id"]) {
      isValidForm = false;
      this.setState({
        sdomaineid_empty: true,
      });
      this.onSwitchAlertStatus("sdomaineid_empty");
      errors["sdomaineid_empty"] = "*Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  };

  onSwitchAlertStatus = (label) => {
    setTimeout(() => {
      if (label === "addSyllabusSuccessAlert") {
        this.setState({
          addSyllabusSuccessAlert: false,
        });
      }
      if (label === "addSyllabusErrorAlert") {
        this.setState({
          addSyllabusErrorAlert: false,
        });
      }
      if (label === "isGetSyllabusRequestError") {
        this.setState({
          isGetSyllabusRequestError: false,
        });
      }
      if (label === "sname_empty") {
        this.setState({
          sname_empty: false,
        });
      }
      if (label === "sdescription_empty") {
        this.setState({
          sdescription_empty: false,
        });
      }
      if (label === "screatedby_empty") {
        this.setState({
          screatedby_empty: false,
        });
      }
      if (label === "slevel_empty") {
        this.setState({
          slevel_empty: false,
        });
      }
      if (label === "sdomaineid_empty") {
        this.setState({
          sdomaineid_empty: false,
        });
      }
    }, 5000);
  };

  getSyllabusData() {
    const recherche = this.state.recherche.trim().toLocaleLowerCase();
    const syllabus = this.state.syllabusDatas;
    if (recherche.length < 1) {
      return syllabus;
    }
    return syllabus.filter((s) => {
      return s.syllabus_name.toLocaleLowerCase().includes(recherche);
    });
  }

  onGetDomaines = () => {
    const _this = this;
    this.showLoader();
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;

    axios
      .get(baseApiUrl + "liste-domaines-actifs")
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          this.setState({
            domaines: response.data.data.reverse(),
          });
        }
        // _this.hideLoader();
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
        // _this.hideLoader();
      });
  };

  setNavTabs = (event) => {
    // let navs = this.state.navs;
    // navs[event.target.name] = event.target.value;
    // console.log("testttt my", event.target.id);
    this.setState({
      tab_all_syllabus: false,
      // my_id: event.target.id,
    });
    const my_id = event.target.id;
    // console.log("testttt my_id", my_id);

    // this.onGetSyllabusByDomaine(event.target.value);
    this.onGetSyllabusByDomaine(my_id);
  };

  setNavall = (event) => {
    // let navs = this.state.navs;
    // navs[event.target.name] = event.target.value;
    // console.log(event.target.value);
    this.setState({
      tab_all_syllabus: true,
    });
  };

  onGetSyllabusByDomaine = (id) => {
    //  const _this = this;
    // console.log("yfhyf", id);
    this.showLoader();
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;

    axios
      .get(baseApiUrl + "syllabus-by-domaine/" + id)
      .then((response) => {
        //console.log("response",response)

        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          this.setState({
            syllabusDomaineDatas: response.data.data.reverse(),
          });
        }
        this.state.syllabusDomaineDatas.forEach((item) => {
          let infos = {
            name: item.syllabus_name,
          };
          this.state.datasDomaine.push(infos);
        });
        //  _this.hideLoader();
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
        //   _this.hideLoader();
      });
  };

  fetchMoreData = () => {
    setTimeout(() => {
      this.setState({
        currentPage: this.state.currentPage + 1,
        loading: true,
      });
      var baseApiUrl = this.state.api;

      axios
        .get(
          baseApiUrl +
            "syllabus-by-coach/" +
            this.state.userID +
            "/?page=" +
            this.state.currentPage
        )
        .then((response) => {
          // console.log("okk", response.data.data.current_page);
          if (response.data.success === true) {
            Object.values(response.data.data.data).forEach((element) => {
              this.state.syllabusDatas.push(element);
            });

            if (
              response.data.data.current_page === response.data.data.last_page
            ) {
              this.setState({ hasMore: false, loading: false });
            } else {
              this.setState({ hasMore: true, loading: true });
            }
          }
        });
    }, 500);

    return this.state.syllabusDatas;
  };
  onGetSyllabus = () => {
    // const _this = this;
    //  this.showLoader();
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;
    axios
      .get(
        baseApiUrl +
          "syllabus-by-coach/" +
          this.state.userID +
          "/?page=" +
          this.state.currentPage
      )
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          // console.log(response);
          this.setState({
            syllabusDatas: response.data.data.data,
          });
          // console.log(response);
        }
        this.state.syllabusDatas.forEach((item) => {
          let infos = {
            name: item.syllabus_name,
          };
          this.state.datas.push(infos);
        });
        // _this.hideLoader();
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
        // _this.hideLoader();
      });
  };

  render() {
    return this.state.load ? (
      <div>
        <Preloader />
      </div>
    ) : (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <div>
              <nav aria-label="breadcrumb fixed">
                <ol className="breadcrumb fixed">
                  <li className="breadcrumb-item ">
                    <Link to="/dashboard-coach-assistant">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Syllabus
                  </li>
                </ol>
              </nav>
            </div>
            <br />

            <div className="row m-b-20">
              <div className="col-xl-4 col-lg-5 col-md-4 col-sm-5 tope">
                <input
                  value={this.state.recherche}
                  type="search"
                  className="recherche"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  onChange={(e) =>
                    this.setState({
                      recherche: e.target.value,
                    })
                  }
                />
              </div>

              {/* <button
                onClick={this.open}
                className="btn-add-groupe "
              >
                <NavLink className="add-group">
                  Ajouter{" "}
                  <Icon className="icon-add-groupe ml-2" icon="plus-circle" />
                </NavLink>
              </button> */}

              <button
                onClick={this.open}
                className="btn-archive col-md-3 d-flex flex-row-reverse mr-3 offset-2 tope"
              >
                <NavLink className="add-group">
                  Ajouter{" "}
                  <Icon className="icon-add-groupe ml-2" icon="plus-circle" />
                </NavLink>
              </button>
              <button
                className="btn-archive col-md-2 d-flex flex-row-reverse mr-3 tope"
                // data-target={"/syllabus-archives"}
              >
                <a href={"/syllabus-archives"} className="add-group">
                  Archives{" "}
                  <Icon className="icon-add-groupe ml-2" icon="archive" />
                </a>
              </button>

              <SweetAlert
                show={this.state.showAlertSuccess}
                onConfirm={() => this.setState({ showAlertSuccess: false })}
                success
                title="Syllabus ajouté avec succes!!!"
                style={{
                  color: "#069A77",
                  fontSize: "12px",
                  fontFamily: "Work Sans, sans-serif",
                  paddingTop: 60,
                  paddingBottom: 40,
                }}
                confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
              />
              <SweetAlert
                show={this.state.showAlertError}
                onConfirm={() => this.setState({ showAlertError: false })}
                error
                title="Erreur sur l'ajout de syllabus, veuillez reessayer!!!"
                style={{
                  color: "#d9534f",
                  fontSize: "12px",
                  fontFamily: "Work Sans, sans-serif",
                  paddingTop: 60,
                  paddingBottom: 40,
                }}
                confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
              />

              <Modal
                className="modal-responsive-sm p-b-50"
                show={this.state.show}
                onHide={this.close}
              >
                <h5 className="modal-bakeliste-title m-b-50">
                  Créer un Syllabus
                </h5>
                <button
                  className="close-modal-coach-title"
                  onClick={this.close}
                >
                  <FeatherIcon icon="x" aria-hidden="true" />
                </button>
                <form
                  //encType="multipart/form-data"
                  id="syllabus_form"
                  onSubmit={(e) => this.submitAddSyllabus(e)}
                >
                  <div className="form-group form-group-add-syllabus">
                    <label for="nomSyllabus" className="label-add-syllabus">
                      Nom du syllabus
                    </label>
                    <input
                      type="text"
                      name="syllabus_name"
                      onChange={this.addHandleChange}
                      className="form-control input-add-syllabus"
                      id="nomSyllabus"
                      placeholder="Nom du syllabus"
                    />
                    {this.state.sname_empty && (
                      <div className="errorMsg">
                        {this.state.errors.sname_empty}
                      </div>
                    )}
                  </div>
                  <div className="form-group form-group-add-syllabus">
                    <label
                      for="descriptionSyllabus"
                      className="label-add-syllabus"
                    >
                      Description du syllabus
                    </label>
                    <textarea
                      className="form-control input-add-syllabus"
                      rows="6"
                      id="descriptionSyllabus"
                      onChange={this.addHandleChange}
                      name="syllabus_description"
                      aria-describedby="descriptionHelp"
                      placeholder="Description du syllabus"
                    />{" "}
                    {this.state.remaining_char >= 31 && (
                      <span className="restant_container_sup_30">
                        {" "}
                        {this.state.remaining_char} caractéres restants
                      </span>
                    )}
                    {this.state.remaining_char <= 30 &&
                      this.state.remaining_char > 1 && (
                        <span className="restant_container_inf_30">
                          {" "}
                          {this.state.remaining_char} caractéres restants
                        </span>
                      )}
                    {this.state.remaining_char <= 1 && (
                      <span className="restant_container_inf_30">
                        {" "}
                        {this.state.remaining_char} caractére restant
                      </span>
                    )}
                    {this.state.sdescription_empty && (
                      <div className="errorMsg">
                        {this.state.errors.sdescription_empty}
                      </div>
                    )}{" "}
                  </div>
                  <div className="form-group form-group-add-syllabus">
                    <label for="domaineSyllabus" className="label-add-syllabus">
                      Domaines
                    </label>{" "}
                    <select
                      name="syllabus_domaine_id"
                      onChange={this.addHandleChange}
                      className="form-control input_item"
                      id="syllabus_domaine_id"
                    >
                      <option> --Choisir un domaine-- </option>
                      {this.state.domaines.map((item, index) => (
                        <option key={index} value={item.id}>
                          {" "}
                          {item.name}{" "}
                        </option>
                      ))}
                    </select>
                    {this.state.sdomaineid_empty && (
                      <div className="errorMsg">
                        {this.state.errors.sdomaineid_empty}
                      </div>
                    )}
                  </div>

                  <div className="form-group form-group-add-syllabus">
                    <div className="form-group form-group-add-syllabus">
                      <label
                        for="outilsSyllabus"
                        className="label-add-syllabus"
                      >
                        Outils
                      </label>
                      <MultiSelect
                        overrideStrings={{
                          selectSomeItems: "Choisissez les outils",
                          allItemsAreSelected:
                            "Tous vos outils ont été choisis",
                          selectAll: "Sélectionnez tout",
                          search: "Rechercher",
                        }}
                        options={this.state.selectOptions2}
                        selected={this.state.selectedtools}
                        onSelectedChanged={(selectedtools) =>
                          this.setState({ selectedtools })
                        }
                      />
                    </div>
                    {this.state.stool_empty && (
                      <div className="errorMsg">
                        {this.state.errors.stool_empty}
                      </div>
                    )}
                  </div>

                  <div className="form-group form-group-add-syllabus">
                    <div className="form-group form-group-add-syllabus">
                      <label
                        htmlFor="syllabus_level"
                        className="label-add-syllabus"
                      >
                        Niveau ciblé
                      </label>{" "}
                      <br />
                      <span className="radio_button_container">
                        <input
                          type="radio"
                          value="débutant"
                          name="syllabus_level"
                          onChange={this.addHandleChange}
                          className="input_item"
                        />
                        &nbsp;{" "}
                        <span className="radio_button_label"> Débutant </span>
                      </span>
                      <span className="radio_button_container">
                        <input
                          type="radio"
                          value="intermédiaire"
                          name="syllabus_level"
                          onChange={this.addHandleChange}
                          className="input_item"
                        />
                        &nbsp;{" "}
                        <span className="radio_button_label">
                          {" "}
                          Intermédiaire{" "}
                        </span>
                      </span>
                      <span className="radio_button_container">
                        <input
                          type="radio"
                          value="avancé"
                          name="syllabus_level"
                          onChange={this.addHandleChange}
                          className="input_item"
                        />
                        &nbsp;{" "}
                        <span className="radio_button_label"> Avancé </span>
                      </span>
                      {this.state.slevel_empty && (
                        <div className="errorMsg">
                          {this.state.errors.slevel_empty}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group form-group-add-syllabus">
                    <label
                      for="LangagesFrameworkSyllabus"
                      className="label-add-syllabus"
                    >
                      Langages/framework
                    </label>{" "}
                    <MultiSelect
                      overrideStrings={{
                        selectSomeItems: "Choisissez les technologies",
                        allItemsAreSelected:
                          "Tous vos technologies ont été choisis",
                        selectAll: "Sélectionnez tout",
                        search: "Rechercher",
                      }}
                      options={this.state.selectOptions1}
                      selected={this.state.selected}
                      onSelectedChanged={(selected) =>
                        this.setState({ selected })
                      }
                    />
                  </div>
                  {this.state.technologie_id_empty && (
                    <div className="errorMsg">
                      {this.state.errors.technologie_id_empty}
                    </div>
                  )}

                  {this.state.addSyllabusErrorAlert && (
                    <div className="row assignation-error">
                      <div className="col-md-12 error-message-alert">
                        Un problème est survenu lors de l'ajout. Veuillez
                        réessayer plus tard.
                      </div>
                    </div>
                  )}
                  <div className="m-t-40 dis-flex justify-content-center">
                    <div className="col-sm-5">
                      {" "}
                      {this.state.addSyllabusInProgress ? (
                        <button
                          type="button"
                          className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                          disabled
                        >
                          Ajout en cours &nbsp;
                          <FeatherIcon icon="loader" aria-hidden="true" />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn-theme w-full fs-13"
                        >
                          <span> Ajouter </span> &nbsp;
                          <img
                            className="submit_icon"
                            src="https://img.icons8.com/material-rounded/24/FFFFFF/filled-sent.png"
                            alt="Ajouter"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </Modal>
            </div>

            <div className="row px-lg-0 px-md-2"></div>
            <div className="row px-lg-0 px-md-2">
              <div
                classname="col-md-2"
                onChange={(event) => this.setNavall(event)}
              >
                <div className="radiobtn">
                  <input
                    defaultChecked
                    type="radio"
                    value="all"
                    name="domaine"
                    id="all"
                    data-target="#all-syllabus"
                  />
                  <label for="all">All</label>
                </div>
              </div>
              {this.state.domaines.map((item, index) => (
                <div
                  classname="col"
                  onChange={(event) => this.setNavTabs(event)}
                >
                  <div className="radiobtn">
                    <input
                      key={index}
                      type="radio"
                      value={item.name}
                      name="domaine"
                      id={item.id}
                      data-target="#filter-syllabus"
                    />
                    <label for={item.id}>{item.name}</label>
                  </div>
                </div>
              ))}
            </div>
            <div className=" px-lg-0 px-md-2">
              <InfiniteScroll
                className="hello"
                dataLength={this.state.syllabusDatas.length}
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                loader={
                  this.state.loading &&
                  "Les donnees sont entrain de se charger...."
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Vous avez tous visualise!!!</b>
                  </p>
                }
              > 
                {this.state.syllabusDatas.length > 0 &&
                this.state.tab_all_syllabus ? (
                  <div classname="row" id="all-syllabus">
                    {this.getSyllabusData().map((item, index) => (
                      <div
                        key={index}
                        className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2"
                      >
                        <SyllabusItemCoachAssistant
                          niveau={item.syllabus_level}
                          name={item.syllabus_name}
                          description={item.syllabus_description}
                          technologies={item.technologies}
                          syllabus_id={item.id}
                          outils={item.tools}
                          // groupes={item.assignationsgroupes.length}
                          nbreBakeliste={
                            item.assignations !== undefined &&
                            item.assignations.length
                          }
                          nbreTache={
                            item.details !== undefined && item.details.length
                          }
                          ongetSyllabus={this.onGetSyllabus}
                          created_by={item.created_by}
                          syllabus_domaine_id={item.syllabus_domaine_id}
                          user_coach_id={this.state.coach_id}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="tab-pane active" id="filter-syllabus">
                    {this.state.syllabusDomaineDatas.map((item, index) => (
                      <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
                        <SyllabusItemCoachAssistant
                          niveau={item.syllabus_level}
                          name={item.syllabus_name}
                          description={item.syllabus_description}
                          technologies={item.technologies}
                          syllabus_id={item.id}
                          outils={item.tools}
                          nbreBakeliste={item.assignations.length}
                          nbreTache={item.details.length}
                          // groupes={item.assignationsgroupes.length > 0 && item.assignationsgroupes.length }
                          ongetSyllabus={this.onGetSyllabus}
                          created_by={item.created_by}
                          syllabus_domaine_id={item.syllabus_domaine_id}
                          user_coach_id={this.state.coach_id}
                        />
                      </div>
                    ))}
                  </div>
                )}

               
              </InfiniteScroll>
              
            </div>
          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteGroupItemForCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ syllabusCoach }) => ({ ...syllabusCoach }),
//     dispatch => bindActionCreators({ ...syllabusCoachActions }, dispatch)
//   )( syllabusCoach );
