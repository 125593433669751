import React, { Component } from "react";
import "./listeCoachView.css";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";
import ListeCoach from "../listeCoach/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listeCoachViewActions from "../../store/listeCoachView/actions";
export default class listeCoachView extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoach />
            </div>

            <div className="col-lg-10 pt-3">
              {/* {this.state.loading ? (
                  <div>
   <Preloader />
                </div>
              )
                 
                : <> */}
              <ListeCoach />
              {/* </>}  */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ listeCoachView }) => ({ ...listeCoachView }),
//     dispatch => bindActionCreators({ ...listeCoachViewActions }, dispatch)
//   )( listeCoachView );
