import React, { Component } from "react";
import "./ajoutCvBakeliste.css";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItem from "../bakelisteGroupItem/index";
import NextReunionItem from "../nextReunionItem/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import ModalContactUs from "../modalContactUs/index";
import { Accordion, Card, Icon } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import API from "../../variablesGlobales";
import { Modal, Tooltip } from "rsuite";
export default class ajoutCvBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cvthequeID: 0,
      bakelisteID: window.sessionStorage.getItem("user_id"),
      api: API.API,
      isRecruitable: false,
      cv: [],
      isCvthequeEmpty: false,
      isProjectEmpty: false,
      isEducationEmpty: false,
      isPortfolioEmpty: false,
      isTecnologyEmpty: false,
      isLanguageEmpty: false,
      isEmptyCV: false,
      cvinfos: [],
      cvprojects: [],
      cveducation: [],
      cvportfolio: [],
      cvtechnology: [],
      cvlanguague: [],
      showProjectForm: false,

      showProjectForm2: false,
      fields: {},
      cvtheque_data: {},
      isAddProjectInProgress: false,
      isSuccessAddItemAlert: false,
      isAddRequestError: false,
      showTacheForm: false,
      showProjectForm2Btn: true,
      showTacheFormBtn: true,
      id_cv: "",
      isAddTacheInProgress: false,
      isTacheRequestError: false,
      showEducationForm: false,
      showEducationFormBtn: false,
      isAddEducationInProgress: false,
      showEducationForm2Btn: true,
      showEducationForm2: false,
      showEducationForm3Btn: false,
      showPortfolioForm: false,
      showPortfolioFormBtn: false,
      isAddPortfolioInProgress: false,
      showPortfolioForm2Btn: true,
      showPortfolioForm2: false,
      imagePreviewUrl: false,
      image: "",
      imgPathValue: "",
      idValue: 0,
      portProjectName: "",

      showMotivationForm: false,
      showMotivationFormBtn: false,
      showMotivationForm2: false,
      showMotivationForm2Btn: true,
      isAUMotivationInProgress: false,
      cvMotivationsValue: "",

      showTechnoForm: false,
      showTechnoFormBtn: false,
      showTechnoForm2: false,
      showTechnoForm2Btn: true,
      isAddTechnoInProgress: false,

      showLangForm: false,
      showLangFormBtn: false,
      showLangForm2: false,
      showLangForm2Btn: true,
      isAddLangInProgress: false,
      isPending: false,
      formEducation: false,
      updateEducation: {
        diplome: "",
        school: "",
        ville: "",
        pays: "",
        diplome_label: "",
      },
      idEdu: "",
      showEditModal: false,
      showProjetModal: false,
      showTacheModal: false,
      size: "md",
      project_technologies: "",
      project_name: "",
      id_projet: "",
      tache_content: "",
      id_tache: "",
      project_id: "",
      showTechModal: false,
      techno_percent: "",
      techno_name: "",
      id_tech: "",
      language_name: "",
      languague_label: "",
      id_lang: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitCvTheque = this.handleSubmitCvTheque.bind(this);
    this.handleSubmitNewProject = this.handleSubmitNewProject.bind(this);
    this.handleSubmitNewTache = this.handleSubmitNewTache.bind(this);
    this.SubmitNewEducation = this.SubmitNewEducation.bind(this);
    this.handleSubmitNewPortfolio = this.handleSubmitNewPortfolio.bind(this);
    this.SubmitMotivation = this.SubmitMotivation.bind(this);
    this.handleChangeUpdateMotivation =
      this.handleChangeUpdateMotivation.bind(this);
    this.hanldeSubmitTechno = this.hanldeSubmitTechno.bind(this);
    this.hanldeSubmitLang = this.hanldeSubmitLang.bind(this);
    this.handleChangeCvTheque = this.handleChangeCvTheque.bind(this);
  }

  componentDidMount() {
    // let token = window.sessionStorage.getItem('token');
    // let userStatus = window.sessionStorage.getItem('user_status');
    // let userID = window.sessionStorage.getItem('user_id');

    // if (userStatus === 'bakeliste') {
    //   this.setState({
    //     bakelisteID: userID
    //   })
    // }

    this.getBakelisteCV(this.state.bakelisteID);
  }

  getBakelisteCV = (bakeliste_id) => {
    axios
      .get(this.state.api + "cvtheques/" + bakeliste_id)
      .then((response) => {
        if (response.data.success === true) {
          //var data = this.state.cv;
          //var data = [];
          if (response.data.cvthequeData.length === 0) {
            this.setState({
              isCvthequeEmpty: true,
            });
          } else {
            this.setState({
              cvinfos: response.data.cvthequeData,
              id_cv: response.data.cvthequeData.id,
              cvMotivationsValue: response.data.cvthequeData.cv_motivations,
            });
          }
          // console.log(this.state.id_cv);
          if (response.data.cvprojectsData.length === 0) {
            this.setState({
              isProjectEmpty: true,
            });
          } else {
            this.setState({
              cvprojects: response.data.cvprojectsData,
            });
            // console.log(this.state.cvprojects);
          }
          if (response.data.cveducationData.length === 0) {
            this.setState({
              isEducationEmpty: true,
            });
          } else {
            this.setState({
              cveducation: response.data.cveducationData,
            });
            //console.log(response.data.cveducationData)
          }
          if (response.data.cvportfolioData.length === 0) {
            this.setState({
              isPortfolioEmpty: true,
            });
          } else {
            this.setState({
              cvportfolio: response.data.cvportfolioData,
            });
            // console.log(response.data.cvportfolioData);
          }
          if (response.data.cvtechnologiesData.length === 0) {
            this.setState({
              isTecnologyEmpty: true,
            });
          } else {
            this.setState({
              cvtechnology: response.data.cvtechnologiesData,
            });

            //console.log(response.data.cvtechnologiesData)
          }
          if (response.data.cvlanguagesData.length === 0) {
            this.setState({
              isLanguageEmpty: true,
            });
          } else {
            this.setState({
              cvlanguague: response.data.cvlanguagesData,
            });
          }
        } else {
          this.setState({
            isEmptyCV: true,
            isCvthequeEmpty: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // handleChange cv theque

  handleChangeCvTheque = (e) => {
    let cvtheque_data = this.state.cvtheque_data;
    cvtheque_data[e.target.name] = e.target.value;
    cvtheque_data["bakeliste_id"] = this.state.bakelisteID;
    cvtheque_data["is_hired"] = 1;
    this.setState({
      cvtheque_data: cvtheque_data,
    });

    console.log("cv thecque data", this.state.cvtheque_data);
  };
  handleSubmitCvTheque(e) {
    e.preventDefault();

    this.setState({
      isPending: true,
    });

    let data = this.state.cvtheque_data;
    axios
      .post(this.state.api + "cvtheques", data)
      .then((response) => {
        this.setState({
          isPending: false,
        });
        if (response.data.success) {
          this.setState({ successRegister: true });
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error.message);
        /* this.setState({
                  errorprogresseAlert: true
                }) */
        //this.handleSubmitlError('errorprogresseAlert')
      });
  }
  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === "successAddItem") {
        this.setState({
          isSuccessAddItemAlert: false,
        });
      }
      if (label === "errorAddItem") {
        this.setState({
          isAddRequestError: false,
        });
      }
    }, 5000);
  }

  addNewProjectForm = (e, label) => {
    e.preventDefault();
    if (label === "pFirstForm") {
      this.setState({
        showProjectForm: true,
      });
    }
    if (label === "pSecondForm") {
      this.setState({
        showProjectForm2: true,
        showProjectForm2Btn: false,
        showTacheFormBtn: false,
      });
    }
  };

  addNewTacheForm = (e) => {
    e.preventDefault();
    this.setState({
      showTacheForm: true,
      showProjectForm2Btn: false,
      showTacheFormBtn: false,
    });
  };

  /**
   * Switcher method for showing education form
   */
  addNewEducationForm = (e, label) => {
    e.preventDefault();
    if (label === "eFirstForm") {
      this.setState({
        showEducationForm: true,
        showEducationFormBtn: false,
      });
    }
    if (label === "eSecondForm") {
      this.setState({
        showEducationForm2: true,
        showEducationForm2Btn: false,
      });
    }
  };

  /**
   * Switcher method for showing portfolio form
   */
  addNewPortfolioForm = (e, label) => {
    e.preventDefault();
    if (label === "poFirstForm") {
      this.setState({
        showPortfolioForm: true,
        showPortfolioFormBtn: false,
      });
    }
    if (label === "poSecondForm") {
      this.setState({
        showPortfolioForm2: true,
        showPortfolioForm2Btn: false,
      });
    }
  };

  /**
   * Switcher method for showing motivations form
   */
  addMotivationForm = (e, label, motivation) => {
    e.preventDefault();
    if (label === "moFirstForm") {
      this.setState({
        showMotivationForm: true,
        showMotivationFormBtn: false,
      });
    }
    if (label === "moSecondForm") {
      this.setState({
        showMotivationForm2: true,
        showMotivationForm2Btn: false,
        cvMotivationsValue: motivation,
      });
    }
  };

  /**
   * Switcher method for showing techno form
   */
  addTechnoForm = (e, label) => {
    e.preventDefault();
    if (label === "teFirstForm") {
      this.setState({
        showTechnoForm: true,
        showTechnoFormBtn: false,
      });
    }
    if (label === "teSecondForm") {
      this.setState({
        showTechnoForm2: true,
        showTechnoForm2Btn: false,
      });
    }
  };

  /**
   * Switcher method for showing languague form
   */
  addLangForm = (e, label) => {
    e.preventDefault();
    if (label === "laFirstForm") {
      this.setState({
        showLangForm: true,
        showLangFormBtn: false,
      });
    }
    if (label === "laSecondForm") {
      this.setState({
        showLangForm2: true,
        showLangForm2Btn: false,
      });
    }
  };

  hideItemForm = (e) => {
    e.preventDefault();
    this.setState({
      showProjectForm: false,
      showProjectForm2Btn: true,
      showProjectForm2: false,

      showTacheFormBtn: true,
      showTacheForm: false,

      showEducationForm: false,
      showEducationForm2: false,
      showEducationForm2Btn: true,

      showPortfolioForm: false,
      showPortfolioForm2: false,
      showPortfolioForm2Btn: true,

      showMotivationForm: false,
      showMotivationForm2: false,
      showMotivationForm2Btn: true,

      showTechnoForm: false,
      showTechnoForm2: false,
      showTechnoForm2Btn: true,

      showLangForm: false,
      showLangForm2: false,
      showLangForm2Btn: true,
    });
  };

  /**
   * Handle add form
   */
  handleChange = (e) => {
    let fields = this.state.fields;
    fields["cvtheque_id"] = this.state.cvinfos.id;
    fields["action"] = "add";
    if (e.target.name === "img_path") {
      console.log(e.target.files);
      this.createImage(e.target.files[0]);
      fields["img_path"] = e.target.files[0];
    } else {
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields,
    });

    console.log(fields);
  };

  createImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image: e.target.result,
      });
    };
    // console.log(this.state.image);
    reader.readAsDataURL(file);
  };

  /**
   * Submit new project form
   */
  handleSubmitNewProject = (e) => {
    e.preventDefault();
    this.setState({
      isAddProjectInProgress: true,
    });
    let newProject = this.state.fields;
    this.setState({
      fields: {},
    });
    axios
      .post(this.state.api + "cvprojects", newProject)
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            isProjectEmpty: false, // déclarer projet non vide
            showProjectForm: false, // cacher le formulaire d'ajout
            showProjectForm2: false, // cacher le deuxième formulaire d'ajout
            showProjectForm2Btn: true, // afficher le bouton switcheur
            showTacheFormBtn: true, // afficher bouton switcheur
            isAddProjectInProgress: false, // cacher l'action en cours
            isSuccessAddItemAlert: true, // afficher le toast de confirmation
          });
          this.getBakelisteCV(this.state.bakelisteID); // raffraîchir les données
          this.handleShowAndHideAlert("successAddItem");
        } else {
          this.setState({
            isAddProjectInProgress: false, // cacher l'action en cours
            isAddRequestError: true, // afficher le message d'erreur
          });
          this.handleShowAndHideAlert("errorAddItem");
        }
      })
      .catch((error) => {
        this.setState({
          isAddProjectInProgress: false, // cacher l'action en cours
          isAddRequestError: true, // afficher le message d'erreur
        });
        console.log(error);
        this.handleShowAndHideAlert("errorAddItem");
      });
    console.log(this.state.fields);
  };

  /**
   * Submit add new tache form
   */
  handleSubmitNewTache = (e) => {
    e.preventDefault();
    this.setState({
      isAddTacheInProgress: true, // activer l'action en cours
    });
    e.target.reset();

    let newTache = this.state.fields; // recupération des données du formulaire

    axios
      .post(this.state.api + "cvtaches", newTache)
      .then((response) => {
        // return back de la requête
        if (response.data.success === true) {
          console.log(response);
          // return back égal à true
          // document.getElementById
          // $.reset("addTacheForm"); // vider le formulaire
          this.setState({
            isAddTacheInProgress: false, // cacher l'action en cours
            isSuccessAddItemAlert: true, // afficher le toast de confirmation
            fields: {},
          });
          this.getBakelisteCV(this.state.bakelisteID); // raffraichissement des données
          this.handleShowAndHideAlert("successAddItem");
        } else {
          // console.log(response);
          this.setState({
            isAddTacheInProgress: false, // cacher l'action en cours
            isAddRequestError: true, // afficher le message d'erreur
          });
          this.handleShowAndHideAlert("errorAddItem");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isAddTacheInProgress: false, // cacher l'action en cours
          isAddRequestError: true, // afficher le message d'erreur
        });
        this.handleShowAndHideAlert("errorAddItem");
      });
  };

  /**
   * Submit new education
   */
  SubmitNewEducation = (e, label) => {
    e.preventDefault();
    this.setState({
      isAddEducationInProgress: true, // submit en cours
    });
    let newEducation = this.state.fields; // recupération des données du formulaire

    axios
      .post(this.state.api + "cveducations", newEducation)
      .then((response) => {
        // return back du serveur
        if (response.data.success === true) {
          // submit action effectuée
          this.getBakelisteCV(this.state.bakelisteID); // raffraichissement des données
          document.getElementById("addNewEducationForm").reset(); // reset du formulaire
          this.setState({
            isEducationEmpty: false, // education non vide
            isAddEducationInProgress: false, // désactiver ajout en cours
            isSuccessAddItemAlert: true, // toast de confirmation
            showEducationForm: false, // cacher le formulaire
            fields: {},
          });
          if (label === "first") {
            // test si c'est le premier ajout
            this.setState({
              showEducationForm2Btn: true, // affichage du bouton switcher
            });
          } else if (label === "second") {
            // test si c'est ajout supplémentaire
            this.setState({
              showEducationForm2Btn: false, // cacher le bouton switcher
            });
          }
          this.handleShowAndHideAlert("successAddItem"); // cacher le toast de confirmation
        } else {
          console.log(response);
          this.setState({
            isAddEducationInProgress: false, // désactiver ajout en cours
            isAddRequestError: true, // affcihage de l'erreur
          });
          this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isAddEducationInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  /**
   * Submit new portfolio item
   */
  handleSubmitNewPortfolio = (e, label) => {
    e.preventDefault();
    this.setState({
      isAddPortfolioInProgress: true,
    });

    const newPortfolio = new FormData();
    newPortfolio.append("img_path", this.state.fields.img_path);
    newPortfolio.append("cvtheque_id", this.state.fields.cvtheque_id);
    newPortfolio.append("project_id", this.state.fields.project_id);

    axios
      .post(this.state.api + "cvportfolios", newPortfolio)
      .then((response) => {
        if (response.data.success === true) {
          this.getBakelisteCV(this.state.bakelisteID); // raffraichissement des données
          document.getElementById("addNewPortfolioForm").reset();
          this.setState({
            isPortfolioEmpty: false,
            isAddPortfolioInProgress: false,
            isSuccessAddItemAlert: true,
            showPortfolioForm: false,
            fields: {},
          });
          if (label === "first") {
            this.setState({
              showPortfolioForm2Btn: true,
            });
          } else if (label === "second") {
            this.setState({
              showPortfolioForm2Btn: false,
            });
          }
          this.handleShowAndHideAlert("successAddItem");
        } else {
          console.log(response);
          this.setState({
            isAddPortfolioInProgress: false,
            isAddRequestError: true,
          });
          this.handleShowAndHideAlert("errorAddItem");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isAddPortfolioInProgress: false,
          isAddRequestError: true,
        });
        this.handleShowAndHideAlert("errorAddItem");
      });
  };

  /**
   * Portfolio infos pour afficher l'image en taille réelle
   */
  getPortfolioInfos = (portfolio) => {
    this.setState({
      imgPathValue: portfolio.img_path,
      idValue: portfolio.id,
      portProjectName: portfolio.projectName,
    });
  };

  /**
   * Handle Change method for update motivations
   */
  handleChangeUpdateMotivation = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["action"] = "update";
    if (e.target.name === "cv_motivations") {
      this.setState({
        cvMotivationsValue: e.target.value,
      });
    }

    if (!fields["cv_motivations"]) {
      fields["cv_motivations"] = this.state.cvMotivationsValue;
    }

    this.setState({
      fields: fields,
    });

    console.log(fields);
  };

  /**
   * Handle submit bakeliste motivations
   */
  SubmitMotivation = (e, label) => {
    e.preventDefault();
    this.setState({
      isAUMotivationInProgress: true, // submit en cours
    });
    let motivations = this.state.fields; // recupération des données du formulaire
    console.log(motivations);

    axios
      .post(
        this.state.api +
          "bakeliste-add-cv-motivations/" +
          this.state.cvinfos.id,
        motivations
      )
      .then((response) => {
        // return back du serveur
        if (response.data.success === true) {
          // submit action effectuée
          this.getBakelisteCV(this.state.bakelisteID); // raffraichissement des données
          document.getElementById("addUpdateMotivationForm").reset(); // reset du formulaire
          this.setState({
            isAUMotivationInProgress: false, // désactiver ajout en cours
            isSuccessAddItemAlert: true, // toast de confirmation
            showMotivationForm: false, // cacher le formulaire
            fields: {},
          });
          if (label === "first") {
            // test si c'est le premier ajout
            this.setState({
              showMotivationForm2Btn: true, // affichage du bouton switcher
            });
          } else if (label === "second") {
            // test si c'est ajout supplémentaire
            this.setState({
              showMotivationForm2Btn: true, // cacher le bouton switcher
              showMotivationForm2: false, // cacher le formulaire de modification
            });
          }
          this.handleShowAndHideAlert("successAddItem"); // cacher le toast de confirmation
        } else {
          console.log(response);
          this.setState({
            isAUMotivationInProgress: false, // désactiver ajout en cours
            isAddRequestError: true, // affcihage de l'erreur
          });
          this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isAUMotivationInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  /**
   * Handle submit new techno
   */
  hanldeSubmitTechno = (e, label) => {
    e.preventDefault();
    this.setState({
      isAddTechnoInProgress: true, // submit en cours
    });
    let techno = this.state.fields; // recupération des données du formulaire

    axios
      .post(this.state.api + "cvtechnologies", techno)
      .then((response) => {
        // return back du serveur
        if (response.data.success === true) {
          // submit action effectuée
          this.getBakelisteCV(this.state.bakelisteID); // raffraichissement des données
          document.getElementById("addTechnoForm").reset(); // reset du formulaire
          this.setState({
            isTecnologyEmpty: false, // techno non vide
            isAddTechnoInProgress: false, // désactiver ajout en cours
            isSuccessAddItemAlert: true, // toast de confirmation
            showTechnoForm: false, // cacher le formulaire
            fields: {},
          });
          if (label === "first") {
            // test si c'est le premier ajout
            this.setState({
              showTechnoForm2Btn: true, // affichage du bouton switcher
            });
          } else if (label === "second") {
            // test si c'est ajout supplémentaire
            this.setState({
              showTechnoForm2Btn: false, // cacher le bouton switcher
            });
          }
          this.handleShowAndHideAlert("successAddItem"); // cacher le toast de confirmation
        } else {
          console.log(response);
          this.setState({
            isAddTechnoInProgress: false, // désactiver ajout en cours
            isAddRequestError: true, // affcihage de l'erreur
          });
          this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isAddTechnoInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  /**
   * Handle submit new lang
   */
  hanldeSubmitLang = (e, label) => {
    e.preventDefault();
    this.setState({
      isAddLangInProgress: true, // submit en cours
    });
    let lang = this.state.fields; // recupération des données du formulaire

    axios
      .post(this.state.api + "cvlanguages", lang)
      .then((response) => {
        // return back du serveur
        if (response.data.success === true) {
          // submit action effectuée
          this.getBakelisteCV(this.state.bakelisteID); // raffraichissement des données
          document.getElementById("addLangForm").reset(); // reset du formulaire
          this.setState({
            isLanguageEmpty: false, // language non vide
            isAddLangInProgress: false, // désactiver ajout en cours
            isSuccessAddItemAlert: true, // toast de confirmation
            showLangForm: false, // cacher le formulaire
            fields: {},
          });
          if (label === "first") {
            // test si c'est le premier ajout
            this.setState({
              showLangForm2Btn: true, // affichage du bouton switcher
            });
          } else if (label === "second") {
            // test si c'est ajout supplémentaire
            this.setState({
              showLangForm2Btn: false, // cacher le bouton switcher
            });
          }
          this.handleShowAndHideAlert("successAddItem"); // cacher le toast de confirmation
        } else {
          console.log(response);
          this.setState({
            isAddLangInProgress: false, // désactiver ajout en cours
            isAddRequestError: true, // affcihage de l'erreur
          });
          this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isAddLangInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };
  showFormEducation = () => {
    this.setState({
      isEducationEmpty: false,
      // showEducationForm : true,
      formEducation: true,
      showEducationForm2Btn: false,
    });
  };
  closeFormEducation = () => {
    this.setState({
      formEducation: false,
      showEducationForm2Btn: true,
    });
  };
  closeFormEducation = () => {
    this.setState({
      showEducationForm3Btn: false,
    });
  };

  editEducationModal = (educ) => {
    // this.onGetEducation(id);
    // console.log("educ", educ)
    this.setState({
      diplome: educ.diplome,
      school: educ.school,
      ville: educ.ville,
      pays: educ.pays,
      diplome_label: educ.diplome_label,
      idEdu: educ.id,
      showEditModal: true,
      annee_scolaire: educ.annee_scolaire,
    });
  };
  editProjetModal = (item) => {
    // this.onGetEducation(id);
    // console.log("educ", educ)
    this.setState({
      project_name: item.project_name,
      project_technologies: item.project_technologies,
      showProjetModal: true,
      id_projet: item.id,
    });
  };
  editTacheModal = (tache) => {
    // this.onGetEducation(id);
    // console.log("educ", tache)
    this.setState({
      tache_content: tache.tache_content,
      showTacheModal: true,
      id_tache: tache.id,
      project_id: tache.project_id,
      // id_projet: item.id,
    });
  };
  editTechModal = (techno) => {
    // this.onGetEducation(id);
    // console.log("educ", techno)
    this.setState({
      techno_name: techno.techno_name,
      showTechModal: true,
      techno_percent: techno.techno_percent,
      id_tech: techno.id,
      // id_projet: item.id,
    });
  };
  editLangModal = (lang) => {
    // this.onGetEducation(id);
    // console.log("educ", lang)
    this.setState({
      languague_label: lang.languague_label,
      showLangModal: true,
      language_name: lang.language_name,
      id_lang: lang.id,
      // id_projet: item.id,
    });
  };
  closeLang = () => {
    this.setState({
      showLangModal: false,
    });
  };
  closeTache = () => {
    this.setState({
      showTacheModal: false,
    });
  };
  close = () => {
    this.setState({
      showEditModal: false,
    });
  };
  closeProjet = () => {
    this.setState({
      showProjetModal: false,
    });
  };
  closeTech = () => {
    this.setState({
      showTechModal: false,
    });
  };
  handleUpdateChange = (e) => {
    let fields = this.state.fields;
    fields["cvtheque_id"] = this.state.cvinfos.id;
    fields["action"] = "add";
    if (e.target.name === "img_path") {
      console.log(e.target.files);
      this.createImage(e.target.files[0]);
      fields["img_path"] = e.target.files[0];
    } else {
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields,
    });

    console.log(fields);
  };

  updateEducation = (e, id) => {
    e.preventDefault();
    this.setState({
      updateEducationInProgress: true,
    });
    var fd = new FormData();
    fd.append("cvtheque_id", this.state.id_cv);
    fd.append("school", this.state.school);
    fd.append("annee_scolaire", this.state.annee_scolaire);
    fd.append("diplome", this.state.diplome);
    fd.append("diplome_label", this.state.diplome_label);
    fd.append("ville", this.state.ville);
    fd.append("pays", this.state.pays);

    axios
      .put(this.state.api + "cveducations/" + id, fd)
      .then((response) => {
        if (response.data.success === true) {
          this.getBakelisteCV(this.state.bakelisteID);
          this.setState({
            updateEducationInProgress: false,
            isSuccessAddItemAlert: true,
            showEditModal: false,
          });
          this.handleShowAndHideAlert("successAddItem");
        } else {
          this.setState({
            updateEducationInProgress: false,
            isAddRequestError: true,
          });
          this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          updateEducationInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };
  closeFormEducation2 = (e) => {
    this.setState({
      formEducation: false,
      showEducationForm2Btn: true,
    });
  };
  updateProjet = (e, id) => {
    e.preventDefault();
    this.setState({
      updateProjetInProgress: true,
    });
    var fd = new FormData();
    fd.append("cvtheque_id", this.state.id_cv);
    fd.append("project_name", this.state.project_name);
    fd.append("project_technologies", this.state.project_technologies);

    axios
      .put(this.state.api + "cvprojects/" + id, fd)
      .then((response) => {
        if (response.data.success === true) {
          this.getBakelisteCV(this.state.bakelisteID);
          this.setState({
            updateProjetInProgress: false,
            isSuccessAddItemAlert: true,
            showProjetModal: false,
          });
          this.handleShowAndHideAlert("successAddItem");
        } else {
          this.setState({
            updateProjetInProgress: false,
            isAddRequestError: true,
          });
          this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          updateProjetInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  updateTache = (e, id) => {
    e.preventDefault();
    this.setState({
      updateTacheInProgress: true,
    });
    var fd = new FormData();
    fd.append("cvtheque_id", this.state.id_cv);
    fd.append("tache_content", this.state.tache_content);
    fd.append("project_id", this.state.project_id);

    axios
      .put(this.state.api + "cvtaches/" + id, fd)
      .then((response) => {
        if (response.data.success === true) {
          this.getBakelisteCV(this.state.bakelisteID);
          this.setState({
            updateTacheInProgress: false,
            isSuccessAddItemAlert: true,
            showTacheModal: false,
          });
          this.handleShowAndHideAlert("successAddItem");
        } else {
          this.setState({
            updateTacheInProgress: false,
            isAddRequestError: true,
          });
          this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          updateTacheInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  updateTech = (e, id) => {
    e.preventDefault();
    this.setState({
      updateTechInProgress: true,
    });
    var fd = new FormData();
    fd.append("cvtheque_id", this.state.id_cv);
    fd.append("techno_name", this.state.techno_name);
    fd.append("techno_percent", this.state.techno_percent);

    axios
      .put(this.state.api + "cvtechnologies/" + id, fd)
      .then((response) => {
        if (response.data.success === true) {
          this.getBakelisteCV(this.state.bakelisteID);
          this.setState({
            updateTechInProgress: false,
            isSuccessAddItemAlert: true,
            showTechModal: false,
          });
          this.handleShowAndHideAlert("successAddItem");
        } else {
          this.setState({
            updateTechInProgress: false,
            isAddRequestError: true,
          });
          this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          updateTechInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };

  updateLang = (e, id) => {
    e.preventDefault();
    this.setState({
      updateLangInProgress: true,
    });
    var fd = new FormData();
    fd.append("cvtheque_id", this.state.id_cv);
    fd.append("language_name", this.state.language_name);
    fd.append("languague_label", this.state.languague_label);

    axios
      .put(this.state.api + "cvlanguages/" + id, fd)
      .then((response) => {
        if (response.data.success === true) {
          this.getBakelisteCV(this.state.bakelisteID);
          this.setState({
            updateLangInProgress: false,
            isSuccessAddItemAlert: true,
            showLangModal: false,
          });
          this.handleShowAndHideAlert("successAddItem");
        } else {
          this.setState({
            updateLangInProgress: false,
            isAddRequestError: true,
          });
          this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          updateLangInProgress: false, // désactiver ajout en cours
          isAddRequestError: true, // affcihage de l'erreur
        });
        this.handleShowAndHideAlert("errorAddItem"); // cacher le message d'alerte
      });
  };
  render() {
    return (
      <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item ">
                  <Link to="coach-dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Ajout CV
                </li>
              </ol>
            </nav>

            <div className="row m-b-15">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600">Ajout de CV</h6>
              </div>
              <div className="col dis-flex m-b-15">
                <ModalContactUs />
              </div>
            </div>

            <h6 className="h-theme ff-inter fw-600 h61">
              {" "}
              {this.state.cvinfos.cv_name}
            </h6>
            <div>
              <Accordion className="accordion">
                <Card className="carde">
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    variant="link"
                    className="according-text"
                  >
                    Education
                    <FeatherIcon
                      className="chevron offset-10"
                      icon="chevron-down"
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="bodyAcordion formrow">
                      {this.state.isEducationEmpty === true &&
                        this.state.showEducationForm === false && (
                          <div className="empty_container">
                            <div className="empty_education alert alert-warning">
                              Vous n'avez pas encore renseigné votre parcours
                              scolaire. Nous vous invitons à le faire.
                            </div>

                            <button
                              type="button"
                              className="col-md-4 offset-md-6 btn-theme"
                              onClick={(e) =>
                                this.showFormEducation(e, "first")
                              }
                            >
                              Ajouter
                            </button>
                          </div>
                        )}
                      {this.state.isEducationEmpty === false && (
                        <div className="list_educations_row ">
                          {this.state.cveducation.map((educ, i) => (
                            <div className="col-md-12 education_item_container">
                              <div className="row">
                                <div className="col-md-6">
                                  <span className="diplome_label">
                                    {" "}
                                    {educ.diplome_label},{" "}
                                  </span>
                                  <span className="school_container">
                                    {" "}
                                    {educ.school} -{" "}
                                  </span>
                                  <i className="diplome_container">
                                    {" "}
                                    {educ.diplome}{" "}
                                  </i>{" "}
                                </div>
                                <div className="col-md-6">
                                  <FeatherIcon
                                    className="edit-education offset-md-4"
                                    icon="edit-3"
                                    onClick={() =>
                                      this.editEducationModal(educ)
                                    }
                                  />
                                </div>
                              </div>
                              <br />
                              <span className="ville_pays">
                                {" "}
                                {educ.ville} - {educ.pays}{" "}
                              </span>
                              <hr />
                              <Modal
                                className="modal-responsive-md"
                                size={this.state.size}
                                show={this.state.showEditModal}
                                onHide={this.close}
                              >
                                <form
                                  onSubmit={(e) =>
                                    this.updateEducation(e, this.state.idEdu)
                                  }
                                >
                                  <Modal.Header>
                                    <Modal.Title className="modal-contact-us">
                                      Modifier Education{" "}
                                    </Modal.Title>
                                  </Modal.Header>

                                  <div className="row ">
                                    <div className="col-md-6">
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control fmCv"
                                          id="exampleInputEmail1"
                                          name="diplome"
                                          aria-describedby="emailHelp"
                                          placeholder="Diplôme:"
                                          onChange={(e) =>
                                            this.setState({
                                              diplome: e.target.value,
                                            })
                                          }
                                          value={this.state.diplome}
                                        />
                                      </div>
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control fmCv"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          placeholder="Ecole:"
                                          name="school"
                                          value={this.state.school}
                                          onChange={(e) =>
                                            this.setState({
                                              school: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control fmCv"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          placeholder="Ecole:"
                                          name="annee_scolaire"
                                          value={this.state.annee_scolaire}
                                          onChange={(e) =>
                                            this.setState({
                                              annee_scolaire: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control fmCv"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          placeholder="Ville:"
                                          name="ville"
                                          value={this.state.ville}
                                          onChange={(e) =>
                                            this.setState({
                                              ville: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control fmCv"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          placeholder="Pays d'obtention:"
                                          name="pays"
                                          value={this.state.pays}
                                          onChange={(e) =>
                                            this.setState({
                                              pays: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control fmCv"
                                          id="exampleInputEmail1"
                                          placeholder="Libellé du dipôme - Exemple : Génie Informatique"
                                          name="diplome_label"
                                          value={this.state.diplome_label}
                                          onChange={(e) =>
                                            this.setState({
                                              diplome_label: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      {this.state.updateEducationInProgress ? (
                                        <button
                                          type="submit"
                                          className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                                          disabled
                                        >
                                          Modification en cours &nbsp;
                                          <FeatherIcon
                                            icon="loader"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      ) : (
                                        <button
                                          type="submit"
                                          className=" col-md-4 btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                        >
                                          Valider
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </form>
                              </Modal>
                            </div>
                          ))}

                          {this.state.showEducationForm2Btn === true && (
                            <div className="add_form_btn_container">
                              <button
                                type="button"
                                className="col-md-4 offset-md-6 btn-theme"
                                onClick={(e) =>
                                  this.showFormEducation(e, "first")
                                }
                              >
                                Ajouter
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.formEducation === true && (
                        <form id="addNewEducationForm formrow">
                          <div className="row ">
                            <div className="col-md-6">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control fmCv"
                                  id="exampleInputEmail1"
                                  name="diplome"
                                  aria-describedby="emailHelp"
                                  placeholder="Diplôme:"
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control fmCv"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  name="annee_scolaire"
                                  placeholder="Année scolaire:"
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control fmCv"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Ecole:"
                                  name="school"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control fmCv"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Ville:"
                                  name="ville"
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control fmCv"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Pays d'obtention:"
                                  name="pays"
                                  onChange={this.handleChange}
                                />
                              </div>
                              <div class="form-group">
                                <input
                                  type="email"
                                  class="form-control fmCv"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Libellé du dipôme - Exemple : Génie Informatique"
                                  name="diplome_label"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              {this.state.isAddEducationInProgress === false ? (
                                <button
                                  type="button"
                                  className="col-md-6 offset-md-6 btn-theme"
                                  onClick={(e) =>
                                    this.SubmitNewEducation(e, "first")
                                  }
                                >
                                  Ajouter
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn-theme-orange"
                                  disabled
                                >
                                  Ajout en cours &nbsp;
                                  <FeatherIcon
                                    icon="loader"
                                    aria-hidden="true"
                                  />
                                </button>
                              )}
                            </div>
                            <div className="col-md-6">
                              <button
                                type="button"
                                className="col-md-6 offset-md-6 btn-theme-orange"
                                onClick={(e) => this.closeFormEducation2(e)}
                              >
                                Annuler
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>

            <div>
              <Accordion className="accordion">
                <Card className="carde">
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    variant="link"
                    className="according-text"
                  >
                    Projets &nbsp;&nbsp;&nbsp;&nbsp;
                    <FeatherIcon
                      className="chevron offset-10"
                      icon="chevron-down"
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="bodyAcordion">
                      {this.state.isProjectEmpty === true &&
                        this.state.showProjectForm === false && (
                          <div className="empty_container">
                            <div className="empty_projects alert alert-warning">
                              Vous n'avez pas encore renseigné vos projets. Nous
                              vous invitons à le faire.
                            </div>
                            <div className="add_form_btn_container">
                              <button
                                type="button"
                                className="col-md-4 offset-md-6 btn-theme"
                                onClick={(e) =>
                                  this.addNewProjectForm(e, "pFirstForm")
                                }
                              >
                                Ajouter
                              </button>
                            </div>
                          </div>
                          // {this.state.showProjectForm === true &&
                        )}
                      {this.state.showProjectForm === true && (
                        <div className="form_container row">
                          <div className="col-md-12">
                            <h5 className="new_project_title">
                              Nouveau projet
                            </h5>
                          </div>

                          <form
                            id="addNewProjetForm"
                            onSubmit={this.handleSubmitNewProject}
                          >
                            <div className="row formrow">
                              <div className="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control fmCv"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    name="project_name"
                                    placeholder="Nom du projet:"
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control fmCv"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Technologies:"
                                    name="project_technologies"
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                {this.state.isAddProjectInProgress === false ? (
                                  <button
                                    type="button"
                                    className="col-md-6 btn-theme"
                                    onClick={(e) =>
                                      this.handleSubmitNewProject(e, "first")
                                    }
                                  >
                                    Ajouter
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn-theme-orange"
                                    disabled
                                  >
                                    Ajout en cours &nbsp;
                                    <FeatherIcon
                                      icon="loader"
                                      aria-hidden="true"
                                    />
                                  </button>
                                )}
                                <button
                                  className="btn btn-theme-orange offset-md-2"
                                  onClick={(e) => this.hideItemForm(e)}
                                >
                                  Quitter
                                </button>
                              </div>
                              <div className="col-md-6"></div>
                            </div>
                          </form>
                        </div>
                      )}

                      {this.state.isProjectEmpty === false && (
                        <div className="list_projects_row">
                          {this.state.cvprojects.map((item, indexe) => (
                            <div
                              key={indexe}
                              className="list_projects_container col-md-12"
                            >
                              <div className="row">
                                <div className="col-md-6">
                                  <h5 className="h-theme ff-inter fw-600">
                                    Nom du projet : {item.project_name}{" "}
                                  </h5>
                                </div>
                                <div className="col-md-6">
                                  <FeatherIcon
                                    className="edit-education offset-md-2"
                                    icon="edit-3"
                                    onClick={() => this.editProjetModal(item)}
                                  />
                                </div>

                                <Modal
                                  className="modal-responsive-md"
                                  size={this.state.size}
                                  show={this.state.showProjetModal}
                                  onHide={this.closeProjet}
                                >
                                  <form
                                    onSubmit={(e) =>
                                      this.updateProjet(e, this.state.id_projet)
                                    }
                                  >
                                    <Modal.Header>
                                      <Modal.Title className="modal-contact-us">
                                        Modifier Projet{" "}
                                      </Modal.Title>
                                    </Modal.Header>

                                    <div className="row formrow">
                                      <div className="col-md-6">
                                        <div class="form-group">
                                          <input
                                            type="text"
                                            class="form-control fmCv"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            name="project_name"
                                            value={this.state.project_name}
                                            onChange={(e) =>
                                              this.setState({
                                                project_name: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div class="form-group">
                                          <input
                                            type="text"
                                            class="form-control fmCv"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            name="project_technologies"
                                            value={
                                              this.state.project_technologies
                                            }
                                            onChange={(e) =>
                                              this.setState({
                                                project_technologies:
                                                  e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6">
                                        {this.state.updateProjetInProgress ? (
                                          <button
                                            type="submit"
                                            className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                                            disabled
                                          >
                                            Modification en cours &nbsp;
                                            <FeatherIcon
                                              icon="loader"
                                              aria-hidden="true"
                                            />
                                          </button>
                                        ) : (
                                          <button
                                            type="submit"
                                            className=" col-md-4 btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                          >
                                            Valider
                                          </button>
                                        )}
                                      </div>
                                      <div className="col-md-6">
                                        <button
                                          type="submit"
                                          className="col-md-4 btn-theme-danger text-up m-t-30 w-full p-t-15 p-b-15"
                                          onClick={(e) => this.close(e)}
                                        >
                                          Annuler
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </Modal>
                              </div>
                              <h6 className="project_technologies_label">
                                {" "}
                                {item.project_technologies}{" "}
                              </h6>
                              {item.taches.length === 0 ? (
                                <div>
                                  <i>Veuillez ajouter une ou les tâche(s)</i>
                                </div>
                              ) : (
                                <div className="list_taches_container">
                                  <ul>
                                    {item.taches.map((tache, ind) => (
                                      <li className="tache_item">
                                        {" "}
                                        {tache.tache_content}{" "}
                                        <FeatherIcon
                                          className="edit-tache offset-md-2"
                                          icon="edit-2"
                                          onClick={() =>
                                            this.editTacheModal(tache)
                                          }
                                        />
                                        <Modal
                                          className="modal-responsive-md"
                                          size={this.state.size}
                                          show={this.state.showTacheModal}
                                          onHide={this.closeTache}
                                        >
                                          <form
                                            onSubmit={(e) =>
                                              this.updateTache(
                                                e,
                                                this.state.id_tache
                                              )
                                            }
                                          >
                                            <Modal.Header>
                                              <Modal.Title className="modal-contact-us">
                                                Modifier Tâche{" "}
                                              </Modal.Title>
                                            </Modal.Header>

                                            <textarea
                                              type="text"
                                              class="form-control fmCv"
                                              id="exampleInputEmail1"
                                              aria-describedby="emailHelp"
                                              name="tache_content"
                                              value={this.state.tache_content}
                                              onChange={(e) =>
                                                this.setState({
                                                  tache_content: e.target.value,
                                                })
                                              }
                                            />

                                            <div className="row">
                                              <div className="col-md-6">
                                                {this.state
                                                  .updateTacheInProgress ? (
                                                  <button
                                                    type="submit"
                                                    className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                                                    disabled
                                                  >
                                                    Modification en cours &nbsp;
                                                    <FeatherIcon
                                                      icon="loader"
                                                      aria-hidden="true"
                                                    />
                                                  </button>
                                                ) : (
                                                  <button
                                                    type="submit"
                                                    className=" col-md-4 btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                                  >
                                                    Valider
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </form>
                                        </Modal>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                              <hr />
                            </div>
                          ))}

                          <div className="row">
                            <div className="col-md-6 projects-btn">
                              {this.state.showProjectForm2Btn === true && (
                                <div className="add_form_btn_container">
                                  <button
                                    type="button"
                                    className="col-md-4 offset-md-6 btn-theme"
                                    onClick={(e) =>
                                      this.addNewProjectForm(e, "pSecondForm")
                                    }
                                  >
                                    Ajouter projet
                                  </button>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 taches-btn">
                              {this.state.showTacheFormBtn === true && (
                                <div className="add_tache_btn_container">
                                  <button
                                    type="button"
                                    className="col-md-4 offset-md-6 btn-theme-orange"
                                    onClick={(e) => this.addNewTacheForm(e)}
                                  >
                                    Ajouter Tâche
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          {this.state.showTacheForm === true && (
                            <div className="row tache_form_container">
                              <div className="col-md-12">
                                <h5 className="new_tache_title">
                                  Nouvelle tâche
                                </h5>
                              </div>
                              <div className="form_row">
                                <form
                                  onSubmit={this.handleSubmitNewTache}
                                  id="addTacheForm formrow"
                                >
                                  <div className="row">
                                    <div className="select_project col-md-6 form-group">
                                      <select
                                        className="form-control project_id_label"
                                        name="project_id"
                                        onChange={this.handleChange}
                                        required
                                      >
                                        <option>--Choisir un projet--</option>
                                        {this.state.cvprojects.map(
                                          (pr, indx) => (
                                            <option key={indx} value={pr.id}>
                                              {" "}
                                              {pr.project_name}{" "}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                    <div className="tache_content col-md-6 form-group">
                                      <textarea
                                        placeholder="Tâche effectuée"
                                        className="form-control formrow"
                                        name="tache_content"
                                        onChange={this.handleChange}
                                        required
                                      />
                                    </div>
                                  </div>

                                  {this.state.isAddRequestError && (
                                    <div className="error-row">
                                      <div className="col-md-12 error-container alert alert-danger">
                                        Une erreur est survenue. Veuillez
                                        réessayer plus tard.
                                      </div>
                                    </div>
                                  )}
                                  <div className="col-md-12 form-group">
                                    {this.state.isAddTacheInProgress ===
                                    false ? (
                                      <button className="btn btn-theme">
                                        Valider
                                      </button>
                                    ) : (
                                      <button className="btn btn-theme-orange">
                                        Ajout en cours
                                        <i className="fas fa-spin fa-spinner"></i>
                                      </button>
                                    )}
                                    <button
                                      className="btn btn-theme-orange offset-md-1"
                                      onClick={(e) => this.hideItemForm(e)}
                                    >
                                      Quitter
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.showProjectForm2 === true && (
                        <form id="addNewProjetForm">
                          <div className="row formrow">
                            <div className="col-md-6">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control fmCv"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  name="project_name"
                                  placeholder="Nom du projet:"
                                  onChange={this.handleChange}
                                />
                              </div>
                              {/* <div class="form-group">
                            <input
                              type="text"
                              class="form-control fmCv"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Tâche:"
                            />
                          </div> */}
                            </div>
                            <div className="col-md-6">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control fmCv"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="Technologies:"
                                  name="project_technologies"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              {this.state.isAddProjectInProgress === false ? (
                                <button
                                  type="button"
                                  className="col-md-6 btn-theme"
                                  onClick={(e) =>
                                    this.handleSubmitNewProject(e, "first")
                                  }
                                >
                                  Ajouter
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn-theme-orange"
                                  disabled
                                >
                                  Ajout en cours &nbsp;
                                  <FeatherIcon
                                    icon="loader"
                                    aria-hidden="true"
                                  />
                                </button>
                              )}
                              <button
                                className="btn btn-theme-orange offset-md-2"
                                onClick={(e) => this.hideItemForm(e)}
                              >
                                Quitter
                              </button>
                            </div>
                            <div className="col-md-6">
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
            <div>
              <Accordion className="accordion">
                <Card className="carde">
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    variant="link"
                    className="according-text"
                  >
                    Motivation
                    <FeatherIcon
                      className="chevron offset-10"
                      icon="chevron-down"
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="bodyAcordion">
                      {this.state.cvinfos.cv_motivations === null &&
                        this.state.showMotivationForm === false && (
                          <div className="empty_motivations_row">
                            <div className="col-md-12 error-alert-container alert alert-warning">
                              Vous n'avez pas encore écrit vos motivations. Nous
                              vous invitons à le faire. Merci !
                            </div>{" "}
                            <hr />
                            <div className="add_form_switcher_container">
                              <button
                                type="button"
                                className="offset-md-6 btn-theme"
                                onClick={(e) =>
                                  this.addMotivationForm(
                                    e,
                                    "moFirstForm",
                                    this.state.cvinfos.cv_motivations
                                  )
                                }
                              >
                                Ajouter
                              </button>
                            </div>
                          </div>
                        )}
                      {this.state.cvinfos.cv_motivations !== null && (
                        <div className="motivations_content_row row">
                          <div className="motivations_item_container">
                            <div className="col-md-12 motivations_item">
                              <p>{this.state.cvinfos.cv_motivations}</p>
                            </div>
                          </div>{" "}
                          <hr />
                          {this.state.showMotivationForm2Btn === true && (
                            <div className="add_form_btn_container col-md-12">
                              <button
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Modifier vos motivations"
                                className="btn-theme"
                                onClick={(e) =>
                                  this.addMotivationForm(
                                    e,
                                    "moSecondForm",
                                    this.state.cvinfos.cv_motivations
                                  )
                                }
                              >
                                {/* <i className="fas fa-edit"></i>
                                <FeatherIcon icon="edit"></FeatherIcon> */}
                                Modifier
                              </button>
                            </div>
                          )}
                        </div>
                      )}

                      {this.state.showMotivationForm === true && (
                        <form
                          id="addUpdateMotivationForm"
                          onSubmit={(e) => this.SubmitMotivation(e, "first")}
                        >
                          <div class="form-group formrow">
                            <textarea
                              type="text"
                              class="form-control fmTa"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Motivation:"
                              name="cv_motivations"
                              onChange={this.handleChange}
                            />
                          </div>

                          {this.state.isAUMotivationInProgress === false ? (
                            <button
                              type="button"
                              className="col-md-6 offset-md-6 btn-theme"
                            >
                              Valider
                            </button>
                          ) : (
                            <button className="btn btn-theme-orange pull-right">
                              Ajout en cours
                              <i className="fas fa-spin fa-spinner"></i>
                            </button>
                          )}
                        </form>
                      )}
                      {this.state.showMotivationForm2 === true && (
                        <div className="motivations_form_row row">
                          <div className="col-md-12 add_title">
                            <h5 className="h-theme ff-inter fw-600">
                              Modifier vos motivations{" "}
                            </h5>
                            <form
                              id="addUpdateMotivationForm"
                              onSubmit={(e) =>
                                this.SubmitMotivation(e, "second")
                              }
                            >
                              <div className="col-md-6 col-md-offset-3 form-group cvMotivation">
                                <textarea
                                  value={this.state.cvMotivationsValue}
                                  placeholder="Ajoutez vos motivations"
                                  name="cv_motivations"
                                  className="form-control cv_motivation_input"
                                  onChange={this.handleChangeUpdateMotivation}
                                />
                              </div>
                              {this.state.isAddRequestError && (
                                <div className="error-row">
                                  <div className="col-md-12 error-container alert alert-danger">
                                    Une erreur est survenue. Veuillez réessayer
                                    plus tard.
                                  </div>
                                </div>
                              )}
                              <div className="col-md-12 form-group">
                                <button
                                  className="btn btn-theme-danger pull-left volkeno-red-bcolor return-btn"
                                  onClick={(e) => this.hideItemForm(e)}
                                >
                                  Quitter
                                </button>
                                {this.state.isAUMotivationInProgress ===
                                false ? (
                                  <button className="btn btn-theme offset-md-4">
                                    Valider
                                  </button>
                                ) : (
                                  <button className="btn btn-theme-orange pull-right volkeno-orange-bcolor inprogress-btn">
                                    Modification en cours
                                    <i className="fas fa-spin fa-spinner"></i>
                                  </button>
                                )}
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
            <div>
              <Accordion className="accordion">
                <Card className="carde">
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    variant="link"
                    className="according-text"
                  >
                    Outils/Technologies
                    <FeatherIcon
                      className="chevron offset-9"
                      icon="chevron-down"
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="bodyAcordion">
                      <div className="techno_lang_row row">
                        <div className="col-md-6 techno_container">
                          <h6 className="text-center techno_lang_title">
                            {this.state.cvinfos.bakelisteDomaineName ===
                            "Programmation" ? (
                              <span>
                                <h6 className="h-theme ff-inter fw-600">
                                  Technologies
                                </h6>
                              </span>
                            ) : (
                              <span>
                                <h6 className="h-theme ff-inter fw-600">
                                  Outils
                                </h6>
                              </span>
                            )}
                          </h6>
                          {this.state.isTecnologyEmpty === true &&
                            this.state.showTechnoForm === false && (
                              // Render technology si les données sont nulles
                              <div className="empty_techno_row">
                                <div className="col-md-12 error-alert-container alert alert-warning">
                                  Vous n'avez pas encore fourni d'informations.
                                  Nous vous invitons à le faire. Merci !
                                </div>{" "}
                                <hr />
                                <div className="add_form_switcher_container">
                                  <button
                                    type="button"
                                    className="offset-md-6 btn-theme"
                                    onClick={(e) =>
                                      this.addTechnoForm(e, "teFirstForm")
                                    }
                                  >
                                    Ajouter
                                  </button>
                                </div>
                              </div>
                            )}

                          {/* Render premier formulaire d'ajout de techno ou outils */}
                          {this.state.showTechnoForm === true && (
                            <div className="techno_form_row row">
                              <div className="col-md-12 add_title">
                                <h5 className="add_techno_title text-center">
                                  Ajouter une technologies / Outils{" "}
                                </h5>
                                <form
                                  id="addTechnoForm"
                                  onSubmit={(e) =>
                                    this.hanldeSubmitTechno(e, "first")
                                  }
                                >
                                  <div className="row">
                                    <div className="col-md-6 form-group">
                                      <input
                                        type="text"
                                        placeholder="Ajoutez une technologie / Outils"
                                        name="techno_name"
                                        className="form-control fmCv"
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                    <div className="col-md-6 form-group ">
                                      <input
                                        type="text"
                                        placeholder="% de maîtrise. Exemple: 65"
                                        name="techno_percent"
                                        className="form-control fmCv"
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>

                                  {this.state.isAddRequestError && (
                                    <div className="error-row">
                                      <div className="col-md-12 error-container alert alert-danger">
                                        Une erreur est survenue. Veuillez
                                        réessayer plus tard.
                                      </div>
                                    </div>
                                  )}
                                  <div className="col-md-12 form-group">
                                    <button className="btn-theme pull-left volkeno-red-bcolor return-btn">
                                      Ajouter
                                    </button>
                                    {this.state.isAddTechnoInProgress ===
                                    false ? (
                                      <button
                                        type="button"
                                        className="offset-md-6 btn-theme-orange"
                                        onClick={(e) => this.hideItemForm(e)}
                                      >
                                        Quitter
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn-theme-orange"
                                      >
                                        <i className="fas fa-spin fa-spinner"></i>
                                      </button>
                                    )}
                                  </div>
                                </form>
                              </div>
                            </div>
                          )}

                          {/* Render techno si le champ n'est pas vide */}
                          {this.state.isTecnologyEmpty === false && (
                            <div className="techno_content_row row">
                              <div className="techno_item_container">
                                <div className="col-md-12 techno_item">
                                  <ul className="list_techno_ul">
                                    {this.state.cvtechnology.map(
                                      (techno, idx) => (
                                        <li key={idx} className="techno_li">
                                          {" "}
                                          {techno.techno_name}{" "}
                                          <FeatherIcon
                                            className="edit-tache offset-md-2"
                                            icon="edit-2"
                                            onClick={() =>
                                              this.editTechModal(techno)
                                            }
                                          />
                                          <Modal
                                            className="modal-responsive-md"
                                            size={this.state.size}
                                            show={this.state.showTechModal}
                                            onHide={this.closeTech}
                                          >
                                            <form
                                              onSubmit={(e) =>
                                                this.updateTech(
                                                  e,
                                                  this.state.id_tech
                                                )
                                              }
                                            >
                                              <Modal.Header>
                                                <Modal.Title className="modal-contact-us">
                                                  Modifier Technologie{" "}
                                                </Modal.Title>
                                              </Modal.Header>

                                              <div className="row">
                                                <div className="col-md-6 form-group">
                                                  <input
                                                    type="text"
                                                    name="techno_name"
                                                    className="form-control fmCv"
                                                    value={
                                                      this.state.techno_name
                                                    }
                                                    onChange={(e) =>
                                                      this.setState({
                                                        techno_name:
                                                          e.target.value,
                                                      })
                                                    }
                                                  />
                                                </div>
                                                <div className="col-md-6 form-group ">
                                                  <input
                                                    type="text"
                                                    name="techno_percent"
                                                    className="form-control fmCv"
                                                    value={
                                                      this.state.techno_percent
                                                    }
                                                    onChange={(e) =>
                                                      this.setState({
                                                        techno_percent:
                                                          e.target.value,
                                                      })
                                                    }
                                                  />
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-md-6">
                                                  {this.state
                                                    .updateTechInProgress ? (
                                                    <button
                                                      type="submit"
                                                      className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                                                      disabled
                                                    >
                                                      Modification en cours
                                                      &nbsp;
                                                      <FeatherIcon
                                                        icon="loader"
                                                        aria-hidden="true"
                                                      />
                                                    </button>
                                                  ) : (
                                                    <button
                                                      type="submit"
                                                      className=" col-md-4 btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                                    >
                                                      Valider
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                            </form>
                                          </Modal>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>{" "}
                              <hr />
                              {/* Add new techno show form button */}
                              {this.state.showTechnoForm2Btn === true && (
                                <div className="add_form_btn_container col-md-12">
                                  <button
                                    type="button"
                                    className="offset-md-6 btn-theme"
                                    onClick={(e) =>
                                      this.addTechnoForm(e, "teFirstForm")
                                    }
                                  >
                                    Ajouter
                                  </button>
                                </div>
                              )}
                              {/* Render ajout formulaire de techno */}
                              {this.state.showTechnoForm2 === true && (
                                <div className="motivations_form_row row">
                                  <div className="col-md-12 add_title">
                                    <h5 className="add_motivation_title text-center">
                                      Ajouter une technologie / outils{" "}
                                    </h5>
                                    <form
                                      id="addTechnoForm"
                                      onSubmit={(e) =>
                                        this.hanldeSubmitTechno(e, "second")
                                      }
                                    >
                                      <div className="row">
                                        <div className="col-md-6 form-group">
                                          <input
                                            type="text"
                                            placeholder="Ajoutez une technologie / Outils"
                                            name="techno_name"
                                            className="form-control fmCv"
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                        <div className="col-md-6 form-group techno">
                                          <input
                                            type="text"
                                            placeholder="% de maîtrise. Exemple: 65"
                                            name="techno_percent"
                                            className="form-control percent_input"
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                      </div>

                                      {this.state.isAddRequestError && (
                                        <div className="error-row">
                                          <div className="col-md-12 error-container alert alert-danger">
                                            Une erreur est survenue. Veuillez
                                            réessayer plus tard.
                                          </div>
                                        </div>
                                      )}
                                      <div className="col-md-12 form-group">
                                        <button
                                          className="btn btn-danger pull-left volkeno-red-bcolor return-btn"
                                          onClick={(e) => this.hideItemForm(e)}
                                        >
                                          Quitter
                                        </button>
                                        <button
                                          type="button"
                                          className="btn-theme-warning"
                                          onClick={(e) => this.hideItemForm(e)}
                                        >
                                          Quitter
                                        </button>
                                        {this.state.isAddTechnoInProgress ===
                                        false ? (
                                          <button
                                            type="button"
                                            className="btn-theme"
                                          >
                                            Ajouter
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn-theme-warning"
                                          >
                                            Ajout en cours
                                            <i className="fas fa-spin fa-spinner"></i>
                                          </button>
                                        )}
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>

                        <div className="col-md-6 lang_container">
                          <h6 className="h-theme ff-inter fw-600">Langues</h6>
                          {this.state.isLanguageEmpty === true &&
                            this.state.showLangForm === false && (
                              // Render technology si les données sont nulles
                              <div className="empty_lang_row">
                                <div className="col-md-12 error-alert-container alert alert-warning">
                                  Vous n'avez pas encore renseigné les langues
                                  que vous parlez. Nous vous invitons à le
                                  faire. Merci !
                                </div>{" "}
                                <hr />
                                <div className="add_form_switcher_container">
                                  <button
                                    type="button"
                                    className="btn-theme offset-md-6"
                                    onClick={(e) =>
                                      this.addLangForm(e, "laFirstForm")
                                    }
                                  >
                                    Ajouter
                                  </button>
                                </div>
                              </div>
                            )}

                          {/* Render premier formulaire d'ajout de langue */}
                          {this.state.showLangForm === true && (
                            <div className="techno_form_row row">
                              <div className="col-md-12 add_title">
                                <h5 className="add_techno_title text-center">
                                  Ajouter une langue{" "}
                                </h5>
                                <form id="addLangForm">
                                  <div class="row">
                                    <div className="col-md-4 form-group">
                                      <input
                                        type="text"
                                        placeholder="Langue"
                                        name="language_name"
                                        className="form-control fmCv"
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                    <div className="col-md-6 form-group">
                                      <input
                                        type="text"
                                        placeholder="Label. Exemple : Lu, parlé et écrit"
                                        name="languague_label"
                                        className="form-control fmCv"
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                  </div>

                                  {this.state.isAddRequestError && (
                                    <div className="error-row">
                                      <div className="col-md-12 error-container alert alert-danger">
                                        Une erreur est survenue. Veuillez
                                        réessayer plus tard.
                                      </div>
                                    </div>
                                  )}
                                  <div className="col-md-12 form-group">
                                    {this.state.isAddLangInProgress ===
                                    false ? (
                                      <button
                                        type="button"
                                        className="btn-theme"
                                        onClick={(e) =>
                                          this.hanldeSubmitLang(e, "first")
                                        }
                                      >
                                        Valider
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn-theme-warning"
                                      >
                                        Ajout en cours
                                        <i className="fas fa-spin fa-spinner"></i>
                                      </button>
                                    )}
                                    <button
                                      className="btn btn-theme-orange offset-md-4"
                                      onClick={(e) => this.hideItemForm(e)}
                                    >
                                      Quitter
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          )}

                          {/* Render lang si le champ n'est pas vide */}
                          {this.state.isLanguageEmpty === false && (
                            <div className="lang_content_row row">
                              <div className="lang_item_container">
                                <div className="col-md-12 lang_item">
                                  <ul className="list_lang_ul">
                                    {this.state.cvlanguague.map((lang, ix) => (
                                      <li key={ix} className="lang_li">
                                        {" "}
                                        {lang.language_name} ({" "}
                                        {lang.languague_label} ){" "}
                                        <FeatherIcon
                                          className="edit-tache offset-md-2"
                                          icon="edit-2"
                                          onClick={() =>
                                            this.editLangModal(lang)
                                          }
                                        />
                                        <Modal
                                          className="modal-responsive-md"
                                          size={this.state.size}
                                          show={this.state.showLangModal}
                                          onHide={this.closeLang}
                                        >
                                          <form
                                            onSubmit={(e) =>
                                              this.updateLang(
                                                e,
                                                this.state.id_lang
                                              )
                                            }
                                          >
                                            <Modal.Header>
                                              <Modal.Title className="modal-contact-us">
                                                Modifier Langues{" "}
                                              </Modal.Title>
                                            </Modal.Header>

                                            <div className="row">
                                              <div className="col-md-6 form-group">
                                                <input
                                                  type="text"
                                                  name="language_name"
                                                  className="form-control fmCv"
                                                  value={
                                                    this.state.language_name
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      language_name:
                                                        e.target.value,
                                                    })
                                                  }
                                                />
                                              </div>
                                              <div className="col-md-6 form-group ">
                                                <input
                                                  type="text"
                                                  name="languague_label"
                                                  className="form-control fmCv"
                                                  value={
                                                    this.state.languague_label
                                                  }
                                                  onChange={(e) =>
                                                    this.setState({
                                                      languague_label:
                                                        e.target.value,
                                                    })
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="row">
                                              <div className="col-md-6">
                                                {this.state
                                                  .updateTechInProgress ? (
                                                  <button
                                                    type="submit"
                                                    className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                                                    disabled
                                                  >
                                                    Modification en cours &nbsp;
                                                    <FeatherIcon
                                                      icon="loader"
                                                      aria-hidden="true"
                                                    />
                                                  </button>
                                                ) : (
                                                  <button
                                                    type="submit"
                                                    className=" col-md-4 btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                                  >
                                                    Valider
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </form>
                                        </Modal>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>{" "}
                              <hr />
                              {/* Add new lang show form button */}
                              {this.state.showLangForm2Btn === true && (
                                <div className="add_form_btn_container col-md-12">
                                  <button
                                    type="button"
                                    className="btn-theme"
                                    onClick={(e) =>
                                      this.addLangForm(e, "laSecondForm")
                                    }
                                  >
                                    Ajouter
                                  </button>
                                </div>
                              )}
                              {/* Render ajout formulaire de lang */}
                              {this.state.showLangForm2 === true && (
                                <div className="motivations_form_row row">
                                  <div className="col-md-12 add_title">
                                    <h5 className="add_motivation_title text-center">
                                      Ajouter une langue{" "}
                                    </h5>
                                    <form id="addLangForm">
                                      <div className="row">
                                        <div className="col-md-4 form-group">
                                          <input
                                            type="text"
                                            placeholder="Langue"
                                            name="language_name"
                                            className="form-control fmCv"
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                        <div className="col-md-6 form-group">
                                          <input
                                            type="text"
                                            placeholder="Label. Exemple : Lu, parlé et écrit"
                                            name="languague_label"
                                            className="form-control fmCv"
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                      </div>
                                      {this.state.isAddRequestError && (
                                        <div className="error-row">
                                          <div className="col-md-12 error-container alert alert-danger">
                                            Une erreur est survenue. Veuillez
                                            réessayer plus tard.
                                          </div>
                                        </div>
                                      )}
                                      <div className="col-md-12 form-group">
                                        {this.state.isAddLangInProgress ===
                                        false ? (
                                          <button
                                            type="button"
                                            className="btn-theme"
                                            onClick={(e) =>
                                              this.hanldeSubmitLang(e, "second")
                                            }
                                          >
                                            Valider
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn-theme-warning"
                                          >
                                            Ajout en cours
                                            <i className="fas fa-spin fa-spinner"></i>
                                          </button>
                                        )}
                                        <button
                                          className="btn-theme-orange offset-md-4"
                                          onClick={(e) => this.hideItemForm(e)}
                                        >
                                          Quitter
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Render premier formulaire d'ajout de techno ou outils */}
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
            <div>
              <Accordion className="accordion">
                <Card className="carde">
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    variant="link"
                    className="according-text"
                  >
                    Portfolio &nbsp;&nbsp;
                    <FeatherIcon
                      className="chevron offset-10"
                      icon="chevron-down"
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="bodyAcordionPortfolio">
                      {this.state.isPortfolioEmpty === true &&
                        this.state.showPortfolioForm === false &&
                        this.state.isProjectEmpty === true && (
                          <div className="empty_container">
                            <div className="empty_portfolio alert alert-danger">
                              Vous n'avez pas encore ajouté de projets. Vous
                              devez en créer au préalable pour pouvoir gérer
                              votrer portfolio.
                            </div>
                          </div>
                        )}
                      {/* {this.state.isEducationEmpty === false */}
                      {this.state.isPortfolioEmpty === true &&
                        this.state.showPortfolioForm === false &&
                        this.state.isProjectEmpty === false && (
                          <div className="empty_container">
                            <div className="empty_portfolio alert alert-warning">
                              Vous n'avez pas encore uploadé vos schreenshots.
                              Nous vous invitons à le faire.
                            </div>
                            <div className="add_form_btn_container">
                              <button
                                title="Ajouter un portfolio"
                                className="btn btn-theme"
                                onClick={(e) =>
                                  this.addNewPortfolioForm(e, "poFirstForm")
                                }
                              >
                                Ajouter
                              </button>
                            </div>
                          </div>
                        )}
                      {this.state.showPortfolioForm === true && (
                        <div className="form_container row">
                          <div className="col-md-12">
                            <h5 className="new_project_title">
                              Nouveau screenshot
                            </h5>
                          </div>
                          <form
                            onSubmit={(e) =>
                              this.handleSubmitNewPortfolio(e, "first")
                            }
                            id="addNewPortfolioForm"
                          >
                            <div className="row">
                              <div className="select_project col-md-6 form-group">
                                <select
                                  className="form-control project_id_label"
                                  name="project_id"
                                  onChange={this.handleChange}
                                  required
                                >
                                  <option>--Choisir un projet--</option>
                                  {this.state.cvprojects.map((pr, indx) => (
                                    <option key={indx} value={pr.id}>
                                      {" "}
                                      {pr.project_name}{" "}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-6 form-group">
                                <input
                                  type="file"
                                  name="img_path"
                                  onChange={this.handleChange}
                                  className="form-control fmCv"
                                />
                              </div>
                            </div>
                            {this.state.isAddRequestError && (
                              <div className="error-row">
                                <div className="col-md-12 error-container alert alert-danger">
                                  Une erreur est survenue. Veuillez réessayer
                                  plus tard.
                                </div>
                              </div>
                            )}
                            <div className="col-md-12 form-group">
                              {this.state.isAddPortfolioInProgress === false ? (
                                <button className="col-md-4 btn btn-theme">
                                  Valider
                                </button>
                              ) : (
                                <button className="btn btn-theme-orange">
                                  Ajout en cours
                                  <i className="fas fa-spin fa-spinner"></i>
                                </button>
                              )}
                              <button
                                className=" col-md-4 btn btn-theme-danger offset-md-4"
                                onClick={(e) => this.hideItemForm(e)}
                              >
                                Quitter
                              </button>
                            </div>
                          </form>
                        </div>
                      )}

                      {/* Render portfolio s'il n'est pas vide */}
                      {this.state.isEducationEmpty === false && (
                        <div className="list_portfolio_row row">
                          {this.state.cvportfolio.map((port, ind) => (
                            <div className="col-md-6 portfolio-card">
                              <div class="card" style={{ width: "18rem" }}>
                                <img
                                  src={port.img_path}
                                  class="card-img-top"
                                  alt={port.projectName}
                                />
                                <div class="card-body">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <h5 class="card-title">
                                        <span className="span-projet">
                                          Projet:
                                        </span>{" "}
                                        &nbsp;{port.projectName}
                                      </h5>
                                    </div>
                                    <div
                                      className="col-md-4"
                                      data-toggle="modal"
                                      data-target="#showPrtfolioItemModal"
                                      onClick={(e) =>
                                        this.getPortfolioInfos(port)
                                      }
                                    >
                                      <FeatherIcon
                                        icon="eye"
                                        className="iconeVoir"
                                      >
                                        Voir
                                      </FeatherIcon>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          {/* Show portfolio item modal */}
                          <div
                            className="modal fade"
                            id="showPrtfolioItemModal"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="showPrtfolioItemModalLabel"
                          >
                            <div
                              className="modal-dialog modal-lg"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h4 className="modal-title" id="myModalLabel">
                                    {" "}
                                    {this.state.portProjectName}{" "}
                                  </h4>
                                  <button
                                    type="button"
                                    className="close "
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    aria-hidden="true"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <img
                                    src={this.state.imgPathValue}
                                    alt=""
                                    className="imagePortfolio"
                                  />
                                </div>
                                <div className="modal-footer">
                                  {/* <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button> */}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Add new portfolio button */}
                          {this.state.showPortfolioForm2Btn === true && (
                            // <div className="add_form_btn_container col-md-12">
                            //   <button  title="Ajouter un portfolio" className="btn btn-theme" onClick={(e) => this.addNewPortfolioForm(e, 'poSecondForm')}>
                            //     AJouter
                            //   </button>
                            // </div>
                            <div className="add_form_btn_container col-md-12">
                              <button
                                title="Ajouter un portfolio"
                                className="col-md-3 offset-md-8 btn btn-theme"
                                onClick={(e) =>
                                  this.addNewPortfolioForm(e, "poSecondForm")
                                }
                              >
                                Ajouter
                              </button>
                            </div>
                          )}

                          {/* Render add form au clic sur le bouton ajouter */}
                          {this.state.showPortfolioForm2 === true && (
                            <div className="form_container row">
                              <div className="col-md-12">
                                <h5 className="new_project_title">
                                  Nouveau screenshot
                                </h5>
                              </div>
                              <form
                                onSubmit={(e) =>
                                  this.handleSubmitNewPortfolio(e, "second")
                                }
                                className="newform"
                                id="addNewPortfolioForm"
                              >
                                <div className="row">
                                  <div className="select_project col-md-6 form-group">
                                    <select
                                      className="form-control project_id_label"
                                      name="project_id"
                                      onChange={this.handleChange}
                                      required
                                    >
                                      <option>--Choisir un projet--</option>
                                      {this.state.cvprojects.map((pr, indx) => (
                                        <option key={indx} value={pr.id}>
                                          {" "}
                                          {pr.project_name}{" "}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-md-6 form-group">
                                    <input
                                      type="file"
                                      name="img_path"
                                      onChange={this.handleChange}
                                      className="form-control fmCv"
                                    />
                                  </div>
                                </div>
                                {this.state.isAddRequestError && (
                                  <div className="error-row">
                                    <div className="col-md-12 error-container alert alert-danger">
                                      Une erreur est survenue. Veuillez
                                      réessayer plus tard.
                                    </div>
                                  </div>
                                )}
                                <div className="col-md-12 form-group">
                                  <button
                                    className="btn btn-theme-danger"
                                    onClick={(e) => this.hideItemForm(e)}
                                  >
                                    Quitter
                                  </button>
                                  {this.state.isAddPortfolioInProgress ===
                                  false ? (
                                    <button className="btn btn-theme offset-md-6 ">
                                      Valider
                                    </button>
                                  ) : (
                                    <button className="btn btn-theme-orange">
                                      Ajout en cours
                                      <i className="fas fa-spin fa-spinner"></i>
                                    </button>
                                  )}
                                </div>
                              </form>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <CoachItemDashboardBakeliste />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <BakelisteGroupItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                <NextReunionItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
