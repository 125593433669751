  import React, { Component } from "react";
  import "./livrableDunBakelisteItem.css";
  import FeatherIcon from "feather-icons-react";
  import { Modal } from "rsuite";
  import base_api_url from "../../variablesGlobales";
  import axios from "axios";
  import * as Sentry from "@sentry/browser";
  import $ from "jquery";
  import API from "../../variablesGlobales";
  import SweetAlert from "react-bootstrap-sweetalert";
  import "react-responsive-carousel/lib/styles/carousel.min.css"; 
  import { Carousel } from "react-responsive-carousel";
  import amplitude from "amplitude-js";
  import { Amplitude } from "@amplitude/react-amplitude";
  import "react-dropzone-uploader/dist/styles.css";
  import Dropzone from "react-dropzone-uploader";
  import Gallery from "react-grid-gallery";
  import { Tooltip } from "@material-ui/core";
  import Commments from "../commments/index";
  import frenchFormatDate from "../frenchFormatDate";
  import IconComment from "../../img/icons/comment.png";
import IconSendComment from "../../img/icons/send1.png";
import { Input, InputGroup } from "rsuite";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
  
  export default class livrableDunBakelisteItem extends Component {

    constructor(props) {
      super(props);
      var today = new Date(),
      date = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate();
  
      this.state = {
        api: API.API,
        show: false,
        frenchFormat: frenchFormatDate.frenchFormatDate,
        showlivrable: false,
        base_api_url: base_api_url.base_api_url,
        fields: {},
        isValidForm: false,
        is_empty_tache_file: false,
        isSendingLivrableInProgress: false,
        bakeliste_id: this.props.idBakeliste,
        user_id: window.sessionStorage.getItem("user_id"),
        user_type: window.sessionStorage.getItem("user_status"),
        showAlertSuccess: false,
        showAlertError: false,
        titre: this.props.titre,
        id: this.props.id,
        files: [],
        images: [],
        showC: false,
        comment: [],
        newComment: {
          comment_text: "",
          isOk: "",
          isnOk: "",
          commentable_id: 0,
          user_type: "",
          livrable_id: 0,
          bakeliste_user_id: 0,
        },
      currentDate: date,

      };
      Sentry.init({
        dsn:
          "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
      });
      //  console.log("commentaires", this.props.commentaires[0])
    }
    componentDidMount() {
      this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
      amplitude.getInstance().logEvent("bakelisteLivrablePageVisite");
    }
  
    onRenderImagesLivrables = () => {
      this.props.fichieirs.map((item, indexx) => {
        var image = {
          src: this.state.base_api_url + item.file_path,
          thumbnail: this.state.base_api_url + item.file_path,
          thumbnailHeight: 320,
        };
        this.state.images.push(image);
      });
  
      return <Gallery images={this.state.images} />;
    };
    componentDidCatch(err, info) {
      Sentry.captureException(err);
    }
  
    open = (size) => {
      this.setState({
        size,
        show: true,
      });
    };
    close = () => {
      this.setState({
        show: false,
      });
    };
  
    openC = (size) => {
      this.setState({
        size,
        showC: true,
      });
    };
    closeC = () => {
      this.setState({
        showC: false,
      });
    };
  
    openLivrable = (sizelivrable) => {
      this.setState({
        sizelivrable,
        showlivrable: true,
      });
    };
    closeLivrable = () => {
      this.setState({
        showlivrable: false,
      });
    };
    amplitudeScript = (api_key) => {
      (function (e, t) {
        var n = e.amplitude || { _q: [], _iq: {} };
        var r = t.createElement("script");
        r.type = "text/javascript";
        r.integrity =
          "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
        r.crossOrigin = "anonymous";
        r.async = true;
        r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
        r.onload = function () {
          if (!e.amplitude.runQueuedFunctions) {
          }
        };
        var i = t.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(r, i);
        function s(e, t) {
          e.prototype[t] = function () {
            this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
            return this;
          };
        }
        var o = function () {
          this._q = [];
          return this;
        };
        var a = [
          "add",
          "append",
          "clearAll",
          "prepend",
          "set",
          "setOnce",
          "unset",
        ];
        for (var u = 0; u < a.length; u++) {
          s(o, a[u]);
        }
        n.Identify = o;
        var c = function () {
          this._q = [];
          return this;
        };
        var l = [
          "setProductId",
          "setQuantity",
          "setPrice",
          "setRevenueType",
          "setEventProperties",
        ];
        for (var p = 0; p < l.length; p++) {
          s(c, l[p]);
        }
        n.Revenue = c;
        var d = [
          "init",
          "logEvent",
          "logRevenue",
          "setUserId",
          "setUserProperties",
          "setOptOut",
          "setVersionName",
          "setDomain",
          "setDeviceId",
          "enableTracking",
          "setGlobalUserProperties",
          "identify",
          "clearUserProperties",
          "setGroup",
          "logRevenueV2",
          "regenerateDeviceId",
          "groupIdentify",
          "onInit",
          "logEventWithTimestamp",
          "logEventWithGroups",
          "setSessionId",
          "resetSessionId",
        ];
        function v(e) {
          function t(t) {
            e[t] = function () {
              e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
            };
          }
          for (var n = 0; n < d.length; n++) {
            t(d[n]);
          }
        }
        v(n);
        n.getInstance = function (e) {
          e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
          if (!n._iq.hasOwnProperty(e)) {
            n._iq[e] = { _q: [] };
            v(n._iq[e]);
          }
          return n._iq[e];
        };
        e.amplitude = n;
      })(window, document);
  
      amplitude.getInstance().init(api_key);
    };
  
  
    onValidateLivrablesFormData() {
      let fields = this.state.fields;
      let errors = this.state.errors;
      var isValidForm = true;
  
      // if (!fields["tache_file"]) {
      //   isValidForm = false;
      //   this.setState({
      //     is_empty_tache_file: true,
      //   });
      //   setTimeout(() => {
      //     this.setState({
      //       is_empty_tache_file: false,
      //     });
      //   }, 5000);
  
      //   errors["tache_file"] = "Ce champ est obligatoire";
      // }
  
      this.setState({
        errors: errors,
      });
  
      return isValidForm;
    }
  
    handleChange = (e) => {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({ fields });
    };
  
    fileValidation = (elementID) => {
      var fileInput = document.getElementById(elementID);
  
      var filePath = fileInput.value;
  
      // Allowing file type
      var allowedExtensions = /(\.png|\.jpg|\.jpeg|)$/i;
  
      if (!allowedExtensions.exec(filePath)) {
        fileInput.value = "";
        return false;
      } else {
        return true;
      }
    };
    getFichiersLivraisons = (data) => {
      const ok = data.map((i, ix) => {
        // console.log("fichierss", i.fichiers);
        return i.fichiers;
      });
      return ok;
    };
  
    handlefileChange = (e) => {
      let fields = this.state.fields;
      if (e.target.name === "tache_file") {
        if (this.fileValidation("tache_file")) {
          fields["tache_file"] = e.target.files[0];
        } else {
          this.setState({
            isInvalidFileExtension: true,
          });
          setTimeout(() => {
            this.setState({
              isInvalidFileExtension: false,
            });
          }, 6000);
        }
      } else {
        fields[e.target.name] = e.target.value;
      }
  
      this.setState({
        fields: fields,
      });
      // console.log(this.state.fields);
    };
  
    // called every time a file's `status` changes
    handleChangeStatus = ({ meta, file }, status) => {
      const files = [];
      if (status === "done") {
        // console.log(status, meta, file);
        // files.push(file);
        this.setState({ files: this.state.files.concat(file) });
      }
  
      // this.setState({ files: files });
      //console.log("filesss", this.state.files);
    };
  
    // receives array of files that are done uploading when submit button is clicked
    handleSubmit = (files, allFiles) => {
      // console.log(files.map((f) => f.meta));
      // console.log("all files", allFiles);
      allFiles.map((file) => {
        const formData = new FormData();
        formData.append("file", file.file);
      });
  
      // allFiles.forEach((f) => f.remove());
    };
    onDrop = (files) => {
      // alert("goooooooooooo");
      this.setState({
        files: files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      });
      const uploaders = files.map((file) => {
        const formData = new FormData();
        formData.append("file", file);
  
        return axios.post("https://httpbin.org/post", formData, {
          headers: { "X-Requested-With": "XMLHttpRequest" },
        });
      });
    };
  
    onhandleChange = (e) => {
      e.preventDefault();
  
      let { newComment } = this.state;
      newComment.comment_text = e.target.value;
  
      this.props.fichiers.map((item, indexx) => {
        if (indexx === 0) {
          this.setState({ liv_id: item.livraisons_id });
          newComment.livrable_id = item.livraisons_id;
        }
      });
      newComment.user_id = this.state.user_id;
      newComment.user_type = this.state.user_type;
      newComment.bakeliste_user_id = this.state.bakeliste_id;
      newComment.tache_content = this.props.titre;
      this.setState({ newComment });
      // console.log("comment", this.state.newComment);
    };
  
   
    addReactiononLivrable = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.onSubmitComment(this.state.newComment, this.props.item);
      e.target.reset();
    };

    validerTache = (id) => {
      const fd = new FormData();
       
        fd.append("coach_id", this.state.user_id);
        fd.append("bakeliste_id", this.props.bakeliste_id);
        fd.append("task_id", id);
        fd.append("livraisons_id", this.props.livraison_id);
        fd.append("is_validated", 1)
        fd.append("date_validation", this.state.currentDate)
      // console.log("eeee",id)
      axios
      .post(this.state.api + "bakeliste_task_validations" , fd)
      .then((res) => {
        // console.log(res)
        if (res.data.success) {
          toast.success(
            "Tâche validée avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          isSendingResumenProgress: false,
        });
        toast.error(
          "Erreur sur la validation de la tâche",
          { position: toast.POSITION.BOTTOM_LEFT },
          { autoClose: 1000 }
        );
      });
  };

    render() {
      const renderCarouselSlides = () =>
        this.props.fichiers.map((item) =>
          item.map((i) => (
            <div>
              <img src={this.state.base_api_url + i.file_path} />
            </div>
          ))
        );
      
      return (
        <Amplitude>
          <div className="component-livrable-bakeliste-item">
            <div className="row">
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 dis-flex itm-center mb-md-0 mb-3">
                <div>
                  <p className="num-tache-livrable-bakeliste-item">
                    Tâches n°{this.props.tache_number}
                  </p>
                  <p className="date-livrable-bakeliste-item">
                    {this.props.date_tache}
                  </p>
                  {this.props.syllabus !== undefined && (
                    <p className="icon-btn-livrable-bakeliste-item">{this.props.syllabus}</p>
                  )}
                </div>
              </div>
              <div className="col-xl-7 pr-xl-5 col-lg-6 col-md-7 pr-md-5 col-sm-9 mb-md-0 mb-3 separateur-livrable-bakeliste-item">
                <p className="titre-tache-livrable-bakeliste-item">
                  {this.props.titre}
                </p>
                <p className="content-tache-livrable-bakeliste-item"></p>
              </div>
              <div className="col-xl-3 pl-xl-0 col-lg-4 col-md-3 pl-md-0 col-sm-6 ml-auto dis-flex itm-center">
                <div className="row">
                  <div className="col-xl-12 mb-3">
                    <button
                      className="btn-livrable-bakeliste-item"
                      onClick={() => this.open("md")}
                    >
                      <FeatherIcon
                        icon="eye"
                        className="icon-btn-livrable-bakeliste-item"
                      />
                      Voir les livrables
                      <span className="badge badge-livrable">
                      </span>
                    </button>
                  </div>
                  <div className="col-xl-12 mb-3">
                    <button
                      className="btn-theme-green text-up w-full"
                      onClick={() => this.openC("md")}
                    >
                      <FeatherIcon
                        icon="eye"
                        className="icon-btn-livrable-bakeliste-item"
                      />
                      Commentaires
                    </button>
                  </div>
                  <div className="col-xl-12 mb-3">
                    <button
                      className="btn-theme text-up w-full"
                      onClick={() => this.validerTache(this.props.id)}
                    >
                      <FeatherIcon
                        icon="check-square"
                        className="icon-btn-livrable-bakeliste-item"
                      />
                      Valider la tâche
                    </button>
                  </div>
                </div>
                <Modal
                  className="modal-responsive-md"
                  size={this.state.size}
                  show={this.state.showC}
                  onHide={this.closeC}
                >
                  <h5 
                    className="modal-bakeliste-title m-b-50"
                    id="modalShowLivrableDetailsTitle"
                  >
                    Commentaires du livrables de la tâche {this.props.titre}
                  </h5>
                  <button
                    className="close-modal-bakeliste-title"
                    onClick={this.closeC}
                  >
                    <FeatherIcon icon="x" aria-hidden="true" />
                  </button>
                  {this.props.commentaires[0].map((d) => 
                  <>
                    <div className="post-comments mt-3">
                      <span className="meta pull-right">
                      {this.state.frenchFormat(d.created_at)}{" "}
                    </span>
                      <p className="comment mt-3"> {d.comment_text}</p>
                   </div>
                      </>
                    )
                  }
                {/* 
                  <form 
                  onSubmit={this.addReactiononLivrable}
                   >
                    <div className="row m-t-25 m-b-5">
                      <div className="px-lg-2 col-sm-2 col-3 px-md-3 px-sm-2 dis-flex itm-center justify-content-center">
                      </div>
                      <div className="col-sm-10 col-9 pl-0 pr-2 pr-sm-2 pr-md-3">
                        <InputGroup inside>
                          <InputGroup.Addon>
                            <img
                              className="icon-comment-fil-actualite"
                              src={IconComment}
                              alt=""
                            />
                          </InputGroup.Addon>
                          <input
                            type="text"
                            placeholder="Ajouter un commentaire"
                            className="input-comment-fil-actualite"
                            name="comment"
                            // value={this.state.newComment.commentaire}
                            onChange={this.onhandleChange}
                          />
                          <button
                            className="btn-send-comment-fil-actualite"
                            type="submit"
                          >
                            <img
                              className="icon-btn-send-comment-fil-actualite"
                              src={IconSendComment}
                              alt=""
                            />
                          </button>
                        </InputGroup>
                      </div>
                    </div>
                  </form>
                */}
                </Modal>
                <Modal
                  className="modal-responsive-md"
                  size={this.state.size}
                  show={this.state.show}
                  onHide={this.close}
                >
                  <h5
                    className="modal-bakeliste-title m-b-50"
                    id="modalShowLivrableDetailsTitle"
                  >
                    Livrables
                  </h5>
                  <button
                    className="close-modal-bakeliste-title"
                    onClick={this.close}
                  >
                    <FeatherIcon icon="x" aria-hidden="true" />
                  </button>
  
                  <h2 className="title-modal-bakeliste-title">
                    Tâches n°{this.props.tache_number}
                  </h2>
                    <Carousel>{renderCarouselSlides()}</Carousel>
                </Modal>
                <SweetAlert
                  show={this.state.showAlertSuccess}
                  onConfirm={() => this.setState({ showAlertSuccess: false })}
                  success
                  title="Livrable envoyé avec succes!!!"
                  style={{
                    color: "#069A77",
                    fontSize: "12px",
                    fontFamily: "Work Sans, sans-serif",
                    paddingTop: 60,
                    paddingBottom: 40,
                  }}
                  confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                />
                <SweetAlert
                  show={this.state.showAlertError}
                  onConfirm={() => this.setState({ showAlertError: false })}
                  error
                  title="Erreur envoie livrable, veuillez reessayer!!!"
                  style={{
                    color: "#d9534f",
                    fontSize: "12px",
                    fontFamily: "Work Sans, sans-serif",
                    paddingTop: 60,
                    paddingBottom: 40,
                  }}
                  confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                />
                <Modal
                  className="modal-responsive-md"
                  size={this.state.sizelivrable}
                  show={this.state.showlivrable}
                  onHide={this.closeLivrable}
                >
                  <h5
                    className="modal-bakeliste-title m-b-50"
                    id="modalSendLivrableTitle"
                  >
                    Envoyer mon travail
                  </h5>
                  <button
                    className="close-modal-bakeliste-title"
                    onClick={this.closeLivrable}
                  >
                    <FeatherIcon icon="x" aria-hidden="true" />
                  </button>
  
                  <form
                    encType="multipart/form-data"
                    id="livrable_form"
                    onSubmit={(e) => this.onSendLivrables(e)}
                  >
                    <div className="form-group">
                      <label
                        id="tache_id"
                        name="tache_id"
                        className="form-control select-modal-content-bakeliste"
                        value={(this.state.fields["tache_id"] = this.props.id)}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Tache: {this.props.titre}
                      </label>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control input-modal-content-bakeliste p-t-15"
                        rows="9"
                        placeholder="Ajouter un commentaire"
                        name="comment"
                        value={this.state.fields["comment"]}
                        onChange={(e) => this.handleChange(e)}
                      ></textarea>
                    </div>
                    <Dropzone
                      required
                      getUploadParams={this.getUploadParams}
                      onChangeStatus={this.handleChangeStatus}
                      onChange={(e) => this.handlefileChange(e)}
                      onDrop={this.onDrop.bind(this)}
                      accept="image/*,audio/*,video/*"
                      inputContent={"Cliquer pour ajouter des images"}
                      inputWithFilesContent={"Ajouter plus..."}
                    />
                    {this.state.isSendingLivrableInProgress ? (
                      <button
                        type="submit"
                        className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                        disabled
                      >
                        Envoie en cours &nbsp;
                        <FeatherIcon icon="loader" aria-hidden="true" />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                      >
                        ENVOYER
                      </button>
                    )}
                  </form>
                </Modal>
              </div>
            </div>
          </div>
        </Amplitude>
      );
    }
  }
 