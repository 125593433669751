import React, { Component } from "react";
import "./tableGroupeBakeliste.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

// class PresenceCheck extends React.Component {
//   render() {
//     return (
//       <div style={{ color: "#22C580", textAlign: "center" }}>
//         <FeatherIcon icon="check" size={16} />
//       </div>
//     );
//   }
// }

// function uploadFormatter(cell, row) {
//   return <PresenceCheck active={cell} />;
// }

export default class tableGroupeBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listeBakeliste: this.props.listeBakeliste,
    };
    console.log("groupeee", this.props.listeBakeliste);
  }
  render() {
    return (
      <div className="table-responsive-sm table-responsive-xl table-responsive-md">
        <BootstrapTable
          data={this.props.listeBakeliste}
          striped
          version="4"
          bordered={false}
          bodyStyle={{
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.7)",
            fontFamily: "inherit",
            fontWeight: 400,
          }}
          tdStyle={{ background: "red" }}
          tableHeaderclassName="header-table-groupe-view"
          wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
          className="table-responsive table-responsive-sm"
        >
          <TableHeaderColumn
            isKey
            dataField="first_name"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{ border: "none" }}
          >
            Prénom
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="last_name"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{ border: "none" }}
          >
            Nom
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="phone"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{ border: "none" }}
          >
            N° Téléphone
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="email"
            dataSort
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{ border: "none" }}
          >
            Email
          </TableHeaderColumn>
          {/* <TableHeaderColumn
            dataField="presence"
            dataSort
            dataFormat={uploadFormatter}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{ border: "none" }}
          >
            Présence
          </TableHeaderColumn> */}
        </BootstrapTable>
      </div>
    );
  }
}
// export default connect(
//     ({ tableGroupeBakeliste }) => ({ ...tableGroupeBakeliste }),
//     dispatch => bindActionCreators({ ...tableGroupeBakelisteActions }, dispatch)
//   )( tableGroupeBakeliste );
