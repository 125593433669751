import React, { Component } from "react";
import "./grapheTaskValidated.css";
import Chart from "react-apexcharts";
import API from "../../variablesGlobales";
import axios from "axios";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as grapheLivraisonsActions from "../../store/grapheLivraisons/actions";
export default class grapheTaskValidated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      task_validated: [],
      categories: [],
    };
  }
  componentDidMount() {
    this.getTaskGrapheData();
  }

  getTaskGrapheData() {
    axios
      .get(this.state.api + "count-task-validation-by-week")
      .then((response) => {
        this.setState({
          categories: response.data.date,
          task_validated: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="component-graphe-livraisons">
        <div className="row">
          <div className="col-md-12">
            <h6 className="h-theme ff-inter fw-600">
              Représentation des Taches Validees en statistique
            </h6>
            <div className="section-content">
              <Chart
                options={{
                  chart: {
                    id: "livraisons_chart",
                  },
                  stroke: {
                    curve: "straight",
                  },

                  grid: {
                    row: {
                      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                      opacity: 0.5,
                    },
                  },
                  xaxis: {
                    categories: this.state.categories,
                  },
                  colors: ["#009688", ""],
                }}
                series={[
                  {
                    name: "livraisons",
                    data: this.state.task_validated,
                  },
                  // {
                  //   name: "Prospect",
                  //   data: this.state.prospects,
                  // },
                  // {
                  //   name: "Visiteur",
                  //   data: this.state.visiteurs,
                  // },
                ]}
                type="bar"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ grapheLivraisons }) => ({ ...grapheLivraisons }),
//     dispatch => bindActionCreators({ ...grapheLivraisonsActions }, dispatch)
//   )( grapheLivraisons );
