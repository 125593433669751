

  import React, {Component} from 'react';
  import './contenusView.css';
  import HeaderDashboard from "../headerDashboard/index";
  import NavVertical from "../navVertical/index";
  import Contenus from "../contenus/index";
  import qs from "qs";
  import API from "../../variablesGlobales";
  import axios from "axios";
  import * as Sentry from "@sentry/react";
// import ModalContactUs from '../modalContactUs/index'
// import FeatherIcon from "feather-icons-react";
// import Fichier from "../../image/fichier.png";
// import { Link } from "react-router-dom";
  // import { connect } from "react-redux";
  // import { bindActionCreators } from "redux";
  // import * as ajouterResumeViewActions from "../../store/ajouterResumeView/actions";
  export default class contenusView extends Component {
    constructor(props) {
      super(props);

      let id = this.props.location?.state?.id;
      if (!id && this.props.location.search) {
        const obj = qs.parse(this.props.location.search.replace("?", ""));
        id = parseInt(obj.id);
      }
          this.state = {
            id: id,
            api:API.API,
            contenus:[],
          };
      }
      componentDidMount(){
        this.getResume();
      }
  
      getResume = () => {
        // var fd = new FormData();
        // fd.append("id", this.state.id);
      axios
        .get(this.state.api + "textual-contents/" + this.state.id)
        .then((res) => {
          this.setState({
            contenus: res.data.data,
          });
          // console.log(this.state.contenus);
        })
        .catch((error) => {
          // console.log(error.message);
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    };
    
      render() {
        const contenus = this.state.contenus
        // console.log("eedd", contenus.title)
        return ( 
        <div className="body-theme">
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVertical />
            </div>
            <div className="col-lg-10 pt-3">
              <Contenus title={contenus.title}
              type={contenus.content_type}
              contenu_text={contenus.content}
              auteur={contenus.fullName}
              created_at={contenus.created_at} />
            </div>
          </div>
        </div>
      </div>) }
    }
  // export default connect(
  //     ({ ajouterResumeView }) => ({ ...ajouterResumeView }),
  //     dispatch => bindActionCreators({ ...ajouterResumeViewActions }, dispatch)
  //   )( ajouterResumeView );