import React, {Component, useState} from 'react';
import './commentsv2.css'
import frenchFormatDate from "../../frenchFormatDate";
import IconComment from "../../../img/icons/comment.png";
import IconSendComment from "../../../img/icons/send1.png";
import FeatherIcon from "feather-icons-react";
import { Input, InputGroup } from "rsuite";
import API2 from "../../../variablesGlobales";
import axios from "axios";
import { toast } from "react-toastify";
import amplitude from "amplitude-js";
import AmplitudeScript from '../../../amplitudeScript';
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const userFullName = window.sessionStorage.getItem("userFullName")
const user_id = window.sessionStorage.getItem("user_id");
const user_type = window.sessionStorage.getItem("user_status");




const Commentsv2 =(props)=> {
    
const [showReplyForm, setShowReplyForm]=useState(false);
const [voirCommentaires,setVoirCommentaires]=useState(false);
const [comments, setComments]=useState(props.comments)


    const [newReply, setNewReply]=useState({
          comment_text: "",
          user_id: window.sessionStorage.getItem("user_id"),
          coach_id: 0,
          user_type: "",
          parent_id: "",
          livrable_id: 0,
          voirCommentairesC: false
    })
    AmplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("bakelisteaddLivrableButtonClick_v2_Redux")

    const frenchFormat = frenchFormatDate.frenchFormatDate
  const onshowReplyForm = (id) => {

   setShowReplyForm(showReplyForm ? '' : id)
     
}

  
  const voirCommentsC = (e) => {
    setVoirCommentaires(true)
  };
  const cacherCommentsC = (e) => {
    setVoirCommentaires(false)

  };
  
  const addReplyComment = (e) => {
    e.preventDefault();
    let { newReply } = this.state;
    // newReply.isOk = this.state.liked;
    // newReply.isnOk = this.state.notliked;

    newReply.user_id = this.state.user_id;
    newReply.user_type = this.state.user_type;
    this.setState({ newReply });
    axios
      .post(this.state.api + "reply/store", this.state.newReply)
      .then((response) => {
        newReply.comment_text = "";
        this.setState({
          newReply,
          // showBloc: false,
        });

        // document.getElementById("comment-livrable").reset();

        if (response.success) {
        }
      })

      .catch((error) => {});
  };

  const ongetComments=(data) =>{
    if (this.props.commentaires.length > 0) {
      this.props.commentaires.map((item, indexx) => {
        item.map((i, ix) => {
          var comment = {
            text: i.comment_text,
            username: i.fullname,
            fichier: i.comment_file,
            parent: i.parent_id,
          };

          this.state.commentaires.push(comment);
        });
      });
    }
    return this.state.commentaires;
  }





  const onhandleChange = (e,i) => {
    e.preventDefault();
    setNewReply({
      comment_text:e.target.value,
      livrable_id:i.commentable_id,
      user_id :window.sessionStorage.getItem("user_id"),
      user_type:user_type,
      parent_id:i.id
    })
  };
  

  const replyToComment = (e,i) => {
    e.preventDefault()
   
   const replies = i.replies;
    const eventProperties = {
      user_id: user_id,
      name: userFullName,
      user_type:user_type
    };
    axios
      .post(API2.API2 + "reply/livraison_comments",newReply)
      .then((response) => {
        amplitude
          .getInstance()
          .logEvent(
            "REPONSE_COMMENTAIRE_LIVRAISON_Success_v2_Redux",
            eventProperties
          );
         const data = response.data.data;
         data.fullname = userFullName;
        i.replies.push(data);
         const commentaires = [...comments];
        const index = commentaires.findIndex((it) => it.id === i.id);
         commentaires[index].replies = replies;
        setComments(commentaires);
        setNewReply({
          comment_text:''
        })
      })

      .catch((error) => {
        amplitude
        .getInstance()
        .logEvent(
          "REPONSE_COMMENTAIRE_LIVRAISON_Error_v2_Redux",
          eventProperties
        );
      });
  };
const validate = val => {
  const errors = {};
  if (!val.firstName) {
    console.log('First Name is required');
    errors.firstName = 'Required';
  }
}
    return (
        <div className="row">
          <div className="col-md-12 mt-5">
            <div className="blog-comment">
              <div className="row">
                  <div class="container">
		<div class="col-md-12" id="fbcomment">
			<div class="header_comment">
				<div class="row">
					<div class="col-md-6 text-left">
					  <span class="count_comment"> {comments.length} &nbsp; Commentaires</span>
					</div>
					<div class="col-md-6 text-right">
					  {/* <span class="sort_title">Sort by</span> */}
					  {/* <select class="sort_by">
						<option>Top</option>
						<option>Newest</option>
						<option>Oldest</option>
					  </select> */}
          
					</div>
				</div>
			</div>

			<div class="body_comment">

				<div class="row">
					<ul id="list_comment" class="col-md-12">
          {comments.map((i, ix) =>
          <>
          
						<li class="box_result row">
        
							<div class="avatar_comment col-md-1">
								<img src="https://static.xx.fbcdn.net/rsrc.php/v1/yi/r/odA9sNLrE86.jpg" alt="avatar"/>
							</div>
							<div class="result_comment col-md-10">
								<h4> {i.user.first_name} {i.user.last_name}<i className="p-3">
                            {i.user_type === "bakeliste" ? (
                              <small className="bakeliste">{i.user_type}</small>
                            ) : (
                              <small className="coach">Coach</small>
                            )}
                          </i></h4>
								<p>{i.comment_text}</p>
								<div class="tools_comment">
									{/* <a class="like" href="#">Like</a> */}
									<span aria-hidden="true"> · </span>
									<button class="replay"  onClick={()=>onshowReplyForm(ix)}>Repondre</button>
									<span aria-hidden="true"> · </span>
									{/* <i class="fa fa-thumbs-o-up"></i> <span class="count">1</span>  */}
									<span aria-hidden="true"> · </span>
									<span>{frenchFormat(i.created_at)}{" "}</span>
								</div>
                {showReplyForm === ix ?
              
              <form 
                onSubmit={(e)=>replyToComment(e,i)} 
               id='reply_form'
              >
        <div className="row m-t-25 m-b-5">
          <div className="px-lg-2 col-sm-2 col-3 px-md-3 px-sm-2 dis-flex itm-center justify-content-center"></div>
          <div className="col-sm-10 col-9 pl-0 pr-2 pr-sm-2 pr-md-3">
            <InputGroup inside>
              <InputGroup.Addon>
                <img
                  className="icon-comment-fil-actualite"
                  src={IconComment}
                  alt=""
                />
              </InputGroup.Addon>
              <input
                type="text"
                placeholder={`Repondre a ${i.user.first_name}`}
                className="input-comment-fil-actualite"
                name="comment"
                 value={newReply.comment_text}
               onChange={(e)=>onhandleChange(e,i)}
              />
            
              <button
                className="btn-send-comment-fil-actualite"
                type="submit"
              >
                <img
                  className="icon-btn-send-comment-fil-actualite"
                  src={IconSendComment}
                  alt=""
                />
              </button>
            </InputGroup>
          </div>
        </div>
      </form>
            : null}

            {i.replies?.length>0 ? 
            (i.replies.map((ii,index)=>
								 <ul class="child_replay ml-5 pl-5">
									<li class="box_reply row">
										<div class="avatar_comment col-md-2">
											<img src="https://static.xx.fbcdn.net/rsrc.php/v1/yi/r/odA9sNLrE86.jpg" alt="avatar"/>
										</div>
										 <div class="result_comment col-md-10">
                     {ii.fullname &&
								<h4> {ii.fullname}</h4>

                }
                     <h4> {ii.user?.first_name} {ii.user?.last_name}<i className="p-3">
                            {ii.user_type === "bakeliste" ? (
                              <small className="bakeliste">{i.user_type}</small>
                            ) : (
                              <small className="coach">Coach</small>
                            )}	
                            </i>		
                            </h4>							
                            	<p>{ii.comment_text}</p>
										
											<ul class="child_replay"></ul>
										</div> 
									</li>
								</ul>
            
            ))
            :null
                            }
							</div> 
           
						</li>
          
            </>

          )}
					</ul>
				{/* <button class="show_more" type="button">Load 10 more comments</button> */}
				</div>
			</div>
		</div>
	</div>
            </div>
          </div>
        </div>
      </div>
    );
}


export default Commentsv2
// export default connect(
//     ({ commments }) => ({ ...commments }),
//     dispatch => bindActionCreators({ ...commmentsActions }, dispatch)
//   )( commments );
