  import React, {Component} from 'react';
  import './livraisonDunBakelisteCoachAssistantView.css';
  import HeaderDashboardCoachAssistant from "../headerDashboardCoach/index";
  import NavVerticalCoachAssistant from "../navVerticalCoachAssistant/index";
  import amplitude from "amplitude-js";
  import LivraisonDunBakelisteCoachAssistant from "../livraisonDunBakelisteCoachAssistant/index";
  import qs from "qs"; 
import { Amplitude } from "@amplitude/react-amplitude";

  
  // import { connect } from "react-redux";
  // import { bindActionCreators } from "redux";
  // import * as livraisonDunBakelisteViewActions from "../../store/livraisonDunBakelisteView/actions";
  export default class livraisonDunBakelisteCoachAssistantView extends Component {
      constructor(props) {
          super(props);
          let Id_Bak = this.props.location?.state?.id;
          let donnees = this.props.location?.state?.data;
          if (!Id_Bak && this.props.location.search) { 
            const obj = qs.parse(this.props.location.search.replace("?", ""));
            Id_Bak = parseInt(obj.id);
          } 
              this.state = {
                 bakeliste_id: Id_Bak, 
            email: window.sessionStorage.getItem("email"),
            user_id: window.sessionStorage.getItem("user_id"), 
            donnees: donnees,
          };
          // console.log(this.state.bakeliste_id,"idddddddd", this.state.donnees, "data")
      }
      componentDidMount =() => {
        var eventProperties = {
          user_email: this.state.email,
          user_id: this.state.user_id,
        };
          this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
          amplitude.getInstance().logEvent("VISITED_LIVRAISON_D'UN_BAKELISTE_V2", eventProperties);
      }
      amplitudeScript = (api_key) => {
        (function (e, t) {
          var n = e.amplitude || { _q: [], _iq: {} };
          var r = t.createElement("script");
          r.type = "text/javascript";
          r.integrity =
            "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
          r.crossOrigin = "anonymous";
          r.async = true;
          r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
          r.onload = function () {
            if (!e.amplitude.runQueuedFunctions) {
            }
          };
          var i = t.getElementsByTagName("script")[0];
          i.parentNode.insertBefore(r, i);
          function s(e, t) {
            e.prototype[t] = function () {
              this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
              return this;
            };
          }
          var o = function () {
            this._q = [];
            return this;
          };
          var a = [
            "add",
            "append",
            "clearAll",
            "prepend",
            "set",
            "setOnce",
            "unset",
          ];
          for (var u = 0; u < a.length; u++) {
            s(o, a[u]);
          }
          n.Identify = o;
          var c = function () {
            this._q = [];
            return this;
          };
          var l = [
            "setProductId",
            "setQuantity",
            "setPrice",
            "setRevenueType",
            "setEventProperties",
          ];
          for (var p = 0; p < l.length; p++) {
            s(c, l[p]);
          }
          n.Revenue = c;
          var d = [
            "init",
            "logEvent",
            "logRevenue",
            "setUserId",
            "setUserProperties",
            "setOptOut",
            "setVersionName",
            "setDomain",
            "setDeviceId",
            "enableTracking",
            "setGlobalUserProperties",
            "identify",
            "clearUserProperties",
            "setGroup",
            "logRevenueV2",
            "regenerateDeviceId",
            "groupIdentify",
            "onInit",
            "logEventWithTimestamp",
            "logEventWithGroups",
            "setSessionId",
            "resetSessionId",
          ];
          function v(e) {
            function t(t) {
              e[t] = function () {
                e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
              };
            }
            for (var n = 0; n < d.length; n++) {
              t(d[n]);
            }
          }
          v(n);
          n.getInstance = function (e) {
            e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
            if (!n._iq.hasOwnProperty(e)) {
              n._iq[e] = { _q: [] };
              v(n._iq[e]);
            }
            return n._iq[e];
          };
          e.amplitude = n;
        })(window, document);
        amplitude.getInstance().init(api_key);
      };
  
      
      render() {
        return (
          <Amplitude>
          <div className="body-theme">
            <HeaderDashboardCoachAssistant />
            <div className="container-fluid fixing-after-header">
              <div className="row">
                <div className="col-lg-2 p-0">
                  <NavVerticalCoachAssistant />
                </div>
                <div className="col-lg-10 pt-3">
                  <LivraisonDunBakelisteCoachAssistant 
                  bakeliste_id={this.state.bakeliste_id}
                  Name = {this.state.donnees.user_fullname}
                  />
                </div>
              </div>
            </div>
          </div>
       </Amplitude> );
      }
    }