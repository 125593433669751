import React, {Component} from 'react';
import './voirContenusBakelisteView.css';
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import VoirContenusBakeliste from "../voirContenusBakeliste/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as ajouterResumeViewActions from "../../store/ajouterResumeView/actions";
export default class voirContenusBakelisteView extends Component {
    constructor(props) {
        super(props);
        this.state = {}; 
    }
    render() {
      return (   
      <div className="body-theme">
      <HeaderDashboard />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVertical />
          </div>
          <div className="col-lg-10 pt-3">
            <VoirContenusBakeliste />
          </div>
        </div>
      </div>
    </div>) }
  }
// export default connect(
//     ({ ajouterResumeView }) => ({ ...ajouterResumeView }),
//     dispatch => bindActionCreators({ ...ajouterResumeViewActions }, dispatch)
//   )( ajouterResumeView );