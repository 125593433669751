import React, { Component } from "react";
import "./envoyerMonTravail.css";
import FeatherIcon from "feather-icons-react";
import Dropzone from "react-dropzone-uploader";
import { Modal } from "rsuite";
import SweetAlert from "react-bootstrap-sweetalert";
import API from "../../variablesGlobales";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import amplitude from "amplitude-js";
import $ from "jquery";
import base_api_url from "../../variablesGlobales";
import { Amplitude } from "@amplitude/react-amplitude";
export default class envoyerMonTravail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      userID: window.sessionStorage.getItem("user_id"),
      email: sessionStorage.getItem("email"),
      bakeliste_id: window.sessionStorage.getItem("user_id"),
      domaine_id: sessionStorage.getItem("domaine_id"),
      show: false,
      base_api_url: base_api_url.base_api_url,
      ALL: [],
      fields: {},
      fieldss: {},
      isSendingLivrableComponentInProgress: false,
      filess: [],
      isValidForm: false,
      table: [],
      tasksyllabusencours: [],
      tasktodo: [],
      taskdoing: [],
      showAlertSuccessComponent: false,
      showAlertErrorComponent: false,
    };
  }

  componentDidMount() {
    this.getTaches();
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("envoyerMonTravailComponent");
    this.getDetailsLastSyllabus();
  }

  Status = ({ meta, file }, status) => {
    const files = [];
    if (status === "done") {
      this.setState({ files: this.state.files.concat(file) });
    }
  };

  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);

    amplitude.getInstance().init(api_key);
  };

  onValidateLivrablesFormDataSyllabus() {
    let fieldss = this.state.fieldss;
    let errors = this.state.errors;
    var isValidForm = true;

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }

  onSendLivrablesComponent = (e) => {
    e.preventDefault();
    if (this.onValidateLivrablesFormDataSyllabus()) {
      this.setState({
        isSendingLivrableComponentInProgress: true,
      });
      const id = this.state.bakeliste_id;
      const email = this.state.email;
      var dataaS = this.state.fieldss;
      var filess = this.state.filess;
      var eventProperties = {
        user_id: id,
        email: email,
        details_syllabus_id: dataaS.details_syllabus_id,
      };
      if (navigator.onLine) {
        filess.map((image) => {
          var fd = new FormData();

          fd.append("commentaire", dataaS.comment);
          fd.append("bakeliste_user_id", this.state.bakeliste_id);
          fd.append("details_syllabus_id", dataaS.details_syllabus_id);
          fd.append("tache_files", image);
          axios
            .post(this.state.api + "livraisons", fd, {
              Headers: { "Content-Type": "multipart/formdata" },
            })
            .then((response) => {
              //  console.log("response", response)
              this.setState({
                isSendingLivrableComponentInProgress: false,
              });

              if (response.data.success) {
                // alert("V2_ENVOIE_LIVRABLE_BAKELISTE_SUCCESS");

                amplitude
                  .getInstance()
                  .logEvent(
                    "ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_BUTTON_V2",
                    eventProperties
                  );
                // $("#tache_file").val("");
                $(".close-modal-bakeliste-title").click();
                setTimeout(() => {
                  this.setState({
                    showAlertSuccessComponent: true,
                    showlivrable: false,
                  });
                }, 1000);
              } else {
                this.setState({
                  showAlertErrorComponent: true,
                });
                setTimeout(() => {
                  this.setState({
                    isErrorSending: false,
                  });
                }, 1000);
                amplitude
                  .getInstance()
                  .logEvent(
                    "ENVOIE_LIVRABLE_BAKELISTE_ERROR_V2",
                    eventProperties
                  );
              }
              $(".close-modal-bakeliste-title").click();
            })
            .catch((error) => {
              if ((error.message = "Request failed with status code 422")) {
              }
              amplitude
                .getInstance()
                .logEvent(
                  "ENVOIE_LIVRABLE_BAKELISTE_ERROR_V2",
                  eventProperties
                );
              this.setState({
                isSendingLivrableComponentInProgress: false,
                showAlertErrorComponent: true,
              });
              if (process.env.NODE_ENV !== "production") {
                return;
              }
              Sentry.captureException(error);
            });
        });
        $(".close-modal-bakeliste-title").click();
      } else {
        this.setState({ userNotConnected: true });
        amplitude
          .getInstance()
          .logEvent("ERROR_CONNEXION_ENVOIE_LIVRABLE_V2", eventProperties);
      }
    }
  };

  getTaches = () => {
    axios
      .get(
        this.state.api +
          "get-taches-bakeliste-by-status/" +
          this.state.bakeliste_id
      )
      .then((response) => {
        this.setState({
          taskdoing: response.data.taskdoing,
        });

        this.formatData();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getDetailsLastSyllabus = () => {
    axios
      .get(
        this.state.api + "bakeliste-details-syllabus/" + this.state.bakeliste_id
      )
      .then((response) => {
        this.setState({
          // tasksyllabusdone: response.data.details_done.reverse(),
          // tasksyllabustodo: response.data.details_todo,
          // tasksyllabusdoing: response.data.details_doing,
          ALL: response.data.all_taches.reverse(),
        });
        this.formatData();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  handleChangeSyllabus = (e) => {
    let fields = this.state.fieldss;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  handleChangeS = (e) => {
    let fieldss = this.state.fieldss;
    fieldss[e.target.name] = e.target.value;
    this.setState({ fieldss });
  };

  getDetailSyllabus = () => {
    axios
      .get(
        this.state.api + "bakeliste-details-syllabus/" + this.state.bakeliste_id
      )
      .then((response) => {
        this.setState({
          tasksyllabusencours: response.data.details_doing,
        });
        this.formatData();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  onValidateLivrablesFormData() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;
    this.setState({
      errors: errors,
    });
    return isValidForm;
  }

  onDropS = (filess) => {
    this.setState({
      filess: filess.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
    const uploaders = filess.map((file) => {
      const formData = new FormData();
      formData.append("file", file);

      return axios.post("https://httpbin.org/post", formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" },
      });
    });
  };
  handleChangeStatusS = ({ meta, file }, status) => {
    const filess = file;

    if (status === "done") {
      this.setState({ filess: this.state.filess.concat(file) });
    }
  };
  handlefileChange = (e) => {
    let fields = this.state.fields;
    if (e.target.name === "tache_file") {
      if (this.fileValidation("tache_file")) {
        fields["tache_file"] = e.target.files[0];
      } else {
        this.setState({
          isInvalidFileExtension: true,
        });
        setTimeout(() => {
          this.setState({
            isInvalidFileExtension: false,
          });
        }, 6000);
      }
    } else {
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields: fields,
    });
  };

  open = (size) => {
    this.setState({
      size,
      show: true,
    });
  };

  close = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    return (
      <Amplitude>
        <div>
          <button
            className="btn-theme-gradient text-up w-full "
            onClick={() => this.open("md")}
          >
            <FeatherIcon
              icon="send"
              className="icon-btn-livrable-bakeliste-item"
            />
            Envoyer mon travail
          </button>
          <SweetAlert
            show={this.state.showAlertSuccessComponent}
            onConfirm={() =>
              this.setState({ showAlertSuccessComponent: false })
            }
            success
            title="Livrable envoyé avec succes!!!"
            style={{
              color: "#069A77",
              fontSize: "12px",
              fontFamily: "Work Sans, sans-serif",
              paddingTop: 60,
              paddingBottom: 40,
            }}
            confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
          />

          <SweetAlert
            show={this.state.showAlertErrorComponent}
            onConfirm={() => this.setState({ showAlertErrorComponent: false })}
            error
            title="Erreur envoie livrable, veuillez reessayer!!!"
            style={{
              color: "#d9534f",
              fontSize: "12px",
              fontFamily: "Work Sans, sans-serif",
              paddingTop: 60,
              paddingBottom: 40,
            }}
            confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
          />
          <Modal
            className="modal-responsive-md"
            size={this.state.size}
            show={this.state.show}
            onHide={this.close}
          >
            <h5
              className="modal-bakeliste-title m-b-50"
              id="modalSendLivrableTitle"
            >
              Envoyer mon travail
            </h5>
            <button
              className="close-modal-bakeliste-title"
              onClick={this.close}
            >
              <FeatherIcon icon="x" aria-hidden="true" />
            </button>

            <form
              encType="multipart/form-data"
              id="livrable_form"
              onSubmit={(e) => this.onSendLivrablesComponent(e)}
            >
              <div className="form-group">
                <label style={{ color: "red", fontFamily: "bold" }}>
                  * Veuillez choisie une tâche .
                </label>
                <select
                  name="details_syllabus_id"
                  className="form-control select-modal-content-bakeliste"
                  id="addLivrableSelectTache"
                  onChange={(e) => this.handleChangeS(e)}
                  value={this.state.fieldss["details_syllabus_id"]}
                >
                  <option></option>
                  {this.state.ALL !== undefined &&
                    this.state.ALL.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.syllabus_task}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control input-modal-content-bakeliste p-t-15"
                  rows="9"
                  placeholder="Ajouter un commentaire"
                  name="comment"
                  value={this.state.fieldss["comment"]}
                  onChange={(e) => this.handleChangeS(e)}
                ></textarea>
              </div>
              <Dropzone
                required
                getUploadParams={this.getUploadParams}
                onChangeStatus={this.handleChangeStatusS}
                // onSubmit={this.handleSubmit}
                onChange={(e) => this.handlefileChange(e)}
                // onDrop={this.onDropS.bind(this)}
                accept="image/*,audio/*,video/*"
                inputContent={"Cliquer pour ajouter des images"}
                inputWithFilesContent={"Ajouter plus..."}
              />
              {this.state.isSendingLivrableComponentInProgress ? (
                <button
                  type="submit"
                  className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                  disabled
                >
                  Envoie en cours &nbsp;
                  <FeatherIcon icon="loader" aria-hidden="true" />
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                >
                  ENVOYER
                </button>
                //   )}
                // </button>
              )}
            </form>
          </Modal>
        </div>
      </Amplitude>
    );
  }
}
// export default connect(
//     ({ envoyerMonTravail }) => ({ ...envoyerMonTravail }),
//     dispatch => bindActionCreators({ ...envoyerMonTravailActions }, dispatch)
//   )( envoyerMonTravail );
