import React, { Component } from "react";
import "./listeBakelisteCoachAssistant.css";
import TableMyBakelistesCoachAssistant from "../tableMyBakelistesCoachAssistant/index";
//import Loader from "react-loader-spinner";
import Preloader from "../preloader/index";
import axios from "axios";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";

export default class listeBakelisteCoachAssistant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      api: API.API,
      coach_id: 0,
      userID: window.sessionStorage.getItem("user_id"),
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 4000);
    this.getDetailsCoachById();
  }
  getDetailsCoachById = () => {
    axios
      .get(this.state.api + "get-coach-by-id/" + this.state.userID)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            coach_id: res.data.data.coach_id,
          });
          //  console.log(this.state.detailCoach, this.state.coach_id)
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  render() {
    return this.state.loading ? (
      <div>
        <Preloader />
      </div>
    ) : (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-12">
            <div className="row m-b-15">
              <div className="col dis-flex m-b-15"></div>
            </div>
            <div className="card-dashboard m-b-15 p-b-60">
              <div className="row m-b-30 m-t-20">
                <div className="col-xl-7 col-lg-6 col-md-7 col-sm-6 col-6 m-b-15 dis-flex itm-center">
                  <h5 className="h-theme ff-inter fw-500">Mes bakelistes</h5>
                </div>
                <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
              </div>

              <TableMyBakelistesCoachAssistant coach_id={this.state.coach_id} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ listeBakelistesCoach }) => ({ ...listeBakelistesCoach }),
//     dispatch => bindActionCreators({ ...listeBakelistesCoachActions }, dispatch)
//   )( listeBakelistesCoach );
