import { combineReducers } from "redux";
import livraisons from "./reducers/livraisonsReducer";
import tachesBakeliste from "./reducers/tachesReducers";
import addLivraison from "./reducers/addLivraisonsReducer";
import syllabusReducer from "./reducers/syllabusReducer";
import livraisonsBakeliste from "./reducers/livraisonsBakelisteReducer1";
import demandeAbsence from "./reducers/demandeAbsenceReducer";
import groupesReducer from "./reducers/groupesReducer";
import listeCoachAssitantReducer from "./reducers/listeCoachAssitantReducer";
import cvBakeliste from "./reducers/cvBakelisteReducers";
import { reducer as formReducer } from "redux-form";
export default combineReducers({
  livraisons,
  tachesBakeliste,
  addLivraison,
  syllabusReducer,
  livraisonsBakeliste,
  groupesReducer,
  listeCoachAssitantReducer,
  demandeAbsence,
  cvBakeliste,
  form: formReducer,
});
