import React, { Component } from "react";
import "./tableNotesAllBakeliste.css";
import FeatherIcon from "feather-icons-react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Book from "../../image/profilb.png";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableNotesAllBakelisteActions from "../../store/tableNotesAllBakeliste/actions";
import axios from "axios";
import * as Sentry from "@sentry/react";

import API from "../../variablesGlobales";

// var gestionTacheForCoach = [{
//   fullname: "Ngone Diop",
//   telephone: "17/20",
//   date:     "18/20",
//   general: "16/20",
//   mention: "Tres bien"
// },{
//   fullname: "Aita Mbaye",
//   telephone: "15/20",
//   date:     "14/20",
//   general: "16/20",
//   mention: "bien"
// },{
//   fullname: "Dieyanaba Ly",
//   telephone: "12/20",
//   date:     "10",
//   general: "16/20",
//   mention: "bien"
// },{
//   fullname: "Adama Diakhaté",
//   telephone: "8/20",
//   date:     "9/20",
//   general: "7/20",
//   mention: "peux m faire"
// },{

//   fullname: "Fatma Dieng",
//   telephone: "17/20",
//   date:     "18/20",
//   general: "16/20",
//   mention: "Tres bien"
// },{
//   fullname: "Aisha Nd Keita",
//   telephone: "8/20",
//   date:     "8/20",
//   general: "8/20",
//   mention: "peux m faire"
// },{
//   fullname: "Lucresse",
//   telephone: "17/20",
//   date:     "18/20",
//   general: "16/20",
//   mention: "Tres bien"
// },
// ];

export default class tableNotesAllBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mention: "",
      Bakeliste: [],
      api: API.API,
      coach_id: window.sessionStorage.getItem("user_id"),
    };
  }

  ImageFormatter = (cell, row) => {
    return (
      <div>
        <img style={{ width: 44, height: 42, borderRadius: 50 }} src={Book} />
      </div>
    );
  };

  Mention = (Column, row) => {
    // console.log("asss", row);
    return (
      <div className="la-taille-div">
        {row.mention === "Tres bien" ? (
          <div className="t-btn-mention">
            <span className="t-b-color">Trés bien</span>
          </div>
        ) : (
          <></>
        )}

        {row.mention === "bien" ? (
          <div className="t-btn-mention">
            <span className="b-color">bien</span>
          </div>
        ) : (
          <></>
        )}

        {row.mention === "peux m faire" ? (
          <div className="t-btn-mention">
            <span className="p-m-color">peux m faire</span>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };
  componentDidMount = () => {
    this.getScoreByBakeliste();
  };
  getScoreByBakeliste() {
    const _this = this;

    axios
      .get(this.state.api + "get_score_bakelistes/" + this.state.coach_id)
      .then((response) => {
        if (response.data.data !== undefined) {
          this.setState({
            Bakeliste: response.data.data,
          });
          // console.log(this.state.Bakeliste);
          // console.log(response);

          this.state.Bakeliste.forEach((item) => {
            this.state.datas.push(item.sous_groupes);
          });
        } else {
          this.setState({
            Bakeliste: 0,
          });
        }
        // _this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        // _this.hideLoader();
      });
  }

  nameFormatter = (column, row) => {
    return (
      <strong>
        {row.first_name} {row.last_name}
      </strong>
    );
  };

  getLastScore = (cell, row) => {
    let table = row.score_examen_theoriques;
    // console.log(table.length);
    if (table.length > 0) {
      let lastRow = table[table.length - 1];
      return lastRow.score;
    } else {
      return "non renseigne";
    }
  };
  render() {
    return (
      <div className="component-table-notes-all-bakeliste">
        <div className="container-fluid bg-white pt-5 pb-5">
          <div className="card title-table pl-5">
            <span className="table-titles">
              TABLEAU DE NOTES DE TOUS LES BAKELISTES
            </span>
          </div>
          <div className="card">
            <BootstrapTable
              pagination
              data={this.state.Bakeliste}
              striped
              version="4"
              bordered={false}
              bodyStyle={{
                fontSize: 12,
                color: "rgba(0, 0, 0, 0.7)",
                fontFamily: "inherit",
                fontWeight: 400,
                border: 1,
              }}
              tdStyle={{ background: "none" }}
              tableHeaderclassName="header-table-groupe-view"
              wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
              className="table-responsive table-responsive-sm"
            >
              <TableHeaderColumn
                dataFormat={this.ImageFormatter}
                tdStyle={{ border: "0.5px solid rgba(0, 0, 0, 0.5)" }}
                dataField="image"
                width="80"
              >
                <span className="color-nom-prenom-utilisateurs">Profil</span>
              </TableHeaderColumn>
              <TableHeaderColumn
                isKey
                dataFormat={this.nameFormatter}
                dataField="fullname"
                thStyle={{ fontWeight: "400" }}
                tdStyle={{
                  border: "0.5px solid rgba(0, 0, 0, 0.5)",
                  padding: "1rem",
                  "font-size": "14px",
                  "font-family": "Montserrat",
                }}
              >
                <span className="color-nom-prenom-utilisateurs">
                  Prenom et Nom
                </span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="telephone"
                dataFormat={this.getLastScore}
                thStyle={{ fontWeight: "400" }}
                tdStyle={{
                  border: "0.5px solid rgba(0, 0, 0, 0.5)",
                  padding: "1.2rem",
                  "font-size": "14px",
                  "font-family": "Montserrat",
                }}
              >
                <span className="color-nom-prenom-utilisateurs">
                  N.Théorique
                </span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="date"
                thStyle={{
                  border: "none",
                  fontWeight: "400",
                  textAlign: "center",
                }}
                tdStyle={{
                  border: "0.5px solid rgba(0, 0, 0, 0.5)",
                  justifyContent: "center",
                  padding: "1.2rem",
                  "font-size": "14px",
                  "font-family": "Montserrat",
                }}
              >
                <span className="color-nom-prenom-utilisateurs">
                  N.Pratique
                </span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="general"
                thStyle={{ fontWeight: "300", textAlign: "center" }}
                tdStyle={{
                  border: "0.5px solid rgba(0, 0, 0, 0.5)",
                  justifyContent: "center",
                  padding: "1.2rem",
                  "font-size": "14px",
                  "font-family": "Montserrat",
                }}
              >
                <span className="color-nom-prenom-utilisateurs">
                  M.Générale
                </span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="mention"
                dataFormat={this.Mention}
                thStyle={{ fontWeight: "300", textAlign: "center" }}
                tdStyle={{
                  border: "0.5px solid rgba(0, 0, 0, 0.5)",
                  justifyContent: "center",
                  padding: "1.2rem",
                  "font-size": "14px",
                  "font-family": "Montserrat",
                }}
              >
                <span className="color-nom-prenom-utilisateurs">Mension</span>
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ tableNotesAllBakeliste }) => ({ ...tableNotesAllBakeliste }),
//     dispatch => bindActionCreators({ ...tableNotesAllBakelisteActions }, dispatch)
//   )( tableNotesAllBakeliste );
