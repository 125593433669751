import React, { Component } from "react";
import "./editQuestion.css";
import Select from "react-select";
import axios from "axios";
import API from "../../variablesGlobales";
import { Link } from "react-router-dom";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import { toast } from "react-toastify";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentModifierQuestionActions from "../../store/contentModifierQuestion/actions";
export default class editQuestion extends Component {
  constructor(props) {
    super(props);
    // console.log(API.API);
    this.state = {
      item: {
        question: "",
        opt1: "",
        opt2: "",
        opt3: "",
        opt4: "",
        libelle:"",
        correct_reponse: "",
        point: 1,
        api: API.API,
        is_archived: false,
        created_by: window.sessionStorage.getItem("id"),
        is_verified: false,
        module_id: 0,
        selectOptions: [],
      },
      isLoading: false,
    };
  }

  componentDidMount = () => {
    // console.log(this.state.api);
    axios
      .get(API.API + "questions/" + this.props.id)
      .then((res) => {
        // console.log(res.data.data);
        this.setState({ item: res.data.data });
        this.getModules();
      })

      .catch(err => {
      // console.log(err);
      }
    )
    }
  handleChange = (e) => {
    e.persist();

    this.setState((prevState) => ({
      item: { ...prevState.item, [e.target.name]: e.target.value },
    }));
  };


  async getModules() {
    try {
      const response = await axios.get(API.API + "domaines");
      this.setState({ selectOptions: response.data });

      // console.log(response.data.data);
      const options = response.data.data.map((d) => ({
        value: d.id,
        label: d.name,
      }));
      // console.log(options);
      this.setState({ selectOptions: options });
    } catch (error) {
      // console.log(error);
    }
  }
  onChange = (e) => {
    // console.log('eeeee',e.value)
    this.setState({ module_id: e.value });
  };
  onContinue = (e) => {
    e.preventDefault();
    //  console.log(this.state.fields); //will give you the value continue

    this.setState({ isLoading: true });
    // console.log("item", this.state.item);
    axios
      .put(API.API + "questions/" + this.props.id, this.state.item)
      .then((result) => {
        // console.log(result);
        this.setState({
          isLoading: false,
        });
        // console.log(result);
        if (result.data.success) {
          toast.success(
            "question modifié avec success",
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 3000 }
          );
          window.location = "/liste-questions";
        } else {
          toast.error(
            "erreur modification question",
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 3000 }
          );
        }
      });
  };
  render() {
    return (
      <div className="p-b-200 container-fluid px-md-3 p-0">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item ">
                  <Link to="/dashboard-coach">Dashboard</Link>
                </li>
                <li className="breadcrumb-item ">
                  <Link to="/liste-questions">Questions</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Ajout Question
                </li>
              </ol>
            </nav>

            <h6 className="h-theme ff-inter fw-600">Ajout Questions</h6>
            <div className="col dis-flex m-b-15 mto-3"></div>
            <div className="container-fluid questionnaire">
              <form className="post">
                <div className="row pr-5 pt-5">
                  <div className="col-xl-12 pb-3">
                    <Select
                      options={this.state.selectOptions}
                      id="module_id"
                      value={this.state.item.module_id}
                      onChange={this.onChange}
                      className="input-add-quiz"
                    />
                  </div>
                </div>
                <div className="row pr-5">
                  <div className="col-xl-12 pb-3">
                    <input
                      type="text"
                      className="input-add-quiz"
                      placeholder="Saisir la question"
                      name="libelle"
                      value={this.state.item.libelle}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row pr-5">
                  <div className="col-xl-12 pb-3">
                    <input
                      type="text"
                      className="input-add-quiz"
                      placeholder="Réponse 2"
                      name="opt1"
                      value={this.state.item.opt1}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row pr-5">
                  <div className="col-xl-12 pb-3">
                    <input
                      type="text"
                      className="input-add-quiz"
                      placeholder="Réponse 2"
                      name="opt2"
                      value={this.state.item.opt2}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row pr-5">
                  <div className="col-xl-12 pb-3">
                    <input
                      type="text"
                      className="input-add-quiz"
                      placeholder="Réponse 3"
                      name="opt3"
                      value={this.state.item.opt3}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row pr-5">
                  <div className="col-xl-12 pb-3">
                    <input
                      type="text"
                      className="input-add-quiz"
                      placeholder="Réponse 4"
                      name="opt4"
                      value={this.state.item.opt4}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row pr-5">
                  <div className="col-xl-12">
                    <select
                      className="input-add-quiz"
                      name="correct_reponse"
                      id="correct_reponse"
                      value={this.state.item.correct_reponse}
                      onChange={this.handleChange}
                    >
                      <option>Choisir une réponse</option>
                      <option name="opt1" value="opt1">
                        reponse 1
                      </option>
                      <option name="opt2" value="opt2">
                        reponse 2
                      </option>
                      <option name="opt3" value="opt3">
                        reponse 3
                      </option>
                      <option name="opt4" value="opt4">
                        reponse 4
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row pr-5 py-5 d-flex justify-content-end">
                  <div className="col-xl-3 col-lg-3 col-md-3">
                    <button
                      className="btn-add-exam"
                      type="submit"
                      value="continue"
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3">
                    <button
                      className="add-question"
                      type="submit"
                      onClick={(e) => this.onContinue(e)}
                    >
                      {" "}
                      Modifier
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteGroupItemForCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ contentModifierQuestion }) => ({ ...contentModifierQuestion }),
//     dispatch => bindActionCreators({ ...contentModifierQuestionActions }, dispatch)
//   )( contentModifierQuestion );
