import React, { Component } from "react";
import "./commments.css";
import frenchFormatDate from "../frenchFormatDate";
import IconComment from "../../img/icons/comment.png";
import IconSendComment from "../../img/icons/send1.png";
import FeatherIcon from "feather-icons-react";
import { Input, InputGroup } from "rsuite";
import axios from "axios";
import API from "../../variablesGlobales";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as commmentsActions from "../../store/commments/actions";
export default class commments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frenchFormat: frenchFormatDate.frenchFormatDate,
      commentaires: this.props.comments,
      showReplyForm: false,
      api: API.API,
      user_id: window.sessionStorage.getItem("user_id"),
      user_type: window.sessionStorage.getItem("user_status"),
      newReply: {
        comment_text: "",
        // bakeliste_id: 0,
        commentable_id: 0,
        // coach_id: 0,
        user_type: "",
        parent_id: "",
        livrable_id: 0,
        voirCommentairesC: false,
      },
    };
  }
  showReplyForm = () => {
    this.setState({
      showReplyForm: !this.state.showReplyForm,
    });
  };
  voirCommentsC = (e) => {
    this.setState({
      voirCommentairesC: true,
    });
  };
  cacherCommentsC = (e) => {
    this.setState({
      voirCommentairesC: false,
    });
  };
  
  addReplyComment = (e) => {
    e.preventDefault();
    let { newReply } = this.state;
    // newReply.isOk = this.state.liked;
    // newReply.isnOk = this.state.notliked;

    newReply.user_id = this.state.user_id;
    newReply.user_type = this.state.user_type;
    this.setState({ newReply });
    axios
      .post(this.state.api + "reply/store", this.state.newReply)
      .then((response) => {
        newReply.comment_text = "";
        this.setState({
          newReply,
          // showBloc: false,
        });

        // document.getElementById("comment-livrable").reset();

        if (response.success) {
        }
      })

      .catch((error) => {});
  };

  ongetComments(data) {
    if (this.props.commentaires.length > 0) {
      this.props.commentaires.map((item, indexx) => {
        item.map((i, ix) => {
          var comment = {
            text: i.comment_text,
            username: i.fullname,
            fichier: i.comment_file,
            parent: i.parent_id,
          };

          this.state.commentaires.push(comment);
        });
      });
    }
    return this.state.commentaires;
  }

  render() {
    return (
        <div className="row">
          <div className="col-md-12 mt-5">
            <div className="blog-comment">
              <div className="row">
                <div className="col-md-6">
                  <h5> {this.state.commentaires.length} &nbsp; Commentaires</h5>
                </div>
                <div className="col-md-5">
                {this.state.voirCommentairesC === true ? (
                  <div className="dropdown">
                    <button
                        className="btn-theme dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                       Tous les commentaires{" "}
                      </button>
                      <div className="dropdown-menu">
                        <button
                          className="btn"
                          onClick={(e) => this.cacherCommentsC()}
                        >
                          Plus récent{" "}
                        </button>
                        <button
                          className="btn"
                          onClick={(e) => this.voirCommentsC()}
                        >
                          Tous les commentaires{" "}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="dropdown">
                      <button
                        className="btn-theme dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Plus récent
                      </button>
                      <div className="dropdown-menu">
                        <button
                          className="btn"
                          onClick={(e) => this.cacherCommentsC()}
                        >
                          Plus récent{" "}
                        </button>
                        <button
                          className="btn"
                          onClick={(e) => this.voirCommentsC()}
                        >
                          Tous les commentaires{" "}
                        </button>
                      </div>
                    </div>
                  )}
                </div>

              <hr />

              <ul className="comments">
                {this.state.commentaires.map((i, ix) =>
                  this.state.commentaires.length - 1 === ix ? (
                    <>
                      <li className="clearfix" key={ix}>
                        <div className="post-comments mt-3">
                          {i.user.first_name} {i.user.last_name}
                          <i className="p-3">
                            {i.user_type === "bakeliste" ? (
                              <small className="bakeliste">{i.user_type}</small>
                            ) : (
                              <small className="coach">Coach</small>
                            )}
                          </i>
                          <span className="meta pull-right">
                            {this.state.frenchFormat(i.created_at)}{" "}
                          </span>
                          <p className="comment mt-3">{i.comment_text}</p>
                        </div>
                        <form
                          key={ix}
                          className="collapse"
                          id={`collapseExample${ix}`}
                        >
                          <div className="row m-t-25 m-b-5">
                            <div className="px-lg-2 col-sm-2 col-3 px-md-3 px-sm-2 dis-flex itm-center justify-content-center">
                              <button className="btn-like-fil-actualite-card mr-xl-3 mr-lg-2 pr-sm-3 px-lg-0">
                                <FeatherIcon
                                  className="icon-btn-like-fil-actualite-card"
                                  icon="thumbs-up"
                                />
                              </button>
                              <button className="btn-like-fil-actualite-card px-lg-0">
                                <FeatherIcon
                                  className="icon-btn-like-fil-actualite-card"
                                  icon="thumbs-down"
                                />
                              </button>
                            </div>
                            <div className="col-sm-10 col-9 pl-0 pr-2 pr-sm-2 pr-md-3">
                              <InputGroup inside>
                                <InputGroup.Addon>
                                  <img
                                    className="icon-comment-fil-actualite"
                                    src={IconComment}
                                    alt=""
                                  />
                                </InputGroup.Addon>
                                <Input
                                  placeholder="Repondre a  un commentaire"
                                  className="input-comment-fil-actualite"
                                  name="comment"
                                  value={this.state.newReply.comment_text}
                                  onChange={(value, event) => {
                                    let { newReply } = this.state;
                                    newReply.comment_text = value;
                                    newReply.livrable_id = i.commentable_id;
                                    newReply.parent_id = i.id;
                                    this.setState({
                                      newReply,
                                    });
                                    // console.log(i);
                                  }}
                                />
                                <button
                                  className="btn-send-comment-fil-actualite"
                                  type="submit"
                                  onClick={this.addReplyComment}
                                >
                                  <img
                                    className="icon-btn-send-comment-fil-actualite"
                                    src={IconSendComment}
                                    alt=""
                                  />
                                </button>
                              </InputGroup>
                            </div>
                          </div>
                        </form>
                      </li>
                    </>
                  ) : this.state.voirCommentairesC === true ? (
                    <>
                      <li className="clearfix">
                        <div className="post-comments mt-3">
                          {i.user.first_name} {i.user.last_name}
                          <i className="p-3">
                            {i.user_type === "bakeliste" ? (
                              <small className="bakeliste">{i.user_type}</small>
                            ) : (
                              <small className="coach">Coach</small>
                            )}
                          </i>
                          <span className="meta pull-right">
                            {this.state.frenchFormat(i.created_at)}{" "}
                          </span>
                          <p className="comment mt-3">{i.comment_text}</p>
                        </div>
                        <form
                          key={ix}
                          className="collapse"
                          id={`collapseExample${ix}`}
                        >
                          <div className="row m-t-25 m-b-5">
                            <div className="px-lg-2 col-sm-2 col-3 px-md-3 px-sm-2 dis-flex itm-center justify-content-center">
                              <button className="btn-like-fil-actualite-card mr-xl-3 mr-lg-2 pr-sm-3 px-lg-0">
                                <FeatherIcon
                                  className="icon-btn-like-fil-actualite-card"
                                  icon="thumbs-up"
                                />
                              </button>
                              <button className="btn-like-fil-actualite-card px-lg-0">
                                <FeatherIcon
                                  className="icon-btn-like-fil-actualite-card"
                                  icon="thumbs-down"
                                />
                              </button>
                            </div>
                            <div className="col-sm-10 col-9 pl-0 pr-2 pr-sm-2 pr-md-3">
                              <InputGroup inside>
                                <InputGroup.Addon>
                                  <img
                                    className="icon-comment-fil-actualite"
                                    src={IconComment}
                                    alt=""
                                  />
                                </InputGroup.Addon>
                                <Input
                                  placeholder="Repondre a  un commentaire"
                                  className="input-comment-fil-actualite"
                                  name="comment"
                                  value={this.state.newReply.comment_text}
                                  onChange={(value, event) => { 
                                    let { newReply } = this.state;
                                    newReply.comment_text = value;
                                    newReply.livrable_id = i.commentable_id;
                                    newReply.parent_id = i.id;
                                    this.setState({
                                      newReply,
                                    });
                                    // console.log(i);
                                  }}
                                />
                                <button
                                  className="btn-send-comment-fil-actualite"
                                  type="submit"
                                  onClick={this.addReplyComment}
                                >
                                  <img
                                    className="icon-btn-send-comment-fil-actualite"
                                    src={IconSendComment}
                                    alt=""
                                  />
                                </button>
                                {/* {this.state.commentEnCours && (
                  <button
                    type="button"
                    className="btn-send-comment-fil-actualite"
                  >
                    Sending &nbsp;
                    <i className="fas fa-spinner fa-spin"></i>
                  </button>
                )} */}
                              </InputGroup>
                            </div>
                          </div>
                        </form>
                      </li>
                    </>
                  ) : (
                    <>
                      {/* <button className="btn-theme" onClick={(e) => this.voirCommentsC()} ><FeatherIcon icon="eye"/> &nbsp; Voir les {this.state.commentaires.length} commentaires</button> */}
                    </>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ commments }) => ({ ...commments }),
//     dispatch => bindActionCreators({ ...commmentsActions }, dispatch)
//   )( commments );
