import React, { Component } from "react";
import "./listeTacheBakeliste.css";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItem from "../bakelisteGroupItem/index";
import NextReunionItem from "../nextReunionItem/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import FeatherIcon from "feather-icons-react";
import TacheItem from "../tacheItem/index";
import TacheItemSyllabus from "../tacheItemSyllabus/index";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import Preloader from "../preloader/index";
import ListCoachAssistantRefact from "../listCoachAssistant/ListCoachAssistantRefact";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import ModalContactUs from "../modalContactUs/index";
import $ from "jquery";
import API from "../../variablesGlobales";
import base_api_url from "../../variablesGlobales";
import frenchFormatDate from "../frenchFormatDate";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "react-js-pagination";
import Dropzone from "react-dropzone-uploader";
import { Modal } from "rsuite";
import { Amplitude } from "@amplitude/react-amplitude";
import amplitude from "amplitude-js";
import Loader from "react-loader-spinner";
import ReactPlayer from "react-player";
import EnvoyerMonTravail from "../envoyerMonTravail/index";

export default class tacheBakeliste extends Component {
  constructor(props) {
    super(props);

    this.state = {
      api: API.API,
      // eventeDroite: "ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_V2_TACHE",

      tasksyllabusdone: [],
      tasksyllabustodo: [],
      tasksyllabusdoing: [],
      All: [],

      frenchFormat: frenchFormatDate.frenchFormatDate,
      bakeliste_id: sessionStorage.getItem("user_id"),
      email: sessionStorage.getItem("email"),
      coach_id: sessionStorage.getItem("coach_id"),
      taskdone: [],
      selectedTache: [],
      selectedTacheS: [],
      tasktodo: [],
      taskdoing: [],
      taskstatus: "",
      userNotConnected: false,
      taskSyllabus: [],
      fields: {},
      fieldss: {},
      statusUpdated: false,
      statusnotUpdated: false,
      statusSyllabusUpdated: false,
      statusSyllabusnotUpdated: false,
      activePage: "",
      count_task_todo: 0,
      per_page: 2,
      current_page: 1,
      showlivrable: false,
      total_todo: "",
      current_list_todo: [],
      isValidForm: false,
      is_empty_tache_file: false,
      isSendingLivrableInProgress: false,
      isSendingInProgress: false,
      alertSuccess: false,
      alertError: false,
      files: [],
      filess: [],
      base_api_url: base_api_url.base_api_url,
      rechercheTache: "",
      currentPage: 1,
      hasMore: true,
      isload: true,
    };
    Sentry.init({
      dsn: "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
    });
  }
  componentDidMount() {
    this.getTachesByStatus();
    this.getDetailsLastSyllabus();

    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("bakelisteLivrablePageVisite");
    setTimeout(() => {
      this.setState({
        isload: false,
      });
    }, 5000);
  }
  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);

    amplitude.getInstance().init(api_key);
  };

  getDetails() {
    const rechercheTache = this.state.rechercheTache.trim().toLocaleLowerCase();
    const syllabusDetails = this.state.All;
    if (rechercheTache.length < 1) {
      return syllabusDetails;
    }
    return syllabusDetails.filter((s) => {
      return s.syllabus_task.toLocaleLowerCase().includes(rechercheTache);
    });
  }

  onSendLivrables = (e) => {
    e.preventDefault();

    if (this.onValidateLivrablesFormData()) {
      this.setState({
        isSendingLivrableInProgress: true,
      });
      var data = this.state.fields;
      var files = this.state.files;
      // console.log("dataa", files);
      var fd = new FormData();
      var eventProperties = {
        user_id: this.state.bakeliste_id,
        tache_id: data.tache_id,
      };
      files.forEach((item) => {
        fd.append("tache_files", item);
        fd.append("commentaire", data.comment);
        fd.append("bakeliste_user_id", this.state.bakeliste_id);
        fd.append("tache_id", data.tache_id);
        //console.log("a soumettre", fd);
        axios
          .post(this.state.api + "livraisons", fd, {
            Headers: { "Content-Type": "multipart/formdata" },
          })
          .then((response) => {
            //  console.log("response", response)
            this.setState({
              isSendingLivrableInProgress: false,
            });

            if (response.data.success) {
              // $("#tache_file").val("");
              $(".close-modal-bakeliste-title").click();
              setTimeout(() => {
                this.setState({
                  alertSuccess: true,
                  showlivrable: false,
                });
              }, 1000);
              amplitude
                .getInstance()
                .logEvent(
                  "ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_V2_TACHE",
                  eventProperties
                );
            } else {
              this.setState({
                alertError: true,
              });
              setTimeout(() => {
                this.setState({
                  isErrorSending: false,
                });
              }, 1000);
              amplitude
                .getInstance()
                .logEvent(
                  "ENVOIE_LIVRABLE_BAKELISTE_ERROR_V2",
                  eventProperties
                );
            }
            $(".close-modal-bakeliste-title").click();
          })
          .catch((error) => {
            if ((error.message = "Request failed with status code 422")) {
            }
            amplitude
              .getInstance()
              .logEvent("ENVOIE_LIVRABLE_BAKELISTE_ERROR_V2", eventProperties);
            this.setState({
              isSendingLivrableInProgress: false,
              alertError: true,
            });
            setTimeout(() => {
              this.setState({
                alertError: false,
              });
            }, 1000);
            if (process.env.NODE_ENV !== "production") {
              return;
            }
            Sentry.captureException(error);
          });
      });
    }
  };

  SendLivrables = (e) => {
    e.preventDefault();
    if (this.onValidateLivrables()) {
      this.setState({
        isSendingInProgress: true,
      });
      const id = this.state.bakeliste_id;
      const email = this.state.email;
      //console.log(id, email);

      var dataS = this.state.fieldss;
      // console.log("datass", dataS);
      var filess = this.state.filess;
      // console.log("filesshhhhhhh", filess);

      var eventProperties = {
        user_id: id,
        email: email,
        details_syllabus_id: dataS.details_syllabus_id,
      };
      if (navigator.onLine) {
        filess.map((image) => {
          var fd = new FormData();

          fd.append("commentaire", dataS.comment);
          fd.append("bakeliste_user_id", this.state.bakeliste_id);
          fd.append("details_syllabus_id", dataS.details_syllabus_id);

          fd.append("tache_files", image);
          axios
            .post(this.state.api + "livraisons", fd, {
              Headers: { "Content-Type": "multipart/formdata" },
            })
            .then((response) => {
              //  console.log("response", response)
              this.setState({
                isSendingInProgress: false,
              });

              if (response.data.success) {
                // $("#tache_file").val("");
                $(".close-modal-bakeliste-title").click();
                setTimeout(() => {
                  this.setState({
                    alertSucces: true,
                    showlivrable: false,
                  });
                }, 1000);
                amplitude
                  .getInstance()
                  .logEvent(
                    "ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_TACHES_V2",
                    eventProperties
                  );
              } else {
                this.setState({
                  alertError: true,
                });
                setTimeout(() => {
                  this.setState({
                    isErrorSending: false,
                  });
                }, 1000);
                amplitude
                  .getInstance()
                  .logEvent(
                    "ENVOIE_LIVRABLE_BAKELISTE_ERROR_V2",
                    eventProperties
                  );
              }
              $(".close-modal-bakeliste-title").click();
            })
            .catch((error) => {
              if ((error.message = "Request failed with status code 422")) {
              }
              amplitude
                .getInstance()
                .logEvent(
                  "ENVOIE_LIVRABLE_BAKELISTE_ERROR_V2",
                  eventProperties
                );
              this.setState({
                isSendingInProgress: false,
                alertError: true,
              });
              if (process.env.NODE_ENV !== "production") {
                return;
              }
              Sentry.captureException(error);
            });
        });
        $(".close-modal-bakeliste-title").click();

        setTimeout(() => {
          this.setState({
            alertSuccess: true,
            showlivrable: false,
          });
        }, 1000);
      } else {
        this.setState({ userNotConnected: true });
        amplitude
          .getInstance()
          .logEvent("ERROR_CONNEXION_ENVOIE_LIVRABLE_V2", eventProperties);
      }
    }
  };

  onValidateLivrables() {
    let fieldss = this.state.fieldss;
    let errors = this.state.errors;
    var isValidForm = true;

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }

  onValidateLivrablesFormData() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  handleChangeS = (e) => {
    let fieldss = this.state.fieldss;
    fieldss[e.target.name] = e.target.value;
    this.setState({ fieldss });
  };

  handleChangeSyllabus = (e) => {
    let fieldss = this.state.fieldss;
    fieldss[e.target.name] = e.target.value;
    this.setState({ fieldss });
  };

  getTachesByStatus = () => {
    axios
      .get(
        this.state.api +
          "get-taches-bakeliste-by-status/" +
          this.state.bakeliste_id
      )
      .then((response) => {
        this.setState({
          taskdone: response.data.taskdone.reverse(),
          tasktodo: response.data.tasktodo,
          count_task_todo: response.data.tasktodo.length,
          total_todo: response.data.tasktodo.length,
          taskdoing: response.data.taskdoing,
        });
        this.formatData();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getDetailsLastSyllabus = () => {
    axios
      .get(
        this.state.api + "bakeliste-details-syllabus/" + this.state.bakeliste_id
      )
      .then((response) => {
        // console.log(response);
        this.setState({
          All: response.data.all_taches_syllabus.data.reverse(),
        });
        // console.log(this.state.All)
        this.formatData();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  fetchMoreData = () => {
    setTimeout(() => {
      this.setState({
        currentPage: this.state.currentPage + 1,
        loading: true,
      });
      axios
        .get(
          this.state.api +
            "bakeliste-details-syllabus/" +
            this.state.bakeliste_id +
            "/?page=" +
            this.state.currentPage
        )
        .then((response) => {
          if (response.data.success === true) {
            Object.values(response.data.all_taches_syllabus.data).forEach(
              (element) => {
                this.state.All.push(element);
              }
            );

            if (
              response.data.all_taches_syllabus.current_page ===
              response.data.all_taches_syllabus.last_page
            ) {
              this.setState({ hasMore: false, loading: false });
            } else {
              this.setState({ hasMore: true, loading: true });
            }
          }
        });
    }, 500);

    return this.state.All;
  };

  formatData = () => {
    const indexOfLastTaskTodo = this.state.current_page * this.state.per_page;
    const indexOfFirstTaskTodo = indexOfLastTaskTodo - this.state.per_page;
    const current_list_todo = this.state.All.slice(
      indexOfFirstTaskTodo,
      indexOfLastTaskTodo
    );
    this.setState({
      current_list_todo,
    });
  };

  handleClick = (number) => {
    this.setState(
      {
        current_page: number,
      },
      () => {
        this.formatData();
      }
    );
  };

  onStatusChanged = (id) => (e) => {
    //console.log("detailsss", id);
    let tacheID = id;
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["bakeliste_id"] = this.state.bakeliste_id;
    this.setState({
      fields: fields,
    });
    // console.log("fields",fields);
    axios
      .post(
        this.state.api + "update-tache-status-by-bakeliste/" + tacheID,
        this.state.fields
      )
      .then((response) => {
        // console.log("response",response.data);
        if (response.data.success === true) {
          this.setState({
            statusUpdated: true,
            statusnotUpdated: false,
          });
          // window.location.reload();
          this.getTachesByStatus();
          this.getDetailsLastSyllabus();
        } else {
          this.setState({
            statusUpdated: false,
            statusnotUpdated: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isUpdatedError: true,
          tacheUpdatedInProgress: false,
          updateTacheStatusError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  onStatusChangedSyllabus = (idd, id) => (e) => {
    // console.log("detailsss", idd);
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["bakeliste_id"] = this.state.bakeliste_id;
    fields["syllabus_id"] = id;
    fields["detail_syllabus_id"] = idd;
    fields["coach_id"] = this.state.coach_id;

    //console.log("fields",fields);
    this.setState({
      fields: fields,
    });
    axios
      .post(this.state.api + "task_excutions/", this.state.fields)
      .then((response) => {
        if (response.data.success === true) {
          this.getTachesByStatus();
          this.getDetailsLastSyllabus();
        } else {
          this.setState({
            statusSyllabusUpdated: false,
            statusSyllabusnotUpdated: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isUpdatedError: true,
          tacheSyllabusUpdatedInProgress: false,
          updateTacheSyllabusStatusError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  handleChange1 = (e) => {
    let fields = this.state.fields;

    if (e.target.name === "tache_file") {
      if (this.fileValidation("tache_file")) {
        fields["tache_file"] = e.target.files[0];
      } else {
        this.setState({
          isInvalidFileExtension: true,
        });
        setTimeout(() => {
          this.setState({
            isInvalidFileExtension: false,
          });
        }, 6000);
      }
    } else {
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields: fields,
    });
    //console.log(fields);
  };

  toggleModal = () => {
    $("#addLivraisonBakelisteSyllabus").modal("show");
  };

  fileValidation = (elementID) => {
    var fileInput = document.getElementById(elementID);

    var filePath = fileInput.value;

    // Allowing file type
    var allowedExtensions = /(\.png|\.jpg|\.jpeg|)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  };

  fileLivrableValidation = (elementID) => {
    var fileInput = document.getElementById(elementID);

    var filePath = fileInput.value;

    // Allowing file type
    var allowedExtensions = /(\.png|\.jpg|\.jpeg|)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  };

  onDrop = (files) => {
    // alert("goooooooooooo");
    this.setState({
      files: files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
    const uploaders = files.map((file) => {
      const formData = new FormData();
      formData.append("file", file);

      return axios.post("https://httpbin.org/post", formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" },
      });
    });
  };

  onDropS = (filess) => {
    // alert("goooooooooooo");
    this.setState({
      filess: filess.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
    const uploaders = filess.map((file) => {
      const formData = new FormData();
      formData.append("file", file);

      return axios.post("https://httpbin.org/post", formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" },
      });
    });
  };

  handlefileChange = (e) => {
    let fields = this.state.fields;
    if (e.target.name === "tache_file") {
      if (this.fileLivrableValidation("tache_file")) {
        fields["tache_file"] = e.target.files[0];
      } else {
        this.setState({
          isInvalidFileExtension: true,
        });
        setTimeout(() => {
          this.setState({
            isInvalidFileExtension: false,
          });
        }, 6000);
      }
    } else {
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields: fields,
    });
    // console.log(this.state.fields);
  };
  handlefileChangeS = (e) => {
    let fieldss = this.state.fieldsS;
    if (e.target.name === "tache_file") {
      if (this.fileLivrableValidation("tache_file")) {
        fieldss["tache_file"] = e.target.files[0];
      } else {
        this.setState({
          isInvalidFileExtension: true,
        });
        setTimeout(() => {
          this.setState({
            isInvalidFileExtension: false,
          });
        }, 6000);
      }
    } else {
      fieldss[e.target.name] = e.target.value;
    }

    this.setState({
      fields: fieldss,
    });
    //console.log(this.state.fieldss);
  };

  openLivrable = (sizelivrable) => {
    this.setState({
      sizelivrable,
      showlivrable: true,
    });
  };
  openLivrableSyllabus = (sizelivrableSyllabus) => {
    this.setState({
      sizelivrableSyllabus,
      showlivrableSyllabus: true,
    });
  };
  closeLivrable = () => {
    this.setState({
      showlivrable: false,
    });
  };
  closeLivrableSyllabus = () => {
    this.setState({
      showlivrableSyllabus: false,
    });
  };

  isValidURL = (string) => {
    var res = string.match(
      // /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    );
    return res !== null;
  };

  // called every time a file's `status` changes
  handleChangeStatus = ({ meta, file }, status) => {
    const files = file;
    if (status === "done") {
      // console.log(status, meta, file);
      // files.push(file);
      this.setState({ files });
    }

    // this.setState({ files: files });
    //console.log("filesss", this.state.files);
  };
  handleChangeStatusS = ({ meta, file }, status) => {
    const filess = file;

    if (status === "done") {
      this.setState({ filess: this.state.filess.concat(file) });
      // this.setState({
      //   filess,
      // });
    }

    // console.log("kxkxkxkx", this.state.filess);
  };
  // receives array of files that are done uploading when submit button is clicked
  handleSubmit = (files, allFiles) => {
    // console.log(files.map((f) => f.meta));
    // console.log("all files", allFiles);
    allFiles.map((file) => {
      const formData = new FormData();
      formData.append("file", file.file);
      //console.log("formatData", formData);
    });

    // allFiles.forEach((f) => f.remove());
  };

  voirTacheValidees = () => {
    window.location = "/liste-taches-validees";
  };
  render() {
    var {
      per_page,
      total_todo,
      current_page,
      current_list_todo,
      tasksyllabusdone,
      tasksyllabustodo,
    } = this.state;

    return this.state.isload ? (
      <div>
        <Preloader />
      </div>
    ) : (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item ">
                  <Link to="/dashboard-bakeliste">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Tâches
                </li>
                {/* <div className="search offset-md-5">
                <div className="input-group size1"> */}
                <div className="input-group-prepend SearchB offset-md-5">
                  <FeatherIcon className="search_icon" icon="search" />
                  <input
                    value={this.state.rechercheTache}
                    type="search"
                    className="form-control form-control-costom"
                    placeholder="Chercher une tache"
                    aria-label="Search"
                    aria-describedby="search-addon"
                    icon="Search"
                    onChange={(e) =>
                      this.setState({
                        rechercheTache: e.target.value,
                      })
                    }
                  />
                </div>

                {/* </div>
              </div> */}
              </ol>
            </nav>
            <div className="row m-b-15 pt6">
              <div className="col-md-6 m-b-15">
                <h6 className="h-theme ff-inter fw-600 ">
                  Liste de vos tâches
                </h6>
              </div>
              <div className="col-md-6">
                <button
                  className="btn-theme-orange offset-md-4"
                  onClick={() => this.voirTacheValidees()}
                >
                  Mes tâches validées
                </button>
              </div>
            </div>

            {/* Alert Livrable envoye avec succes */}
            <SweetAlert
              show={this.state.alertSuccess}
              onConfirm={() => this.setState({ alertSuccess: false })}
              success
              title="Livrable envoyé avec succes!!!"
              style={{
                color: "#069A77",
                fontSize: "12px",
                fontFamily: "Work Sans, sans-serif",
                paddingTop: 60,
                paddingBottom: 40,
              }}
              confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
            />

            <SweetAlert
              show={this.state.userNotConnected}
              onConfirm={() => this.setState({ userNotConnected: false })}
              error
              title="Vous n'etes pas connecte, Merci de verifier votre connexion pour continuer!"
              style={{
                color: "#d9534f",
                fontSize: "12px",
                fontFamily: "Work Sans, sans-serif",
                paddingTop: 60,
                paddingBottom: 40,
              }}
              confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
            />
            {/* ENd ALert Succes */}

            {/* Alert Livrable non envoye */}
            <SweetAlert
              show={this.state.alertError}
              onConfirm={() => this.setState({ alertError: false })}
              error
              title="Erreur sur envoie livrable, veuillez reessayer!!!"
              style={{
                color: "#d9534f",
                fontSize: "12px",
                fontFamily: "Work Sans, sans-serif",
                paddingTop: 60,
                paddingBottom: 40,
              }}
              confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
            />

            <SweetAlert
              show={this.state.statusSyllabusnotUpdated}
              onConfirm={() =>
                this.setState({ statusSyllabusnotUpdated: true })
              }
              error
              title="Erreur modification Statut!!!"
              style={{
                color: "#069A77",
                fontSize: "12px",
                fontFamily: "Work Sans, sans-serif",
                paddingTop: 60,
                paddingBottom: 40,
              }}
              confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
            />
            {/* ENd ALert Error */}
            <SweetAlert
              show={this.state.statusUpdated}
              onConfirm={() => this.setState({ statusUpdated: false })}
              success
              title="Statut tache modifié!!!"
              style={{
                color: "#069A77",
                fontSize: "12px",
                fontFamily: "Work Sans, sans-serif",
                paddingTop: 60,
                paddingBottom: 40,
              }}
              confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
            />
            <SweetAlert
              show={this.state.statusnotUpdated}
              onConfirm={() => this.setState({ statusnotUpdated: true })}
              error
              title="Erreur modification Statut!!!"
              style={{
                color: "#069A77",
                fontSize: "12px",
                fontFamily: "Work Sans, sans-serif",
                paddingTop: 60,
                paddingBottom: 40,
              }}
              confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
            />

            {/* Toutes les taches du baekliste */}
            <InfiniteScroll
              className="hello"
              dataLength={this.state.All.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMore}
              loader={
                this.state.loading &&
                "Les donnees sont entrain de se charger...."
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Vous avez tous visualise!!!</b>
                </p>
              }
            >
              {
                this.state.All.length > 0 &&
                  //  this.state.All.map((item, index) => (
                  this.getDetails().map((item, index) => (
                    <div className="col-xl-12 col-lg-12 col-md-9">
                      <div className="card-dashboard m-b-15">
                        <div className="row mb-4">
                          <div className="col-sm-8 mb-sm-0 mb-3">
                            <p className="h-theme ff-work fw-500">
                              {/* Tâches {index + 1} */}
                            </p>
                            <p className="ff-work fs-14 fw-500 text-dark">
                              {item.syllabus_task}
                            </p>
                            {item.description}
                          </div>
                          <div className="col-sm-4 mb-sm-0 mb-3 itm-right">
                            <FeatherIcon
                              className="icon-tache-bakeliste-limit-hours"
                              icon="clock"
                            />
                            <div>
                              <p className="tache-bakeliste-limit-hours fw-300 text-up">
                                Duree tache :
                              </p>
                              <p className="tache-bakeliste-limit-hours fw-500 text-cap">
                                {item.syllabus_completion_time} h
                              </p>
                            </div>
                            <p className="fs-12 m-t-30">
                              <span className="flag">{item.syllabus_name}</span>
                            </p>
                          </div>
                        </div>

                        <p className="ff-work fs-12 fw-500 text-dark m-b-10">
                          Liens utiles
                        </p>

                        {item.tache_url !== null &&
                        this.isValidURL(item.tache_url) ? (
                          <div>
                            <ReactPlayer
                              url={item.tache_url}
                              width="100%"
                              controls={true}
                            />
                          </div>
                        ) : (
                          <a
                            className="lien-utile-bakeliste-tache-card"
                            href={item.tache_url}
                            target="blank"
                          >
                            {item.tache_url}
                          </a>
                        )}

                        {item.tache_url !== null && item.tache_file ? (
                          <div classname="thumbnail">
                            <p className="ff-work fs-12 fw-500 text-dark m-b-10 m-t-30">
                              Pièces jointes
                            </p>
                            <img
                              src={this.state.base_api_url + item.tache_file}
                              alt="fichier tache"
                              style={{ width: "100px" }}
                            />
                          </div>
                        ) : (
                          <div classname="thumbnail">
                            {/* Cette tâche n'a pas de pièces */}
                          </div>
                        )}
                        <div className="col-md-5 offset-7">
                          <button
                            type="button"
                            className="btn-theme text-up w-full"
                            onClick={() => {
                              this.setState({ selectedTacheS: item });
                              this.openLivrableSyllabus("md");
                            }}
                          >
                            Envoyer mon travail
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                // ))
              }
            </InfiniteScroll>

            {/* Modal d'envoie de livrable */}

            <Modal
              className="modal-responsive-md"
              size={this.state.sizelivrableSyllabus}
              show={this.state.showlivrableSyllabus}
              onHide={this.closeLivrableSyllabus}
            >
              <h5
                className="modal-bakeliste-title m-b-50"
                id="modalSendLivrableTitle"
              >
                Envoyer mon travail
              </h5>
              <button
                className="close-modal-bakeliste-title"
                onClick={this.closeLivrableSyllabus}
              >
                <FeatherIcon icon="x" aria-hidden="true" />
              </button>

              <form
                encType="multipart/form-data"
                id="livrable_form"
                onSubmit={(e) => this.SendLivrables(e)}
              >
                <div className="form-group">
                  <label
                    id="details_syllabus_id"
                    name="detail_syllabus_id"
                    className="form-control select-modal-content-bakeliste"
                    value={
                      (this.state.fieldss["details_syllabus_id"] =
                        this.state.selectedTacheS.id)
                    }
                    onChange={(e) => this.handleChangeSyllabus(e)}
                  >
                    {this.state.selectedTacheS.syllabus_task}
                  </label>
                </div>
                <div>
                  <textarea
                    className="form-control input-modal-content-bakeliste p-t-15"
                    rows="9"
                    placeholder="Ajouter un commentaire"
                    name="comment"
                    value={this.state.fieldss["comment"]}
                    onChange={(e) => this.handleChangeS(e)}
                  ></textarea>
                </div>
                <Dropzone
                  required
                  getUploadParams={this.getUploadParams}
                  onChangeStatus={this.handleChangeStatusS}
                  //onSubmit={this.handleSubmit}
                  onChange={(e) => this.handlefileChangeS(e)}
                  //onDrop={this.onDropS.bind(this)}
                  accept="image/*,audio/*,video/*"
                  inputContent={"Cliquer pour ajouter des images"}
                  inputWithFilesContent={"Ajouter plus..."}
                />
                {this.state.isSendingInProgress ? (
                  <button
                    type="submit"
                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                    disabled
                  >
                    Envoie en cours &nbsp;
                    <FeatherIcon icon="loader" aria-hidden="true" />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                  >
                    ENVOYER
                  </button>
                )}
              </form>
            </Modal>
          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <CoachItemDashboardBakeliste />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <BakelisteGroupItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <ListCoachAssistantRefact />
              </div>
              {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                <NextReunionItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <SavedReunionListItem />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ tacheBakeliste }) => ({ ...tacheBakeliste }),
//     dispatch => bindActionCreators({ ...tacheBakelisteActions }, dispatch)
//   )( tacheBakeliste );
