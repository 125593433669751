import React, { Component } from "react";
import "./tableMyBakelistes.css";
import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from "react-bootstrap-table";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import Select from "react-select";
import $ from "jquery";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "react-loader-spinner";
import API from "../../variablesGlobales";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
class PresenceCheck extends React.Component {
  render() {
    return (
      <div style={{ color: "#22C580", textAlign: "center" }}>
        <FeatherIcon icon="check" size={16} />
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return <PresenceCheck active={cell} />;
}

export default class tableMyBakelistes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Bakeliste: [],
      api: API.API,
      coach_id: window.sessionStorage.getItem("user_id"),
      index: 0,
      datas: [],
      data: [],
      groupes: [],
      bakeliste: {},
      selectOptions: [],
      selected: [],
      sous_groupes: [],
      ajoutEnCours: false,
      ajoutSuccessAlert: false,
      isAjoutError: false,
      fields: {},
      //showAlertSuccess: false,
      showAlertError: false,
      bakId: "",
      loading: true,
      sous_groupe: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.getBakelisteByCoach();
    this.__getSousGroupes();
  }

  handleChange(e) {
    e.preventDefault();
    var fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }
  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };
  nameFormatter = (column, row) => {
    return (
      <strong>
        {row.first_name} {row.last_name}
      </strong>
    );
  };
  DureeFormatter = (column, row) => {
    return (
      <div className="">
        {row.type_formation === "courte" ? (
          <span className="formation-courte">Courte</span>
        ) : (
          <span className="formation-longue">Longue</span>
        )}
      </div>
    );
  };
  getBakelisteByCoach() {
    const _this = this;

    axios
      .get(this.state.api + "bakelistes-by-coach/" + this.state.coach_id)
      .then((response) => {
        if (response.data.data !== undefined) {
          this.setState({
            Bakeliste: response.data.data,
          });
          //console.log(this.state.Bakeliste)
          this.state.Bakeliste.forEach((item) => {
            // let infos = {
            //   groupe: item.sous_groupes,
            // };
            this.state.datas.push(item.sous_groupes);
          });
        } else {
          this.setState({
            Bakeliste: 0,
          });
        }
        // _this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        // _this.hideLoader();
      });
  }

  __getSousGroupes = () => {
    const _this = this;
    this.showLoader();
    axios
      .get(this.state.api + "sous__groupes/coach/" + this.state.coach_id)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            sous_groupes: res.data.data.reverse(),
          });
        }
        // this.state.sous_groupes.forEach((item) => {
        //   let infos = {
        //     label: item.name,
        //     value: item.id,
        //   };
        //   this.state.selectOptions.push(infos);
        // });
        _this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        _this.hideLoader();
      });
  };

  actionsFormatterSousGroup(cell, row) {
    return (
      <span>
        <FeatherIcon
          icon="plus-circle"
          onClick={() => {
            this.onGetBakelisteInfos(row);
          }}
          data-toggle="modal"
          data-target="#ajouterBakelisteModale"
          className="ajouter"
          cursor="pointer"
        />
      </span>
    );
  }

  onGetBakelisteInfos(bakeliste) {
    this.setState({
      bakelisteInfos: bakeliste,
      bakId: bakeliste.id,
    });
  }

  handleMultiChange = (selectedOption) => {
    let idinc = [];
    selectedOption.map((inc, id) => {
      idinc.push(inc.value);
    });
    this.setState({ selected: idinc });
  };

  ajoutBakeliste(id) {
    this.setState({
      ajoutEnCours: true,
    });
    let data = this.state.fields;
    // console.log(this.state.selected);
    this.state.selected.forEach((item) => {
      data["sous_groupe_id"] = item;
      data["is_only"] = 0;
      data["is_groupy"] = 1;
      data["bakeliste_ids"] = [id];
      axios
        .post(this.state.api + "sous_groupes/add-bakeliste", data)
        .then((res) => {
          if (res.data.success) {
            this.setState({
              ajoutEnCours: false,
              //showAlertSuccess: true,
              selected: [],
            });
            this.getBakelisteByCoach();
          } else {
            this.setState({
              showAlertError: true,
            });
          }
          $(".close-modal-bakeliste-title").click();
          $("#ajoutgroupform").reset();
          this.getBakelisteByCoach();
          this.__getSousGroupes();
        })
        .catch((error) => {
          // console.log(error.message);
          this.setState({
            ajoutEnCours: false,
            isAjoutError: true,
          });
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    });
  }

  getUnique = (array, key) => {
    if (typeof key !== "function") {
      const property = key;
      key = function (item) {
        return item[property];
      };
    }
    return Array.from(
      array
        .reduce(function (map, item) {
          const k = key(item);
          if (!map.has(k)) map.set(k, item);
          return map;
        }, new Map())
        .values()
    );
  };

  ajoutGroupModal = (cell, row) => {
    this.state.sous_groupes.map((item, index) => {
      let infos = {
        label: item.name,
        value: item.id,
        isDisabled: false,
      };
      this.state.selectOptions.push(infos);
      // }
    });

    return (
      <>
        <div
          data-toggle="modal"
          data-target={"#ajouterBakelisteModale" + row.id}
        >
          <label
            data-toggle="tooltip"
            title="Ajouter Bakeliste dans un sous groupe"
          >
            {" "}
            <FeatherIcon
              icon="user-plus"
              className="ajouter"
              cursor="pointer"
            />{" "}
          </label>
        </div>
        {/* Alert syllabus assigné avec succes */}
        {/* <SweetAlert
          show={this.state.showAlertSuccess}
          onConfirm={() => this.setState({ showAlertSuccess: false })}
          success
          title="Bakeliste ajouté avec succes!!!"
          style={{
            color: "#069A77",
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            paddingTop: 60,
            paddingBottom: 40,
          }}
          confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
        /> */}
        {/* ENd ALert Succes */}

        {/* Alert erreur sur assignation */}
        <SweetAlert
          show={this.state.showAlertError}
          onConfirm={() => this.setState({ showAlertError: false })}
          error
          title="Erreur sur l'ajouté du bakeliste, veuillez reessayer!!!"
          style={{
            color: "#d9534f",
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            paddingTop: 60,
            paddingBottom: 40,
          }}
          confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
        />
        <div
          className="modal fade"
          id={"ajouterBakelisteModale" + row.id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addLivraisonBakelisteTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-content-bakeliste">
              <h5
                className="modal-bakeliste-title m-b-50"
                id="addLivraisonBakelisteTitle"
              >
                Ajouter {row.first_name} {row.last_name} dans un sous-groupe
              </h5>
              <button
                type="button"
                className="close-modal-bakeliste-title"
                data-dismiss="modal"
                aria-label="Close"
              >
                <FeatherIcon icon="x" aria-hidden="true" />
              </button>
              <form encType="multipart/form-data" id="ajoutgroupform">
                <div className="form-group col-md-12">
                  {/* <select
                    className="form-control"
                    id=""
                    name="sous_groupe_id"
                    onChange={(e) => this.handleChange(e)}
                  >
                    <option>--Choisir un groupe--</option>
                    {this.state.sous_groupes.map((x, y) => (
                      <option value={x.id} key={y}>
                        {x.name}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    // className="mt-4 col-md-6 col-offset-4"
                    // components={makeAnimated()}
                    isMulti
                    name="groupe_ids"
                    options={this.state.selectOptions}
                    className="basic-multi-select map-color"
                    onChange={this.handleMultiChange}
                    classNamePrefix="select"
                  />
                </div>

                {this.state.ajoutEnCours ? (
                  <button
                    type="submit"
                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                    disabled
                  >
                    Ajout en cours &nbsp;
                    <FeatherIcon icon="loader" aria-hidden="true" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                    onClick={() => this.ajoutBakeliste(row.id)}
                  >
                    Ajouter
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </>
    );
  };

  listeGroupeModal = (cell, row) => {
    return (
      <>
        <div data-toggle="modal" data-target={"#listeGroupesModale" + row.id}>
          <label
            data-toggle="tooltip"
            title={`Voir la liste des sous-groupes de ${row.first_name}`}
            className="cursor"
          >
            {" "}
            {row.sous_groupes.length}{" "}
          </label>
        </div>

        <div
          className="modal fade"
          id={"listeGroupesModale" + row.id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addLivraisonBakelisteTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-content-bakeliste">
              <h5
                className="modal-bakeliste-title m-b-50"
                id="addLivraisonBakelisteTitle"
              >
                Liste des groupes de {row.first_name} {row.last_name}
              </h5>
              <button
                type="button"
                className="close-modal-bakeliste-title"
                data-dismiss="modal"
                aria-label="Close"
              >
                <FeatherIcon icon="x" aria-hidden="true" />
              </button>
              <div className="modal-body">
                <div className="modal-body row">
                  <ul className="character-bullets-3 md-3 0ffset-4">
                    {row.sous_groupes.map((item, index) => {
                      if (item.sous_groupe != null) {
                        return <li>{item.sous_groupe.name}</li>;
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  removeSyllabus = (id, row) => {
    const fd = new FormData()
    fd.append("syllabus_id", id)
    fd.append("bakeliste_id",row.id )
      axios
        .post(this.state.api + "syllabus/remove-bakeliste", fd)
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            // this.props.getListeAllBakelistes();
            toast.success(
              "Syllabus supprimé avec success",
              { position: toast.POSITION.BOTTOM_LEFT },
              { autoClose: 1000 }
            );
          } else {
            toast.error(
              "Erreur sur  la suppression de ce syllabus!!!",
              { position: toast.POSITION.BOTTOM_LEFT },
              { autoClose: 1000 }
            );
          }
        })
        .catch((error) => {
          // console.log(error.message);
        });
    
  };
  getSyllabusBakeliste = (cell, row) => {
    return (
      <>
        <div data-toggle="modal" data-target={"#syllabusModal" + row.id}>
          <label
            className="cursor"
            data-toggle="tooltip"
            title={`Voir la liste des syllabus de ${row.first_name}`}
          >
            {" "}
            {row.nombre_syllabus}{" "}
          </label>
        </div>
        <div
          className="modal fade"
          id={"syllabusModal" + row.id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="syllabusModalListe"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-content-bakeliste">
              <h5
                className="modal-bakeliste-title m-b-50"
                id="addLivraisonBakelisteTitle"
              >
                Liste des syllabus de {row.user_fullname}
              </h5>
              <button
                type="button"
                className="close-modal-bakeliste-title"
                data-dismiss="modal"
                aria-label="Close"
              >
                <FeatherIcon icon="x" aria-hidden="true" />
              </button>

              <div className="modal-body row">
                <ul className="character-bullets-3 md-3 0ffset-4">
                  {row.syllabus.map((item, index) => {
                    return (
                      <li>
                        <div className="col-md-6">
                        {item.syllabus !== null && item.syllabus.syllabus_name}
                        </div>
                        <div className="col-md-6">
                          <FeatherIcon icon="x" 
                          
                          onClick ={() => this.removeSyllabus(item.id, row) }
                          aria-hidden="true" />
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  handleExportCSVButtonClick = (onClick) => {
    // Custom your onClick event here,
    // it's not necessary to implement this function if you have no any process before onClick
    console.log("This is my custom function for ExportCSVButton click event");
    onClick();
  };
  createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton style={{ background: "#009688" }} btnText="Export CSV" />
    );
  };

  dureeFormatter = (cell, row) => {
    return (
      <span>
        {" "}
        {row.years > 0 && row.years + "ans"}
        {row.month > 0 && row.month + "mois"}{" "}
      </span>
    );
  };

  nameFormatter = (cell, row) => {
    // console.log(row)
    return (
      <span>
        <button>
          <Link
            to={{
              pathname: "/fiche-bakeliste",
              state: { id: row.id, data: row },
            }}
          >
            {row.first_name} {row.last_name}{" "}
          </Link>
        </button>

        {/*        
        <Modal
          className="modal-responsive-sm"
          show={this.state.show}
          onHide={this.close}
        >
          <h5 className="modal-bakeliste-title m-b-50" id="modalAddTacheTitle">
            Informations Bakeliste
          </h5>
          <button
            type="button"
            className="close-modal-bakeliste-title"
            onClick={this.close}
          >
            <FeatherIcon icon="x" aria-hidden="true" />
          </button>

          <div className="row">
            <div className="col-md-6">
              <label className="label-color-green p-3">Nom Complet:</label>
              {row.first_name} {row.last_name}
            </div>
            <div className="col-md-6">
              <img
                src={this.state.base_api_url + row.avatar_file}
                alt=" avatarbakeliste"
              />
            </div>
            <div className="col-md-6">
              <label className="label-color-green p-3">Email:</label>
              {row.email}
            </div>

            <div className="col-md-6">
              <label className="label-color-green p-3">Domaine:</label>
              {row.domaine_name}
            </div>
            <div className="col-md-6">
              <label className="label-color-green p-3">Telephone:</label>
              {row.phone}
            </div>
            <div className="col-md-6">
              <label className="label-color-green p-3">Adresse:</label>
              {row.address}
            </div>
            <div className="col-md-6">
              <label className="label-color-green p-3">Horaire:</label>
              {row.horaire}
            </div>
            <div className="col-md-6">
              <label className="label-color-green p-3">Type :</label>
              {row.type_formation === "courte" ? (
                <span className="formation-courte">Formation Courte</span>
              ) : (
                <span className="formation-longue">Formation Longue</span>
              )}
            </div>
            <div className="col-md-6">
              <label className="label-color-green p-3">Date début formation</label>
             {this.state.formatDate(row.debut_formation)}
            </div>
            <div className="col-md-6">
              <label className="label-color-green p-3">Date de fin</label>
              {this.state.formatDate(row.fin_formation)}
            </div>
            <div className="col-md-12">
              <label className="label-color-green p-3">Objectifs:</label>
              {row.objectifs}
            </div>
           
            <div className="col-md-12">
              <label className="label-color-green p-3">
                Formation Payante:
              </label>
              {row.is_formation_payante === 0 ? (
                <span className="">Oui</span>
              ) : (
                <span className="">Non</span>
              )}
            </div>
            <div className="col-md-12">
              <label className="label-color-green p-3">Bio:</label>
              {row.bio}
            </div>
            <div className="col-md-12">
              <label className="label-color-green p-3">Sous Groupes:</label>
              <ul>
                {row.sous_groupes.map((item, index) =>
                  item.sous_groupe !== null ? (
                    <li>{item.sous_groupe.name}</li>
                  ) : (
                    ""
                  )
                )}
              </ul> 
            </div>
            <div className="col-md-12">
              <label className="label-color-green p-3">Syllabus:</label>
              <ul>
                {row.syllabus.map((item, index) =>
                  item.syllabus !== null ? (
                    <li>{item.syllabus.syllabus_name}</li>
                  ) : (
                    ""
                  )
                )}
              </ul>
            </div>
          </div>
        </Modal>
       */}
      </span>
    );
  };
  render() {
    const options = {
      exportCSVBtn: this.createCustomExportCSVButton,
    };

    return (
      <div className="component-table-my-bakelistes table-responsive-sm table-responsive-xl table-responsive-md">
        <BootstrapTable
          data={this.state.Bakeliste}
          striped={true}
          hover={true}
          options={options}
          search
          searchPlaceholder="Rechercher bakeliste..."
          multiColumnSearch
          exportCSV
          csvFileName="mes-bakelistes.csv"
          multiColumnSort={2}
          pagination
          bordered={true}
        >
          <TableHeaderColumn
            isKey="true"
            dataField="bakelisteFullname"
            // dataFormat={this.nameFormatter}
            dataFormat={this.nameFormatter}
            filter={{ type: "TextFilter", delay: 1000 }}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            width="150"
          >
            Nom Complet
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="domaine_name"
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            filter={{ type: "TextFilter", delay: 1000 }}
            width="100"
          >
            Formation
          </TableHeaderColumn>
          {/* <TableHeaderColumn dataField="" >
            Durée 
          </TableHeaderColumn> */}
          <TableHeaderColumn
            dataField="month"
            dataFormat={this.dureeFormatter}
            //dataFormat={this.DureeFormatter}
            width="80"
            filter={{ type: "TextFilter", delay: 1000 }}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          >
            Durée
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="email"
            dataSort
            filter={{ type: "TextFilter", delay: 1000 }}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            width="170"
          >
            Email
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            dataField="sous_groupes"
            dataFormat={this.listeGroupeModal}
            tdStyle={{ cursor: "pointer" }}
            width="80"
          >
            Groupes
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            dataField="nombre_syllabus"
            dataFormat={this.getSyllabusBakeliste}
            tdStyle={{ cursor: "pointer" }}
            width="80"
          >
            Syllabus
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            // thStyle={{ border: "none", fontWeight: "400", cursor: "pointer" }}
            tdStyle={{ cursor: "pointer" }}
            dataField="id"
            dataFormat={this.ajoutGroupModal}
            dataSort={true}
          ></TableHeaderColumn>
        </BootstrapTable>
     
      </div>
    );
  }
}
// export default connect(
//     ({ tableMyBakelistes }) => ({ ...tableMyBakelistes }),
//     dispatch => bindActionCreators({ ...tableMyBakelistesActions }, dispatch)
//   )( tableMyBakelistes );
