import API from "../../../../variablesGlobales";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const DEMANDES_ABSENCES_BY_BAKELISTE = "DEMANDES_ABSENCES_BY_BAKELISTE";
export const ADD_DEMANDE_ABSENCE ='ADD_DEMANDE_ABSENCE';
export const UPDATED_DEMANDE_ABSENCE = "UPDATED_DEMANDE_ABSENCE"

const userID = window.sessionStorage.getItem("user_id");

export const getDemandeAbsenceBakeliste = (bakelisteId) => {
    return (dispatch) => {
      return axios
        .get(API.API + "demande_absences/bakeliste/" + bakelisteId)
        .then((response) => {
          // console.log('okkkk',response.data.data)
          dispatch({
            type: DEMANDES_ABSENCES_BY_BAKELISTE,
            payload: response.data.data.reverse(),
          });
        })
        .catch((err) => console.log(err));
    };
  };

export function addDemandeAbsence(formdata) {
    return function(dispatch) {
     console.log('form',formdata);
     axios
        .post(API.API + "demande_absences", formdata, {
          Headers: { "Content-Type": "multipart/formdata" },
        })
        .then((response)=>{
          // console.log('success',response.data.success)
              toast('SUCCES!', {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
          })
        console.log('success',userID)

          dispatch(getDemandeAbsenceBakeliste(userID))
        }).catch((error) =>{
            toast.error('Erreur!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              })
            }
          );
    //   })
    // }else{
    //   dispatch(addLivraisonsFailure('Ajouter un fichier'))

    // }

 };
}

export function updatedDemande(id,formaData){
  return function(dispatch){
    axios.put(API.API +"demande_absences/"+id,formaData,{
      Headers: { "Content-Type": "multipart/formdata" },
    }).then((response)=>{
      // console.log('success',response.data.success)
          toast('SUCCES!', {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
      })
      dispatch(getDemandeAbsenceBakeliste(userID))
    }).catch((error) =>{
        toast.error('Erreur!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          })
        }
      );
  }
}