import React, { Component } from "react";
import "./syllabusItem.css";
import ImageSyllabus from "../../img/syllabus.jpg";
import { NavLink, Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

const SyllabusItem = ({ syllabus }) => {
  let id = syllabus.id;

  return (
    <div className="container-syllabus-item">
      <div className="syllabus-item">
        <p
          className={
            "etiquette-syllabus-item " +
            (syllabus.syllabus_level === "débutant"
              ? " bg-debutant"
              : syllabus.syllabus_level === "intermédiaire"
              ? "bg-intermediaire"
              : syllabus.syllabus_level === "avancé"
              ? "bg-avance"
              : "")
          }
        >
          {syllabus.syllabus_level}
        </p>
        <img
          src={ImageSyllabus}
          alt="Avatar"
          className="image-syllabus-item"
          style={{ width: "100%" }}
        />
        <div className="content-syllabus-item">
          <p className="titre-content-syllabus-item">
            {syllabus.syllabus_name}
          </p>
          <div className="row">
            <div className="col-xl-4 col-lg-5 pr-lg-0 col-6 pr-0">
              <p className="info-content-syllabus-item">
                <FeatherIcon
                  className="icon-content-syllabus-item"
                  icon="list"
                />{" "}
                {syllabus.details?.length} tâches
              </p>
            </div>

            <div className="col-xl-4 pl-xl-0 col-6 pr-0">
              <p className="info-content-syllabus-item">
                <FeatherIcon
                  className="icon-content-syllabus-item"
                  icon="user"
                />{" "}
                {syllabus.assignations?.length} bakelistes
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-syllabus-item-back">
        <Link
          className="link-details-container-syllabus-item-back"
          to={{
            pathname: "/syllabus-details-bakeliste",
            state: { id: id },
          }}
        >
          <div className="height-container-syllabus-item-back">
            <p className="titre-container-syllabus-item-back">Description</p>
            <p className="content-container-syllabus-item-back">
              {syllabus.syllabus_description}
            </p>

            <p className="titre-container-syllabus-item-back mt-xl-5 mt-lg-2 mt-md-2 mt-2">
              Outils et Technologies
            </p>
            <p className="content-container-syllabus-item-back">
              {syllabus.tools?.map((item, index) => (
                <span>{item.name}&nbsp;- </span>
              ))}
            </p>
            <p className="content-container-syllabus-item-back">
              {syllabus.technologies?.map((item, index) => (
                <span key={index}>{item.name}&nbsp;</span>
              ))}
            </p>
          </div>
        </Link>

        <div className="">
          <div className="row justify-content-end position-action-container-syllabus-item-back">
            <div className="col-xl-4 col-lg-5 col-md-5 pl-lg-3 pl-md-0 col-sm-6">
              <Link
                className="link-details-container-syllabus-item-back"
                to={{
                  pathname: "/syllabus-details-bakeliste",
                  state: { id: id },
                }}
              >
                <FeatherIcon
                  className="icon-details-container-syllabus-item-back"
                  icon="eye"
                />
                Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyllabusItem;
