import React, { Component } from "react";
import "./sousGroupeCoachAssistant.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { Link } from "react-router-dom";
import $ from "jquery";
import NavVerticalCoachAssistant from "../navVerticalCoachAssistant/index";
import BakelisteGroupeItemForCoachAssistant from "../bakelisteGroupeItemForCoachAssistant/index"
import { NavLink } from "react-router-dom";
import ProgramReunionCoach from "../programReunionCoach/index";
import MultiSelect from "@khanacademy/react-multi-select";
import SavedReunionListItem from "../savedReunionListItem/index";
import { Icon, Modal } from "rsuite";
import Preloader from "../preloader/index";

import SweetAlert from "react-bootstrap-sweetalert";

import HeaderDashboardCoach from "../headerDashboardCoach/index";

import Loader from "react-loader-spinner";

import API from "../../variablesGlobales";
import qs from "qs";
//const Loader = () => <div>Loading...</div>;

export default class sousGroupeCoachAssitant extends Component {
  constructor(props) {
    super(props);
    let groupeId = this.props.location?.state?.id
    if (!groupeId && this.props.location.search) {
      const obj = qs.parse(this.props.location.search.replace('?', ""))
      groupeId = parseInt(obj.id)
    } 
    this.state = {
      api: API.API,
      assistant_domaine_id: window.sessionStorage.getItem("assistant_domaine_id"),
      user_id: window.sessionStorage.getItem("user_id"),
      coach_id:window.sessionStorage.getItem("coach_id"),

      loading: false,
      //syllabus_id:this.props.match.params.id,
      groupes: [],
      //groupeId: this.props.location?.state?.id,
      groupeId: groupeId,
      // Id: this.props.location.state.id,
      datas: [],
      sous_groupes: [],
      showEditModal: false,
      groupeInfos: [],
      sgroupe: {},
      sgroupeInfos: [],
      liste: [],
      noSavedData: [],
      selectOptions: [],
      selected: [],
      bakelistes: [],
      name: "",
      lien_whatsapp: "",
      ajoutEnCours: false,
      ajoutSuccessAlert: false,
      isAjoutError: false,
      fields: {},
      onlySelect: false,
      groupySelect: true,
      showAlertSuccess: false,
      showAlertError: false,
      showAlertDeleteSuccess: false,
      showAlertDeleteError: false,
      editSousGroupeInProgress: false,
      size: "md",

    };
    this.ajoutBakeliste = this.ajoutBakeliste.bind(this);
    this.close = this.close.bind(this);

  }
  componentDidMount() {
    this.getSousGroupes();
    this.getBakelistes();

    this.isLoading = setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  }

  
  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };

  // ajouter un bakeliste dans un sous-groupe
  ajoutBakeliste() {
    this.setState({
      ajoutEnCours: true,
      onlySelect: false,
      groupySelect: true,
    });
    let data = this.state.fields;
    if (this.state.onlySelect) {
      data["sous_groupe_id"] = this.state.sgroupeInfos.id;
      data["is_only"] = 1;
      data["is_groupy"] = 0;
      data["bakeliste_ids"] = this.state.fields.bakeliste_ids;
    } else if (this.state.groupySelect) {
      data["sous_groupe_id"] = this.state.sgroupeInfos.id;
      data["is_groupy"] = 1;
      data["is_only"] = 0;
      data["bakeliste_ids"] = this.state.selected;
    }
    axios
      .post(this.state.api + "sous_groupes/add-bakeliste", data)
      .then((res) => {
        if (res.data.success) {
          $("#date_groupe").val("");
          $(".close").click();

          this.setState({
            ajoutEnCours: false,
            showAlertSuccess: true,
            fields: {},
          });
          this.getSousGroupes();
          this.getBakelistes();

          // window.location = "/sous-groupe/" + this.props.id;
        } else {
          this.setState({
            showAlertError: true,
          });
        }
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          ajoutEnCours: false,
          isAjoutError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }
  getSousGroupes = () => {
    const _this = this;
    this.showLoader();
    axios
      .get(this.state.api + "groupes/" + this.state.groupeId)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.data !== undefined) {
          this.setState({
            groupes: res.data.data,
            sous_groupes: res.data.data.sous_groupes,
          });
          // console.log("nnnnn",this.state.groupes.group_name)
        }
        ///_this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        //_this.hideLoader();
      });
  };

  actionsFormatterSousGroup(cell, row) {
    return (
      <span>
        <FeatherIcon
          icon="archive"
          onClick={() => {
            this.deleteSousGroup(row);
          }}
          id="delete_group"
          style={{
            color: "red",
            height: "21px",
            width: "22px",
            cursor: "pointer",
          }}
        />
        &nbsp;&nbsp;
        <FeatherIcon
          icon="plus-circle"
          onClick={() => {
            this.onGetSousGroupeInfos(row);
          }}
          data-toggle="modal"
          data-target="#ajouterBakelisteModale"
          className="ajouter"
          cursor="pointer"
        />
        &nbsp;&nbsp;
       
      &nbsp;&nbsp;
        <FeatherIcon
          icon="edit"
          onClick={() => {
            this.onGetSousGroupeInfos(row);
            this.editSousGroupeModal();
          }}
          cursor="pointer"
          className="edit"
        />
      </span>
    );
  }
  editSousGroupeModal = (id) => {
    // this.onGetGroupeById(id);
    this.setState({
      showEditModal: true,
    });
  };
  close = () => {
    this.setState({
      showEditModal: false,
    });
  };

  deleteSousGroup = (sgroup) => {
    axios
      .get(this.state.api + "sous__groupe/supprimer/" + sgroup.id)
      .then((res) => {
        if (res.data.success) {
          //  console.log(res.data)
          this.setState({
            showAlertDeleteSuccess: true,
          });
          this.getSousGroupes();
        } else {
          this.setState({
            showAlertDeleteError: true,
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  onGetSousGroupeInfos(sgroup) {
    this.setState({
      sgroupeInfos: sgroup,
      sgId: sgroup.id,
      name: sgroup.name,
      lien_whatsapp: sgroup.lien_whatsapp,
    });

  //  console.log("sgrpou", this.state.name)
  }
  onSubmitEditSousGroupe = (e) => {
    e.preventDefault();
    this.setState({
      editSousGroupeInProgress: true,
    });
    var fd = new FormData();
    fd.append('created_by', this.state.coach_id);
    fd.append('groupe_id', this.state.groupeId);
    fd.append("name", this.state.name);
    fd.append("lien_whatsapp", this.state.lien_whatsapp);
    axios
      .put(this.state.api + "sous__groupes/" + this.state.sgId, fd)
      .then((response) => {
        this.setState({
          editSousGroupeNInProgress: true,
        });
        if (response.data.success) {
          this.setState({
            editSousGroupeInProgress: false,
            showEditModal: false,
          });
          this.close();
          this.getSousGroupes();

        } else {
          this.setState({
            editSousGroupeInProgress: false,
            showEditModal: false,
          });
        }
      })
      .catch((error) => {
        //console.log(error.message);
        this.setState({
          editSousGroupeNInProgress: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getBakelisteBySousGroupes = (id) => {
    const _this = this;
    this.showLoader();
    axios
      .get(this.state.api + "sous__groupes/" + id)
      .then((response) => {
        this.setState({
          loading: false,
        });
        if (response.data.success) {
          this.setState({
            liste: response.data.liste[0].reverse(),
          });
        }
        //_this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        //_this.hideLoader();
      });
  };

  // liste des bakelistes
  getBakelistes() {
    const _this = this;
    this.showLoader();
    axios
      .get(this.state.api + "bakelistes-by-coach-assistant/" + this.state.coach_id + "/" +  this.state.assistant_domaine_id )
      .then((response) => {
        var data = response.data.data;
        if (data !== undefined) {
          this.setState({
            bakelistes: data,
          });
        }
        data.forEach((item) => {
          let infos = {
            label:
              item.first_name +
              " " +
              item.last_name +
              " - " +
              item.domaine_name,
            value: item.id,
          };
          this.state.selectOptions.push(infos);
        });
        //_this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        //_this.hideLoader();
      });
  }


  voirModal = (cell, row) => {
    return (
      <>
       <button 
        >
           <Link 
            to={{
                pathname: "/informations-sous-groupe-coach",
                state: { id: row.id, name: this.state.groupes.group_name},
            }}>
        {row.name}{" "}
        </Link>
        </button>
        
        {/* 
        <div
          onClick={() => {
            this.getBakelisteBySousGroupes(row.id);
            this.onGetSousGroupeInfos(row);
          }}
          data-toggle="modal"
          data-target={"#voirBakelisteModale"}
        >
          <label style={{ cursor: "pointer" }}> {row.name}</label>
        </div>
        <div
          className="modal fade"
          id="voirBakelisteModale"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addLivraisonBakelisteTitle"
          h
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-content-bakeliste">
              <h5
                className="modal-bakeliste-title m-b-50"
                id="addLivraisonBakelisteTitle"
              >
                Liste des bakelistes dans {this.state.sgroupeInfos.name}
              </h5>
              <button
                type="button"
                className="close-modal-bakeliste-title"
                data-dismiss="modal"
                aria-label="Close"
              >
                <FeatherIcon icon="x" aria-hidden="true" />
              </button>

              <div className="modal-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Prenom</th>
                      <th scope="col">Nom</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.liste.map((item, index) => (
                      <tr key={index}>
                        <td>{item.first_name}</td>
                        <td>{item.last_name}</td>
                        <td>{item.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
       */}
      
      </>
    );
  };
  render() {
    //const { loading } = this.state;
    const options = {
      clearSearchBtn: this.createCustomClearButton,
    };
    let id = this.state.groupeId;
    return (

      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoachAssistant />
            </div>
            <div className="col-lg-10 pt-3">
            {this.state.loading ? (
                <div>
                   <Preloader />
              </div>
            )
            :  <>
              <div className="p-b-200">
              <nav aria-label="breadcrumb fixed">
                <ol className="breadcrumb fixed">
                    <li className="breadcrumb-item ">
                      <Link to="/dashboard-coach-assistant">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item " aria-current="page">
                      <Link to="/groupe-coach-assistant">Groupes</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    {this.state.groupes.group_name} 
                    </li>
                  </ol>
                </nav>
                <div className="row m-b-15 mto-3">

                  <div className="col m-b-15">
                    <h7 className="h-theme ff-inter fw-600">
                      Sous-groupes de {this.state.groupes.group_name}{" "}
                    </h7>
                  </div>
                 
                 
                  <button
                    className="btn-add-groupe d-flex flex-row-reverse offset-md-4 mr-5"
                    data-target={"/ajout-groupe"}
                  >
                    <Link
                      className="add-sgroup"
                      to={{
                        pathname: "/ajout-sous-groupe-coach-assistant",
                        state: { id: id },
                      }}
                    >
                      Ajouter un sous-groupe{" "}
                      <Icon
                        className="icon-add-groupe ml-2"
                        icon="plus-circle"
                      />
                    </Link>
                  </button>
                  {/* <button
                    className="btn-archive col-md-2 d-flex flex-row-reverse mr-3"
                    data-target={"/ajout-groupe"}
                  >
                    <NavLink to={"/sous-groupes-archives"} className="add-group">
                      Archives{" "}
                      <Icon className="icon-add-groupe ml-2" icon="archive" />
                    </NavLink>
                  </button> */}
                  <div className="col dis-flex m-b-15"></div>
                </div>
                <div className="row">
                  <div className="col-lg-9">

                    <SweetAlert
                      show={this.state.showAlertDeleteSuccess}
                      onConfirm={() =>
                        this.setState({ showAlertDeleteSuccess: false })
                      }
                      success
                      title="Sous-groupe archivé avec succes!!!"
                      style={{
                        color: "#069A77",
                        fontSize: "12px",
                        fontFamily: "Work Sans, sans-serif",
                        paddingTop: 60,
                        paddingBottom: 40,
                      }}
                      confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                    />
                    {/* ENd ALert Succes */}

                    {/* Alert Livrable non envoye */}
                    <SweetAlert
                      show={this.state.showAlertDeleteError}
                      onConfirm={() =>
                        this.setState({ showAlertDeleteError: false })
                      }
                      error
                      title="Erreur sur la suppression, veuillez reessayer!!!"
                      style={{
                        color: "#d9534f",
                        fontSize: "12px",
                        fontFamily: "Work Sans, sans-serif",
                        paddingTop: 60,
                        paddingBottom: 40,
                      }}
                      confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                    />

                    <div className="card-dashboard p-md-5 p-sm-5 m-b-20">
                      <BootstrapTable
                        data={this.state.sous_groupes}
                        striped={true}
                        hover={true}
                        condensed={true}
                        multiColumnSort={2}
                        options={options}
                        pagination
                        search={true}
                      >
                        <TableHeaderColumn
                          dataField="id"
                          isKey={true}
                          hidden={true}
                        >
                          {" "}
                          ID
                        </TableHeaderColumn>
                        <TableHeaderColumn 
                          // dataField="name"
                          dataFormat={this.voirModal}
                        >
                          Sous-Groupes
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="description">
                          Description
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="lien_whatsapp">
                          Lien whatsapp
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="id"
                          dataFormat={this.actionsFormatterSousGroup.bind(this)}
                          dataSort={true}
                        >
                          Actions
                        </TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  </div>
                  <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <BakelisteGroupeItemForCoachAssistant />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                        <ProgramReunionCoach />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <SavedReunionListItem />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Ajouter bakeliste dans un groupe*/}

                {/* Alert Livrable envoye avec succes */}
                <SweetAlert
                  show={this.state.showAlertSuccess}
                  onConfirm={() => this.setState({ showAlertSuccess: false })}
                  success
                  title="Ajout effectué avec succes!!!"
                  style={{
                    color: "#069A77",
                    fontSize: "12px",
                    fontFamily: "Work Sans, sans-serif",
                    paddingTop: 60,
                    paddingBottom: 40,
                  }}
                  confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                />
                {/* ENd ALert Succes */}

                {/* Alert Livrable non envoye */}
                <SweetAlert
                  show={this.state.showAlertError}
                  onConfirm={() => this.setState({ showAlertError: false })}
                  error
                  title="Erreur sur l'ajout, veuillez reessayer!!!"
                  style={{
                    color: "#d9534f",
                    fontSize: "12px",
                    fontFamily: "Work Sans, sans-serif",
                    paddingTop: 60,
                    paddingBottom: 40,
                  }}
                  confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                />
                <div
                  className="modal fade"
                  id="ajouterBakelisteModale"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="addLivraisonBakelisteTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content modal-content-bakeliste">
                      <h5
                        className="modal-bakeliste-title m-b-50"
                        id="addLivraisonBakelisteTitle"
                      >
                        Ajouter des bakelistes dans{" "}
                        {this.state.sgroupeInfos.name}
                      </h5>
                      <button
                        type="button"
                        className="close-modal-bakeliste-title"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <FeatherIcon icon="x" aria-hidden="true" />
                      </button>
                      <form encType="multipart/form-data" id="livrable_form">
                        <div className="form-group col-md-12">
                          <label>Choisissez un ou des Bakelistes</label>
                          <MultiSelect
                            overrideStrings={{
                              selectSomeItems: "Choisissez les bakelistes",
                              allItemsAreSelected:
                                "Tous vos bakelistes on été choisis",
                              selectAll: "Sélectionnez tout",
                              search: "Rechercher",
                            }}
                            options={this.state.selectOptions}
                            selected={this.state.selected}
                            onSelectedChanged={(selected) =>
                              this.setState({ selected })
                            }
                          />
                        </div>

                        {this.state.ajoutEnCours ? (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                            disabled
                          >
                            Ajout en cours &nbsp;
                            <FeatherIcon icon="loader" aria-hidden="true" />
                          </button>
                        ) : (
                            <button
                              type="button"
                              className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                              onClick={() => this.ajoutBakeliste()}
                            >
                              Ajouter
                            </button>
                          )}
                      </form>
                    </div>
                  </div>
                </div>

                {/* voire les bakeliste dans un groupe*/}

                <div
                  className="modal fade"
                  id="voirListeModale"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="addLivraisonBakelisteTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content modal-content-bakeliste">
                      <h5
                        className="modal-bakeliste-title m-b-50"
                        id="addLivraisonBakelisteTitle"
                      >
                        Liste des bakelistes dans {this.state.sgroupeInfos.name}
                      </h5>
                      <button
                        type="button"
                        className="close-modal-bakeliste-title"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <FeatherIcon icon="x" aria-hidden="true" />
                      </button>

                      <div className="modal-body">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Prenom</th>
                              <th scope="col">Nom</th>
                              <th scope="col">Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.liste.map((item, index) => (
                              <tr key={index}>
                                <td>{item.first_name}</td>
                                <td>{item.last_name}</td>
                                <td>{item.email}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>



                <Modal
                  className="modal-responsive-md"
                  size={this.state.size}
                  show={this.state.showEditModal}
                  onHide={this.close}
                >
                  <form
                    encType="multipart/form-data"
                    onSubmit={(e) =>
                      this.onSubmitEditSousGroupe(e)
                    }
                  >
                    <Modal.Header>
                      <Modal.Title className="modal-contact-us">

                        Modifier le sous-groupe {this.state.sgroupeInfos.name}
                      </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="max-height-modal">
                      <div className="form-group as_input_container">
                        <input
                          value={this.state.name}
                          onChange={(e) =>
                            this.setState({
                              name: e.target.value,
                            })
                          }
                          name="name"
                          className="form-control input_item"
                          id="name"
                        />
                        <br />
                        <input
                          value={this.state.lien_whatsapp}
                          onChange={(e) =>
                            this.setState({
                              lien_whatsapp: e.target.value,
                            })
                          }
                          name="lien_whatsapp"
                          placeholder="Lien groupe whatsapp"
                          className="form-control input_item"
                          id="lien_whatsapp"
                        />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="row dis-flex justify-content-end">
                        <div className="col-md-5 col-sm-6">

                          {this.state.editSousGroupeInProgress ? (
                            <button
                              type="submit"
                              className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                              disabled
                            >
                              Modification en cours &nbsp;
                              <FeatherIcon
                                icon="loader"
                                aria-hidden="true"
                              />
                            </button>
                          ) : (
                              <button
                                type="submit"
                                className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                              >
                                MODIFIER
                              </button>
                            )}

                        </div>
                      </div>
                    </Modal.Footer>
                  </form>


                </Modal>

              </div>
                </>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ tableMyBakelistes }) => ({ ...tableMyBakelistes }),
//     dispatch => bindActionCreators({ ...tableMyBakelistesActions }, dispatch)
//   )( tableMyBakelistes );
