import React, { Component } from "react";
import "./notFound.css";
import { Link } from "react-router-dom";
// import Nfi from "../../img/404.jpg";
import NotFound from "../../img/image png.png";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItemRefact from "../bakelisteGroupItem/BakelisteGroupItemRefact";
import StatisticsBakeliste from "../statisticsBakeliste/index";
import ModalContactUs from "../modalContactUs/index";
import FeatherIcon from "feather-icons-react";
import LivraisonsBakelistesView from "../livraisonsBakelistesView/index";
import EnvoyerMonTravail from "../envoyerMonTravail/index";
import ListCoachAssistantRefact from "../listCoachAssistant/ListCoachAssistantRefact";
import { Alert, Button } from "react-bootstrap";
import { Modal } from "rsuite";
export default class notFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: true,
      show: true,
      showModal: false,
    };
  }

  render() {
    return (
      <div className="p-b-200 ">
        <div className="row">
          <div className="col-lg-9">
            <div>
              <img src={NotFound} alt="" />
            </div>
            <p style={{ textAlign: "center" }}>
              <Link to="/">Retourner à l'acueil </Link>
            </p>
          </div>

          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <CoachItemDashboardBakeliste />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <BakelisteGroupItemRefact />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <ListCoachAssistantRefact />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
