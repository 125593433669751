import {
  FETCH_TACHES_BEGIN,
  FETCH_TACHES_SUCCESS,
  FETCH_TACHES_FAILURE,
  FETCH_TACHES_BAKELISTE,
  FETCH_MORE_TACHES_BAKELISTE,
} from "../actions/tacheBakelistesActions";

const initialState = {
  items: [],
  loading: false,
  error: null,
};

export default function TachesBakelisteReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TACHES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_TACHES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.taches,
      };
    case FETCH_TACHES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };
    case FETCH_TACHES_BAKELISTE:
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload,
      };

    case FETCH_MORE_TACHES_BAKELISTE:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };
    default:
      return state;
  }
}
