import React, {Component} from 'react';
import './detailsExamenCoachAssistant.css';
import DetailsCoachAssistantExamen from '../detailsCoachAssistantExamen/index';
import ExamenItemQuestion from '../examenItemQuestion/index';

import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import API from '../../variablesGlobales';

import axios from "axios";
import { Link } from 'react-router-dom';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as detailsExamenCoachActions from "../../store/detailsExamenCoach/actions";
export default class detailsExamenCoachAssistant extends Component {
    constructor(props) {
        super(props);
        this.state = {
          id: this.props.id_examen,
          examen:[],
          listeQuestions:[],
          Quest:[],
          liste: [],
          listeQ: [],
          test:[],
          api: API.API 

        };
    }

    componentDidMount = () => {
      this.getExamensById();
  };



async getExamensById() {
  axios
    .get(this.state.api + "examen_theoriques/" + this.state.id)
    .then((response) => {
  var data = response.data.questions

      this.setState({
        examen:response.data.exam,
        listeQuestions: response.data.questions,
        }) ;
        // console.log("all questions",response.data.questions);
        // console.log(this.state.listeQuestions);
        // console.log(this.state.listeQuestions[0]);
      this.state.listeQuestions.map((item, index) => (
        
        // console.log(item[0]),
        this.setState({
         Quest: item[0],
          })
        //  console.log("quest", this.state.Quest)
      ))
     // console.log(data)
      data.forEach((item) => {
        let infos = {
          question: item[0],
        };
        this.state.liste.push(infos);
      });
     // console.log("infos",this.state.liste)
      this.state.liste.forEach((item) => {
        let infos = {
          helo: item.question,
        };
        this.state.listeQ.push(infos);
      }); 
     // console.log("loerttttt",this.state.listeQ)
    })
    .catch((error) => {
      // console.log(error.message);
      if (process.env.NODE_ENV !== "production") {
        return;
      }
     // Sentry.captureException(error);
    });
};


    render() {
      return  (<div className="p-b-200">
                <div className="row">
                  <div className="col-lg-9 mb-md-0 mb-5">

              <nav aria-label="breadcrumb fixed">
                <ol className="breadcrumb fixed">
              <li className="breadcrumb-item ">
                <Link to="/dashboard-coach-assistant">Dashboard</Link>
              </li>
              <li className="breadcrumb-item " aria-current="page">
                <Link to="/examens-coach-assistant">Examens</Link>
              </li>
              
              <li className="breadcrumb-item active" aria-current="page"> 
              Examens Theoriques -- Détails &nbsp; {this.state.examen.libelle}
              </li>
            </ol>
          </nav>  
                       <DetailsCoachAssistantExamen
                    id={this.state.examen.id}
                    nom={this.state.examen.libelle}
                    level={this.state.examen.niveau}
                    questions={this.state.listeQuestions.length}
                    session={this.state.examen.session}
                    type={this.state.examen.type}

                    />
                     <p className="fw-700 ff-inter fs-14 text-dark mb-3">Liste des Questions</p>

                   {this.state.listeQuestions.map((item, index) => 
                    <ExamenItemQuestion
                    getExamensById={this.getExamensById}
                    eid={this.state.examen.id} 
                      id={item[0].id}
                      option1={item[0].opt1}
                      option2={item[0].opt2} 
                      option3={item[0].opt3}
                      option4={item[0].opt4}
                      correct_answer={item[0].bonne_reponse}
                      libelle={item[0].libelle}
                      index={index+1}

                      />
                   )}

                  </div>
                          
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteGroupItemForCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div>
      
                </div>
              </div>)
    }
  }
  
// export default connect(
//     ({ detailsExamenCoach }) => ({ ...detailsExamenCoach }),
//     dispatch => bindActionCreators({ ...detailsExamenCoachActions }, dispatch)
//   )( detailsExamenCoach );