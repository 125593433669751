import React, { Component } from "react";
import "./tacheItemSyllabus.css";
import API from "../../variablesGlobales";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn:
    "https://cfd5fe9f51b049ad94ea20a6bd7ab883@o253692.ingest.sentry.io/1442714",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
export default class tacheItemSyllabus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      fields: {},
      bakeliste_id: sessionStorage.getItem("user_id"),
      coach_id: sessionStorage.getItem("coach_id"),
      statusUpdated: false,
      statusnotUpdated: false,
    };
    //console.log("state", this.props.state);
  }
  onStatusChanged = (id) => (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["bakeliste_id"] = this.state.bakeliste_id;
    fields["syllabus_id"] = this.props.syllabus_id;
    fields["detail_syllabus_id"] = id;
    fields["coach_id"] = this.state.coach_id;

    //console.log(fields);
    this.setState({
      fields: fields,
    });
    axios
      .post(this.state.api + "task_excutions/", this.state.fields)
      .then((response) => {
        if (response.data.success === true) {
          // this.setState({
          //   statusUpdated: true,
          //   statusnotUpdated: false,
          // });
          //  console.log("statut", response);
          // window.location.reload();
          this.props.reloadTask();
          // this.getTachesByStatus();
          // this.getDetailsLastSyllabus();
        } else {
          this.setState({
            statusUpdated: false,
            statusnotUpdated: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isUpdatedError: true,
          tacheUpdatedInProgress: false,
          updateTacheStatusError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  render() {
    return (
      <div className="row">
        <SweetAlert
          show={this.state.statusnotUpdated}
          onConfirm={() => this.setState({ statusnotUpdated: true })}
          error
          title="Erreur modification Statut!!!"
          style={{
            color: "#069A77",
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            paddingTop: 60,
            paddingBottom: 40,
          }}
          confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
        />
        <div className="component-tache-item col-xl-9 col-lg-12 col-md-9">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-2 mb-sm-0 mb-3 px-sm-3 px-2 dis-flex itm-center">
              <p className="h-theme ff-work fs-12 fw-500">
                Tâches N°{this.props.index}
              </p>
            </div>

            <div className="col-lg-10 col-md-10 col-sm-10 px-sm-3 px-2 separateur-livrable-bakeliste-item">
              <p className="titre-component-tache-item">{this.props.content}</p>
              <p className="content-component-tache-item"></p>
            </div>
          </div>
          <p className="fs-12 m-t-30">
            <span className="flag">{this.props.syllabus_name}</span>
          </p>
        </div>
        <div className=" component-tache-item col-xl-3 p-xl-0 pl-lg-3 pl-md-0 col-lg-12 col-md-3 m-b-15">
          <div className="card-status-info m-b-15">
            <p className="fw-500 fs-12 text-black ff-work mb-3">Statut</p>
            <div>
              <ul
                className="p-0 list-none m-b-0 dis-flex"
                onChange={this.onStatusChanged(this.props.id)}
              >
                <li className="text-status-indicator">
                  {/* <span className="status-indicator a-faire"></span> */}
                  <input
                    type="radio"
                    name="task_status"
                    value="todo"
                    defaultChecked={this.props.state === "todo"}
                    className="status-indicator a-faire"
                  ></input>
                  <p className="text-black">À faire</p>
                </li>
                <li className="text-status-indicator">
                  {/* <span className="status-indicator en-cours"></span> */}
                  <input
                    type="radio"
                    name="task_status"
                    value="doing"
                    defaultChecked={this.props.state === "doing"}
                    className="status-indicator en-cours"
                  ></input>
                  <p className="text-black">En cours</p>
                </li>
                <li className="text-status-indicator">
                  <input
                    type="radio"
                    name="task_status"
                    value="done"
                    defaultChecked={this.props.state === "done"}
                    className="status-indicator termine"
                  ></input>
                  <p className="text-black">Terminé</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
