import React, { Component } from "react";
import "./syllabusCoachInnerDetails.css";
import { Icon } from "rsuite";
import FeatherIcon from "feather-icons-react";
import SyllabusCoachItemTache from "../syllabusCoachItemTache/index";
import axios from "axios";
import { NavLink, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import API from "../../variablesGlobales";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// fake data generator
const getItems = (data) =>
  Array.from(data).map((v) => ({
    id: `${v.id}`,
    syllabus_task: `${v.syllabus_task}`,
    syllabus_skills: `${v.syllabus_skills}`,
    description: `${v.description}`,
    syllabus_week_number: `${v.syllabus_week_number}`,
    tache_url: `${v.tache_url}`,
    syllabus_completion_time: `${v.syllabus_completion_time}`,
    syllabus_file_jointe: `${v.syllabus_file_jointe}`,
    created_by: `${v.created_by}`,
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "#00968899" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#00968899" : "none",
  padding: grid,
});

export default class syllabusCoachInnerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      syllabus_id: this.props.id,
      api: API.API,
      syllabusDatas: [],
      selectOptions1: [],
      
      items: [],
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }
  componentDidMount = () => {
    this.onGetSyllabusDetails();
   
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
  }

  onGetSyllabusDetails = () => {
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;
    axios
      .get(baseApiUrl + "all-details-syllabus/" + this.state.syllabus_id)
      .then((response) => {
        var data = response.data.data.details;
        // console.log("test", response.data.data.details);

        this.setState({
          isPageLoading: false,
          items: getItems(response.data.data.details),
          syllabusDatas: response.data.data,
        });
        // if (response.data.success) {
        //   this.setState({
        //     isPageLoading: false,
        //     syllabusDatas: response.data.data,
        //   });
        // }
        data.forEach((item) => {
          let infos = {
            sy: item.syllabus_task,
            value: item.id,
          };
          this.state.selectOptions1.push(infos);
        });
      })
      .catch((error) => {
       // console.log(error.message);
        this.setState({
          isPageLoading: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };
  onChange = (doc) => {
    this.setState({
      selectOptions1: doc,
    });
  };
  render() {
    let id = this.props.id;
    return (
      <div>
        <div className="card-dashboard mb-4 mto-3">
          <div className="row m-b-30">
            <div className="col-md-12">
              <div className="row">
                <p className="titre-syllabus-details-card mb-3 col-md-6">
                  {" "}
                  {this.props.name}
                </p>
                <Link
                    className="btn-theme col-md-3 offset-md-3 d-flex flex-row-reverse fts"
                    to={{
                      pathname: "/ajout-tache-syllabus",
                      state: { id: id },
                    }}
                  >
                    Ajouter tâche{" "}
                    <Icon className="icon-pointage" icon="plus-circle" />
                  </Link>
              </div>
              <span
                className={
                  "eetiquette-syllabus-details " +
                  (this.props.niveau === "débutant"
                    ? " etiquette-syllabus-details debutant mb-2"
                    : this.props.niveau === "intermédiaire"
                    ? "etiquette-syllabus-details intermediaire mb-2"
                    : this.props.niveau === "avancé"
                    ? "etiquette-syllabus-details avance mb-2"
                    : "")
                }
              >
                {this.props.niveau}
              </span>
              <div className="row mb-4">
                <div className="col-xl-3 col-lg-4 pr-lg-0 col-md-3 pr-md-0 col-sm-3 col-6">
                  <p className="info-content-syllabus-item">
                    <FeatherIcon
                      className="icon-content-syllabus-item"
                      icon="list"
                    />
                    {this.props.nbreTaches} tâches
                  </p>
                </div>
                {/*  <div className="col-xl-4 col-lg-4 pl-xl-0 px-lg-0 col-md-3 col-6 px-0">
                          <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="clock"/> 2 semaines</p>
                        </div> */}
                <div className="col-xl-4 pl-xl-0 col-6 pr-0">
                  <p className="info-content-syllabus-item">
                    <Link
                      to={{
                        pathname: "/bakelistes-par-syllabus",
                        state: { id: id },
                      }}
                    >
                      <FeatherIcon
                        className="icon-content-syllabus-item"
                        icon="user"
                      />{" "}
                      {this.props.nbreBakeliste} bakelistes{" "}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="content-syllabus-details-card">
            {this.props.description}
          </p>
        </div>
        <p className="fw-700 ff-inter fs-14 text-dark mb-3">Liste des tâches</p>

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {this.state.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div className="mb-5">
                          <SyllabusCoachItemTache
                            id={this.state.syllabus_id}
                            tache_id={item.id}
                            competence={item.syllabus_skills}
                            key={index}
                            id_tache={index + 1}
                            tacheid={item.id}
                            contenu={item.syllabus_task}
                            description={item.description}
                            week={item.syllabus_week_number}
                            lien={item.tache_url}
                            duree={item.syllabus_completion_time}
                            jointe={item.syllabus_file_jointe}
                            onNameChange={this.onChange}
                            created_by={item.created_by}
                            onGetDetails={this.onGetSyllabusDetails}
                          />
                        </div>{" "}
                      </div>
                    )}
                  </Draggable>
                ))}
                {/* {this.state.syllabusDatas.details.map((item, index) => (
                  <div className="mb-5">
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <SyllabusCoachItemTache
                            id={this.state.syllabus_id}
                            tache_id={item.id}
                            competence={item.syllabus_skills}
                            key={index}
                            id_tache={index + 1}
                            tacheid={item.id}
                            contenu={item.syllabus_task}
                            description={item.description}
                            week={item.syllabus_week_number}
                            lien={item.tache_url}
                            duree={item.syllabus_completion_time}
                            jointe={item.syllabus_file_jointe}
                            onNameChange={this.onChange}
                            created_by={item.created_by}
                            onGetDetails={this.onGetSyllabusDetails}
                          />
                        </div>
                      )}
                    </Draggable>

                    {provided.placeholder}
                  </div>
                ))} */}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* {this.state.syllabusDatas.details !== undefined
          ? 
          this.state.syllabusDatas.details.map((item, index) => (
              <div className="mb-5">
                <SyllabusCoachItemTache
                  id={this.state.syllabus_id}
                  tache_id={item.id}
                  competence={item.syllabus_skills}
                  key={index}
                  id_tache={index + 1}
                  tacheid={item.id}
                  contenu={item.syllabus_task}
                  description={item.description}
                  week={item.syllabus_week_number}
                  lien={item.tache_url}
                  duree={item.syllabus_completion_time}
                  jointe={item.syllabus_file_jointe}
                  onNameChange={this.onChange}
                  created_by={item.created_by}
                  onGetDetails={this.onGetSyllabusDetails}
                />
              </div>
            ))
          : ""} */}
      </div>
    );
  }
}
// export default connect(
//     ({ syllabusDeatils }) => ({ ...syllabusDeatils }),
//     dispatch => bindActionCreators({ ...syllabusDeatilsActions }, dispatch)
//   )( syllabusDeatils );
