import React, { useEffect, useState } from "react";
import "./groupeBakelisteView.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import GroupeBakelisteRefact from "../groupeBakeliste/GroupeBakelisteRefact";
import Loader from "react-loader-spinner";
import Preloader from "../preloader/index";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const GroupeBakelisteViewRefact = () => {
  const [load, setLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  });

  return (
    <div className="body-theme">
      <HeaderDashboard />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVertical />
          </div>

          <div className="col-lg-10 pt-3">
            {load ? (
              <div>
                <Preloader />
              </div>
            ) : (
              <>
                <GroupeBakelisteRefact />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GroupeBakelisteViewRefact;
