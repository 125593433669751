import React, { Component } from "react";
import "./livraisonsBakelistes.css";
import ItemFilActualiteBakeliste from "../itemFilActualiteBakeliste/index";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import API from "../../variablesGlobales";
import InfiniteScroll from "react-infinite-scroll-component";
import base_api_url from "../../variablesGlobales";
//import syllabusItemCoach from "../syllabusItemCoach";
import FeatherIcon from "feather-icons-react";
import Dropzone from "react-dropzone-uploader";
import { Modal } from "rsuite";
import Preloader from "../preloader/index";

import amplitude from "amplitude-js";
import $ from "jquery";
import SweetAlert from "react-bootstrap-sweetalert";

export default class livraisonsBakelistes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userFullName: window.sessionStorage.getItem("userFullName"),
      user_id: sessionStorage.getItem("user_id"),
      domaine_id: sessionStorage.getItem("domaine_id"),
      api: API.API,
      email: sessionStorage.getItem("email"),
      base_api_url: base_api_url.base_api_url,
      livraisonsSyllabus: [],
      livraisonsTaches: [],
      isSendingLivrableInProgress: false,
      page: 0,
      images: [],
      files: [],
      limit: 5,
      show: false,
      fields: {},
      taskdone: [],
      tasktodo: [],
      taskdoing: [],
      tasksyllabusterminer: [],
      tasksyllabusafaire: [],
      tasksyllabusencours: [],
      table: [],
      showAlertSuccess: false,
      showAlertError: false,
      recherche1: "",
      rechercher: [],
      currentPage: 1,
      hasMore: true,
      loading: true,
      isLoader: true,
      AlllivraisonsSyllabus: [],
    };
  }

  componentDidMount() {
    this.getLivraisonsByCoach(this.state.page);
    this.getTachesByStatus();
    this.getDetailsLastSyllabus();
    // this.getAllLivraisonsByCoach();
    setTimeout(() => {
      this.setState({
        isLoader: false,
      });
    }, 4000);
  }

  /*   getAllLivraisonsByCoach = (page) => {
    axios
      .get(
        this.state.api +
          "livraison_bakelistes_by_domain/" +
          this.state.domaine_id +
          "/?page=" +
          page
      )
      .then((res) => {
        // console.log(page);
        if (res.data.success === true) {
          // console.log("lenght", res.data.livraisonTaches.data.length);
          this.setState({
            AlllivraisonsSyllabus: [
              ...this.state.AlllivraisonsSyllabus,
              ...res.data.livraisonTaches.data,
            ],
          });
          // console.log(
          //   res.data.livraisonTaches.data,
          //   this.state.AlllivraisonsSyllabus,
          //   "livALL"
          // );
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }; */

  getLivraisonsByCoach = () => {
    // console.log("debut");
    axios
      .get(
        this.state.api +
          "livraison_bakelistes_by_domain/" +
          this.state.domaine_id +
          "/?page=" +
          this.state.currentPage
      )
      .then(async (res) => {
        // console.log(res, "liv");
        if (res.data.success === true) {
          this.setState({
            livraisonsSyllabus: res.data.detailsSyllabus.data.reverse(),
          });
        }
        for (let i = 0; i < res.data.livraisonTaches.total; i++) {
          await this.getAllLivraisonsByCoach(i);
        }
        // console.log(res, "liv");
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getFichiviersLraisons = (data) => {
    const ok = data.map((i, ix) => {
      return i.fichiers;
    });
    return ok;
  };

  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);

    amplitude.getInstance().init(api_key);
  };
  // getLivraisonsByCoach = () => {
  //   axios
  //     .get(
  //       this.state.api +
  //         "livraison_bakelistes_by_domain/" +
  //         this.state.domaine_id +
  //         "/?page=" +
  //         this.state.currentPage
  //     )
  //     .then((res) => {
  //       if (res.data.success === true) {
  //         this.setState({
  //           livraisonsSyllabus: res.data.detailsSyllabus.data.reverse(),
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       if (process.env.NODE_ENV !== "production") {
  //         return;
  //       }
  //       Sentry.captureException(error);
  //     });
  // };

  getFichiviersLraisons = (data) => {
    const ok = data.map((i, ix) => {
      return i.fichiers;
    });
    return ok;
  };

  onValidateLivrablesFormData() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;
    this.setState({
      errors: errors,
    });
    return isValidForm;
  }

  getDetailsLastSyllabus = () => {
    axios
      .get(this.state.api + "bakeliste-details-syllabus/" + this.state.user_id)
      .then((response) => {
        this.setState({
          tasksyllabusterminer: response.data.details_done.reverse(),
          tasksyllabusafaire: response.data.details_todo,
          tasksyllabusencours: response.data.details_doing,
        });

        this.formatData();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getTachesByStatus = () => {
    axios
      .get(
        this.state.api + "get-taches-bakeliste-by-status/" + this.state.user_id
      )
      .then((response) => {
        this.setState({
          taskdone: response.data.taskdone.reverse(),
          tasktodo: response.data.tasktodo,
          count_task_todo: response.data.tasktodo.length,
          total_todo: response.data.tasktodo.length,
          taskdoing: response.data.taskdoing,
        });

        this.formatData();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  onSendLivrables = (e) => {
    e.preventDefault();
    if (this.onValidateLivrablesFormData()) {
      this.setState({
        isSendingLivrableInProgress: true,
      });
      const email = this.state.email;

      var data = this.state.fields;
      var files = this.state.files;
      var eventProperties = {
        user_id: this.state.bakeliste_id,
        email: email,
        details_syllabus_id: data.details_syllabus_id,
      };
      if (navigator.onLine) {
        // console.log("go1");
        files.map((image) => {
          var fd = new FormData();
          fd.append("tache_files", image);
          fd.append("commentaire", data.comment);
          fd.append("bakeliste_user_id", this.state.user_id);
          fd.append("details_syllabus_id", data.details_syllabus_id);

          axios
            .post(this.state.api + "livraisons", fd, {
              Headers: { "Content-Type": "multipart/formdata" },
            })
            .then((response) => {
              this.setState({
                isSendingLivrableInProgress: false,
              });

              if (response.data.success) {
                // $("#tache_file").val("");
                $(".close-modal-bakeliste-title").click();

                amplitude
                  .getInstance()
                  .logEvent(
                    "ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_V2",
                    eventProperties
                  );
              } else {
                this.setState({
                  showAlertError: true,
                });
                setTimeout(() => {
                  this.setState({
                    isErrorSending: false,
                  });
                }, 1000);
                amplitude
                  .getInstance()
                  .logEvent(
                    "ENVOIE_LIVRABLE_BAKELISTE_ERROR_V2",
                    eventProperties
                  );
              }
              $(".close-modal-bakeliste-title").click();
            })
            .catch((error) => {
              if ((error.message = "Request failed with status code 422")) {
              }
              amplitude
                .getInstance()
                .logEvent(
                  "ENVOIE_LIVRABLE_BAKELISTE_ERROR_V2",
                  eventProperties
                );
              this.setState({
                isSendingLivrableInProgress: false,
                showAlertError: true,
              });
              setTimeout(() => {
                this.setState({
                  showAlertError: false,
                });
              }, 1000);
              if (process.env.NODE_ENV !== "production") {
                return;
              }
              Sentry.captureException(error);
            });
        });
        $(".close-modal-bakeliste-title").click();

        setTimeout(() => {
          this.setState({
            showAlertSuccess: true,
            showlivrable: false,
          });
        }, 1000);
      } else {
        this.setState({ userNotConnected: true });
        amplitude
          .getInstance()
          .logEvent("ERROR_CONNEXION_ENVOIE_LIVRABLE_V2", eventProperties);
      }
    }
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  handlefileChange = (e) => {
    let fields = this.state.fields;
    if (e.target.name === "tache_file") {
      if (this.fileValidation("tache_file")) {
        fields["tache_file"] = e.target.files[0];
      } else {
        this.setState({
          isInvalidFileExtension: true,
        });
        setTimeout(() => {
          this.setState({
            isInvalidFileExtension: false,
          });
        }, 6000);
      }
    } else {
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields: fields,
    });
  };

  onDrop = (files) => {
    this.setState({
      files: files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
    const uploaders = files.map((file) => {
      const formData = new FormData();
      formData.append("file", file);

      return axios.post("https://httpbin.org/post", formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" },
      });
    });
  };

  getCommentsLivraisons = (data) => {
    const ok = data.map((i, ix) => {
      return i.comments;
    });
    return ok;
  };

  getIdLivraisons = (data) => {
    const ok = data.map((i, ix) => {
      return i.id;
    });
    return ok;
  };

  fetchMoreData = () => {
    setTimeout(() => {
      this.setState({
        currentPage: this.state.currentPage + 1,
        loading: true,
      });
      axios
        .get(
          this.state.api +
            "livraison_bakelistes_by_domain/" +
            this.state.domaine_id +
            "/?page=" +
            this.state.currentPage
        )
        .then((response) => {
          // console.log("jdcjcndzl", response);

          if (response.data.success === true) {
            Object.values(response.data.detailsSyllabus.data).forEach(
              (element) => {
                this.state.livraisonsSyllabus.push(element);
              }
            );
            //console.log("dzlkd", this.state.livraisonsSyllabus);

            if (
              response.data.detailsSyllabus.current_page ===
              response.data.detailsSyllabus.last_page
            ) {
              this.setState({ hasMore: false, loading: false });
            } else {
              this.setState({ hasMore: true, loading: true });
            }
          }
        });
    }, 500);

    return this.state.livraisonsSyllabus;
  };

  open = (size) => {
    this.setState({
      size,
      show: true,
    });
  };

  close = () => {
    this.setState({
      show: false,
    });
  };

  addReactiononLivrable = (fields, item) => {
    const livraison = item;
    var eventProperties = {
      user_id: this.state.bakeliste_id,
      email: this.state.email,
    };
    axios
      .post(this.state.api + "comment/store", fields)
      .then((response) => {
        amplitude
          .getInstance()
          .logEvent("COMMENTAIRE_LIVRAISON_V2", eventProperties);
        const data = response.data.data;
        data.fullname = this.state.userFullName;

        // const item1 = livraison.find((item) => item.id === data.commentable_id);
        item.comments.push(data);
        const livraisonsSyllabus = [...this.state.livraisonsSyllabus];
        const index = livraisonsSyllabus.findIndex((it) => it.id === item.id);
        livraisonsSyllabus[index].livraisons = livraison;
        this.setState({ livraisonsSyllabus });
        var event = {
          user_id: this.state.bakeliste_id,
          email: this.state.email,
        };
        this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
        amplitude
          .getInstance()
          .logEvent("COMMENTAIRE_LIVRAISON_BAKELISTE_V2", event);
      })

      .catch((error) => {
        // console.log(error);
      });
  };

  handleChangeStatus = ({ meta, file }, status) => {
    const files = [];
    if (status === "done") {
      this.setState({ files: this.state.files.concat(file) });
    }
  };

  handleSubmit = (files, allFiles) => {
    // console.log(files.map((f) => f.meta));
    allFiles.map((file) => {
      const formData = new FormData();
      formData.append("file", file.file);
    });

    // allFiles.forEach((f) => f.remove());
  };

  getBakelisteLivraison() {
    const recherche = this.state.recherche1.trim().toLocaleLowerCase();
    const livraison = this.state.AlllivraisonsSyllabus;
    if (recherche.length < 1) {
      return livraison;
    }
    const result = livraison.filter((s) => {
      const fullname =
        s.user.first_name +
        " " +
        s.user.last_name +
        " " +
        s.user.domaine.name +
        " ";
      return fullname.toLocaleLowerCase().includes(recherche);
    });
    // console.log("recherche",result);

    // this.setState({
    //   rechercher:result,
    // })
    return livraison.filter((s) => {
      // console.log(s.user.domaine.name, "name kknd")
      const fullname =
        s.user.first_name + " " + s.user.last_name + " " + s.user.domaine.name;
      // const domaineName = s.user.domaine.name;
      return fullname.toLocaleLowerCase().includes(recherche);
    });
  }

  render() {
    const { livraisonsTaches, livraisonsSyllabus, rechercher } = this.state;
    const table = [...this.state.taskdoing, ...this.state.tasksyllabusencours];
    // console.log(livraisonsSyllabus)
    return this.state.isLoader ? (
      <div>
        <Preloader />
      </div>
    ) : (
      <div className="p-b-100 mb-5">
        <div className="row">
          <div className="col-md-6">
            <h6 className="h-theme ff-inter fw-600">Livraisons</h6>
          </div>
          <div className="col-md-6">
            <div className="input-group-prepend SearchB offset-md-5">
              <FeatherIcon className="search_icon" icon="search" />
              <input
                value={this.state.recherche1}
                type="search"
                className="form-control form-control-costom"
                placeholder="Faire une recherche"
                aria-label="Search"
                aria-describedby="search-addon"
                icon="Search"
                onChange={(e) =>
                  this.setState({
                    recherche1: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <div className="dis-flex justify-content-center">
              {/* <p className="date-livraison-fil-d-actualite">Lun 20/12/2020 </p> */}
            </div>

            {livraisonsSyllabus.length > 0 &&
              this.getBakelisteLivraison().map((item, index) => (
                <div key={index}>
                  <InfiniteScroll
                    className="hello"
                    dataLength={this.state.livraisonsSyllabus.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                    // loader={
                    //   this.state.loading &&
                    //   "Les donnees sont entrain de se charger...."
                    // }
                    // endMessage={
                    //   <p style={{ textAlign: "center" }}>
                    //     <b>Vous avez tous visualise!!!</b>
                    //   </p>
                    // }
                  >
                    {/* {livraisonsSyllabus.length > 0 &&
                this.getBakelisteLivraison().map((item, index) => ( */}
                    <ItemFilActualiteBakeliste
                      key={item.id}
                      livraisons={item.livraisons}
                      onSubmitComment={this.addReactiononLivrable}
                      fichiers={item.fichiers !== null && item.fichiers}
                      commentaires={item.comments !== null && item.comments}
                      index={index + 1}
                      item={item}
                      bakeliste_id={item.bakeliste_user_id}
                      fullname={
                        item?.user !== null &&
                        item?.user?.first_name + " " + item?.user?.last_name
                      }
                      tache_content={
                        item.details !== null && item.details.syllabus_task
                      }
                      date={item.updated_at}
                      hours="0"
                    />
                  </InfiniteScroll>
                </div>
              ))}
            <p style={{ textAlign: "center" }}>
              <b>Vous avez tous visualise!!!</b>
            </p>
            {/* Alert Livrable envoye avec succes */}
            <SweetAlert
              show={this.state.showAlertSuccess}
              onConfirm={() => this.setState({ showAlertSuccess: false })}
              success
              title="Livrable envoyé avec succes!!!"
              style={{
                color: "#069A77",
                fontSize: "12px",
                fontFamily: "Work Sans, sans-serif",
                paddingTop: 60,
                paddingBottom: 40,
              }}
              confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
            />
            {/* ENd ALert Succes */}

            {/* Alert Livrable non envoye */}
            <SweetAlert
              show={this.state.showAlertError}
              onConfirm={() => this.setState({ showAlertError: false })}
              error
              title="Erreur envoie livrable, veuillez reessayer!!!"
              style={{
                color: "#d9534f",
                fontSize: "12px",
                fontFamily: "Work Sans, sans-serif",
                paddingTop: 60,
                paddingBottom: 40,
              }}
              confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
            />
            <Modal
              className="modal-responsive-md"
              size={this.state.size}
              show={this.state.show}
              onHide={this.close}
            >
              <h5
                className="modal-bakeliste-title m-b-50"
                id="modalSendLivrableTitle"
              >
                Envoyer mon travail
              </h5>
              <button
                className="close-modal-bakeliste-title"
                onClick={this.close}
              >
                <FeatherIcon icon="x" aria-hidden="true" />
              </button>

              <form
                encType="multipart/form-data"
                id="livrable_form"
                onSubmit={(e) => this.onSendLivrables(e)}
              >
                <div className="form-group">
                  <select
                    name="details_syllabus_id"
                    className="form-control select-modal-content-bakeliste"
                    id="addLivrableSelectTache"
                    onChange={(e) => this.handleChange(e)}
                    value={this.state.fields["details_syllabus_id"]}
                  >
                    <option>Choisir une tache</option>
                    {table !== undefined &&
                      table.map((item, index) =>
                        item.content !== undefined ? (
                          <option key={index} value={item.id}>
                            {item.content}
                          </option>
                        ) : (
                          <option key={index} value={item.id}>
                            {item.syllabus_task}
                          </option>
                        )
                      )}
                  </select>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control input-modal-content-bakeliste p-t-15"
                    rows="9"
                    placeholder="Ajouter un commentaire"
                    name="comment"
                    value={this.state.fields["comment"]}
                    onChange={(e) => this.handleChange(e)}
                  ></textarea>
                </div>
                <Dropzone
                  required
                  getUploadParams={this.getUploadParams}
                  onChangeStatus={this.handleChangeStatus}
                  // onSubmit={this.handleSubmit}
                  onChange={(e) => this.handlefileChange(e)}
                  onDrop={this.onDrop.bind(this)}
                  accept="image/*,audio/*,video/*"
                  inputContent={"Cliquer pour ajouter des images"}
                  inputWithFilesContent={"Ajouter plus..."}
                />
                {this.state.isSendingLivrableInProgress ? (
                  <button
                    type="submit"
                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                    disabled
                  >
                    Envoie en cours &nbsp;
                    <FeatherIcon icon="loader" aria-hidden="true" />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                  >
                    ENVOYER
                  </button>
                  //   )}
                  // </button>
                )}
              </form>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ livraisonCoach }) => ({ ...livraisonCoach }),
//     dispatch => bindActionCreators({ ...livraisonCoachActions }, dispatch)
//   )( livraisonCoach );
