import React, { Component } from "react";
import axios from "axios";
import API from "../../variablesGlobales";
import { Link } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import PropTypes from "prop-types";
import Select from "react-select";
import { toast } from "react-toastify";

import BakelisteGroupeItemForCoachAssistant from "../bakelisteGroupeItemForCoachAssistant/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import "./ajoutExamTheoriqueCoachAssistant.css";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoachAssistant from "../navVerticalCoachAssistant/index";
toast.configure();

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentAjoutExamTheoriqueActions from "../../store/contentAjoutExamTheorique/actions";
export default class ajoutExamTheoriqueCoachAssistant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      libelle: "",
      duree: "",
      date: "",
      niveau: "",
      session: "",
      api: API.API,
      module_name: "",
      selectOptions: [],
      domaine_id: 0,
      created_by: window.sessionStorage.getItem("user_id"),

      is_archived: false,
      is_available: false,
    };
    //console.log(window.sessionStorage.getItem("user_id"));
  }
  componentDidMount() {
    this.getOptions();
  }

  async getOptions() {
    const response = await axios.get(this.state.api + "domaines");
    this.setState({ selectOptions: response.data });

    //console.log(response.data.data);
    const options = response.data.data.map((d) => ({
      value: d.id,
      label: d.name,
    }));
    // console.log(options);
    this.setState({ selectOptions: options });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange = (e) => {
    this.setState({ domaine_id: e.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { libelle, duree, date, niveau, session, domaine_id } = this.state;
    const is_archived = 0;
    const is_available = 0;
    const created_by = this.state.created_by;
    const nbre_questions = 20;
    const numero = 1;
    const type = "qcm";

    //console.log(libelle, duree, date, niveau, session, numero, domaine_id);
    axios
      .post(this.state.api + "examen_theoriques", {
        nbre_questions,
        numero,
        type,
        is_archived,
        is_available,
        created_by,
        libelle,
        duree,
        date,
        niveau,
        session,
        domaine_id,
      })
      .then((result) => {
        // console.log(result);
        if (result.data.success) {
          //alert("ok");
          toast.success(
            "ajout efféctué avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 3000 }
          );
          window.location = "/examens-coach-assistant";
        } else {
          toast.error(
            "erreur sur l ajout d examen",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 3000 }
          );
        }
      });
  };
  render() {
    const {
      libelle,
      duree,
      date,
      niveau,
      session,
      numero,
      domaine_id,
    } = this.state;
    // console.log(this.state.selectOptions)
    return (
      <div className="body-theme">
        <NavVerticalCoachAssistant />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <HeaderDashboardCoach />
            </div>

            <div className="col-lg-7 p-0">
              <div className="row">
                <div className="col-lg-9 pt-3">
                  <div className="row">
                    <div className="col-lg-9 pt-3">
                      <nav aria-label="breadcrumb fixed">
                        <ol className="breadcrumb fixed">
                          <li className="breadcrumb-item ">
                            <Link to="/dashboard-coach-assistant">
                              Dashboard
                            </Link>
                          </li>
                          <li className="breadcrumb-item " aria-current="page">
                            <Link to="/examens-coach-assistant">Examens</Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Ajout examen theorique
                          </li>
                        </ol>
                      </nav>

                      <div className="mto-3">
                        <form className="post" onSubmit={this.onSubmit}>
                          <div className="row">
                            <div className="col-sm-12 offset-md-2 pb-3">
                              <input
                                type="text"
                                className="input-add-quiz"
                                placeholder="Nom de l’examen"
                                name="libelle"
                                value={libelle}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 offset-md-2 pb-3">
                              {/* <input
                                type="text"
                                className="input-add-quiz"
                                placeholder="Niveau"
                                name="niveau"
                                value={niveau}
                                onChange={this.onChange}
                              /> */}
                              <select
                                onChange={this.onChange}
                                name="niveau"
                                className="input-add-quiz"
                              >
                                <option> --Choisir un niveau-- </option>
                                <option value="débutant">Débutant</option>
                                <option value="avancé">Avancé</option>
                                <option value="intermédiaire">
                                  Intermédiaire
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 offset-md-2 pb-3">
                              <input
                                type="text"
                                className="input-add-quiz"
                                placeholder="Session"
                                name="session"
                                value={session}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 offset-md-2 pb-3">
                              <input
                                type="date"
                                className="input-add-quiz"
                                placeholder="Date"
                                name="date"
                                value={date}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12 offset-md-2 pb-3">
                              <Select
                                className="input-add-quiz"
                                options={this.state.selectOptions}
                                onChange={(e) => this.handleChange(e)}
                              />
                            </div>
                          </div>
                          <div className="row d-flex justify-content-end pt-4 pb-5 pr-3">
                            <button type="submit" className="btn btn-theme">
                              Creer Examen
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div></div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteGroupeItemForCoachAssistant />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ contentAjoutExamTheorique }) => ({ ...contentAjoutExamTheorique }),
//     dispatch => bindActionCreators({ ...contentAjoutExamTheoriqueActions }, dispatch)
//   )( contentAjoutExamTheorique );
