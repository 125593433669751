import {
    ADD_LIVRAISONS_BEGIN,
    ADD_LIVRAISONS_SUCCESS,
    ADD_LIVRAISONS_FAILURE
} from '../actions/livraisonsActions'

const initialState ={
    items:[],
    loading:false,
    error:null,
    success:false
};


export default function addLivraisonReducer(state=initialState,action){
    switch(action.type){
        case ADD_LIVRAISONS_BEGIN:
            return{
                ...state,
                loading:true,
                error:null,
                items:[],
                success:false,
            };
        case ADD_LIVRAISONS_SUCCESS:
            return{
                ...state,
                loading:false,
                error:null,
                items:action.payload.livraison,
                success:true,
                
            };
             
        case ADD_LIVRAISONS_FAILURE:
            return{
                ...state,
                loading:false,
                error:action.payload.error,
                items:[],
                success:false,

            };
            default:
                return state;
    }
}

