import {FETCH_LISTE_COACH_ASSISTANT} from "../actions/listeCoachAssitantActions";

const initialState = {
   items: [],
   loading: false,
   error: null,
};

export default function listeCoachAssitantReducer(state = initialState, action) {
   switch (action.type) {
     case FETCH_LISTE_COACH_ASSISTANT:
       return {
           ...state,
           loading: false,
           error: action.payload.error,
           items: action.payload,
       };
       default :
       return state
   }

}

