import storage from "redux-persist/lib/storage";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// . import logger from "redux-logger";

import rootReducer from "../rootReducer";
import { persistReducer, persistStore } from "redux-persist";

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const pReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(thunk);

const store = createStore(rootReducer, middleware);
// const persistor = persistStore(store);
export { store };
