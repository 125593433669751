import React, { Component } from "react";
import "./contenusCoachAssistant.css";
import axios from "axios";

import IconComment from "../../img/icons/comment.png";
import IconSendComment from "../../img/icons/send1.png";
import * as Sentry from "@sentry/react";
import API from "../../variablesGlobales";
// import FeatherIcon from "feather-icons-react";
import { InputGroup } from "rsuite";

import frenchShortFormatDate from "../frenchFormatDate";

// import NextReunionItem from "../nextReunionItem/index";
// import SavedReunionListItem from "../savedReunionListItem/index";
import DOMPurify from "dompurify";
// CommonJS
import Preloader from "../preloader/index";
import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";
import qs from "qs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContentComments from "../contentComments/contentComments";
toast.configure();
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as voirMesContenusActions from "../../store/voirMesContenus/actions";
export default class contenusCoachAssistant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contenus: [],
      api: API.API,
      fieldss: {},
      userID: window.sessionStorage.getItem("user_id"),
      userFullName: window.sessionStorage.getItem("userFullName"),
      userEmail: window.sessionStorage.getItem("email"),
      user_type: window.sessionStorage.getItem("user_status"),
      formatDate: frenchShortFormatDate.frenchShortFormatDate,
      isload: true,
      commentInProgress: false,
      id: this.props.id,
      show: false,
      contenu: [],
      // commentaires: this.props.commentaires,
    };
    // console.log("rrrr",this.props.id)
  }
  componentDidMount() {
    this.getResume();
    setTimeout(() => {
      this.setState({
        isload: false,
      });
    }, 5000);
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("envoyerMonTravailComponent");
  }
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };

  getResume = () => {
    // var fd = new FormData();
    // fd.append("id", this.state.id);
    axios
      .get(this.state.api + "textual-contents/" + this.state.id)
      .then((res) => {
        // const contenu = res.data;
        this.setState({
          contenu: res.data.data,
        });
        console.log("ok", this.state.contenu);
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  open = (size) => {
    this.setState({
      size,
      show: true,
    });
  };
  close = () => {
    this.setState({
      show: false,
    });
  };

  commentContenu = (e) => {
    e.preventDefault();
    const eventProperties = {
      user_id: this.state.userID,
      Name: this.state.userFullName,
    };
    this.setState({
      commentInProgress: true,
    });
    var dataaS = this.state.fieldss;
    var fd = new FormData();
    fd.append("user_type", this.state.user_type);
    fd.append("comment_file", "");
    fd.append("comment_text", dataaS.comment_text);
    fd.append("content_id", this.state.id);
    fd.append("content_type", this.props.type);
    fd.append("user_id", this.state.userID);
    fd.append("content_user_id", this.props.bakeliste_id);
    fd.append("content_title", this.props.title);
    axios
      .post(this.state.api + "content_feedbacks", fd)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          this.setState({
            commentInProgress: false,
          });
          console.log(
            "okk",
            response.data.data,
            this.state.contenu.commentaires
          );
          // const data = response.data.data;
          // data.fullname = this.state.userFullName;
          // this.state.contenu.commentaires.push(data);
          //  const contenu = [...this.state.contenu];
          //  const index = contenu.findIndex((it) => it.id === item.id);
          //  contenu[index].comments = livraison;
          // this.setState({ livraisonsSyllabus });
          amplitude
            .getInstance()
            .logEvent(
              "COMMENTAIRE_CONTENU_COACH_ASSISTANT_V2",
              eventProperties
            );
          // toast.success(
          //   "Commentaire ajouté avec success",
          //   { position: toast.POSITION.BOTTOM_LEFT },
          //   { autoClose: 1000 }
          // );
        }
      })
      .catch((error) => {
        // console.log(error.message);
        // this.setState({
        //   isSendingResumenProgress: false,
        // });
        toast.error(
          "Erreur sur l'ajout de feedback",
          { position: toast.POSITION.BOTTOM_LEFT },
          { autoClose: 1000 }
        );
      });
  };

  handleChange = (e) => {
    let fieldss = this.state.fieldss;
    fieldss[e.target.name] = e.target.value;
    this.setState({ fieldss });
    console.log(
      this.state.userID,
      this.props.type,
      this.state.id,
      this.props.id,
      this.props.bakeliste_id
    );
  };
  validerContenu = () => {
    var eventProperties = {
      user_email: this.state.userEmail,
      user_name: this.state.userFullName,
    };
    const fd = new FormData();
    fd.append("validated_by", this.state.userID);
    fd.append("id", this.state.id);
    axios
      .post(this.state.api + "validate-textual-content", fd)
      .then((res) => {
        if (res.data.success) {
          amplitude
            .getInstance()
            .logEvent("VLIDATE_CONTENT_BY_COACH_ASSISTANT", eventProperties);
          toast.success(
            "Contenu validée avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
          // window.location="/contenus-validés"
        }
      })
      .catch((error) => {
        // console.log(error.message);
        // this.setState({
        //   isSendingResumenProgress: false,
        // });
        toast.error(
          "Erreur sur la validation du contenue",
          { position: toast.POSITION.BOTTOM_LEFT },
          { autoClose: 1000 }
        );
      });
  };
  render() {
    // const parse = require('html-react-parser');
    const contenu = this.props.contenu_text;
    return (
      <Amplitude>
        {this.state.isload ? (
          <div>
            <Preloader />
          </div>
        ) : (
          <div className="component-details-contenu">
            <article>
              <div class="article-header pr-5">
                <div class="headline">
                  {/* <p>The Sights & Sounds of</p> */}
                  <h1>{this.props.title}</h1>
                  <div class="byline">par {this.props.auteur}</div>
                </div>
                <div class="image"></div>
              </div>
              <div class="article-text">
                <p class="first">
                  <div
                    className="bot"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(contenu),
                    }}
                  ></div>
                </p>
              </div>
            </article>
            <div className="row bot">
              <div className="col-md-6">
                <button
                  className="btn-theme"
                  onClick={() => this.validerContenu()}
                >
                  Valider ce contenu
                </button>
              </div>

              <div class="col-md-12 m-t-25 m-b-5">
                <ContentComments comments={this.state.contenu.commentaires} />

                {/* <h5>Commentaires</h5> */}
                <form
                  encType="multipart/form-data"
                  id="contenu_livraison"
                  onSubmit={(e) => this.commentContenu(e)}
                >
                  {" "}
                  {/* <button className="btn-like-fil-actualite-card mr-xl-3 mr-lg-2 pr-sm-3 px-lg-0">
                     <FeatherIcon
                       className="icon-btn-like-fil-actualite-card"
                       icon="thumbs-up"
                     />
                   </button>
                   <button className="btn-like-fil-actualite-card px-lg-0">
                     <FeatherIcon
                       className="icon-btn-like-fil-actualite-card"
                       icon="thumbs-down"
                     />
                   </button> */}
                  <div className="col-sm-8 col-9 pl-0 pr-2 pr-sm-2 pr-md-3">
                    <InputGroup inside>
                      <InputGroup.Addon>
                        <img
                          className="icon-comment-fil-actualite"
                          src={IconComment}
                          alt=""
                        />
                      </InputGroup.Addon>
                      <input
                        type="text"
                        placeholder="Ajouter un commentaire"
                        className="input-comment-fil-actualite"
                        name="comment_text"
                        value={this.state.fieldss["comment_text"]}
                        onChange={(e) => this.handleChange(e)}
                      />
                      <button
                        className="btn-send-comment-fil-actualite"
                        type="submit"
                      >
                        <img
                          className="icon-btn-send-comment-fil-actualite"
                          src={IconSendComment}
                          alt=""
                        />
                      </button>
                    </InputGroup>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        ;
      </Amplitude>
    );
  }
}
// export default connect(
//     ({ voirMesContenus }) => ({ ...voirMesContenus }),
//     dispatch => bindActionCreators({ ...voirMesContenusActions }, dispatch)
//   )( voirMesContenus );
