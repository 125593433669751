import React, {Component} from 'react';
import './livraisonDunBakelisteView.css';
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";
import amplitude from "amplitude-js";
import LivraisonDunBakeliste from "../livraisonDunBakeliste/index";
import qs from "qs"; 
import { Amplitude } from "@amplitude/react-amplitude";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as livraisonDunBakelisteViewActions from "../../store/livraisonDunBakelisteView/actions";
export default class livraisonDunBakelisteView extends Component {
    constructor(props) {
        super(props);
        let bak_id = this.props.location?.state?.id;
        let bakelisteDonnees = this.props.location?.state?.data;
        if (!bak_id && this.props.location.search) { 
          const obj = qs.parse(this.props.location.search.replace("?", ""));
          bak_id = parseInt(obj.id);
        } 
          this.state = {
            bakeliste_id: bak_id, 
            data: bakelisteDonnees,
            email: window.sessionStorage.getItem("email"),
            user_id: window.sessionStorage.getItem("user_id"), 
        };
        // console.log(this.state.bakeliste_id,"idddddddd" , this.state.data, "dataaa")
    }
    componentDidMount =() => {
      var eventProperties = {
        user_email: this.state.email,
        user_id: this.state.user_id,
      };
        this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
        amplitude.getInstance().logEvent("VISITED_LIVRAISON_D'UN_BAKELISTE_V2", eventProperties);
    }
    amplitudeScript = (api_key) => {
      (function (e, t) {
        var n = e.amplitude || { _q: [], _iq: {} };
        var r = t.createElement("script");
        r.type = "text/javascript";
        r.integrity =
          "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
        r.crossOrigin = "anonymous";
        r.async = true;
        r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
        r.onload = function () {
          if (!e.amplitude.runQueuedFunctions) {
          }
        };
        var i = t.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(r, i);
        function s(e, t) {
          e.prototype[t] = function () {
            this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
            return this;
          };
        }
        var o = function () {
          this._q = [];
          return this;
        };
        var a = [
          "add",
          "append",
          "clearAll",
          "prepend",
          "set",
          "setOnce",
          "unset",
        ];
        for (var u = 0; u < a.length; u++) {
          s(o, a[u]);
        }
        n.Identify = o;
        var c = function () {
          this._q = [];
          return this;
        };
        var l = [
          "setProductId",
          "setQuantity",
          "setPrice",
          "setRevenueType",
          "setEventProperties",
        ];
        for (var p = 0; p < l.length; p++) {
          s(c, l[p]);
        }
        n.Revenue = c;
        var d = [
          "init",
          "logEvent",
          "logRevenue",
          "setUserId",
          "setUserProperties",
          "setOptOut",
          "setVersionName",
          "setDomain",
          "setDeviceId",
          "enableTracking",
          "setGlobalUserProperties",
          "identify",
          "clearUserProperties",
          "setGroup",
          "logRevenueV2",
          "regenerateDeviceId",
          "groupIdentify",
          "onInit",
          "logEventWithTimestamp",
          "logEventWithGroups",
          "setSessionId",
          "resetSessionId",
        ];
        function v(e) {
          function t(t) {
            e[t] = function () {
              e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
            };
          }
          for (var n = 0; n < d.length; n++) {
            t(d[n]);
          }
        }
        v(n);
        n.getInstance = function (e) {
          e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
          if (!n._iq.hasOwnProperty(e)) {
            n._iq[e] = { _q: [] };
            v(n._iq[e]);
          }
          return n._iq[e];
        };
        e.amplitude = n;
      })(window, document);
      amplitude.getInstance().init(api_key);
    };

    
    render() {
      return (
        <Amplitude>
          <div className="body-theme">
            <HeaderDashboardCoach />
            <div className="container-fluid fixing-after-header">
              <div className="row">
                <div className="col-lg-2 p-0">
                  <NavVerticalCoach />
                </div>
                <div className="col-lg-10 pt-3">
                  <LivraisonDunBakeliste  
                    bakeliste_id={this.state.bakeliste_id}
                    Name={this.state.data.user_fullname}
                  />
                </div>
              </div>
            </div>
          </div>
        </Amplitude> );
    }
  }
// export default connect(
//     ({ livraisonDunBakelisteView }) => ({ ...livraisonDunBakelisteView }),
//     dispatch => bindActionCreators({ ...livraisonDunBakelisteViewActions }, dispatch)
//   )( livraisonDunBakelisteView );