import API2 from "../../../../variablesGlobales";
import API from "../../../../variablesGlobales";
import axios from "axios";
export const FETCH_GROUPE_BAKELISTE = "FETCH_GROUPE_BAKELISTE";

export const getGroupeBakeliste = (groupeId) => {
    return (dispatch) => {
      return axios 
        .get(API.API + "sous__groupes/" + groupeId) 
        .then((response) => {
        if (response.data.data !== undefined) {

          dispatch({
            type: FETCH_GROUPE_BAKELISTE,
            payload: response.data.liste,
            name: response.data.data.name,
          });

     } })
        .catch((err) => console.log(err));
    };
  };


 