import { DEMANDES_ABSENCES_BY_BAKELISTE,ADD_DEMANDE_ABSENCE,UPDATED_DEMANDE_ABSENCE } from "../actions/demandeAbsenceActions";

const initialState = {
    items: [],
    loading: false,
    error: null,
};

export default function demandeAbsenceReducer(state = initialState, action) {
    switch (action.type) {

        case DEMANDES_ABSENCES_BY_BAKELISTE:
        return {
            ...state,
            loading: false,
            error: action.payload.error,
            items: action.payload,
        };
        case ADD_DEMANDE_ABSENCE:
            return{
                ...state,
                loading:false,
                error:null,
                items:null,
                success:true,
                
            };
        case UPDATED_DEMANDE_ABSENCE:
            return{
                ...state,
                loading:false,
                error:null,
                items:null,
                success:true,
                
            };
        default :
        return state
    }

}