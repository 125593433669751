import React, { Component } from "react";
import "./connexion.css";
import Logo from "../../img/logo.png";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import API from "../../variablesGlobales";
import amplitude from "amplitude-js";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { FaFacebook, FaGoogle } from "react-icons/fa";

Sentry.init({
  dsn:
    "https://cfd5fe9f51b049ad94ea20a6bd7ab883@o253692.ingest.sentry.io/1442714",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
class Connexion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginPage: true,
      email: "",
      password: "",
      errors: {},
      isAuthenticated: false,
      token: null,
      isValidUser: true,
      authAlertError: false,
      userFullName: "",
      assistant_domaine_id: "",
      isPending: false,
      api: API.API,
      email_empty: false,
      password_empty: false,
      isLoaded: true,
      isRequestError: false,
      notUser: false,
      trainingIsCompleted: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ isLoaded: false });
      }.bind(this),
      6000
    );
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("visitedHomePage_V2");
  }

  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  responseFacebook = (res) => {
    const fields = {
      email: res.email,
      provider: res.graphDomain,
      provider_id: res.id,
      token: res.accessToken,
    };
    var eventProperties = {
      user_email: res.email,
      provider: res.graphDomain,
    };
    axios
      .post(this.state.api + "user/social-login", fields, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (response.status === 205) {
          this.setState({ notUser: true });
        }
        let errors = {};
        const token = response.data.data.auth_token;
        const sous_groupe_id = response.data.data.sous_groupe_id;
        const user_first_name = response.data.data.first_name;
        const user_last_name = response.data.data.last_name;
        const email = response.data.data.email;
        const userFullName =
          response.data.data.first_name + " " + response.data.data.last_name;
        const userStatus = response.data.data.status;
        const connectedUserID = response.data.data.id;
        const user_phone = response.data.data.phone;
        const domaine_id = response.data.data.bakeliste_domaine_id;
        const coach_id = response.data.data.coach_id;
        const avatar_file = response.data.data.avatar_file;
        const isBakelisteEndTraining = response.data.data.trainingIsCompleted;
        this.setState({
          userFullName: userFullName,
          token: token,
        });
        if (token) {
          window.sessionStorage.setItem("token", token);
          localStorage.setItem("user", response.data.data);
          window.sessionStorage.setItem("sous_groupe_id", sous_groupe_id);
          window.sessionStorage.setItem("userFullName", userFullName);
          window.sessionStorage.setItem("user_status", userStatus);
          window.sessionStorage.setItem("email", email);
          window.sessionStorage.setItem("avatar_file", avatar_file);

          window.sessionStorage.setItem("user_id", connectedUserID);
          window.sessionStorage.setItem("user_first_name", user_first_name);
          window.sessionStorage.setItem("user_last_name", user_last_name);
          window.sessionStorage.setItem("user_phone", user_phone);
          window.sessionStorage.setItem("domaine_id", domaine_id);

          window.sessionStorage.setItem("coach_id", coach_id);

          localStorage.setItem("app_data", JSON.stringify(token));
          if (userStatus === "bakeliste") {
          }
          this.setState({
            isPending: false,
          });
          // amplitude
          //   .getInstance()
          //   .logEvent("CONNEXION_BAKELISTE_TYPE", eventProperties);
          if (response.data.data.status === "bakeliste") {
            if (isBakelisteEndTraining === 1) {
              this.setState({
                trainingIsCompleted: true,
              });
              window.sessionStorage.removeItem("coach_fullname");
              window.sessionStorage.removeItem("coach_email");
              window.sessionStorage.removeItem("coach_phone");
              window.sessionStorage.removeItem("token");

              amplitude
                .getInstance()
                .logEvent(
                  "END_TRANING_BAKELISTE_CONNEXION_TYPE_V2",
                  eventProperties
                );
            } else {
              window.location = "dashboard-bakeliste";
              amplitude
                .getInstance()
                .logEvent(
                  "BAKELISTE_FACEBOOK_CONNEXION_TYPE_V2",
                  eventProperties
                );
            }
          } else if (response.data.data.status === "coach") {
            window.location = "coach-dashboard";
            amplitude
              .getInstance()
              .logEvent("COACH_FACEBOOK_CONNEXION_TYPE_V2", eventProperties);
          } else if (response.data.data.status === "coach-assistant") {
            window.location = "/dashboard-coach-assistant";
            amplitude
              .getInstance()
              .logEvent("COACH_FACEBOOK_CONNEXION_TYPE_V2", eventProperties);
          }
        } else if (!token) {
          errors["auth_error"] = "Vos identifiants ne sont pas corrects.";
          amplitude.getInstance().logEvent("NO_CORRECT_ID_CONNEXION_TYPE_V2");

          this.setState({
            errors: errors,
            isValidUser: false,
            isPending: false,
          });

          this.handleShowAndHideAlert("credential_error");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  responseGoogle = (response) => {
    const fields = {
      email: response.Es.kt,
      provider: "google",
      provider_id: response.googleId,
      token: response.accessToken,
    };
    var eventProperties = {
      user_email: response.email,
      provider: "google",
    };
    axios
      .post(this.state.api + "user/social-login", fields, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (response.status === 205) {
          this.setState({ notUser: true });
        }
        let errors = {};
        const token = response.data.data.auth_token;
        const sous_groupe_id = response.data.data.sous_groupe_id;
        const user_first_name = response.data.data.first_name;
        const user_last_name = response.data.data.last_name;
        const userFullName =
          response.data.data.first_name + " " + response.data.data.last_name;
        const userStatus = response.data.data.status;
        const connectedUserID = response.data.data.id;
        const user_phone = response.data.data.phone;
        const domaine_id = response.data.data.bakeliste_domaine_id;
        const isBakelisteEndTraining = response.data.data.trainingIsCompleted;
        this.setState({
          userFullName: userFullName,
          token: token,
        });
        if (token) {
          window.sessionStorage.setItem("token", token);
          localStorage.setItem("user", response.data.data);
          window.sessionStorage.setItem("sous_groupe_id", sous_groupe_id);
          window.sessionStorage.setItem("userFullName", userFullName);
          window.sessionStorage.setItem("user_status", userStatus);
          window.sessionStorage.setItem("user_id", connectedUserID);
          window.sessionStorage.setItem("user_first_name", user_first_name);
          window.sessionStorage.setItem("user_last_name", user_last_name);
          window.sessionStorage.setItem("user_phone", user_phone);
          window.sessionStorage.setItem("domaine_id", domaine_id);

          window.sessionStorage.setItem(
            "coach_id",
            response.data.data.coach_id
          );
          localStorage.setItem("app_data", JSON.stringify(token));
          if (userStatus === "bakeliste") {
          }
          this.setState({
            isPending: false,
          });
          // amplitude
          //   .getInstance()
          //   .logEvent("CONNEXION_BAKELISTE_TYPE", eventProperties);
          if (response.data.data.status === "bakeliste") {
            if (isBakelisteEndTraining === 1) {
              this.setState({
                trainingIsCompleted: true,
              });
              window.sessionStorage.removeItem("coach_fullname");
              window.sessionStorage.removeItem("coach_email");
              window.sessionStorage.removeItem("coach_phone");
              window.sessionStorage.removeItem("token");
              amplitude
                .getInstance()
                .logEvent(
                  "END_TRANING_BAKELISTE_CONNEXION_TYPE_V2",
                  eventProperties
                );
            } else {
              window.location = "dashboard-bakeliste";
              amplitude
                .getInstance()
                .logEvent(
                  "BAKELISTE_GOOGLE_CONNEXION_TYPE_V2",
                  eventProperties
                );
            }
          } else if (response.data.data.status === "coach") {
            window.location = "coach-dashboard";
            amplitude
              .getInstance()
              .logEvent("COACH_GOOGLE_CONNEXION_TYPE_V2", eventProperties);
          } else if (response.data.data.status === "coach-assistant") {
            window.location = "/dashboard-coach-assistant";
            amplitude
              .getInstance()
              .logEvent("COACH_GOOGLE_CONNEXION_TYPE_V2", eventProperties);
          }
        } else if (!token) {
          errors["auth_error"] = "Vos identifiants ne sont pas corrects.";
          amplitude.getInstance().logEvent("NO_CORRECT_ID_CONNEXION_TYPE_V2");
          this.setState({
            errors: errors,
            isValidUser: false,
            isPending: false,
          });
          this.handleShowAndHideAlert("credential_error");
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };

  validateLoginForm() {
    let email = this.state.email;
    let password = this.state.password;
    let isValidForm = true;
    let errors = {};
    if (!email) {
      this.setState({
        isPending: false,
      });
      isValidForm = false;
      this.setState({
        email_empty: true,
      });
      this.handleShowAndHideAlert("email");
      errors["email"] = "Le champ email est obligatoire";
    }
    if (!password) {
      this.setState({
        isPending: false,
      });
      isValidForm = false;
      this.setState({
        password_empty: true,
      });
      this.handleShowAndHideAlert("password");
      errors["password"] = "Le champ mot de passe est obligatoire";
    }
    this.setState({
      errors: errors,
    });
    return isValidForm;
  }

  handleShowAndHideAlert(input) {
    setTimeout(() => {
      if (input === "credential_error") {
        this.setState({
          isValidUser: true,
        });
      }
      if (input === "email") {
        this.setState({
          email_empty: false,
        });
      }
      if (input === "password") {
        this.setState({
          password_empty: false,
        });
      }
      if (input === "requestError") {
        this.setState({
          isRequestError: false,
        });
      }
    }, 5000);
  }

  doLogin = (e) => {
    e.preventDefault();

    this.setState({
      isPending: true,
    });
    if (this.validateLoginForm()) {
      const fields = {
        email: this.state.email,
        password: this.state.password,
      };
      var eventProperties = {
        user_email: this.state.email,
      };
      axios
        .post(this.state.api + "user/login", fields, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          let errors = {};
          const token = response.data.data.auth_token;
          const sous_groupe_id = response.data.data.sous_groupe_id;
          const user_first_name = response.data.data.first_name;
          const user_last_name = response.data.data.last_name;
          const userFullName =
            response.data.data.first_name + " " + response.data.data.last_name;
          const userStatus = response.data.data.status;
          const connectedUserID = response.data.data.id;
          const user_phone = response.data.data.phone;
          const avatar_file = response.data.data.avatar_file;
          const domaine_id = response.data.data.bakeliste_domaine_id;
          const assistant_domaine_id = response.data.data.coach_domaine_id;
          const isBakelisteEndTraining = response.data.data.trainingIsCompleted;
          this.setState({
            userFullName: userFullName,
            token: token,
          });
          if (token) {
            window.sessionStorage.setItem("token", token);
            localStorage.setItem("user", response.data.data);
            window.sessionStorage.setItem("avatar_file", avatar_file);

            window.sessionStorage.setItem("sous_groupe_id", sous_groupe_id);
            window.sessionStorage.setItem("userFullName", userFullName);
            window.sessionStorage.setItem("user_status", userStatus);
            window.sessionStorage.setItem("user_id", connectedUserID);
            window.sessionStorage.setItem("user_first_name", user_first_name);
            window.sessionStorage.setItem("user_last_name", user_last_name);
            window.sessionStorage.setItem("user_phone", user_phone);
            window.sessionStorage.setItem("domaine_id", domaine_id);
            window.sessionStorage.setItem(
              "assistant_domaine_id",
              assistant_domaine_id
            );

            window.sessionStorage.setItem(
              "coach_id",
              response.data.data.coach_id
            );
            localStorage.setItem("app_data", JSON.stringify(token));
            if (userStatus === "bakeliste") {
            }
            this.setState({
              isPending: false,
            });
            // amplitude
            //   .getInstance()
            //   .logEvent("CONNEXION_BAKELISTE_TYPE", eventProperties);
            if (response.data.data.status === "bakeliste") {
              if (response.data.data.is_completed === 1) {
                this.setState({
                  trainingIsCompleted: true,
                });
                window.sessionStorage.removeItem("coach_fullname");
                window.sessionStorage.removeItem("coach_email");
                window.sessionStorage.removeItem("coach_phone");
                window.sessionStorage.removeItem("token");
                amplitude
                  .getInstance()
                  .logEvent(
                    "END_TRANING_BAKELISTE_CONNEXION_TYPE_V2",
                    eventProperties
                  );
              }
              if (isBakelisteEndTraining === 1) {
                this.setState({
                  trainingIsCompleted: true,
                });
                window.sessionStorage.removeItem("coach_fullname");
                window.sessionStorage.removeItem("coach_email");
                window.sessionStorage.removeItem("coach_phone");
                window.sessionStorage.removeItem("token");
                amplitude
                  .getInstance()
                  .logEvent(
                    "END_TRANING_BAKELISTE_CONNEXION_TYPE_V2",
                    eventProperties
                  );
              } else {
                window.location = "dashboard-bakeliste";
                amplitude
                  .getInstance()
                  .logEvent("BAKELISTE_CONNEXION_TYPE_V2", eventProperties);
              }
            } else if (response.data.data.status === "coach") {
              window.location = "coach-dashboard";
              amplitude
                .getInstance()
                .logEvent("COACH_CONNEXION_TYPE_V2", eventProperties);
            } else if (response.data.data.status === "coach-assistant") {
              window.location = "/dashboard-coach-assistant";
              amplitude
                .getInstance()
                .logEvent("COACH_ASSISTANT_CONNEXION_TYPE_V2", eventProperties);
            }
          } else if (!token) {
            errors["auth_error"] = "Vos identifiants ne sont pas corrects.";
            amplitude.getInstance().logEvent("NO_CORRECT_ID_CONNEXION_TYPE_V2");
            this.setState({
              errors: errors,
              isValidUser: false,
              isPending: false,
            });
            this.handleShowAndHideAlert("credential_error");
          }
        })
        .catch((error) => {
          this.setState({
            isPending: false,
            isRequestError: true,
          });
          amplitude.getInstance().logEvent("REQUEST_ERROR_CONNEXION_TYPE_V2");

          this.handleShowAndHideAlert("requestError");
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    }
  };

  testAmplitude = () => {
    amplitude.logEvent("test Amplitude v2", {
      color: "red;",
      shape: "triangle",
      sides: 3,
    });
  };

  render() {
    return (
      <Amplitude>
        <div className="container-fluid">
          <LogOnMount eventType="visite page d'accueil" />
          <div className="row">
            <div className="col-md-6 banner-connexion-page dis-flex justify-content-center itm-center py-5">
              <div className="col-xl-9 col-lg-10 col-md-11 col-sm-8 text-center">
                <h1 className="titre-banner-connexion">Bakeli SI</h1>
                <p className="slogan-banner-connexion">
                  Training the best tech talent in Africa.
                </p>
              </div>
            </div>
            <div className="col-md-6 bg-white dis-flex justify-content-center itm-center py-md-0 py-5">
              <div className="col-xl-7 col-lg-9 col-md-11 col-sm-8 dis-flex flex-col">
                <img className="logo-connexion m-l-r-auto" src={Logo} alt="" />
                <form onSubmit={(e) => this.doLogin(e)}>
                  <p className="h-connexion-form">CONNECTEZ-VOUS</p>
                  {this.state.notUser && (
                    <div className="auth-error text-center">
                      Cet utilisateur n'existe pas dans la base de donnee.
                    </div>
                  )}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text input-group-text-connexion"
                        id="basic-addon1"
                      >
                        <FeatherIcon
                          className="icon-form-connexion"
                          icon="mail"
                        />
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control input-form-connexion"
                      placeholder="Adresse Mail"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </div>
                  {this.state.email_empty && (
                    <div className="errorMsg">{this.state.errors.email}</div>
                  )}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text input-group-text-connexion"
                        id="basic-addon1"
                      >
                        <FeatherIcon
                          className="icon-form-connexion"
                          icon="lock"
                        />
                      </span>
                    </div>
                    <input
                      type="password"
                      className="form-control input-form-connexion"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      placeholder="Mot de passe"
                    />
                  </div>
                  {this.state.password_empty && (
                    <div className="errorMsg">{this.state.errors.password}</div>
                  )}
                  <NavLink
                    className="mdp-forget-link"
                    to="/reinitialiser-mot-de-passe"
                  >
                    Mot de passe oublié ?
                  </NavLink>
                  <button className="btn-connexion">
                    CONNEXION &nbsp;
                    {this.state.isPending && (
                      <FeatherIcon
                        icon="loader"
                        aria-hidden="true"
                        style={{ color: "#069A77" }}
                      />
                    )}
                  </button>
                  {!this.state.isValidUser && (
                    <div className="auth-error text-center">
                      {this.state.errors.auth_error}
                    </div>
                  )}
                  {this.state.isRequestError && (
                    <div className="auth-error text-center">
                      La connexion a échoué. Veuillez réessayer plus tard.
                    </div>
                  )}

                  {this.state.trainingIsCompleted && (
                    <div className="auth-error text-center">
                      Votre formation est terminée. Veuillez vous addresser à
                      votre coach.
                    </div>
                  )}
                  <div className="row omb_row-sm-offset-3 omb_loginOr">
                    <div className="col-xs-12 col-sm-12">
                      <hr className="omb_hrOr" />
                      <span className="omb_spanOr"> Ou se connecter avec</span>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      {/* <FacebookLogin
                        cssclassName="btn-connexion-facebook"
                        // appId="271249503551607"
                        appId="228359102285829"
                        textButton={<FaFacebook />}
                        // render={(renderProps) => (
                        //   <button
                        //     className="btn-connexion-facebook"
                        //     onClick={renderProps.onClick}
                        //     cssclassName="my-facebook-button-class"
                        //   >
                        //     Facebook
                        //   </button>
                        // )}

                        autoLoad
                        fields="name,email,picture"
                        callback={this.responseFacebook}
                      /> */}
                      <p className="alignemant-icon-fb-block">
                        <span className="">
                          <FacebookLogin
                            appId="228359102285829"
                            textButton="Continue avec Facebook"
                            className="but-facb"
                            fields="name,email,picture"
                            callback={this.responseFacebook}
                            icon="fa-facebook"
                          />
                        </span>
                      </p>
                    </div>
                    {/* <div className="col-md-3">
                      <GoogleLogin
                        jsSrc="https://apis.google.com/js/api.js"
                        clientId="481844604119-o8v2f93kgessr3p37hu2064ajfq0lup4.apps.googleusercontent.com"
                        render={(renderProps) => (
                          <button
                            onClick={renderProps.onClick}
                            className="btn-connexion-google"
                          >
                            <FaGoogle />{" "}
                          </button>
                        )}
                        // isSignedIn={true}
                        className="btn-connexion-google"
                        onSuccess={this.responseGoogle}
                        // onFailure={this.responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      />
                    </div> */}
                  </div>
                  {/* Log an event when this view mounts */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </Amplitude>
    );
  }
}
export default Connexion;
