import React, { Component } from "react";
import "./ajoutQuestionCoach.css";
import axios from "axios";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import $ from "jquery";
import qs from "qs";
import MultiSelect from "@khanacademy/react-multi-select";
import FeatherIcon from "feather-icons-react";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";
import { toast } from "react-toastify";

import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import { Link } from "react-router-dom";
export default class ajoutQuestionCoach extends Component {
  constructor(props) {
    super(props);
    let id = this.props.location?.state?.id;
    if (!id && this.props.location.search) {
      const obj = qs.parse(this.props.location.search.replace("?", ""));
      id = parseInt(obj.id);
    }
    let nameEx = this.props.location?.state?.nom;
    if (!nameEx && this.props.location.search) {
      const obj = qs.parse(this.props.location.search.replace("?", ""));
      nameEx = parseInt(obj.nameEx);
    }
    this.state = {
      selectOptions: [],
      selected: [],
      questionsVerifier: [],
      fields: {},
      id: id,
      options: [],
      api: API.API,
      ajoutQuestionEnCours: false,
      name: nameEx,
    };
    // console.log(this.state.id)
  }

  componentDidMount = () => {
    this.getQuestions();
  };

  async getQuestions() {
    axios
      .get(this.state.api + "get-verified-questions/")
      .then((response) => {
        this.setState({ questionsVerifier: response.data.data });
        // console.log('verifier', this.state.questionsVerifier);
        var data = this.state.questionsVerifier;
        data.forEach((item) => {
          let infos = {
            label: item.libelle,
            value: item.id,
          };
          this.state.selectOptions.push(infos);
          this.setState({ options: infos });
          //console.log(infos);
        });
      })
      .catch((error) => {
        // console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  }

  setSelected = (value) => {
    this.state.options.push(value);
    //console.log("selected", this.state.options)
    // console.log('value', value)
    this.setState({ options: value });
  };

  setSelected = (value) => {
    this.state.options.push(value);
    // console.log("selected", this.state.options);
    // console.log("value", value);
    this.setState({ options: value });
  };

  ajoutQuestion() {
    this.setState({
      ajoutQuestionEnCours: true,
    });

    let data = this.state.fields;
    data["questions"] = this.state.selected;
    data["examen_id"] = this.state.id;

    axios
      .post(this.state.api + "add-questions-to-exam", data)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            ajoutQuestionEnCours: false,
            fields: {},
          });
          toast.success(
            "ajout efféctué avec success",
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 3000 }
          );
          window.location = `/details-examens-theorique?id=${this.state.id}`;
        } else {
          toast.error(
            "erreur sur l ajout de question",
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 3000 }
          );
        }
        $(".close-modal-bakeliste-title").click();
      })
      .catch((error) => {
        this.setState({
          ajoutQuestionEnCours: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }

  render() {
    // console.log("data", this.state.selected);
    return (
      <div className="body-theme">
        <NavVerticalCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <HeaderDashboardCoach />
            </div>

            <div className="col-lg-7 p-0">
              <div className="row">
                <div className="col-lg-2 p-0">
                  <HeaderDashboardCoach />
                </div>

                <div className="col-lg-7 p-0">
                  <div className="row">
                    <div className="col-lg-9 pt-3">
                      <div className="row">
                        <div className="col-lg-11 pt-3">
                          <nav aria-label="breadcrumb fixed">
                            <ol className="breadcrumb fixed">
                              <li className="breadcrumb-item ">
                                <Link to="/dashboard-ch">Dashboard</Link>
                              </li>
                              <li className="breadcrumb-item " aria-current="page">
                                <Link to="/examens">Examens</Link>
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                              >
                                Ajouter question dans {this.state.name}
                              </li>
                            </ol>
                          </nav>
                          <div className="mto-3">
                            <form
                              encType="multipart/form-data"
                              id="livrable_form"
                            >
                              <div className="form-group col-md-12">
                                <label>Choisissez un ou des questions</label>
                                <MultiSelect
                                  overrideStrings={{
                                    selectSomeItems:
                                      "Choisissez une ou des questions",
                                    allItemsAreSelected:
                                      "Toutes les questions disponibles ont été choisis",
                                    selectAll: "Sélectionnez tout",
                                    search: "Rechercher",
                                  }}
                                  options={this.state.selectOptions}
                                  selected={this.state.selected}
                                  onSelectedChanged={(selected) =>
                                    this.setState({ selected })
                                  }
                                />
                              </div>

                              {this.state.ajoutQuestionEnCours ? (
                                <div className="col-lg-6 offset-6">
                                  <button
                                    type="submit"
                                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                    disabled
                                  >
                                    Ajout en cours &nbsp;
                                    <FeatherIcon
                                      icon="loader"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              ) : (
                                <div className="col-lg-6 offset-6">
                                  <button
                                    type="button"
                                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                    onClick={() => this.ajoutQuestion()}
                                  >
                                    Ajouter à l'examen
                                  </button>
                                </div>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>

                      <div></div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                  <div className="row">
                    <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                      <BakelisteGroupItemForCoach />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                      <ProgramReunionCoach />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                      <SavedReunionListItem />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
