import React, {Component} from 'react';
import './examenCoachView.css'

import ExamenCoach from '../examenCoach/index';
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";

export default class examenCoachView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    } 
    render() { 
      return  <div className="body-theme">
        <HeaderDashboardCoach />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalCoach />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <ExamenCoach />
                    </div>
                  </div>
                </div>

              </div>;
    }
  }

// export default connect(
//     ({ examenCoachView }) => ({ ...examenCoachView }),
//     dispatch => bindActionCreators({ ...examenCoachViewActions }, dispatch)
//   )( examenCoachView );