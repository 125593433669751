import {
    FETCH_LIVRAISONS_BEGIN,
    FETCH_LIVRAISONS_SUCCESS,
    FETCH_LIVRAISONS_FAILURE
} from '../actions/livraisonsActions'

const initialState ={
    items:[],
    loading:false,
    error:null
};


export default function livraisonReducer(state=initialState,action){
    switch(action.type){
        case FETCH_LIVRAISONS_BEGIN:
            
                return{
                ...state,
                loading:true,
                error:null
            };
        case FETCH_LIVRAISONS_SUCCESS:
            return{
                ...state,
                loading:false,
                items:action.payload.livraisons,
            };
        case FETCH_LIVRAISONS_FAILURE:
            return{
                ...state,
                loading:false,
                error:action.payload.error,
                items:[]
            };
            default:
                return state;
    }
}

