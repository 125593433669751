import React, {Component} from 'react';
import './syllabusDetails.css';

//import {Icon } from 'rsuite';

import FeatherIcon from 'feather-icons-react';
import SyllabusItemTache from '../syllabusItemTache/index';
import axios from 'axios';
//import {NavLink} from 'react-router-dom';

import API from '../../variablesGlobales';

      


export default class syllabusDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
          syllabus_id:this.props.id,
          api: API.API,
          syllabusDatas: [], 

        };
    }
    componentDidMount=() => {
      this.onGetSyllabusDetails()
    }


    onGetSyllabusDetails= () => {
      this.setState({
        isPageLoading: true
      })
      var baseApiUrl = this.state.api;
      
      axios.get(baseApiUrl + 'all-details-syllabus/' + this.state.syllabus_id)
      .then(response => {

          this.setState({
            isPageLoading: false
          })
      
          if (response.data.success) {
            this.setState({
              isPageLoading: false,
              syllabusDatas: response.data.data,
            })
           
          }
          

        })
        .catch(error => {
         // console.log(error.message)
          this.setState({
            isPageLoading: false
          })
          if (process.env.NODE_ENV !== 'production') {
            return;
          }
        })
      }

   
    render() {
      return  <div>
                <div className="card-dashboard mb-4 mto-3">
                  <div className="row m-b-30">
                    <div className="col-md-8">
                 
                      <p className="titre-syllabus-details-card mb-3 col-md-6"> {this.props.name}</p>
                     
                  
                      {/* !!!!important !!!! */}
                      {/* Etiquette pour debutant  */} 
                      <span /* className="etiquette-syllabus-details " */
                        className={
                          "eetiquette-syllabus-details " +
                          (this.props.niveau === "débutant"
                            ? " etiquette-syllabus-details debutant mb-2"
                            : this.props.niveau === "intermédiaire"
                            ? "etiquette-syllabus-details intermediaire mb-2"
                            : this.props.niveau === "avancé"
                            ? "etiquette-syllabus-details avance mb-2"
                            : "")
                        }
                      >   
                        {this.props.niveau}
                      </span>
                       <div className="row mb-4">
                        <div className="col-xl-3 col-lg-4 pr-lg-0 col-md-3 pr-md-0 col-sm-3 col-6">
                          <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="list"/> 
                            {this.props.nbreTaches} tâches</p>
                        </div>
                      {/*   <div className="col-xl-4 col-lg-4 pl-xl-0 px-lg-0 col-md-3 col-6 px-0">
                          <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="clock"/> 2 semaines</p>
                        </div> */}
                        <div className="col-xl-4 pl-xl-0 col-6 pr-0">
                        <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="user"/> {this.props.nbreBakeliste} bakelistes</p>
                      </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4 dis-flex">
                      <AddTacheSullabus/>
                    </div> */}

                  </div>
                  <p className="content-syllabus-details-card">{this.props.description}</p>
                </div>
                  <p className="fw-700 ff-inter fs-14 text-dark mb-3">Liste des tâches</p>

                
                {this.state.syllabusDatas.details !== undefined ? this.state.syllabusDatas.details.map((item,index) =>
                  <div className="mb-5">
                    <SyllabusItemTache 
                    id={this.state.syllabus_id}  
                    key={index} id_tache={index+1}  
                    contenu={item.syllabus_task} 
                    description={item.description}
                     lien={item.tache_url} 
                     duree={item.syllabus_completion_time}
                     />
                  </div>
                 ):''}
              </div>;
    }
  }
// export default connect(
//     ({ syllabusDeatils }) => ({ ...syllabusDeatils }),
//     dispatch => bindActionCreators({ ...syllabusDeatilsActions }, dispatch)
//   )( syllabusDeatils );