import React, { Component } from "react";
import { Link } from "react-router-dom";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import frenchShortFormatDate from "../frenchFormatDate";
import "./ficheBakeliste.css";
import FeatherIcon from "feather-icons-react";
import qs from "qs";
//import axios from "axios";
import API from "../../variablesGlobales";
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import axios from "axios";
import * as Sentry from "@sentry/react";
import $ from "jquery";
import Preloader from "../preloader/index";

export default class ficheBakeliste extends Component {
  constructor(props) {
    super(props);
    let bakeliste_id = this.props.location?.state?.id;
    let bakelisteDonnees = this.props.location?.state?.data;
    if (!bakeliste_id && this.props.location.search) {
      const obj = qs.parse(this.props.location.search.replace("?", ""));
      bakeliste_id = parseInt(obj.id);
    }

    this.state = {
      bakeliste_id: bakeliste_id,
      api: API.API,
      loading: true,
      bakelisteDonnees: bakelisteDonnees,
      formatDate: frenchShortFormatDate.frenchShortFormatDate,
      selectOptions: [],
      selectOptions1: [],
      selected: [],
      showAlertError: false,
      coach_id: window.sessionStorage.getItem("user_id"),
      selectedBakeliste: [],
      sous_groupes: [],
      ajoutEnCours: false,
      ajoutSuccessAlert: false,
      isAjoutError: false,
      fields: {},
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 4000);
    this.__getSousGroupes();
  }
  __getSousGroupes = () => {
    axios
      .get(this.state.api + "sous__groupes/coach/" + this.state.coach_id)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            sous_groupes: res.data.data.reverse(),
          });
        }
        this.state.sous_groupes.forEach((item) => {
          let infos = {
            label: item.name,
            value: item.id,
          };
          this.state.selectOptions.push(infos);
        });
        // _this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        // _this.hideLoader();
      });
  };

  ajoutBakeliste(id) {
    this.setState({
      ajoutEnCours: true,
    });
    let data = this.state.fields;
    // console.log(this.state.selected);
    this.state.selected.forEach((item) => {
      data["sous_groupe_id"] = item;
      data["is_only"] = 0;
      data["is_groupy"] = 1;
      data["bakeliste_ids"] = [id];
      // console.log("bids", id);
      axios
        .post(this.state.api + "sous_groupes/add-bakeliste", data)
        .then((res) => {
          if (res.data.success) {
            $("#date_groupe").val("");
            this.setState({
              ajoutEnCours: false,
              //showAlertSuccess: true,
              selected: [],
            });

            window.location.reload(true);
          } else {
            this.setState({
              showAlertError: true,
            });
          }
          $(".close-modal-bakeliste-title").click();
          $(".ajoutgroupform").reset();
        })
        .catch((error) => {
          // console.log(error.message);
          this.setState({
            ajoutEnCours: false,
            isAjoutError: true,
          });
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    });
  }
  handleMultiChange = (selectedOption) => {
    let idinc = [];
    selectedOption.map((inc, id) => {
      idinc.push(inc.value);
    });
    this.setState({ selected: idinc });
  };

  render() {
    // console.log(this.props.location)
    let bakeliste = this.state.bakelisteDonnees;
    // console.log(bakeliste)
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoach />
            </div>
            {this.state.loading ? (
              <div className="col-md-3 offset-md-5">
                <Preloader />
              </div>
            ) : (
              <>
                <div className="col-lg-10 pt-3">
                  <div className="p-b-200">
                    <div className="row">
                      <div className="col-lg-9">
                        {/* <div className="col-lg-10"> */}
                        <nav aria-label="breadcrumb fixed">
                          <ol className="breadcrumb fixed">
                            <li className="breadcrumb-item ">
                              <Link to="/dashboard-coach">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item " aria-current="page">
                              <Link to="/coach-bakelistes">Bakelistes</Link>
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              Fiche de {bakeliste.first_name}{" "}
                              {bakeliste.last_name}
                            </li>
                          </ol>
                        </nav>
                        {/* </div> */}
                        <div className="card-dashboard m-b-15 p-b-60 mto-3">
                          <div className="row mt-5 mb-3">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="label-account">
                                  Nom Complet:
                                </label>
                                <label className="form-control input-account">
                                  {" "}
                                  {bakeliste.first_name} {bakeliste.last_name}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">Email:</label>
                                <label className="form-control input-account">
                                  {bakeliste.email}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Adresse:
                                </label>
                                <label className="form-control input-account">
                                  {bakeliste.address}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Domaine:
                                </label>
                                <label className="form-control input-account">
                                  {bakeliste.formation_suivie}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Type de formation:
                                </label>
                                <br />
                                {bakeliste.type_formation === "courte" ? (
                                  <label className="form-control input-account">
                                    Formation Courte
                                  </label>
                                ) : (
                                  <label className="form-control input-account">
                                    Formation Longue
                                  </label>
                                )}
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Formation Payante:
                                </label>
                                <br />
                                {bakeliste.is_formation_payante === 0 ? (
                                  <span className="form-control input-account">
                                    Oui
                                  </span>
                                ) : (
                                  <span className="form-control input-account">
                                    Non
                                  </span>
                                )}
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Objectifs:
                                </label>
                                <textarea className="form-control input-account taille">
                                  {bakeliste.objectifs}
                                </textarea>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="label-account">
                                  Telephone:
                                </label>
                                <label className="form-control input-account">
                                  {bakeliste.phone}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Horaire:
                                </label>
                                <label className="form-control input-account"></label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Date début formation
                                </label>
                                <label className="form-control input-account">
                                  {this.state.formatDate(
                                    bakeliste.debut_formation
                                  )}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Date de fin
                                </label>
                                <label className="form-control input-account">
                                  {this.state.formatDate(
                                    bakeliste.fin_formation
                                  )}
                                </label>
                              </div>
                              <div className="form-group">
                                {/* <div className="col-md-3">
                        <label className="label-account">Sous Groupes:</label>
                      </div>
                      <div className="col-md-3">
                        <label className="label-account">Sous Group</label>
                      </div>
                           */}
                                <label className="label-account">
                                  Sous Groupes:
                                </label>
                                <button
                                  type="button"
                                  className="col-md-4 btn-theme text-up w-full offset-md-4"
                                  data-toggle="modal"
                                  data-target={
                                    "#ajouterBakelisteModale" +
                                    this.state.bakeliste_id
                                  }
                                >
                                  Ajouter
                                </button>
                                <SweetAlert
                                  show={this.state.showAlertError}
                                  onConfirm={() =>
                                    this.setState({ showAlertError: false })
                                  }
                                  error
                                  title="Erreur sur l'ajouté du bakeliste, veuillez reessayer!!!"
                                  style={{
                                    color: "#d9534f",
                                    fontSize: "12px",
                                    fontFamily: "Work Sans, sans-serif",
                                    paddingTop: 60,
                                    paddingBottom: 40,
                                  }}
                                  confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                                />

                                <div
                                  className="modal fade"
                                  id={
                                    "ajouterBakelisteModale" +
                                    this.state.bakeliste_id
                                  }
                                  tabIndex="-1"
                                  role="dialog"
                                  aria-labelledby="addLivraisonBakelisteTitle"
                                  aria-hidden="true"
                                >
                                  <div
                                    className="modal-dialog modal-dialog-centered"
                                    role="document"
                                  >
                                    <div className="modal-content modal-content-bakeliste">
                                      <h5
                                        className="modal-bakeliste-title m-b-50"
                                        id="addLivraisonBakelisteTitle"
                                      >
                                        Ajouter {bakeliste.first_name}{" "}
                                        {bakeliste.last_name} dans un
                                        sous-groupe
                                      </h5>
                                      <button
                                        type="button"
                                        className="close-modal-bakeliste-title"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <FeatherIcon
                                          icon="x"
                                          aria-hidden="true"
                                        />
                                      </button>
                                      <form
                                        encType="multipart/form-data"
                                        id="ajoutgroupform"
                                      >
                                        <div className="form-group col-md-12">
                                          <Select
                                            isMulti
                                            name="groupe_ids"
                                            options={this.state.selectOptions}
                                            className="basic-multi-select map-color"
                                            onChange={this.handleMultiChange}
                                            classNamePrefix="select"
                                          />
                                        </div>
                                        {this.state.ajoutEnCours ? (
                                          <button
                                            type="submit"
                                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                            disabled
                                          >
                                            Ajout en cours &nbsp;
                                            <FeatherIcon
                                              icon="loader"
                                              aria-hidden="true"
                                            />
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                            onClick={() =>
                                              this.ajoutBakeliste(
                                                this.state.bakeliste_id
                                              )
                                            }
                                          >
                                            Ajouter
                                          </button>
                                        )}
                                      </form>
                                    </div>
                                  </div>
                                </div>

                                {/* <label className="label-account offset-md-5">Sous Groupes</label> */}

                                <ul>
                                  {bakeliste.sous_groupes.map((item, index) =>
                                    item.sous_groupe !== null ? (
                                      <li>{item.sous_groupe.name}</li>
                                    ) : (
                                      ""
                                    )
                                  )}
                                </ul>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Syllabus:
                                </label>
                                <ul>
                                  {bakeliste.syllabus.map((item, index) =>
                                    item.syllabus !== null ? (
                                      <li>{item.syllabus.syllabus_name}</li>
                                    ) : (
                                      ""
                                    )
                                  )}
                                </ul>
                              </div>

                              <div className="form-group">
                                <label className="label-account">Bio:</label>
                                <textarea className="form-control input-account">
                                  {bakeliste.bio}
                                </textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                      <div className="col-md-6">
                        <label className="label-account">Nom Complet:</label>
                        <label className="form-control input-account"> {bakeliste.first_name} {bakeliste.last_name}</label>
               
                      </div>
                      <div className="col-md-6">
                        <label className="label-account">Email:</label>
                        <label className="form-control input-account">{bakeliste.email}</label>
                      </div>
                    </div >
                    <div className="row">
                      <div className="col-md-6">
                        <label className="label-account">Domaine:</label>
                        <label className="form-control input-account">{bakeliste.formation_suivie}</label>
                      </div>
            
                      <div className="col-md-6">
                        <label className="label-account">Telephone:</label>
                        <label className="form-control input-account">{bakeliste.phone}</label>
                      </div>
                    </div> 
                    <div className="row">

                      <div className="col-md-6">
                        <label className="label-account">Adresse:</label>
                        <label className="form-control input-account">{bakeliste.address}</label>
                      </div>
                      <div className="col-md-6">
                        <label className="label-account">Horaire:</label>
                        <label className="form-control input-account"></label>

                      </div>
                    </div>
                    <div className="row">

                      <div className="col-md-6">
                        <label className="label-account">Type de formation:</label><br/>
                        {bakeliste.type_formation === "courte" ? (
                          <span className="formation-courte">Formation Courte</span>
                        ) : (
                            <span className="formation-longue">Formation Longue</span>
                          )}
                      </div>
                      <div className="col-md-6">
                        <label className="label-account">
                          Formation Payante:
                      </label><br/>
                        {bakeliste.is_formation_payante === 0 ? (
                          <span className="form-control input-account">Oui</span>
                        ) : (
                            <span className="form-control input-account">Non</span>
                          )}
                      </div>
                    </div>

                    <div className="row">

                      <div className="col-md-6">
                        <label className="label-account">Date début formation</label>
                        <label className="form-control input-account">{this.state.formatDate(bakeliste.debut_formation)}</label>
                      </div>
                      <div className="col-md-6">
                        <label className="label-account">Date de fin</label>
                        <label className="form-control input-account">{this.state.formatDate(bakeliste.fin_formation)}</label>
                      </div>
                    </div>
                    <div className="row">

                      <div className="col-md-6">
                        <label className="label-account">Objectifs:</label>
                        <textarea className="form-control input-account">{bakeliste.objectifs}</textarea>
                      </div>

                      <div className="col-md-6">
                        <label className="label-account">Bio:</label>
                        <textarea className="form-control input-account">{bakeliste.bio}</textarea>
                      </div>
                    </div>
                    <div className="row">

                      <div className="col-md-6">
                        <label className="label-account">Sous Groupes:</label>
                        <ul>
                          {bakeliste.sous_groupes.map((item, index) =>
                            item.sous_groupe !== null ? (
                              <li>{item.sous_groupe.name}</li>
                            ) : (
                                ""
                              )
                          )}
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <label className="label-account">Syllabus:</label>
                        <ul>
                          {bakeliste.syllabus.map((item, index) =>
                            item.syllabus !== null ? (
                              <li>{item.syllabus.syllabus_name}</li>
                            ) : (
                                ""
                              )
                          )}
                        </ul>
                      </div>
                    </div> */}
                      </div>
                      <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                        <div className="row">
                          <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                            <BakelisteGroupItemForCoach />
                          </div>
                          <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                            <ProgramReunionCoach />
                          </div>
                          <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                            <SavedReunionListItem />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ ficheBakeliste }) => ({ ...ficheBakeliste }),
//     dispatch => bindActionCreators({ ...ficheBakelisteActions }, dispatch)
//   )( ficheBakeliste );
