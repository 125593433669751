import React, { useEffect, useState } from "react";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import Loader from "react-loader-spinner";
import Preloader from "../preloader/index";

import amplitude from "amplitude-js";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import SyllabusBakelisteRefact from "../syllabusBakeliste/SyllabusBakelisteRefact";

const SyllabusBakelisteViewRefact = () => {
  return (
    <div className="body-theme">
      <HeaderDashboard />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVertical />
          </div>
          <div className="col-lg-10 pt-3">
            <SyllabusBakelisteRefact />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyllabusBakelisteViewRefact;
