import React ,{useEffect,useState} from "react";
import "./dashboardBakeliste.css";
import {useDispatch,connect} from 'react-redux'
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItemRefact from "../bakelisteGroupItem/BakelisteGroupItemRefact";
import StatisticsBakeliste from "../statisticsBakeliste/index";
import ModalContactUs from "../modalContactUs/index";
import FeatherIcon from "feather-icons-react";
import LivraisonsBakelistesView from "../livraisonsBakelistesView/index";
import EnvoyerMonTravail from "../envoyerMonTravail/index";
import ListCoachAssistantRefact from "../listCoachAssistant/ListCoachAssistantRefact";
import { Alert, Button } from "react-bootstrap";
import { fetchLivraisons} from "../LivraisonRefactor/redux/actions/livraisonsActions"; 
import { Modal } from "rsuite";
import AddLivraisonsv2 from "../LivraisonRefactor/addLivraisonsv2/addLivraisonsv2";
import LivraisonsItemv2View from "../LivraisonRefactor/livraisonsItemv2View/livraisonsItemv2View";

const DashboardBakeliste=()=>{

  const [initialState, setState] = useState({
      alert: true,
      show: true,
      showModal: true,
      evente: "ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_V2_DASHBOARD",
      eventeDroite: "ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_V2_DROITE",
  })
  const dispatch = useDispatch();
  // useEffect(() => {(fetchLivraisons(dispatch) , setState({showModal:true})), [dispatch])
  useEffect(
    () => {
      fetchLivraisons(dispatch)
      // setState{showModal:true}
    },
    [dispatch]
  );
  // const [livraisons, setLivraisonsSyllabus]=useState(props.livraisons)
  // AmplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
  //   amplitude.getInstance().logEvent("bakelisteDashboard_v2_Redux")


  const close = () => {
    setState({ showModal: false });
  };

  const AlertDismissibleExample = () => {
    if (this.state.show) {
      return (
        <Alert
          variant="danger"
          onClose={() => this.setState({ show: false })}
          dismissible
        >
          <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
          <p>
            Change this and that and try again. Duis mollis, est non commodo
            luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
            Cras mattis consectetur purus sit amet fermentum.
          </p>
        </Alert>
      );
    }
    return (
      <Button onClick={() => this.setState({ show: true })}>Show Alert</Button>
    );
  };

    return (
      <div className="p-b-200 ">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </nav>
            <div className="row m-b-20 pt3">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600">Bakeli SI Dashboard</h6>
              </div>
              {/* <Modal
                className="modal-responsive-md"
                size={initialState.size}
                show={initialState.showModal}
                onHide={close}
              >
                <Modal.Header>
                  <Modal.Title className="modal-contact-us">
                    <h2>
                      Ajouter du Contenu{" "}
                      <FeatherIcon className=" bell" icon="bell" />
                    </h2>
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body className="max-height-modal">
                  <h4>
                    Savez-vous que c'est possible maintenant de CREER des
                    contenus textes sur le SI???
                  </h4>
                  <div className="fonct-y">Aaah OUI c'est possible</div>
                  <div className="m-3">
                    <h5 className="fonct-q"> Contenu??</h5>
                    <div className="fonct-y">
                      Vous pouvez désormais ajouter du contenu textuel dans le
                      SI{" "}
                    </div>

                    <li>RESUME</li>
                    <li>ARTICLES</li>
                    <li>TUTORIELS</li>
                    <li>RAPPORT DE STAGE</li>
                    <div className="fonct-y">
                      Vous pouvez aussi lire les contenus des autres bakelistes.
                    </div>
                    <div className="fonct-y">
                      NB: Les meilleurs contenus seront ajoutes dans le reseau
                      de Bakeli : network.bakeli.tech
                    </div>
                   
                  </div>
                </Modal.Body>
              </Modal> */}

              <div className="col dis-flex m-b-15">
                <ModalContactUs />
              </div>
            </div>
            <StatisticsBakeliste />
            {/* {this.state.show ? (
              <Alert
                variant="danger"
                onClose={() => this.setState({ show: false })}
                dismissible
              >
                <Alert.Heading>DÉROULEMENT DES EXAMENS!</Alert.Heading>
                <p className="info-exam">
                  Les examens sont prévus du <b> 17 Mai 2021 au 21 Mai 2021 </b>
                  inclu. Le calendrier et les convocations des examens sont déjà
                  mis à votre disposition
                </p>
                <hr />
                <p className="mb-0">
                  NB : les nouveaux bakelistes ne sont pas concernés par les
                  examens.
                  <b>
                    <br />
                    Les bakelistes qui ont des créances merci de vous rapprocher
                    de la comptabilité, au risque de ne pas faire les examens
                  </b>{" "}
                </p>
              </Alert>
            ) : (
              <div className="col-md-6 offset-5">
                <button
                  className="btn-theme text-up w-full "
                  onClick={() => this.setState({ show: true })}
                >
                  <FeatherIcon
                    icon="info"
                    className="icon-btn-livrable-bakeliste-item"
                  />
                  AFFICHER INFORMATION{" "}
                </button>{" "}
              </div>
            )} */}

            <div className="col-md-12 col-sm-8 pr-md-3 pr-sm-2 dis-flex">
              {/* <EnvoyerMonTravail /> */}
              <AddLivraisonsv2/>
            </div>
            <br />
            {/* <LivraisonsBakelistesView /> */}
            <LivraisonsItemv2View/>
          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              {/* <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <div className="item-coach-dashboard-bakeliste1 m-b-15">
                  <EnvoyerMonTravail nomEvent={this.state.eventeDroite} />
                </div>
              </div> */}
              <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <CoachItemDashboardBakeliste />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <BakelisteGroupItemRefact />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <ListCoachAssistantRefact />
              </div>
              {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                <NextReunionItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <SavedReunionListItem />
              </div> */}
              <div className="sendFixe">
                <EnvoyerMonTravail />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  
}
// const mapStateToprops =state=>{
//   return{
//    livraisons: state.livraisons.items,
//   loading:state.livraisons.loading,
//   error:state.livraisons.error,
//   }
// }

export default DashboardBakeliste;