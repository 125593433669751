import React, { Component } from "react";
import "./syllabusItemCoach.css";
import ImageSyllabus from "../../img/syllabus.jpg";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ShareTo from "../shareTo/index";
import axios from "axios";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import { Modal } from "rsuite";
import SweetAlert from "react-bootstrap-sweetalert";

export default class syllabusItemCoach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      syllabusID: this.props.syllabus_id,
      api: API.API,
      userID: window.sessionStorage.getItem("user_id"),
      syllabus_name: this.props.name,
      syllabus_level: this.props.niveau,
      syllabus_description: this.props.description,
      nombregroupes: this.props.groupes,
      fields: {},
      showAlertSuccess: false,
      showAlertError: false,
      showEditModal: false,
      size: "md",
      syllabus: {},
      showArchiveSuccess: false,
      created_by: this.props.created_by,
      syllabus_domaine_id: this.props.syllabus_domaine_id
    };
  }

  editDetailsHandleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["syllabus_id"] = this.state.syllabusID;
    fields["created_by"] = this.state.userID;
    if (e.target.name === "syllabus_name") {
      this.setState({
        syllabus_name: e.target.value,
      });
    }

    if (e.target.name === "syllabus_description") {
      this.setState({
        syllabus_description: e.target.value,
      });
    }
    if (e.target.name === "syllabus_level") {
      this.setState({
        syllabus_level: e.target.value,
      });
    }

    if (!fields["syllabus_name"]) {
      fields["syllabus_name"] = this.state.syllabus_name;
    }
    if (!fields["syllabus_description"]) {
      fields["syllabus_description"] = this.state.syllabus_description;
    }
    if (!fields["syllabus_level"]) {
      fields["syllabus_level"] = this.state.syllabus_level;
    }
    this.setState({
      fields,
    });
  };

  useEffect = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
  };

  onGetSyllabusById = (id) => {
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;
    axios
      .get(baseApiUrl + "syllabus/" + id)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          this.setState({
            syllabus: response.data.data,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
      });
  };

  showDetailsSyllabus = () => {
    let id = this.state.syllabusID;
    return (
      <Link
        to={{
          pathname: "/syllabus-details-coach",
          state: { id: this.state.syllabusID }, // your data array of objects
        }}
      ></Link>
    );

    // this.context.history.push({
    //   pathname: "/syllabus-details-coach",
    //   data: { id: this.state.syllabusID }, // your data array of objects
    // });
  };

  archiveSyllabus = () => {
    axios
      .post(this.state.api + "syllabus/archiver/" + this.state.syllabusID)
      .then((res) => {
        if (res.data.success) {
          this.props.ongetSyllabus();
          this.setState({ showArchiveSuccess: true });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  editSyllabusModal = () => {
    this.onGetSyllabusById(this.state.id);
    this.setState({
      showEditModal: true,
    });  
  };

  close = () => {
    this.setState({
      showEditModal: false,
    });
  };

  onSubmitEditDetailsSyllabus = (e) => {
    e.preventDefault();
    this.setState({
      editSyllabusInProgress: true,
    });
    // var data = this.state.syllabus; 
    var fd = new FormData();
    fd.append("syllabus_name", this.state.syllabus_name);
    fd.append("syllabus_level", this.state.syllabus_level);
    fd.append("syllabus_description", this.state.syllabus_description);
  //  fd.append("syllabus_id", this.state.syllabusID);
    fd.append("created_by", this.state.created_by);
    fd.append("syllabus_domaine_id", this.state.syllabus_domaine_id);
    
  
    // data["syllabus_name"] = this.state.syllabus_name;
    // data["syllabus_level"] = this.state.syllabus_level;
    // data["syllabus_description"] = this.state.syllabus_description;
    // data["id"] = this.state.syllabusID;
    // console.log("a envoyé" ,data)
    axios
      .put(this.state.api + "syllabus/modifier/" + this.state.syllabusID ,fd,{
        Headers: { "Content-Type": "multipart/formdata" },
      })
      .then((response) => {
        // console.log("start")
        // console.log("response", response)
        this.setState({
          editSyllabusInProgress: true,
        });
        if (response.data.success) {
          this.setState({
            showAlertSuccess: true,
            showEditModal: false,
          });
          this.props.ongetSyllabus();
        } else {
          this.setState({
            showAlertError: true,
            showEditModal: false,
          });
        }
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          editSyllabusContentInProgress: false,
          showAlertError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  render() {
    let id = this.props.syllabus_id;
    return (
      <div className="container-syllabus-item">
        <SweetAlert
          show={this.state.showArchiveSuccess}
          onConfirm={() => this.setState({ showArchiveSuccess: false })}
          success
          title="Syllabus archivé avec succes!!!"
          style={{
            color: "#069A77",
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            paddingTop: 60,
            paddingBottom: 40,
          }}
          confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
        />

        <SweetAlert
          show={this.state.showAlertError}
          onConfirm={() => this.setState({ showAlertError: false })}
          error
          title="Erreur sur l'ajout, veuillez reessayer!!!"
          style={{
            color: "#d9534f",
            fontSize: "12px",
            fontFamily: "Work Sans, sans-serif",
            paddingTop: 60,
            paddingBottom: 40,
          }}
        />
        <div className="syllabus-item">
          <p 
            className={
              "etiquette-syllabus-item " +
              (this.props.niveau === "débutant"
                ? " bg-debutant"
                : this.props.niveau === "intermédiaire"
                ? "bg-intermediaire"
                : this.props.niveau === "avancé"
                ? "bg-avance"
                : "")
            }
          >
            {this.props.niveau}
          </p>
          <img
            src={ImageSyllabus}
            alt="Avatar"
            className="image-syllabus-item"
            style={{ width: "100%" }}
          />
          <div className="content-syllabus-item">
            <p className="titre-content-syllabus-item">{this.props.name}</p>
            <div className="row">
              <div className="col-xl-4 col-lg-5 pr-lg-0 col-6 pr-0">
                <p className="info-content-syllabus-item">
                  <FeatherIcon
                    className="icon-content-syllabus-item"
                    icon="list"
                  />{" "}
                  {this.props.nbreTache} tâches
                </p>
              </div>
              {/*<div className="col-xl-4 pl-xl-0 col-6 pr-0">
                  <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="clock"/> 3 semaines</p>
                </div> */}
              <div className="col-xl-4 pl-xl-0 col-6 pr-0">
                <p className="info-content-syllabus-item">
                  <FeatherIcon
                    className="icon-content-syllabus-item"
                    icon="user"
                  />{" "}
                  {this.props.nbreBakeliste} bakelistes
                </p>
              </div>
              <div className="col-xl-4 pl-xl-0 col-6 pr-0">
                <p className="info-content-syllabus-item">
                  <FeatherIcon
                    className="icon-content-syllabus-item"
                    icon="user"
                  />{" "}
                  {this.props.groupes} sous-groupes
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-syllabus-item-back">
          <Link
            className="link-details-container-syllabus-item-back"
            // href={`/syllabus-details-coach/${this.props.syllabus_id}`}
            to={{
              pathname: "/syllabus-details-coach",
              state: { id: id },
            }}
          >
            <div className="height-container-syllabus-item-back">
              <p className="titre-container-syllabus-item-back">Description</p>
              <p className="content-container-syllabus-item-back">
                {this.props.description}
              </p>

              <p className="titre-container-syllabus-item-back mt-xl-5 mt-lg-2 mt-md-2 mt-2">
                Outils et Technologies
              </p>
              <p className="content-container-syllabus-item-back">
                {this.props.outils.map((item, index) => (
                  <span>{item.name}&nbsp;- </span>
                ))}
              </p>
              <p className="content-container-syllabus-item-back">
                {this.props.technologies.map((item, index) => (
                  <span key={index}>{item.name}&nbsp;</span>
                ))}
              </p>
            </div>
          </Link>
          <div className="">
            <div className="row justify-content-end position-action-container-syllabus-item-back">
              <div className="col-xl-4 col-lg-5 col-md-5 pl-lg-3 pl-md-0 col-sm-6 link-details-container-syllabus-item-back">
                <span onClick={() => this.editSyllabusModal()}>
                  <FeatherIcon
                    className="icon-details-container-syllabus-item-back"
                    icon="edit"
                    type="button"
                    aria-hidden="true"
                    // data-toggle="modal"
                    // data-target='#editDetailsSyllabusModal'
                  />
                  Modifier
                </span>
              </div>

              <div className="col-xl-4 col-lg-5 col-md-5 pl-lg-3 pl-md-0 col-sm-6 link-details-container-syllabus-item-back">
                <span onClick={() => this.archiveSyllabus()}>
                  <FeatherIcon
                    className="icon-details-container-syllabus-item-back"
                    icon="archive"
                    type="button"
                    aria-hidden="true"
                  />
                  Archiver
                </span>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-5 pl-lg-3 pl-md-0 col-sm-6">
                <Link
                  className="link-details-container-syllabus-item-back"
                  to={{
                    pathname: "/syllabus-details-coach",
                    state: { id: id },
                  }}
                >
                  <FeatherIcon
                    className="icon-details-container-syllabus-item-back"
                    icon="eye"
                  />
                  Details
                </Link>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-6 pl-lg-3 pl-md-0 col-sm-6">
                <ShareTo
                  id={this.state.syllabusID}
                  syllabusDetails={this.onGetSyllabusById}
                />
              </div>
              <Modal
                className="modal-responsive-md"
                size={this.state.size}
                show={this.state.showEditModal}
                onHide={this.close}
              >
                <form onSubmit={(e) => this.onSubmitEditDetailsSyllabus(e)}>
                  <Modal.Header>
                    <Modal.Title className="modal-contact-us">
                      Modifier Syllabus{" "}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="max-height-modal">
                    <div className="form-group as_input_container">
                    <label>Nom du syllabus</label>
                      <input
                        value={this.state.syllabus_name}
                        onChange={(e) =>
                          this.setState({ syllabus_name: e.target.value })
                        }
                        name="syllabus_name"
                        placeholder="Nom du syllabus"
                        className="form-control input_item"
                        id="syllabus_name"
                      />
                    </div>
                    <div className="form-group as_input_container">
                      <label>Description du syllabus</label>
                      <textarea
                        value={this.state.syllabus_description}
                        onChange={(e) =>
                          this.setState({ syllabus_description: e.target.value })
                        }
                        name="syllabus_description"
                        placeholder="Nom du syllabus"
                        className="form-control input_item"
                        id="syllabus_description"
                      />
                    </div>
                    <div className="form-group as_input_container">
                      <label> Niveau : {this.state.syllabus_level}</label>
                           <select
                            name="syllabus_level"
                            className="form-control input_item"
                            id="syllabus_level"
                             value={this.state.fields["domaine_id"]}
                             onChange={(e) =>
                              this.setState({ syllabus_level: e.target.value })
                            }
                          > 
                              <option  value="debutant"> Débutant  </option>
                              <option  value="intermediare"> Intermédiaire  </option>
                              <option  value="avance"> Avancé  </option>                           
                          </select>
                    </div>
                    {/* <div className="form-group as_input_container">
                      <label> Domaine </label>
                      <input
                        value={this.state.syllabus_name}
                        onChange={(e) =>
                          this.setState({ syllabus_name: e.target.value })
                        }
                        name="syllabus_name"
                        placeholder="Nom du syllabus"
                        className="form-control input_item"
                        id="syllabus_name"
                      />
                    </div>
                    <div className="form-group as_input_container">
                      <label> Outils  </label>
                      <input
                        value={this.state.syllabus_name}
                        onChange={(e) =>
                          this.setState({ syllabus_name: e.target.value })
                        }
                        name="syllabus_name"
                        placeholder="Nom du syllabus"
                        className="form-control input_item"
                        id="syllabus_name"
                      />
                    </div>
                    <div className="form-group as_input_container">
                      <label> Langages et frameworks </label>
                      <input
                        value={this.state.syllabus_name}
                        onChange={(e) =>
                          this.setState({ syllabus_name: e.target.value })
                        }
                        name="syllabus_name"
                        placeholder="Nom du syllabus"
                        className="form-control input_item"
                        id="syllabus_name"
                      />
                    </div> */}
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="row dis-flex justify-content-end">
                      <div className="col-md-5 col-sm-6">
                        {this.state.editSyllabusInProgress ? (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                            disabled
                          >
                            Modification en cours &nbsp;
                            <FeatherIcon icon="loader" aria-hidden="true" />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                          >
                            MODIFIER
                          </button>
                        )}
                      </div>
                    </div>
                  </Modal.Footer>
                </form>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ syllabusItem }) => ({ ...syllabusItem }),
//     dispatch => bindActionCreators({ ...syllabusItemActions }, dispatch)
//   )( syllabusItem );
