import React, { useEffect, useState } from 'react'
import "./bakelisteGroupItem.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import IconGroup from "../../img/icons/people-icon.png";
import axios from "axios";
import API from "../../variablesGlobales"; 
import * as Sentry from "@sentry/browser";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGroupeBakeliste } from "../LivraisonRefactor/redux/actions/groupesActions";

const BakelisteGroupItemRefact = () => { 
//   constructor(props) {
//     super(props);
//     this.state = {
//       groupe_bakeliste: [],
//       membres_groupe: [],
//       bakeliste_id: sessionStorage.getItem("user_id"),
//       groupe_id: sessionStorage.getItem("sous_groupe_id"),
//       api: API.API,
//     }; 
//     Sentry.init({
//       dsn:
//         "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
//     });
//   }

//   componentDidMount() {
//     this.onGetBakelisteGroupe();
//   }

//   componentDidCatch(err, info) {
//     Sentry.captureException(err);
//   }
//   onGetBakelisteGroupe = () => {
//     axios
//       .get(this.state.api + "sous__groupes/" + this.state.groupe_id)
//       .then((response) => {
//         if (response.data.data !== undefined) {
//           this.setState({
//             groupe_bakeliste: response.data.data,
//             membres_groupe: response.data.liste[0],
//           });
//         }
//       })
//       .catch((error) => {
//         if (process.env.NODE_ENV !== "production") {
//           return;
//         }
//         Sentry.captureException(error);
//       }); 
//   };


  const [api] = useState(API.API);
  const [groupeId, setGroupeId] = useState(
    sessionStorage.getItem("sous_groupe_id")
  );
  const [groupeDatas, setGroupeDatas] = useState([]);
  const groupeBakeliste = useSelector((state) => state.groupesReducer);
  const [groupe, setGroupe] = useState([]);
  const dispatch = useDispatch();
  const [nameGRoupe, setNameGroupe] = useState();
  const [myList, setMyList] = useState([]);



  useEffect(
    (err) => {
      dispatch(getGroupeBakeliste(groupeId));
      setGroupeDatas(groupeBakeliste);
      getGroupe(groupeBakeliste.items);
      setNameGroupe(groupeBakeliste.name);
    },
    [dispatch, groupeId]
  );
  const getGroupe = (groupeList) => {
    groupeList.map((item) => {
      setGroupe(item);
    });
     setMyList(groupe.slice(0, 5)) 

  };
  const indexNum = (cell, row, enumObject, index) => {
    return (<div>{index+1}</div>) 
}
const voirMembres = () => {
  window.location="/groupe-bakeliste"
};
    return (
      
// console.log("eder",myList),

    <div className="card-dashboard m-b-15">
        <NavLink to="/groupe-bakeliste">
          <p className="h-theme h-card-dashboard-bakeliste">
            <img
              className="icon-h-card-dashboard-bakeliste"
              src={IconGroup}
              alt="" 
            />{" "}
            Groupe {nameGRoupe}
          </p>
        </NavLink>
        {/* <BootstrapTable
          data={myList}
          version="4"
          bordered={false}
          bodyStyle={{
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.7)",
            fontFamily: "inherit",
            fontWeight: 400,
          }}
        >
          <TableHeaderColumn
            isKey
            dataField="id"
            dataFormat={indexNum}
            thStyle={{ border: "none" }}
            tdStyle={{
              border: "none",
              paddingLeft: "0",
              paddingRight: "0",
              width: "20px",
            }}
          ></TableHeaderColumn>
          <TableHeaderColumn
            dataField="first_name"
            thStyle={{ border: "none" }}
            tdStyle={{ border: "none" }}
          ></TableHeaderColumn>
          <TableHeaderColumn 
            dataField="last_name"
            thStyle={{ border: "none" }}
            tdStyle={{ border: "none" }}
          ></TableHeaderColumn>
        </BootstrapTable> */}
        <button className="btn btn-theme lgr" onClick={() => voirMembres()}>Voir membres</button>
      </div>
    );
  
}
export default BakelisteGroupItemRefact
