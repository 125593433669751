import React, { useEffect, useState } from "react";
import "./listCoachAssistant.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import IconGroup from "../../img/icons/people-icon.png";
import axios from "axios";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import { NavLink } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getListeCoachAssistant } from "../LivraisonRefactor/redux/actions/listeCoachAssitantActions";

const ListCoachAssistantRefact = ()  => {
//   constructor(props) {
//     super(props);
//     this.state = {
//       groupe_bakeliste: [],
//       membres_groupe: [],
//       bakeliste_id: sessionStorage.getItem("user_id"),
//       groupe_id: sessionStorage.getItem("sous_groupe_id"),
//       domaine_id: sessionStorage.getItem("domaine_id"),
//       api: API.API,
//       listCoachAssistant:[]
//     };   


//   onGetListCoachAssistant = () => {
//     axios
//       .get(this.state.api + "coach-assistants-by-domaine/" + this.state.domaine_id)
//       .then((response) => {
//         // console.log("listCoach", response);
//         if (response.data.success) {
//           this.setState({
//             listCoachAssistant: response.data.data,
//           });
//         }
//       })
//       .catch((error) => {
//         if (process.env.NODE_ENV !== "production") {
//           return;
//         }
//         Sentry.captureException(error);
//       });
//   };

const [api] = useState(API.API);
const [domaineId, setDomaineId] = useState(
  sessionStorage.getItem("domaine_id")
);
const [listCoach, setListCoach] = useState([]);
const listeCoachAssistant = useSelector((state) => state.listeCoachAssitantReducer);
const [listCA, setListCA] = useState([]);

const dispatch = useDispatch();

useEffect(
  () => {
    dispatch(getListeCoachAssistant(domaineId));
    setListCoach(listeCoachAssistant);
    setListCA(listeCoachAssistant.items);
  },
  [dispatch, domaineId]
);
    return (
// console.log("listecoachassistant",listCA),
      <div className="card-dashboard m-b-15">
        <NavLink to="/groupe-bakeliste">
          <p className="h-theme h-card-dashboard-bakeliste">
            <img
              className="icon-h-card-dashboard-bakeliste"
              src={IconGroup}
              alt=""
            />{" "}
            Liste des coachs assistants
          </p>
        </NavLink>
        {listCA.length === 0 ?(
              <p className="h-theme h-card-dashboard-bakeliste">
              Aucun coach assistant disponible
            </p>  
        ) : (

      
            <BootstrapTable
            data={listCA}
            version="4"
            bordered={false}
            bodyStyle={{
                fontSize: 12,
                color: "rgba(0, 0, 0, 0.7)",
                fontFamily: "inherit",
                fontWeight: 400,
            }}
            >
            
            <TableHeaderColumn
                isKey
                dataField="first_name"
                thStyle={{ border: "none" }}
                tdStyle={{
                border: "none",
                paddingLeft: "0",
                paddingRight: "0",
                width: "10%",
                }}

            ></TableHeaderColumn>
            <TableHeaderColumn
                dataField="last_name"
                thStyle={{ border: "none" }}
                tdStyle={{
                border: "none",
                paddingLeft: "0",
                paddingRight: "0",
                width: "10%",
                }}
            ></TableHeaderColumn>
                <TableHeaderColumn
                dataField="phone"
                thStyle={{ border: "none" }}
                tdStyle={{
                border: "none",
                paddingLeft: "0",
                paddingRight: "0",
                width: "10%",
                }}
            ></TableHeaderColumn>
            </BootstrapTable>
        )}
      </div>
    );
  
}
export default ListCoachAssistantRefact
