import { FETCH_DETAILS_CV_BAKELISTE } from "../actions/cvBakelisteActions";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function cvBakelisteReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DETAILS_CV_BAKELISTE:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      };

    default:
      return state;
  }
}
