import React, { Component } from "react";
import "./syllabusCoachItemTache2.css";
import { Dropdown, Icon, IconButton, Modal } from "rsuite";
import Loader from "react-loader-spinner";
import FeatherIcon from "feather-icons-react";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import axios from "axios";
import API from "../../variablesGlobales";
import base_api_url from "../../variablesGlobales";
import $ from "jquery";
import * as Sentry from "@sentry/browser";
import Preloader from "../preloader/index";

export default class syllabusCoachItemTache2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taches: this.props.Taches,
      id: this.props.id,
      api: API.API,
      base_api_url: base_api_url.base_api_url,
      sSkillValue: "",
      sTaskValue: "",
      sUrlValue: "",
      sDescriptionValue: "",
      sCompletionTimeValue: 0,
      sCompletionWeek: 0,
     
      showAlertError: false,
      showAlertSuccess: false,
      sWeekNumber: 0,
      tache_id: this.props.tache_id,
      nomsyllabus: this.props.syllabus_skills,
      tacheid: this.props.id_tache,
      comp: this.props.syllabus_skills,
      syllabus_skills: this.props.competence,
      syllabus_task: this.props.contenu,
      tache_url: this.props.lien,
      description: this.props.description,
      syllabus_completion_time: this.props.duree,
      syllabus_week_number: this.props.week,
      tache_file: this.props.jointe,
      valueThatChanges: this.props.contenu,
      userID: window.sessionStorage.getItem("user_id"),
      fields: {},
      uploadedfile: "",
      tacheToEdit: {},
      copyEnCours: false,
      syllabusDatas: [],
      selectOptions: [],
      selectedOption: null,
      selected: [],
      show: false,
      close: false,
      showErrorCopie: false,
      showSuccessCopie: false,
      showtachefile: false,
      loading: false,
      loader:true,
    };
    this.close = this.close.bind(this);

    this.editDetailsHandleChange = this.editDetailsHandleChange.bind(this);
  }

  componentDidMount = () => {
    this.onGetSyllabus();
    setTimeout(() => {
      this.setState({ 
        loader: false,
       });
    }, 4000);
  };


  close = () => {
    this.setState({
      show: false,
    });
  };
  closetachefile = () => {
    this.setState({
      showtachefile: false,
    });
  };
  showtachefile = () => {
    this.setState({
      showtachefile: true,
    });
  };

  closeModal = () => {
    this.setState({
      showEditModal: false,
    });
  };
  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };

  onGetSyllabus = () => {
    const _this = this;
    this.showLoader();
    // this.setState({
    //   isPageLoading: true,
    // });
    var baseApiUrl = this.state.api;
    axios
      .get(baseApiUrl + "syllabus-by-coach/" + this.state.userID)
      .then((response) => {
        // this.setState({
        //   isPageLoading: false,
        // });
        if (response.data.success) {
          this.setState({
            syllabusDatas: response.data.data.reverse(),
          });
        }
        this.state.syllabusDatas.forEach((item) => {
          if (item.id === parseInt(this.state.id)) {
            let infos = {
              label: item.syllabus_name,
              value: item.id,
              isDisabled: true,
            };
            this.state.selectOptions.push(infos);
          } else {
            let infos = {
              label: item.syllabus_name,
              value: item.id,
              isDisabled: false,
            };
            this.state.selectOptions.push(infos);
          }
        });

        _this.hideLoader();
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
      });
  };

  onSubmitEditDetailsSyllabus = (e) => {
    e.preventDefault();
    this.setState({
      editSyllabusContentInProgress: true,
    });
    var data = this.state.fields;
    //console.log(this.state.fields);
    var detailsID = this.props.tacheid;
    var fd = new FormData();
    fd.append("syllabus_id", this.state.id);
    fd.append("syllabus_skills", this.state.syllabus_skills);
    fd.append("created_by", this.state.userID);
    fd.append("syllabus_task", this.state.syllabus_task);
    fd.append("syllabus_completion_time", this.state.syllabus_completion_time);
    fd.append("syllabus_week_number", this.state.syllabus_week_number);
    fd.append("tache_file", this.state.uploadedfile);
    fd.append("tache_url", this.state.tache_url);
    fd.append("description", this.state.description);
    axios
      .put(this.state.api + "details-syllabus/" + detailsID, fd)
      .then((response) => {
        //console.log(response.data);

        if (response.data.success) {
          this.setState({
            showAlertSuccess: true,
            editSyllabusContentInProgress: false,
          });
          setTimeout(() => {
            this.setState({
              showAlertSuccess: false,
            });
          }, 1000);
          this.props.onGetTaches();
          this.closeModal();
        } else {
          this.setState({
            showAlertError: true,
          });
        }
      })
      .catch((error) => {
       // console.log(error.message);
        this.setState({
          editSyllabusContentInProgress: false,
          showAlertError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  editDetailsHandleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["syllabus_id"] = this.state.id;
    fields["created_by"] = this.state.userID;
    if (e.target.name === "syllabus_skills") {
      this.setState({
        syllabus_skills: e.target.value,
      });
    }
    if (e.target.name === "tache_url") {
      this.setState({
        tache_url: e.target.value,
      });
    }
    if (e.target.name === "description") {
      this.setState({
        description: e.target.value,
      });
    }
    if (e.target.name === "syllabus_task") {
      this.setState({
        syllabus_task: e.target.value,
      });
    }
    if (e.target.name === "syllabus_completion_time") {
      this.setState({
        syllabus_completion_time: e.target.value,
      });
    }
    if (e.target.name === "syllabus_week_number") {
      this.setState({
        syllabus_week_number: e.target.value,
      });
    }

    if (!fields["syllabus_skills"]) {
      fields["syllabus_skills"] = this.state.syllabus_skills;
    }
    if (!fields["syllabus_task"]) {
      fields["syllabus_task"] = this.state.syllabus_task;
    }
    if (!fields["tache_url"]) {
      fields["tache_url"] = this.state.tache_url;
    }
    if (!fields["description"]) {
      fields["description"] = this.state.description;
    }
    if (!fields["syllabus_completion_time"]) {
      fields["syllabus_completion_time"] = this.state.syllabus_completion_time;
    }
    if (!fields["syllabus_week_number"]) {
      fields["syllabus_week_number"] = this.state.syllabus_week_number;
    }

    this.setState({
      fields,
    });
  };

  handlefileChange = (e) => {
    let fields = this.state.fields;
    if (e.target.name === "tache_file") {
      if (this.fileValidation("tache_file")) {
        // fields["tache_file"] = e.target.files[0];
        this.setState({ uploadedfile: e.target.files[0] });
      } else {
        this.setState({
          isInvalidFileExtension: true,
        });
        setTimeout(() => {
          this.setState({
            isInvalidFileExtension: false,
          });
        }, 6000);
      }
    } else {
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields: fields,
    });
    //console.log(this.state.fields);
  };

  // handlefileChange = (e) => {
  //   let fields = this.state.fields;
  //   if (e.target.name === "tache_file") {
  //     if (this.fileValidation("tache_file")) {
  //       fields["tache_file"] = e.target.files[0];
  //     } else {
  //       this.setState({
  //         isInvalidFileExtension: true,
  //       });
  //       setTimeout(() => {
  //         this.setState({
  //           isInvalidFileExtension: false,
  //         });
  //       }, 6000);
  //     }
  //   } else {
  //     fields[e.target.name] = e.target.value;
  //   }

  //   this.setState({
  //     fields: fields,
  //   });
  //   console.log(this.state.fields);
  // };
  fileValidation = (elementID) => {
    var fileInput = document.getElementById(elementID);

    var filePath = fileInput.value;

    // Allowing file type
    var allowedExtensions = /(\.png|\.jpg|\.jpeg|)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  };

  handleMultiChange = (selectedOption) => {
    selectedOption.map((inc, id) => {
      this.setState({ selected: this.state.selected.concat(inc.value) });
      // idinc.push(inc.value);
    });
  };

  copyTaches = (e) => {
    e.preventDefault();
    this.setState({
      copyEnCours: true,
    });
    var fd = new FormData();
    const headers = {
      "Content-Type": "application/json",
      Authorization: "JWT fefege...",
    };
    this.state.selected.forEach((item) => {
      fd.append("tache_id", this.state.tache_id);
      fd.append("syllabus_id", this.state.id);
      fd.append("selected_syllabus", item);
      // }
      axios
        .post(this.state.api + "details-syllabus/dupliquer", fd)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              copyEnCours: false,
              showSuccessCopie: true,
              show: false,
            });
          } else {
            this.setState({
              copyEnCours: false,
              showErrorCopie: true,
            });
          }
        })
        .catch((error) => {
          setTimeout(() => {
            this.setState({
              copyEnCours: false,
              show: false,
              showErrorCopie: true,
            });
          }, 1000);
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    });
  };

  editTacheModal = (id) => {
    this.setState({
      showEditModal: true,
    });
  };

  openCopyModal = () => {
    this.setState({ show: true });
  };
  render() {
    return (
    //   this.state.loader ? (
    //     <div>
    //   <Preloader />
    //   </div>
    // )
    //  : ( 
      <div> 
   
        <div className="card-dashboard pos-relative mb-2 px-xl-5 px-lg-4 px-md-5 allTache">
        
          <div className="row">
            
            <div className="col-md-2 col-sm-2 pr-sm-0 mb-sm-0 mb-3">
              <p className="numero-tache-syllabus-item-tache">
                Tâches N° {this.props.id_tache}
              </p>
              <p className="duree-tache-syllabus-item-tache">
                Durée: {this.props.duree}h
              </p>
            </div>
            <div className="col-md-9 col-sm-9">
              {/* <p className="titre-syllabus-item-tache">
                {this.props.contenu}{" "}
                <button onClick={this.showtachefile}>
                  <FeatherIcon icon="image" aria-hidden="true" />
                </button>
              </p> */}
              {this.props.tache_file !== null && (
                <div classname="thumbnail">
                  <img
                    src={this.state.base_api_url + this.props.tache_file}
                    alt="fichier tache"
                    style={{ width: "100px" }}
                  />
                </div>
              )}

              <p className="contenu-syllabus-item-tache">
                {this.props.description}
              </p>
              <p className="titre-syllabus-item-tache">
                Liens utiles
                <a
                  className="lien-utile-bakeliste-tache-card"
                  href={this.props.lien}
                  target="blank"
                >
                  {this.props.lien}
                </a>
              </p>
              <p className="fs-12 m-t-30">
                <span className="flag">{this.props.syllabus_name}</span>
              </p>
            </div>
          </div>
          <Dropdown
            className="dropdown-syllabus-item-tache"
            renderTitle={() => {
              return (
                <IconButton
                  icon={
                    <Icon
                      icon="ellipsis-v"
                      className="icon-dropdown-syllabus-item-tache"
                    />
                  }
                  circle
                />
              );
            }}
            placement="bottomEnd"
          >
            <Dropdown.Item>
              <button onClick={() => this.editTacheModal()}>Modifier</button>
            </Dropdown.Item>
            <Dropdown.Item>
              <button onClick={this.openCopyModal}>Copier vers</button>
            </Dropdown.Item>
            {/* <Dropdown.Item>
                    Archiver
                  </Dropdown.Item> */}
          </Dropdown>
          {/* Edit Syllabus */}

          {/* Alert Livrable envoye avec succes */}

          {/* ENd ALert Succes */}

          {/* Alert Livrable non envoye */}
          <SweetAlert
            show={this.state.showAlertError}
            onConfirm={() => this.setState({ showAlertError: false })}
            error
            title="Erreur sur modification de la tâche, veuillez reessayer!!!"
            style={{
              color: "#d9534f",
              fontSize: "12px",
              fontFamily: "Work Sans, sans-serif",
              paddingTop: 60,
              paddingBottom: 40,
            }}
            confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
          />
          <SweetAlert
            show={this.state.showAlertSuccess}
            onConfirm={() => this.setState({ showAlertSuccess: false })}
            success
            title="tâche modifiee avec succes!!!"
            style={{
              color: "#069A77",
              fontSize: "12px",
              fontFamily: "Work Sans, sans-serif",
              paddingTop: 60,
              paddingBottom: 40,
            }}
            confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
          />
          <Modal
            className="modal-responsive-md"
            size={this.state.size}
            show={this.state.showEditModal}
            onHide={this.closeModal}
          >
            <form
              id="edit_syllabus_content_form"
              encType="multipart/form-data"
              onSubmit={(e) => this.onSubmitEditDetailsSyllabus(e)}
            >
              <Modal.Header>
                <Modal.Title className="modal-contact-us">
                  Modifier tache{" "}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="max-height-modal">
                <div className="modal-body">
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <input
                        value={this.state.syllabus_skills}
                        onChange={this.editDetailsHandleChange}
                        name="syllabus_skills"
                        placeholder="Compétences à acquérir"
                        className="form-control input_item"
                        id="syllabus_skills"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <textarea
                        value={this.state.syllabus_task}
                        onChange={this.editDetailsHandleChange}
                        name="syllabus_task"
                        placeholder="Contenu de la tâche"
                        className="form-control input_item"
                        id="syllabus_task"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <textarea
                        value={this.state.tache_url}
                        onChange={this.editDetailsHandleChange}
                        name="tache_url"
                        placeholder="lien associé"
                        className="form-control input_item"
                        id="tache_url"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      {/* <label htmlFor="description">Description</label> */}
                      <textarea
                        value={this.state.description}
                        onChange={this.editDetailsHandleChange}
                        name="description"
                        placeholder="Description"
                        className="form-control input_item"
                        id="description"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <input
                        type="number"
                        value={this.state.syllabus_completion_time}
                        onChange={this.editDetailsHandleChange}
                        name="syllabus_completion_time"
                        placeholder="Temps de réalisation en heure"
                        className="form-control input_item"
                        id="syllabus_completion_time"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <input
                        type="number"
                        value={this.state.syllabus_week_number}
                        onChange={this.editDetailsHandleChange}
                        name="syllabus_week_number"
                        placeholder="Numéro de la semaine"
                        className="form-control input_item"
                        id="syllabus_week_number"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="syllabus_file_jointe">
                        Ajouter une pièce jointe
                      </label>
                      <input
                        type="file"
                        onChange={(e) => this.handlefileChange(e)}
                        name="tache_file"
                        placeholder="Pièce jointe"
                        className="form-control input_item"
                        id="tache_file"
                      />
                    </div>
                  </div>
                  {this.state.showAlertError && (
                    <div className="row assignation-error">
                      <div className="col-md-12 error-message-alert alert alert-danger">
                        Un problème est survenu lors de la modification.
                        Veuillez réessayer plus tard.
                      </div>
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="row dis-flex justify-content-end">
                  <div className="col-md-5 col-sm-6">
                    {this.state.editSyllabusContentInProgress ? (
                      <button
                        type="submit"
                        className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                        disabled
                      >
                        Modification en cours &nbsp;
                        <FeatherIcon icon="loader" aria-hidden="true" />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                      >
                        MODIFIER
                      </button>
                    )}
                  </div>
                </div>
              </Modal.Footer>
            </form>
          </Modal>

          <Modal
            className="modal-responsive-sm"
            show={this.state.show}
            onHide={this.close}
          >
            <h5
              className="modal-bakeliste-title m-b-50"
              id="modalAddTacheTitle"
            >
              Copier vers
            </h5>
            <button
              type="button"
              className="close-modal-bakeliste-title"
              onClick={this.close}
            >
              <FeatherIcon icon="x" aria-hidden="true" />
            </button>

            {/* Assign syllabus to bakeliste */}

            <form
              id="copy_syllabus_content_form"
              onSubmit={(e) => this.copyTaches(e)}
            >
              <div className="modal-body">
                <div className="col-md-12 form_col">
                  <div className="form-group as_input_container">
                    <Select
                      isMulti
                      name="syllabus_ids"
                      options={this.state.selectOptions}
                      className="basic-multi-select"
                      onChange={this.handleMultiChange}
                      classNamePrefix="select"
                    />
                  </div>
                </div>

                {this.state.showAlertError && (
                  <div className="row assignation-error">
                    <div className="col-md-12 error-message-alert alert alert-danger">
                      Un problème est survenu lors de la copie. Veuillez
                      réessayer plus tard.
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                {this.state.copyEnCours ? (
                  <button
                    type="submit"
                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                    disabled
                  >
                    Copie en cours &nbsp;
                    <FeatherIcon icon="loader" aria-hidden="true" />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                  >
                    Copier
                  </button>
                )}
              </div>
            </form>
          </Modal>
          <SweetAlert
            show={this.state.showSuccessCopie}
            onConfirm={() => this.setState({ showSuccessCopie: false })}
            success
            title="tâche copiée avec succes!!!"
            style={{
              color: "#069A77",
              fontSize: "12px",
              fontFamily: "Work Sans, sans-serif",
              paddingTop: 60,
              paddingBottom: 40,
            }}
            confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
          />
          {/* ENd ALert Succes */}

          {/* Alert Livrable non envoye */}
          <SweetAlert
            show={this.state.showErrorCopie}
            onConfirm={() => this.setState({ showErrorCopie: false })}
            error
            title="Erreur sur copie de la tâche, veuillez reessayer!!!"
            style={{
              color: "#d9534f",
              fontSize: "12px",
              fontFamily: "Work Sans, sans-serif",
              paddingTop: 60,
              paddingBottom: 40,
            }}
            confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
          />

          {/* Show image Tache */}
          <Modal
            className="modal-responsive-sm"
            show={this.state.showtachefile}
            onHide={this.closetachefile}
          >
            <h5
              className="modal-bakeliste-title m-b-50"
              id="modalShowTacheFile"
            >
              Image de la tache
            </h5>
            <button
              type="button"
              className="close-modal-bakeliste-title"
              onClick={this.closetachefile}
            >
              <FeatherIcon icon="x" aria-hidden="true" />
            </button>

            {/* Assign syllabus to bakeliste */}

            <div className="modal-body">
              <img
                src={this.state.base_api_url + this.props.tache_file}
                alt="fichier tache"
              />
            </div>
          </Modal>
        </div>
      
            
      </div>
   
   // ) 
    );
  }
}
// export default connect(
//     ({ syllabusItemTache }) => ({ ...syllabusItemTache }),
//     dispatch => bindActionCreators({ ...syllabusItemTacheActions }, dispatch)
//   )( syllabusItemTache );
