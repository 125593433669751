import React, { Component } from "react";
import "./cvBakeliste.css";

import { Link } from "react-router-dom";
import API from "../../variablesGlobales";
import Waagan from "../../img/wagaan_head.png";
import FbImageLibrary from "react-fb-image-grid";
import axios from "axios";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as cvBakelistesActions from "../../store/cvBakelistes/actions";
export default class cvBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      cvthequeID: 0,
      bakelisteID: 0,
      cv: [],
      isCvthequeEmpty: false,
      isProjectEmpty: false,
      isEducationEmpty: false,
      isPortfolioEmpty: false,
      isTecnologyEmpty: false,
      isLanguageEmpty: false,
      isEmptyCV: false,
      cvinfos: [],
      cvprojects: [],
      cveducation: [],
      cvportfolio: [],
      cvtechnology: [],
      cvlanguague: [],
      validationInProgress: false,
      isValidationCVSuccessAlert: false,
      isValidationCVRequestError: false,
    };
  }

  componentDidMount() {
    const btns = document.querySelectorAll(".accordion__btn");
    const answers = document.querySelectorAll(".accordion__answer__container");
    const icons = document.querySelectorAll(".fa");

    for (let i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        for (let j = 0; j < btns.length; j++) {
          if (j !== i) {
            answers[j].classList.remove("active__accordion");
            icons[j].classList.add("fa-plus");
            icons[j].classList.remove("fa-minus");
          }
        }
        answers[i].classList.toggle("active__accordion");
        icons[i].classList.toggle("fa-plus");
        icons[i].classList.toggle("fa-minus");
      });
    }
    this.getBakelisteCV(
      window.sessionStorage.getItem("bakelisteIDForShowingCV")
    );
  }

  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === "successValidation") {
        this.setState({
          isValidationCVSuccessAlert: false,
        });
      }
      if (label === "errorValidation") {
        this.setState({
          isValidationCVRequestError: false,
        });
      }
    }, 5000);
  }

  getBakelisteCV = () => {
    axios
      // .get(this.state.api + "cvtheques/" + this.props.bakeliste_id)
      .get(this.state.api + "cvtheques/" + this.props.id)

      .then((response) => {
        if (response.data.success === true) {
          console.log("ok", response.data.cvthequeData);
          //var data = this.state.cv;
          //var data = [];
          if (response.data.cvthequeData.length === 0) {
            this.setState({
              isCvthequeEmpty: true,
            });
          } else {
            this.setState({
              cvinfos: response.data.cvthequeData,
              cvMotivationsValue: response.data.cvthequeData.cv_motivations,
            });
          }
          // console.log(response.data.cvthequeData)
          if (response.data.cvprojectsData.length === 0) {
            this.setState({
              isProjectEmpty: true,
            });
          } else {
            this.setState({
              cvprojects: response.data.cvprojectsData,
            });
            // console.log(this.state.cvprojects)
          }
          if (response.data.cveducationData.length === 0) {
            this.setState({
              isEducationEmpty: true,
            });
          } else {
            this.setState({
              cveducation: response.data.cveducationData,
            });
            //console.log(response.data.cveducationData)
          }
          if (response.data.cvportfolioData.length === 0) {
            this.setState({
              isPortfolioEmpty: true,
            });
          } else {
            this.setState({
              cvportfolio: response.data.cvportfolioData,
            });
            // console.log(response.data.cvportfolioData)
          }
          if (response.data.cvtechnologiesData.length === 0) {
            this.setState({
              isTecnologyEmpty: true,
            });
          } else {
            this.setState({
              cvtechnology: response.data.cvtechnologiesData,
            });

            //console.log(response.data.cvtechnologiesData)
          }
          if (response.data.cvlanguagesData.length === 0) {
            this.setState({
              isLanguageEmpty: true,
            });
          } else {
            this.setState({
              cvlanguague: response.data.cvlanguagesData,
            });
          }
        } else {
          this.setState({
            isEmptyCV: true,
            isCvthequeEmpty: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * Cv validation method
   */
  onValidateCV = (cvID) => {
    this.setState({
      validationInProgress: true,
    });

    // console.log(cvID)
    axios
      .post(this.state.api + "cv-bakeliste-done-validation/" + cvID)
      .then((response) => {
        if (response.data.success === true) {
          this.getBakelisteCV(
            window.sessionStorage.getItem("bakelisteIDForShowingCV")
          );
          this.setState({
            validationInProgress: false,
            isValidationCVSuccessAlert: true,
          });
          this.handleShowAndHideAlert("successValidation");
        } else {
          this.setState({
            validationInProgress: false,
            isValidationCVRequestError: true,
          });
          // console.log(response)
          this.handleShowAndHideAlert("errorValidation");
        }
      })
      .catch((error) => {
        this.setState({
          validationInProgress: false,
          isValidationCVRequestError: true,
        });
        this.handleShowAndHideAlert("errorValidation");
      });
  };
  render() {
    const files = [];

    this.state.cvportfolio.map((item) => {
      files.push(item.img_path);
    });
    return (
      <div className="component-my-participants">
        <div className="container-fluid px-0">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard-admin" className="lien_nav">
                  Dashboard{" "}
                </Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                {" "}
                <Link to="/bakelistes-db" className="lien_nav">
                  Liste bakelistes
                </Link>
              </li>
              <li className="breadcrumb-item">Cv bakeliste </li>
            </ol>
          </nav>
        </div>
        <div className="row info-container m-3">
          <div className="col-lg-12 contain-card ">
            <div>
              <img src={Waagan} alt="Ellipse" className="image-profil" />
            </div>
            <div>
              <span className="nom-profil">
                {this.props.first_name} {this.props.last_name}
              </span>
              {/* {this.state.cvinfos.is_done === 1 ? (
                <span className="label label-success"> CV validé </span>
              ) : (
                <span className="label label-danger"> CV non validé </span>
              )} */}
            </div>
            {/* <div>
              <span className="contain-profil">
                {this.state.cvinfos.bakelisteNickname}
              </span>
            </div> */}
          </div>
        </div>
        {this.state.isCvthequeEmpty ? (
          <div className="row info-container m-3">
            <div className="col-lg-12 contain-card ">
              <h5 className="p-2">Ce bakeliste n'a pas encore rempli son cv</h5>
            </div>
          </div>
        ) : (
          <div className="row info-container m-3 p-3">
            <div class="accordion__container">
              <div class="accordion__question__container">
                <h2 class="titre-info">Education</h2>
                <button class="accordion__btn">
                  <i class="fa fa-plus"></i>{" "}
                </button>
              </div>
              <div class="accordion__answer__container">
                <p class="accordion__answer">
                  <div className="panel-body">
                    {this.state.isEducationEmpty === true && (
                      <div className="empty_container">
                        <div className="empty_education alert alert-warning">
                          Education non renseignée.
                        </div>
                      </div>
                    )}
                    {/* // Render education s'il n'est pas vide */}
                    {this.state.isEducationEmpty === false && (
                      <div className="list_educations_row">
                        {this.state.cveducation.map((educ, i) => (
                          <div className="col-md-12 education_item_container">
                            <p>
                              {" "}
                              <span className="diplome_label">
                                {" "}
                                {educ.diplome_label},{" "}
                              </span>
                              <span className="school_container">
                                {" "}
                                {educ.school} -{" "}
                              </span>
                              <i className="diplome_container">
                                {" "}
                                {educ.diplome}{" "}
                              </i>{" "}
                              <br />
                            </p>
                            <span className="ville_pays">
                              {" "}
                              {educ.ville} - {educ.pays}{" "}
                            </span>
                            <hr />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </p>
              </div>
            </div>

            <div class="accordion__container">
              <div class="accordion__question__container">
                <h2 class="titre-info">Experience</h2>
                <button class="accordion__btn">
                  <i class="fa fa-plus"></i>{" "}
                </button>
              </div>
              <div class="accordion__answer__container">
                <p class="accordion__answer">
                  {this.state.isProjectEmpty === false && (
                    <div className="list_projects_row">
                      {this.state.cvprojects.map((item, indexe) => (
                        <div
                          key={indexe}
                          className="list_projects_container col-md-12"
                        >
                          <h5 className="project_name_label">
                            {item.project_name}{" "}
                          </h5>
                          <h6 className="project_technologies_label">
                            {" "}
                            {item.project_technologies}{" "}
                          </h6>
                          {item.taches.length === 0 ? (
                            <div>
                              <i>Tâches non renseignées.</i>
                            </div>
                          ) : (
                            <div className="list_taches_container">
                              <ul>
                                {item.taches.map((tache, ind) => (
                                  <li className="tache_item">
                                    {" "}
                                    {tache.tache_content}{" "}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                          <hr />
                        </div>
                      ))}
                    </div>
                  )}
                </p>
              </div>
            </div>

            <div class="accordion__container">
              <div class="accordion__question__container">
                <h2 class="titre-info">
                  {" "}
                  {this.state.cvinfos.bakelisteDomaineName ===
                  "Programmation" ? (
                    <span>Technologies et Langues</span>
                  ) : (
                    <span>Outils et Langues</span>
                  )}
                </h2>
                <button class="accordion__btn">
                  <i class="fa fa-plus"></i>{" "}
                </button>
              </div>
              <div class="accordion__answer__container">
                <p class="accordion__answer">
                  <div className="panel-body">
                    <div className="techno_lang_row row">
                      <div className="col-md-6 techno_container">
                        <h6 className=" techno_lang_title">
                          {this.state.cvinfos.bakelisteDomaineName ===
                          "Programmation" ? (
                            <span>Technologies</span>
                          ) : (
                            <span>Outils</span>
                          )}
                        </h6>
                        {this.state.isTecnologyEmpty === true && (
                          <div className="empty_techno_row">
                            <div className="col-md-12 error-alert-container alert alert-warning">
                              Technologies non renseignées.
                            </div>
                          </div>
                        )}
                        {this.state.isTecnologyEmpty === false && (
                          <div className="techno_content_row row">
                            <div className="techno_item_container">
                              <div className="col-md-12 techno_item">
                                <ul className="list_techno_ul">
                                  {this.state.cvtechnology.map(
                                    (techno, idx) => (
                                      <li key={idx} className="techno_li">
                                        {" "}
                                        {techno.techno_name}{" "}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>{" "}
                            <hr />
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 lang_container">
                        <h6 className=" techno_lang_title">Langues</h6>
                        {this.state.isLanguageEmpty === true && (
                          // Render technology si les données sont nulles
                          <div className="empty_lang_row">
                            <div className="col-md-12 error-alert-container alert alert-warning">
                              Langues non renseignées
                            </div>
                          </div>
                        )}
                        {this.state.isLanguageEmpty === false && (
                          <div className="lang_content_row row">
                            <div className="lang_item_container">
                              <div className="col-md-12 lang_item">
                                <ul className="list_lang_ul">
                                  {this.state.cvlanguague.map((lang, ix) => (
                                    <li key={ix} className="lang_li">
                                      {" "}
                                      {lang.language_name} ({" "}
                                      {lang.languague_label} ){" "}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </p>
              </div>
            </div>

            <div class="accordion__container">
              <div class="accordion__question__container">
                <h2 class="titre-info">Motivations</h2>

                <button class="accordion__btn">
                  <i class="fa fa-plus"></i>{" "}
                </button>
              </div>
              <div class="accordion__answer__container">
                <p class="accordion__answer">
                  <div className="panel-body">
                    <div className="cv_motivations_container">
                      {this.state.cvinfos.cv_motivations === null && (
                        <div className="empty_motivations_row row">
                          <div className="col-md-12 error-alert-container alert alert-warning">
                            La motivation n'est pas encore renseignée.
                          </div>
                        </div>
                      )}

                      {this.state.cvinfos.cv_motivations !== null && (
                        <div className="motivations_content_row row">
                          <div className="motivations_item_container">
                            <div className="col-md-12 motivations_item">
                              <p class="accordion__answer">
                                {this.state.cvinfos.cv_motivations}
                              </p>
                            </div>
                          </div>{" "}
                          <hr />
                        </div>
                      )}
                    </div>
                  </div>
                </p>
              </div>
            </div>
            <div class="accordion__container">
              <div class="accordion__question__container">
                <h2 class="titre-info">Portfolio</h2>

                <button class="accordion__btn">
                  <i class="fa fa-plus"></i>{" "}
                </button>
              </div>
              <div class="accordion__answer__container">
                <p class="accordion__answer">
                  <div className="panel-body">
                    {this.state.isPortfolioEmpty === true && (
                      <div className="empty_container">
                        <div className="empty_portfolio alert alert-warning">
                          Le Portfolio est vide.
                        </div>
                      </div>
                    )}
                    {this.state.isEducationEmpty === false && (
                      <div className="list_portfolio_row row">
                        <FbImageLibrary images={files} />
                      </div>
                    )}
                  </div>
                </p>
              </div>
            </div>
          </div>
        )}
        {/* </div> */}

        {/* <div className="row info-container m-3 p-3">
            <div className="col-xl-5 col-lg-5 col-md-4">
              <div className="titre-info">
                <h2 className="title-cv">Education </h2>
              </div>
              <div className="form-info">
                <div className="form-group row form-info">
                  <label className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-form-label label-texte">
                    Diplome :
                  </label>
                  <label className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-form-label form-control-plaintext input-texte">
                    {this.state.cveducation.diplome}
                  </label>
                  <div className="col-xl-6 col-lg-6 col-md-8">
                    <input
                      type="text"
                      readonly
                      className="form-control-plaintext input-texte"
                      id="staticEmail"
                      value={this}
                    />
                  </div>
                </div>{" "}
                <div className="form-group row form-info">
                  <label
                    for="staticEmail"
                    className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-form-label label-texte"
                  >
                    Diplome :
                  </label>
                  <div className="col-xl-6 col-lg-6 col-md-8">
                    <input
                      type="text"
                      readonly
                      className="form-control-plaintext input-texte"
                      id="staticEmail"
                      value="Ngoné Diop"
                    />
                  </div>
                </div>
                <div className="form-group row form-info">
                  <label
                    for="inputPassword"
                    className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-form-label label-texte"
                  >
                    Annee Scolaire :
                  </label>
                  <div className="col-xl-6 col-lg-6 col-md-8">
                    <input
                      type="text"
                      readonly
                      className="form-control-plaintext input-texte"
                      id="staticEmail"
                      value="15/04/99"
                    />
                  </div>
                </div>
                <div className="form-group row form-info">
                  <label
                    for="inputPassword"
                    className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-form-label label-texte"
                  >
                    Ecole :{" "}
                  </label>
                  <div className="col-xl-6 col-lg-6 col-md-8">
                    <input
                      type="text"
                      readonly
                      className="form-control-plaintext input-texte"
                      id="staticEmail"
                      value="+221 800 00 00"
                    />
                  </div>
                </div>
                <div className="form-group row form-info">
                  <label
                    for="inputPassword"
                    className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-form-label label-texte"
                  >
                    Ville :
                  </label>
                  <div className="col-xl-6 col-lg-6 col-md-8">
                    <input
                      type="text"
                      readonly
                      className="form-control-plaintext input-texte"
                      id="staticEmail"
                      value="Keur Massar"
                    />
                  </div>
                </div>
                <div className="form-group row form-info">
                  <label
                    for="inputPassword"
                    className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-form-label label-texte"
                  >
                    Adresse mail :
                  </label>
                  <div className="col-xl-6 col-lg-6 col-md-8">
                    <input
                      type="text"
                      readonly
                      className="form-control-plaintext input-texte"
                      id="staticEmail"
                      value="diopngone34@gmail.com"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="titre-info">
                <h2 className="title-cv">Formations </h2>
                <div>
                  <form>
                    <div className="form-group row form-info">
                      <label
                        for="staticEmail"
                        className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-form-label label-texte"
                      >
                        Statut
                      </label>
                      <div className="col-xl-6 col-lg-6 col-md-8">
                        <input
                          type="text"
                          readonly
                          className="form-control-plaintext input-texte"
                          id="staticEmail"
                          value="Profetionnel"
                        />
                      </div>
                    </div>
                    <div className="form-group row form-info">
                      <label
                        for="inputPassword"
                        className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-form-label label-texte"
                      >
                        Certifications
                      </label>
                      <div className="col-xl-6 col-lg-6 col-md-8">
                        <input
                          type="text"
                          readonly
                          className="form-control-plaintext input-texte"
                          id="staticEmail"
                          value="Licence informatique"
                          value=" DTS en Design"
                        />
                      </div>
                    </div>
                    <div className="form-group row form-info">
                      <label
                        for="inputPassword"
                        className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-form-label label-texte"
                      >
                        école/ Université :{" "}
                      </label>
                      <div className="col-xl-6 col-lg-6 col-md-8">
                        <input
                          type="text"
                          readonly
                          className="form-control-plaintext input-texte"
                          id="staticEmail"
                          value="UVS Dakar Bakeli"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4">
              <div className="titre-info">
                <h2 className="title-cv">Expérience </h2>
                <div className="texte-info">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row info-container m-3 p-3">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="titre-info">
                <h2 className="title-cv">Motivations </h2>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6">
            <h6 className="h-theme ff-inter fw-600 px-3">
              Dossiesr de candidature
            </h6>
          </div>

          <section className="content">
            <div className="validation-btn-container">
              {this.state.cvinfos.is_done === 1 ? (
                // <button className="btn btn-success volkeno-green-bcolor validation-btn">
                //   <i className="fas fa-check-double"></i>
                // </button>
                ""
              ) : !this.state.validationInProgress ? (
                <button
                  className="btn btn-success volkeno-orange-bcolor validation-btn"
                  onClick={(e) =>
                    window.confirm(
                      "Êtes-vous sûr de vouloir valider le CV ?"
                    ) && this.onValidateCV(this.state.cvinfos.id)
                  }
                >
                  <i className="fas fa-clipboard-check"></i>
                </button>
              ) : (
                <button className="btn btn-success volkeno-orange-bcolor validation-btn">
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>

            <div className="col-md-12 bakeliste_cv_content">
              {!this.state.isCvthequeEmpty && ( // test si cvtheque est à false
                <div className="row cv_row">
                  <div className="col-md-12 cvprojects">
                    <div className="cv_name">
                      <h2 className="cv_name_label">
                        {this.state.cvinfos.cv_name} &nbsp;
                        {this.state.cvinfos.is_done === 1 ? (
                          <span className="label label-success">
                            {" "}
                            CV validé{" "}
                          </span>
                        ) : (
                          <span className="label label-danger">
                            {" "}
                            CV non validé{" "}
                          </span>
                        )}
                      </h2>
                    </div>
                    <hr />

                    <div
                      className="panel-group"
                      id="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <div className="panel panel-default">
                        <div
                          className="panel-heading volkeno-green-bcolor"
                          role="tab"
                          id="headingOne"
                        >
                          <h4 className="panel-title">
                            <a
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseProjet"
                              aria-expanded="true"
                              aria-controls="collapseProjet"
                            >
                              Projets
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseProjet"
                          className="panel-collapse collapse in"
                          role="tabpanel"
                          aria-labelledby="headingOne"
                        >
                          <div className="panel-body">
                            <div className="projects_container">
                              {this.state.isProjectEmpty === true && (
                                <div className="empty_container">
                                  <div className="empty_projects alert alert-warning">
                                    Aucun projet renseigné.
                                  </div>
                                </div>
                              )}

                              {this.state.isProjectEmpty === false && (
                                <div className="list_projects_row">
                                  {this.state.cvprojects.map((item, indexe) => (
                                    <div
                                      key={indexe}
                                      className="list_projects_container col-md-12"
                                    >
                                      <h5 className="project_name_label">
                                        Nom du projet : {item.project_name}{" "}
                                      </h5>
                                      <h6 className="project_technologies_label">
                                        {" "}
                                        {item.project_technologies}{" "}
                                      </h6>
                                      {item.taches.length === 0 ? (
                                        <div>
                                          <i>Tâches non renseignées.</i>
                                        </div>
                                      ) : (
                                        <div className="list_taches_container">
                                          <ul>
                                            {item.taches.map((tache, ind) => (
                                              <li className="tache_item">
                                                {" "}
                                                {tache.tache_content}{" "}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      )}
                                      <hr />
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading volkeno-green-bcolor"
                          role="tab"
                          id="headingTwo"
                        >
                          <h4 className="panel-title">
                            <a
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseEducation"
                              aria-expanded="true"
                              aria-controls="collapseEducation"
                            >
                              Education
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseEducation"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingTwo"
                        >
                          <div className="panel-body">
                            {this.state.isEducationEmpty === true && (
                              <div className="empty_container">
                                <div className="empty_education alert alert-warning">
                                  Education non renseignée.
                                </div>
                              </div>
                            )}
                            {this.state.isEducationEmpty === false && (
                              <div className="list_educations_row">
                                {this.state.cveducation.map((educ, i) => (
                                  <div className="col-md-12 education_item_container">
                                    <span className="diplome_label">
                                      {" "}
                                      {educ.diplome_label},{" "}
                                    </span>
                                    <span className="school_container">
                                      {" "}
                                      {educ.school} -{" "}
                                    </span>
                                    <i className="diplome_container">
                                      {" "}
                                      {educ.diplome}{" "}
                                    </i>{" "}
                                    <br />
                                    <span className="ville_pays">
                                      {" "}
                                      {educ.ville} - {educ.pays}{" "}
                                    </span>
                                    <hr />
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading volkeno-green-bcolor"
                          role="tab"
                          id="headingThree"
                        >
                          <h4 className="panel-title">
                            <a
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapsePortfolio"
                              aria-expanded="true"
                              aria-controls="collapsePortfolio"
                            >
                              Portfolio
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapsePortfolio"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingThree"
                        >
                          <div className="panel-body">
                            {this.state.isPortfolioEmpty === true && (
                              <div className="empty_container">
                                <div className="empty_portfolio alert alert-warning">
                                  Le Portfolio est vide.
                                </div>
                              </div>
                            )}
                            {this.state.isEducationEmpty === false && (
                              <div className="list_portfolio_row row">
                                {this.state.cvportfolio.map((port, ind) => (
                                  <div
                                    key={ind}
                                    className="portfolio_item_container"
                                  >
                                    <div className="col-md-4 portfolio_item_container">
                                      <div className="panel panel-warning">
                                        <div className="panel-heading volkeno-orange-bcolor">
                                          {" "}
                                          {port.projectName}{" "}
                                        </div>
                                        <div className="panel-body">
                                          <img
                                            src={port.img_path}
                                            alt={port.projectName}
                                            className="img_responsive portfolio_img"
                                          />
                                        </div>
                                        <div
                                          className="panel-footer"
                                          data-toggle="modal"
                                          data-target="#showPrtfolioItemModal"
                                          onClick={(e) =>
                                            this.getPortfolioInfos(port)
                                          }
                                        >
                                          voir cv
                                          <i className="fas fa-eye fa-2x"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div
                          className="panel-heading volkeno-green-bcolor"
                          role="tab"
                          id="headingFour"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseMotivation"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Motivations
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseMotivation"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingTwo"
                        >
                          <div className="panel-body">
                            <div className="cv_motivations_container">
                              {this.state.cvinfos.cv_motivations === null && (
                                <div className="empty_motivations_row row">
                                  <div className="col-md-12 error-alert-container alert alert-warning">
                                    La motivation n'est pas encore renseignée.
                                  </div>
                                </div>
                              )}

                              {this.state.cvinfos.cv_motivations !== null && (
                                <div className="motivations_content_row row">
                                  <div className="motivations_item_container">
                                    <div className="col-md-12 motivations_item">
                                      <p>{this.state.cvinfos.cv_motivations}</p>
                                    </div>
                                  </div>{" "}
                                  <hr />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      // Render Technologies et Languagues panel
                      <div className="panel panel-default">
                        <div
                          className="panel-heading volkeno-green-bcolor"
                          role="tab"
                          id="headingFive"
                        >
                          <h4 className="panel-title">
                            <a
                              className="collapsed"
                              role="button"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseTechnoLang"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              {this.state.cvinfos.bakelisteDomaineName ===
                              "Programmation" ? (
                                <span>Technologies et Langues</span>
                              ) : (
                                <span>Outils et Langues</span>
                              )}
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseTechnoLang"
                          className="panel-collapse collapse"
                          role="tabpanel"
                          aria-labelledby="headingFive"
                        >
                          <div className="panel-body">
                            <div className="techno_lang_row row">
                              <div className="col-md-6 techno_container">
                                <h6 className="text-center techno_lang_title">
                                  {this.state.cvinfos.bakelisteDomaineName ===
                                  "Programmation" ? (
                                    <span>Technologies</span>
                                  ) : (
                                    <span>Outils</span>
                                  )}
                                </h6>
                                {this.state.isTecnologyEmpty === true && (
                                  <div className="empty_techno_row">
                                    <div className="col-md-12 error-alert-container alert alert-warning">
                                      Technologies non renseignées.
                                    </div>
                                  </div>
                                )}
                                {this.state.isTecnologyEmpty === false && (
                                  <div className="techno_content_row row">
                                    <div className="techno_item_container">
                                      <div className="col-md-12 techno_item">
                                        <ul className="list_techno_ul">
                                          {this.state.cvtechnology.map(
                                            (techno, idx) => (
                                              <li
                                                key={idx}
                                                className="techno_li"
                                              >
                                                {" "}
                                                {techno.techno_name}{" "}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>{" "}
                                    <hr />
                                  </div>
                                )}
                              </div>

                              <div className="col-md-6 lang_container">
                                <h6 className="text-center techno_lang_title">
                                  Langues
                                </h6>
                                {this.state.isLanguageEmpty === true && (
                                  // Render technology si les données sont nulles
                                  <div className="empty_lang_row">
                                    <div className="col-md-12 error-alert-container alert alert-warning">
                                      Langues non renseignées
                                    </div>
                                  </div>
                                )}
                                Render lang si le champ n'est pas vide
                                {this.state.isLanguageEmpty === false && (
                                  <div className="lang_content_row row">
                                    <div className="lang_item_container">
                                      <div className="col-md-12 lang_item">
                                        <ul className="list_lang_ul">
                                          {this.state.cvlanguague.map(
                                            (lang, ix) => (
                                              <li key={ix} className="lang_li">
                                                {" "}
                                                {lang.language_name} ({" "}
                                                {lang.languague_label} ){" "}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="panel-footer"
                data-toggle="modal"
                data-target="#showPrtfolioItemModal"
                // onClick={(e) => this.getPortfolioInfos(port)}
              >
                voir cv
                <i className="fas fa-eye fa-2x"></i>
              </div>
              <div
                className="modal fade"
                id="showPrtfolioItemModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="showPrtfolioItemModalLabel"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h4 className="modal-title" id="myModalLabel">
                        {" "}
                        {this.state.portProjectName}{" "}
                      </h4>
                    </div>
                    <div className="modal-body">
                      <img
                        src={this.state.imgPathValue}
                        alt=""
                        className="img-responsive image_item"
                      />
                    </div>
                    <div className="modal-footer">
                      //{" "}
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        */}
      </div>
    );
  }
}
// export default connect(
//     ({ cvBakelistes }) => ({ ...cvBakelistes }),
//     dispatch => bindActionCreators({ ...cvBakelistesActions }, dispatch)
//   )( cvBakelistes );
