import React, { Component } from "react";
import "./groupeBakelisteView.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import GroupeBakeliste from "../groupeBakeliste/index";
import Loader from "react-loader-spinner";
import Preloader from "../preloader/index";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
 
export default class groupeBakelisteView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  } 

  componentDidMount() {
  
   setTimeout(() => {
      this.setState({ loading: false });
    }, 4000);
  }
 
  render() {
    const { loading } = this.state;

    return (
      <div className="body-theme">
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVertical />
            </div>
         
            <div className="col-lg-10 pt-3">
              {this.state.loading ? (
                <div>
              <Preloader />
              </div>
            ):
            <>
              <GroupeBakeliste />
            </> }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ groupeBakelisteView }) => ({ ...groupeBakelisteView }),
//     dispatch => bindActionCreators({ ...groupeBakelisteViewActions }, dispatch)
//   )( groupeBakelisteView );
