import React, { Component, useState } from "react";

import ListeBakelisteForExamen from "../listeBakelisteForExamen/index";
import NoteExamen from "../noteExamen/index";
import "./noteExamenView.css";
import { Link } from "react-router-dom";

import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";
export default class noteExamenView extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }

  render() {
    return (
    <div className="body-theme">
      <NavVerticalCoach />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <HeaderDashboardCoach />
          </div>

          <div className="col-lg-7 p-0">
            <div className="row">
              <div className="col-lg-9 pt-3">
                <div className="row">
                  <div className="col-lg-11 pt-3">
                    <nav aria-label="breadcrumb fixed">
                            <ol className="breadcrumb fixed">
                                <li className="breadcrumb-item"><Link to="/home" className="lien_nav">Accueil</Link> </li>
                                <li className="breadcrumb-item active lien_nav_current" aria-current="page">Notes </li>
                            </ol>
                        </nav>
                          
                  </div>
                </div>

              </div>
            </div>
            <NoteExamen />

          </div>

          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteGroupItemForCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
