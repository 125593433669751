import React ,{useState,useEffect} from 'react';
import LivraisonsItemv2 from '../livraisonsItemv2/livraisonsItemv2';
import './livraisonsItemv2View.css'
import axios from 'axios';
import { connect ,useDispatch} from 'react-redux';
import { fetchLivraisons } from '../redux/actions/livraisonsActions';
import AddLivraisonsv2 from '../addLivraisonsv2/addLivraisonsv2';
import API2 from '../../../variablesGlobales'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AmplitudeScript from '../../../amplitudeScript';
import amplitude from 'amplitude-js'
import SkeletonLivraisonv2 from '../skeletonLivraisonv2/skeletonLivraisonv2';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as livraisonsItemv2ViewActions from "../../store/livraisonsItemv2View/actions";
const LivraisonsItemv2View = (props)=> {
  const userFullName = window.sessionStorage.getItem("userFullName")
  const user_id = window.sessionStorage.getItem("user_id")
  const [loading, setLoading]= useState(false)
  const [livraisons, setLivraisonsSyllabus]=useState(props.livraisons)

  const dispatch = useDispatch();
  useEffect(() => { fetchLivraisons(dispatch);
    setTimeout(
      () => setLoading(true), 
      3000
    );
 }, [dispatch])

    const addCommentOnLivrable = (fields, item) => {
      const livraison = item.livraisons;
      const eventProperties = {
        user_id: user_id,
        name: userFullName,
      };
      axios
        .post(API2.API2 + "add/livraison_comments", fields)
        .then((response) => {
          amplitude
            .getInstance()
            .logEvent(
            "COMMENTAIRE_LIVRAISON_Success_v2_Redux",
                eventProperties
            );
          const data = response.data.data;
           data.fullname = userFullName;
          item.comments.push(data);
          const livraisonSyllabus = [...livraisons];
          const index = livraisonSyllabus.findIndex((it) => it.id === item.id);
          livraisonSyllabus[index].livraisons = livraison;
          setLivraisonsSyllabus(livraisonSyllabus)
        })
        .catch((error) => {
          console.log(error)
          amplitude
          .getInstance()
          .logEvent(
          "COMMENTAIRE_LIVRAISON_Error_v2_Redux",
              eventProperties
          );
        });
    };
      return (
<>
{
  loading===false?
<SkeletonLivraisonv2/>
:
props.livraisons.map((item, index) => (
       <LivraisonsItemv2
       key={item.id}
       livraisons={item}
       fichiers = {item.fichiers.reverse()}
       comments={item.comments}
       livraison_id={item.id}
        onSubmitComment={addCommentOnLivrable}
       item={item}
       index={index + 1}
       bakeliste_id={item.bakeliste_user_id}
       fullname={item.bakeliste.user_fullname}
       tache_content={item.details_syllabus.task}
       date={item.updated_at}
     />
    ))}
</>
      )
    
  }
  

  const mapStateToprops =state=>{
    return{
     livraisons: state.livraisons.items,
    loading:state.livraisons.loading,
    error:state.livraisons.error,
    }
  }
  
  export default connect(mapStateToprops,{fetchLivraisons})(LivraisonsItemv2View)
// export default connect(
//     ({ livraisonsItemv2View }) => ({ ...livraisonsItemv2View }),
//     dispatch => bindActionCreators({ ...livraisonsItemv2ViewActions }, dispatch)
//   )( livraisonsItemv2View );