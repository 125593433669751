import React, { Component } from "react";

import { Link } from "react-router-dom";

import BakelisteGroupeItemForCoachAssistant from "../bakelisteGroupeItemForCoachAssistant/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify"; 

import API from "../../variablesGlobales";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoachAssistant from "../navVerticalCoachAssistant/index";
import "./ajoutExamenPratiqueCoachAssistant.css";
toast.configure();

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentAjoutExamPratiqueActions from "../../store/contentAjoutExamPratique/actions";
export default class ajoutExamenPratiqueCoachAssistant extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      titre: "",
      duree: "",
      date: "",
      enonce: "",
      niveau: "",
      session: "",
      duree: "",
      taf: "",
      module_name: "",
      selectOptions: [],
      domaine_id: 0,
      created_by: window.sessionStorage.getItem("user_id"),
      is_archived: false,
      is_available: false,
      api: API.API,
    };
   // console.log(window.sessionStorage.getItem("id"));
  }
  componentDidMount() {
    this.getOptions();
  }

  async getOptions() {
    const response = await axios.get(this.state.api + "domaines");
    this.setState({ selectOptions: response.data });

   // console.log(response.data.data);
    const options = response.data.data.map((d) => ({
      value: d.id,
      label: d.name,
    }));
    // console.log(options);
    this.setState({ selectOptions: options });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange = (e) => {
    this.setState({ domaine_id: e.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      titre,
      duree,
      date,
      enonce,
      niveau,
      session,
      domaine_id,
      taf,
    } = this.state;
    const is_archived = 0;
    const is_available = 0;
    const created_by = this.state.created_by;
   // console.log(created_by)
    // console.log(
    //   titre,
    //   duree,
    //   date,
    //   enonce,
    //   niveau,
    //   session,
    //   duree,
    //   domaine_id,
    //   taf
    // );
    axios
      .post(this.state.api + "examen_pratiques", {
        is_archived,
        is_available,
        created_by,
        titre,
        duree,
        date,
        enonce,
        niveau,
        session,
        duree,
        domaine_id,
        taf,
      })
      .then((result) => {
        this.setState({
          isLoading: false,
        });
    //    console.log(result);
        toast.success(
          "Examen ajouté avec success",
          { position: toast.POSITION.TOP_CENTER },
          { autoClose: 2000 }
        );

       window.location = "/examens-coach-assistant";
      })
      .catch((err) => {
        toast.erreur(
          "Erreur ajout examen",
          { position: toast.POSITION.TOP_CENTER },
          { autoClose: 2000 }
        );
        this.setState({
          isLoading: false,
        });
        console.warn(err);
      });
  };
  render() {
    const {
      titre,
      duree,
      date,
      enonce,
      niveau,
      session,
      domaine_id,
      taf,
    } = this.state;
    // console.log(this.state.selectOptions)
    return ( 
      <div className="body-theme">
        <NavVerticalCoachAssistant />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <HeaderDashboardCoach />
            </div>

            <div className="col-lg-7 p-0">
              <div className="row">
                <div className="col-lg-9 pt-3">
                  <div className="row">
                    <div className="col-lg-11 pt-3">
                      <nav aria-label="breadcrumb fixed">
                        <ol className="breadcrumb fixed">
                          <li className="breadcrumb-item ">
                            <Link to="/dashboard-coach-assistant">Dashboard</Link>
                          </li>
                          <li className="breadcrumb-item " aria-current="page">
                            <Link to="/examens-coach-assistant">Examens</Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Ajout Examen Pratique
                          </li>
                        </ol>
                      </nav>

                      <div className="mto-3">
                        <form
                          encType="multipart/form-data"
                          id="livrable_form"
                          onSubmit={this.onSubmit}
                        >
                          <div className="row">
                            <div className="col-sm-12 offset-md-2 pb-3">
                              <input
                                type="text"
                                className="input-add-quiz"
                                placeholder="Nom de l’examen"
                                name="titre"
                                value={titre}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12 offset-md-2 pb-3">
                              <textarea
                                className="input-add-quiz"
                                placeholder="Enonce de l'examen"
                                name="enonce"
                                value={enonce}
                                onChange={this.onChange}
                              />
                            </div>
                            <div className="col-sm-12 offset-md-2 pb-3">
                              <textarea
                                className="input-add-quiz"
                                placeholder="Travail a faire"
                                name="taf"
                                value={taf}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12 offset-md-2 pb-3">
                              <input
                                type="date"
                                className="input-add-quiz"
                                placeholder="Date"
                                name="date"
                                value={date}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 offset-md-2 pb-3">
                              {/* <input
                                type="text"
                                className="input-add-quiz"
                                placeholder="Niveau"
                                name="niveau"
                                value={niveau}
                                onChange={this.onChange}
                              /> */}
                              <select
                                onChange={this.onChange}
                                name="niveau"
                                className="input-add-quiz"
                              >
                                <option> --Choisir un niveau-- </option>
                                <option value="débutant">Débutant</option>
                                <option value="avancé">Avancé</option>
                                <option value="intermédiaire">
                                  Intermédiaire
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 offset-md-2 pb-3">
                              <input
                                type="text"
                                className="input-add-quiz"
                                placeholder="Session"
                                name="session"
                                value={session}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 offset-md-2 pb-3">
                              <input
                                type="text"
                                className="input-add-quiz"
                                placeholder="duree"
                                name="duree"
                                value={duree}
                                onChange={this.onChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 offset-md-2 pb-3">
                              <Select
                                 className="input-add-quiz"
                                options={this.state.selectOptions}
                                onChange={(e) => this.handleChange(e)}
                              />
                            </div>
                          </div>
                          <div className="row d-flex justify-content-end pt-4 pb-5 pr-3">
                            <button type="submit" className="btn btn-theme">
                              Creer Examen
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div></div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteGroupeItemForCoachAssistant />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
