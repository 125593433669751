import React, { useState } from "react";
import './dashboardCAViewR.css'
import HeaderDashboardCoach from "../../headerDashboardCoach/index";
import Loader from "react-loader-spinner";
import NavVerticalCoachAssistant from "../../navVerticalCoachAssistant/index";
import DashboardCAR from "../dashboardCAR/dashboardCAR";

const DashboardCAViewR = ()=>
{
  // const [loading,setLoading] = useState(true)



    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoachAssistant />
            </div>

            <div className="col-lg-10 pt-3">
              <DashboardCAR />
              {/* </>}  */}
            </div>
          </div>
        </div>
      </div>
    );
  
}
export default DashboardCAViewR
// export default connect(
//     ({ dashboardCoachView }) => ({ ...dashboardCoachView }),
//     dispatch => bindActionCreators({ ...dashboardCoachViewActions }, dispatch)
//   )( dashboardCoachView );
