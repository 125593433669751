import React, { Component } from "react";
import "./livraisonExamenCoachView.css";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";
import LivraisonExamenCoach from "../livraisonExamenCoach/index";
import Preloader from "../preloader/index";

export default class livraisonExamenCoachView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 5000);
  }
  render() {
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoach />
            </div>
            <div className="col-lg-10 pt-3">
              {this.state.loading ? (
                <div className="col-md-3 offset-md-5">
                  <Preloader />
                </div>
              ) : (
                <>
                  <LivraisonExamenCoach />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ livrableCoachView }) => ({ ...livrab leCoachView }),
//     dispatch => bindActionCreators({ ...livrableCoachViewActions }, dispatch)
//   )( livrableCoachView );
