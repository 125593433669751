import React, { Component } from "react";
import "./ajoutTacheSyllabus.css";
import { Link, Redirect } from "react-router-dom";
// import IconGroupWhatsapp from '../../img/icons/group-whatsapp.png';
// import TableGroupeBakeliste from '../tableGroupeBakeliste/index';
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import ProgramReunionCoach from "../programReunionCoach/index";

import SavedReunionListItem from "../savedReunionListItem/index";

import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";

//import $ from "jquery";

import API from "../../variablesGlobales";

import SweetAlert from "react-bootstrap-sweetalert";

import NavVerticalCoach from "../navVerticalCoach/index";

export default class ajoutTacheSyllabus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      syllabusID: this.props.location.state.id,
      api: API.API,

      showAlertSuccess: false,
      showAlertError: false,
      isPageLoading: true,
      userID: window.sessionStorage.getItem("user_id"),
      syllabusDatas: [],
      syllabusInfos: [],
      addSyllabusContentInProgress: false,
      fields: {},
      errors: {},
      addSuccessDetailsAlert: false,
      addErrorDetailsAlert: false,
      sskills_empty: false,
      stask_empty: false,
      surl_empty: false,
      sdescription_empty: false,
      spiece_empty: false,
      scompletiontime_empty: false,
      sweeknumber_empty: false,
      dsInfos: [],
      upInfos: [],
      sSkillValue: "",
      sTaskValue: "",
      sUrlValue: "",
      sDescriptionValue: "",
      sFileJointeValue: "",
      sCompletionTimeValue: 0,
      sCompletionWeek: 0.0,
      sWeekNumber: 0,
      editSyllabusContentInProgress: false,
      editSuccessDetailsAlert: false,
      editErrorDetailsAlert: false,
      _fields: {},
      uploadedfile: "",
      selectOptions: [],
      selected: [],
    };
    this.onSubmitDetailsSyllabus = this.onSubmitDetailsSyllabus.bind(this);
    //console.log(this.state.syllabusID, "tra la la ")
  }

  componentDidMount = () => {
    this.addDetailsHandleChange = this.addDetailsHandleChange.bind(this);
    this.onGetDetailsBySyllabus(this.state.syllabusID);
  };

  addDetailsHandleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["syllabus_id"] = this.state.syllabusID;
    fields["created_by"] = this.state.userID;

    if (e.target.name === "syllabus_completion_time") {
      var sct = e.target.value;
      var week = (sct / 45).toFixed(1);
      fields["syllabus_completion_week"] = week;
    }

    this.setState({
      fields: fields,
    });
    //console.log(fields);
  };
  onGetDetailsBySyllabus = (syllabus_id) => {
    this.setState({
      isPageLoading: true,
    });
    axios
      .get(this.state.api + "details-by-syllabus/" + syllabus_id)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });

        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            syllabusDatas: response.data.data,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  
  onSwitchAlertStatus = (label) => {
    setTimeout(() => {
      if (label === "addSyllabusContentInProgress") {
        this.setState({
          addSyllabusContentInProgress: false,
        });
      }
      if (label === "addSuccessDetailsAlert") {
        this.setState({
          addSuccessDetailsAlert: false,
        });
      }
      if (label === "addErrorDetailsAlert") {
        this.setState({
          addErrorDetailsAlert: false,
        });
      }
      if (label === "sskills_empty") {
        this.setState({
          sskills_empty: false,
        });
      }
      if (label === "stask_empty") {
        this.setState({
          stask_empty: false,
        });
      }
      if (label === "surl_empty") {
        this.setState({
          surl_empty: false,
        });
      }
      if (label === "sdescription_empty") {
        this.setState({
          sdescription_empty: false,
        });
      }
      if (label === "spiece_empty") {
        this.setState({
          spiece_empty: false,
        });
      }
      if (label === "scompletiontime_empty") {
        this.setState({
          scompletiontime_empty: false,
        });
      }
      if (label === "sweeknumber_empty") {
        this.setState({
          sweeknumber_empty: false,
        });
      }
    }, 5000);
  };

  onValidateFormData = () => {
    let fields = this.state.fields;
    let errors = {};
    var isValidForm = true;
    if (!fields["syllabus_task"]) {
      isValidForm = false;
      this.setState({
        stask_empty: true,
      });
      this.onSwitchAlertStatus("stask_empty");
      errors["stask_empty"] = "Le titre de la tâche est obligatoire";
    }
    this.setState({
      errors: errors,
    });
    return isValidForm;
  };

  onSubmitDetailsSyllabus = (e) => {
    e.preventDefault();

    if (this.onValidateFormData()) {
      this.setState({
        addSyllabusContentInProgress: true,
      });
      var data = this.state.fields;
      var id = this.state.syllabusID;
      // console.log(this.state.uploadedfile)
      // console.log(data, "vos données");
      var fd = new FormData();
      fd.append("tache_file", this.state.uploadedfile);
      fd.append("syllabus_id", data["syllabus_id"]);
      fd.append("syllabus_skills", data["syllabus_skills"]);
      fd.append("created_by", data["created_by"]);
      fd.append("syllabus_task", data["syllabus_task"]);
      fd.append("syllabus_completion_time", data["syllabus_completion_time"]);
      fd.append("syllabus_week_number", data["syllabus_week_number"]);
      fd.append("tache_url", data["tache_url"]);
      fd.append("description", data["description"]);
      var baseApiUrl = this.state.api;
      //console.log(data)
      // console.log(fd)
      axios
        .post(baseApiUrl + "details-syllabus", fd)
        .then((response) => {
          this.setState({
            addSyllabusContentInProgress: true,
          });
          if (response.data.success) {
            this.onGetDetailsBySyllabus(this.state.syllabusID);
            this.setState({
              showAlertSuccess: true,
            });
            window.location = `/syllabus-details-coach?id=${this.state.syllabusID}`;
          } else {
            this.setState({
              showAlertError: true,
            });
          }
        })
        .catch((error) => {
          // console.log(error.message);
          this.setState({
            addSyllabusContentInProgress: false,
            addErrorDetailsAlert: true,
          });
          this.onSwitchAlertStatus("addErrorDetailsAlert");
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    }
  };

  handlefileChange = (e) => {
    let fields = this.state.fields;
    if (e.target.name === "tache_file") {
      if (this.fileValidation("tache_file")) {
        // fields["tache_file"] = e.target.files[0];
        this.setState({ uploadedfile: e.target.files[0] });
      } else {
        this.setState({
          isInvalidFileExtension: true,
        });
        setTimeout(() => {
          this.setState({
            isInvalidFileExtension: false,
          });
        }, 6000);
      }
    } else {
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields: fields,
    });
    // console.log(this.state.fields);
  };

  fileValidation = (elementID) => {
    var fileInput = document.getElementById(elementID);

    var filePath = fileInput.value;
    var allowedExtensions = /(\.png|\.jpg|\.jpeg|)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  };

  render() {
    let id = this.state.syllabusID;
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoach />
            </div>
            <div className="col-lg-10 pt-3">
              <div className="p-b-200">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item ">
                      <Link to="/coach-dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item " aria-current="page">
                      <Link to="/coach-syllabus">Syllabus</Link>
                    </li>
                    {/* <li className="breadcrumb-item " aria-current="page">
                    <Link
                      to={{
                        pathname: "/syllabus-details-coach",
                        state: { id: id },
                      }}
                    >Syllabus {this.props.name}
                    </Link>
                    </li> */}

                    <li className="breadcrumb-item active" aria-current="page">
                      Ajout tâche
                    </li>
                  </ol>
                </nav>
                <div className="row">
                  <div className="col-lg-9">
                    <div className="row m-b-15">
                      <div className="col m-b-15">
                        <h6 className="h-theme ff-inter fw-600">
                          Ajouter une tâche dans le syllabus
                        </h6>
                      </div>
                      <div className="col dis-flex m-b-15"></div>
                    </div>

                    <div className="card-dashboard p-md-5 p-sm-5 m-b-20">
                      <SweetAlert
                        show={this.state.showAlertSuccess}
                        onConfirm={() =>
                          this.setState({ showAlertSuccess: false })
                        }
                        success
                        title="Tâche ajouté avec succes!!!"
                        style={{
                          color: "#069A77",
                          fontSize: "12px",
                          fontFamily: "Work Sans, sans-serif",
                          paddingTop: 60,
                          paddingBottom: 40,
                        }}
                        confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                      />
                      <SweetAlert
                        show={this.state.showAlertError}
                        onConfirm={() =>
                          this.setState({ showAlertError: false })
                        }
                        error
                        title="Erreur sur l'ajout d'une nouvelle tâche, veuillez reessayer!!!"
                        style={{
                          color: "#d9534f",
                          fontSize: "12px",
                          fontFamily: "Work Sans, sans-serif",
                          paddingTop: 60,
                          paddingBottom: 40,
                        }}
                        confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                      />
                      <form
                        encType="multipart/form-data"
                        id="add_syllabus_content_form"
                        onSubmit={(e) => this.onSubmitDetailsSyllabus(e)}
                      >
                        <label>
                          Les champs précédés d'une étoile sont obligatoires
                        </label>

                        <div className="form_col">
                          {this.state.stask_empty && (
                            <div className="errorMsg">
                              {this.state.errors.stask_empty}
                            </div>
                          )}
                          <div className="form-group as_input_container">
                            <label style={{ color: "red" }}>*</label>
                            <input
                              name="syllabus_task"
                              placeholder="* Titre de la tâche *"
                              className="form-control input_item"
                              onChange={this.addDetailsHandleChange}
                              id="syllabus_task"
                            />
                          </div>
                        </div>
                        <div className=" form_col">
                          <div className="form-group as_input_container">
                            <textarea
                              name="description"
                              placeholder="Ajouter une description"
                              className="form-control input_item"
                              onChange={this.addDetailsHandleChange}
                              id="description"
                            />
                          </div>
                          {this.state.sdescription_empty && (
                            <div className="errorMsg">
                              {this.state.errors.sdescription_empty}
                            </div>
                          )}
                        </div>
                        <div className=" form_col">
                          <div className="form-group as_input_container">
                            <label htmlFor="tache_file">
                              Ajouter une pièce jointe
                            </label>
                            <input
                              type="file"
                              onChange={(e) => this.handlefileChange(e)}
                              name="tache_file"
                              placeholder="Pièce jointe"
                              className="form-control input_item"
                              id="tache_file"
                            />
                          </div>
                        </div>
                        <div className="form_col">
                          <div className="form-group as_input_container">
                            <input
                              name="tache_url"
                              placeholder="Ajouter un lien"
                              className="form-control input_item"
                              onChange={this.addDetailsHandleChange}
                              id="tache_url"
                            />
                          </div>
                          {this.state.surl_empty && (
                            <div className="errorMsg">
                              {this.state.errors.surl_empty}
                            </div>
                          )}
                        </div>
                        <div className="form_col">
                          <div className="form-group as_input_container">
                            <input
                              type="number"
                              name="syllabus_completion_time"
                              placeholder="Temps de réalisation en heure"
                              className="form-control input_item"
                              onChange={this.addDetailsHandleChange}
                              id="syllabus_completion_time"
                            />
                          </div>

                          {this.state.scompletiontime_empty && (
                            <div className="errorMsg">
                              {this.state.errors.scompletiontime_empty}
                            </div>
                          )}
                        </div>
                        <div className="cform_col">
                          <div className="form-group as_input_container">
                            <input
                              type="number"
                              name="syllabus_week_number"
                              placeholder="Numéro de la semaine"
                              className="form-control input_item"
                              onChange={this.addDetailsHandleChange}
                              id="syllabus_week_number"
                            />
                          </div>
                          <div className="form-group as_input_container">
                            <input
                              name="syllabus_skills"
                              placeholder="Compétences"
                              className="form-control input_item"
                              onChange={this.addDetailsHandleChange}
                              id="syllabus_skills"
                            />
                            {this.state.sskills_empty && (
                              <div className="errorMsg">
                                {this.state.errors.sskills_empty}
                              </div>
                            )}
                          </div>
                          {this.state.sweeknumber_empty && (
                            <div className="errorMsg">
                              {this.state.errors.sweeknumber_empty}
                            </div>
                          )}
                        </div>
                        {this.state.addSyllabusContentInProgress ? (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                            disabled
                          >
                            Ajout en cours &nbsp;
                            <FeatherIcon icon="loader" aria-hidden="true" />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                          >
                            AJOUTER
                          </button>
                        )}
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <BakelisteGroupItemForCoach />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                        <ProgramReunionCoach />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <SavedReunionListItem />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ groupeCoach }) => ({ ...groupeCoach }),
//     dispatch => bindActionCreators({ ...groupeCoachActions }, dispatch)
//   )( groupeCoach );
