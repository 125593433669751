// import { get } from "lodash";
import axios from "axios";
import API2 from "../../../../variablesGlobales";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const FETCH_LIVRAISONS_BEGIN = "FETCH_LIVRAISONS_BEGIN";
export const FETCH_LIVRAISONS_SUCCESS = "FETCH_LIVRAISONS_SUCCESS";
export const FETCH_LIVRAISONS_FAILURE = "FETCH_LIVRAISONS_FAILURE";
export const ADD_LIVRAISONS_BEGIN = "ADD_LIVRAISONS_BEGIN";
export const ADD_LIVRAISONS_SUCCESS = "ADD_LIVRAISONS_SUCCESS";
export const ADD_LIVRAISONS_FAILURE = "ADD_LIVRAISONS_FAILURE";

// Fetch Livraison Actionc

export const fetchLivraisonsBegin = () => ({
  type: FETCH_LIVRAISONS_BEGIN,
});

export const fetchLivraisonsSuccess = (livraisons) => ({
  type: FETCH_LIVRAISONS_SUCCESS,
  payload: { livraisons },
});

export const fetchLivraisonsFailure = (error) => ({
  type: FETCH_LIVRAISONS_FAILURE,
  payload: { error },
});

// add Livraison Actions
export const addLivraisonsBegin = () => ({
  type: ADD_LIVRAISONS_BEGIN,
});

export const addLivraisonsSuccess = (livraison) => ({
  type: ADD_LIVRAISONS_SUCCESS,
  payload: { livraison },
});

export const addLivraisonsFailure = (error) => ({
  type: ADD_LIVRAISONS_FAILURE,
  payload: { error },
});



export function fetchLivraisons() {
       return function(dispatch) {
        dispatch(fetchLivraisonsBegin());
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        };
        axios.get(API2.API2+'get_all_livraisons',headers)
        // .then((res) => res.json())
        .then((data) => {
          // console.log('liv data',data.data.livraisons);
               dispatch(fetchLivraisonsSuccess(data.data.livraisons))
            })

        .catch(error =>
          dispatch(fetchLivraisonsFailure(error))
        );
    };
  
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

  export function addLivraisons(formdata) {
    return function(dispatch) {
     dispatch(addLivraisonsBegin());
    //  console.log('form',formdata);
     axios
        .post(API2.API2 + "livraisons", formdata, {
          Headers: { "Content-Type": "multipart/formdata" },
        })
        .then((response) => {
          // console.log('success',response.data.success)
          dispatch(addLivraisonsSuccess(response))
          toast('SUCCES!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            })
            dispatch(fetchLivraisons());

          }).catch((error) =>{
            dispatch(addLivraisonsFailure(error))
            toast.error('Erreur!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              })
            }
          );
      
   

 };
}
