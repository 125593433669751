import React, { Component } from "react";
import "./headerDashboard.css";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import Logo from "../../img/logo.png";
import { Avatar, Dropdown, Modal } from "rsuite";
//import Pointage from "../pointage/index";
import API from "../../variablesGlobales";
import axios from "axios";
import "../pointage/pointage.css";
import SweetAlert from "react-bootstrap-sweetalert";
import $ from "jquery";
import Dropzone from "react-dropzone-uploader";
import * as Sentry from "@sentry/browser";
import amplitude from "amplitude-js";
import base_api_url from "../../variablesGlobales";

import EnvoyerMonTravail from "../envoyerMonTravail/index";
import AddLivraisonsv2 from "../LivraisonRefactor/addLivraisonsv2/addLivraisonsv2";

export default class headerDashboard extends Component {
  constructor(props) {
    super(props);
    var retrievedObject = localStorage.getItem("user"); 

    this.state = {
      api: API.API,
      token: window.sessionStorage.getItem("token"),
      userStatus: window.sessionStorage.getItem("user_status"),
      userID: window.sessionStorage.getItem("user_id"),
      userName: window.sessionStorage.getItem("userFullName"),
      email: sessionStorage.getItem("email"),
      isAlreadyPoint: false,
      pointInProgress: false,
      isSuccessPointMe: false,
      bakeliste_id: window.sessionStorage.getItem("user_id"),
      phone: window.sessionStorage.getItem("user_phone"),
      isErrorPointAlert: false,
      isPoint: false,
      show: false,

      fields: {},
      isSendingLivrableInProgress: false,
      images: [],
      phone_syntax_error: false,
      files: [],
      isValidForm: false,
      table: [],
      pointage: [],
      tasksyllabusencours: [],
      tasktodo: [],
      taskdoing: [],
      pointDeparture: false,
      sg_id: sessionStorage.getItem("sous_groupe_id"),
      showAlertSuccess: false,
      showAlertError: false,
      // user: JSON.parse(retrievedObject),
      avatar_file: window.sessionStorage.getItem("avatar_file"),

      base_api_url: base_api_url.base_api_url,
    };
    this.handlePointChange = this.handlePointChange.bind(this);
    this.submitPointerArriving = this.submitPointerArriving.bind(this);
  }

  componentDidMount() {
    this.onCheckBakelisteDailyPointage();
  }

  doLogout = () => {
    window.sessionStorage.removeItem("token", "user_id");
    axios.get(this.state.api + "user-logout").then((response) => {
      const result = response.data;
      //  console.log(result);
      if (result.success === true) {
        window.location = "/";
      }
    });
  };

  onCheckBakelisteDailyPointage = () => {
    axios
      .get(
        this.state.api +
          "daily-pointage-by-bakeliste/" +
          this.state.bakeliste_id
      )
      .then((response) => {
        if (response.data.success && response.data.ispoint) {
          this.setState({
            isPoint: true,
            pointage: response.data.data,
          });
        } else if (response.data.success && !response.data.ispoint) {
          this.setState({
            isPoint: false,
          });
        }
      });
  };

  handlePointChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields: fields,
    });
    //console.log(fields)
  };

  handleSwitchStatut = (label) => {
    setTimeout(() => {
      if (label === "phone") {
        this.setState({
          empty_pm_phone: false,
        });
      }
      if (label === "isSuccessPointMe") {
        this.setState({
          isSuccessPointMe: false,
        });
      }
      if (label === "isErrorPointAlert") {
        this.setState({
          isErrorPointAlert: false,
        });
      }
      /*  if(label === 'isSuccessPointDepartureAlert'){
        this.setState({
          isSuccessPointDepartureAlert: false
        })
      }
      if(label === 'isErrorPointDepartureAlert'){
        this.setState({
          isErrorPointDepartureAlert: false
        })
      } */
      if (label === "phone") {
        this.setState({
          empty_pm_phone: false,
        });
      }
      if (label === "phone_syntax_error") {
        this.setState({
          phone_syntax_error: false,
        });
      }
    }, 5000);
  };

  handleValidatePointerForm = () => {
    // let fields = this.state.fields;
    var isValidForm = true;
    let errors = {};

    /* if(!fields["phone"]){
        isValidForm = false;
        this.setState({
          empty_pm_phone: true
        })          

        this.handleSwitchStatut('phone');
        errors["phone"] = 'Ce champ est obligatoire';
      } */
    this.setState({
      errors: errors,
    });

    return isValidForm;
  };

  onConfirmDeparture = (e) => {
    e.preventDefault();
    this.setState({
      pointDeparture: true,
      isAlreadyPoint: true,
    });
  };

  goToRi = () => {
    window.location = "/reglement-interieur";
  };

  submitPointerDeparture = (item) => {
    this.setState({
      departurePointInProgress: true,
      pointDeparture: false,
    });
    var pointItemID = item.id;
    var apiBaseUrl = this.state.api;

    axios
      .post(apiBaseUrl + "store-departure-time/" + pointItemID)
      .then((response) => {
        this.setState({
          departurePointInProgress: false,
        });

        if (response.data.success) {
          this.onCheckBakelisteDailyPointage();
          this.setState({
            isSuccessPointDepartureAlert: true,
          });
          this.handleSwitchStatut("isSuccessPointDepartureAlert");
        } else {
          this.setState({
            isErrorPointDepartureAlert: true,
            pointDeparture: true,
          });
          this.handleSwitchStatut("isErrorPointDepartureAlert");
        }
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          isErrorPointDepartureAlert: true,
        });
        this.handleSwitchStatut("isErrorPointDepartureAlert");
      });
  };

  submitPointerArriving = (e) => {
    e.preventDefault();
    if (this.handleValidatePointerForm()) {
      this.setState({
        pointInProgress: true,
      });
      var data = this.state.fields;
      var fd = new FormData();
      fd.append("phone", this.state.phone);
      const apiBaseUrl = this.state.api;
      axios
        .post(apiBaseUrl + "store-pointages", data)
        .then((response) => {
          if (response.data.success) {
            $("#phone").val("");
            this.onCheckBakelisteDailyPointage();
            this.setState({
              pointInProgress: false,
              isSuccessPointMe: true,
              fields: {},
            });
            this.handleSwitchStatut("isSuccessPointMe");
          } else {
            this.setState({
              pointInProgress: false,
              isErrorPointAlert: true,
            });
            this.handleSwitchStatut("isErrorPointAlert");
          }
        })
        .catch((error) => {
          // console.log(error);
          this.setState({
            pointInProgress: false,
            isErrorPointAlert: true,
          });
          this.handleSwitchStatut("isErrorPointAlert");
        });
    }
  };

  open = (size) => {
    this.setState({
      size,
      show: true,
    });
  };

  goToNetwork = () => {
    window.location.href = "https://network.bakeli.tech";
  };
  close = () => {
    this.setState({
      show: false,
    });
  };
  render() {
    const userprofile = this.state.avatar_file;

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-white box-shadow fixed-top px-lg-5 py-md-3 height-header">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-brand mr-lg-5 mr-0">
            <img className="logo-header" src={Logo} alt="" />
          </div>

          {/* <Avatar className="avatar-rs-custom no-display-desktop">RS</Avatar> */}
          {/* <div className="no-display-desktop">
            {this.state.isAlreadyPoint === false ? (
              <div className="m-auto">
                <button
                  className="btn-theme-light"
                  type="button"
                  data-toggle="modal"
                  data-target="#btnPointage"
                >
                  <FeatherIcon className="icon-pointage" icon="check-circle" />
                  Pointage
                </button>
              </div>
            ) : (
              <div className="m-auto">
                <button
                  className="btn-theme-green"
                  type="button"
                  data-toggle="modal"
                  data-target="#btnPointage"
                >
                  <FeatherIcon className="icon-pointage" icon="check-circle" />
                  Deja Pointer
                </button>
              </div>
            )}
          </div> */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="no-view-tablette dis-flex itm-center w-full">
              <div className="pl-xl-5 pl-lg-4 dis-flex pt-lg-0 pt-5 pb-lg-0 pb-5">
                <p className="welcome-header">Bienvenue sur Bakeli SI </p>
                <p className="annee-header">2021</p>
              </div>
              <div className="col-md-2 m-auto">
                <button
                  className="btn-connexion"
                  type="button"
                  onClick={this.goToNetwork}
                >
                  Découvrir Network
                </button>
              </div>
              
              <div className="col-md-3">
                {/* <EnvoyerMonTravail /> */}
                <AddLivraisonsv2/>

              </div>

              <div className="ml-auto no-view-tablette">
                <div className="avatar-group dis-flex itm-center">
                  <Avatar className="avatar-rs-custom">
                    {userprofile !== null ? (
                      <img
                        className="avatar-account mb-2"
                        src={this.state.base_api_url + userprofile}
                        alt=""
                      />
                    ) : (
                      this.state.userName
                        .split(" ")
                        .map((n, i, a) =>
                          i === 0 || i + 1 === a.length ? n[0] : null
                        )
                        .join("")
                    )}
                  </Avatar>
                  <Dropdown
                    className="name-avatar-custom"
                    title={this.state.userName}
                    placement="bottomEnd"
                    trigger="hover"
                  >
                    <NavLink
                      className="dropdown-item-header-dashboard"
                      to="/compte-bakeliste"
                    >
                      Mon compte
                    </NavLink>
                    <Dropdown.Item onClick={this.goToRi}>
                      Reglement Interieur
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.doLogout}>
                      Déconnexion
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div className="no-display-desktop">
              <div className="container-nav-vertical">
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tabCustom" 
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/dashboard-bakeliste"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="layout"
                    />
                    Dashboard
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/liste-tache-bakeliste"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="grid"
                    />
                    Tâches
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/syllabus-bakeliste"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="book"
                    />
                    Programmes
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/livraison-bakeliste"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="folder"
                    />
                    Livraison
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/groupe-bakeliste"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="users"
                    />
                    Groupe
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/demande-d-absence"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="mail"
                    />
                    Demande d'Absence
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/liste-contenus-bakelistes"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="file-text"
                    />
                    Liste des contenus
                  </NavLink>
                </div>

                {/* <button
                  className="btn-theme-light mt-4 ml-4 mb-5"
                  type="button"
                  data-toggle="modal"
                  data-target="#btnPointage"
                >
                  <FeatherIcon className="icon-pointage" icon="check-circle" />
                  Pointage
                </button> */}
              </div>
            </div>
          </div>
        </nav>

        <div
          className="modal fade"
          id="btnPointage"
          tabindex="-1"
          role="dialog"
          aria-labelledby="btnPointageTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content modal-pointage">
              {/*               <Pointage />
               */}{" "}
              <div>
                <div className="illustration-bsi">
                  <span className="point-label-bsi">POINT</span>
                  <span className="me-label-bsi">me</span>
                </div>
                <SweetAlert
                  show={this.state.pointDeparture}
                  onConfirm={() =>
                    this.submitPointerDeparture(this.state.pointage)
                  }
                  warning
                  title="Confirmez cette action!!!"
                  style={{
                    color: "rgb(240, 173, 78)",
                    fontSize: "12px",
                    fontFamily: "Work Sans, sans-serif",
                    paddingTop: 60,
                    paddingBottom: 40,
                  }}
                  confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                />
                {this.state.isPoint ? (
                  <div className="pointage-container">
                    <div>
                      <p className="pointage-label-bsi">
                        Aujourd'hui vous avez pointé à{" "}
                        <span className="heure-pointage-label-bsi">
                          {this.state.pointage.arriving_time}{" "}
                        </span>
                      </p>
                    </div>
                    <hr />
                    {this.state.pointage.departure_time === null ? (
                      <div className="post-pointage">
                        <h3 className="depart-pointage-text">
                          Cliquez sur le bouton pour pointer au départ
                        </h3>
                        <button
                          className="depart-btn-pointage btn-danger"
                          onClick={(e) => this.onConfirmDeparture(e)}
                        >
                          {!this.state.departurePointInProgress ? (
                            <img
                              src="https://img.icons8.com/ios-glyphs/30/FFFFFF/walking.png"
                              className="departure-icon"
                              alt="Pointage départ"
                            />
                          ) : (
                            <i className="fas fa-spinner fa-spin"></i>
                          )}
                        </button>
                      </div>
                    ) : (
                      <div>
                        <p className="pointage-label-bsi">
                          Aujourd'hui vous avez pointé au départ{" "}
                          <span className="heure-pointage-label-bsi">
                            {this.state.pointage.departure_time}{" "}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <form id="pointme-form">
                    <div className="input-group mb-4">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text bg-theme b-0"
                          id="basic-addon1"
                        >
                          <FeatherIcon
                            className="icon-input-pointage"
                            icon="phone"
                          />
                        </span>
                      </div>
                      <label
                        onChange={this.handlePointChange}
                        value={(this.state.fields["phone"] = this.state.phone)}
                        name="phone"
                        className="form-control input-pointage"
                        aria-describedby="basic-addon1"
                      >
                        {" "}
                        {this.state.phone}{" "}
                      </label>
                    </div>

                    {this.state.empty_pm_phone && (
                      <div className="alert alert-danger alert-item">
                        {" "}
                        {this.state.errors.phone}{" "}
                      </div>
                    )}
                    {this.state.phone_syntax_error && (
                      <div className="alert alert-danger alert-item">
                        {" "}
                        {this.state.errors.phone_syntax_error}{" "}
                      </div>
                    )}
                    {this.state.isErrorPointAlert && (
                      <div className="alert alert-danger">
                        {" "}
                        Un probléme est survenu lors du pointage. Veuillez
                        réessayer plus tard.{" "}
                      </div>
                    )}
                    <div className="submit-pointme-btn-container">
                      {!this.state.pointInProgress ? (
                        <button
                          className="btn-pointage"
                          onClick={(e) => this.submitPointerArriving(e)}
                        >
                          Pointer
                        </button>
                      ) : (
                        <button className="btn btn-warning bg-vorange pointme_inprogress_btn">
                          Pointage en cours &nbsp;
                          <i className="fa fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
