import React, { Component } from "react";
import "./voirContenusCoach.css";
import axios from "axios";
import * as Sentry from "@sentry/react";
import API from "../../variablesGlobales";
import ModalContactUs from "../modalContactUs/index";
import FeatherIcon from "feather-icons-react";
import Fichier from "../../image/fichier.png";
import { Link } from "react-router-dom";
import { NavLink } from "react-bootstrap";
import DOMPurify from "dompurify";
import TextTruncate from "react-text-truncate";
import InfiniteScroll from "react-infinite-scroll-component";
import Moment from "react-moment";
import Resume from "../../img/resume.jpeg";
import Article from "../../img/article.jpeg";
import Tutoriel from "../../img/tutoriel.jpeg";
import Rapport from "../../img/rapport.jpeg";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Preloader from "../preloader/index";
import Pra from "../../img/pra.jpeg";
import The from "../../img/pratique.jpeg";
import { Tooltip } from "@material-ui/core";
import Truncate from "react-truncate-html";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItem from "../bakelisteGroupItem/index";
import contenus from "../contenus";
import { toast } from "react-toastify";
toast.configure();

// import NextReunionItem from "../nextReunionItem/index";
// import SavedReunionListItem from "../savedReunionListItem/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as voirMesContenusActions from "../../store/voirMesContenus/actions";
export default class voirContenusCoach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contenus: [],
      contenus1: [],
      contenus_search: [],
      api: API.API,
      userID: window.sessionStorage.getItem("user_id"),
      isload: true,
      currentPage: 1,
      hasMore: true,
      article: [],
      rapportDeStage: [],
      tutoriel: [],
      resume: [],
      tab_All: true,
      tab_Rapports: false,
      tab_tutoriels: false,
      tab_resumes: false,
      tab_articles: false,
      navs: [],
      loading: false,
      recherche: "",
      search: false,
    };
  }
  componentDidMount() {
    this.getResume();
    this.getArticles();
    this.getListResumes();
    this.getTutoriels();
    this.getRapportDeStage();
    setTimeout(() => {
      this.setState({
        isload: false,
      });
    }, 5000);
  }
  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };

  setNavTabs(event) {
    let navs = this.state.navs;
    navs[event.target.name] = event.target.value;
    if (navs["drone"] === "all") {
      this.setState({
        tab_All: true,
        tab_Rapports: false,
        tab_tutoriels: false,
        tab_resumes: false,
        tab_articles: false,
      });
    }
    if (navs["drone"] === "rapports") {
      this.setState({
        tab_All: false,
        tab_Rapports: true,
        tab_tutoriels: false,
        tab_resumes: false,
        tab_articles: false,
      });
    }
    if (navs["drone"] === "tutoriels") {
      this.setState({
        tab_All: false,
        tab_Rapports: false,
        tab_tutoriels: true,
        tab_resumes: false,
        tab_articles: false,
      });
    }
    if (navs["drone"] === "resumes") {
      this.setState({
        tab_All: false,
        tab_Rapports: false,
        tab_tutoriels: false,
        tab_resumes: true,
        tab_articles: false,
      });
    }
    if (navs["drone"] === "articles") {
      this.setState({
        tab_All: false,
        tab_Rapports: false,
        tab_tutoriels: false,
        tab_resumes: false,
        tab_articles: true,
      });
    }
  }
  getListResumes = () => {
    axios
      .get(this.state.api + "liste-resumes")
      .then((res) => {
        const contenu = res.data;
        this.setState({
          resume: contenu.data,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getTutoriels = () => {
    axios
      .get(this.state.api + "liste-tutoriels")
      .then((res) => {
        const contenu = res.data;
        this.setState({
          tutoriel: contenu.data,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getRapportDeStage = () => {
    axios
      .get(this.state.api + "liste-rapport-de-stage")
      .then((res) => {
        const contenu = res.data;
        this.setState({
          rapportDeStage: contenu.data,
        });
        // console.log(this.state.rapportDeStage)
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getArticles = () => {
    axios
      .get(this.state.api + "liste-articles")
      .then((res) => {
        const contenu = res.data;
        this.setState({
          article: contenu.data,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getResume = () => {
    axios
      .get(
        this.state.api + "textual-contents" + "/?page=" + this.state.currentPage
      )
      .then((res) => {
        const contenu = res.data.data;
        this.setState({
          contenus: contenu.data,
        });
        // console.log(this.state.contenus)
      })
      .catch((error) => {
        // console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  archiveContent = (id) => {
    // console.log(id,"delete id", id)

    // var fd = new FormData();
    // fd.append("archived-by", this.state.userID);
    axios
      .post(this.state.api + "archive-content/" + id)
      .then((res) => {
        if (res.data.success) {
          // console.log("res",res)
          // this.getResume();
          toast.success(
            "Contenu archivé avec succes",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1500 }
          );
        } else {
          toast.error(
            "Erreur l'archivage de ce contenu",
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 1500 }
          );
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  searchOnContent = () => {
    this.setState({
      search: true,
      currentPage: 1
    });
    // console.log(this.state.recherche, this.state.search, "new");
    var fd = new FormData();
    fd.append("data", this.state.recherche);
    axios
      .post(
        this.state.api +
          "search-textual-contents" +
          "/?page=" +
          1,
        fd
      )
      .then((res) => {
        const contenu = res.data;
        this.setState((prevState, props) => ({
          contenus_search: prevState.contenus_search.concat(contenu.data.data),
        }));
       
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

 

  fetchMoreData = () => {
    setTimeout(() => {
      this.setState({
        currentPage: this.state.currentPage + 1,
        loading: true,
      });
      if (this.state.search === true) {
        var fd = new FormData();
        // fd.append("data", this.state.recherche);
        axios
          .post(
            this.state.api +
              "search-textual-contents" +
              "/?page=" +
              this.state.currentPage,
            fd
          )
          .then((response) => {
            // console.log(response.data.data.data, "search");
            if (response.data.success === true) {
              Object.values(response.data.data.data).forEach((element) => {
                this.state.contenus.push(element);
              });

              if (
                response.data.data.current_page === response.data.data.last_page
              ) {
                this.setState({ hasMore: false, loading: false });
              } else {
                this.setState({ hasMore: true, loading: true });
              }
            }
          });
        this.setState({ ttt: false });
      } else {
        axios
          .get(
            this.state.api +
              "textual-contents" +
              "/?page=" +
              this.state.currentPage
          )
          .then((response) => {
            // console.log("jdcjcndzl", response);
            if (response.data.success === true) {
              Object.values(response.data.data.data).forEach((element) => {
                this.state.contenus.push(element);
              });
              //console.log("dzlkd", this.state.livraisonsSyllabus);

              if (
                response.data.data.current_page === response.data.data.last_page
              ) {
                this.setState({ hasMore: false, loading: false });
              } else {
                this.setState({ hasMore: true, loading: true });
              }
            }
          });
      }
    }, 500);

    return this.state.contenus;
  };

  voirMesProductions = () => {
    window.location = "/liste-de-mes-productions";
  }
  voirPlus = (id) => { 
    window.location = `/details-contenus-coach?id=${id}`;
  };
  ajouterContenusCoach = () => {
    window.location = "coach-ajout-contenu";
  };
  render() {
    // console.log("dans render", this.state.contenus_search);
    return this.state.isload ? (
      <div>
        <Preloader />
      </div>
    ) : (
      <div className="p-b-200 ">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item ">
                  <Link to="coach-dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Liste des contenus
                </li>
              </ol>
            </nav>
            <div className="row m-b-20 pt3">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600">Liste des contenus</h6>
              </div>
              <div className="col dis-flex m-b-15">
                <ModalContactUs />
              </div>
            </div>
            <div className="row chercher">
            <div className="col-md-4">

            <button
                    className="btn-theme-green "
                    onClick={() => this.ajouterContenusCoach()}
                  >
                    Ajouter un contenu
                  </button>
                  </div>
                  <div className="col-md-4">

<button
         className="btn-theme "
         onClick={() => this.voirMesProductions()}
      >
        Mes productions
      </button>
      </div>
             <div className="input-group-prepend SearchB col-md-4">
              <FeatherIcon className="search_icon" icon="search" />
              <input
                className="form-control form-control-costom"
                value={this.state.recherche}
                type="search"
                // className="recherche"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
                onChange={(e) =>
                  this.setState({
                    recherche: e.target.value,
                  })
                }
              />

              
              <button onClick={() => this.searchOnContent()}>Rechercher</button>
            </div> 
            </div>
            <div></div>
            {!this.state.search ? (
            <div className="">
              <InfiniteScroll
                className="hello"
                dataLength={this.state.contenus.length}
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                loader={
                  this.state.loading &&
                  "Les donnees sont entrain de se charger...."
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Vous avez tous visualise!!!</b>
                  </p>
                }
              >
                <div>
                  <div className=" tab-data-col">
                    <form>
                      <div onChange={(event) => this.setNavTabs(event)}>
                        <div className="row tb">
                           <div className="col-md-4">
                            <div className="radiobtn1">
                              <input
                                defaultChecked
                                type="radio"
                                value="all"
                                name="drone"
                                id="all"
                                data-target="#all"
                              />
                              <label for="all">All</label>
                            </div>
                            <div className="radiobtn1">
                              <input
                                type="radio"
                                value="articles"
                                name="drone"
                                id="article"
                                data-target="#articles"
                              />
                              <label for="article">Articles</label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="radiobtn1">
                              <input
                                type="radio"
                                value="resumes"
                                name="drone"
                                id="resume"
                                data-target="#resumes"
                              />
                              <label for="resume">Résumé de cours</label>
                            </div>
                            <div className="radiobtn1">
                              <input
                                type="radio"
                                value="rapports"
                                name="drone"
                                id="rapport"
                                data-target="#rapports"
                              />
                              <label for="rapport">Rapports de stage</label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="radiobtn1">
                              <input
                                type="radio"
                                value="tutoriels"
                                name="drone"
                                id="tutoriel"
                                data-target="#tutoriel"
                              />
                              <label for="tutoriel">Tutoriels</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* la liste des contenus par catégories */}
                <div className="tab-content">
                  {this.state.tab_Rapports ? (
                    <div className="tab-pane active" id="rapports">
                      <div className="row content-block">
                        {this.state.rapportDeStage.length > 0 ? (
                          this.state.rapportDeStage.map((item, index) => (
                            <div key={index} className="col-md-6 dis-flex mb-5">
                              <div class="column">
                                <div class="post-module">
                              
                                  <div class="thumbnail">
                                  {/* <div className="auteur">
                                    {item.fullName}
                                  </div> */}
                                    <div class="date">
                                      <div class="day">
                                        {" "}
                                        <Moment format="DD" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                      <div class="month">
                                        {" "}
                                        <Moment format="MMMM" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                    {/* {item.content_type === "article" && ( */}
                                    <img src={Rapport} />
                                    {/* )} */}
                                  </div>
                                  <div class="post-content p-2">
                                    <div class="category">
                                      {item.content_type}
                                    </div>
                                    <Link
                                      to={{
                                        pathname: "/details-contenus-coach",
                                        state: { id: item.id },
                                      }}
                                    >
                                      <h1 class="title">{item.title}</h1>
                                    </Link>
                                    <p class="description">
                                      <div>
                                        <Truncate
                                          lines={3}
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                              item.content
                                            ),
                                          }}
                                        />
                                      </div>
                                      ....
                                    </p>
                                    <div class="post-meta">
                                    <div className="row">
                                          <div className="col-md-6">
                                        <span class="timestamp">
                                         
                                            <div className="auteur">
                                    {item.fullName}
                                  </div>
                                        </span>
                                        </div>
                                          <div className="col-md-6">
                                            
                                              <Tooltip title="Archiver ce contenu">
                                                <FeatherIcon
                                                  icon="trash-2"
                                                  className="icon-content-syllabus-itemC"
                                                  onClick={() => this.archiveContent(item.id)}
                                                />
                                              </Tooltip>
                                            
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p style={{ textAlign: "center" }}>
                            <b>
                              Aucun rapport disponible.
                            </b>
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="tab-content">
                  {this.state.tab_tutoriels ? (
                    <div className="tab-pane active" id="tutoriel">
                      <div className="row content-block">
                        {this.state.tutoriel.length > 0 ? (
                          this.state.tutoriel.map((item, index) => (
                            <div key={index} className="col-md-6 dis-flex mb-5">
                              <div class="column">
                                <div class="post-module">
                                  <div class="thumbnail">
                                    <div class="date">
                                      <div class="day">
                                        {" "}
                                        <Moment format="DD" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                      <div class="month">
                                        {" "}
                                        <Moment format="MMMM" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                    {/* {item.content_type === "article" && ( */}
                                    <img src={Tutoriel} />
                                    {/* )} */}
                                  </div>
                                  <div class="post-content p-2">
                                    <div class="category">
                                      {item.content_type}
                                    </div>
                                    <Link
                                      to={{
                                        pathname: "/details-contenus-coach",
                                        state: { id: item.id },
                                      }}
                                    >
                                      <h1 class="title">{item.title}</h1>
                                    </Link>
                                    <p class="description">
                                      <div>
                                        <Truncate
                                          lines={3}
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                              item.content
                                            ),
                                          }}
                                        />
                                      </div>
                                      ....
                                    </p>
                                    <div class="post-meta">
                                    <div className="row">
                                          <div className="col-md-6">
                                        <span class="timestamp">
                                         
                                            <div className="auteur">
                                    {item.fullName}
                                  </div>
                                        </span>
                                        </div>
                                          <div className="col-md-6">
                                            
                                              <Tooltip title="Archiver ce contenu">
                                                <FeatherIcon
                                                  icon="trash-2"
                                                  className="icon-content-syllabus-itemC"
                                                  onClick={() => this.archiveContent(item.id)}
                                                />
                                              </Tooltip>
                                            
                                          </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p style={{ textAlign: "center" }}>
                            <b>
                              Aucun tutoriel disponible.
                            </b>
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="tab-content">
                  {this.state.tab_resumes ? (
                    <div className="tab-pane active" id="resumes">
                      <div className="row content-block">
                        {this.state.resume.length > 0 ? (
                          this.state.resume.map((item, index) => (
                            <div key={index} className="col-md-6 dis-flex mb-5">
                              <div class="column">
                                <div class="post-module">
                                  <div class="thumbnail">
                                    <div class="date">
                                      <div class="day">
                                        {" "}
                                        <Moment format="DD" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                      <div class="month">
                                        {" "}
                                        <Moment format="MMMM" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                    {/* {item.content_type === "article" && ( */}
                                    <img src={Resume} />
                                    {/* )} */}
                                  </div>
                                  <div class="post-content p-2">
                                    <div class="category">
                                      {item.content_type}
                                    </div>
                                    <Link
                                      to={{
                                        pathname: "/details-contenus-coach",
                                        state: { id: item.id },
                                      }}
                                    >
                                      <h1 class="title">{item.title}</h1>
                                    </Link>
                                    <p class="description">
                                      <div>
                                        <Truncate
                                          lines={3}
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                              item.content
                                            ),
                                          }}
                                        />
                                      </div>
                                      ....
                                    </p>
                                    <div class="post-meta">
                                    <div className="row">
                                          <div className="col-md-6">
                                        <span class="timestamp">
                                          {/* <i class="fa fa-clock-">o</i>
                                          <Moment format="DD-MM-YYYY">
                                            {item.created_at}
                                          </Moment> */}
                                           
                                           <div className="auteur">
                                    {item.fullName}
                                  </div>
                                        </span>
                                        </div>
                                          <div className="col-md-6">
                                            
                                              <Tooltip title="Archiver ce contenu">
                                                <FeatherIcon
                                                  icon="trash-2"
                                                  className="icon-content-syllabus-itemC"
                                                  onClick={() => this.archiveContent(item.id)}
                                                />
                                              </Tooltip>
                                            
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p style={{ textAlign: "center" }}>
                            <b>
                              Aucun resumé disponible.
                            </b>
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="tab-content">
                  {this.state.tab_articles ? (
                    <div className="tab-pane active" id="articles">
                      <div className="row content-block">
                        {this.state.article.length > 0 ? (
                          this.state.article.map((item, index) => (
                            <div key={index} className="col-md-6 dis-flex mb-5">
                              <div class="column">
                                <div class="post-module">
                                  <div class="thumbnail">
                                    <div class="date">
                                      <div class="day">
                                        {" "}
                                        <Moment format="DD" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                      <div class="month">
                                        {" "}
                                        <Moment format="MMMM" local>
                                          {item.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                    {/* {item.content_type === "article" && ( */}
                                    <img src={Article} />
                                    {/* )} */}
                                  </div>
                                  <div class="post-content p-2">
                                    <div class="category">
                                      {item.content_type}
                                    </div>
                                    <Link
                                      to={{
                                        pathname: "/details-contenus-coach",
                                        state: { id: item.id },
                                      }}
                                    >
                                      <h1 class="title">{item.title}</h1>
                                    </Link>
                                    <p class="description">
                                      <div>
                                        <Truncate
                                          lines={3}
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                              item.content
                                            ),
                                          }}
                                        />
                                      </div>
                                      ....
                                    </p>
                                    <div class="post-meta">
                                    <div className="row">
                                          <div className="col-md-6">
                                        <span class="timestamp">
                                          {/* <i class="fa fa-clock-">o</i>
                                          <Moment format="DD-MM-YYYY">
                                            {item.created_at}
                                          </Moment> */}
                                           
                                           <div className="auteur">
                                    {item.fullName}
                                  </div>
                                        </span>
                                        </div>
                                          <div className="col-md-6">
                                            
                                              <Tooltip title="Archiver ce contenu">
                                                <FeatherIcon
                                                  icon="trash-2"
                                                  className="icon-content-syllabus-itemC"
                                                  onClick={() => this.archiveContent(item.id)}
                                                />
                                              </Tooltip>
                                            
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p style={{ textAlign: "center" }}>
                            <b>
                              Aucun article disponible.
                            </b>
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="tab-content">
                  {this.state.tab_All ? (
                    <div className="tab-pane active" id="all">
                      <div className="row content-block">
                        {this.state.contenus.length > 0 ? (
                          
                            this.state.contenus.map((item, index) => (

                                <div key={index} className="col-md-6 dis-flex mb-5">
                                <div class="column">
                                  <div class="post-module">
                                    <div class="thumbnail">
                                      <div class="date">
                                        <div class="day">
                                          {" "}
                                          <Moment format="DD" local>
                                            {item.created_at}
                                          </Moment>
                                        </div>
                                        <div class="month">
                                          {" "}
                                          <Moment format="MMMM" local>
                                            {item.created_at}
                                          </Moment>
                                        </div>
                                      </div>
                                      {item.content_type === "article" && (
                                        <img src={Article} />
                                      )}
                                      {item.content_type === "résumé" && (
                                        <img src={Resume} />
                                      )}
                                      {item.content_type === "tutoriel" && (
                                        <img src={Tutoriel} />
                                      )}
                                      {item.content_type ===
                                        "rapport de stage" && (
                                        <img src={Rapport} />
                                      )}
                                      {item.content_type ===
                                        "cahier de charges" && (
                                        <img src={Tutoriel} />
                                      )}
                                      {item.content_type === "backlog" && (
                                        <img src={Tutoriel} />
                                      )}
                                    </div>
                                    <div class="post-content p-2">
                                      <div class="category">
                                        {item.content_type}
                                      </div>
                                      <Link
                                        to={{
                                          pathname: "/details-contenus-coach",
                                          state: { id: item.id },
                                        }}
                                      >
                                        <h1 class="title">{item.title}</h1>
                                      </Link>
                                      <p class="description">
                                        <div>
                                          <Truncate
                                            lines={3}
                                            dangerouslySetInnerHTML={{
                                              __html: DOMPurify.sanitize(
                                                item.content
                                              ),
                                            }}
                                          />
                                        </div>
                                        ....
                                      </p>
                                      <div class="post-meta">
                                      <div className="row">
                                          <div className="col-md-6">
                                        <span class="timestamp">
                                          {/* <i class="fa fa-clock-">o</i>
                                          <Moment format="DD-MM-YYYY">
                                            {item.created_at}
                                          </Moment> */}
                                           
                                           <div className="auteur">
                                    {item.fullName}
                                  </div>
                                        </span>
                                        </div>
                                          <div className="col-md-6">
                                            
                                              <Tooltip title="Archiver ce contenu">
                                                <FeatherIcon
                                                  icon="trash-2"
                                                  className="icon-content-syllabus-itemC"
                                                  onClick={() => this.archiveContent(item.id)}
                                                />
                                              </Tooltip>
                                            
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                        
                             
                                 ))
                          ) : (
                            <p style={{ textAlign: "center" }}>
                              Aucun contenu disponible pour le moment. 
                            </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </InfiniteScroll>

              
              </div>
             ) : (
              <div className="">
                <InfiniteScroll
                  className="hello"
                  dataLength={this.state.contenus_search.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.hasMore}
                  loader={
                    this.state.loading &&
                    "Les donnees sont entrain de se charger...."
                  }
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Vous avez tous visualise!!!</b>
                    </p>
                  }
                >
                  <div className="row content-block">
                    {this.state.contenus_search.length > 0 ? (
                      this.state.contenus_search.map((item, index) => (
                        <div key={index} className="col-md-6 dis-flex mb-5">
                          <div class="column">
                            <div class="post-module">
                              <div class="thumbnail">
                                <div class="date">
                                  <div class="day">
                                    {" "}
                                    <Moment format="DD" local>
                                      {item.created_at}
                                    </Moment>
                                  </div>
                                  <div class="month">
                                    {" "}
                                    <Moment format="MMMM" local>
                                      {item.created_at}
                                    </Moment>
                                  </div>
                                </div>
                                {item.content_type === "article" && (
                                  <img src={Article} />
                                )}
                                {item.content_type === "résumé" && (
                                  <img src={Resume} />
                                )}
                                {item.content_type === "tutoriel" && (
                                  <img src={Tutoriel} />
                                )}
                                {item.content_type === "rapport de stage" && (
                                  <img src={Rapport} />
                                )}
                                {item.content_type === "cahier de charges" && (
                                  <img src={Tutoriel} />
                                )}
                                {item.content_type === "backlog" && (
                                  <img src={Tutoriel} />
                                )}
                              </div>
                              <div class="post-content p-2">
                                <div class="category">{item.content_type}</div>
                                <Link
                                  to={{
                                    pathname: "/details-contenus-coach",
                                    state: { id: item.id },
                                  }}
                                >
                                  <h1 class="title">{item.title}</h1>
                                </Link>
                                <p class="description">
                                  <div>
                                    <Truncate
                                      lines={3}
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          item.content
                                        ),
                                      }}
                                    />
                                  </div>
                                  ....
                                </p>
                                <div class="post-meta">
                                  <span class="timestamp">
                                    <i class="fa fa-clock-">o</i>

                                    <Moment format="DD-MM-YYYY">
                                      {item.created_at}
                                    </Moment>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p style={{ textAlign: "center" }}>
                        Aucun contenu disponible pour le moment. Vous pouvez en
                        créer
                      </p>
                    )}
                  </div>
                </InfiniteScroll>
              </div>
            )} 

          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              {/* <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <CoachItemDashboardBakeliste />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <BakelisteGroupItem />
              </div> */}
              {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                <NextReunionItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <SavedReunionListItem />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ voirMesContenus }) => ({ ...voirMesContenus }),
//     dispatch => bindActionCreators({ ...voirMesContenusActions }, dispatch)
//   )( voirMesContenus );
