import React, { Component } from "react";
import "./coachAssistantProductions.css";
import axios from "axios";
import * as Sentry from "@sentry/react";
import API from "../../variablesGlobales";
import ModalContactUs from "../modalContactUs/index";
import FeatherIcon from "feather-icons-react";
import Fichier from "../../image/fichier.png";
import { Link } from "react-router-dom";
import { NavLink } from "react-bootstrap";
import DOMPurify from "dompurify";
import TextTruncate from "react-text-truncate";
import Preloader from "../preloader/index";
import InfiniteScroll from "react-infinite-scroll-component";
import ListCoachAssistant from "../listCoachAssistant/index";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Moment from "react-moment";
import Resume from "../../img/resume.jpeg";
import Article from "../../img/article.jpeg";
import Tutoriel from "../../img/tutoriel.jpeg";
import Rapport from "../../img/rapport.jpeg";
import { Tooltip } from "@material-ui/core";
import Truncate from "react-truncate-html";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItem from "../bakelisteGroupItem/index";
// import NextReunionItem from "../nextReunionItem/index";
// import SavedReunionListItem from "../savedReunionListItem/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as voirMesContenusActions from "../../store/voirMesContenus/actions";
export default class coachAssistantProductions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contenus: [],
      api: API.API,
      userID: window.sessionStorage.getItem("user_id"),
      isload: true,

    };
  }
  componentDidMount() {
    this.getResume(); 
    setTimeout(() => {
      this.setState({
        isload: false,
      });
    }, 5000);
  }

  getResume = () => {
    // var fd = new FormData();
    // fd.append("bakeliste_id", this.state.userID);
    axios
      .get(this.state.api + "textual-contents-by-bakeliste/" + this.state.userID)
      .then((res) => {
        // console.log(res)
        const contenu = res.data;
        this.setState({
          contenus: contenu.data,
        });
        // console.log(this.state.contenus);
      })
      .catch((error) => {
        // console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      }); 
  };
  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true }); 
  };
  voirPlus = (id) => {
    window.location=`/details-contenus-coach-assistant?id=${id}` 
  }
 
  editProduction = (id) => {
    window.location=`/modification-contenu-coach-assistant?id=${id}`
  }
  render() {
    return this.state.isload ? (

      <div>
      <Preloader />
    </div>
  ) : (
      <div className="p-b-200 ">
        <div className="row">

        <div className="col-lg-9">
          <nav aria-label="breadcrumb fixed">
            <ol className="breadcrumb fixed">
              <li className="breadcrumb-item ">
                <Link to="dashboard-coach-assistant">Dashboard</Link>
              </li>
              <li className="breadcrumb-item ">
                <Link to="liste-contenus-coach-assistant">Liste des contenus</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Mes productions
              </li>
            </ol>
          </nav>
          <div className="row m-b-20 pt3">
            <div className="col m-b-15">
              <h6 className="h-theme ff-inter fw-600">Liste de mes contenus</h6>
            </div>
           
          </div>
          <div className="row content-block">
                  {this.state.contenus.length > 0 ? (
                    this.state.contenus.map((content, index) => (
                      <div className="col-md-6 dis-flex mb-5">
                        <div class="column">
                          <div class="post-module">
                            <div class="thumbnail">
                              <div class="date">
                                <div class="day">
                                  {" "}
                                  <Moment format="DD" local>
                                    {content.created_at}
                                  </Moment>
                                </div>
                                <div class="month">
                                  {" "}
                                  <Moment format="MMMM" local>
                                    {content.created_at}
                                  </Moment>
                                </div>
                              </div>
                              {content.content_type === "article" && (
                                <img src={Article} />
                              )}
                              {content.content_type === "résumé" && (
                                <img src={Resume} />
                              )}
                              {content.content_type === "tutoriel" && (
                                <img src={Tutoriel} />
                              )}
                              {content.content_type === "rapport de stage" && (
                                <img src={Rapport} />
                              )}
                              {content.content_type === "cahier de charges" && (
                                <img src={Tutoriel} />
                              )}
                              {content.content_type === "backlog" && (
                                <img src={Tutoriel} />
                              )}
                            </div>
                            <div class="post-content p-2">
                              <div class="category">{content.content_type}</div>
                              <Link
                                to={{
                                  pathname: "/details-contenus-coach-assistant",
                                  state: { id: content.id },
                                }}
                              >
                                <h1 class="title">{content.title}</h1>
                              </Link>
                              <p class="description">
                                <div>
                                  <Truncate
                                    lines={3}
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        content.content
                                      ),
                                    }}
                                  />
                                </div>
                                ....
                              </p>
                              <div class="post-meta">
                              <div className="row">
                                        <div className="col-md-6">
                                          <span class="timestamp">
                                            <i class="fa fa-clock-">o</i>

                                             <Moment format="DD-MM-YYYY">
                                              {content.created_at}
                                            </Moment>
                                          </span>
                                        </div>
                                        <div className="col-md-6">
                                          <Tooltip title="Modifier ce contenu">
                                            <FeatherIcon
                                              icon="edit"
                                              className="icon-content-syllabus-itemE"
                                              onClick={() => this.editProduction(content.id)}
                                            />
                                          </Tooltip>
                                        </div>

                                      </div>
                                     
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p style={{ textAlign: "center" }}>
                      <b>
                        Aucun contenu disponible pour le moment. Vous pouvez en
                        créer
                      </b>
                    </p>
                  )}
                </div>
        </div>
        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              {/* <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <CoachItemDashboardBakeliste />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <BakelisteGroupItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <ListCoachAssistant /> 
              </div>*/}
              {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                <NextReunionItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <SavedReunionListItem />
              </div> */}
            </div>
            </div>

          </div>
      </div>
    );
  }
}
// export default connect(
//     ({ voirMesContenus }) => ({ ...voirMesContenus }),
//     dispatch => bindActionCreators({ ...voirMesContenusActions }, dispatch)
//   )( voirMesContenus );
