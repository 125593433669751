import React, { Component } from "react";
import "./bakelisteGroupItem.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import IconGroup from "../../img/icons/people-icon.png";
import axios from "axios";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import { NavLink } from "react-router-dom";

export default class bakelisteGroupItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupe_bakeliste: [],
      membres_groupe: [],
      bakeliste_id: sessionStorage.getItem("user_id"),
      groupe_id: sessionStorage.getItem("sous_groupe_id"),
      api: API.API,
    }; 
    Sentry.init({
      dsn:
        "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
    });
  }

  componentDidMount() {
    this.onGetBakelisteGroupe();
  }

  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }
  onGetBakelisteGroupe = () => {
    axios
      .get(this.state.api + "sous__groupes/" + this.state.groupe_id)
      .then((response) => {
        //console.log("sous__groupe", response.data.liste);
        if (response.data.data !== undefined) {
          this.setState({
            groupe_bakeliste: response.data.data,
            membres_groupe: response.data.liste[0],
          });
          // console.log("groupe nabsjdk", this.state.groupe_bakeliste)
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  render() {
    return (
      <div className="card-dashboard m-b-15">
        <NavLink to="/groupe-bakeliste">
          <p className="h-theme h-card-dashboard-bakeliste">
            <img
              className="icon-h-card-dashboard-bakeliste"
              src={IconGroup}
              alt=""
            />{" "}
            Groupe {this.state.groupe_bakeliste.name}
          </p>
        </NavLink>
        <BootstrapTable
          data={this.state.membres_groupe}
          version="4"
          bordered={false}
          bodyStyle={{
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.7)",
            fontFamily: "inherit",
            fontWeight: 400,
          }}
        >
          <TableHeaderColumn
            isKey
            dataField="id"
            thStyle={{ border: "none" }}
            tdStyle={{
              border: "none",
              paddingLeft: "0",
              paddingRight: "0",
              width: "20px",
            }}
          ></TableHeaderColumn>
          <TableHeaderColumn
            dataField="first_name"
            thStyle={{ border: "none" }}
            tdStyle={{ border: "none" }}
          ></TableHeaderColumn>
          <TableHeaderColumn 
            dataField="last_name"
            thStyle={{ border: "none" }}
            tdStyle={{ border: "none" }}
          ></TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}
// export default connect(
//     ({ bakelisteGroupItem }) => ({ ...bakelisteGroupItem }),
//     dispatch => bindActionCreators({ ...bakelisteGroupItemActions }, dispatch)
//   )( bakelisteGroupItem );
