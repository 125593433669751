import React, { Component } from "react";
import "./modifierProductions.css";
// import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ModalContactUs from "../modalContactUs/index";
import { Link } from "react-router-dom";
import { stateToHTML } from "draft-js-export-html";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItem from "../bakelisteGroupItem/index";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import * as Sentry from "@sentry/react";
import $ from "jquery";
import API from "../../variablesGlobales";
import { toast } from "react-toastify";

import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import "react-toastify/dist/ReactToastify.css";
toast.configure();

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as ajouterResumeActions from "../../store/ajouterResume/actions";
export default class modifierProductions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      uploadedImages: [],
      show: false,
      api: API.API,
      id: this.props.id,
      isSendingResumenProgress: false,
      userID: window.sessionStorage.getItem("user_id"),
      coach_id: window.sessionStorage.getItem("coach_id"),
      userFullName: window.sessionStorage.getItem("userFullName"),
      domaine_id: window.sessionStorage.getItem("domaine_id"),
      fields: {},
      isSuccessSending: false,
      detailContenu: [],
      content: "",
      
    };
    this._uploadImageCallBack = this._uploadImageCallBack.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

 
  onEditorStateChange = (editorState, e) => {
    this.setState({
      editorState
    },
    );
    
  };


  componentDidMount() {
    this.getContenu();
  }

  getContenu = () => {
    axios
      .get(this.state.api + "textual-contents/" + this.state.id)
      .then((res) => {
        // console.log("modcont",res)
        this.setState({
          detailContenu: res.data.data,
          title: res.data.data.title,
          editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(res.data.data.content)
            )
          )
        });
        // console.log(this.state.detailContenu)
      })
      .catch((error) => {
        // console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if (e.target.name === "content_type") {
      this.setState({
        content_type: e.target.value,
      });
    }
  };

  _uploadImageCallBack(file) {
    // long story short, every time we upload an image, we
    // need to save it to the state so we can get it's data
    // later when we decide what to do with it.

    // Make sure you have a uploadImages: [] as your default state
    let uploadedImages = this.state.uploadedImages;

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };

    uploadedImages.push(imageObject);

    this.setState({ uploadedImages: uploadedImages });

    // We need to return a promise with the image src
    // the img src we will use here will be what's needed
    // to preview it in the browser. This will be different than what
    // we will see in the index.md file we generate.
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  }

  showTexte = () => {
    this.setState({
      show: true,
    });
    console.log(stateToHTML(this.state.editorState.getCurrentContent()));
    <p dangerouslySetInnerHTML={{ __html: this.state.editorState }}></p>;
    alert("you resume is here", this.state.editorState);
    return <p dangerouslySetInnerHTML={{ __html: this.state.editorState }}></p>;
  };

  onEditContenu = () => {
    this.setState({
      isSendingResumenProgress: true,
    });
    var content = stateToHTML(this.state.editorState.getCurrentContent());
    var data = this.state.fields;
    var fd = new FormData();
    fd.append("content", content);
    fd.append("coach_id", this.state.coach_id);
    fd.append("content_type", this.state.content_type);
    fd.append("bakeliste_id", this.state.userID);
    fd.append("domaine_id", this.state.domaine_id);
    fd.append("fullName", this.state.userFullName);
    fd.append("title", this.state.title);
    // fd.append("id", this.state.id);
    axios
      .put(this.state.api + "textual-contents/" + this.state.id , fd, {
        Headers: { "Content-Type": "multipart/formdata" },
      })
      .then((response) => {
        // console.log(response);
        this.setState({
          isSendingResumenProgress: false,
        });
        if (response.data.success) {
          toast.success(
            "Contenu modifié avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
          window.location="/liste-de-mes-productions";
        }
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          isSendingResumenProgress: false,
        });
        toast.error(
          "Erreur sur la modification du contenu",
          { position: toast.POSITION.BOTTOM_LEFT },
          { autoClose: 1000 }
        );
      });
  };

  render() {
    const { editorState } = this.state;
    //  return <div className="component-ajouter-resume">Hello! component ajouterResume</div>;
    return (
      <div className="p-b-200 ">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item ">
                  <Link to="coach-dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item ">
                  <Link to="liste-contenus-coach">Liste des contenus</Link>
                </li>
                <li className="breadcrumb-item ">
                  <Link to="liste-de-mes-productions">Mes productions</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Modification d'un contenu
                </li>
              </ol>
            </nav>
            <div className="row m-b-20 pt3">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600">
                  Modification d'un contenu
                </h6>
              </div>
              <div className="col dis-flex m-b-15">
                <ModalContactUs />
              </div>
            </div>
            {/* <div className="card-dashboard ht"> */}
            <form>
              <div className="col-md-12 tc-input-item">
                <div className="form-group ">
                  <label>Type de contenu : {this.state.detailContenu.content_type}</label>
                  <select
                    className="form-control"
                    id="ct-select"
                    // value={this.state.detailContenu.content_type}
                    value={this.state.fields["content_type"]}

                    name="content_type"
                    onChange={(e) =>
                      this.setState({content_type: e.target.value })
                    }
                    required
                  >
                    <option > {this.state.detailContenu.content_type} </option>
                   
                    <option value="résumé"> Résumé de cours </option>
                    <option value="article"> Article </option>
                    <option value="tutoriel"> Tutoriel </option>
                    <option value="rapport de stage"> Rapport de stage </option>
                    <option value="cahier de charges">
                      {" "}
                      Cahier de charges{" "}
                    </option>
                    <option value="backlog"> Backlog </option>
                  </select>
                </div>
              </div>
              <div className="col-md-12 tc-input-item">
                <label>Titre :</label>
                <input
                  type="text"
                  className="form-control"
                  id="title-input"
                  value={this.state.title}
                  placeholder="Titre"
                  required
                  name="title"
                  onChange={(e) =>
                    this.setState({title: e.target.value })
                  }
                />
              </div>
              <div className="col-md-12 tc-input-item">
                <label>Contenu :</label>
                {this.state.detailContenu.content && (

                <Editor
                  className="ht"
                  editorState={editorState}
                  // editorState={this.state.editorState}
                  
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  // onChange={this.handleChange}
                  onEditorStateChange={this.onEditorStateChange}
                  name="content"
                 defaultContentState={editorState}
                  toolbar={{
                    inline: { inDropdown: false },
                    list: { inDropdown: false },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    image: { uploadCallback: this._uploadImageCallBack },
                    inputAccept:
                      "application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel",
                  }}
                />
                )}
              </div>
           
              <div className="col-md-6 offset-md-10 m-t-25">
                {this.state.isSendingResumenProgress ? (
                  <button type="button" className="btn-theme-orange" disabled>
                    Modification en cours &nbsp;
                    <FeatherIcon icon="loader" aria-hidden="true" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-theme"
                    onClick={() => this.onEditContenu()}
                  >
                    Envoyer
                  </button>
                )}
              </div>
            </form>
            {/* </div> */}
          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <CoachItemDashboardBakeliste />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <BakelisteGroupItem />
              </div>
              {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                <NextReunionItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <SavedReunionListItem />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      // </div>
    );
  }
}

// export default App;

//   render() {
//     return <div className="component-ajouter-resume">Hello! component ajouterResume</div>;
//   }
// }
// export default connect(
//     ({ ajouterResume }) => ({ ...ajouterResume }),
//     dispatch => bindActionCreators({ ...ajouterResumeActions }, dispatch)
//   )( ajouterResume );
