import React, { Component } from "react";
import AddQuestion from "../addQuestion/index";
import { Link } from "react-router-dom";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import "./contentQuestions.css";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as contentQuestionnaireQuizActions from "../../store/contentQuestionnaireQuiz/actions";
export default class contentQuestions extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }

  render() {
    return (
      <div className="p-b-200 container-fluid px-md-3 p-0">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed"> 
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item ">
                  <Link to="/dashboard-coach">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Questions
                </li>
              </ol>
            </nav>

            <h6 className="h-theme ff-inter fw-600">Ajout Questions</h6>
            <div className="col dis-flex m-b-15 mto-3"></div>
            <AddQuestion />
          </div>

          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteGroupItemForCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ contentQuestionnaireQuiz }) => ({ ...contentQuestionnaireQuiz }),
//     dispatch => bindActionCreators({ ...contentQuestionnaireQuizActions }, dispatch)
//   )( contentQuestionnaireQuiz );
