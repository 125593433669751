import API2 from "../../../../variablesGlobales";
import API from "../../../../variablesGlobales";
import axios from "axios";
export const FETCH_SYLLABUS_BAKELISTE = "FETCH_SYLLABUS_BAKELISTE";
export const FETCH_TACHES_BY_SYLLABUS = "FETCH_TACHES_BY_SYLLABUS"

export const getSyllabusBakeliste = (bakelisteId) => {
    return (dispatch) => {
      return axios
        .get(API.API + "syllabus-bakeliste/" + bakelisteId)
        .then((response) => {
          dispatch({
            type: FETCH_SYLLABUS_BAKELISTE,
            payload: response.data.data,
          });
        })
        .catch((err) => console.log(err));
    };
  };


  export const  getTachesBySyllabus = (syllabusId) => {
    return (dispatch) => {
    return axios 
      .get(API.API + "all-details-syllabus/" + syllabusId)
      .then((response) => {
       dispatch({
         type: FETCH_TACHES_BY_SYLLABUS,
         payload: response.data.data,
       });
      })
      .catch((err) => console.log(err));
      };
  };