import React, {Component} from 'react';
import './detailExamenPratiqueCoachAssistantView.css';

import DetailsExamenCoachAssistant from '../detailsExamenCoachAssistant/index';
 import qs from "qs"; 

import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoachAssistant from "../navVerticalCoachAssistant/index";
import { Link } from 'react-router-dom';

// import * as detailsExamenCoachViewActions from "../../store/detailsExamenCoachView/actions";
export default class detailExamenPratiqueCoachAssistantView extends Component {
    constructor(props) {
        super(props);
        let examen_id = this.props.location?.state?.id;
    if (!examen_id && this.props.location.search) {
      const obj = qs.parse(this.props.location.search.replace("?", ""));
      examen_id = parseInt(obj.id);
    } 
        this.state = {
           examen_id: examen_id,  
        };
    }
    render() { 
      return(<div className="body-theme">
                 <HeaderDashboardCoach />
 
          <div className="container-fluid fixing-after-header">
            <div className="row">
              <div className="col-lg-2 p-0">
              <NavVerticalCoachAssistant />
              </div> 
              <div className="col-lg-10 pt-3">

                 
                <DetailsExamenCoachAssistant
                id_examen={this.state.examen_id}
                />
              </div>
            </div>
          </div>
        </div>)
    }
  }

// export default connect(
//     ({ detailsExamenCoachView }) => ({ ...detailsExamenCoachView }),
//     dispatch => bindActionCreators({ ...detailsExamenCoachViewActions }, dispatch)
//   )( detailsExamenCoachView );