import React, {Component} from 'react';
import './coachAjoutContenu.css';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ModalContactUs from '../modalContactUs/index';
import { Link } from "react-router-dom";
import {stateToHTML} from 'draft-js-export-html';
// import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
// import BakelisteGroupItem from "../bakelisteGroupItem/index"; 
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import Preloader from "../preloader/index";

import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";
import $ from "jquery";
import  API  from '../../variablesGlobales';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default class coachAjoutContenu extends Component {
   

    constructor(props) {
      super(props);
      this.state = {
        editorState: EditorState.createEmpty(),
        uploadedImages: [],
        show: false,
        api:API.API,
        isSendingResumenProgress: false,
        userEmail: window.sessionStorage.getItem("email"),
        userID: window.sessionStorage.getItem("user_id"),
        coach_id: window.sessionStorage.getItem("coach_id"),
        userFullName: window.sessionStorage.getItem("userFullName"),
        domaine_id: window.sessionStorage.getItem("domaine_id"),
        fields: {},
        isSuccessSending: false,
        isload: true,


      };
      this._uploadImageCallBack = this._uploadImageCallBack.bind(this);
      this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }
  componentDidMount = () => {
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("envoyerMonTravailComponent");
    setTimeout(() => {
      this.setState({
        isload: false,
      });
    }, 5000);
  }
    onEditorStateChange = (editorState) => {
      this.setState({
        editorState,
      });
    };


    handleChange = (e) => {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
    };
  
    _uploadImageCallBack(file) {
      // long story short, every time we upload an image, we
      // need to save it to the state so we can get it's data
      // later when we decide what to do with it.
  
      // Make sure you have a uploadImages: [] as your default state
      let uploadedImages = this.state.uploadedImages;
  
      const imageObject = {
        file: file,
        localSrc: URL.createObjectURL(file),
      }
  
      uploadedImages.push(imageObject);
  
      this.setState({ uploadedImages: uploadedImages })
  
      // We need to return a promise with the image src
      // the img src we will use here will be what's needed
      // to preview it in the browser. This will be different than what
      // we will see in the index.md file we generate.
      return new Promise(
        (resolve, reject) => {
          resolve({ data: { link: imageObject.localSrc } });
        }
      );
    }
    showTexte=()=>{
      this.setState({
        show: true
      })
      console.log(stateToHTML(this.state.editorState.getCurrentContent()));
      <p dangerouslySetInnerHTML={{ __html: this.state.editorState }}></p>
      alert("you resume is here", this.state.editorState)
      return (
        <p dangerouslySetInnerHTML={{ __html: this.state.editorState }}></p>
      )
    }
    amplitudeScript = (api_key) => {
      (function (e, t) {
        var n = e.amplitude || { _q: [], _iq: {} };
        var r = t.createElement("script");
        r.type = "text/javascript";
        r.integrity =
          "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
        r.crossOrigin = "anonymous";
        r.async = true;
        r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
        r.onload = function () {
          if (!e.amplitude.runQueuedFunctions) {
          }
        };
        var i = t.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(r, i);
        function s(e, t) {
          e.prototype[t] = function () {
            this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
            return this;
          };
        }
        var o = function () {
          this._q = [];
          return this;
        };
        var a = [
          "add",
          "append",
          "clearAll",
          "prepend",
          "set",
          "setOnce",
          "unset",
        ];
        for (var u = 0; u < a.length; u++) {
          s(o, a[u]);
        }
        n.Identify = o;
        var c = function () {
          this._q = [];
          return this;
        };
        var l = [
          "setProductId",
          "setQuantity",
          "setPrice",
          "setRevenueType",
          "setEventProperties",
        ];
        for (var p = 0; p < l.length; p++) {
          s(c, l[p]);
        }
        n.Revenue = c;
        var d = [
          "init",
          "logEvent",
          "logRevenue",
          "setUserId",
          "setUserProperties",
          "setOptOut",
          "setVersionName",
          "setDomain",
          "setDeviceId",
          "enableTracking",
          "setGlobalUserProperties",
          "identify",
          "clearUserProperties",
          "setGroup",
          "logRevenueV2",
          "regenerateDeviceId",
          "groupIdentify",
          "onInit",
          "logEventWithTimestamp",
          "logEventWithGroups",
          "setSessionId",
          "resetSessionId",
        ];
        function v(e) {
          function t(t) {
            e[t] = function () {
              e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
            };
          }
          for (var n = 0; n < d.length; n++) {
            t(d[n]);
          }
        }
        v(n);
        n.getInstance = function (e) {
          e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
          if (!n._iq.hasOwnProperty(e)) {
            n._iq[e] = { _q: [] };
            v(n._iq[e]);
          }
          return n._iq[e];
        };
        e.amplitude = n;
      })(window, document);
      amplitude.getInstance().init(api_key);
    };

    onAddResume = () => {

      this.setState({
        isSendingResumenProgress: true,
      });
      var eventProperties = {
        user_email: this.state.userEmail,
        user_name: this.state.userFullName,
      };
      var content = stateToHTML(this.state.editorState.getCurrentContent())
      var data = this.state.fields;
      var fd = new FormData();
      fd.append("content", content);
      fd.append("coach_id", this.state.coach_id);
      fd.append("content_type", data.content_type);
      fd.append("bakeliste_id", this.state.userID);
      fd.append("domaine_id", this.state.domaine_id);
      fd.append("fullName",this.state.userFullName );
      fd.append("title", data.title );
      axios
        .post(this.state.api + "textual-contents", fd, {
          Headers: { "Content-Type": "multipart/formdata" },
        })
        .then((response) => {
          // console.log(response);
          this.setState({
            isSendingResumenProgress: false,
          });
          if (response.data.success) {
            amplitude
            .getInstance()
            .logEvent(
              "ADD_CONTENT_BY_COACH",
              eventProperties
            );
            toast.success(
              "Contenu ajouté avec success",
              { position: toast.POSITION.BOTTOM_LEFT },
              { autoClose: 1000 }
            );
            window.location="/liste-contenus-coach";
          }
        })
        .catch((error) => {
          // console.log(error.message);
          this.setState({
            isSendingResumenProgress: false,
          });
          toast.error(
            "Erreur sur l'ajout du contenu",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        });
    };


    render() {

      const { editorState } = this.state;

        return (
      <Amplitude>
        
        {this.state.isload ? (

          <div>
            <Preloader />
          </div>
        ) : (
        
        <div className="p-b-200 ">
          <div className="row">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb fixed">
                <ol className="breadcrumb fixed">
                  <li className="breadcrumb-item ">
                    <Link to="coach-dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item ">
                    <Link to="liste-contenus-coach">Liste des contenus</Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >
                    Ajout de résumés
                  </li>
                </ol>
              </nav>
              <div className="row m-b-20 pt3">
                <div className="col m-b-15">
                  <h6 className="h-theme ff-inter fw-600">Rédaction de résumé</h6>
                </div>
                {/* <div className="col dis-flex m-b-15">
                  <ModalContactUs />
                </div> */}
              </div> 
              {/* <div className="card-dashboard ht"> */}
              <form>

              <div className="col-md-12 tc-input-item">
                              <div className="form-group ">
                                  <label >Choix du type de contenu :</label>
                                  <select className="form-control" id="ct-select" name="content_type" onChange={this.handleChange} required>
                                    <option> --Choisir un type de contenu-- </option>
                                    <option value="résumé"> Résumé de cours </option>
                                    <option value="article"> Article </option>
                                    <option value="tutoriel"> Tutoriel </option>
                                    <option value="rapport de stage"> Rapport de stage </option>
                                    <option value="cahier de charges"> Cahier de charges </option>
                                    <option value="backlog"> Backlog </option>
                                  </select>
                                  
                              </div>
                              
                          </div>
                          <div className="col-md-12 tc-input-item">
                                  <label >Titre :</label>
                                  <input type="text" className="form-control" id="title-input" placeholder="Titre" required name="title" onChange={this.handleChange} />
                              </div>
                              

                              <div className="col-md-12 tc-input-item">
                              <label >Contenu :</label>

                <Editor
                  className="ht"
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={this.onEditorStateChange}
                  name="content"
                  toolbar={{
                    inline: { inDropdown: false },
                    list: { inDropdown: false },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    image: { uploadCallback: this._uploadImageCallBack },
                    inputAccept: 'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel'
                  }}
                />
              </div>
              <div className="col-md-6 offset-md-10 m-t-25">
                {this.state.isSendingResumenProgress ? (
                    <button type="button" className="btn-theme-orange" disabled>
                      Envoie en cours &nbsp;
                      <FeatherIcon icon="loader" aria-hidden="true" />
                    </button>
                ) : (
                <button type="button" className="btn-theme" onClick={() => this.onAddResume()} >
                  Envoyer
                </button>
      )}
              </div>
              </form>
              {/* </div> */}
              
            </div>
            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              {/* <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <CoachItemDashboardBakeliste />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <BakelisteGroupItem />
              </div> */}
              {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                <NextReunionItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <SavedReunionListItem />
              </div> */}
            </div>
            </div>
        </div>
          </div>
     

      )};
      </Amplitude>

        )}
  }
  
  // export default App;





  //   render() {
  //     return <div className="component-ajouter-resume">Hello! component ajouterResume</div>;
  //   }
  // }
// export default connect(
//     ({ ajouterResume }) => ({ ...ajouterResume }),
//     dispatch => bindActionCreators({ ...ajouterResumeActions }, dispatch)
//   )( ajouterResume );