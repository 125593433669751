import React, {Component} from 'react';
import './listeContenusBakelisteView.css';
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import ListeContenusBakeliste from "../listeContenusBakeliste/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listeContenusBakelisteViewActions from "../../store/listeContenusBakelisteView/actions";
export default class listeContenusBakelisteView extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {}; 
    // }
    render() {
      return (   
      <div className="body-theme">
      <HeaderDashboard />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVertical />
          </div>
          <div className="col-lg-10 pt-3">
            <ListeContenusBakeliste />
          </div>
        </div>
      </div>
    </div>) }
  }
// export default connect(
//     ({ listeContenusBakelisteView }) => ({ ...listeContenusBakelisteView }),
//     dispatch => bindActionCreators({ ...listeContenusBakelisteViewActions }, dispatch)
//   )( listeContenusBakelisteView );