import React, { Component } from "react";
import "./faireExamenView.css";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";
import FaireExamen from "../faireExamen/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as faireQuizzViewActions from "../../store/faireQuizzView/actions";
export default class faireExamenView extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-faire-quizz-view">
        {" "}
        <div className="body-theme">
          <HeaderDashboardCoach />
          <div className="container-fluid fixing-after-header">
            <div className="row">
              <div className="col-lg-2 p-0">
                <NavVerticalCoach />
              </div>
              <div className="col-lg-10 pt-3">
                <FaireExamen />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ faireQuizzView }) => ({ ...faireQuizzView }),
//     dispatch => bindActionCreators({ ...faireQuizzViewActions }, dispatch)
//   )( faireQuizzView );
