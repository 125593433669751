import React, { Component } from "react";
import "./groupeCoachView.css";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";
import GroupeCoach from "../groupeCoach/index";
import Offlinecoach from "../offlinecoach/index";
import  Loader from "react-loader-spinner";
import Preloader from "../preloader/index";


export default class groupeCoachView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      show: false
    };
  }
 
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false,
        show: true
      });
    }, 4000);
  }
  render() {
    
    const checkConnexion = navigator.onLine ? (
      this.state.show?
      <GroupeCoach />  :null 
    ) : (
      <p>
        <Offlinecoach />
      </p>
    );
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoach />
            </div>
            
            <div className="col-lg-10 pt-3">
            {this.state.loading ? (
                <div>
 <Preloader />
              </div>
            )
               
             : null
            }
              {/* <GroupeCoach /> */}
              {checkConnexion}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ groupeCoachView }) => ({ ...groupeCoachView }),
//     dispatch => bindActionCreators({ ...groupeCoachViewActions }, dispatch)
//   )( groupeCoachView );
