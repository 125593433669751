import React, { Component } from "react";
import "./livraisonBakeliste.css";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItem from "../bakelisteGroupItem/index";
import NextReunionItem from "../nextReunionItem/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import LivrableBakelisteItem from "../livrableBakelisteItem/index";
import ModalContactUs from "../modalContactUs/index";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import { Link } from "react-router-dom";
import Preloader from "../preloader/index";
import ListCoachAssistant from "../listCoachAssistant/index";
import API from "../../variablesGlobales";
// import amplitudeScript from "../../amplitudeScript";
import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";
import Loader from "react-loader-spinner";
import EnvoyerMonTravail from "../envoyerMonTravail/index";
export default class livraisonBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      taches: [],
      loading: true,
      detailsSyllabus: [],
      livraison:[],
      bakeliste_id: sessionStorage.getItem("user_id"),
      eventeDroite: "ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_V2_DROITE",

      // amplitudeScript: amplitudeScript.amplitudeScript,
    };
    Sentry.init({
      dsn:
        "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
    });
  }

  componentDidMount() {
    this.getTachesBakeliste();
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("visitedBakelisteLivraisonPage");
    setTimeout(() => {
      this.setState({ loading: false });
    }, 4000);
  }
  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);

    amplitude.getInstance().init(api_key);
  };
  
  // addReactiononLivrable = (fields, item) => {
  //   const livraison = item;
  //   var eventProperties = {
  //     user_id: this.state.bakeliste_id,
  //     email: this.state.email,
  //   };
  //   console.log(fields,"fields")
   

  //   // fd.append("tache_content", )
  //   // console.log(fd,"fields")

  //   axios
  //     .post(this.state.api + "comment/store",fields)
  //     .then((response) => {
  //       amplitude
  //         .getInstance()
  //         .logEvent("COMMENTAIRE_LIVRAISON_V2", eventProperties);
  //       const data = response.data.data;
  //       data.fullname = this.state.userFullName;

  //       // const item1 = livraison.find((item) => item.id === data.commentable_id);
  //       item.comments.push(data);
  //       const livraisonsSyllabus = [...this.state.livraisonsSyllabus];
  //       const index = livraisonsSyllabus.findIndex((it) => it.id === item.id);
  //       livraisonsSyllabus[index].livraisons = livraison;
  //       this.setState({ livraisonsSyllabus });
  //       var event = {
  //         user_id: this.state.bakeliste_id,
  //         email: this.state.email,
  //       };
  //       this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
  //       amplitude
  //         .getInstance()
  //         .logEvent("COMMENTAIRE_LIVRAISON_BAKELISTE_V2", event);
  //     })

  //     .catch((error) => {
  //       // console.log(error);
  //     });
  // };

  getTachesBakeliste = () => {
    axios
      .get(this.state.api + "taches-by-bakeliste/" + this.state.bakeliste_id)
      .then((response) => {
        this.setState({
          taches: response.data.data,
          detailsSyllabus: response.data.detailsSyllabus,
        }); 
        // console.log(this.state.detailsSyllabus,"dsid")
        // this.detailsSyllabus.map((index, item) => {
        //   this.setState({
        //     livraison:item.livraison
        //   })
        // })
        const livraison = response.data.detailsSyllabus.map((d) => ({
          value: d.livraisons,
        }));
        // const Commentaires = response.data.detailsSyllabus.livraisons.comments
      //  console.log("tache", this.state.detailsSyllabus);
        // console.log("detailsdddd syllabus", this.state.detailsSyllabus);
        // console.log("eeeee",Commentaires);

        // console.log("livr",livraison);

        var eventProperties = {
          user_id: this.state.bakeliste_id,
          nombre_taches:
            this.state.taches.length + this.state.detailsSyllabus.length,
        };
        amplitude
          .getInstance()
          .logEvent("BAKELISTE_NUMBER_TACHES_V2", eventProperties);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  getFichiersLivraisons = (data) => {
    const ok = data.map((i, ix) => {
      return i.fichiers;
    });
    // console.log("ok", ok);
    return ok;
  };
  getCommentsLivraisons = (data) => {
    const ok = data.map((i, ix) => {
      return i.comments.reverse();
    });
    // console.log("ok", ok);
    return ok;
  };
  render() {
    // const { loading } = this.state;

    return this.state.loading ? (
      <div>
        <Preloader />
      </div>
    ) : (
      <Amplitude>
        <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
          <div className="row">
         
            <div className="col-lg-9 livrason">
            <div>
                <nav aria-label="breadcrumb fixed">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item ">
                      <Link to="/dashboard-bakeliste">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Livraisons
                    </li>
                  </ol>
                </nav>
              </div>
              {/*  */}
              <div className="row m-b-15 pt3">
                <div className="col m-b-15">
                  <h6 className="h-theme ff-inter fw-600">Livraison</h6>
                </div>
                <div className="col dis-flex m-b-15">
                  <ModalContactUs />
                </div>
              </div>
               
              
              <section> 

                {this.state.detailsSyllabus !== undefined ? (
                  this.state.detailsSyllabus.map((item, index) => (
                    <LivrableBakelisteItem
                      key={index}
                      tache_number={index + 1}
                      // onSubmitComment={this.addReactiononLivrable} 
                      getTachesBakeliste={this.getTachesBakeliste}
                      fichiers={this.getFichiersLivraisons(item.livraisons)}
                      commentaires={this.getCommentsLivraisons(item.livraisons)}
                      id={item.id}
                      iteme={item}
                      // commentaires={item.livraisons.comments !== null && item.livraisons.comments}
                      titre={item.syllabus_task}
                      date_tache={item.syllabus_completion_time}
                      livraisons={item.livraisons}
                      syllabus={"syllabus"}
                    /> 
                  ))
                ) : (
                  <div>Aucune livraison pour le moment</div>
                )}

                {/* {this.state.taches.map((item, index) => (
                  <LivrableBakelisteItem
                    key={index}
                    tache_number={index + 1}
                    fichiers={this.getFichiersLivraisons(item.livraisons)}
                    id={item.id}
                    titre={item.content}
                    date_tache={item.todo_date}
                    livraisons={item.livraisons}
                  />
                ))} */}
              </section>
            </div>

            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                  <CoachItemDashboardBakeliste />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                  <BakelisteGroupItem />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <ListCoachAssistant />
              </div>
                {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                  <NextReunionItem />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Amplitude>
    );
  }
}
// export default connect(
//     ({ livraisonBakeliste }) => ({ ...livraisonBakeliste }),
//     dispatch => bindActionCreators({ ...livraisonBakelisteActions }, dispatch)
//   )( livraisonBakeliste );
