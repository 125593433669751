import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import HeaderDashboard from "../headerDashboard/index";
import ModalContactUs from "../modalContactUs/index";
// import SweetAlert from "react-bootstrap-sweetalert";
import ListCoachAssistantRefact from "../listCoachAssistant/ListCoachAssistantRefact";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItemRefact from "../bakelisteGroupItem/BakelisteGroupItemRefact";
import NextReunionItem from "../nextReunionItem/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import "./demandeAbsence.css";
// import NavVertical from "../navVertical/index";
import FeatherIcon from "feather-icons-react";
import API from "../../variablesGlobales";
// import EnvoyerMonTravail from "../envoyerMonTravail/index";
import axios from "axios";
import * as Sentry from "@sentry/react";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Tooltip } from "@material-ui/core";
import $ from "jquery";
import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from "react-bootstrap-table";
// import { options } from "amplitude-js";
import { toast } from "react-toastify"; 
toast.configure();


// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as demandeAbsenceActions from "../../store/demandeAbsence/actions";
export default class demandeAbsence extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),
    date = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate();

    this.state = {
      show: false,
      fields: {},
      utInProgress: false,
      api: API.API,
      userID: window.sessionStorage.getItem("user_id"),
      isSendingDemandeInProgress: false,
      isSuccessSending: false,
      isErrorSending: false,
      demande: [],
      alldemande: [],
      eventeDroite: "ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_V2_DROITE",
      demandeById:[],
      isEditingInProgress: false,
      demandeId: "",
      motif: "",
      contenu: "",
      date_debut: "",
      date_fin: "",
      showAlertEditSuccess: false,
      showAlertEditError: false,
      currentDate: date,
      showDate: false,

    };
   // console.log((this.state.currentDate))

  }


  open = (size) => {
    this.setState({
      size,
      show: true,
    });
  };

  close = () => {
    this.setState({
      show: false,
    });
  };
  componentDidMount() {
    this.getDemande();
    // this.getDemandeById();
  }

  demandeStateFormatter(cell, row) {
    return (
      <span>
        {row.status === "rejete" && (
          <span
            className="label label-danger"
            data-placement="top"
            
          
          >
            Rejetée
          </span>
        )}
        {row.status === "nouveau" && (
          <span
            className="label label-warning"
            data-placement="top"
          >
            Nouvelle
          </span>
        )}
        {row.status === "accepte" && (
          <span
            className="label label-success"
            data-placement="top"
          >
            Accéptée
          </span>
        )}
      </span>
    );
  } 

  frenchShortFormatDate = (datetoformat) => {
    const date = new Date(datetoformat);
    return date.toLocaleString("fr-FR", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  getDemande = () => {
    axios
      .get(this.state.api + "demande_absences/bakeliste/" + this.state.userID)
      .then((res) => {
        const demandes = res.data;
        this.setState({
          demande: demandes.data.reverse(),
        });
        // console.log(this.state.demande);
      })
      .catch((error) => {
        // console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  onSubmitEditDemande = (e) => {
    e.preventDefault();
    // console.log("gooo");
    this.setState({
      isEditingInProgress : true,
    });
    
    var fd = new FormData();
    fd.append("motif", this.state.motif);
    fd.append("bakeliste_id" , this.state.userID)
    fd.append("contenu", this.state.contenu);
    fd.append("date_debut", this.state.date_debut);
    fd.append("date_fin", this.state.date_fin);
    // console.log(this.state.motif, this.state.contenue)
    axios
      .put(this.state.api + "demande_absences/" + this.state.demandeId ,fd,{
        Headers: { "Content-Type": "multipart/formdata" },
      }) 
      .then((response) => {
        // console.log("gooo1");
        // console.log(response);
        if (response.data.success) {
          // $(".close-modal-bakeliste-title").click();

          this.setState({
            isEditingInProgress: false,
            // showAlertEditSuccess: true
          });
          toast.success(
            "Demande d'absence modifiée avec succes",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1500 }
          );
          this.getDemande();
          $(".close-modal-bakeliste-title").click();
        } else {
          this.setState({
            isEditingInProgress: false,
            // showAlertEditError: true,
          });
          toast.error(
            "Erreur sur modification de la demande",
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 1500 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  onDemandeInfos(infosdemande) {
    this.setState({
      // demande: infosdemande,
      demandeId: infosdemande.id,
      motif: infosdemande.motif,
      contenu: infosdemande.contenu,
      date_debut: infosdemande.date_debut,
      date_fin: infosdemande.date_fin,
    });
  }

  // getDemandeById = (id) => {
   
  //   axios
  //     .get(this.state.api + "demande_absences/" + id)
  //     .then((res) => {
  //       const demande = res.data;
  //       this.setState({
  //         demandeById: demande.data,
  //       });
  //       console.log(this.state.demandeById.date_debut, "eeee");
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //       if (process.env.NODE_ENV !== "production") {
  //         return;
  //       }
  //       Sentry.captureException(error);
  //     });
  // };

 

  handleChange = (e) => {
    
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if (fields.motif === "absence"){
      this.setState({
        showDate:true
      })
    }
    fields["bakeliste_id"] = this.state.userID;
    this.setState({
      fields: fields,
    });
  };

  onSubmitDemande = (e) => {
    e.preventDefault();

    //if(this.onValidateDemandesFormData()){
    this.setState({
      isSendingDemandeInProgress: true,
    });

    var api = this.state.api;
    var data = this.state.fields;

    var fd = new FormData();
    fd.append("motif", data.motif);
  //  console.log("motif:", data.motif);
  
    fd.append("contenu", data.contenu);
    fd.append("date_debut", data.date_debut);
    fd.append("date_fin", data.date_fin);
    fd.append("bakeliste_id", this.state.userID);
    axios
      .post(api + "demande_absences", fd, {
        Headers: { "Content-Type": "multipart/formdata" },
      })
      .then((response) => {
        //console.log(response);
        this.setState({
          isSendingDemandeInProgress: false,
        });
        document.getElementById("submit-demande").reset();

        if (response.data.success) {
          this.getDemande();
          $("#motif").val("");
          $("#contenu").val("");
          $("#date_debut").val("");
          $("#date_fin").val("");
          this.setState({
            isSuccessSending: true,
            show: false,
          });
          //alert("Demande envoyé avec succès !")

          setTimeout(() => {
            this.setState({
              isSuccessSending: false,
            });
          }, 6000);
        }
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          isSendingDemandeInProgress: false,
          isErrorSending: true,
        });
        setTimeout(() => {
          this.setState({
            isErrorSending: false,
          });
        }, 6000);
      });
  };

  createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton style={{ background: "#009688" }} btnText="Export CSV" />
    );
  };
  // rowClassNameFormat = (row, cell) => {
  //   return row.status === 'nouveau' ? 'changed-status-demande' : '';
  // }

  actionsFormatter = (cell, row) => {

    // console.log(row.id)
    // let id = row.id

    return (
      
      row.status === "nouveau" ? (
      <span>
        <Tooltip title="Modifier">
          <EditIcon  className="btn-icon-table editColor" cursor="pointer"  data-toggle="modal"
          data-target="#editDemandeAbsence"  onClick={() => this.onDemandeInfos(row)} />
        </Tooltip>
        
     
       <Tooltip title="Voir demande">
         <VisibilityIcon  className="btn-icon-table voirColor" cursor="pointer"  data-toggle="modal"
         data-target="#voirDemandeAbsence"  onClick={() => this.onDemandeInfos(row)} />
       </Tooltip>
     </span>

      ) : (
        <span>
        <Tooltip title="Voir demande">
          <VisibilityIcon  className="btn-icon-table voirColor" cursor="pointer"  data-toggle="modal"
          data-target="#voirDemandeAbsence"  onClick={() => this.onDemandeInfos(row)} />
        </Tooltip>
      </span>
      )
      
    );
  };

  render() {
    const options = {
      exportCSVBtn: this.createCustomExportCSVButton,
    };
    return (
      <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed ">
                <li className="breadcrumb-item ">
                  <Link to="coach-dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Demande d'absence 
                </li>
              </ol>
            </nav>
            <div className="row m-b-15 pt3">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600">Demande d'absence</h6>
              </div>
              <div className="col dis-flex m-b-15">
                <ModalContactUs />
              </div>
            </div>

            <div className="card-dashboard p-md-5 p-sm-5 m-b-20">
              <div className="col-md-4 offset-md-8">
                <button
                  className="btn-theme text-up w-full "
                  onClick={() => this.open("md")}
                >
                  <FeatherIcon
                    icon="mail"
                    className="icon-btn-livrable-bakeliste-item"
                  />
                  Faire une demande
                </button>
              </div>

              <br />

              {this.state.show ? (
                <form
                  id="submit-demande"
                  encType="multipart/form-data"
                  onSubmit={(e) => this.onSubmitDemande(e)}
                >
                  <div className="form-group m-b-45">
                    <label>Motif de votre absence</label>

                      
                    <select className="form-control form-custom-input"  name="motif" id="pet-select" onChange={this.handleChange}>
                        <option value="">--Séléctionner un motif--</option>
                        <option value="absence">Demande d'absence</option>
                        <option value="Changement-horaire">Changement horaire</option>
                    </select>
                    {/* <input
                      id="motifd"
                      required
                      onChange={this.handleChange}
                      name="motif"
                      className="form-control form-custom-input"
                      aria-describedby="emailHelp"
                      type="text"
                      placeholder="Motif de l'absence"
                    ></input> */}
                  </div>
                  <div className="form-group m-b-45">
                    <label>Contenu de la demande</label>
                    <textarea
                      id="contenud"
                      required
                      onChange={this.handleChange}
                      name="contenu"
                      className="form-control form-custom-input"
                      aria-describedby="emailHelp"
                      placeholder=""
                    ></textarea>
                  </div>
                  {this.state.showDate === true ? (
                  
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group m-b-45">
                        <label>Date de début</label>
                        <input
                          id="date_debutd"
                          type="date"
                          required
                          onChange={this.handleChange}
                          name="date_debut"
                          className="form-control form-custom-input"
                          // aria-describedby="emailHelp"
                          min="2021-01-01" max="2021-12-31"
                        ></input>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group m-b-45">
                        <label>Date de fin</label>
                        <input
                          id="date_find"
                          type="date"
                          required
                          onChange={this.handleChange}
                          name="date_fin"
                          className="form-control form-custom-input"
                          // aria-describedby="emailHelp"
                          min="2021-01-01" max="2021-12-31"
                        ></input>
                      </div>
                    </div>
                  </div>
                  ):(
                    <>
                    </>
                  )}
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                        onClick={() => this.close("md")}
                      >
                        ANNULER
                      </button>
                    </div>
                    <div className="col-md-6">
                      {this.state.isSendingDemandeInProgress ? (
                        <button
                          type="submit"
                          className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                          disabled
                        >
                          Envoie en cours &nbsp;
                          <FeatherIcon icon="loader" aria-hidden="true" />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                        >
                          ENVOYER
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              ) : (
                <>
                  <BootstrapTable
                    data={this.state.demande}
                    striped={true}
                    hover={true}
                    condensed={true}
                    multiColumnSort={2}
                    options={options}
                    pagination
                    exportCSV
                    // trClassName={this.rowClassNameFormat}

                  >
                    <TableHeaderColumn
                      dataField="id"
                      isKey={true}
                      hidden={true}
                    >
                      ID
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="motif"
                      filter={{ type: "TextFilter", delay: 1000 }}
                      width="100"
                      dataSort={true}
                    >
                      Motif
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="date_debut"
                      dataFormat={this.frenchShortFormatDate.bind(this)}
                      filter={{ type: "TextFilter", delay: 1000 }}
                      dataSort={true}
                      width="160"
                    >
                      Date de début
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="date_fin"
                      dataFormat={this.frenchShortFormatDate.bind(this)}
                      dataSort={true}
                      width="160"
                      filter={{ type: "TextFilter", delay: 1000 }}
                    >
                      Date de fin
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="status"
                      dataFormat={this.demandeStateFormatter.bind(this)}
                      dataSort={true}
                      width="100"
                      filter={{ type: "TextFilter", delay: 1000 }}
                      >
                      Status
                    </TableHeaderColumn>
                    <TableHeaderColumn 
                       
                      width="110" 
                      dataFormat={this.actionsFormatter.bind(this)}

                      >
                        Actions
                    </TableHeaderColumn>

                  </BootstrapTable>
                  <div
          className="modal fade"
          id="voirDemandeAbsence"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="voirDemandeAbsenceTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-content-bakeliste">
              <h5
                className="modal-bakeliste-title m-b-50"
                id="voirDemandeAbsenceTitle"
              >
                Détails de la demande
              </h5>
              <button
                type="button"
                className="close-modal-bakeliste-title"
                data-dismiss="modal"
                aria-label="Close"
              >
                <FeatherIcon icon="x" aria-hidden="true" />
              </button>
              <form
                // encType="multipart/form-data"
                
                // onSubmit={(e) => this.onSubmitEditDemande(e)}
               
              >
                <div className="form-group">
                  Motif
                 <div
                    id="tache_id"
                    name="motif"
                    
                    className="form-control select-modal-content-bakeliste"
                  
                >
                 {this.state.motif}
                 </div>
                
                </div>
                <div className="form-group">
                  Contenu
                  <p
                  >{this.state.contenu}</p>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    Date de début : 
                    <label
                      id="tache_file"
                      name="date_debut"
                      type="date"
                      className="form-control select-modal-content-bakeliste"
                      >
                      {this.frenchShortFormatDate(this.state.date_debut)}
                    </label>
                  </div>
                  <div className="form-group col-md-6">
                    Date de fin :
                    <label
                      id="tache_file"
                      xvalue={this.frenchShortFormatDate(this.state.date_fin)}
                      
                      name="date_debut"
                      type="date"
                      className="form-control select-modal-content-bakeliste"
                    >
                      {this.frenchShortFormatDate(this.state.date_debut)}
                    </label>
                  </div>
                  </div>               
              </form>

            
            </div>
          </div>
        </div>
      
                  {/* <SweetAlert
                      show={this.state.showAlertEditSuccess}
                      onConfirm={() =>
                        this.setState({ showAlertEditSuccess: false })
                      }
                      success
                      title="Demande d'absence modifié avec succes!!!"
                      style={{
                        color: "#069A77",
                        fontSize: "12px",
                        fontFamily: "Work Sans, sans-serif",
                        paddingTop: 60,
                        paddingBottom: 40,
                      }}
                      confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                    />
                   
                    <SweetAlert
                      show={this.state.showAlertEditError}
                      onConfirm={() =>
                        this.setState({ showAlertEditError: false })
                      }
                      error
                      title="Erreur sur la modification de votre demande, veuillez reessayer!!!"
                      style={{
                        color: "#d9534f",
                        fontSize: "12px",
                        fontFamily: "Work Sans, sans-serif",
                        paddingTop: 60,
                        paddingBottom: 40,
                      }}
                      confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                    /> */}
                  <div
          className="modal fade"
          id="editDemandeAbsence"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editDemandeAbsenceTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-content-bakeliste">
              <h5
                className="modal-bakeliste-title m-b-50"
                id="editDemandeAbsenceTitle"
              >
                Modifier votre demande d'absense
              </h5>
              <button
                type="button"
                className="close-modal-bakeliste-title"
                data-dismiss="modal"
                aria-label="Close"
              >
                <FeatherIcon icon="x" aria-hidden="true" />
              </button>
              <form
                encType="multipart/form-data"
                
                onSubmit={(e) => this.onSubmitEditDemande(e)}
               
              >
                <div className="form-group">
                  Motif
                 <input
                    id="tache_id"
                    name="motif"
                    type="text"
                    className="form-control select-modal-content-bakeliste"
                    value={this.state.motif}
                    onChange={(e) =>
                      this.setState({
                        motif: e.target.value,
                      })
                    }
                />
                
                </div>
                <div className="form-group">
                  Contenu
                  <textarea
                    className="form-control input-modal-content-bakeliste p-t-15"
                    rows="9"
                    // placeholder="Ajouter un commentaire"
                    // name="comment"
                    value={this.state.contenu}
                    onChange={(e) =>
                      this.setState({
                        contenu: e.target.value,
                      })
                    }
                    name="contenu"
                  ></textarea>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    Date de début : {this.frenchShortFormatDate(this.state.date_debut)}
                    <input
                      id="tache_file"
                      
                      // value={this.state.demandeById.date_debut}
                      value={this.state.date_debut}
                      onChange={(e) =>
                        this.setState({
                          date_debut: e.target.value,
                        })
                      }
                      name="date_debut"
                      type="date"
                      className="form-control select-modal-content-bakeliste"
                      placeholder="Votre livrable"
                      // min="2021-01-01"
                      // max="2021-12-31"



                    />
                  </div>
                  <div className="form-group col-md-6">
                    Date de fin :{this.frenchShortFormatDate(this.state.date_fin)}
                    <input
                      id="tache_file"
                      value={this.state.date_fin}
                      onChange={(e) =>
                        this.setState({
                          date_fin: e.target.value,
                        })
                      }
                      name="date_debut"
                      type="date"
                      className="form-control select-modal-content-bakeliste"
                      placeholder="Votre livrable"
                    />
                  </div>
                  </div>
                {this.state.isEditingInProgress ? (
                  <button
                    type="submit"
                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                    disabled
                  >
                    Envoie en cours &nbsp;
                    <FeatherIcon icon="loader" aria-hidden="true" />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                  >
                    ENVOYER
                  </button>
                )}
               
              </form>

            
            </div>
          </div>
        </div>
      
                </>
              )}
            </div>
          </div>
          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
                <CoachItemDashboardBakeliste />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
                <BakelisteGroupItemRefact />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
              <ListCoachAssistantRefact />
              </div>
              {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                <NextReunionItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <SavedReunionListItem />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ demandeAbsence }) => ({ ...demandeAbsence }),
//     dispatch => bindActionCreators({ ...demandeAbsenceActions }, dispatch)
//   )( demandeAbsence );
