import React, { Component } from "react";
import "./cvBakelisteView.css";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoach from "../navVerticalCoach/index";
import DashboardCoachAssistant from "../dashboardCoachAssistant/index";
import NavVerticalCoachAssistant from "../navVerticalCoachAssistant/index";
import CvBakeliste from "../cvBakeliste/index";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as cvBakelisteViewActions from "../../store/cvBakelisteView/actions";
export default class cvBakelisteView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.location.state.id,
      first_name: this.props.location.state.data.first_name,
      last_name: this.props.location.state.data.last_name,
    };
    console.log("location", this.props.location);
  }
  render() {
    return (
      <div className="component-cv-bakeliste-view">
        {" "}
        <div className="body-theme">
          <HeaderDashboardCoach />
          <div className="container-fluid fixing-after-header">
            <div className="row">
              <div className="col-lg-2 p-0">
                <NavVerticalCoachAssistant />
              </div>

              <div className="col-lg-10 pt-3">
                <CvBakeliste
                  id={this.state.id}
                  first_name={this.state.first_name}
                  last_name={this.state.last_name}
                />
                {/* </>}  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ cvBakelisteView }) => ({ ...cvBakelisteView }),
//     dispatch => bindActionCreators({ ...cvBakelisteViewActions }, dispatch)
//   )( cvBakelisteView );
