import React, { Component } from "react";
import "./dashboardBakelisteView.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import DashboardBakeliste from "../dashboardBakeliste/index";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Preloader from "../preloader/index";
import amplitude from "amplitude-js";
// import { Amplitude } from "@amplitude/react-amplitude";

export default class dashboardBakelisteView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      groupe_id: sessionStorage.getItem("sous_groupe_id"),
      email: window.sessionStorage.getItem("email"),
      user_id: window.sessionStorage.getItem("user_id"),
    };
  }

  componentDidMount() {
    var eventProperties = {
      user_email: this.state.email,
      user_id: this.state.user_id,
    };
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude
      .getInstance()
      .logEvent("VISITED_DASHBOARD_BAKELISTE_V2", eventProperties);
    // console.log(this.state.groupe_id);
    // <!--Start of Tawk.to Script-->

    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/60c9feb47f4b000ac037ee63/1f8aglsa4";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
    // <!--End of Tawk.to Script-->
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 4000);
  }

  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };

  render() {
    return (
      <div className="body-theme">
        <HeaderDashboard />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVertical />
            </div>
            <div className="col-lg-10 pt-3">
              <DashboardBakeliste />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ dashboardBakelisteView }) => ({ ...dashboardBakelisteView }),
//     dispatch => bindActionCreators({ ...dashboardBakelisteViewActions }, dispatch)
//   )( dashboardBakelisteView );
