import React, { Component } from "react";
import EditQuestionCoachAssistant from "../editQuestionCoachAssistant/index";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoachAssistant from "../navVerticalCoachAssistant/index";
import "./viewEditQuestionCoach.css";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as viewModifierQuestionActions from "../../store/viewModifierQuestion/actions";
export default class viewEditQuestionCoach extends Component {
  constructor(props) {
    super(props);
    // console.log(this.props.location.state.id);
    this.state = {
      question_id: this.props.location?.state?.id,
    };
  }
  render() {
    return (
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoachAssistant />
            </div>
            <div className="col-lg-10 pt-3">
              <EditQuestionCoachAssistant id={this.state.question_id} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ viewModifierQuestion }) => ({ ...viewModifierQuestion }),
//     dispatch => bindActionCreators({ ...viewModifierQuestionActions }, dispatch)
//   )( viewModifierQuestion );
