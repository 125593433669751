import React, { useEffect, useRef, useState, useMemo } from "react";
import "./listeTacheBakeliste.css";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItemRefact from "../bakelisteGroupItem/BakelisteGroupItemRefact";
import NextReunionItem from "../nextReunionItem/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import FeatherIcon from "feather-icons-react";
import TacheItem from "../tacheItem/index";
import TacheItemSyllabus from "../tacheItemSyllabus/index";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import Preloader from "../preloader/index";
import ListCoachAssistantRefact from "../listCoachAssistant/ListCoachAssistantRefact";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import ModalContactUs from "../modalContactUs/index";
import $ from "jquery";
import API from "../../variablesGlobales";
import base_api_url from "../../variablesGlobales";
import frenchFormatDate from "../frenchFormatDate";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "react-bootstrap/Pagination";
import Dropzone from "react-dropzone-uploader";
import { Modal } from "rsuite";
import { Amplitude } from "@amplitude/react-amplitude";
import amplitude from "amplitude-js";
import Loader from "react-loader-spinner";
import ReactPlayer from "react-player";
import EnvoyerMonTravail from "../envoyerMonTravail/index";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  fetchMoreData,
  getDetailsSyllabus,
} from "../LivraisonRefactor/redux/actions/tacheBakelistesActions";
import PaginationRefac from "../pagination/PaginationRefac";

// require("bootstrap/less/bootstrap.less");
let PageSize = 10;
const ListeTacheBakelisteRefac = (props) => {
  const dispatch = useDispatch();
  // INITIAL STATES

  /* Syllabus Tasks */
  const [taskSyllabusTodo, setTaskSyllabusTodo] = useState([]);
  const [taskSyllabusDoing, setTaskSyllabusDoing] = useState([]);
  const [taskSyllabusDone, setTaskSyllabusDone] = useState([]);
  const [all, setAll] = useState([]);

  /* Tasks */
  const [taskTodo, setTaskTodo] = useState([]);
  const [taskDoing, setTaskDoing] = useState([]);
  const [taskDone, setTaskDone] = useState([]);
  const [taskStatus, setTaskStatus] = useState("");
  const [totalTodo, setTotalTodo] = useState("");
  const [currentListTodo, setCurrentListTodo] = useState([]);

  /* Status */
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [statusNotUpdated, setStatusNotUpdated] = useState(false);
  const [statusSyllabusUpdated, setStatusSyllabusUpdated] = useState(false);
  const [statusSyllabusNotUpdated, setStatusSyllabusNotUpdated] =
    useState(false);

  /* Selected Tasks */
  const [selectedTacheS, setSelectedTacheS] = useState([]);

  /* Livrables */
  const [showlivrable, setShowlivrable] = useState(false);
  const [showlivrableSyllabus, setShowlivrableSyllabus] = useState(false);
  const [sizelivrableSyllabus, setSizelivrableSyllabus] = useState();
  const [isSendingLivrableInProgress, setIsSendingLivrableInProgress] =
    useState(false);
  const [isSendingInProgress, setIsSendingInProgress] = useState(false);

  /* Alerts */
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);

  /* Other */
  const [countTaskTodo, setCountTaskTodo] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(5);
  const [current_Page, setCurrent_Page] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [userNotConnected, setUserNotConnected] = useState(false);
  const [rechercheTache, setRechercheTache] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [isload, setIsload] = useState(true);
  const [loading, setLoading] = useState();
  const [errors, setErrors] = useState();
  const isValidForm = false;
  const [files, setFiles] = useState([]);
  const [filess, setFiless] = useState([]);
  const [fields, setFields] = useState({});
  const [fieldss, setFieldss] = useState({});
  const base_api = base_api_url.base_api_url;

  // Storage variables
  const bakeliste_id = sessionStorage.getItem("user_id");
  const email = sessionStorage.getItem("email");
  const coach_id = sessionStorage.getItem("coach_id");

  Sentry.init({
    dsn: "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
  });

  // UseEffect
  useEffect(() => {
    dispatch(getDetailsSyllabus(bakeliste_id));
    // Sentry.captureException(err);
    getDetails();
    setTimeout(() => {
      setIsload(false);
    }, 3000);
  }, [dispatch, bakeliste_id]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return props.taches.all_taches?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, props.taches.all_taches]);

  // getDetails function
  const getDetails = () => {
    const recherchetache = rechercheTache.trim().toLocaleLowerCase();
    const syllabusDetails = currentTableData;
    if (recherchetache.length < 1) {
      return syllabusDetails;
    }
    return syllabusDetails.filter((s) => {
      return s.syllabus_task.toLocaleLowerCase().includes(recherchetache);
    });
  };

  // SendLivrables
  const onValidateLivrablesFormData = () => {
    let datafields = fields;
    let dataerrors = errors;
    var isValidForm = true;

    setErrors(dataerrors);

    return isValidForm;
  };

  // SendLivrables function
  const onValidateLivrables = () => {
    let datafieldss = fieldss;
    let dataerrors = errors;
    var isValidForm = true;

    setErrors(dataerrors);

    return isValidForm;
  };

  const sendLivrables = (e) => {
    e.preventDefault();
    if (onValidateLivrables()) {
      setIsSendingInProgress(true);

      const id = bakeliste_id;
      const emaill = email;

      var dataS = fieldss;
      // console.log("datass", dataS);
      var datafiless = filess;
      // console.log("filesshhhhhhh", filess);

      //   var eventProperties = {
      //     user_id: id,
      //     email: emaill,
      //     details_syllabus_id: dataS.details_syllabus_id,
      //   };
      if (navigator.onLine) {
        datafiless.map((image) => {
          var fd = new FormData();

          fd.append("commentaire", dataS.comment);
          fd.append("bakeliste_user_id", bakeliste_id);
          fd.append("details_syllabus_id", dataS.details_syllabus_id);

          fd.append("tache_files", image);
          axios
            .post(API.API + "livraisons", fd, {
              Headers: { "Content-Type": "multipart/formdata" },
            })
            .then((response) => {
              //  console.log("response", response)
              setIsSendingInProgress(false);

              if (response.data.success) {
                // $("#tache_file").val("");
                $(".close-modal-bakeliste-title").on("click");
                setTimeout(() => {
                  setAlertSuccess(true);
                  setShowlivrable(false);
                }, 1000);
                // amplitude
                //   .getInstance()
                //   .logEvent(
                //     "ENVOIE_LIVRABLE_BAKELISTE_SUCCESS_TACHES_V2",
                //     eventProperties
                //   );
              } else {
                setAlertError(true);
                // setTimeout(() => {
                //   this.setState({
                //     isErrorSending: false,
                //   });
                // }, 1000);
                // amplitude
                //   .getInstance()
                //   .logEvent(
                //     "ENVOIE_LIVRABLE_BAKELISTE_ERROR_V2",
                //     eventProperties
                //   );
              }
              $(".close-modal-bakeliste-title").on("click");
            })
            .catch((error) => {
              if ((error.message = "Request failed with status code 422")) {
              }
              //   amplitude
              //     .getInstance()
              //     .logEvent(
              //       "ENVOIE_LIVRABLE_BAKELISTE_ERROR_V2",
              //       eventProperties
              //     );

              setIsSendingInProgress(false);
              setAlertError(true);
              if (process.env.NODE_ENV !== "production") {
                return;
              }
              //   Sentry.captureException(error);
            });
        });
        $(".close-modal-bakeliste-title").on("click");

        setTimeout(() => {
          setAlertSuccess(true);
          setShowlivrable(false);
        }, 1000);
      } else {
        setUserNotConnected(true);
        // amplitude
        //   .getInstance()
        //   .logEvent("ERROR_CONNEXION_ENVOIE_LIVRABLE_V2", eventProperties);
      }
    }
  };

  // handleChange
  const handleChangeS = (e) => {
    let datafieldss = fieldss;
    datafieldss[e.target.name] = e.target.value;
    setFieldss(datafieldss);
  };

  const handleChangeSyllabus = (e) => {
    let datafieldss = fieldss;
    datafieldss[e.target.name] = e.target.value;
    setFieldss(datafieldss);
  };

  const fileLivrableValidation = (elementID) => {
    var fileInput = document.getElementById(elementID);

    var filePath = fileInput.value;

    // Allowing file type
    var allowedExtensions = /(\.png|\.jpg|\.jpeg|)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  };

  const onDrop = (datafiles) => {
    // alert("goooooooooooo");

    setFiles(
      datafiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );

    const uploaders = datafiles.map((file) => {
      const formData = new FormData();
      formData.append("file", file);

      return axios.post("https://httpbin.org/post", formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" },
      });
    });
  };

  const onDropS = (datafiless) => {
    // alert("goooooooooooo");
    setFiless(
      datafiless.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    const uploaders = datafiless.map((file) => {
      const formData = new FormData();
      formData.append("file", file);

      return axios.post("https://httpbin.org/post", formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" },
      });
    });
  };

  const handlefileChangeS = (e) => {
    let datafieldss = fieldss;
    if (e.target.name === "tache_file") {
      if (fileLivrableValidation("tache_file")) {
        datafieldss["tache_file"] = e.target.files[0];
      }
      // else {
      //   this.setState({
      //     isInvalidFileExtension: true,
      //   });
      //   setTimeout(() => {
      //     this.setState({
      //       isInvalidFileExtension: false,
      //     });
      //   }, 6000);
      // }
    } else {
      datafieldss[e.target.name] = e.target.value;
    }

    setFieldss(datafieldss);
    //console.log(this.state.fieldss);
  };

  const openLivrableSyllabus = (sizelivrableSyllabus) => {
    setShowlivrableSyllabus(true);
    setSizelivrableSyllabus(sizelivrableSyllabus);
  };

  const closeLivrableSyllabus = () => {
    setShowlivrableSyllabus(false);
  };

  const isValidURL = (string) => {
    var res = string?.match(
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    );
    return res !== null;
  };

  // called every time a file's `status` changes
  const handleChangeStatusS = ({ meta, file }, status) => {
    if (status === "done") {
      setFiless(filess.concat(file));
    }

    // console.log("kxkxkxkx", this.state.filess);
  };

  const voirTacheValidees = () => {
    window.location = "/liste-taches-validees";
  };

  return isload ? (
    <div>
      <Preloader />
    </div>
  ) : (
    <div className="p-b-200">
      {/* {console.log("Redux data", AllData.items.all_taches_syllabus.data)} */}
      <div className="row">
        <div className="col-lg-9">
          <nav aria-label="breadcrumb fixed">
            <ol className="breadcrumb fixed">
              <li className="breadcrumb-item ">
                <Link to="/dashboard-bakeliste">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Tâches
              </li>
              {/* <div className="search offset-md-5">
                <div className="input-group size1"> */}

              {/* </div>
              </div> */}
            </ol>
          </nav>
          <div className="row justify-content-end">
            <div className="container-search">
              <FeatherIcon className="search_icon" icon="search" />
              <input
                value={rechercheTache}
                type="search"
                className="form-control form-control-costom shadow "
                placeholder="Chercher une tache"
                aria-label="Search"
                aria-describedby="search-addon"
                icon="Search"
                onChange={(e) => setRechercheTache(e.target.value)}
              />
            </div>
          </div>
          <div className="row m-b-15 pt6">
            <div className="col-md-6 m-b-15">
              <h6 className="h-theme ff-inter fw-600 ">Liste de vos tâches</h6>
            </div>
            <div className="col-md-6 justify-content-end">
              <button className="btn-theme-orange" onClick={voirTacheValidees}>
                Mes tâches validées
              </button>
            </div>
          </div>

          {/* Alert Livrable envoye avec succes */}
          <SweetAlert
            show={alertSuccess}
            onConfirm={() => setAlertSuccess(false)}
            success
            title="Livrable envoyé avec succes!!!"
            style={{
              color: "#069A77",
              fontSize: "12px",
              fontFamily: "Work Sans, sans-serif",
              paddingTop: 60,
              paddingBottom: 40,
            }}
            confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
          />

          <SweetAlert
            show={userNotConnected}
            onConfirm={() => setUserNotConnected(false)}
            error
            title="Vous n'etes pas connecte, Merci de verifier votre connexion pour continuer!"
            style={{
              color: "#d9534f",
              fontSize: "12px",
              fontFamily: "Work Sans, sans-serif",
              paddingTop: 60,
              paddingBottom: 40,
            }}
            confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
          />
          {/* ENd ALert Succes */}

          {/* Alert Livrable non envoye */}
          <SweetAlert
            show={alertError}
            onConfirm={() => setAlertError(false)}
            error
            title="Erreur sur envoie livrable, veuillez reessayer!!!"
            style={{
              color: "#d9534f",
              fontSize: "12px",
              fontFamily: "Work Sans, sans-serif",
              paddingTop: 60,
              paddingBottom: 40,
            }}
            confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
          />

          <SweetAlert
            show={statusSyllabusNotUpdated}
            onConfirm={() => setStatusSyllabusNotUpdated(true)}
            error
            title="Erreur modification Statut!!!"
            style={{
              color: "#069A77",
              fontSize: "12px",
              fontFamily: "Work Sans, sans-serif",
              paddingTop: 60,
              paddingBottom: 40,
            }}
            confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
          />
          {/* ENd ALert Error */}
          <SweetAlert
            show={statusUpdated}
            onConfirm={() => setStatusUpdated(false)}
            success
            title="Statut tache modifié!!!"
            style={{
              color: "#069A77",
              fontSize: "12px",
              fontFamily: "Work Sans, sans-serif",
              paddingTop: 60,
              paddingBottom: 40,
            }}
            confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
          />
          <SweetAlert
            show={statusNotUpdated}
            onConfirm={() => setStatusNotUpdated(true)}
            error
            title="Erreur modification Statut!!!"
            style={{
              color: "#069A77",
              fontSize: "12px",
              fontFamily: "Work Sans, sans-serif",
              paddingTop: 60,
              paddingBottom: 40,
            }}
            confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
          />

          {/* Toutes les taches du baekliste */}
          {/* <InfiniteScroll
            className="hello"
            dataLength={all.length}
            // next={() => fetchMoreReduxData()}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={loading && "Les donnees sont entrain de se charger...."}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Vous avez tous visualise!!!</b>
              </p>
            }
          >
          </InfiniteScroll> */}
          {/* {console.log("taille",getDetails().length)} */}
          {getDetails().length > 0 && (
            <>
              {getDetails().map((item, index) => (
                <div key={item.id} className="col-xl-12 col-lg-12 col-md-9">
                  <div className="card-dashboard m-b-15">
                    <div className="row mb-4">
                      <div className="col-sm-8 mb-sm-0 mb-3">
                        <p className="h-theme ff-work fw-500">
                          {/* Tâches {index + 1} */}
                        </p>
                        <p className="ff-work fs-14 fw-500 text-dark">
                          {item.syllabus_task}
                        </p>
                        {item.description}
                      </div>
                      <div className="col-sm-4 mb-sm-0 mb-3 itm-right">
                        <FeatherIcon
                          className="icon-tache-bakeliste-limit-hours"
                          icon="clock"
                        />
                        <div>
                          <p className="tache-bakeliste-limit-hours fw-300 text-up">
                            Duree tache :
                          </p>
                          <p className="tache-bakeliste-limit-hours fw-500 text-cap">
                            {item.syllabus_completion_time}
                          </p>
                        </div>
                        <p className="fs-12 m-t-30">
                          <span className="flag">{item.syllabus_name}</span>
                        </p>
                      </div>
                    </div>

                    <p className="ff-work fs-12 fw-500 text-dark m-b-10">
                      Liens utiles
                    </p>

                    {item.tache_url !== null && isValidURL(item.tache_url) ? (
                      <div>
                        <ReactPlayer
                          url={item.tache_url}
                          width="100%"
                          controls={true}
                        />
                      </div>
                    ) : (
                      <a
                        className="lien-utile-bakeliste-tache-card"
                        href={item.tache_url}
                        target="blank"
                      >
                        {item.tache_url}
                      </a>
                    )}

                    {item.tache_url !== null && item.tache_file ? (
                      <div classname="thumbnail">
                        <p className="ff-work fs-12 fw-500 text-dark m-b-10 m-t-30">
                          Pièces jointes
                        </p>
                        <img
                          src={base_api + item.tache_file}
                          alt="fichier tache"
                          style={{ width: "100px" }}
                        />
                      </div>
                    ) : (
                      <div classname="thumbnail">
                        {/* Cette tâche n'a pas de pièces */}
                      </div>
                    )}
                    {/* <div className="row d-flex justify-content-end">
                      <div className="col-md-5 ">
                        <button
                          type="button"
                          className="btn-theme text-up w-full"
                          onClick={() => {
                            setSelectedTacheS(item);
                            openLivrableSyllabus("md");
                          }}
                        >
                          Envoyer mon travail
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              ))}

              <PaginationRefac
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={props.taches.all_taches.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </>
          )}

          {/* Modal d'envoie de livrable */}

          <Modal
            className="modal-responsive-md"
            size={sizelivrableSyllabus}
            show={showlivrableSyllabus}
            onHide={closeLivrableSyllabus}
          >
            <h5
              className="modal-bakeliste-title m-b-50"
              id="modalSendLivrableTitle"
            >
              Envoyer mon travail
            </h5>
            <button
              className="close-modal-bakeliste-title"
              onClick={closeLivrableSyllabus}
            >
              <FeatherIcon icon="x" aria-hidden="true" />
            </button>

            <form
              encType="multipart/form-data"
              id="livrable_form"
              onSubmit={(e) => sendLivrables(e)}
            >
              <div className="form-group">
                <label
                  id="details_syllabus_id"
                  name="detail_syllabus_id"
                  className="form-control select-modal-content-bakeliste"
                  value={(fieldss["details_syllabus_id"] = selectedTacheS.id)}
                  onChange={(e) => handleChangeSyllabus(e)}
                >
                  {selectedTacheS.syllabus_task}
                </label>
              </div>
              <div>
                <textarea
                  className="form-control input-modal-content-bakeliste p-t-15"
                  rows="9"
                  placeholder="Ajouter un commentaire"
                  name="comment"
                  value={fieldss["comment"]}
                  onChange={(e) => handleChangeS(e)}
                ></textarea>
              </div>
              <Dropzone
                required
                // getUploadParams={this.getUploadParams}
                onChangeStatus={handleChangeStatusS}
                //onSubmit={this.handleSubmit}
                onChange={(e) => handlefileChangeS(e)}
                //onDrop={this.onDropS.bind(this)}
                accept="image/*,audio/*,video/*"
                inputContent={"Cliquer pour ajouter des images"}
                inputWithFilesContent={"Ajouter plus..."}
              />
              {isSendingInProgress ? (
                <button
                  type="submit"
                  className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                  disabled
                >
                  Envoie en cours &nbsp;
                  <FeatherIcon icon="loader" aria-hidden="true" />
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                >
                  ENVOYER
                </button>
              )}
            </form>
          </Modal>
        </div>
        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-md-12 col-sm-6 pr-md-3 pr-sm-2 dis-flex">
              <CoachItemDashboardBakeliste />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
              <BakelisteGroupItemRefact />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-2 dis-flex">
              <ListCoachAssistantRefact />
            </div>
            {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-2 dis-flex">
                <NextReunionItem />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-2 dis-flex">
                <SavedReunionListItem />
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToprops = (state) => {
  return {
    taches: state.tachesBakeliste.items,
  };
};

export default connect(mapStateToprops, { getDetailsSyllabus })(
  ListeTacheBakelisteRefac
);
