import React, {Component} from 'react';
import './detailPratique.css';
import FeatherIcon from 'feather-icons-react';
// import ExamenItemQuestion from '../examenItemQuestion/index';
import AddQuestionExamen from '../addQuestionExamen/index';

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as detailsExamenActions from "../../store/detailsExamen/actions";
export default class detailPratique extends Component {
    constructor(props) {
        super(props);
        this.state = {
          liste_questions: this.props.liste,
        };
        // console.log("liste",this.props.liste)
        // console.log("liste",this.state.id) 

    }
    render() {
   // console.log("ééééééé",this.props.id);
   
 
      let comp = this.props.id;
      let nomEx =  this.props.nom;
      return  <div>
                <div className="card-dashboard mb-4 mto-3">
                  <div className="row m-b-30">
                    <div className="col-md-8">
                      <p className="titre-syllabus-details-card mb-3">
                        {this.props.nom}
                        </p>
                      <span
                className={
                  "eetiquette-syllabus-details " +
                  (this.props.level === "débutant" || this.props.level === "debutant" || this.props.level === "Débutant"
                    ? " etiquette-syllabus-details debutant mb-2"
                    : this.props.level === "intermédiaire" || this.props.level === "intermediaire" || this.props.level === "Intermédiaire"
                    ? "etiquette-syllabus-details intermediaire mb-2"
                    : this.props.level === "avancé" || this.props.level === "avance" || this.props.level === "Avancé"
                    ? "etiquette-syllabus-details avance mb-2"
                    : "")
                }
              >
                {this.props.level} 
                 </span>

                      <div className="row mb-4">
                        <div className="col-xl-3 col-lg-4 pr-lg-0 col-md-3 pr-md-0 col-sm-3 col-6">
                          <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="list"/> Session:{this.props.session}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 pl-xl-0 px-lg-0 col-md-3 col-6 px-0">
                          <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="clock"/>Durée:{this.props.duree}H</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 pl-xl-0 px-lg-0 col-md-3 col-6 px-0">
                          <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="clock"/>Examen {this.props.type}</p>
                        </div>
                      </div> 
                    </div>
                 
                  </div>
                  
                    
                </div>
                <p className="fw-700 ff-inter fs-14 text-dark mb-3 offset-md-5">Travail à faire</p>
                <div className="card-dashboard mb-4 mto-3">
                <div> 
                    {this.props.taf}
                  </div>
                </div>
              </div>;
    }
  }

// export default connect(
//     ({ detailsExamen }) => ({ ...detailsExamen }),
//     dispatch => bindActionCreators({ ...detailsExamenActions }, dispatch)
//   )( detailsExamen );