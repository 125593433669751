import React, { Component } from "react";
import "./listeBakelistesCoach.css";
import TableBakeliste from "../tableBakeliste/index";
//import Loader from "react-loader-spinner";
import Preloader from "../preloader/index";

export default class listeBakelistesCoach extends Component {
  constructor(props) {
    super(props);
    this.state = {
     loading: true,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 4000);
  }
  render() {
    return (
this.state.loading ? (
                <div>
                    <Preloader />
              </div>
            )  : ( 
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-12">
            <div className="row m-b-15">
              <div className="col dis-flex m-b-15"></div>
            </div>
            <div className="card-dashboard m-b-15 p-b-60">
              <div className="row m-b-30 m-t-20">
                <div className="col-xl-7 col-lg-6 col-md-7 col-sm-6 col-6 m-b-15 dis-flex itm-center">
                  <h5 className="h-theme ff-inter fw-500">Mes bakelistes</h5>
                </div>
                <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
              </div>

              <TableBakeliste /> 
            </div>
          </div>
          {/* <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteGroupItemForCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div> */}
        </div>
      </div>
   ) );
  }
}
// export default connect(
//     ({ listeBakelistesCoach }) => ({ ...listeBakelistesCoach }),
//     dispatch => bindActionCreators({ ...listeBakelistesCoachActions }, dispatch)
//   )( listeBakelistesCoach );
