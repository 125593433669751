import React, {Component} from 'react';
import './listeContenusBakeliste.css';
import axios from "axios";
import * as Sentry from "@sentry/react";
import API from "../../variablesGlobales";
import ModalContactUs from "../modalContactUs/index";
import FeatherIcon from "feather-icons-react";
import Fichier from "../../image/fichier.png";
import { Link } from "react-router-dom";
import { NavLink } from "react-bootstrap";
import DOMPurify from "dompurify";
import TextTruncate from "react-text-truncate";
import Preloader from "../preloader/index";
// import ListCoachAssistant from "../listCoachAssistant/index";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import InfiniteScroll from "react-infinite-scroll-component";
import Moment from "react-moment";
import Resume from "../../img/resume.jpeg";
import Article from "../../img/article.jpeg";
import Tutoriel from "../../img/tutoriel.jpeg";
import Rapport from "../../img/rapport.jpeg";
import { Tooltip } from "@material-ui/core";
import Truncate from "react-truncate-html";
import CoachItemDashboardBakeliste from "../coachItemDashboardBakeliste/index";
import BakelisteGroupItem from "../bakelisteGroupItem/index";
import { FilterCenterFocusOutlined } from '@material-ui/icons';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listeContenusBakelisteActions from "../../store/listeContenusBakeliste/actions";
export default class listeContenusBakeliste extends Component {
    constructor(props) {
        super(props);
        this.state = {
          contenus: [],
          api: API.API,
          userID: window.sessionStorage.getItem("user_id"),
          isload: true,
          currentPage: 1,
          hasMore: true,
          article: [],
          rapportDeStage: [],
          tutoriel: [], 
          resume: [],
          tab_All: true, 
          tab_Rapports: false,
          tab_tutoriels: false,
          tab_resumes: false,
          tab_articles: false,
          navs: [],
          recherche: "",
    
        };
    } 
    componentDidMount() {
      // this.getArticles();
      this.getResume();
      // this.getListResumes();
      // this.getTutoriels();
      // this.getRapportDeStage();
      setTimeout(() => {
        this.setState({
          isload: false,
        });
      }, 5000);
    };


  getResume = () => {
    axios
      .get(
        this.state.api +
          "textual-contents" +
          "/?page=" +
          this.state.currentPage
      )
      .then((res) => {
        const contenu = res.data.data;
        this.setState({
          contenus: contenu.data,
        });
        console.log(this.state.contenus)
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  fetchMoreData = () => {
    setTimeout(() => {
      this.setState({
        currentPage: this.state.currentPage + 1,
        loading: true,
      });
      axios
        .get(
          this.state.api +
            "textual-contents" +
            "/?page=" +
            this.state.currentPage
        )
        .then((response) => {
          // console.log("jdcjcndzl", response);

          if (response.data.success === true) {
            Object.values(response.data.data.data).forEach((element) => {
              this.state.contenus.push(element);
            });

            if (
              response.data.data.current_page === response.data.data.last_page
            ) {
              this.setState({ hasMore: false, loading: false });
            } else {
              this.setState({ hasMore: true, loading: true });
            }
          }
        });
    }, 500);

    return this.state.contenus;
  };
    render() {
      return this.state.isload ? (
        <div>
          <Preloader />
        </div>
      ) : (
        <>
        hello
        </>
       );
    }
  }
  // export default connect(
  //     ({ voirMesContenus }) => ({ ...voirMesContenus }),
  //     dispatch => bindActionCreators({ ...voirMesContenusActions }, dispatch)
  //   )( voirMesContenus );
  
// export default connect(
//     ({ listeContenusBakeliste }) => ({ ...listeContenusBakeliste }),
//     dispatch => bindActionCreators({ ...listeContenusBakelisteActions }, dispatch)
//   )( listeContenusBakeliste );