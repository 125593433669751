import React, { Component } from "react";
import "./navVertical.css";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

export default class navVertical extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container-nav-vertical no-view-tablette">
        <div
          className="nav flex-column nav-pills"
          id="v-pills-tabCustom"
          role="tablist"
          aria-orientation="vertical"
        >
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/dashboard-bakeliste"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="layout" />
            Dashboard
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-tache-bakeliste"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="grid" />
            Tâches
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/syllabus-bakeliste"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="book" />
            Programmes
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/livraison-bakeliste"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="folder" />
            Livraison
          </NavLink>
          {/* <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-coach"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Coachs
          </NavLink> */}
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/groupe-bakeliste"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Groupe
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/demande-d-absence"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="mail" />
            Demande d'Absence
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/ajout-cv-bakeliste"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="file-text" />
          CV
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-contenus-bakelistes"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="list" />
            Liste des contenus
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/livraisons-refactor"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="list" />
            Livraison v2
          </NavLink>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ navVertical }) => ({ ...navVertical }),
//     dispatch => bindActionCreators({ ...navVerticalActions }, dispatch)
//   )( navVertical );
 