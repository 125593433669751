import React, { Component } from "react";
import "./preloader.css";
import Logo from "../../img/logo.png";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as preloaderActions from "../../store/preloader/actions";
export default class preloader extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-preloader">
        {" "}
        <div id="preloader-active">
          <div className="preloader d-flex align-items-center justify-content-center">
            <div className="preloader-inner position-relative">
              <div className="preloader-circle"></div>
              <div className="preloader-img pere-text">
                <img src={Logo} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ preloader }) => ({ ...preloader }),
//     dispatch => bindActionCreators({ ...preloaderActions }, dispatch)
//   )( preloader );
