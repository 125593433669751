import React  from "react";
import "./syllabusItemDisabled.css";
import ImageSyllabus from "../../img/syllabus.jpg";
import FeatherIcon from "feather-icons-react";

const SyllabusItemDisabled = ({syllabus}) => {
// const SyllabusBakeliste = () => {

  // constructor(props) {
  //   super(props);
  //   this.state = {};
  // } 
  
    return (
      // console.log(niveau,"eeede", syllabus),
      <div className="component-syllabus-item-disabled">
        <div className="syllabus-item"> 
          <p /* className="etiquette-syllabus-item " */
            className={ 
              "etiquette-syllabus-item " +
              (syllabus.syllabus_level === "débutant"
                ? " bg-debutant"
                : syllabus.syllabus_level === "intermédiaire"
                ? "bg-intermediaire"
                : syllabus.syllabus_level === "avancé"
                ? "bg-avance"
                : "")
            }
          >
            {syllabus.syllabus_level}
          </p>
          {/* <p className="etiquette-syllabus-item confirme">confirmé</p> */}
          <img
            src={ImageSyllabus}
            alt="Avatar"
            className="image-syllabus-item"
            style={{ width: "100%" }}
          />
           <div className="content-syllabus-item">
            <p className="titre-content-syllabus-item">{syllabus.syllabus_name}</p>
            <div className="row">
              <div className="col-xl-4 col-lg-5 pr-lg-0 col-6 pr-0">
                <p className="info-content-syllabus-item">
                  <FeatherIcon
                    className="icon-content-syllabus-item"
                    icon="list"
                  />{" "}
                  {syllabus.details.length} tâches
                </p>
              </div>
           
              <div className="col-xl-4 pl-xl-0 col-6 pr-0">
                <p className="info-content-syllabus-item">
                  <FeatherIcon
                    className="icon-content-syllabus-item"
                    icon="user"
                  />{" "}
                  {syllabus.assignations.length} bakelistes
                </p>
              </div>
            </div>
          </div> 
        </div>
      </div>
    );
  
}
export default SyllabusItemDisabled
//     ({ syllabusItemDisabled }) => ({ ...syllabusItemDisabled }),
//     dispatch => bindActionCreators({ ...syllabusItemDisabledActions }, dispatch)
//   )( syllabusItemDisabled );
