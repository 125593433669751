import React, {Component} from 'react';
import './addQuestionExamen.css';
// import {Icon} from 'rsuite';
import {NavLink, Link} from 'react-router-dom';

import FeatherIcon from 'feather-icons-react';
import { DatePicker, Modal, Uploader, Icon } from 'rsuite';

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as addQuestionExamenActions from "../../store/addQuestionExamen/actions";
export default class addQuestionExamen extends Component {
    constructor(props) {
        super(props);
        this.state = {
          show: false, 
          id:this.props.exam,
          
        }; 
    }
  

    
   
    render() {
      return  <div className="ml-md-auto">
                <div className="modal-container">
                        <Link
                          className="btn-add-with-icon-plus-circle btn btn-theme"
                          to={{
                            pathname:"/ajouter-question-examen",
                            state: { id:this.props.exam, nom:this.props.libelle},
                          }}
                        >
                        Ajouter une question
                          <Icon className="icon-add1 ml-2" icon="plus-circle" />

                        
                        </Link>
                </div>
              </div>;
    };
  }

// export default connect(
//     ({ addQuestionExamen }) => ({ ...addQuestionExamen }),
//     dispatch => bindActionCreators({ ...addQuestionExamenActions }, dispatch)
//   )( addQuestionExamen );