import React from "react";
import "./listeTacheBakelisteView.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVertical from "../navVertical/index";
import ListeTacheBakeliste from "../listeTacheBakeliste/index";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ListeTacheBakelisteRefac from "../listeTacheBakeliste/ListeTacheBakelisteRefac";

const ListeTacheBakelisteViewRefac = () => {
  return (
    <div className="body-theme">
      <HeaderDashboard />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVertical />
          </div>
          <div className="col-lg-10 pt-3">
            {/*   {this.state.loading ? (
                <div>

                <Preloader />
              </div>
            ) : (  <> */}
            {/* <ListeTacheBakeliste /> */}
            <ListeTacheBakelisteRefac />
            {/* </>)} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeTacheBakelisteViewRefac;
