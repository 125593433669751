import {FETCH_GROUPE_BAKELISTE} from "../actions/groupesActions";

const initialState = {
   items: [],
   loading: false,
   error: null,
};

export default function groupesReducer(state = initialState, action) {
   switch (action.type) {
     case FETCH_GROUPE_BAKELISTE:
       return {
           ...state,
           loading: false,
           error: action.payload.error,
           items: action.payload,
           name: action.name,
       };
       default :
       return state
   }

}

