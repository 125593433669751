import React, {Component} from 'react';
import './ajoutGroupeCoachAssistant.css';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import * as Sentry from "@sentry/react";
import { Link } from 'react-router-dom';
import NavVerticalCoachAssistant from '../navVerticalCoachAssistant/index';
import BakelisteGroupItemForCoach from '../bakelisteGroupItemForCoach/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import HeaderDashboardCoach from '../headerDashboardCoach/index';
import API from "../../variablesGlobales";
import $ from "jquery";
import SweetAlert from "react-bootstrap-sweetalert";





export default class ajoutGroupeCoachAssistant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlertSuccess: false,
            showAlertError: false,    
            api: API.API,
            newGroup: {
                group_name: "",
                lien_whatsapp:"",
            },
            isErrorAjout: false,
            group_name: "",
            lien_whatsapp:"",
            name:"",
            userID: window.sessionStorage.getItem('user_id'),
            addGroupInProgress: false,           
        };


    }
    componentDidMount(){

    }
  
// Ajouter groupes
addGroupe(e) {
    e.preventDefault()
    this.setState({
      addGroupInProgress: true,
    });
    var fd = new FormData();
    fd.append("group_name", this.state.group_name);
    fd.append("lien_whatsapp", this.state.lien_whatsapp);
    
    axios
      .post(this.state.api + "groupes",fd, this.state.newGroup)
      .then((response) => {
        if (response.data.success) {
            $('#group_name').val('')
            $('#lien_whatsapp').val('')
            this.setState({
              addGroupInProgress: false,
              showAlertSuccess: true,
              group_name: "",
              lien_whatsapp: "",
            })
            window.location = '/groupe-coach-assistant';

            }  else {
            this.setState({
              showAlertError: true,
            })
           } 
      })
      .catch((error) => {
        if ((error.message = "Request failed with status code 422")) {
        }
        this.setState({
          assignationEncours: false,
          showAlertError: true,
        });
        setTimeout(() => {
          this.setState({
            showAlertError: false,
          });
        }, 1000);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });

  }
  onChange = event => {
    this.setState({
        group_name: event.target.value.replace(/[^\w\s]/gi, "") ,
    });
    
  };
  onChangeLink = event => {
    this.setState({
        lien_whatsapp: event.target.value.replace(/[^\w\s]/gi, "") 
    });
    
  };

  render() {
    return  <div className="body-theme">
                <HeaderDashboardCoach />
                <div className="container-fluid fixing-after-header">
                    <div className="row">
                        <div className="col-lg-2 p-0">
                            <NavVerticalCoachAssistant />
                        </div>
                        <div className="col-lg-10 pt-3">
                        
                            <div className="p-b-200">
                                <div className="col-lg-9">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                        <li className="breadcrumb-item ">
                                            <Link to="/dashboard-coach-assistant">Dashboard</Link>
                                        </li>
                                        <li className="breadcrumb-item " aria-current="page">
                                            <Link to="/groupe-coach-assistant">Groupes</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Créer un groupe
                                        </li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div className="row m-b-15">
                                            <div className="col m-b-15">
                                                <h6 className="h-theme ff-inter fw-600">Créer un groupe</h6>
                                            </div>
                                            <div className="col dis-flex m-b-15">
                                            </div>
                                        </div>
                                        <div className="card-dashboard p-md-5 p-sm-5 m-b-20"> 
                                            <SweetAlert
                                                show={this.state.showAlertSuccess}
                                                onConfirm={() => this.setState({ showAlertSuccess: false })}
                                                success
                                                title="Groupe ajouté avec succes!!!"
                                                style={{
                                                    color: "#069A77",
                                                    fontSize: "12px",
                                                    fontFamily: "Work Sans, sans-serif",
                                                    paddingTop: 60,
                                                    paddingBottom: 40,
                                                }}
                                                confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                                            />
                                            <SweetAlert
                                                show={this.state.showAlertError}
                                                onConfirm={() => this.setState({ showAlertError: false })}
                                                error
                                                title="Erreur sur la création d'un groupe, veuillez reessayer!!!"
                                                style={{
                                                    color: "#d9534f",
                                                    fontSize: "12px",
                                                    fontFamily: "Work Sans, sans-serif",
                                                    paddingTop: 60,
                                                    paddingBottom: 40,
                                                }}
                                                confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                                            />
                                            <form id="add_groupe_form"  onSubmit={(e) => this.addGroupe(e)}>                                    
                                                <div className="edit-bakeliste-container">
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label>Nom du groupe</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="inputName"
                                                                placeholder="Nom"
                                                                name="group_name"
                                                                required
                                                                value={this.state.group_name} 
                                                                onChange={this.onChange} 
                                                            />{" "}
                                                                {this.state.remaining_char >= 31 && (
                                                                <span className="restant_container_sup_30">
                                                                    {" "}
                                                                    {this.state.remaining_char} caractéres restants
                                                                </span>
                                                                )}
                                                                {this.state.remaining_char <= 30 &&
                                                                this.state.remaining_char > 1 && (
                                                                    <span className="restant_container_inf_30">
                                                                    {" "}
                                                                    {this.state.remaining_char} caractéres restants
                                                                    </span>
                                                                )}
                                                                {this.state.remaining_char <= 1 && (
                                                                <span className="restant_container_inf_30">
                                                                    {" "}
                                                                    {this.state.remaining_char} caractére restant
                                                                </span>
                                                                )}
                                                                 <br/>  
                                                            <label>Lien du groupe whatsapp</label>

                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="inputLink"
                                                                placeholder="Lien whatsapp"
                                                                name="lien_whatsapp"
                                                                value={this.state.lien_whatsapp} 
                                                                onChange={this.onChangeLink} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div className="col-md-12">
                                                    {this.state.addGroupInProgress ? (
                                                        <button
                                                            type="submit"
                                                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                                            disabled
                                                        >
                                                            Création en cours &nbsp;
                                                            <FeatherIcon icon="loader" aria-hidden="true" />
                                                        </button>
                                                        ) : (
                                                        <button
                                                            type="submit"

                                                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                                        >
                                                            Créer
                                                        </button>
                                                    )}
                                                </div>
                                            </form> 
                                        </div>
                                    </div>
                                <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                                            <BakelisteGroupItemForCoach />
                                        </div>
                                        <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                                            <ProgramReunionCoach />
                                        </div>
                                        <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                                            <SavedReunionListItem />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
    
}
// export default connect(
//     ({ tableMyBakelistes }) => ({ ...tableMyBakelistes }),
//     dispatch => bindActionCreators({ ...tableMyBakelistesActions }, dispatch)
//   )( tableMyBakelistes );

