import React, { Component } from "react";
import "./addQuestion.css";
import axios from "axios"; 
import API from "../../variablesGlobales";
import Select from "react-select";
import { Link } from "react-router-dom";
import { toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
toast.configure();

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as componentQuestionQuizActions from "../../store/componentQuestionQuiz/actions";
export default class addQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      libelle: "",
      opt1: "",
      opt2: "",
      opt3: "",
      opt4: "",
      bonne_reponse: "",
      point: 1,
      is_archived: false,
      created_by: window.sessionStorage.getItem("user_id"),
      is_verified: false,
      domaine_id: 0,
      fields: {},
      errors: {},
      api: API.API,
      selectOptions: [],
      isLoading: false,
      isLoadingcontinue: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {
    this.getdomaines();
  }

  async getdomaines() {
    try {
      const response = await axios.get(this.state.api + "domaines");

      this.setState({ selectOptions: response.data });

      // console.log(response.data.data);
      const options = response.data.data.map((d) => ({
        value: d.id,
        label: d.name,
      }));
      // console.log(options);
      this.setState({ selectOptions: options });
    } catch (err) {
      console.warn(err);
    }
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Question
    if (!fields["libelle"]) {
      formIsValid = false;
      errors["libelle"] = "Ce champs est obligatoire";
    }

    //Opt1
    if (!fields["opt1"]) {
      formIsValid = false;
      errors["opt1"] = "Ce champs est obligatoire";
    }

    //Opt2
    if (!fields["opt2"]) {
      formIsValid = false;
      errors["opt2"] = "Ce champs est obligatoire";
    }

    //Opt3
    if (!fields["opt3"]) {
      formIsValid = false;
      errors["opt3"] = "Ce champs est obligatoire";
    }

    //Opt4
    if (!fields["opt4"]) {
      formIsValid = false;
      errors["opt4"] = "Ce champs est obligatoire";
    }

    if (!fields["bonne_reponse"]) {
      formIsValid = false;
      errors["bonne_reponse"] = "Cannot be empty";
    }

    //  if(typeof fields["bonne_reponse"] !== "undefined"){
    //     if(!fields["bonne_reponse"].match(/^[a-zA-Z]+$/)){
    //        formIsValid = false;
    //        errors["bonne_reponse"] = "Only letters";
    //     }
    //  }

    this.setState({ errors: errors });
    return formIsValid;
  }
 
  onChange = (e) => {
    // this.setState({ [e.target.name]: e.target.value });
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["created_by"] = this.state.created_by;
    fields["is_archived"] = this.state.is_archived;
    fields["is_verified"] = this.state.is_verified;
    fields["domaine_id"] = this.state.domaine_id;
    // fields["bonne_reponse"] = this.state.bonne_reponse;
    fields["point"] = this.state.point;
    this.setState({ fields });
  };

  handleChange = (e) => {
    // console.log('eeeee',e.value)
    this.setState({ domaine_id: e.value });
  };

  onContinue = (e) => {
    e.preventDefault();
    this.setState({ isLoadingcontinue: true });
    if (this.handleValidation()) {
      axios
        .post(this.state.api + "questions", this.state.fields)
        .then((result) => {
          this.setState({
            isLoadingcontinue: false,
          });
        //  console.log(result);
          toast.success(
            "Question ajoutée avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
          window.location.reload(false);
        })

        .catch((err) => {
          this.setState({
            isLoadingcontinue: false,
          });
          console.warn(err);
          toast.error(
            "Erreur Ajout question",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        });
    } else {
      this.setState({
        isLoadingcontinue: false, 
      });
      toast.error(
        "Formulaire incomplet",
        { position: toast.POSITION.TOP_CENTER },
        { autoClose: 1000 }
      );
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (this.handleValidation()) {
      axios
        .post(this.state.api + "questions", this.state.fields)
        .then((result) => {
          this.setState({
            isLoading: false,
          });
          toast.success(
            "question ajoutée avec success",
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 1000 }
          );
          this.setState({
            ...this.state,
            fields: {},
            libelle: "",
            opt1: "",
            opt2: "",
            opt3: "",
            opt4: "",
            bonne_reponse: "",
          });
          window.location = "/liste-questions";
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          toast.error(
            "Erreur! Veuillez reessayer",
            { position: toast.POSITION.TOP_CENTER },
            { autoClose: 1000 }
          );
        });
    } else {
      this.setState({
        isLoading: false,
      });
      toast.warning(
        "formulaire incomplet",
        { position: toast.POSITION.TOP_CENTER },
        { autoClose: 1000 }
      );
    }
  };

  render() {
    return (
      <div className="component-component-question-quiz px-0">
        <div className="container-fluid questionnaire">
          <form className="post form-question" id="form-question">
            <div className="row pr-5 pt-5">
              <div className="col-xl-12 pb-3">
                <Select
                  options={this.state.selectOptions}
                  onChange={(e) => this.handleChange(e)}
                  id="domaine_id"
                  className="input-add-quiz"
                  placeholder="Choisir un domaine"
                  required
                />
              </div>
            </div>
            <div className="row pr-5">
              <div className="col-xl-12 pb-3">
                <input
                  type="text"
                  className="input-add-quiz"
                  placeholder="Saisir la question"
                  name="libelle"
                  value={this.state.fields["libelle"]}
                  onChange={this.onChange}
                  required
                />
                <span style={{ color: "red" }}>
                  {this.state.errors["libelle"]}
                </span>
              </div>
            </div>
            <div className="row pr-5">
              <div className="col-xl-12 pb-3">
                <input
                  type="text"
                  className="input-add-quiz"
                  placeholder="Réponse 1"
                  name="opt1"
                  value={this.state.fields["opt1"]}
                  onChange={this.onChange}
                  required
                />
                <span style={{ color: "red" }}>
                  {this.state.errors["opt1"]}
                </span>
              </div>
            </div>
            <div className="row pr-5">
              <div className="col-xl-12 pb-3">
                <input
                  type="text"
                  className="input-add-quiz"
                  placeholder="Réponse 2"
                  name="opt2"
                  value={this.state.fields["opt2"]}
                  onChange={this.onChange}
                  required
                />
                <span style={{ color: "red" }}>
                  {this.state.errors["opt2"]}
                </span>
              </div>
            </div>
            <div className="row pr-5">
              <div className="col-xl-12 pb-3">
                <input
                  type="text"
                  className="input-add-quiz"
                  placeholder="Réponse 3"
                  name="opt3"
                  value={this.state.fields["opt3"]}
                  onChange={this.onChange}
                  required
                />
                <span style={{ color: "red" }}>
                  {this.state.errors["opt3"]}
                </span>
              </div>
            </div>
            <div className="row pr-5">
              <div className="col-xl-12 pb-3">
                <input
                  type="text"
                  className="input-add-quiz"
                  placeholder="Réponse 4"
                  name="opt4"
                  value={this.state.fields["opt4"]}
                  onChange={this.onChange}
                  required
                />
                <span style={{ color: "red" }}>
                  {this.state.errors["opt4"]}
                </span>
              </div>
            </div>
            <div className="row pr-5">
              <div className="col-xl-12">
                <select
                  className="input-add-quiz"
                  name="bonne_reponse"
                  id="bonne_reponse"
                  value={this.state.fields["bonne_reponse"]}
                  onChange={this.onChange}
                  required
                >
                  <option>Choisir une réponse</option>
                  <option name="opt1" value="opt1">
                    reponse 1
                  </option>
                  <option name="opt2" value="opt2">
                    reponse 2
                  </option>
                  <option name="opt3" value="opt3">
                    reponse 3
                  </option>
                  <option name="opt4" value="opt4">
                    reponse 4
                  </option>
                </select>
              </div>
            </div>
            <div className="row pr-5 py-5 d-flex justify-content-end">
              <div className="col-xl-3 col-lg-3 col-md-3">
                <button
                  onClick={(e) => this.onContinue(e)}
                  className="btn-theme-orange text-up m-t-30 w-full p-t-15 p-b-15"
                  type="submit"
                  value="continue"
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading
                    ? "Enregistrement en cours..."
                    : "Enregistrer et continuer"}
                </button>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3">
                <button
                  onClick={(e) => this.onSubmit(e)}
                  className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                  type="submit"
                  disabled={this.state.isLoadingcontinue}
                >
                  {" "}
                  {this.state.isLoadingcontinue
                    ? "Enregistrement en cours..."
                    : "Enregistrer et fermer"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ componentQuestionQuiz }) => ({ ...componentQuestionQuiz }),
//     dispatch => bindActionCreators({ ...componentQuestionQuizActions }, dispatch)
//   )( componentQuestionQuiz );
