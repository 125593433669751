import React, { Component } from "react";
import "./livraisonsBakelistesView.css";
import LivraisonsBakelistes from "../livraisonsBakelistes/index";

export default class livraisonsBakelistesView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      // <div className="col-lg-10 pt-3">
      //   <LivraisonsBakelistes />
      // </div>
      <div className="row">
        <div className="col-md-12 pl-md-2 mb-3">
          <LivraisonsBakelistes />
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ livrableCoachView }) => ({ ...livrableCoachView }),
//     dispatch => bindActionCreators({ ...livrableCoachViewActions }, dispatch)
//   )( livrableCoachView );
