import React, { Component } from "react";
import "./listeTachesCoachAssistantView.css";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import NavVerticalCoachAssistant from "../navVerticalCoachAssistant/index";
//import Loader from "react-loader-spinner";
import API from "../../variablesGlobales";
import { Link } from "react-router-dom";
import Select from "react-select";
import FeatherIcon from "feather-icons-react";

import BakelisteGroupItemForCoachAssistant from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import SyllabusCoachItemTacheAssistant from "../syllabusCoachItemTacheAssistant/index";
import axios from "axios";
import Loader from "react-loader-spinner";
import Offlinecoach from "../offlinecoach/index";
import { options } from "amplitude-js";
import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";hhfusjuiuue0wuueefw9
// import * as listeTachesCoachViewActions from "../../store/listeTachesCoachView/actions";

// const Loader = () => <div> </div>;

export default class listeTachesCoachAssistantView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      syllabus_id: this.props.id,
      api: API.API,
      tacheDatas: [], 
      tacheDatastoFilter: [],
      selectOptions1: [],
      syllabusDatas: [],
      selectOptions: [],
      userID: window.sessionStorage.getItem("user_id"),
      all_taches: true,
      selected: "",
      loading: true,
      recherche: "",
      recherche1: "",
      recherche2: "",
      showTache: false,
      showSyllabus: false,
      showDescription: false,
    };
  }
  componentDidMount = () => {
   var eventProperties = {
     userID : this.state.userID,

   }
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("VISITED_PAGE_TACHES_COACH_ASSISTANT_V2", eventProperties);

    this.onGetAllTaches();
    this.onGetSyllabus();
  };

  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };
  getSyllabusData() {
    const recherche = this.state.recherche.trim().toLocaleLowerCase();
    const tache = this.state.tacheDatas;
    if (recherche.length < 1) {
      return tache;
    }
    return tache.filter((s) => {
      return s.syllabus_task.toLocaleLowerCase().includes(recherche);
    });
  }

  getSyllabusName() {
    const recherche1 = this.state.recherche1.trim().toLocaleLowerCase();
    const tache = this.state.tacheDatas;
    if (recherche1.length < 1) {
      return tache;
    }
    return tache.filter((s) => {
      return s.syllabus_name.toLocaleLowerCase().includes(recherche1);
    });
  }

  getSyllabusDescription() {
    const recherche2 = this.state.recherche2.trim().toLocaleLowerCase();
    const tache = this.state.tacheDatas;
    if (recherche2.length < 1) {
      return tache;
    }
    return tache.filter((s) => {
      return s.description.toLocaleLowerCase().includes(recherche2);
    });
  }

  onGetAllTaches = () => {
    var baseApiUrl = this.state.api;
    axios
      .get(baseApiUrl + "details-syllabus/")
      .then((response) => {
        var data = response.data.data;

        if (response.data.success) {
          this.setState({
            tacheDatas: response.data.data,
          });
          //console.log(this.state.tacheDatas);
        }
      })
      .catch((error) => {
        // console.log(error.message);

        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };
  onGetSyllabus = () => {
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;
    axios
      .get(baseApiUrl + "syllabus-by-coach/" + this.state.userID)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        // console.log("syllabus", response.data);
        if (response.data.success) {
          this.setState({
            syllabusDatas: response.data.data.reverse(),
          });
        }
        //_this.hideLoader();

        let infos0 = {
          label: "Tous les syllabus",
          value: 0,
          isDisabled: false,
        };
        this.state.selectOptions.push(infos0);

        this.state.syllabusDatas.forEach((item) => {
          let infos = {
            label: item.syllabus_name,
            value: item.id,
            isDisabled: false,
          };
          this.state.selectOptions.push(infos);
        });
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
      });
    // _this.hideLoader();
  };
  handleChange = (selectedOption) => {
    // console.log(selectedOption.value);
    // this.setState({ tacheDatas: this.state.tacheDatastoFilter });

    this.setState({ selected: selectedOption.value });
    let filtereredTaches = [];
    if (selectedOption.value === 0) {
      filtereredTaches = this.state.tacheDatas;
    } else {
      filtereredTaches = this.state.tacheDatas.filter(
        (tache) => tache.syllabus_id === selectedOption.value
      );
    }

    this.setState({ tacheDatastoFilter: filtereredTaches, all_taches: false });
  };

  onChange = (doc) => {
    this.setState({
      selectOptions1: doc,
    });
  };
  handleSearch = (event) => {
    // setSearchedCountry(event.target.value);
  };
  handleSelect = (event) => {
    // setRegion(event.target.value);
  };
  showBar = () => {
    this.setState({
      show: false,
    });
  };

  onSearchTache = () => {
    this.setState({
      showTache: true,
      showSyllabus: false,
      showDescription: false,
    });
  };

  onSearchDescription = () => {
    this.setState({
      showTache: false,
      showSyllabus: false,
      showDescription: true,
    });
  };

  onSearchSyllabus = () => {
    this.setState({
      showTache: false,
      showSyllabus: true,
      showDescription: false,
    });
  };

  render() {
    // const { loading } = this.state;
    // {this.getSyllabusData().map((item, index) => (

    // this.state.syllabusDatas.map((item, index) => (
    //   <option value={item.label} />
    // ));
    const checkConnexion = (
      // navigator.onLine && (
      <div className="row">
        {/* {this.state.loading ? (
                <div className="col-lg-10 pt-3">
              <Preloader />
              </div>
            )
               
             : <> */}
        <div className="col-xl-3 col-lg-3 input fixe">
          {/* <div className="input-group">
            <div className="input-group-prepend iconInput">
              <input
                value={this.state.recherche}
                type="search"
                className="form-control form-control-costom"
                placeholder="Search by task"
                // placeholder={
                //   <FeatherIcon icon="search" cursor="pointer" />
                // }
                list="cityname"
                aria-label="Search"
                aria-describedby="search-addon"
                icon="Search"
                onChange={(e) =>
                  this.setState({
                    recherche: e.target.value,
                  })
                }
              />
              <datalist id="cityname">
                {this.state.syllabusDatas.map((item, index) => (
                  <option value={item.label} />
                ))}
              </datalist>
              <i className="fal fa-search d-flex align-items-center" />
            </div>
          </div> */}
        </div>

        <div className="col-lg-9 tache">
          {/* {this.state.loading ? 
                <div className="col-md-3 offset-md-5">
              <Loader 
                type="ThreeDots" 
                color="#009688" 
                height={700} 
                width={150} 
                timeout={4000} />  </div>
             : <> */}
          <div className="row ">
            <nav aria-label="breadcrumb fixed">
              <ol className="breadcrumb fixed">
                <li className="breadcrumb-item ">
                  <Link to="/dashboard-coach-assistant">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Liste Taches
                </li>{" "}
              </ol>
              <div className="search offset-md-5">
                <div className="input-group size">
                  <div className="input-group-prepend iconInput">
                    <FeatherIcon className="search_icon" icon="search" />
                    <input
                      value={this.state.recherche}
                      type="search"
                      className="form-control form-control-costom "
                      placeholder="Search by task"
                      list="cityname"
                      aria-label="Search"
                      aria-describedby="search-addon"
                      icon="Search"
                      onChange={(e) =>
                        this.setState({
                          recherche: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </nav>
            <div className="col-md-6 ">
              <h6 className="h-theme ff-inter fw-600">Liste des tâches</h6>
            </div>{" "}
            {/* {this.state.showTache && (
              <div className="col-md-6 offset-4 fixe color ">
                <input
                  value={this.state.recherche}
                  type="search"
                  className="rechercheTache"
                  placeholder="Search by task"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  icon="Search"
                  onChange={(e) =>
                    this.setState({
                      recherche: e.target.value,
                    })
                  }
                />
              </div>
            )} */}
            {/* {this.state.showSyllabus && (
              <Select
                name="syllabus_ids"
                options={this.state.selectOptions}
                className="col-md-6 searchSyllabus color"
                placeholder={<FeatherIcon icon="search" cursor="pointer" />}
                onChange={this.handleChange}
                classNamePrefix="select"
              />
            )} */}
          </div>
          <br />
          {this.state.all_taches ? (
            // this.state.tacheDatas !== undefined &&
            <div classname="row" id="all-syllabus">
              {this.getSyllabusData().map((item, index) => (
                <div className="mb-5">
                  <SyllabusCoachItemTacheAssistant
                    id={item.syllabus_id}
                    tache_id={item.id}
                    competence={item.syllabus_skills}
                    key={index}
                    id_tache={index + 1}
                    tacheid={item.id}
                    contenu={item.syllabus_task}
                    description={item.description}
                    syllabus_name={
                      item.syllabus !== null ? item.syllabus.syllabus_name : ""
                    }
                    week={item.syllabus_week_number}
                    lien={item.tache_url}
                    duree={item.syllabus_completion_time}
                    jointe={item.syllabus_file_jointe}
                    tache_file={item.tache_file}
                    onNameChange={this.onChange}
                    onGetTaches={this.onGetAllTaches}
                    syllabus={item.syllabus}
                  />
                </div>
              ))}
            </div>
          ) : this.state.tacheDatastoFilter.length > 0 ? (
            this.state.tacheDatastoFilter.map((item, index) => (
              <div className="mb-5">
                <SyllabusCoachItemTacheAssistant
                  id={item.syllabus_id}
                  tache_id={item.id}
                  competence={item.syllabus_skills}
                  key={index}
                  id_tache={index + 1}
                  tacheid={item.id}
                  contenu={item.syllabus_task}
                  description={item.description}
                  week={item.syllabus_week_number}
                  lien={item.tache_url}
                  syllabus_name={
                    item.syllabus !== "null" ? item.syllabus.syllabus_name : ""
                  }
                  duree={item.syllabus_completion_time}
                  jointe={item.syllabus_file_jointe}
                  tache_file={item.tache_file}
                  onNameChange={this.onChange}
                  onGetTaches={this.onGetAllTaches}
                  syllabus={item.syllabus}
                />
              </div>
            ))
          ) : (
            <div className="error-text m-t-10">
              Ce syllabus n'a pas encore de taches
            </div>
          )}
          {/* </>  } */}
        </div>

        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteGroupItemForCoachAssistant />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
              <ProgramReunionCoach />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <SavedReunionListItem />
            </div>
          </div>
        </div>
        {/* </>  } */}
      </div>
    );
    // ) : (
    //   <p>
    //     <Offlinecoach />
    //   </p>
    // );
    return (
      <Amplitude>
      <div className="body-theme">
        <HeaderDashboardCoach />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalCoachAssistant />
            </div>

            <div className="col-lg-10 pt-3">
              {checkConnexion}
              {/* <div className="row">
                {this.state.loading ? (
                  <Loader
                    type="ThreeDots"
                    color="#009688"
                    height={700}
                    width={150}
                    timeout={5000} />
                ) : null
                }
                <div className="col-lg-9 tache">
                  {/* <p className="fw-700 ff-inter fs-14 text-dark mb-3">
                    Liste des tâches
                  </p> */}
            </div>
          </div>
        </div>
      </div>
    </Amplitude>);
  }
}

// export default connect(
//     ({ listeTachesCoachView }) => ({ ...listeTachesCoachView }),
//     dispatch => bindActionCreators({ ...listeTachesCoachViewActions }, dispatch)
//   )( listeTachesCoachView );
